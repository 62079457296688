/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckoutRequestSetAlternativeProductOperationsInner
 */
export interface UpdateCheckoutRequestSetAlternativeProductOperationsInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestSetAlternativeProductOperationsInner
     */
    operation: UpdateCheckoutRequestSetAlternativeProductOperationsInnerOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestSetAlternativeProductOperationsInner
     */
    checkoutItemId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestSetAlternativeProductOperationsInner
     */
    alternativeProductId: string;
}


/**
 * @export
 */
export const UpdateCheckoutRequestSetAlternativeProductOperationsInnerOperationEnum = {
    SetAlternativeProduct: 'set_alternative_product'
} as const;
export type UpdateCheckoutRequestSetAlternativeProductOperationsInnerOperationEnum = typeof UpdateCheckoutRequestSetAlternativeProductOperationsInnerOperationEnum[keyof typeof UpdateCheckoutRequestSetAlternativeProductOperationsInnerOperationEnum];


/**
 * Check if a given object implements the UpdateCheckoutRequestSetAlternativeProductOperationsInner interface.
 */
export function instanceOfUpdateCheckoutRequestSetAlternativeProductOperationsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operation" in value;
    isInstance = isInstance && "checkoutItemId" in value;
    isInstance = isInstance && "alternativeProductId" in value;

    return isInstance;
}

export function UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSON(json: any): UpdateCheckoutRequestSetAlternativeProductOperationsInner {
    return UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSONTyped(json, false);
}

export function UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutRequestSetAlternativeProductOperationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'checkoutItemId': json['checkout_item_id'],
        'alternativeProductId': json['alternative_product_id'],
    };
}

export function UpdateCheckoutRequestSetAlternativeProductOperationsInnerToJSON(value?: UpdateCheckoutRequestSetAlternativeProductOperationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': value.operation,
        'checkout_item_id': value.checkoutItemId,
        'alternative_product_id': value.alternativeProductId,
    };
}

