import * as api from "../services/api/models";
import { CouponCodeDiscountType } from "./coupon_code";

export const FriendCouponCodeRewardDiscountType =
  api.FriendCouponCodeAllOfRewardDiscountTypeEnum;
export type FriendCouponCodeRewardDiscountType =
  typeof FriendCouponCodeRewardDiscountType[keyof typeof FriendCouponCodeRewardDiscountType];

export type FriendCouponCodeType = api.FriendCouponCode;

export class FriendCouponCode implements FriendCouponCodeType {
  id = "";
  name = "";
  code = "";
  isAvailable = false;
  expiresAt: Date | undefined = undefined;
  type = "";
  discountType = CouponCodeDiscountType.Amount;
  amountOff = 0;
  percentOff = 0.0;
  isPublic = false;
  reward: api.FriendCouponCodeAllOfReward = {
    discountType: FriendCouponCodeRewardDiscountType.Amount,
    amountOff: 0,
    percentOff: 0.0,
  };

  constructor(data: Partial<FriendCouponCodeType> = {}) {
    Object.assign(this, data);
  }
}
