import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { forwardRef, Ref } from "react";

import { twClassNames } from "shared/lib/tailwind";

export type TextInputProps = {
  invalid?: boolean;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const TextInput = forwardRef(function TextInput({
  type = "text",
  invalid,
  className,
  containerClassName,
  ...props
}: TextInputProps, ref: Ref<HTMLInputElement>) {
  const classes = twClassNames(
    "rounded border-gray-300 shadow-sm w-full form-input",
    "placeholder-gray-300",
    className,
    {
      "pr-10 border-red-300 text-red-900": invalid,
    },
  );

  return (
    <div className={classNames("relative", containerClassName)}>
      <input
        {...props}
        ref={ref}
        type={type}
        className={classes}
      />
      {invalid && (
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      )}
    </div>
  );
});
