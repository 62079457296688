import { immerable } from "immer";

import * as api from "../services/api/logistics/models";

export type CheckoutRedeliveryType = api.CheckoutRedelivery;

export type CheckoutRedeliveryReason = api.CheckoutRedeliveryReasonEnum;
export const CheckoutRedeliveryReason = api.CheckoutRedeliveryReasonEnum;

export class CheckoutRedelivery implements CheckoutRedeliveryType {
  [immerable] = true;

  id = "";
  redeliveryDate = new Date();
  redeliveryTime = "";
  redeliveryCommission = 0;
  reason: CheckoutRedeliveryReason = CheckoutRedeliveryReason.Other;
  note = "";

  constructor(data: Partial<CheckoutRedeliveryType> = {}) {
    Object.assign(this, data);
  }
}
