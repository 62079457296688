/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateReviewRequestReview
 */
export interface CreateReviewRequestReview {
    /**
     * 
     * @type {string}
     * @memberof CreateReviewRequestReview
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateReviewRequestReview
     */
    body: string;
    /**
     * 添付するメディアファイルのsigned_id
     * @type {Array<string>}
     * @memberof CreateReviewRequestReview
     */
    files: Array<string>;
}

/**
 * Check if a given object implements the CreateReviewRequestReview interface.
 */
export function instanceOfCreateReviewRequestReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "files" in value;

    return isInstance;
}

export function CreateReviewRequestReviewFromJSON(json: any): CreateReviewRequestReview {
    return CreateReviewRequestReviewFromJSONTyped(json, false);
}

export function CreateReviewRequestReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReviewRequestReview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'body': json['body'],
        'files': json['files'],
    };
}

export function CreateReviewRequestReviewToJSON(value?: CreateReviewRequestReview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'body': value.body,
        'files': value.files,
    };
}

