/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSaleProducts200ResponseAllOfData } from './GetSaleProducts200ResponseAllOfData';
import {
    GetSaleProducts200ResponseAllOfDataFromJSON,
    GetSaleProducts200ResponseAllOfDataFromJSONTyped,
    GetSaleProducts200ResponseAllOfDataToJSON,
} from './GetSaleProducts200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetSaleProducts200ResponseAllOf
 */
export interface GetSaleProducts200ResponseAllOf {
    /**
     * 
     * @type {GetSaleProducts200ResponseAllOfData}
     * @memberof GetSaleProducts200ResponseAllOf
     */
    data: GetSaleProducts200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetSaleProducts200ResponseAllOf interface.
 */
export function instanceOfGetSaleProducts200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetSaleProducts200ResponseAllOfFromJSON(json: any): GetSaleProducts200ResponseAllOf {
    return GetSaleProducts200ResponseAllOfFromJSONTyped(json, false);
}

export function GetSaleProducts200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSaleProducts200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetSaleProducts200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetSaleProducts200ResponseAllOfToJSON(value?: GetSaleProducts200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetSaleProducts200ResponseAllOfDataToJSON(value.data),
    };
}

