import { useEffect } from "react";

type Response = {
  status: number;
  results: Array<{
    address1: string;
    address2: string;
    address3: string;
    prefcode: string;
    zipcode: string;
  }>;
};

type AddressData = {
  prefectureCode: string;
  prefecture: string;
  city: string;
  town: string;
};

export const useZipCodeAddressCompletion = (
  zipCode: string,
  onCompleted?: (address: AddressData) => void,
) => {
  useEffect(() => {
    if (zipCode?.length !== 7) {
      return;
    }

    fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zipCode}`).then((res) => {
      if (res.ok) {
        res.json().then((data: Response) => {
          if (data.status === 200) {
            const result = data.results[0];
            if (onCompleted && result) {
              onCompleted({
                prefectureCode: result.prefcode,
                prefecture: result.address1,
                city: result.address2,
                town: result.address3,
              });
            }
          }
        });
      }
    });
  }, [zipCode]);
};
