import classNames from "classnames";
import { useMemo } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { ColorRing } from "react-loader-spinner";

import { formatPrice } from "shared/helpers";
import { Checkout } from "shared/models";
import { BFC } from "shared/types";

import { DescriptionPopoverButton } from "~/features/checkouts";

type Props = {
  checkout: Checkout;
  hideTotalPrice?: boolean;
  hideCampaignNotice?: boolean;
  showShippingRateNotice?: boolean;
  showDiscountPriceIfZero?: boolean;
  totalPriceTitle?: string;
  totalPriceSectionClassName?: string;
  totalPriceClassName?: string;
  fixed?: boolean;
  loading?: boolean;
};

export const CheckoutPaymentDetail: BFC<Props> = ({
  checkout,
  hideTotalPrice = false,
  hideCampaignNotice = false,
  showShippingRateNotice = false,
  showDiscountPriceIfZero = false,
  className,
  totalPriceTitle = "ご請求金額(税込)",
  totalPriceSectionClassName,
  totalPriceClassName,
  fixed,
  loading,
}) => {
  const {
    subtotalPrice,
    minSubtotalPrice,
    maxSubtotalPrice,
    discountPrice,
    manualDiscountPrice,
    serviceCommission,
    smallCheckoutCommission,
    redeliveryCommission,
    totalTax,
    minTotalTax,
    maxTotalTax,
    totalPrice,
    minTotalPrice,
    maxTotalPrice,
    totalShippingRate,
  } = checkout;

  const totalPriceSectionClassNames = classNames("flex items-center justify-between", totalPriceSectionClassName);

  const hasAlternative = useMemo(() => {
    return maxTotalPrice !== minTotalPrice;
  }, [maxTotalPrice, minTotalPrice]);

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      <div className="flex items-center justify-between">
        <div>商品の小計</div>
        <div>
          {!fixed && minSubtotalPrice !== maxSubtotalPrice ?
            `${formatPrice(minSubtotalPrice)} 〜 ${formatPrice(maxSubtotalPrice)}` :
            formatPrice(subtotalPrice)}
        </div>
      </div>
      {(discountPrice > 0 || showDiscountPriceIfZero) && (
        <div className="flex items-center justify-between">
          <div>クーポン割引額</div>
          <div className={classNames({ "text-primary": discountPrice > 0 })}>
            {discountPrice > 0 && "-"}{formatPrice(discountPrice)}
          </div>
        </div>
      )}
      {manualDiscountPrice > 0 && (
        <div className="flex items-center justify-between">
          <div>その他の割引額</div>
          <div className="text-primary">
            -{formatPrice(manualDiscountPrice)}
          </div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex gap-1 items-center">
          <div>配送手数料</div>
          <DescriptionPopoverButton
            title="配送手数料"
            description={(
              <div>
                配送毎に484円(税込)の手数料をいただきます。<br />
                15,000円(税抜)以上商品をお買い上げいただいた場合、配送手数料が無料になります。
              </div>
            )}
            button={<AiOutlineQuestionCircle size={16} className="text-black-500" />}
          />
        </div>
        {showShippingRateNotice
          ? <div className="text-gray-500">決済画面で計上されます</div>
          : <div>{formatPrice(totalShippingRate)}</div>
        }
      </div>
      {!hideCampaignNotice && (
        <div className="flex items-start justify-between text-red-500 font-bold">
          <div>ずっと適用💡</div>
          <div className="text-right">
            商品代金15,000円(税抜)以上で<br />
            配送手数料無料
          </div>
        </div>
      )}
      {redeliveryCommission > 0 && (
        <div className="flex items-center justify-between">
          <div className="flex gap-1 items-center">
            <div>再配達手数料</div>
          </div>
          <div>{formatPrice(redeliveryCommission)}</div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex gap-1 items-center">
          <div>サービス料</div>
          <DescriptionPopoverButton
            title="サービス料"
            description="商品の購入金額が10,000円未満の場合、605円(税込)の手数料をいただきます"
            button={<AiOutlineQuestionCircle size={16} className="text-black-500" />}
          />
        </div>
        <div>{formatPrice(serviceCommission)}</div>
      </div>
      {smallCheckoutCommission > 0 && (
        <div className="flex items-center justify-between">
          <div className="flex gap-1 items-center">
            <div>少額決済手数料</div>
            <DescriptionPopoverButton
              title="少額決済手数料"
              description="商品の購入金額が5,000円未満の場合、660円(税込)の手数料をいただきます"
              button={<AiOutlineQuestionCircle size={16} className="text-black-500" />}
              panelClassName="-left-12"
            />
          </div>
          <div>{formatPrice(smallCheckoutCommission)}</div>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex gap-1 items-center">
          <div>消費税</div>
            <DescriptionPopoverButton
              title="消費税"
              description="代替品を選択した場合、実際にお届けできた商品で計算されます"
              button={<AiOutlineQuestionCircle size={16} className="text-black-500" />}
              className="flex items-center"
            />
        </div>
        <div>
          {!fixed && minTotalTax !== maxTotalTax ? `最大 ${formatPrice(maxTotalTax)}` : formatPrice(totalTax)}
        </div>
      </div>
      {!hideTotalPrice && (
        <>
          <div className={totalPriceSectionClassNames}>
            <div>{totalPriceTitle}</div>
            {loading ? (
              <ColorRing width={40} height={40} />
            ) : (
              <div className={totalPriceClassName}>
                {!fixed && minTotalPrice !== maxTotalPrice ?
                  `${formatPrice(minTotalPrice)} 〜 ${formatPrice(maxTotalPrice)}` :
                  formatPrice(totalPrice)}
              </div>
            )}
          </div>
          {hasAlternative && !fixed && (
            <div className="text-right text-sm text-black-400">
              各種手数料は代替品を含む最大金額基準で計算されます
            </div>
          )}
        </>
      )}
    </div>
  );
};
