/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * Paymentを表示するための必要最低限のフィールドのみを持たせたモデル
 * @export
 * @interface PaymentMinimum
 */
export interface PaymentMinimum {
    /**
     * 
     * @type {string}
     * @memberof PaymentMinimum
     */
    id: string;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof PaymentMinimum
     */
    paymentMethod: PaymentMethod;
    /**
     * 
     * @type {string}
     * @memberof PaymentMinimum
     */
    status: PaymentMinimumStatusEnum;
    /**
     * Stripe により提供される領収書ページのURL。支払いが未完了の場合はフィールドが存在しない
     * @type {string}
     * @memberof PaymentMinimum
     */
    receiptUrl?: string;
}


/**
 * @export
 */
export const PaymentMinimumStatusEnum = {
    Pending: 'pending',
    Confirmed: 'confirmed',
    Completed: 'completed',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type PaymentMinimumStatusEnum = typeof PaymentMinimumStatusEnum[keyof typeof PaymentMinimumStatusEnum];


/**
 * Check if a given object implements the PaymentMinimum interface.
 */
export function instanceOfPaymentMinimum(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PaymentMinimumFromJSON(json: any): PaymentMinimum {
    return PaymentMinimumFromJSONTyped(json, false);
}

export function PaymentMinimumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMinimum {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'paymentMethod': PaymentMethodFromJSON(json['payment_method']),
        'status': json['status'],
        'receiptUrl': !exists(json, 'receipt_url') ? undefined : json['receipt_url'],
    };
}

export function PaymentMinimumToJSON(value?: PaymentMinimum | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'payment_method': PaymentMethodToJSON(value.paymentMethod),
        'status': value.status,
        'receipt_url': value.receiptUrl,
    };
}

