import { immerable } from "immer";

import * as api from "../services/api/models";

export type ShippingTimeType = api.ShippingTime;

export class ShippingTime implements ShippingTimeType {
  [immerable] = true;

  id = "";
  name = "";
  rangeStart = "";
  rangeEnd = "";

  constructor(data: Partial<ShippingTimeType> = {}) {
    Object.assign(this, data);
  }

  get colorCode() {
    const hash = this.id.split("").reduce((acc, c) => {
      acc = (acc << 5) - acc + c.charCodeAt(0);
      return acc & acc;
    }, 0);
    const r = ((hash & 0xff0000) >> 16).toString(16).padStart(2, "0");
    const g = ((hash & 0x00ff00) >> 8).toString(16).padStart(2, "0");
    const b = (hash & 0x0000ff).toString(16).padStart(2, "0");
    return `#${r}${g}${b}`;
  }
}
