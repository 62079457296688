import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { BFC } from "shared/types";

import { routes } from "~/constants";

import { EmailEditForm, EmailEditData } from "../../forms";
import { useUpdateEmail } from "../../hooks";

export const EmailEditPage: BFC = () => {
  const { email, update } = useUpdateEmail();
  const [isUpdatedEmail, setIsUpdatedEmail] = useState(false);
  const router = useRouter();

  const onSubmit = useCallback(async (data: EmailEditData) => {
    await update(data, {
      onSuccess: () => setIsUpdatedEmail(true),
    });
  }, []);

  const onBackClick = useCallback(() => {
    router.push(routes.MYPAGE_EMAIL_SHOW);
  }, []);

  const defaultValues = useMemo(() => ({
    user: { email },
  }), [email]);

  if (isUpdatedEmail) {
    return (
      <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
        <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <h1 className="text-xl font-bold">メール送信完了</h1>
          </div>
          <p>メールアドレス認証用のURLをご入力のメールアドレスに送信しました。<br /> 記載された内容に従って、メールアドレスの変更を行なってください。</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onBackClick} />
          メールアドレスの設定
        </h1>
      </div>
      <div className="bg-white p-3">
        <EmailEditForm
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
      </div>
    </div>
  );
};
