import * as api from "../services/api/models";

export const CouponCodeDiscountType = api.CouponCodeDiscountTypeEnum;
export type CouponCodeDiscountType = typeof CouponCodeDiscountType[keyof typeof CouponCodeDiscountType];

export type CouponCodeType = api.CouponCode;

export class CouponCode implements CouponCodeType {
  id = "";
  name = "";
  code = "";
  isAvailable = false;
  expiresAt: Date | undefined = undefined;
  type = "";
  discountType = CouponCodeDiscountType.Amount;
  amountOff = 0;
  percentOff = 0.0;
  isPublic = false;

  constructor(data: Partial<CouponCodeType> = {}) {
    Object.assign(this, data);
  }
}
