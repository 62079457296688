/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorUnauthorizedAllOfError } from './ResponseErrorUnauthorizedAllOfError';
import {
    ResponseErrorUnauthorizedAllOfErrorFromJSON,
    ResponseErrorUnauthorizedAllOfErrorFromJSONTyped,
    ResponseErrorUnauthorizedAllOfErrorToJSON,
} from './ResponseErrorUnauthorizedAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorUnauthorizedAllOf
 */
export interface ResponseErrorUnauthorizedAllOf {
    /**
     * 
     * @type {ResponseErrorUnauthorizedAllOfError}
     * @memberof ResponseErrorUnauthorizedAllOf
     */
    error: ResponseErrorUnauthorizedAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorUnauthorizedAllOf interface.
 */
export function instanceOfResponseErrorUnauthorizedAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorUnauthorizedAllOfFromJSON(json: any): ResponseErrorUnauthorizedAllOf {
    return ResponseErrorUnauthorizedAllOfFromJSONTyped(json, false);
}

export function ResponseErrorUnauthorizedAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorUnauthorizedAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorUnauthorizedAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorUnauthorizedAllOfToJSON(value?: ResponseErrorUnauthorizedAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorUnauthorizedAllOfErrorToJSON(value.error),
    };
}

