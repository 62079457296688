/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckoutRequestSetManualDiscountPriceOperation
 */
export interface UpdateCheckoutRequestSetManualDiscountPriceOperation {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestSetManualDiscountPriceOperation
     */
    operation: UpdateCheckoutRequestSetManualDiscountPriceOperationOperationEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateCheckoutRequestSetManualDiscountPriceOperation
     */
    manualDiscountPrice: number;
}


/**
 * @export
 */
export const UpdateCheckoutRequestSetManualDiscountPriceOperationOperationEnum = {
    SetManualDiscountPrice: 'set_manual_discount_price'
} as const;
export type UpdateCheckoutRequestSetManualDiscountPriceOperationOperationEnum = typeof UpdateCheckoutRequestSetManualDiscountPriceOperationOperationEnum[keyof typeof UpdateCheckoutRequestSetManualDiscountPriceOperationOperationEnum];


/**
 * Check if a given object implements the UpdateCheckoutRequestSetManualDiscountPriceOperation interface.
 */
export function instanceOfUpdateCheckoutRequestSetManualDiscountPriceOperation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operation" in value;
    isInstance = isInstance && "manualDiscountPrice" in value;

    return isInstance;
}

export function UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSON(json: any): UpdateCheckoutRequestSetManualDiscountPriceOperation {
    return UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSONTyped(json, false);
}

export function UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutRequestSetManualDiscountPriceOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'manualDiscountPrice': json['manual_discount_price'],
    };
}

export function UpdateCheckoutRequestSetManualDiscountPriceOperationToJSON(value?: UpdateCheckoutRequestSetManualDiscountPriceOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': value.operation,
        'manual_discount_price': value.manualDiscountPrice,
    };
}

