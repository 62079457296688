/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutRedelivery
 */
export interface CheckoutRedelivery {
    /**
     * 
     * @type {string}
     * @memberof CheckoutRedelivery
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof CheckoutRedelivery
     */
    redeliveryDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckoutRedelivery
     */
    redeliveryTime?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutRedelivery
     */
    redeliveryCommission: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutRedelivery
     */
    reason: CheckoutRedeliveryReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutRedelivery
     */
    note?: string;
}


/**
 * @export
 */
export const CheckoutRedeliveryReasonEnum = {
    Pending: 'pending',
    Absent: 'absent',
    AddressUnknown: 'address_unknown',
    OperationMiss: 'operation_miss',
    Other: 'other'
} as const;
export type CheckoutRedeliveryReasonEnum = typeof CheckoutRedeliveryReasonEnum[keyof typeof CheckoutRedeliveryReasonEnum];


/**
 * Check if a given object implements the CheckoutRedelivery interface.
 */
export function instanceOfCheckoutRedelivery(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "redeliveryDate" in value;
    isInstance = isInstance && "redeliveryCommission" in value;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function CheckoutRedeliveryFromJSON(json: any): CheckoutRedelivery {
    return CheckoutRedeliveryFromJSONTyped(json, false);
}

export function CheckoutRedeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutRedelivery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'redeliveryDate': (new Date(json['redelivery_date'])),
        'redeliveryTime': !exists(json, 'redelivery_time') ? undefined : json['redelivery_time'],
        'redeliveryCommission': json['redelivery_commission'],
        'reason': json['reason'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function CheckoutRedeliveryToJSON(value?: CheckoutRedelivery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'redelivery_date': (value.redeliveryDate.toISOString().substr(0,10)),
        'redelivery_time': value.redeliveryTime,
        'redelivery_commission': value.redeliveryCommission,
        'reason': value.reason,
        'note': value.note,
    };
}

