import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { Button, Link, RadioInput } from "shared/components";
import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { PaymentMethodListItem, usePaymentMethods } from "~/features/users";

import { useCheckoutServiceContext } from "../../hooks";

export const PaymentMethodsIndexPage: BFC = () => {
  const { paymentMethods, defaultPaymentMethod } = usePaymentMethods();
  const { paymentMethod: currentPaymentMethod, setPaymentMethod } = useCheckoutServiceContext();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(currentPaymentMethod);
  const router = useRouter();

  useEffect(() => {
    setSelectedPaymentMethod(currentPaymentMethod);
  }, [currentPaymentMethod]);

  const onSelectFactory = useCallback((paymentMethod: PaymentMethod) => () => {
    setSelectedPaymentMethod(paymentMethod);
  }, []);

  const onSubmit = useCallback(() => {
    setPaymentMethod(selectedPaymentMethod);
    router.push(routes.CHECKOUTS_NEW);
  }, [selectedPaymentMethod]);

  return (
    <div className="bg-white">
      <div className="flex flex-col divide-y">
        {paymentMethods.map((paymentMethod) => (
          <div key={paymentMethod.id} className="flex items-center gap-3 px-3" onClick={onSelectFactory(paymentMethod)}>
            <div className="flex flex-col justify-start">
              <RadioInput checked={paymentMethod.id === selectedPaymentMethod?.id} />
            </div>
            <PaymentMethodListItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              isDefault={defaultPaymentMethod?.id === paymentMethod.id}
              className="flex-1 py-3"
            />
          </div>
        ))}
        <Link href={routes.CHECKOUTS_NEW_PAYMENT_METHODS_NEW} noDecoration className="px-3 py-4 flex items-center justify-between">
          支払い方法を追加する
          <ChevronRightIcon className="w-5" />
        </Link>
        <div className="p-3">
          <Button primary large block onClick={onSubmit}>
            変更する
          </Button>
        </div>
      </div>
    </div>
  );
};
