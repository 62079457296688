/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStockupItemRequestStockupItem } from './CreateStockupItemRequestStockupItem';
import {
    CreateStockupItemRequestStockupItemFromJSON,
    CreateStockupItemRequestStockupItemFromJSONTyped,
    CreateStockupItemRequestStockupItemToJSON,
} from './CreateStockupItemRequestStockupItem';

/**
 * 
 * @export
 * @interface CreateStockupItemRequest
 */
export interface CreateStockupItemRequest {
    /**
     * 
     * @type {CreateStockupItemRequestStockupItem}
     * @memberof CreateStockupItemRequest
     */
    stockupItem: CreateStockupItemRequestStockupItem;
}

/**
 * Check if a given object implements the CreateStockupItemRequest interface.
 */
export function instanceOfCreateStockupItemRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockupItem" in value;

    return isInstance;
}

export function CreateStockupItemRequestFromJSON(json: any): CreateStockupItemRequest {
    return CreateStockupItemRequestFromJSONTyped(json, false);
}

export function CreateStockupItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockupItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockupItem': CreateStockupItemRequestStockupItemFromJSON(json['stockup_item']),
    };
}

export function CreateStockupItemRequestToJSON(value?: CreateStockupItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockup_item': CreateStockupItemRequestStockupItemToJSON(value.stockupItem),
    };
}

