import classNames from "classnames";
import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { SelectInputGroup, SelectInputGroupProps } from "../SelectInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & SelectInputGroupProps;

export const SelectControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  inputClassName,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState, watch } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);
  const value = watch(name);

  const className = classNames(inputClassName, {
    "text-gray-400": !value,
  });

  return (
    <SelectInputGroup
      {...props}
      {...register(name)}
      inputClassName={className}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
