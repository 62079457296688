import classNames from "classnames";
import { format } from "date-fns";
import { useCallback } from "react";

import { formatWeekday } from "shared/helpers";
import { BFC } from "shared/types";

type Props = {
  date: Date;
  selected?: boolean;
  available?: boolean;
  onClick?: (date: Date) => void;
};

export const DatePickerItem: BFC<Props> = ({
  date,
  selected,
  available,
  onClick,
  className,
}) => {
  const classes = classNames("w-20 flex flex-col gap-1 items-center border rounded p-1", className, {
    "border-primary bg-primary-50": selected,
    "text-black-200 bg-gray-50": !available,
  });

  const onClickHandler = useCallback(() => {
    if (onClick && available) {
      onClick(date);
    }
  }, [date, onClick, available]);

  return (
    <div className={classes} onClick={onClickHandler}>
      <div className="text-sm">
        {formatWeekday(date)}
      </div>
      <div className="text-sm">
        {format(date, "MM月dd日")}
      </div>
    </div>
  );
};
