/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckoutFullfilledStockingAllOfData } from './TimelineCheckoutFullfilledStockingAllOfData';
import {
    TimelineCheckoutFullfilledStockingAllOfDataFromJSON,
    TimelineCheckoutFullfilledStockingAllOfDataFromJSONTyped,
    TimelineCheckoutFullfilledStockingAllOfDataToJSON,
} from './TimelineCheckoutFullfilledStockingAllOfData';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledStocking
 */
export interface TimelineCheckoutFullfilledStocking {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledStocking
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledStocking
     */
    event: TimelineCheckoutFullfilledStockingEventEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimelineCheckoutFullfilledStocking
     */
    occurredAt: Date;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof TimelineCheckoutFullfilledStocking
     */
    initiator: TimelineInitiator;
    /**
     * 
     * @type {TimelineCheckoutFullfilledStockingAllOfData}
     * @memberof TimelineCheckoutFullfilledStocking
     */
    data: TimelineCheckoutFullfilledStockingAllOfData;
}


/**
 * @export
 */
export const TimelineCheckoutFullfilledStockingEventEnum = {
    FullfilledStocking: 'fullfilled_stocking'
} as const;
export type TimelineCheckoutFullfilledStockingEventEnum = typeof TimelineCheckoutFullfilledStockingEventEnum[keyof typeof TimelineCheckoutFullfilledStockingEventEnum];


/**
 * Check if a given object implements the TimelineCheckoutFullfilledStocking interface.
 */
export function instanceOfTimelineCheckoutFullfilledStocking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "occurredAt" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledStockingFromJSON(json: any): TimelineCheckoutFullfilledStocking {
    return TimelineCheckoutFullfilledStockingFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledStockingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledStocking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'],
        'occurredAt': (new Date(json['occurred_at'])),
        'initiator': TimelineInitiatorFromJSON(json['initiator']),
        'data': TimelineCheckoutFullfilledStockingAllOfDataFromJSON(json['data']),
    };
}

export function TimelineCheckoutFullfilledStockingToJSON(value?: TimelineCheckoutFullfilledStocking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event': value.event,
        'occurred_at': (value.occurredAt.toISOString()),
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': TimelineCheckoutFullfilledStockingAllOfDataToJSON(value.data),
    };
}

