import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useCallback, useState } from "react";
import { MdPayment } from "react-icons/md";

import { Link, Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { ConfirmModal } from "~/components";
import { routes } from "~/constants";
import { PaymentMethodListItem, usePaymentMethods } from "~/features/users";

export const PaymentMethodsIndexPage: BFC = () => {
  const { paymentMethods, defaultPaymentMethod, destroy } = usePaymentMethods();
  const [modalShown, showModal, hideModal] = useBooleanState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>(new PaymentMethod);

  const onClickDeleteFactory = useCallback((paymentMethod: PaymentMethod) => () => {
    setSelectedPaymentMethod(paymentMethod);
    showModal();
  }, []);

  const onConfirmFactory = useCallback((id: string) => () => {
    destroy(id, {
      onSuccess: () => {
        hideModal();
      },
    });
  }, []);

  return (
    <>
      <div className="flex flex-col gap-4 pb-4">
        <div className="bg-white">
          <h1 className="flex items-center gap-2 font-bold px-5 py-6 text-lg">
            <MdPayment size={24} className="text-black-500" />
            お支払い方法一覧
          </h1>
        </div>

        <div className="bg-white">
          <div className="flex flex-col divide-y">
            {paymentMethods.map((paymentMethod) => (
              <div key={paymentMethod.id} className="flex items-center gap-3 p-4">
                <PaymentMethodListItem
                  key={paymentMethod.id}
                  paymentMethod={paymentMethod}
                  isDefault={defaultPaymentMethod?.id === paymentMethod.id}
                  className="flex-1"
                />
                <div>
                  <Button small onClick={onClickDeleteFactory(paymentMethod)}>削除</Button>
                </div>
              </div>
            ))}
            <Link href={routes.MYPAGE_PAYMENT_METHODS_NEW} noDecoration className="px-3 py-4 flex items-center justify-between">
              支払い方法を追加する
              <ChevronRightIcon className="w-5" />
            </Link>
          </div>
        </div>
      </div>

      <ConfirmModal
        open={modalShown}
        title="お支払い方法の削除"
        description="本当にこのお支払い方法を削除しますか？"
        onConfirm={onConfirmFactory(selectedPaymentMethod.id)}
        onCancel={hideModal}
      />
    </>

  );
};
