import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { BiDetail } from "react-icons/bi";
import { HiExclamationTriangle, HiOutlineCalendar, HiOutlineCreditCard, HiOutlineTruck } from "react-icons/hi2";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { formatCheckoutParcelRedeliveryStatus, formatCheckoutParcelShippingStatus } from "shared/helpers";
import { useBooleanState } from "shared/hooks";
import { getParam } from "shared/lib/next";
import { Address, CheckoutParcelDeliveryFailedReason, CheckoutStatus } from "shared/models";
import { BFC } from "shared/types";

import { ConfirmModal, MaskedCardDetail } from "~/components";
import { routes } from "~/constants";
import { useCheckout, CheckoutPaymentDetail } from "~/features/checkouts";
import { AddressListItem } from "~/features/users";

import { CheckoutItemRow } from "./CheckoutItemRow";

export const CheckoutShowPage: BFC = () => {
  const router = useRouter();
  const id = getParam(router.query, "id");
  const { checkout, isLoading, cancel } = useCheckout(id);
  const [modalShown, showModal, hideModal] = useBooleanState();

  const payment = useMemo(() => checkout.getPayment(), [checkout]);
  const paymentMethod = useMemo(() => payment.getPaymentMethod(), [payment]);

  const onClickHandler = useCallback(() => {
    showModal();
  }, []);

  const onConfirm = useCallback(() => {
    cancel({ onSuccess: hideModal });
  }, []);

  const shippingTimeDecorator = useCallback((shippingTime: string) => {
    return (
      <span className="text-green-600 font-bold">{shippingTime}</span>
    );
  }, []);

  const redeliveryDateDecorator = useCallback((date: string) => {
    return (
      <span className="text-red-600 font-bold">{date}</span>
    );
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <ColorRing />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 pb-4">
        <div className="bg-white">
          <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
            <BiDetail size={24} className="text-black-500" />
            注文の詳細
          </h1>
        </div>
        <div className="bg-white p-4 flex flex-col gap-4">
          <div className="w-full grid grid-cols-1 gap-6 text-base font-medium leading-5 sm:grid-cols-3">
            {payment.isFailed() && (
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-lg flex gap-1 items-center text-red-600">
                  <HiExclamationTriangle size={24} />
                  支払いに失敗しました
                </h2>
                <div>
                  <Link href={routes.CHECKOUTS_PAYMENT_METHODS(checkout.id)}>
                    <Button primary block>お支払い方法を変更する</Button>
                  </Link>
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <h2 className="font-bold text-lg flex gap-1 items-center">
                <HiOutlineCalendar size={24} />
                注文日
              </h2>
              <div className="text-gray-500">{checkout.formatCheckedOutAt(true)}</div>
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="font-bold text-lg flex gap-1 items-center">
                <HiOutlineTruck size={24} />
                お届け先
              </h2>
              <div className="text-gray-500">
                <AddressListItem address={new Address(checkout.shippingAddress)} />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="font-bold text-lg flex gap-1 items-center">
                <HiOutlineCreditCard size={24} />
                お支払い詳細
              </h2>
              <div className="text-gray-500">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <div>お支払い方法</div>
                    <div>
                      {paymentMethod.id ? (
                        <MaskedCardDetail
                          brand={paymentMethod.card.brand}
                          last4={paymentMethod.card.last4}
                        />
                      ) : (
                        <span className="text-gray-500">不明</span>
                      )}
                    </div>
                  </div>
                  {(checkout.hasCancelItems()) ? (
                    <>
                      <CheckoutPaymentDetail
                        checkout={checkout}
                        totalPriceTitle="購入時のご請求金額"
                        totalPriceSectionClassName="text-gray-500"
                        hideCampaignNotice
                      />
                      <div className="flex flex-col gap-1">
                        <div>注文内容の変更後</div>
                        <div className="border-t pt-2 border-gray-200 flex flex-col gap-2">
                          <CheckoutPaymentDetail
                            checkout={checkout.getFixedCheckout()}
                            totalPriceSectionClassName="font-bold text-black"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <CheckoutPaymentDetail
                      checkout={checkout.getFixedCheckout()}
                      fixed={checkout.isFixed()}
                      totalPriceSectionClassName="font-bold text-black"
                      hideCampaignNotice
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            {checkout.getParcels().map((parcel) => (
              <div key={parcel.id} className="border rounded">
                <div className="p-3 border-b bg-neutral-100">
                  {checkout.status === CheckoutStatus.Canceled ?
                    "キャンセル済みです" :
                    formatCheckoutParcelShippingStatus(parcel, {
                      decorator: shippingTimeDecorator,
                      billed: checkout.isBilled,
                    })
                  }
                  {parcel.redeliveryDate && parcel.deliveryFailedReason != CheckoutParcelDeliveryFailedReason.Pending && (
                    <div>{formatCheckoutParcelRedeliveryStatus(parcel, { decorator: redeliveryDateDecorator })}</div>
                  )}
                </div>
                <div className="flex flex-col divide-y divide-dashed">
                  {parcel.getFixedItems(checkout).map((item) => (
                    <CheckoutItemRow
                      key={item.id}
                      item={item}
                      fixed={parcel.isFixed()}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          {checkout.status !== CheckoutStatus.Canceled && (
            checkout.isCancelable && (
              <div className="text-gray-500">配達日の変更をご希望の場合、ご注文をキャンセルして再度注文していただくようお願い致します</div>
            )
          )}
          <div className="flex flex-col gap-2 text-gray-500">
            <div>
              <Button
                primary
                block
                onClick={onClickHandler}
                disabled={!checkout.isCancelable}
              >
                注文をキャンセルする
              </Button>
            </div>
            {checkout.status === CheckoutStatus.Paid && (
              <div>
                {checkout.isCancelable
                  ? `${checkout.formatCancelableAt()}までキャンセルできます`
                  : "配送が進行中のため、キャンセルできません"}
              </div>
            )}
            {checkout.status === CheckoutStatus.Completed && checkout.payment?.receiptUrl ? (
              <div className="flex justify-center mt-2">
                <a href={checkout.payment.receiptUrl} target="_blank" rel="noreferrer" className="text-black-500 hover:text-black-700">
                  領収書を表示
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <ConfirmModal
        open={modalShown}
        title="注文のキャンセル"
        description="本当に注文をキャンセルしますか？"
        confirmText="キャンセルする"
        cancelText="キャンセルしない"
        onConfirm={onConfirm}
        onCancel={hideModal}
      />
    </>
  );
};
