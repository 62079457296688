import { CFC } from "shared/types";

import { Footer } from "~/components/layouts/Footer";
import { TitleHeader } from "~/components/layouts/TitleHeader";

import { CheckoutServiceProvider, StripeProvider } from "../../hooks";

type Props = {
  backTo?: string;
  title: string;
  headerContent?: React.ReactNode;
};

export const CheckoutLayout: CFC<Props> = ({
  backTo,
  title,
  children,
}) => {
  return (
    <StripeProvider>
      <CheckoutServiceProvider>
        <div className="w-full h-full">
          <div className="flex flex-col h-full relative">
            <TitleHeader
              backTo={backTo}
              title={title}
              className="sticky top-0 border-b z-30"
            />
            <div className="flex-1 flex z-0">
              <div className="w-full flex-1">
                {children}
              </div>
            </div>
            <Footer className="mt-6" />
          </div>
        </div>
      </CheckoutServiceProvider>
    </StripeProvider>
  );
};
