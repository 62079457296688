import { PhotoIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ReactNode } from "react";

import { Product } from "shared/models";
import { BFC } from "shared/types";

import { ProductPrice } from "~/features/products/components/ProductPrice";

type Props = {
  product: Product;
  quantity?: number;
  smallImage?: boolean;
  imageClassName?: string;
  hidePrice?: boolean;
  clampTitle?: boolean;
  showDescription?: boolean;
  action?: ReactNode;
};

export const ProductCardWide: BFC<Props> = ({
  product,
  quantity,
  smallImage,
  hidePrice,
  clampTitle,
  showDescription,
  action,
  className,
  imageClassName,
}) => {
  let imageClasses = classNames("border rounded aspect-square object-cover", imageClassName);
  imageClasses = classNames(imageClasses, {
    "w-28": !smallImage && !imageClasses.includes("w-"),
    "w-20": smallImage && !imageClasses.includes("w-"),
  });

  return (
    <div className={classNames("flex gap-3 items-start text-left", className)}>
      {product.image?.url || product.image?.webp ? (
        <img
          src={product.image?.webp?.url ?? product.image?.url}
          className={imageClasses}
        />
      ) : (
        <div className="border rounded flex justify-center items-center aspect-square">
          <PhotoIcon className="text-black-200 w-8" />
        </div>
      )}
      <div className="flex flex-col gap-1 flex-1">
        <h3 className={classNames({ "line-clamp-2": clampTitle })}>{product.title}</h3>
        {!hidePrice && (
          <ProductPrice
            price={product.price}
            discountPrice={product.discountPrice}
            taxPercentage={product.taxPercentage}
            className="font-bold"
          />
        )}
        {showDescription && (
          <p className="text-sm text-black-400 line-clamp-2">{product.description}</p>
        )}
        {quantity && <div>{quantity}個</div>}
        {action}
      </div>
    </div>
  );
};
