/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Permission } from './Permission';
import {
    PermissionFromJSON,
    PermissionFromJSONTyped,
    PermissionToJSON,
} from './Permission';

/**
 * 
 * @export
 * @interface SurveyUser
 */
export interface SurveyUser {
    /**
     * 
     * @type {string}
     * @memberof SurveyUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SurveyUser
     */
    nickname: string;
    /**
     * 
     * @type {Array<Permission>}
     * @memberof SurveyUser
     */
    permissions: Array<Permission>;
}

/**
 * Check if a given object implements the SurveyUser interface.
 */
export function instanceOfSurveyUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "permissions" in value;

    return isInstance;
}

export function SurveyUserFromJSON(json: any): SurveyUser {
    return SurveyUserFromJSONTyped(json, false);
}

export function SurveyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SurveyUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'nickname': json['nickname'],
        'permissions': ((json['permissions'] as Array<any>).map(PermissionFromJSON)),
    };
}

export function SurveyUserToJSON(value?: SurveyUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'nickname': value.nickname,
        'permissions': ((value.permissions as Array<any>).map(PermissionToJSON)),
    };
}

