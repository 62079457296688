/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateReviewRequestReview } from './CreateReviewRequestReview';
import {
    CreateReviewRequestReviewFromJSON,
    CreateReviewRequestReviewFromJSONTyped,
    CreateReviewRequestReviewToJSON,
} from './CreateReviewRequestReview';

/**
 * 
 * @export
 * @interface CreateReviewRequest
 */
export interface CreateReviewRequest {
    /**
     * 
     * @type {CreateReviewRequestReview}
     * @memberof CreateReviewRequest
     */
    review: CreateReviewRequestReview;
}

/**
 * Check if a given object implements the CreateReviewRequest interface.
 */
export function instanceOfCreateReviewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "review" in value;

    return isInstance;
}

export function CreateReviewRequestFromJSON(json: any): CreateReviewRequest {
    return CreateReviewRequestFromJSONTyped(json, false);
}

export function CreateReviewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateReviewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'review': CreateReviewRequestReviewFromJSON(json['review']),
    };
}

export function CreateReviewRequestToJSON(value?: CreateReviewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'review': CreateReviewRequestReviewToJSON(value.review),
    };
}

