/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryParcel } from './DeliveryParcel';
import {
    DeliveryParcelFromJSON,
    DeliveryParcelFromJSONTyped,
    DeliveryParcelToJSON,
} from './DeliveryParcel';

/**
 * 
 * @export
 * @interface CreateDeliveryParcel201ResponseAllOfData
 */
export interface CreateDeliveryParcel201ResponseAllOfData {
    /**
     * 
     * @type {DeliveryParcel}
     * @memberof CreateDeliveryParcel201ResponseAllOfData
     */
    deliveryParcel: DeliveryParcel;
}

/**
 * Check if a given object implements the CreateDeliveryParcel201ResponseAllOfData interface.
 */
export function instanceOfCreateDeliveryParcel201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryParcel" in value;

    return isInstance;
}

export function CreateDeliveryParcel201ResponseAllOfDataFromJSON(json: any): CreateDeliveryParcel201ResponseAllOfData {
    return CreateDeliveryParcel201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateDeliveryParcel201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryParcel201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryParcel': DeliveryParcelFromJSON(json['delivery_parcel']),
    };
}

export function CreateDeliveryParcel201ResponseAllOfDataToJSON(value?: CreateDeliveryParcel201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_parcel': DeliveryParcelToJSON(value.deliveryParcel),
    };
}

