/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseSuccessWithPagingAllOfMetaPaging } from './ResponseSuccessWithPagingAllOfMetaPaging';
import {
    ResponseSuccessWithPagingAllOfMetaPagingFromJSON,
    ResponseSuccessWithPagingAllOfMetaPagingFromJSONTyped,
    ResponseSuccessWithPagingAllOfMetaPagingToJSON,
} from './ResponseSuccessWithPagingAllOfMetaPaging';

/**
 * 
 * @export
 * @interface ResponseSuccessWithPagingAllOfMeta
 */
export interface ResponseSuccessWithPagingAllOfMeta {
    /**
     * 
     * @type {ResponseSuccessWithPagingAllOfMetaPaging}
     * @memberof ResponseSuccessWithPagingAllOfMeta
     */
    paging: ResponseSuccessWithPagingAllOfMetaPaging;
}

/**
 * Check if a given object implements the ResponseSuccessWithPagingAllOfMeta interface.
 */
export function instanceOfResponseSuccessWithPagingAllOfMeta(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paging" in value;

    return isInstance;
}

export function ResponseSuccessWithPagingAllOfMetaFromJSON(json: any): ResponseSuccessWithPagingAllOfMeta {
    return ResponseSuccessWithPagingAllOfMetaFromJSONTyped(json, false);
}

export function ResponseSuccessWithPagingAllOfMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseSuccessWithPagingAllOfMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paging': ResponseSuccessWithPagingAllOfMetaPagingFromJSON(json['paging']),
    };
}

export function ResponseSuccessWithPagingAllOfMetaToJSON(value?: ResponseSuccessWithPagingAllOfMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'paging': ResponseSuccessWithPagingAllOfMetaPagingToJSON(value.paging),
    };
}

