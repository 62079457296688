import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { SignInUserRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";
import { useCartContext, useMergeCart } from "~/features/carts";

export const useSignIn = () => {
  const api = useStoreAPI();
  const { signIn, waitToken, setOnlyToken } = useAuthContext();
  const { cart, associateToUser, setCartId } = useCartContext();
  const { merge } = useMergeCart();
  const [isSignedInLoading, setIsSignedInLoading] = useState(false);

  const { mutateAsync, isLoading } = useMutation(
    ["users/signIn"],
    (data: SignInUserRequest) => api.signInUser(data),
    {
      onSuccess: async ({ data: { user, token, cart: c } }) => {
        setIsSignedInLoading(true);
        setOnlyToken(new Token(token));
        if (!c) {
          await waitToken();
          await associateToUser(cart.id);
        } else {
          await merge(c.id, cart);
          setCartId(c.id);
        }
        signIn(new UserMe(user), new Token(token));
        setIsSignedInLoading(false);
      },
    },
  );

  return {
    signIn: mutateAsync,
    isSignedInLoading,
    isLoading: isLoading || isSignedInLoading,
  };
};
