/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledShippingAllOfDataItems
 */
export interface TimelineCheckoutFullfilledShippingAllOfDataItems {
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledShippingAllOfDataItems
     */
    productId: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledShippingAllOfDataItems
     */
    alternativeProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledShippingAllOfDataItems
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledShippingAllOfDataItems
     */
    cancelQuantity: number;
}

/**
 * Check if a given object implements the TimelineCheckoutFullfilledShippingAllOfDataItems interface.
 */
export function instanceOfTimelineCheckoutFullfilledShippingAllOfDataItems(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "cancelQuantity" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSON(json: any): TimelineCheckoutFullfilledShippingAllOfDataItems {
    return TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledShippingAllOfDataItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'alternativeProductId': !exists(json, 'alternative_product_id') ? undefined : json['alternative_product_id'],
        'quantity': json['quantity'],
        'cancelQuantity': json['cancel_quantity'],
    };
}

export function TimelineCheckoutFullfilledShippingAllOfDataItemsToJSON(value?: TimelineCheckoutFullfilledShippingAllOfDataItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'alternative_product_id': value.alternativeProductId,
        'quantity': value.quantity,
        'cancel_quantity': value.cancelQuantity,
    };
}

