/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCostcoProducts201ResponseAllOfData } from './PostCostcoProducts201ResponseAllOfData';
import {
    PostCostcoProducts201ResponseAllOfDataFromJSON,
    PostCostcoProducts201ResponseAllOfDataFromJSONTyped,
    PostCostcoProducts201ResponseAllOfDataToJSON,
} from './PostCostcoProducts201ResponseAllOfData';

/**
 * 
 * @export
 * @interface PostCostcoProducts201Response
 */
export interface PostCostcoProducts201Response {
    /**
     * 
     * @type {PostCostcoProducts201ResponseAllOfData}
     * @memberof PostCostcoProducts201Response
     */
    data: PostCostcoProducts201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof PostCostcoProducts201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the PostCostcoProducts201Response interface.
 */
export function instanceOfPostCostcoProducts201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function PostCostcoProducts201ResponseFromJSON(json: any): PostCostcoProducts201Response {
    return PostCostcoProducts201ResponseFromJSONTyped(json, false);
}

export function PostCostcoProducts201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCostcoProducts201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PostCostcoProducts201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function PostCostcoProducts201ResponseToJSON(value?: PostCostcoProducts201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PostCostcoProducts201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

