import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ArticleCarousel, ArticleCardWide } from "../../components";
import { useMagazineArticles, useMagazinePickupArticles } from "../../hooks";

export const MagazineTopPage: BFC = () => {
  const { articles: pickupArticles, isLoading: isPickupLoading } = useMagazinePickupArticles();
  const { articles, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useMagazineArticles();

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div>
      <div className="bg-white">
        {!isPickupLoading && pickupArticles.length > 0 && (
          <ArticleCarousel articles={pickupArticles} />
        )}
        <div className="bg-white p-4">
          <h2 className="font-bold text-lg">新着記事</h2>
          <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage} className="mt-4 flex flex-col gap-3">
            {articles.map((article) => (
              <Link key={article.id} href={routes.MAGAZINE_ARTICLES_SHOW(article.slug)}>
                <ArticleCardWide article={article} />
              </Link>
            ))}
          </InfiniteScroll>
          {(isLoading || isFetchingNextPage) && (
            <div className="flex justify-center items-center">
              <ColorRing />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
