import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { CostcoProduct, CostcoProductType } from "./costco_product";
import { Stockup, StockupType } from "./stockup";

export type StockupItemType = api.StockupItem & {
  stockup: StockupType;
};

export const StockupItemStatus = api.StockupItemStatusEnum;
export type StockupItemStatus = api.StockupItemStatusEnum;

export class StockupItem implements StockupItemType {
  [immerable] = true;

  id = "";
  stockup: StockupType = new Stockup();
  costcoProduct: CostcoProductType = new CostcoProduct();
  quantity = 0;
  status: StockupItemStatus = StockupItemStatus.Reserved;

  constructor(data: Partial<StockupItemType> = {}) {
    Object.assign(this, data);
  }

  getStockup() {
    return new Stockup(this.stockup);
  }

  getCostcoProduct() {
    return new CostcoProduct(this.costcoProduct);
  }

  isReserved() {
    return this.status === StockupItemStatus.Reserved;
  }

  isOutOfStock() {
    return this.status === StockupItemStatus.OutOfStock;
  }

  isStocked() {
    return this.status === StockupItemStatus.Stocked;
  }

  get contentsQuantity() {
    return this.quantity * this.costcoProduct.contentsQuantity;
  }
}
