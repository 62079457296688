import { useMutation } from "@tanstack/react-query";

import { useStoreAPI } from "shared/services/api";
import { UpdateUserEmailRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

export const useUpdateEmail = () => {
  const { user, accessToken, refetch } = useAuthContext();
  const api = useStoreAPI({ accessToken });

  const { mutateAsync, isLoading } = useMutation(
    ["users/email"],
    (data: UpdateUserEmailRequest) => api.updateUserEmail(data),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    email: user.email,
    update: mutateAsync,
    isLoading,
  };
};
