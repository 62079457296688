/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDeliveryDriver201ResponseAllOfData } from './CreateDeliveryDriver201ResponseAllOfData';
import {
    CreateDeliveryDriver201ResponseAllOfDataFromJSON,
    CreateDeliveryDriver201ResponseAllOfDataFromJSONTyped,
    CreateDeliveryDriver201ResponseAllOfDataToJSON,
} from './CreateDeliveryDriver201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateDeliveryDriver201Response
 */
export interface CreateDeliveryDriver201Response {
    /**
     * 
     * @type {CreateDeliveryDriver201ResponseAllOfData}
     * @memberof CreateDeliveryDriver201Response
     */
    data: CreateDeliveryDriver201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof CreateDeliveryDriver201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the CreateDeliveryDriver201Response interface.
 */
export function instanceOfCreateDeliveryDriver201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function CreateDeliveryDriver201ResponseFromJSON(json: any): CreateDeliveryDriver201Response {
    return CreateDeliveryDriver201ResponseFromJSONTyped(json, false);
}

export function CreateDeliveryDriver201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryDriver201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateDeliveryDriver201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function CreateDeliveryDriver201ResponseToJSON(value?: CreateDeliveryDriver201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateDeliveryDriver201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

