/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutSummary } from './CheckoutSummary';
import {
    CheckoutSummaryFromJSON,
    CheckoutSummaryFromJSONTyped,
    CheckoutSummaryToJSON,
} from './CheckoutSummary';

/**
 * 在庫の消費（チェックアウト付き）
 * @export
 * @interface StockConsumptionWithCheckout
 */
export interface StockConsumptionWithCheckout {
    /**
     * 
     * @type {CheckoutSummary}
     * @memberof StockConsumptionWithCheckout
     */
    checkout: CheckoutSummary;
    /**
     * 消費した内容量
     * @type {number}
     * @memberof StockConsumptionWithCheckout
     */
    consumedContentsQuantity: number;
    /**
     * 消費日時
     * @type {Date}
     * @memberof StockConsumptionWithCheckout
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the StockConsumptionWithCheckout interface.
 */
export function instanceOfStockConsumptionWithCheckout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkout" in value;
    isInstance = isInstance && "consumedContentsQuantity" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function StockConsumptionWithCheckoutFromJSON(json: any): StockConsumptionWithCheckout {
    return StockConsumptionWithCheckoutFromJSONTyped(json, false);
}

export function StockConsumptionWithCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockConsumptionWithCheckout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkout': CheckoutSummaryFromJSON(json['checkout']),
        'consumedContentsQuantity': json['consumed_contents_quantity'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function StockConsumptionWithCheckoutToJSON(value?: StockConsumptionWithCheckout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkout': CheckoutSummaryToJSON(value.checkout),
        'consumed_contents_quantity': value.consumedContentsQuantity,
        'created_at': (value.createdAt.toISOString()),
    };
}

