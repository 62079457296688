import { z } from "zod";

import {
  Button,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    email: z.string().min(1).email(),
  }),
});

export type EmailEditData = z.infer<typeof schema>;

export const EmailEditForm = createHookForm<EmailEditData>(({
  formState: { isSubmitting },
}) => {
  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup name="user.email" label="メールアドレス" placeholder="taro@soctoc.jp" required />
      <Button type="submit" block primary large disabled={isSubmitting}>
        変更する
      </Button>
    </div>
  );
}, {
  schema,
});
