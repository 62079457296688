import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { z } from "zod";

import { ProductsSortParam } from "./useProducts";

/**
 * 商品一覧のクエリパラメータを扱うフック
 */

const QUERY_KEYS = {
  SORT: "sort",
} as const;

export const useProductsQueryParams = () => {
  const router = useRouter();

  /**
   * クエリパラメータからパースした商品一覧のクエリパラメータ
   *
   * 不正な値はオブジェクトに含まれない
   * 有効な値がない場合は undefined になる
   */
  const parsedQueryParams = useMemo(() => {
    const parsed = z
      .object({
        sort: z
          .nativeEnum(ProductsSortParam)
          .optional()
          .catch(() => undefined),
      })
      .parse(router.query);

    // undefined のプロパティを削除
    const cleaned = Object.fromEntries(
      Object.entries(parsed).filter(([, value]) => typeof value !== "undefined")
    );

    if (!Object.keys(cleaned).length) {
      return undefined;
    }

    return cleaned;
  }, [router.query]);

  const setSort = useCallback(
    async (value: ProductsSortParam) => {
      await router.replace(
        {
          query: {
            ...router.query,
            [QUERY_KEYS.SORT]: value,
          },
        },
        undefined,
        {
          scroll: false,
          shallow: true,
        }
      );
    },
    [router.pathname, router.query]
  );

  return {
    parsedQueryParams,
    sort: parsedQueryParams?.sort || ProductsSortParam.ActivatedAtDesc,
    setSort,
  };
};
