import { useMutation, useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { createAtomFamily } from "shared/lib/recoil";
import { Address } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { CreateUserAddressRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

import { useAddresses } from "./useAddresses";

const addressState = createAtomFamily<Address>(new Address());

export const useAddress = (id: string) => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });
  const [address, setAddress] = useRecoilState(addressState(id));
  const { defaultAddress } = useAddresses();

  const { isLoading, error } = useQuery(["users/useAddress", id], () => api.getUserAddress(id), {
    enabled: !!id,
    onSuccess: ({ data }) => {
      setAddress(new Address(data.userAddress));
    },
  });

  const { mutateAsync: update, isLoading: isUpdateMutating } = useMutation(
    ["users/useAddress", id, "update"],
    (data: CreateUserAddressRequest) => api.updateUserAddress(id, data), {
      onSuccess: ({ data }) => {
        setAddress(new Address(data.userAddress));
      }
    },
  );

  const { mutate: destroy } = useMutation((id: string) => api.deleteUserAddress(id));

  return {
    address,
    defaultAddress,
    update,
    destroy,
    error,
    isLoading,
    isMutating: isUpdateMutating,
  };
};
