import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { DeliverableAddress } from "shared/models";
import { useStoreAPI } from "shared/services/api";

export const useIsDeliverableAddress = (zipCode?: string) => {
  const api = useStoreAPI();
  const [isDeliverable, setDeliverable] = useState(true);
  const [deliverableAddress, setDeliverableAddress] = useState<DeliverableAddress | null>(null);
  const [fastestShippingDate, setFastestShippingDate] = useState<Date | null>(null);
  const [fastestShippingTime, setFastestShippingTime] = useState<string | null>(null);

  const { isLoading } = useQuery(
    ["addresses/getIsDeliverableAddress", zipCode],
    () => api.getDeliverableAddresses(zipCode),
    {
      enabled: !!zipCode,
      onSuccess: ({ data: { addresses, fastestShippingDate, fastestShippingTime } }) => {
        setDeliverable(addresses.length > 0);
        setDeliverableAddress(addresses[0] ? new DeliverableAddress(addresses[0]) : null);
        setFastestShippingDate(fastestShippingDate ?? null);
        setFastestShippingTime(fastestShippingTime ?? null);
      },
      onError: () => {
        setDeliverable(false);
      },
    },
  );

  return {
    isDeliverable,
    fastestShippingDate,
    fastestShippingTime,
    isLoading: isLoading && !!zipCode,
    deliverableAddress,
  };
};
