import { CancelMembershipLogReason } from "shared/models/cancel_membership_log";

export const formatCancelMembershipLogReason = (cancelMembershipLogReason: CancelMembershipLogReason) => {
  const reason = {
    high_commissions: "手数料が高い",
    not_found_wanted: "欲しい商品がない",
    not_covered_area: "配送可能エリアに自宅が含まれていない",
    no: "なし",
    other: "その他"
  };

  return reason[cancelMembershipLogReason];
};