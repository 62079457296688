import { MdOutlineEmail } from "react-icons/md";

import { Button, Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

export const EmailShowPage: BFC = () => {
  const { user } = useAuthContext();

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-5 py-6 text-lg">
          <MdOutlineEmail  size={24} className="text-black-500" />
          メールアドレスの詳細
        </h1>
      </div>

      <div className="bg-white">
        <div className="flex items-center gap-3 px-3 py-4 justify-between">
          <div>
            <div className="font-bold">現在登録しているメールアドレス</div>
            <div>{user.email}</div>
          </div>
          <Link href={routes.MYPAGE_EMAIL_EDIT}>
            <Button small>編集</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};