/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CouponCode
 */
export interface CouponCode {
    /**
     * 
     * @type {string}
     * @memberof CouponCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CouponCode
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CouponCode
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof CouponCode
     */
    isAvailable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CouponCode
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CouponCode
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CouponCode
     */
    discountType: CouponCodeDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CouponCode
     */
    amountOff: number;
    /**
     * 
     * @type {number}
     * @memberof CouponCode
     */
    percentOff: number;
    /**
     * 
     * @type {boolean}
     * @memberof CouponCode
     */
    isPublic: boolean;
}


/**
 * @export
 */
export const CouponCodeDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent'
} as const;
export type CouponCodeDiscountTypeEnum = typeof CouponCodeDiscountTypeEnum[keyof typeof CouponCodeDiscountTypeEnum];


/**
 * Check if a given object implements the CouponCode interface.
 */
export function instanceOfCouponCode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "isAvailable" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "discountType" in value;
    isInstance = isInstance && "amountOff" in value;
    isInstance = isInstance && "percentOff" in value;
    isInstance = isInstance && "isPublic" in value;

    return isInstance;
}

export function CouponCodeFromJSON(json: any): CouponCode {
    return CouponCodeFromJSONTyped(json, false);
}

export function CouponCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouponCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
        'isAvailable': json['is_available'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'type': json['type'],
        'discountType': json['discount_type'],
        'amountOff': json['amount_off'],
        'percentOff': json['percent_off'],
        'isPublic': json['is_public'],
    };
}

export function CouponCodeToJSON(value?: CouponCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'is_available': value.isAvailable,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'type': value.type,
        'discount_type': value.discountType,
        'amount_off': value.amountOff,
        'percent_off': value.percentOff,
        'is_public': value.isPublic,
    };
}

