import { ErrorBoundary } from "shared/components";
import { WithChildrenProps } from "shared/types";

type Props = WithChildrenProps;

export const App = ({
  children,
}: Props) => {
  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};
