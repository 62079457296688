/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ユーザーの通知受信設定
 * @export
 * @interface UserNotificationSetting
 */
export interface UserNotificationSetting {
    /**
     * カート落ちリマインダー
     * @type {boolean}
     * @memberof UserNotificationSetting
     */
    cartDropReminder: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationSetting
     */
    couponNotification: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserNotificationSetting
     */
    productReActivated: boolean;
}

/**
 * Check if a given object implements the UserNotificationSetting interface.
 */
export function instanceOfUserNotificationSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cartDropReminder" in value;
    isInstance = isInstance && "couponNotification" in value;
    isInstance = isInstance && "productReActivated" in value;

    return isInstance;
}

export function UserNotificationSettingFromJSON(json: any): UserNotificationSetting {
    return UserNotificationSettingFromJSONTyped(json, false);
}

export function UserNotificationSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cartDropReminder': json['cart_drop_reminder'],
        'couponNotification': json['coupon_notification'],
        'productReActivated': json['product_re_activated'],
    };
}

export function UserNotificationSettingToJSON(value?: UserNotificationSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cart_drop_reminder': value.cartDropReminder,
        'coupon_notification': value.couponNotification,
        'product_re_activated': value.productReActivated,
    };
}

