/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCostcoProductLog200ResponseAllOfData } from './GetCostcoProductLog200ResponseAllOfData';
import {
    GetCostcoProductLog200ResponseAllOfDataFromJSON,
    GetCostcoProductLog200ResponseAllOfDataFromJSONTyped,
    GetCostcoProductLog200ResponseAllOfDataToJSON,
} from './GetCostcoProductLog200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCostcoProductLog200Response
 */
export interface GetCostcoProductLog200Response {
    /**
     * 
     * @type {GetCostcoProductLog200ResponseAllOfData}
     * @memberof GetCostcoProductLog200Response
     */
    data: GetCostcoProductLog200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetCostcoProductLog200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetCostcoProductLog200Response interface.
 */
export function instanceOfGetCostcoProductLog200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetCostcoProductLog200ResponseFromJSON(json: any): GetCostcoProductLog200Response {
    return GetCostcoProductLog200ResponseFromJSONTyped(json, false);
}

export function GetCostcoProductLog200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProductLog200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCostcoProductLog200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetCostcoProductLog200ResponseToJSON(value?: GetCostcoProductLog200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCostcoProductLog200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

