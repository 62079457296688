import { immerable } from "immer";

import * as api from "../services/api/models";
import { AttachableImageType } from "./attachable_image";

export type ProductImageType = api.ProductImage;

export class ProductImage implements ProductImageType {
  [immerable] = true;

  url = "";
  height = 0;
  width = 0;
  displayPriority = 0;
  caption = "";
  webp?: AttachableImageType;
  thumb?: AttachableImageType;
  thumbWebp?: AttachableImageType;

  constructor(data: Partial<ProductImageType> = {}) {
    Object.assign(this, data);
  }
}
