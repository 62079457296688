/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stock } from './Stock';
import {
    StockFromJSON,
    StockFromJSONTyped,
    StockToJSON,
} from './Stock';

/**
 * 在庫の消費（在庫付き）
 * @export
 * @interface StockConsumptionWithStock
 */
export interface StockConsumptionWithStock {
    /**
     * 
     * @type {Stock}
     * @memberof StockConsumptionWithStock
     */
    stock: Stock;
    /**
     * 消費した内容量
     * @type {number}
     * @memberof StockConsumptionWithStock
     */
    consumedContentsQuantity: number;
    /**
     * 消費日時
     * @type {Date}
     * @memberof StockConsumptionWithStock
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the StockConsumptionWithStock interface.
 */
export function instanceOfStockConsumptionWithStock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stock" in value;
    isInstance = isInstance && "consumedContentsQuantity" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function StockConsumptionWithStockFromJSON(json: any): StockConsumptionWithStock {
    return StockConsumptionWithStockFromJSONTyped(json, false);
}

export function StockConsumptionWithStockFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockConsumptionWithStock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stock': StockFromJSON(json['stock']),
        'consumedContentsQuantity': json['consumed_contents_quantity'],
        'createdAt': (new Date(json['created_at'])),
    };
}

export function StockConsumptionWithStockToJSON(value?: StockConsumptionWithStock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stock': StockToJSON(value.stock),
        'consumed_contents_quantity': value.consumedContentsQuantity,
        'created_at': (value.createdAt.toISOString()),
    };
}

