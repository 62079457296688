import classNames from "classnames";
import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxInput } from "../CheckboxInput";

export type CheckboxInputsGroupItem = {
  value: string;
  label: string;
};

export type CheckboxInputsGroupProps = {
  label?: ReactNode;
  items: CheckboxInputsGroupItem[];
  renderItem?: (item: CheckboxInputsGroupItem, index: number) => ReactNode;
  error?: string;
  invalid?: boolean;
  inputClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInputsGroup = forwardRef(function CheckboxInputGroup({
  name,
  label,
  items,
  renderItem,
  error,
  invalid,
  inputClassName,
  className,
  ...props
}: CheckboxInputsGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={classNames("mt-3 first:mt-0", className)}>
      {label && (
        <label className="block text-sm font-medium text-gray-700">{label}</label>
      )}
      <div className="mt-1 flex gap-x-4 gap-y-3 flex-wrap">
        {items.map((item, index) => renderItem ? renderItem(item, index) : (
          <CheckboxInput
            {...props}
            key={item.value}
            name={`${name}.${index}`}
            value={item.value}
            label={item.label}
            className={inputClassName}
          />
        ))}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
    </div>
  );
});
