/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostPreRegistrations201ResponseAllOfData } from './PostPreRegistrations201ResponseAllOfData';
import {
    PostPreRegistrations201ResponseAllOfDataFromJSON,
    PostPreRegistrations201ResponseAllOfDataFromJSONTyped,
    PostPreRegistrations201ResponseAllOfDataToJSON,
} from './PostPreRegistrations201ResponseAllOfData';

/**
 * 
 * @export
 * @interface PostPreRegistrations201Response
 */
export interface PostPreRegistrations201Response {
    /**
     * 
     * @type {PostPreRegistrations201ResponseAllOfData}
     * @memberof PostPreRegistrations201Response
     */
    data: PostPreRegistrations201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof PostPreRegistrations201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the PostPreRegistrations201Response interface.
 */
export function instanceOfPostPreRegistrations201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function PostPreRegistrations201ResponseFromJSON(json: any): PostPreRegistrations201Response {
    return PostPreRegistrations201ResponseFromJSONTyped(json, false);
}

export function PostPreRegistrations201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPreRegistrations201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PostPreRegistrations201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function PostPreRegistrations201ResponseToJSON(value?: PostPreRegistrations201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PostPreRegistrations201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

