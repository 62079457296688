import { formatPrice, calcTaxIncludedPrice } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { BFC } from "shared/types";

type Props = {
  price: number;
  discountPrice?: number;
  taxPercentage?: number;
};

export const ProductPrice: BFC<Props> = ({
  price,
  discountPrice,
  taxPercentage = -1,
  className
}) => {
  if (!price) {
    return null;
  }

  if (taxPercentage === -1) {
    return <div>{`${formatPrice(price)}(税抜)`}</div>;
  } else if (taxPercentage === 0) {
    return <div>{`${formatPrice(price)}(税込)`}</div>;
  }

  if (discountPrice !== undefined) {
    return (
      <div className={twClassNames("flex gap-1 flex-col text-sm", className)}>
        <div className="text-primary font-bold">
          {formatPrice(discountPrice)}
          (税込{formatPrice(calcTaxIncludedPrice(discountPrice, taxPercentage))})
        </div>
        <div className="text-black-500 line-through">
          (税込{formatPrice(calcTaxIncludedPrice(price, taxPercentage))})
        </div>
      </div>
    );
  }

  return (
    <div className={twClassNames("text-sm", className)}>
      <span>
        {formatPrice(price)}
      </span>
      <span>
        (税込{formatPrice(calcTaxIncludedPrice(price, taxPercentage))})
      </span>
    </div>
  );
};
