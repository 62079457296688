/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeleteUserAddress200ResponseAllOfData } from './DeleteUserAddress200ResponseAllOfData';
import {
    DeleteUserAddress200ResponseAllOfDataFromJSON,
    DeleteUserAddress200ResponseAllOfDataFromJSONTyped,
    DeleteUserAddress200ResponseAllOfDataToJSON,
} from './DeleteUserAddress200ResponseAllOfData';

/**
 * 
 * @export
 * @interface DeleteUserAddress200ResponseAllOf
 */
export interface DeleteUserAddress200ResponseAllOf {
    /**
     * 
     * @type {DeleteUserAddress200ResponseAllOfData}
     * @memberof DeleteUserAddress200ResponseAllOf
     */
    data: DeleteUserAddress200ResponseAllOfData;
}

/**
 * Check if a given object implements the DeleteUserAddress200ResponseAllOf interface.
 */
export function instanceOfDeleteUserAddress200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DeleteUserAddress200ResponseAllOfFromJSON(json: any): DeleteUserAddress200ResponseAllOf {
    return DeleteUserAddress200ResponseAllOfFromJSONTyped(json, false);
}

export function DeleteUserAddress200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteUserAddress200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': DeleteUserAddress200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function DeleteUserAddress200ResponseAllOfToJSON(value?: DeleteUserAddress200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DeleteUserAddress200ResponseAllOfDataToJSON(value.data),
    };
}

