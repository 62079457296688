/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeleteCostcoProductLog200ResponseAllOfData } from './DeleteCostcoProductLog200ResponseAllOfData';
import {
    DeleteCostcoProductLog200ResponseAllOfDataFromJSON,
    DeleteCostcoProductLog200ResponseAllOfDataFromJSONTyped,
    DeleteCostcoProductLog200ResponseAllOfDataToJSON,
} from './DeleteCostcoProductLog200ResponseAllOfData';

/**
 * 
 * @export
 * @interface DeleteCostcoProductLog200ResponseAllOf
 */
export interface DeleteCostcoProductLog200ResponseAllOf {
    /**
     * 
     * @type {DeleteCostcoProductLog200ResponseAllOfData}
     * @memberof DeleteCostcoProductLog200ResponseAllOf
     */
    data: DeleteCostcoProductLog200ResponseAllOfData;
}

/**
 * Check if a given object implements the DeleteCostcoProductLog200ResponseAllOf interface.
 */
export function instanceOfDeleteCostcoProductLog200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DeleteCostcoProductLog200ResponseAllOfFromJSON(json: any): DeleteCostcoProductLog200ResponseAllOf {
    return DeleteCostcoProductLog200ResponseAllOfFromJSONTyped(json, false);
}

export function DeleteCostcoProductLog200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCostcoProductLog200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': DeleteCostcoProductLog200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function DeleteCostcoProductLog200ResponseAllOfToJSON(value?: DeleteCostcoProductLog200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DeleteCostcoProductLog200ResponseAllOfDataToJSON(value.data),
    };
}

