/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyUser } from './SurveyUser';
import {
    SurveyUserFromJSON,
    SurveyUserFromJSONTyped,
    SurveyUserToJSON,
} from './SurveyUser';
import type { Token } from './Token';
import {
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
} from './Token';

/**
 * 
 * @export
 * @interface SignInUser201ResponseAllOfData
 */
export interface SignInUser201ResponseAllOfData {
    /**
     * 
     * @type {SurveyUser}
     * @memberof SignInUser201ResponseAllOfData
     */
    user: SurveyUser;
    /**
     * 
     * @type {Token}
     * @memberof SignInUser201ResponseAllOfData
     */
    token: Token;
}

/**
 * Check if a given object implements the SignInUser201ResponseAllOfData interface.
 */
export function instanceOfSignInUser201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function SignInUser201ResponseAllOfDataFromJSON(json: any): SignInUser201ResponseAllOfData {
    return SignInUser201ResponseAllOfDataFromJSONTyped(json, false);
}

export function SignInUser201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInUser201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': SurveyUserFromJSON(json['user']),
        'token': TokenFromJSON(json['token']),
    };
}

export function SignInUser201ResponseAllOfDataToJSON(value?: SignInUser201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': SurveyUserToJSON(value.user),
        'token': TokenToJSON(value.token),
    };
}

