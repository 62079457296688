import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";
import { useCartContext } from "~/features/carts";

export const useSignOut = () => {
  const { unsetCart } = useCartContext();
  const { signOut, accessToken } = useAuthContext();
  const [isSignOutLoading, setIsSignOutLoading] = useState(false);
  const api = useStoreAPI({ accessToken });

  const { mutateAsync, isLoading } = useMutation(
    ["users/signOut"],
    () => api.signOutUser(),
    {
      onSuccess: async () => {
        setIsSignOutLoading(true);
        signOut();
        unsetCart();
        setIsSignOutLoading(false);
      },
      onError: async () => {
        signOut();
        unsetCart();
        setIsSignOutLoading(false);
      },
    },
  );

  return {
    signOut: mutateAsync,
    isSignOutLoading,
    isLoading: isLoading || isSignOutLoading,
  };
};
