import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

import { Button, Link, RadioInput } from "shared/components";
import { Address } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { AddressListItem, useAddresses } from "~/features/users";

import { useCheckoutServiceContext } from "../../hooks";

export const AddressesIndexPage: BFC = () => {
  const { addresses, defaultAddress } = useAddresses();
  const { address: currentAddress, setAddress } = useCheckoutServiceContext();
  const [selectedAddress, setSelectedAddress] = useState(currentAddress);
  const router = useRouter();

  useEffect(() => {
    setSelectedAddress(currentAddress);
  }, [currentAddress]);

  const onSelectFactory = useCallback((address: Address) => () => {
    setSelectedAddress(address);
  }, []);

  const onSubmit = useCallback(() => {
    setAddress(selectedAddress);
    router.push(routes.CHECKOUTS_NEW);
  }, [selectedAddress]);

  return (
    <div className="bg-white">
      <div className="flex flex-col divide-y">
        {addresses.map((address) => (
          <div key={address.id} className="flex items-center gap-3 px-3" onClick={onSelectFactory(address)}>
            <div className="flex flex-col justify-start">
              <RadioInput checked={address.id === selectedAddress?.id} />
            </div>
            <AddressListItem
              key={address.id}
              address={address}
              isDefault={defaultAddress?.id === address.id}
              className="flex-1 py-3"
            />
            <div onClick={(e) => e.stopPropagation()}>
              <Link href={routes.CHECKOUTS_NEW_ADDRESSES_EDIT(address.id)}>
                <Button small>修正</Button>
              </Link>
            </div>
          </div>
        ))}
        <Link href={routes.CHECKOUTS_NEW_ADDRESSES_NEW} noDecoration className="px-3 py-4 flex items-center justify-between">
          お届け先を追加する
          <ChevronRightIcon className="w-5" />
        </Link>
        <div className="p-3">
          <Button primary large block onClick={onSubmit}>
            変更する
          </Button>
        </div>
      </div>
    </div>
  );
};
