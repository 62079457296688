/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostPreRegistrations201ResponseAllOfData } from './PostPreRegistrations201ResponseAllOfData';
import {
    PostPreRegistrations201ResponseAllOfDataFromJSON,
    PostPreRegistrations201ResponseAllOfDataFromJSONTyped,
    PostPreRegistrations201ResponseAllOfDataToJSON,
} from './PostPreRegistrations201ResponseAllOfData';

/**
 * 
 * @export
 * @interface PostPreRegistrations201ResponseAllOf
 */
export interface PostPreRegistrations201ResponseAllOf {
    /**
     * 
     * @type {PostPreRegistrations201ResponseAllOfData}
     * @memberof PostPreRegistrations201ResponseAllOf
     */
    data?: PostPreRegistrations201ResponseAllOfData;
}

/**
 * Check if a given object implements the PostPreRegistrations201ResponseAllOf interface.
 */
export function instanceOfPostPreRegistrations201ResponseAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostPreRegistrations201ResponseAllOfFromJSON(json: any): PostPreRegistrations201ResponseAllOf {
    return PostPreRegistrations201ResponseAllOfFromJSONTyped(json, false);
}

export function PostPreRegistrations201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPreRegistrations201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : PostPreRegistrations201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function PostPreRegistrations201ResponseAllOfToJSON(value?: PostPreRegistrations201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PostPreRegistrations201ResponseAllOfDataToJSON(value.data),
    };
}

