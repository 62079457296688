import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { formatPrice } from "shared/helpers";
import { CartItemType, Product } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { CheckoutPaymentDetail } from "~/features/checkouts";
import { AlternativeProductSelector, ProductCardWideRaw, SoctocProductProvider } from "~/features/products";

import { QuantityAdjuster } from "../../components";
import { useCartContext, useCartCalculate, useCartValidation } from "../../hooks";

export const CartShowPage: BFC = () => {
  const { cart, updateCartItem, isLoading, isMutating } = useCartContext();
  const { checkout, isLoading: isCalculateLoading } = useCartCalculate(cart);

  const cartValidation = useCartValidation();

  const onPlusFactory = useCallback((item: CartItemType) => () => {
    updateCartItem(item.product.id, {
      quantity: item.quantity + 1,
    });
  }, [updateCartItem]);

  const onMinusFactory = useCallback((item: CartItemType) => () => {
    updateCartItem(item.product.id, {
      quantity: item.quantity - 1,
    });
  }, [updateCartItem]);

  const onRemoveFactory = useCallback((item: CartItemType) => () => {
    updateCartItem(item.product.id, {
      quantity: 0,
    });
  }, [updateCartItem]);

  const onAlternativeProductChangeFactory = useCallback((item: CartItemType) => (alternativeProduct: Product | null) => {
    return updateCartItem(item.product.id, {
      alternativeProductId: alternativeProduct?.id,
      removeAlternativeProduct: !alternativeProduct,
    });
  }, [updateCartItem]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <ColorRing />
      </div>
    );
  }

  const items = cart.getItems();

  return (
    <div>
      <div className="bg-white">
        {items.length > 0 && (
          <div className="flex flex-col border-b divide-y divide-dashed">
            {items.map((item) => (
              <SoctocProductProvider
                key={item.product.id}
                product={item.getProduct()}
              >
                {(product) => (
                  <div className="p-4">
                    <ProductCardWideRaw
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      image={product.image?.webp?.url ?? product.image?.url}
                      price={product.price}
                      discountPrice={product.discountPrice}
                      inactive={product.isInactive()}
                      taxPercentage={product.taxPercentage}
                      sellableQuantity={product.sellableQuantity}
                      action={
                        <QuantityAdjuster
                          quantity={item.quantity}
                          maxQuantity={product.sellableQuantity}
                          onPlus={onPlusFactory(item)}
                          onMinus={onMinusFactory(item)}
                          onRemove={onRemoveFactory(item)}
                          disabled={isMutating || isLoading}
                          className="mt-2"
                        />
                      }
                    />
                    <AlternativeProductSelector
                      selected={item.getAlternativeProduct()}
                      products={product.getAlternativeProducts()}
                      onSelect={onAlternativeProductChangeFactory(item)}
                      className="mt-2"
                    />
                  </div>
                )}
              </SoctocProductProvider>
            ))}
          </div>
        )}
        <div className="px-4 py-5">
          {cart.totalQuantity > 0 ? (
            <>
              <CheckoutPaymentDetail
                checkout={checkout}
                showShippingRateNotice={true}
                totalPriceTitle="合計(税込)"
                totalPriceSectionClassName="text-lg"
                totalPriceClassName="text-2xl font-bold"
                loading={isCalculateLoading}
                className="mb-2"
              />
              <div className="mt-3">
                {cartValidation.hasError ? (
                  <div className="flex flex-col gap-3">
                    <Button primary large block disabled>
                      レジに進むにはエラーを解消してください
                    </Button>
                    <div className="flex flex-col gap-2">
                      {cartValidation.errorMessages.map((message) => (
                        <div key={message} className="flex items-center w-full flex-none gap-x-2">
                          <dt className="flex-none">
                            <ExclamationCircleIcon
                              className="h-5 w-5 text-red-400"
                              aria-hidden="true"
                            />
                          </dt>
                          <dd className="text-sm font-medium text-red-500">
                            {message}
                          </dd>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link href={routes.CHECKOUTS_NEW}>
                    <Button primary large block>
                      レジに進む({cart.totalQuantity}個の商品)
                    </Button>
                  </Link>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-between items-center">
                <div className="text-lg">合計(税込)</div>
                <div className="font-bold text-2xl">{formatPrice(0)}</div>
              </div>
              <Button primary large block disabled className="mt-3">
                商品が入っていません
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
