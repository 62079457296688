import classNames from "classnames";
import { useEffect, useState } from "react";
import { HiOutlineShoppingCart } from "react-icons/hi2";

import { Link, NotificationBadge } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useCartContext } from "~/features/carts";

export const CartLink: BFC = () => {
  const { cart, isLoading: isCartLoading } = useCartContext();

  // カートの数量が増減したときにカートアイコンをバウンドさせる
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isCartBouncing, setIsCartBouncing] = useState(false);
  useEffect(() => {
    if (isCartLoading) {
      return;
    }

    if (isInitialRender) {
      // ページ読み込み直後にバウンドするのを防ぐ
      setIsInitialRender(false);
      return;
    }

    setIsCartBouncing(true);
    const timeoutId = setTimeout(() => {
      setIsCartBouncing(false);
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [cart.totalQuantity]);

  const badgeId = "cart-badge";

  return (
    <Link
      href={routes.CARTS_SHOW}
      className={classNames("relative", {
        "animate-bounce-once": isCartBouncing,
      })}
      aria-label="カートを見る"
      aria-describedby={cart.totalQuantity > 0 ? badgeId : undefined}
    >
      <HiOutlineShoppingCart size={24} className="text-gray-500" />
      {cart.totalQuantity > 0 && (
        <div id={badgeId}>
          <NotificationBadge
            count={cart.totalQuantity}
            className="absolute -top-1 -right-1"
          />
          <span className="sr-only">
            {cart.totalQuantity}個の商品がカートに入っています
          </span>
        </div>
      )}
    </Link>
  );
};
