/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateStockRequestStock
 */
export interface UpdateStockRequestStock {
    /**
     * 
     * @type {number}
     * @memberof UpdateStockRequestStock
     */
    stockedContentsQuantity?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateStockRequestStock
     */
    stockedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof UpdateStockRequestStock
     */
    expiresAt?: Date | null;
}

/**
 * Check if a given object implements the UpdateStockRequestStock interface.
 */
export function instanceOfUpdateStockRequestStock(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateStockRequestStockFromJSON(json: any): UpdateStockRequestStock {
    return UpdateStockRequestStockFromJSONTyped(json, false);
}

export function UpdateStockRequestStockFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStockRequestStock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockedContentsQuantity': !exists(json, 'stocked_contents_quantity') ? undefined : json['stocked_contents_quantity'],
        'stockedAt': !exists(json, 'stocked_at') ? undefined : (json['stocked_at'] === null ? null : new Date(json['stocked_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (json['expires_at'] === null ? null : new Date(json['expires_at'])),
    };
}

export function UpdateStockRequestStockToJSON(value?: UpdateStockRequestStock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stocked_contents_quantity': value.stockedContentsQuantity,
        'stocked_at': value.stockedAt === undefined ? undefined : (value.stockedAt === null ? null : value.stockedAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt === null ? null : value.expiresAt.toISOString()),
    };
}

