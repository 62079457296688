/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCostcoProductsRequestCostcoProduct
 */
export interface PostCostcoProductsRequestCostcoProduct {
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    nameEn: string;
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    sku: string;
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    storageTemperature: string;
    /**
     * 
     * @type {number}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    contentsQuantity: number;
    /**
     * 
     * @type {boolean}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    isPerWeight: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductsRequestCostcoProduct
     */
    kind: string;
}

/**
 * Check if a given object implements the PostCostcoProductsRequestCostcoProduct interface.
 */
export function instanceOfPostCostcoProductsRequestCostcoProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nameEn" in value;
    isInstance = isInstance && "sku" in value;
    isInstance = isInstance && "storageTemperature" in value;
    isInstance = isInstance && "contentsQuantity" in value;
    isInstance = isInstance && "isPerWeight" in value;
    isInstance = isInstance && "kind" in value;

    return isInstance;
}

export function PostCostcoProductsRequestCostcoProductFromJSON(json: any): PostCostcoProductsRequestCostcoProduct {
    return PostCostcoProductsRequestCostcoProductFromJSONTyped(json, false);
}

export function PostCostcoProductsRequestCostcoProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCostcoProductsRequestCostcoProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'nameEn': json['name_en'],
        'sku': json['sku'],
        'storageTemperature': json['storage_temperature'],
        'contentsQuantity': json['contents_quantity'],
        'isPerWeight': json['is_per_weight'],
        'kind': json['kind'],
    };
}

export function PostCostcoProductsRequestCostcoProductToJSON(value?: PostCostcoProductsRequestCostcoProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'name_en': value.nameEn,
        'sku': value.sku,
        'storage_temperature': value.storageTemperature,
        'contents_quantity': value.contentsQuantity,
        'is_per_weight': value.isPerWeight,
        'kind': value.kind,
    };
}

