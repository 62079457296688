/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddCartItemRequestItem
 */
export interface AddCartItemRequestItem {
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequestItem
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof AddCartItemRequestItem
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof AddCartItemRequestItem
     */
    alternativeProductId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddCartItemRequestItem
     */
    removeAlternativeProduct?: boolean;
}

/**
 * Check if a given object implements the AddCartItemRequestItem interface.
 */
export function instanceOfAddCartItemRequestItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function AddCartItemRequestItemFromJSON(json: any): AddCartItemRequestItem {
    return AddCartItemRequestItemFromJSONTyped(json, false);
}

export function AddCartItemRequestItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddCartItemRequestItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'quantity': json['quantity'],
        'alternativeProductId': !exists(json, 'alternative_product_id') ? undefined : json['alternative_product_id'],
        'removeAlternativeProduct': !exists(json, 'remove_alternative_product') ? undefined : json['remove_alternative_product'],
    };
}

export function AddCartItemRequestItemToJSON(value?: AddCartItemRequestItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'quantity': value.quantity,
        'alternative_product_id': value.alternativeProductId,
        'remove_alternative_product': value.removeAlternativeProduct,
    };
}

