/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachableImage } from './AttachableImage';
import {
    AttachableImageFromJSON,
    AttachableImageFromJSONTyped,
    AttachableImageToJSON,
} from './AttachableImage';

/**
 * 
 * @export
 * @interface ProductImageAllOf
 */
export interface ProductImageAllOf {
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImageAllOf
     */
    webp?: AttachableImage;
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImageAllOf
     */
    thumb?: AttachableImage;
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImageAllOf
     */
    thumbWebp?: AttachableImage;
    /**
     * 
     * @type {number}
     * @memberof ProductImageAllOf
     */
    displayPriority: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImageAllOf
     */
    caption?: string;
}

/**
 * Check if a given object implements the ProductImageAllOf interface.
 */
export function instanceOfProductImageAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "displayPriority" in value;

    return isInstance;
}

export function ProductImageAllOfFromJSON(json: any): ProductImageAllOf {
    return ProductImageAllOfFromJSONTyped(json, false);
}

export function ProductImageAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductImageAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'webp': !exists(json, 'webp') ? undefined : AttachableImageFromJSON(json['webp']),
        'thumb': !exists(json, 'thumb') ? undefined : AttachableImageFromJSON(json['thumb']),
        'thumbWebp': !exists(json, 'thumb_webp') ? undefined : AttachableImageFromJSON(json['thumb_webp']),
        'displayPriority': json['display_priority'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
    };
}

export function ProductImageAllOfToJSON(value?: ProductImageAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'webp': AttachableImageToJSON(value.webp),
        'thumb': AttachableImageToJSON(value.thumb),
        'thumb_webp': AttachableImageToJSON(value.thumbWebp),
        'display_priority': value.displayPriority,
        'caption': value.caption,
    };
}

