/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProduct } from './CostcoProduct';
import {
    CostcoProductFromJSON,
    CostcoProductFromJSONTyped,
    CostcoProductToJSON,
} from './CostcoProduct';

/**
 * 
 * @export
 * @interface GetCostcoProducts200ResponseAllOfData
 */
export interface GetCostcoProducts200ResponseAllOfData {
    /**
     * 
     * @type {Array<CostcoProduct>}
     * @memberof GetCostcoProducts200ResponseAllOfData
     */
    costcoProducts: Array<CostcoProduct>;
}

/**
 * Check if a given object implements the GetCostcoProducts200ResponseAllOfData interface.
 */
export function instanceOfGetCostcoProducts200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProducts" in value;

    return isInstance;
}

export function GetCostcoProducts200ResponseAllOfDataFromJSON(json: any): GetCostcoProducts200ResponseAllOfData {
    return GetCostcoProducts200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCostcoProducts200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProducts200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProducts': ((json['costco_products'] as Array<any>).map(CostcoProductFromJSON)),
    };
}

export function GetCostcoProducts200ResponseAllOfDataToJSON(value?: GetCostcoProducts200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_products': ((value.costcoProducts as Array<any>).map(CostcoProductToJSON)),
    };
}

