import { useCallback } from "react";
import { FcClock } from "react-icons/fc";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { formatDateTime } from "shared/helpers";
import { Sale } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ProductCard } from "~/features/products";
import { useSaleProducts } from "~/features/sales";

type Props = {
  sale: Sale;
};

export const SaleShowPage: BFC<Props> = ({
  sale,
}) => {
  const { products, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useSaleProducts(sale.id);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white p-3">
        <div className="flex flex-wrap items-center">
          <div className="flex gap-1 mr-auto">
            <FcClock size={20} />
            <h2 className="font-bold text-lg">{sale.name}開催中</h2>
          </div>
          <div className="text-xs bg-primary rounded-full text-white py-1 px-2 shrink-0 ml-auto">{formatDateTime(sale.endAt)}まで</div>
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="mt-3 grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-4">
            {products.map((product) => (
              <Link key={product.id} href={routes.PRODUCTS_SHOW(product.id)} noDecoration>
                <ProductCard
                  product={product}
                />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
        {(isLoading || isFetchingNextPage) && (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  );
};
