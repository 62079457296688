import { forwardRef, ReactNode, Ref } from "react";
import { useTranslation } from "react-i18next";

import { CheckboxInput } from "../CheckboxInput";

export type CheckboxInputGroupProps = {
  label?: ReactNode;
  note?: string;
  renderItem?: (
    props: React.InputHTMLAttributes<HTMLInputElement>
  ) => ReactNode;
  error?: string;
  invalid?: boolean;
  inputLabel?: ReactNode;
  inputClassName?: string;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInputGroup = forwardRef(function CheckboxInputGroup({
  label,
  note,
  renderItem,
  error,
  invalid,
  inputLabel,
  inputClassName,
  className,
  ...props
}: CheckboxInputGroupProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={className}>
      {label && (
        <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      )}
      <div className="flex gap-x-4 gap-y-3 flex-wrap">
        {renderItem ? renderItem(props) : (
          <CheckboxInput
            {...props}
            label={inputLabel}
            className={inputClassName}
          />
        )}
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-600">
          {t(error, { ns: "validation" })}
        </div>
      )}
      {note && (
        <div className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
});
