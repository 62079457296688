/* tslint:disable */
/* eslint-disable */
/**
 * Common
 * 共通API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoStore } from './CostcoStore';
import {
    CostcoStoreFromJSON,
    CostcoStoreFromJSONTyped,
    CostcoStoreToJSON,
} from './CostcoStore';

/**
 * 
 * @export
 * @interface GetCostcoStores200ResponseAllOfData
 */
export interface GetCostcoStores200ResponseAllOfData {
    /**
     * 
     * @type {Array<CostcoStore>}
     * @memberof GetCostcoStores200ResponseAllOfData
     */
    costcoStores: Array<CostcoStore>;
}

/**
 * Check if a given object implements the GetCostcoStores200ResponseAllOfData interface.
 */
export function instanceOfGetCostcoStores200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoStores" in value;

    return isInstance;
}

export function GetCostcoStores200ResponseAllOfDataFromJSON(json: any): GetCostcoStores200ResponseAllOfData {
    return GetCostcoStores200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCostcoStores200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoStores200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoStores': ((json['costco_stores'] as Array<any>).map(CostcoStoreFromJSON)),
    };
}

export function GetCostcoStores200ResponseAllOfDataToJSON(value?: GetCostcoStores200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_stores': ((value.costcoStores as Array<any>).map(CostcoStoreToJSON)),
    };
}

