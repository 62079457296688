import { produce } from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { Cart, convertShopifyCartGID, convertShopifyProductGID, convertToShopifyCartGID } from "shared/models";

import { getShopifyGraphqlClient } from "~/services/shopify";

import { useGetCartQuery, GetCartQuery } from "../graphql/GetCartQuery";

const { persistAtom } = recoilPersist();

const cartIdState = atom<string>({
  key: "carts/useCart/cartId",
  default: "",
  effects: [persistAtom],
});

export const useShopifyCart = () => {
  const [cartId, setCartId] = useRecoilState(cartIdState);
  const [cart, setCart] = useState(new Cart());
  const cartRef = useRef<Cart>();

  const client = getShopifyGraphqlClient();
  const { isLoading } = useGetCartQuery(client, { id: convertToShopifyCartGID(cartId) }, {
    enabled: !!cartId,
    onSuccess: async ({ cart: c }) => {
      if (c) {
        setCart((current) => produce(current, (draft) => {
          updateCart(draft, c);
        }));
      }
    },
  });

  const waitCartReady = useCallback(() => {
    return new Promise<Cart>((resolve) => {
      setTimeout(function waiter() {
        if (cartRef.current?.id) {
          resolve(cartRef.current);
        } else {
          setTimeout(waiter, 100);
        }
      });
    });
  }, [cartRef]);

  useEffect(() => {
    cartRef.current = cart;
  }, [cart]);

  return {
    cart,
    waitCartReady,
    cartId,
    setCartId,
    isLoading: cartId && isLoading,
  };
};

function updateCart(draft: Cart, cart: NonNullable<Required<GetCartQuery>["cart"]>) {
  draft.id = convertShopifyCartGID(cart.id);
  draft.items = cart.lines.edges.map(({ node }) => ({
    product: {
      id: convertShopifyProductGID(node.merchandise.product.id),
      title: node.merchandise.product.title,
      shopifyProductId: convertShopifyProductGID(node.merchandise.product.id),
      price: parseInt(node.merchandise.product.priceRange.minVariantPrice.amount),
      description: node.merchandise.product.description,
      descriptionHtml: node.merchandise.product.descriptionHtml,
      taxPercentage: 10,
      storageTemperature: "normal",
      isAlcoholic: false,
      expirationDays: 0,
      status: "active",
      isFavorite: false,
    },
    quantity: node.quantity,
  }));
}
