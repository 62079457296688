import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { ProductType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useProduct } from "~/features/products";

import { ReviewData, ReviewForm } from "../../forms";
import { useCreateReview } from "../../hooks";
import { ReviewConfirm } from "./ReviewConfirm";

const FormState = {
  Form: "Form",
  Confirm: "Confirm",
  Error: "Error",
} as const;

type FormState = typeof FormState[keyof typeof FormState];

type Props = {
  product: ProductType;
};

export const ReviewNewPage: BFC<Props> = ({ product: defaultProduct }) => {
  const productId = defaultProduct.id;
  const { product } = useProduct(productId, defaultProduct);

  const [formState, setFormState] = useState<FormState>(FormState.Form);
  const [submittedReviewData, setSubmittedReviewData] =
    useState<ReviewData | null>(null);

  // 確認画面で投稿ボタンを押したときレビューを投稿し、
  // 投稿に成功したら商品詳細ページに遷移する
  const router = useRouter();
  const { create, isLoading } = useCreateReview({
    productId,
  });
  const handleConfirm = useCallback(async () => {
    if (!submittedReviewData) return;
    setFormState(FormState.Confirm);

    try {
      await create({
        review: {
          title: submittedReviewData.review.title,
          body: submittedReviewData.review.body,
          files:
            submittedReviewData.review.files?.map(
              (file) => file.blob.signed_id
            ) || [],
        },
      });

      toast.success(
        "レビューを投稿しました。\nご投稿いただいたレビューは事務局が確認のうえ、掲載されましたらメールにてお知らせいたします。",
        {
          duration: 10_000,
        }
      );

      await router.push(routes.PRODUCTS_SHOW(productId));
    } catch (error) {
      console.log(error);
      setFormState(FormState.Error);
    }
  }, [create, productId, router, submittedReviewData]);

  // 確認画面でキャンセルボタンを押したとき、フォームに戻る
  const handleCancel = useCallback(() => {
    setFormState(FormState.Form);
  }, [setFormState]);

  // フォームで確認ボタンを押したとき、確認画面に遷移する
  const handleFormSubmit = useCallback(
    async (data: ReviewData) => {
      setSubmittedReviewData(data);
      setFormState(FormState.Confirm);
    },
    [setSubmittedReviewData, setFormState]
  );

  return (
    <div className="flex flex-col gap-4">
      <Link href={routes.PRODUCTS_SHOW(productId)}>
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg bg-white">
          <HiOutlineChevronLeft size={24} />
          レビューを投稿
        </h1>
      </Link>

      <div className="bg-white p-6 sm:px-10 flex flex-col gap-6">
        <div className="p-4 space-y-4 bg-white rounded border border-gray-300 shadow-sm">
          <h2 className="font-bold">レビュー対象商品</h2>
          <div className="flex items-center gap-4">
            {product.image && (
              <img
                src={product.image.url}
                alt={product.title}
                className="h-20 rounded-md"
              />
            )}
            <div className="flex flex-col gap-1">
              <span className="font-bold">{product.title}</span>
              <span className="text-gray-600">{product.description}</span>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <p className="leading-6 text-black">
            商品に関するご意見やご感想をお寄せください。
            <br />
            ご投稿いただいたレビューは、事務局が確認のうえ掲載させていただきます。
            <br />
            商品について批判的な内容でも問題ありません。どうぞご遠慮なくお知らせください。
          </p>

          {(formState === FormState.Confirm || formState === FormState.Error) && submittedReviewData ? (
            <ReviewConfirm
              reviewData={submittedReviewData}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              isSubmitting={isLoading}
              failed={formState === FormState.Error}
            />
          ) : (
            <ReviewForm
              onSubmit={handleFormSubmit}
              defaultValues={submittedReviewData || undefined}
            />
          )}
        </div>
      </div>
    </div>
  );
};
