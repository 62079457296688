/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * トークン
 * @export
 * @interface MultipassToken
 */
export interface MultipassToken {
    /**
     * 
     * @type {string}
     * @memberof MultipassToken
     */
    token: string;
}

/**
 * Check if a given object implements the MultipassToken interface.
 */
export function instanceOfMultipassToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function MultipassTokenFromJSON(json: any): MultipassToken {
    return MultipassTokenFromJSONTyped(json, false);
}

export function MultipassTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultipassToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function MultipassTokenToJSON(value?: MultipassToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
    };
}

