import { PhotoIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { HiCheck, HiOutlineShoppingCart } from "react-icons/hi2";

import { Button } from "shared/components";
import { ProductStorageTemperature } from "shared/models";
import { BFC } from "shared/types";

import { AddToCartButtonState } from "~/features/carts";

import { ProductPrice } from "../ProductPrice";
import { StorageTemperatureBadge } from "../StorageTemperatureBadge";

type Props = {
  title: string;
  image?: string;
  price: number;
  discountPrice?: number;
  taxPercentage?: number;
  storageTemperature?: ProductStorageTemperature;
  addToCartButtonState: AddToCartButtonState;
  onAddToCartButtonClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ProductCardRaw: BFC<Props> = ({
  title,
  image,
  price,
  discountPrice,
  taxPercentage,
  storageTemperature,
  addToCartButtonState,
  onAddToCartButtonClick,
  className,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-1 justify-between h-full",
        className
      )}
    >
      <div>
        <div className="relative">
          {image ? (
            <img
              src={image}
              className="border rounded aspect-square w-full object-cover"
            />
          ) : (
            <div className="border rounded flex justify-center items-center aspect-square">
              <PhotoIcon className="text-black-200 w-8" />
            </div>
          )}
          {addToCartButtonState === AddToCartButtonState.Disabled && (
            <div className="absolute inset-0 bg-gray-300/70" />
          )}
        </div>
        <h3 className="text-sm mt-1 text-left line-clamp-2">
          {storageTemperature && <StorageTemperatureBadge storageTemperature={storageTemperature} className="mr-1" small />}
          {title}
        </h3>
      </div>
      <ProductPrice
        price={price}
        discountPrice={discountPrice}
        taxPercentage={taxPercentage}
        className="text-right font-bold"
      />
      {addToCartButtonState === AddToCartButtonState.Disabled ? (
        <Button
          disabled
          className="justify-center items-center gap-1"
        >
          販売を終了しました
        </Button>
      ) : (
        <Button
          onClick={onAddToCartButtonClick}
          disabled={addToCartButtonState === AddToCartButtonState.Loading}
          primary={addToCartButtonState === AddToCartButtonState.Success}
          className="justify-center items-center gap-1 transition-colors duration-300"
        >
          {addToCartButtonState === AddToCartButtonState.Success ? (
            <>
              <HiCheck size={20} className="text-white" />
              <span className="sr-only">カートに追加しました</span>
            </>
          ) : (
            <>
              <HiOutlineShoppingCart size={20} className="text-gray-500" />
              <span className="sr-only">カートに追加</span>
            </>
          )}
        </Button>
      )}
    </div>
  );
};
