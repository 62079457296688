/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUserAddresses200ResponseAllOfData } from './GetUserAddresses200ResponseAllOfData';
import {
    GetUserAddresses200ResponseAllOfDataFromJSON,
    GetUserAddresses200ResponseAllOfDataFromJSONTyped,
    GetUserAddresses200ResponseAllOfDataToJSON,
} from './GetUserAddresses200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetUserAddresses200ResponseAllOf
 */
export interface GetUserAddresses200ResponseAllOf {
    /**
     * 
     * @type {GetUserAddresses200ResponseAllOfData}
     * @memberof GetUserAddresses200ResponseAllOf
     */
    data: GetUserAddresses200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetUserAddresses200ResponseAllOf interface.
 */
export function instanceOfGetUserAddresses200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetUserAddresses200ResponseAllOfFromJSON(json: any): GetUserAddresses200ResponseAllOf {
    return GetUserAddresses200ResponseAllOfFromJSONTyped(json, false);
}

export function GetUserAddresses200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserAddresses200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetUserAddresses200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetUserAddresses200ResponseAllOfToJSON(value?: GetUserAddresses200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetUserAddresses200ResponseAllOfDataToJSON(value.data),
    };
}

