import { immerable } from "immer";

import { ImageType } from "./image";

export type ProductVariantType = {
  id: string;
  title: string;
  image: Partial<ImageType> | null;
  price: number;
};

export class ProductVariant implements ProductVariantType {
  [immerable] = true;

  id = "";
  title = "";
  image: Partial<ImageType> | null = null;
  price = 0;

  constructor(data: Partial<ProductVariantType> = {}) {
    Object.assign(this, data);
  }

  get gid() {
    return `gid://shopify/ProductVariant/${this.id}`;
  }
}

export const convertShopifyProductVariantGID = (gid: string) => {
  return gid.replace("gid://shopify/ProductVariant/", "");
};
