import { z } from "zod";

import {
  Button,
  TextControlGroup,
  HiddenControl,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    resetPasswordToken: z.string().min(1),
    password: z.string().min(1).min(8, "最低8文字で入力してください"),
    passwordConfirmation: z.string().min(1),
  }).refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致しません",
      path: ["passwordConfirmation"],
    },
  ),
});

export type PasswordEditData = z.infer<typeof schema>;

type Props = {
  resetPasswordToken: string;
}

export const PasswordEditForm = createHookForm<PasswordEditData, Props>(({
  resetPasswordToken,
  formState: { isSubmitting },
}) => {
  return (
    <div className="flex flex-col gap-4">
      <HiddenControl name="user.resetPasswordToken" value={resetPasswordToken} />
      <TextControlGroup name="user.password" type="password" label="パスワード" required />
      <TextControlGroup name="user.passwordConfirmation" type="password" label="パスワード(確認)" required />
      <Button type="submit" block primary large disabled={isSubmitting}>
        送信
      </Button>
    </div>
  );
}, {
  schema,
});
