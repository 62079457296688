/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetSale200ResponseAllOfData } from './GetSale200ResponseAllOfData';
import {
    GetSale200ResponseAllOfDataFromJSON,
    GetSale200ResponseAllOfDataFromJSONTyped,
    GetSale200ResponseAllOfDataToJSON,
} from './GetSale200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetSale200ResponseAllOf
 */
export interface GetSale200ResponseAllOf {
    /**
     * 
     * @type {GetSale200ResponseAllOfData}
     * @memberof GetSale200ResponseAllOf
     */
    data: GetSale200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetSale200ResponseAllOf interface.
 */
export function instanceOfGetSale200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetSale200ResponseAllOfFromJSON(json: any): GetSale200ResponseAllOf {
    return GetSale200ResponseAllOfFromJSONTyped(json, false);
}

export function GetSale200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSale200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetSale200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetSale200ResponseAllOfToJSON(value?: GetSale200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetSale200ResponseAllOfDataToJSON(value.data),
    };
}

