import classNames from "classnames";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ProductCardWide, SoctocProductProvider } from "~/features/products";

type Props = {
  id: string;
};

export const ArticleProductLinkBlock: BFC<Props> = ({
  id,
  className,
}) => {
  return (
    <SoctocProductProvider id={id}>
      {(product) => (
        <Link href={routes.PRODUCTS_SHOW(product.id)}>
          <ProductCardWide
            product={product}
            showDescription
            className={classNames("border rounded p-3", className)}
          />
        </Link>
      )}
    </SoctocProductProvider>
  );
};
