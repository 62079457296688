/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponCode } from './CouponCode';
import {
    CouponCodeFromJSON,
    CouponCodeFromJSONTyped,
    CouponCodeToJSON,
} from './CouponCode';

/**
 * 
 * @export
 * @interface CheckoutBilling
 */
export interface CheckoutBilling {
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    subtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    serviceCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    smallCheckoutCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    redeliveryCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    totalShippingRate: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    discountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    manualDiscountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBilling
     */
    totalPrice: number;
    /**
     * 
     * @type {CouponCode}
     * @memberof CheckoutBilling
     */
    couponCode?: CouponCode;
    /**
     * 
     * @type {Date}
     * @memberof CheckoutBilling
     */
    billedAt?: Date;
}

/**
 * Check if a given object implements the CheckoutBilling interface.
 */
export function instanceOfCheckoutBilling(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subtotalPrice" in value;
    isInstance = isInstance && "serviceCommission" in value;
    isInstance = isInstance && "smallCheckoutCommission" in value;
    isInstance = isInstance && "redeliveryCommission" in value;
    isInstance = isInstance && "totalShippingRate" in value;
    isInstance = isInstance && "totalTax" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "manualDiscountPrice" in value;
    isInstance = isInstance && "totalPrice" in value;

    return isInstance;
}

export function CheckoutBillingFromJSON(json: any): CheckoutBilling {
    return CheckoutBillingFromJSONTyped(json, false);
}

export function CheckoutBillingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutBilling {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subtotalPrice': json['subtotal_price'],
        'serviceCommission': json['service_commission'],
        'smallCheckoutCommission': json['small_checkout_commission'],
        'redeliveryCommission': json['redelivery_commission'],
        'totalShippingRate': json['total_shipping_rate'],
        'totalTax': json['total_tax'],
        'discountPrice': json['discount_price'],
        'manualDiscountPrice': json['manual_discount_price'],
        'totalPrice': json['total_price'],
        'couponCode': !exists(json, 'coupon_code') ? undefined : CouponCodeFromJSON(json['coupon_code']),
        'billedAt': !exists(json, 'billed_at') ? undefined : (new Date(json['billed_at'])),
    };
}

export function CheckoutBillingToJSON(value?: CheckoutBilling | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subtotal_price': value.subtotalPrice,
        'service_commission': value.serviceCommission,
        'small_checkout_commission': value.smallCheckoutCommission,
        'redelivery_commission': value.redeliveryCommission,
        'total_shipping_rate': value.totalShippingRate,
        'total_tax': value.totalTax,
        'discount_price': value.discountPrice,
        'manual_discount_price': value.manualDiscountPrice,
        'total_price': value.totalPrice,
        'coupon_code': CouponCodeToJSON(value.couponCode),
        'billed_at': value.billedAt === undefined ? undefined : (value.billedAt.toISOString()),
    };
}

