/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail
 */
export interface SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail {
    /**
     * 
     * @type {string}
     * @memberof SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail
     */
    parcelId: string;
}

/**
 * Check if a given object implements the SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail interface.
 */
export function instanceOfSendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parcelId" in value;

    return isInstance;
}

export function SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMailFromJSON(json: any): SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail {
    return SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMailFromJSONTyped(json, false);
}

export function SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parcelId': json['parcel_id'],
    };
}

export function SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMailToJSON(value?: SendCheckoutFullfillmentShippingMailRequestFullfillmentShippingMail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parcel_id': value.parcelId,
    };
}

