import { format } from "date-fns";
import { immerable } from "immer";

import { formatDate } from "../helpers";
import * as api from "../services/api/store/models";
import { Checkout, CheckoutType } from "./checkout";
import { CheckoutItem, CheckoutItemType } from "./checkout_item";
import { DeliveryParcel } from "./delivery_parcel";
import { ShippingRate, ShippingRateType } from "./shipping_rate";
import { ShippingTime, ShippingTimeType } from "./shipping_time";

export type CheckoutParcelType = api.CheckoutParcel;

export const CheckoutParcelStatus = api.CheckoutParcelStatusEnum;
export type CheckoutParcelStatus = typeof CheckoutParcelStatus[keyof typeof CheckoutParcelStatus];

export const CheckoutParcelStockingStatus = api.CheckoutParcelStockingStatusEnum;
export type CheckoutParcelStockingStatus = typeof CheckoutParcelStockingStatus[keyof typeof CheckoutParcelStockingStatus];

export const CheckoutParcelShippingStatus = api.CheckoutParcelShippingStatusEnum;
export type CheckoutParcelShippingStatus = typeof CheckoutParcelShippingStatus[keyof typeof CheckoutParcelShippingStatus];

export const CheckoutParcelShippingMailStatus = api.CheckoutParcelShippingMailStatusEnum;
export type CheckoutParcelShippingMailStatus = typeof CheckoutParcelShippingMailStatus[keyof typeof CheckoutParcelShippingMailStatus];

export const CheckoutParcelDeliveryStatus = api.CheckoutParcelDeliveryStatusEnum;
export type CheckoutParcelDeliveryStatus = typeof CheckoutParcelDeliveryStatus[keyof typeof CheckoutParcelDeliveryStatus];

export const CheckoutParcelDeliveryFailedReason = api.CheckoutParcelDeliveryFailedReasonEnum;
export type CheckoutParcelDeliveryFailedReason = typeof CheckoutParcelDeliveryFailedReason[keyof typeof CheckoutParcelDeliveryFailedReason];

export class CheckoutParcel implements CheckoutParcelType {
  [immerable] = true;

  id = "";
  checkout?: CheckoutType;
  shippingRate: ShippingRateType = new ShippingRate();
  shippingTime: ShippingTimeType = new ShippingTime();
  shippingDate: Date = new Date();
  items: CheckoutItemType[] = [];
  status: CheckoutParcelStatus = CheckoutParcelStatus.Waiting;
  stockingStatus: CheckoutParcelStockingStatus = CheckoutParcelStockingStatus.Waiting;
  shippingStatus: CheckoutParcelShippingStatus = CheckoutParcelShippingStatus.Waiting;
  shippingMailStatus: CheckoutParcelShippingMailStatus = CheckoutParcelShippingMailStatus.Waiting;
  deliveryStatus: CheckoutParcelDeliveryStatus = CheckoutParcelDeliveryStatus.Waiting;
  deliveryFailedReason: CheckoutParcelDeliveryFailedReason = CheckoutParcelDeliveryFailedReason.None;
  redeliveryDate?: Date;
  redeliveryTime?: string;

  constructor(data: Partial<CheckoutParcelType> = {}) {
    Object.assign(this, data);
  }

  formatShippingDate(full = false) {
    return formatDate(this.shippingDate, full ? "yyyy年MM月dd日 HH:mm" : "MM月dd日 HH:mm");
  }

  getCheckout() {
    return new Checkout(this.checkout);
  }

  getShippingRate() {
    return new ShippingRate(this.shippingRate);
  }

  getShippingTime() {
    return new ShippingTime(this.shippingTime);
  }

  getItems() {
    return this.items.map((item) => new CheckoutItem(item));
  }

  getSortedByStorageTemperatureItems() {
    return this.items.map((item) => new CheckoutItem(item))
      .sort((a, b) => a.getProduct().compareByStorageTemperature(b.getProduct()));
  }

  isStockingWaiting() {
    return this.stockingStatus === CheckoutParcelStockingStatus.Waiting;
  }

  isStockingCompleted() {
    return this.stockingStatus === CheckoutParcelStockingStatus.Completed
      || this.stockingStatus === CheckoutParcelStockingStatus.CompletedWithPartially;
  }

  isStockingCanceled() {
    return this.stockingStatus === CheckoutParcelStockingStatus.Canceled;
  }

  isShippingWaiting() {
    return this.shippingStatus === CheckoutParcelShippingStatus.Waiting;
  }

  isShippingCompleted() {
    return this.shippingStatus === CheckoutParcelShippingStatus.Completed
      || this.shippingStatus === CheckoutParcelShippingStatus.CompletedWithPartially;
  }

  isShippingCanceled() {
    return this.shippingStatus === CheckoutParcelShippingStatus.Canceled;
  }

  isShippingMailCompleted() {
    return this.shippingMailStatus === CheckoutParcelShippingMailStatus.Completed;
  }

  isFixed() {
    return this.isShippingMailCompleted();
  }

  isEditable() {
    return this.isStockingWaiting() && this.isShippingWaiting();
  }

  formatShippingDateTime() {
    return `${format(this.shippingDate, "yyyy年MM月dd日")} ${this.shippingTime.name}`;
  }

  getFixedItems(checkout: Checkout): CheckoutItem[] {
    if (this.isShippingMailCompleted()) {
      if (checkout.fullfillmentShippings) {
        return checkout.fullfillmentShippings.reduce((acc, { items }) => {
          items.forEach((item) => {
            const originalItem = this.items.find((i) => i.product.id === item.product.id);
            if (originalItem) {
              acc.push(new CheckoutItem({
                ...item,
                price: originalItem.price,
                discountPrice: originalItem.discountPrice,
                taxPercentage: originalItem.taxPercentage,
                alternativePrice: originalItem.alternativePrice,
                alternativeDiscountPrice: originalItem.alternativeDiscountPrice,
                alternativeTaxPercentage: originalItem.alternativeTaxPercentage,
              }));
            }
          });
          return acc;
        }, [] as CheckoutItem[]);
      } else {
        return [];
      }
    } else {
      return this.getItems();
    }
  }

  toDeliveryParcel() {
    return new DeliveryParcel({
      checkoutParcel: this,
    });
  }
}
