/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpUserRequestUser
 */
export interface SignUpUserRequestUser {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    lastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpUserRequestUser
     */
    emailMarketingAgreed: boolean;
    /**
     * 
     * @type {Date}
     * @memberof SignUpUserRequestUser
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestUser
     */
    zipCode?: string;
}

/**
 * Check if a given object implements the SignUpUserRequestUser interface.
 */
export function instanceOfSignUpUserRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "passwordConfirmation" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "emailMarketingAgreed" in value;

    return isInstance;
}

export function SignUpUserRequestUserFromJSON(json: any): SignUpUserRequestUser {
    return SignUpUserRequestUserFromJSONTyped(json, false);
}

export function SignUpUserRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUserRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'emailMarketingAgreed': json['email_marketing_agreed'],
        'birthDate': !exists(json, 'birth_date') ? undefined : (new Date(json['birth_date'])),
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
    };
}

export function SignUpUserRequestUserToJSON(value?: SignUpUserRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email_marketing_agreed': value.emailMarketingAgreed,
        'birth_date': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0,10)),
        'zip_code': value.zipCode,
    };
}

