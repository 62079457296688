import { useRouter } from "next/router";
import { useEffect } from "react";

import { CFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth/hooks";

export const AuthedRoute: CFC = ({
  children,
}) => {
  const { isSignedIn, isIdle } = useAuthContext();
  const router = useRouter();

  useEffect(() => {
    if (!isIdle && !isSignedIn) {
      router.replace(
        `${routes.USERS_SIGN_IN}?origin=${router.asPath}`,
      );
    }
  }, [isSignedIn, isIdle]);

  if (isIdle || !isSignedIn) {
    return <></>;
  }

  return <>{children}</>;
};
