import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { ShippingTime } from "shared/models";
import { useCommonAPI } from "shared/services/api";

export const useShippingTimes = () => {
  const api = useCommonAPI();
  const [shippingTimes, setShippingTimes] = useState<ShippingTime[]>([]);

  const { data, isLoading } = useQuery(
    ["shippingTimes"],
    () => api.getShippingTimes(),
    {
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (data) {
      setShippingTimes(data.data.shippingTimes.map((d) => new ShippingTime(d)));
    }
  }, [data]);

  return {
    shippingTimes,
    isLoading,
  };
};
