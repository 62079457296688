/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCheckoutRequestParcelsInnerItemsInner } from './CreateCheckoutRequestParcelsInnerItemsInner';
import {
    CreateCheckoutRequestParcelsInnerItemsInnerFromJSON,
    CreateCheckoutRequestParcelsInnerItemsInnerFromJSONTyped,
    CreateCheckoutRequestParcelsInnerItemsInnerToJSON,
} from './CreateCheckoutRequestParcelsInnerItemsInner';

/**
 * 
 * @export
 * @interface CalculateCheckoutRequestParcelsInner
 */
export interface CalculateCheckoutRequestParcelsInner {
    /**
     * 
     * @type {string}
     * @memberof CalculateCheckoutRequestParcelsInner
     */
    shippingRateId?: string;
    /**
     * 
     * @type {Array<CreateCheckoutRequestParcelsInnerItemsInner>}
     * @memberof CalculateCheckoutRequestParcelsInner
     */
    items: Array<CreateCheckoutRequestParcelsInnerItemsInner>;
}

/**
 * Check if a given object implements the CalculateCheckoutRequestParcelsInner interface.
 */
export function instanceOfCalculateCheckoutRequestParcelsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function CalculateCheckoutRequestParcelsInnerFromJSON(json: any): CalculateCheckoutRequestParcelsInner {
    return CalculateCheckoutRequestParcelsInnerFromJSONTyped(json, false);
}

export function CalculateCheckoutRequestParcelsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateCheckoutRequestParcelsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingRateId': !exists(json, 'shipping_rate_id') ? undefined : json['shipping_rate_id'],
        'items': ((json['items'] as Array<any>).map(CreateCheckoutRequestParcelsInnerItemsInnerFromJSON)),
    };
}

export function CalculateCheckoutRequestParcelsInnerToJSON(value?: CalculateCheckoutRequestParcelsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_rate_id': value.shippingRateId,
        'items': ((value.items as Array<any>).map(CreateCheckoutRequestParcelsInnerItemsInnerToJSON)),
    };
}

