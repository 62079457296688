import { useMutation, useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { createAtom } from "shared/lib/recoil";
import { Address } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { CreateUserAddressRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

const addressesState = createAtom<Address[]>([]);
const defaultAddressState = createAtom<Address | null>(null);

export const useAddresses = () => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });
  const [addresses, setAddresses] = useRecoilState(addressesState);
  const [defaultAddress, setDefaultAddress] = useRecoilState(defaultAddressState);

  const { refetch, isLoading } = useQuery(["users/useAddresses"], () => api.getUserAddresses(), {
    onSuccess: ({ data }) => {
      setAddresses(data.userAddresses.map((a) => new Address(a)));
      setDefaultAddress(data.defaultAddress ? new Address(data.defaultAddress) : null);
    },
  });

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["users/useAddresses/create"],
    (params: CreateUserAddressRequest) => api.createUserAddress(params),
    {
      onSuccess: () => {
        refetch();
      }
    },
  );

  return {
    addresses,
    defaultAddress,
    create,
    isLoading: isLoading || isCreateLoading,
    isMutating: isCreateLoading,
  };
};
