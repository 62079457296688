import { useState } from "react";

import { MagazineArticle } from "shared/models";

import { toStrapiImageURL } from "../helpers";
import { StrapiResponse, StrapiPaginationMeta, StrapiArticle, StrapiRequestParameters } from "../types";
import { useStrapiInfiniteQuery } from "./useStrapiQuery";

export const useMagazineArticles = (params?: StrapiRequestParameters<StrapiArticle>) => {
  const [articles, setArticles] = useState<MagazineArticle[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const { data, ...rest } = useStrapiInfiniteQuery<StrapiResponse<StrapiArticle[], StrapiPaginationMeta>>(
    ["magazineArticles", params],
    "/api/articles",
    {
      populate: "*",
      sort: ["publishAt:desc", "createdAt:desc"],
      ...params,
    },
    {
      onSuccess: ({ pages }) => {
        setTotalCount(pages.slice(-1)[0].meta.pagination.total);
        setArticles(pages.flatMap(({ data }) => data).map(({ id, attributes }) => new MagazineArticle({
          id,
          title: attributes.title,
          slug: attributes.slug,
          body: attributes.body,
          cover: attributes.cover ? {
            url: toStrapiImageURL(attributes.cover.data.attributes.url),
            width: attributes.cover.data.attributes.width,
            height: attributes.cover.data.attributes.height,
          } : undefined,
          categories: attributes.categories.data.map(({ id, attributes }) => ({
            id,
            name: attributes.name,
            slug: attributes.slug,
          })),
          tags: attributes.tags.data.map(({ id, attributes }) => ({
            id,
            name: attributes.name,
            slug: attributes.slug,
          })),
          publishAt: attributes.publishAt ? new Date(attributes.publishAt) : new Date(attributes.createdAt),
          createdAt: new Date(attributes.createdAt),
          updatedAt: new Date(attributes.updatedAt),
        })));
      },
    },
  );

  return {
    articles,
    totalCount,
    ...rest,
  };
};
