/**
 * 文字列を指定された文字数で切り取り、末尾に `...` を追加して返す
 * ただし、文字列の長さが指定された文字数以下の場合は `...` を追加せずにそのまま返す
 *
 * @param {string} str - 省略する文字列
 * @param {number} length - 切り取りたい文字数
 * @returns {string} 省略された文字列
 *
 * @example
 * truncate("Hello, world!", 5);  // "Hello..."
 * truncate("Hi!", 10);           // "Hi!"
 */
export const truncate = (str: string, length: number): string => {
  if (str.length <= length) {
    return str;
  }

  return str.substring(0, length) + "...";
};

export function nl2br(str: string): JSX.Element[] {
  return str.split(/\r?\n/g).map((line) => <>{line}<br /></>);
}
