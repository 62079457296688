import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Checkout } from "./checkout";
import { CouponCodeType } from "./coupon_code";

export type CheckoutBillingType = api.CheckoutBilling;

export class CheckoutBilling implements CheckoutBillingType {
  [immerable] = true;

  subtotalPrice = 0;
  serviceCommission = 0;
  smallCheckoutCommission = 0;
  redeliveryCommission = 0;
  redeliveryCommissionRate = 0;
  totalShippingRate = 0;
  discountPrice = 0;
  manualDiscountPrice = 0;
  totalTax = 0;
  totalPrice = 0;
  couponCode: CouponCodeType | undefined = undefined;
  billedAt = new Date();

  constructor(data: Partial<CheckoutBillingType> = {}) {
    Object.assign(this, data);
  }

  toCheckout() {
    return new Checkout(this);
  }
}
