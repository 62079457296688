import { Bars3Icon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useCallback, useRef } from "react";
import { AiOutlineHeart } from "react-icons/ai";
import { HiOutlineSearch } from "react-icons/hi";
import { HiXMark } from "react-icons/hi2";

import { Link } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { getParam } from "shared/lib/next";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { useProductsQueryParams } from "~/features/products";

import { CartLink } from "./CartLink";

type Props = {
  onMenuClick: () => void;
};

export const Header: BFC<Props> = ({
  onMenuClick,
  className,
}) => {
  const router = useRouter();
  const keyword = getParam(router.query, "keyword");
  const { isSignedIn, isIdle } = useAuthContext();
  const [searchShown, showSearch, hideSearch] = useBooleanState(!!keyword);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchIconClick = useCallback(() => {
    showSearch();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  const { parsedQueryParams } = useProductsQueryParams();
  const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      router.push(routes.PRODUCTS_SEARCH(e.currentTarget.value, parsedQueryParams));
    }
  }, [parsedQueryParams]);

  const onBlur = useCallback(() => {
    if (!inputRef.current?.value) {
      hideSearch();
    }
  }, []);

  return (
    <div className={classNames("bg-white flex items-center h-12", className)}>
      <div className="flex flex-1 items-center">
        <div className="py-3 px-3">
          <Link href={routes.TOP_WITH_PARAMS(parsedQueryParams)}>
            <img
              src="/assets/images/logo/logo_primary.png"
              className="h-6 w-auto"
            />
          </Link>
        </div>
        <div className="flex-1 flex items-center pl-3">
          {searchShown && (
            <div className="w-full flex justify-center relative">
              <input
                ref={inputRef}
                type="text"
                placeholder="キーワードで検索"
                enterKeyHint="search"
                defaultValue={keyword}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                className="py-0.5 pl-3 pr-8 w-full border-gray-300 rounded-full focus:ring-0 focus:border-gray-300 max-w-xs"
              />
              <HiXMark size={20} className="absolute right-2 top-1" onClick={hideSearch} />
            </div>
          )}
        </div>
        <div className="flex items-center gap-3 py-3 px-3">
          {!isSignedIn && !isIdle && !searchShown && (
            <Link href={routes.USERS_SIGN_IN} className="text-sm font-bold text-text">
              ログイン
            </Link>
          )}
          {!searchShown && (
            <button onClick={onSearchIconClick} aria-label="検索フォームを開く">
              <HiOutlineSearch size={24} className="text-gray-500" onClick={onSearchIconClick} />
            </button>
          )}
          {isSignedIn && !searchShown && (
            <Link href={routes.FAVORITES}>
              <AiOutlineHeart size={23} className="text-gray-500" />
            </Link>
          )}
          <CartLink />
          <button
            onClick={onMenuClick}
            aria-label="メニューを開く"
            className="text-gray-600 md:hidden">
            <Bars3Icon className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};
