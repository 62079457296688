/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface DeleteUserAddress200ResponseAllOfData
 */
export interface DeleteUserAddress200ResponseAllOfData {
    /**
     * 
     * @type {Address}
     * @memberof DeleteUserAddress200ResponseAllOfData
     */
    defaultAddress?: Address;
}

/**
 * Check if a given object implements the DeleteUserAddress200ResponseAllOfData interface.
 */
export function instanceOfDeleteUserAddress200ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteUserAddress200ResponseAllOfDataFromJSON(json: any): DeleteUserAddress200ResponseAllOfData {
    return DeleteUserAddress200ResponseAllOfDataFromJSONTyped(json, false);
}

export function DeleteUserAddress200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteUserAddress200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'defaultAddress': !exists(json, 'default_address') ? undefined : AddressFromJSON(json['default_address']),
    };
}

export function DeleteUserAddress200ResponseAllOfDataToJSON(value?: DeleteUserAddress200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'default_address': AddressToJSON(value.defaultAddress),
    };
}

