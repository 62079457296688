/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignInUser201ResponseAllOfData } from './SignInUser201ResponseAllOfData';
import {
    SignInUser201ResponseAllOfDataFromJSON,
    SignInUser201ResponseAllOfDataFromJSONTyped,
    SignInUser201ResponseAllOfDataToJSON,
} from './SignInUser201ResponseAllOfData';

/**
 * 
 * @export
 * @interface SignInUser201Response
 */
export interface SignInUser201Response {
    /**
     * 
     * @type {SignInUser201ResponseAllOfData}
     * @memberof SignInUser201Response
     */
    data: SignInUser201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof SignInUser201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the SignInUser201Response interface.
 */
export function instanceOfSignInUser201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function SignInUser201ResponseFromJSON(json: any): SignInUser201Response {
    return SignInUser201ResponseFromJSONTyped(json, false);
}

export function SignInUser201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInUser201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SignInUser201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function SignInUser201ResponseToJSON(value?: SignInUser201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SignInUser201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

