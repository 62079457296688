/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteUserRequest
 */
export interface DeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteUserRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteUserRequest
     */
    reason: DeleteUserRequestReasonEnum;
}


/**
 * @export
 */
export const DeleteUserRequestReasonEnum = {
    HighCommissions: 'high_commissions',
    NotFoundWanted: 'not_found_wanted',
    NotCoveredArea: 'not_covered_area',
    No: 'no',
    Other: 'other'
} as const;
export type DeleteUserRequestReasonEnum = typeof DeleteUserRequestReasonEnum[keyof typeof DeleteUserRequestReasonEnum];


/**
 * Check if a given object implements the DeleteUserRequest interface.
 */
export function instanceOfDeleteUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reason" in value;

    return isInstance;
}

export function DeleteUserRequestFromJSON(json: any): DeleteUserRequest {
    return DeleteUserRequestFromJSONTyped(json, false);
}

export function DeleteUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reason': json['reason'],
    };
}

export function DeleteUserRequestToJSON(value?: DeleteUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'reason': value.reason,
    };
}

