/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUserPaymentMethod201ResponseAllOfData } from './CreateUserPaymentMethod201ResponseAllOfData';
import {
    CreateUserPaymentMethod201ResponseAllOfDataFromJSON,
    CreateUserPaymentMethod201ResponseAllOfDataFromJSONTyped,
    CreateUserPaymentMethod201ResponseAllOfDataToJSON,
} from './CreateUserPaymentMethod201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateUserPaymentMethod201ResponseAllOf
 */
export interface CreateUserPaymentMethod201ResponseAllOf {
    /**
     * 
     * @type {CreateUserPaymentMethod201ResponseAllOfData}
     * @memberof CreateUserPaymentMethod201ResponseAllOf
     */
    data: CreateUserPaymentMethod201ResponseAllOfData;
}

/**
 * Check if a given object implements the CreateUserPaymentMethod201ResponseAllOf interface.
 */
export function instanceOfCreateUserPaymentMethod201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CreateUserPaymentMethod201ResponseAllOfFromJSON(json: any): CreateUserPaymentMethod201ResponseAllOf {
    return CreateUserPaymentMethod201ResponseAllOfFromJSONTyped(json, false);
}

export function CreateUserPaymentMethod201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserPaymentMethod201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateUserPaymentMethod201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CreateUserPaymentMethod201ResponseAllOfToJSON(value?: CreateUserPaymentMethod201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateUserPaymentMethod201ResponseAllOfDataToJSON(value.data),
    };
}

