/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Favorite } from './Favorite';
import {
    FavoriteFromJSON,
    FavoriteFromJSONTyped,
    FavoriteToJSON,
} from './Favorite';

/**
 * 
 * @export
 * @interface GetFavorites200ResponseAllOfData
 */
export interface GetFavorites200ResponseAllOfData {
    /**
     * お気に入り登録されたアイテムのリスト。お気に入り登録した時刻降順（新しい順）にソートされている
     * @type {Array<Favorite>}
     * @memberof GetFavorites200ResponseAllOfData
     */
    favorites: Array<Favorite>;
}

/**
 * Check if a given object implements the GetFavorites200ResponseAllOfData interface.
 */
export function instanceOfGetFavorites200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "favorites" in value;

    return isInstance;
}

export function GetFavorites200ResponseAllOfDataFromJSON(json: any): GetFavorites200ResponseAllOfData {
    return GetFavorites200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetFavorites200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFavorites200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favorites': ((json['favorites'] as Array<any>).map(FavoriteFromJSON)),
    };
}

export function GetFavorites200ResponseAllOfDataToJSON(value?: GetFavorites200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favorites': ((value.favorites as Array<any>).map(FavoriteToJSON)),
    };
}

