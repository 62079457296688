import classNames from "classnames";
import { useCallback } from "react";
import { HiOutlineChevronDown } from "react-icons/hi2";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { Product } from "shared/models";
import { BFC } from "shared/types";

import { AlternativeProductSelectorModal } from "../AlternativeProductSelectorModal";
import { ProductPrice } from "../ProductPrice";

type Props = {
  selected: Product | null;
  products: Product[];
  onSelect?: (product: Product | null) => void;
};

export const AlternativeProductSelector: BFC<Props> = ({
  selected,
  products,
  onSelect,
  className,
}) => {
  const [shownModal, showModal, hideModal] = useBooleanState();
  const onSelectHandler = useCallback((product: Product | null) => {
    if (onSelect) {
      onSelect(product);
    }
  }, [onSelect]);

  if (products.length === 0) {
    return null;
  }

  return (
    <div className={classNames(className)}>
      {selected ? (
        <Button block onClick={showModal}>
          <div className="flex items-center justify-between gap-3 text-left w-full">
            <div className="flex flex-col gap-1">
              <div className="text-black-400 text-2xs">指定中の代替品</div>
              <div className="flex gap-3 items-start">
                <img
                  src={selected.image?.webp?.url ?? selected.image?.url}
                  className="border rounded aspect-square object-cover w-10 shrink-0"
                />
                <div>
                  {selected.title}
                  <ProductPrice
                    price={selected.price}
                    discountPrice={selected.discountPrice}
                    taxPercentage={selected.taxPercentage}
                    className="font-bold"
                  />
                </div>
              </div>
            </div>
            <div className="shrink-0">
              <HiOutlineChevronDown className="text-black-400" size={18} />
            </div>
          </div>
        </Button>
      ) : (
        <Button block large onClick={showModal}>
          代替品を希望する
        </Button>
      )}
      <AlternativeProductSelectorModal
        show={shownModal}
        selected={selected}
        products={products}
        onSelect={onSelectHandler}
        onClose={hideModal}
      />
    </div>
  );
};
