import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  code: string;
};

export const ArticleCodeBlock: BFC<Props> = ({
  code,
  className,
}) => {
  return (
    <code className={classNames("border rounded p-4 bg-gray-100 text-black-500", className)}>
      {code}
    </code>
  );
};
