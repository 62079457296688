/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseSuccessWithPagingAllOfMeta } from './ResponseSuccessWithPagingAllOfMeta';
import {
    ResponseSuccessWithPagingAllOfMetaFromJSON,
    ResponseSuccessWithPagingAllOfMetaFromJSONTyped,
    ResponseSuccessWithPagingAllOfMetaToJSON,
} from './ResponseSuccessWithPagingAllOfMeta';

/**
 * 
 * @export
 * @interface ResponseSuccessWithPagingAllOf
 */
export interface ResponseSuccessWithPagingAllOf {
    /**
     * 
     * @type {ResponseSuccessWithPagingAllOfMeta}
     * @memberof ResponseSuccessWithPagingAllOf
     */
    meta: ResponseSuccessWithPagingAllOfMeta;
}

/**
 * Check if a given object implements the ResponseSuccessWithPagingAllOf interface.
 */
export function instanceOfResponseSuccessWithPagingAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ResponseSuccessWithPagingAllOfFromJSON(json: any): ResponseSuccessWithPagingAllOf {
    return ResponseSuccessWithPagingAllOfFromJSONTyped(json, false);
}

export function ResponseSuccessWithPagingAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseSuccessWithPagingAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': ResponseSuccessWithPagingAllOfMetaFromJSON(json['meta']),
    };
}

export function ResponseSuccessWithPagingAllOfToJSON(value?: ResponseSuccessWithPagingAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': ResponseSuccessWithPagingAllOfMetaToJSON(value.meta),
    };
}

