// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { CartFragmentFragmentDoc } from './CartFragment';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetCartQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetCartQuery = (
  { __typename?: 'QueryRoot' }
  & { cart?: Types.Maybe<(
    { __typename?: 'Cart' }
    & Pick<Types.Cart, 'id' | 'checkoutUrl' | 'totalQuantity'>
    & { cost: (
      { __typename?: 'CartCost' }
      & { totalAmount: (
        { __typename?: 'MoneyV2' }
        & Pick<Types.MoneyV2, 'amount'>
      ) }
    ), buyerIdentity: (
      { __typename?: 'CartBuyerIdentity' }
      & Pick<Types.CartBuyerIdentity, 'email' | 'phone'>
      & { customer?: Types.Maybe<(
        { __typename?: 'Customer' }
        & Pick<Types.Customer, 'id' | 'email' | 'firstName' | 'lastName' | 'displayName'>
      )> }
    ), lines: (
      { __typename?: 'CartLineConnection' }
      & { edges: Array<(
        { __typename?: 'CartLineEdge' }
        & { node: (
          { __typename?: 'CartLine' }
          & Pick<Types.CartLine, 'id' | 'quantity'>
          & { merchandise: (
            { __typename?: 'ProductVariant' }
            & Pick<Types.ProductVariant, 'id' | 'title'>
            & { image?: Types.Maybe<(
              { __typename?: 'Image' }
              & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
            )>, price: (
              { __typename?: 'MoneyV2' }
              & Pick<Types.MoneyV2, 'amount'>
            ), product: (
              { __typename?: 'Product' }
              & Pick<Types.Product, 'id' | 'title' | 'description' | 'descriptionHtml'>
              & { featuredImage?: Types.Maybe<(
                { __typename?: 'Image' }
                & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
              )>, priceRange: (
                { __typename?: 'ProductPriceRange' }
                & { minVariantPrice: (
                  { __typename?: 'MoneyV2' }
                  & Pick<Types.MoneyV2, 'amount'>
                ), maxVariantPrice: (
                  { __typename?: 'MoneyV2' }
                  & Pick<Types.MoneyV2, 'amount'>
                ) }
              ) }
            ) }
          ), attributes: Array<(
            { __typename?: 'Attribute' }
            & Pick<Types.Attribute, 'key' | 'value'>
          )>, cost: (
            { __typename?: 'CartLineCost' }
            & { totalAmount: (
              { __typename?: 'MoneyV2' }
              & Pick<Types.MoneyV2, 'amount'>
            ), compareAtAmountPerQuantity?: Types.Maybe<(
              { __typename?: 'MoneyV2' }
              & Pick<Types.MoneyV2, 'amount'>
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);


export const GetCartDocument = `
    query GetCart($id: ID!) {
  cart(id: $id) {
    ...CartFragment
  }
}
    ${CartFragmentFragmentDoc}`;
export const useGetCartQuery = <
      TData = GetCartQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCartQueryVariables,
      options?: UseQueryOptions<GetCartQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCartQuery, TError, TData>(
      ['GetCart', variables],
      fetcher<GetCartQuery, GetCartQueryVariables>(client, GetCartDocument, variables, headers),
      options
    );

useGetCartQuery.getKey = (variables: GetCartQueryVariables) => ['GetCart', variables];
;

export const useInfiniteGetCartQuery = <
      TData = GetCartQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCartQueryVariables,
      client: GraphQLClient,
      variables: GetCartQueryVariables,
      options?: UseInfiniteQueryOptions<GetCartQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCartQuery, TError, TData>(
      ['GetCart.infinite', variables],
      (metaData) => fetcher<GetCartQuery, GetCartQueryVariables>(client, GetCartDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );


useInfiniteGetCartQuery.getKey = (variables: GetCartQueryVariables) => ['GetCart.infinite', variables];
;

useGetCartQuery.fetcher = (client: GraphQLClient, variables: GetCartQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCartQuery, GetCartQueryVariables>(client, GetCartDocument, variables, headers);