import { useRouter } from "next/router";
import { useCallback, useState } from "react";

import { BFC } from "shared/types";

import { routes } from "~/constants";

import { PasswordEditForm, PasswordEditData } from "../../forms";
import { usePasswordReset } from "../../hooks";

type Props = {
  resetPasswordToken: string;
};

export const PasswordEditPage: BFC<Props> = ({
  resetPasswordToken,
}) => {
  const { resetPassword } = usePasswordReset();
  const [errorMessage, setErrorMessage] = useState("");
  const router = useRouter();
  const onSubmit = useCallback((data: PasswordEditData) => {
    resetPassword(data, {
      onSuccess: () => router.push(routes.USERS_SIGN_IN),
      onError: () => setErrorMessage("リンクの有効期限が切れています。もう一度メール送信をやり直してください。"),
    });
  }, []);

  return (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">パスワード再設定</h1>
        </div>
        <p>新しいパスワードを入力してください</p>
        <div className="flex flex-col gap-4">
          <PasswordEditForm resetPasswordToken={resetPasswordToken} onSubmit={onSubmit} />
          {errorMessage && <p className="text-red-600">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};