/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ConsumeStockRequest,
  CreateCheckoutRedelivery201Response,
  CreateCheckoutRedeliveryRequest,
  CreateDeliveryDriver201Response,
  CreateDeliveryDriverRequest,
  CreateDeliveryParcel201Response,
  CreateDeliveryParcelRequest,
  CreateStocks201Response,
  CreateStocksRequest,
  CreateStockup201Response,
  CreateStockupItem201Response,
  CreateStockupItemRequest,
  CreateStockupRequest,
  DeleteCheckoutFullfillmentStocking200Response,
  DeleteStockupItem200Response,
  GetCheckout200Response,
  GetCheckoutFullfillmentShippings200Response,
  GetCheckoutFullfillmentStockings200Response,
  GetCheckoutRedeliveries200Response,
  GetCheckoutTimelines200Response,
  GetCheckouts200Response,
  GetCostcoProduct200Response,
  GetCostcoProducts200Response,
  GetDeliveryDrivers200Response,
  GetDeliveryParcels200Response,
  GetProduct200Response,
  GetStock200Response,
  GetStocks200Response,
  GetStockups200Response,
  GetUserSession200Response,
  OntimeSignInUserRequest,
  PostCheckoutFullfillmentShipping201Response,
  PostCheckoutFullfillmentShippingRequest,
  PostCheckoutFullfillmentStockingRequest,
  RecreateStockups201Response,
  RecreateStockupsRequest,
  ResponseErrorBadRequest,
  ResponseErrorConflict,
  ResponseErrorForbidden,
  ResponseErrorInvalidRecord,
  ResponseErrorNotFound,
  ResponseErrorUnauthorized,
  ResponseSuccess,
  SendCheckoutFullfillmentShippingMailRequest,
  SignInUser201Response,
  SignInUserRequest,
  StockedStockupItemRequest,
  UpdateCheckout200Response,
  UpdateCheckoutRequest,
  UpdateDeliveryDriverParcelsRequest,
  UpdateDeliveryDriverRequest,
  UpdateDeliveryParcelRequest,
  UpdateStockRequest,
  UpdateStockupItemRequest,
  UpdateStockupRequest,
  UpdateStockupsRequest,
} from '../models';
import {
    ConsumeStockRequestFromJSON,
    ConsumeStockRequestToJSON,
    CreateCheckoutRedelivery201ResponseFromJSON,
    CreateCheckoutRedelivery201ResponseToJSON,
    CreateCheckoutRedeliveryRequestFromJSON,
    CreateCheckoutRedeliveryRequestToJSON,
    CreateDeliveryDriver201ResponseFromJSON,
    CreateDeliveryDriver201ResponseToJSON,
    CreateDeliveryDriverRequestFromJSON,
    CreateDeliveryDriverRequestToJSON,
    CreateDeliveryParcel201ResponseFromJSON,
    CreateDeliveryParcel201ResponseToJSON,
    CreateDeliveryParcelRequestFromJSON,
    CreateDeliveryParcelRequestToJSON,
    CreateStocks201ResponseFromJSON,
    CreateStocks201ResponseToJSON,
    CreateStocksRequestFromJSON,
    CreateStocksRequestToJSON,
    CreateStockup201ResponseFromJSON,
    CreateStockup201ResponseToJSON,
    CreateStockupItem201ResponseFromJSON,
    CreateStockupItem201ResponseToJSON,
    CreateStockupItemRequestFromJSON,
    CreateStockupItemRequestToJSON,
    CreateStockupRequestFromJSON,
    CreateStockupRequestToJSON,
    DeleteCheckoutFullfillmentStocking200ResponseFromJSON,
    DeleteCheckoutFullfillmentStocking200ResponseToJSON,
    DeleteStockupItem200ResponseFromJSON,
    DeleteStockupItem200ResponseToJSON,
    GetCheckout200ResponseFromJSON,
    GetCheckout200ResponseToJSON,
    GetCheckoutFullfillmentShippings200ResponseFromJSON,
    GetCheckoutFullfillmentShippings200ResponseToJSON,
    GetCheckoutFullfillmentStockings200ResponseFromJSON,
    GetCheckoutFullfillmentStockings200ResponseToJSON,
    GetCheckoutRedeliveries200ResponseFromJSON,
    GetCheckoutRedeliveries200ResponseToJSON,
    GetCheckoutTimelines200ResponseFromJSON,
    GetCheckoutTimelines200ResponseToJSON,
    GetCheckouts200ResponseFromJSON,
    GetCheckouts200ResponseToJSON,
    GetCostcoProduct200ResponseFromJSON,
    GetCostcoProduct200ResponseToJSON,
    GetCostcoProducts200ResponseFromJSON,
    GetCostcoProducts200ResponseToJSON,
    GetDeliveryDrivers200ResponseFromJSON,
    GetDeliveryDrivers200ResponseToJSON,
    GetDeliveryParcels200ResponseFromJSON,
    GetDeliveryParcels200ResponseToJSON,
    GetProduct200ResponseFromJSON,
    GetProduct200ResponseToJSON,
    GetStock200ResponseFromJSON,
    GetStock200ResponseToJSON,
    GetStocks200ResponseFromJSON,
    GetStocks200ResponseToJSON,
    GetStockups200ResponseFromJSON,
    GetStockups200ResponseToJSON,
    GetUserSession200ResponseFromJSON,
    GetUserSession200ResponseToJSON,
    OntimeSignInUserRequestFromJSON,
    OntimeSignInUserRequestToJSON,
    PostCheckoutFullfillmentShipping201ResponseFromJSON,
    PostCheckoutFullfillmentShipping201ResponseToJSON,
    PostCheckoutFullfillmentShippingRequestFromJSON,
    PostCheckoutFullfillmentShippingRequestToJSON,
    PostCheckoutFullfillmentStockingRequestFromJSON,
    PostCheckoutFullfillmentStockingRequestToJSON,
    RecreateStockups201ResponseFromJSON,
    RecreateStockups201ResponseToJSON,
    RecreateStockupsRequestFromJSON,
    RecreateStockupsRequestToJSON,
    ResponseErrorBadRequestFromJSON,
    ResponseErrorBadRequestToJSON,
    ResponseErrorConflictFromJSON,
    ResponseErrorConflictToJSON,
    ResponseErrorForbiddenFromJSON,
    ResponseErrorForbiddenToJSON,
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordToJSON,
    ResponseErrorNotFoundFromJSON,
    ResponseErrorNotFoundToJSON,
    ResponseErrorUnauthorizedFromJSON,
    ResponseErrorUnauthorizedToJSON,
    ResponseSuccessFromJSON,
    ResponseSuccessToJSON,
    SendCheckoutFullfillmentShippingMailRequestFromJSON,
    SendCheckoutFullfillmentShippingMailRequestToJSON,
    SignInUser201ResponseFromJSON,
    SignInUser201ResponseToJSON,
    SignInUserRequestFromJSON,
    SignInUserRequestToJSON,
    StockedStockupItemRequestFromJSON,
    StockedStockupItemRequestToJSON,
    UpdateCheckout200ResponseFromJSON,
    UpdateCheckout200ResponseToJSON,
    UpdateCheckoutRequestFromJSON,
    UpdateCheckoutRequestToJSON,
    UpdateDeliveryDriverParcelsRequestFromJSON,
    UpdateDeliveryDriverParcelsRequestToJSON,
    UpdateDeliveryDriverRequestFromJSON,
    UpdateDeliveryDriverRequestToJSON,
    UpdateDeliveryParcelRequestFromJSON,
    UpdateDeliveryParcelRequestToJSON,
    UpdateStockRequestFromJSON,
    UpdateStockRequestToJSON,
    UpdateStockupItemRequestFromJSON,
    UpdateStockupItemRequestToJSON,
    UpdateStockupRequestFromJSON,
    UpdateStockupRequestToJSON,
    UpdateStockupsRequestFromJSON,
    UpdateStockupsRequestToJSON,
} from '../models';

export interface ConsumeStockOperationRequest {
    id: string;
    consumeStockRequest?: ConsumeStockRequest;
}

export interface CreateCheckoutRedeliveryOperationRequest {
    id: string;
    createCheckoutRedeliveryRequest?: CreateCheckoutRedeliveryRequest;
}

export interface CreateDeliveryDriverOperationRequest {
    createDeliveryDriverRequest?: CreateDeliveryDriverRequest;
}

export interface CreateDeliveryParcelOperationRequest {
    createDeliveryParcelRequest?: CreateDeliveryParcelRequest;
}

export interface CreateStocksOperationRequest {
    createStocksRequest?: CreateStocksRequest;
}

export interface CreateStockupOperationRequest {
    createStockupRequest?: CreateStockupRequest;
}

export interface CreateStockupItemOperationRequest {
    id: string;
    createStockupItemRequest?: CreateStockupItemRequest;
}

export interface DeleteCheckoutFullfillmentShippingRequest {
    id: string;
    fid: string;
}

export interface DeleteCheckoutFullfillmentStockingRequest {
    id: string;
    fid: string;
}

export interface DeleteCheckoutRedeliveryRequest {
    id: string;
    redeliveryId: string;
}

export interface DeleteDeliveryDriverRequest {
    id: string;
}

export interface DeleteDeliveryParcelRequest {
    id: string;
}

export interface DeleteStockRequest {
    id: string;
}

export interface DeleteStockupRequest {
    id: string;
}

export interface DeleteStockupItemRequest {
    id: string;
    itemId: string;
}

export interface GetCheckoutRequest {
    id: string;
}

export interface GetCheckoutFullfillmentShippingsRequest {
    id: string;
}

export interface GetCheckoutFullfillmentStockingsRequest {
    id: string;
}

export interface GetCheckoutRedeliveriesRequest {
    id: string;
    parcelId: string;
}

export interface GetCheckoutTimelinesRequest {
    id: string;
    page?: number;
}

export interface GetCheckoutsRequest {
    status?: string;
    page?: number;
    shippingDate?: string;
    shippingTimeId?: string;
}

export interface GetCostcoProductRequest {
    id: string;
}

export interface GetCostcoProductsRequest {
    keyword?: string;
}

export interface GetDeliveryDriversRequest {
    date: string;
}

export interface GetDeliveryParcelsRequest {
    date: string;
}

export interface GetProductRequest {
    id: string;
}

export interface GetStockRequest {
    id: string;
}

export interface GetStocksRequest {
    stockedDate?: Date;
    costcoProductId?: string;
    expiredOnly?: boolean;
    statuses?: Array<GetStocksStatusesEnum>;
}

export interface GetStockupsRequest {
    date: string;
    withStockedContentsQuantity?: boolean;
}

export interface OntimeSignInUserOperationRequest {
    ontimeSignInUserRequest?: OntimeSignInUserRequest;
}

export interface PostCheckoutFullfillmentShippingOperationRequest {
    id: string;
    postCheckoutFullfillmentShippingRequest?: PostCheckoutFullfillmentShippingRequest;
}

export interface PostCheckoutFullfillmentStockingOperationRequest {
    id: string;
    postCheckoutFullfillmentStockingRequest?: PostCheckoutFullfillmentStockingRequest;
}

export interface RecreateStockupsOperationRequest {
    recreateStockupsRequest?: RecreateStockupsRequest;
}

export interface SendCheckoutFullfillmentShippingMailOperationRequest {
    id: string;
    sendCheckoutFullfillmentShippingMailRequest?: SendCheckoutFullfillmentShippingMailRequest;
}

export interface SignInUserOperationRequest {
    signInUserRequest?: SignInUserRequest;
}

export interface StockedStockupItemOperationRequest {
    id: string;
    itemId: string;
    stockedStockupItemRequest?: StockedStockupItemRequest;
}

export interface UpdateCheckoutOperationRequest {
    id: string;
    updateCheckoutRequest?: UpdateCheckoutRequest;
}

export interface UpdateDeliveryDriverOperationRequest {
    id: string;
    updateDeliveryDriverRequest?: UpdateDeliveryDriverRequest;
}

export interface UpdateDeliveryDriverParcelsOperationRequest {
    deliveryDriverId: string;
    updateDeliveryDriverParcelsRequest?: UpdateDeliveryDriverParcelsRequest;
}

export interface UpdateDeliveryParcelOperationRequest {
    id: string;
    updateDeliveryParcelRequest?: UpdateDeliveryParcelRequest;
}

export interface UpdateStockOperationRequest {
    id: string;
    updateStockRequest?: UpdateStockRequest;
}

export interface UpdateStockStatusRequest {
    id: string;
    status: UpdateStockStatusStatusEnum;
}

export interface UpdateStockupOperationRequest {
    id: string;
    updateStockupRequest?: UpdateStockupRequest;
}

export interface UpdateStockupItemOperationRequest {
    id: string;
    itemId: string;
    updateStockupItemRequest?: UpdateStockupItemRequest;
}

export interface UpdateStockupsOperationRequest {
    updateStockupsRequest?: UpdateStockupsRequest;
}

/**
 * 
 */
export class LogisticsApi extends runtime.BaseAPI {

    /**
     * 在庫を消費する
     * Consume Stock
     */
    async consumeStockRaw(requestParameters: ConsumeStockOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStock200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling consumeStock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks/{id}/consumed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConsumeStockRequestToJSON(requestParameters.consumeStockRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStock200ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫を消費する
     * Consume Stock
     */
    async consumeStock(id: string, consumeStockRequest?: ConsumeStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStock200Response> {
        const response = await this.consumeStockRaw({ id: id, consumeStockRequest: consumeStockRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 再配達情報を作成する
     * 
     */
    async createCheckoutRedeliveryRaw(requestParameters: CreateCheckoutRedeliveryOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCheckoutRedelivery201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createCheckoutRedelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/redeliveries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCheckoutRedeliveryRequestToJSON(requestParameters.createCheckoutRedeliveryRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCheckoutRedelivery201ResponseFromJSON(jsonValue));
    }

    /**
     * 再配達情報を作成する
     * 
     */
    async createCheckoutRedelivery(id: string, createCheckoutRedeliveryRequest?: CreateCheckoutRedeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCheckoutRedelivery201Response> {
        const response = await this.createCheckoutRedeliveryRaw({ id: id, createCheckoutRedeliveryRequest: createCheckoutRedeliveryRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送者データを作成する
     * 
     */
    async createDeliveryDriverRaw(requestParameters: CreateDeliveryDriverOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeliveryDriver201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_drivers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDeliveryDriverRequestToJSON(requestParameters.createDeliveryDriverRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeliveryDriver201ResponseFromJSON(jsonValue));
    }

    /**
     * 配送者データを作成する
     * 
     */
    async createDeliveryDriver(createDeliveryDriverRequest?: CreateDeliveryDriverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeliveryDriver201Response> {
        const response = await this.createDeliveryDriverRaw({ createDeliveryDriverRequest: createDeliveryDriverRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送梱包データを作成する
     * 
     */
    async createDeliveryParcelRaw(requestParameters: CreateDeliveryParcelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeliveryParcel201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_parcels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDeliveryParcelRequestToJSON(requestParameters.createDeliveryParcelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeliveryParcel201ResponseFromJSON(jsonValue));
    }

    /**
     * 配送梱包データを作成する
     * 
     */
    async createDeliveryParcel(createDeliveryParcelRequest?: CreateDeliveryParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeliveryParcel201Response> {
        const response = await this.createDeliveryParcelRaw({ createDeliveryParcelRequest: createDeliveryParcelRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 在庫を登録する
     * Create Stocks
     */
    async createStocksRaw(requestParameters: CreateStocksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStocks201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStocksRequestToJSON(requestParameters.createStocksRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStocks201ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫を登録する
     * Create Stocks
     */
    async createStocks(createStocksRequest?: CreateStocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStocks201Response> {
        const response = await this.createStocksRaw({ createStocksRequest: createStocksRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async createStockupRaw(requestParameters: CreateStockupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStockup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStockupRequestToJSON(requestParameters.createStockupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStockup201ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async createStockup(createStockupRequest?: CreateStockupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStockup201Response> {
        const response = await this.createStockupRaw({ createStockupRequest: createStockupRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れた商品を登録する
     * 
     */
    async createStockupItemRaw(requestParameters: CreateStockupItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStockupItem201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createStockupItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateStockupItemRequestToJSON(requestParameters.createStockupItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStockupItem201ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れた商品を登録する
     * 
     */
    async createStockupItem(id: string, createStockupItemRequest?: CreateStockupItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStockupItem201Response> {
        const response = await this.createStockupItemRaw({ id: id, createStockupItemRequest: createStockupItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトの配送を削除する
     * 
     */
    async deleteCheckoutFullfillmentShippingRaw(requestParameters: DeleteCheckoutFullfillmentShippingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCheckoutFullfillmentShipping.');
        }

        if (requestParameters.fid === null || requestParameters.fid === undefined) {
            throw new runtime.RequiredError('fid','Required parameter requestParameters.fid was null or undefined when calling deleteCheckoutFullfillmentShipping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_shippings/{fid}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fid"}}`, encodeURIComponent(String(requestParameters.fid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトの配送を削除する
     * 
     */
    async deleteCheckoutFullfillmentShipping(id: string, fid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteCheckoutFullfillmentShippingRaw({ id: id, fid: fid }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトの仕入れを削除する
     * 
     */
    async deleteCheckoutFullfillmentStockingRaw(requestParameters: DeleteCheckoutFullfillmentStockingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCheckoutFullfillmentStocking.');
        }

        if (requestParameters.fid === null || requestParameters.fid === undefined) {
            throw new runtime.RequiredError('fid','Required parameter requestParameters.fid was null or undefined when calling deleteCheckoutFullfillmentStocking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_stockings/{fid}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"fid"}}`, encodeURIComponent(String(requestParameters.fid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトの仕入れを削除する
     * 
     */
    async deleteCheckoutFullfillmentStocking(id: string, fid: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteCheckoutFullfillmentStockingRaw({ id: id, fid: fid }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async deleteCheckoutRedeliveryRaw(requestParameters: DeleteCheckoutRedeliveryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCheckoutRedelivery.');
        }

        if (requestParameters.redeliveryId === null || requestParameters.redeliveryId === undefined) {
            throw new runtime.RequiredError('redeliveryId','Required parameter requestParameters.redeliveryId was null or undefined when calling deleteCheckoutRedelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/redeliveries/{redelivery_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"redelivery_id"}}`, encodeURIComponent(String(requestParameters.redeliveryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     */
    async deleteCheckoutRedelivery(id: string, redeliveryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteCheckoutRedeliveryRaw({ id: id, redeliveryId: redeliveryId }, initOverrides);
        return await response.value();
    }

    /**
     * 配送者データを削除する
     * 
     */
    async deleteDeliveryDriverRaw(requestParameters: DeleteDeliveryDriverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliveryDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_drivers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送者データを削除する
     * 
     */
    async deleteDeliveryDriver(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteDeliveryDriverRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 配送梱包データを削除する
     * 
     */
    async deleteDeliveryParcelRaw(requestParameters: DeleteDeliveryParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteDeliveryParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_parcels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送梱包データを削除する
     * 
     */
    async deleteDeliveryParcel(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteDeliveryParcelRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 在庫を削除する。在庫の廃棄ではなく、レコードそのものを削除する
     * Delete Stock
     */
    async deleteStockRaw(requestParameters: DeleteStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫を削除する。在庫の廃棄ではなく、レコードそのものを削除する
     * Delete Stock
     */
    async deleteStock(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteStockRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れを削除する
     * 
     */
    async deleteStockupRaw(requestParameters: DeleteStockupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStockup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れを削除する
     * 
     */
    async deleteStockup(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.deleteStockupRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れた商品を削除する
     * 
     */
    async deleteStockupItemRaw(requestParameters: DeleteStockupItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteStockupItem200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStockupItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling deleteStockupItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}/items/{item_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteStockupItem200ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れた商品を削除する
     * 
     */
    async deleteStockupItem(id: string, itemId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteStockupItem200Response> {
        const response = await this.deleteStockupItemRaw({ id: id, itemId: itemId }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを取得する
     * get logistics checkouts
     */
    async getCheckoutRaw(requestParameters: GetCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを取得する
     * get logistics checkouts
     */
    async getCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.getCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトの配送状況を取得する
     * get checkout fullfillment shippings
     */
    async getCheckoutFullfillmentShippingsRaw(requestParameters: GetCheckoutFullfillmentShippingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckoutFullfillmentShippings200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckoutFullfillmentShippings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_shippings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckoutFullfillmentShippings200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトの配送状況を取得する
     * get checkout fullfillment shippings
     */
    async getCheckoutFullfillmentShippings(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckoutFullfillmentShippings200Response> {
        const response = await this.getCheckoutFullfillmentShippingsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトの仕入れ状況を取得する
     * get checkout fullfillment stockings
     */
    async getCheckoutFullfillmentStockingsRaw(requestParameters: GetCheckoutFullfillmentStockingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckoutFullfillmentStockings200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckoutFullfillmentStockings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_stockings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckoutFullfillmentStockings200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトの仕入れ状況を取得する
     * get checkout fullfillment stockings
     */
    async getCheckoutFullfillmentStockings(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckoutFullfillmentStockings200Response> {
        const response = await this.getCheckoutFullfillmentStockingsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 再配達一覧を取得する
     * 
     */
    async getCheckoutRedeliveriesRaw(requestParameters: GetCheckoutRedeliveriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckoutRedeliveries200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckoutRedeliveries.');
        }

        if (requestParameters.parcelId === null || requestParameters.parcelId === undefined) {
            throw new runtime.RequiredError('parcelId','Required parameter requestParameters.parcelId was null or undefined when calling getCheckoutRedeliveries.');
        }

        const queryParameters: any = {};

        if (requestParameters.parcelId !== undefined) {
            queryParameters['parcel_id'] = requestParameters.parcelId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/redeliveries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckoutRedeliveries200ResponseFromJSON(jsonValue));
    }

    /**
     * 再配達一覧を取得する
     * 
     */
    async getCheckoutRedeliveries(id: string, parcelId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckoutRedeliveries200Response> {
        const response = await this.getCheckoutRedeliveriesRaw({ id: id, parcelId: parcelId }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトのタイムラインを取得する
     * 
     */
    async getCheckoutTimelinesRaw(requestParameters: GetCheckoutTimelinesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckoutTimelines200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckoutTimelines.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/timelines`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckoutTimelines200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトのタイムラインを取得する
     * 
     */
    async getCheckoutTimelines(id: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckoutTimelines200Response> {
        const response = await this.getCheckoutTimelinesRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトの一覧を取得する
     * get logistics checkouts
     */
    async getCheckoutsRaw(requestParameters: GetCheckoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckouts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.shippingDate !== undefined) {
            queryParameters['shipping_date'] = requestParameters.shippingDate;
        }

        if (requestParameters.shippingTimeId !== undefined) {
            queryParameters['shipping_time_id'] = requestParameters.shippingTimeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckouts200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトの一覧を取得する
     * get logistics checkouts
     */
    async getCheckouts(status?: string, page?: number, shippingDate?: string, shippingTimeId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckouts200Response> {
        const response = await this.getCheckoutsRaw({ status: status, page: page, shippingDate: shippingDate, shippingTimeId: shippingTimeId }, initOverrides);
        return await response.value();
    }

    /**
     * 単一のコストコ商品を取得する
     * Get Costco Product
     */
    async getCostcoProductRaw(requestParameters: GetCostcoProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProduct200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCostcoProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProduct200ResponseFromJSON(jsonValue));
    }

    /**
     * 単一のコストコ商品を取得する
     * Get Costco Product
     */
    async getCostcoProduct(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProduct200Response> {
        const response = await this.getCostcoProductRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * コストコ商品を取得する
     * 
     */
    async getCostcoProductsRaw(requestParameters: GetCostcoProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProducts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * コストコ商品を取得する
     * 
     */
    async getCostcoProducts(keyword?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProducts200Response> {
        const response = await this.getCostcoProductsRaw({ keyword: keyword }, initOverrides);
        return await response.value();
    }

    /**
     * 配送者データを取得する
     * get delivery parcels
     */
    async getDeliveryDriversRaw(requestParameters: GetDeliveryDriversRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDeliveryDrivers200Response>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDeliveryDrivers.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_drivers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeliveryDrivers200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送者データを取得する
     * get delivery parcels
     */
    async getDeliveryDrivers(date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDeliveryDrivers200Response> {
        const response = await this.getDeliveryDriversRaw({ date: date }, initOverrides);
        return await response.value();
    }

    /**
     * 配送の梱包データを取得する
     * get delivery parcels
     */
    async getDeliveryParcelsRaw(requestParameters: GetDeliveryParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDeliveryParcels200Response>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getDeliveryParcels.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_parcels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeliveryParcels200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送の梱包データを取得する
     * get delivery parcels
     */
    async getDeliveryParcels(date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDeliveryParcels200Response> {
        const response = await this.getDeliveryParcelsRaw({ date: date }, initOverrides);
        return await response.value();
    }

    /**
     * 商品情報を取得する
     * Get Stock
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProduct200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProduct200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品情報を取得する
     * Get Stock
     */
    async getProduct(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProduct200Response> {
        const response = await this.getProductRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 単一の在庫商品を取得する
     * Get Stock
     */
    async getStockRaw(requestParameters: GetStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStock200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStock200ResponseFromJSON(jsonValue));
    }

    /**
     * 単一の在庫商品を取得する
     * Get Stock
     */
    async getStock(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStock200Response> {
        const response = await this.getStockRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 在庫商品を取得する
     * Get Stocks
     */
    async getStocksRaw(requestParameters: GetStocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStocks200Response>> {
        const queryParameters: any = {};

        if (requestParameters.stockedDate !== undefined) {
            queryParameters['stocked_date'] = (requestParameters.stockedDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.costcoProductId !== undefined) {
            queryParameters['costco_product_id'] = requestParameters.costcoProductId;
        }

        if (requestParameters.expiredOnly !== undefined) {
            queryParameters['expired_only'] = requestParameters.expiredOnly;
        }

        if (requestParameters.statuses) {
            queryParameters['statuses[]'] = requestParameters.statuses;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStocks200ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫商品を取得する
     * Get Stocks
     */
    async getStocks(stockedDate?: Date, costcoProductId?: string, expiredOnly?: boolean, statuses?: Array<GetStocksStatusesEnum>, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStocks200Response> {
        const response = await this.getStocksRaw({ stockedDate: stockedDate, costcoProductId: costcoProductId, expiredOnly: expiredOnly, statuses: statuses }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れるべき商品を取得する
     * get stockups
     */
    async getStockupsRaw(requestParameters: GetStockupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStockups200Response>> {
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling getStockups.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        if (requestParameters.withStockedContentsQuantity !== undefined) {
            queryParameters['with_stocked_contents_quantity'] = requestParameters.withStockedContentsQuantity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStockups200ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れるべき商品を取得する
     * get stockups
     */
    async getStockups(date: string, withStockedContentsQuantity?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStockups200Response> {
        const response = await this.getStockupsRaw({ date: date, withStockedContentsQuantity: withStockedContentsQuantity }, initOverrides);
        return await response.value();
    }

    /**
     * トークンの有効性を確かめる
     * 
     */
    async getUserSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserSession200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserSession200ResponseFromJSON(jsonValue));
    }

    /**
     * トークンの有効性を確かめる
     * 
     */
    async getUserSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserSession200Response> {
        const response = await this.getUserSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * ワンタイムログインする
     * onetime sign in user
     */
    async ontimeSignInUserRaw(requestParameters: OntimeSignInUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/onetime_sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OntimeSignInUserRequestToJSON(requestParameters.ontimeSignInUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ワンタイムログインする
     * onetime sign in user
     */
    async ontimeSignInUser(ontimeSignInUserRequest?: OntimeSignInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInUser201Response> {
        const response = await this.ontimeSignInUserRaw({ ontimeSignInUserRequest: ontimeSignInUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送を登録する
     * create fullfillment shipping
     */
    async postCheckoutFullfillmentShippingRaw(requestParameters: PostCheckoutFullfillmentShippingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostCheckoutFullfillmentShipping201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postCheckoutFullfillmentShipping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_shippings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCheckoutFullfillmentShippingRequestToJSON(requestParameters.postCheckoutFullfillmentShippingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCheckoutFullfillmentShipping201ResponseFromJSON(jsonValue));
    }

    /**
     * 配送を登録する
     * create fullfillment shipping
     */
    async postCheckoutFullfillmentShipping(id: string, postCheckoutFullfillmentShippingRequest?: PostCheckoutFullfillmentShippingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostCheckoutFullfillmentShipping201Response> {
        const response = await this.postCheckoutFullfillmentShippingRaw({ id: id, postCheckoutFullfillmentShippingRequest: postCheckoutFullfillmentShippingRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れを登録する
     * create fullfillment stocking
     */
    async postCheckoutFullfillmentStockingRaw(requestParameters: PostCheckoutFullfillmentStockingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckoutFullfillmentStockings200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postCheckoutFullfillmentStocking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_stockings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCheckoutFullfillmentStockingRequestToJSON(requestParameters.postCheckoutFullfillmentStockingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckoutFullfillmentStockings200ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れを登録する
     * create fullfillment stocking
     */
    async postCheckoutFullfillmentStocking(id: string, postCheckoutFullfillmentStockingRequest?: PostCheckoutFullfillmentStockingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckoutFullfillmentStockings200Response> {
        const response = await this.postCheckoutFullfillmentStockingRaw({ id: id, postCheckoutFullfillmentStockingRequest: postCheckoutFullfillmentStockingRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れバッチを再実行する
     * 
     */
    async recreateStockupsRaw(requestParameters: RecreateStockupsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecreateStockups201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/recreate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecreateStockupsRequestToJSON(requestParameters.recreateStockupsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecreateStockups201ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れバッチを再実行する
     * 
     */
    async recreateStockups(recreateStockupsRequest?: RecreateStockupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecreateStockups201Response> {
        const response = await this.recreateStockupsRaw({ recreateStockupsRequest: recreateStockupsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送に関するメールを送信する
     * 
     */
    async sendCheckoutFullfillmentShippingMailRaw(requestParameters: SendCheckoutFullfillmentShippingMailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendCheckoutFullfillmentShippingMail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fullfillment_shipping_mails`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCheckoutFullfillmentShippingMailRequestToJSON(requestParameters.sendCheckoutFullfillmentShippingMailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送に関するメールを送信する
     * 
     */
    async sendCheckoutFullfillmentShippingMail(id: string, sendCheckoutFullfillmentShippingMailRequest?: SendCheckoutFullfillmentShippingMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.sendCheckoutFullfillmentShippingMailRaw({ id: id, sendCheckoutFullfillmentShippingMailRequest: sendCheckoutFullfillmentShippingMailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ログインする
     * sign in user
     */
    async signInUserRaw(requestParameters: SignInUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInUserRequestToJSON(requestParameters.signInUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ログインする
     * sign in user
     */
    async signInUser(signInUserRequest?: SignInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInUser201Response> {
        const response = await this.signInUserRaw({ signInUserRequest: signInUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 確保した仕入れ商品を仕入れる
     * 
     */
    async stockedStockupItemRaw(requestParameters: StockedStockupItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStockupItem201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling stockedStockupItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling stockedStockupItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}/items/{item_id}/stocked`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StockedStockupItemRequestToJSON(requestParameters.stockedStockupItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStockupItem201ResponseFromJSON(jsonValue));
    }

    /**
     * 確保した仕入れ商品を仕入れる
     * 
     */
    async stockedStockupItem(id: string, itemId: string, stockedStockupItemRequest?: StockedStockupItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStockupItem201Response> {
        const response = await this.stockedStockupItemRaw({ id: id, itemId: itemId, stockedStockupItemRequest: stockedStockupItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async updateCheckoutRaw(requestParameters: UpdateCheckoutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCheckoutRequestToJSON(requestParameters.updateCheckoutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async updateCheckout(id: string, updateCheckoutRequest?: UpdateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCheckout200Response> {
        const response = await this.updateCheckoutRaw({ id: id, updateCheckoutRequest: updateCheckoutRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送者データを更新する
     * 
     */
    async updateDeliveryDriverRaw(requestParameters: UpdateDeliveryDriverOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeliveryDriver201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliveryDriver.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_drivers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeliveryDriverRequestToJSON(requestParameters.updateDeliveryDriverRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeliveryDriver201ResponseFromJSON(jsonValue));
    }

    /**
     * 配送者データを更新する
     * 
     */
    async updateDeliveryDriver(id: string, updateDeliveryDriverRequest?: UpdateDeliveryDriverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeliveryDriver201Response> {
        const response = await this.updateDeliveryDriverRaw({ id: id, updateDeliveryDriverRequest: updateDeliveryDriverRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送者の梱包データを更新する
     * 
     */
    async updateDeliveryDriverParcelsRaw(requestParameters: UpdateDeliveryDriverParcelsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        if (requestParameters.deliveryDriverId === null || requestParameters.deliveryDriverId === undefined) {
            throw new runtime.RequiredError('deliveryDriverId','Required parameter requestParameters.deliveryDriverId was null or undefined when calling updateDeliveryDriverParcels.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_drivers/{delivery_driver_id}/parcels`.replace(`{${"delivery_driver_id"}}`, encodeURIComponent(String(requestParameters.deliveryDriverId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeliveryDriverParcelsRequestToJSON(requestParameters.updateDeliveryDriverParcelsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送者の梱包データを更新する
     * 
     */
    async updateDeliveryDriverParcels(deliveryDriverId: string, updateDeliveryDriverParcelsRequest?: UpdateDeliveryDriverParcelsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.updateDeliveryDriverParcelsRaw({ deliveryDriverId: deliveryDriverId, updateDeliveryDriverParcelsRequest: updateDeliveryDriverParcelsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 配送梱包データを更新する
     * 
     */
    async updateDeliveryParcelRaw(requestParameters: UpdateDeliveryParcelOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDeliveryParcel201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDeliveryParcel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/delivery_parcels/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeliveryParcelRequestToJSON(requestParameters.updateDeliveryParcelRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDeliveryParcel201ResponseFromJSON(jsonValue));
    }

    /**
     * 配送梱包データを更新する
     * 
     */
    async updateDeliveryParcel(id: string, updateDeliveryParcelRequest?: UpdateDeliveryParcelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDeliveryParcel201Response> {
        const response = await this.updateDeliveryParcelRaw({ id: id, updateDeliveryParcelRequest: updateDeliveryParcelRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 在庫を更新する
     * Update Stock
     */
    async updateStockRaw(requestParameters: UpdateStockOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStock200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStockRequestToJSON(requestParameters.updateStockRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStock200ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫を更新する
     * Update Stock
     */
    async updateStock(id: string, updateStockRequest?: UpdateStockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStock200Response> {
        const response = await this.updateStockRaw({ id: id, updateStockRequest: updateStockRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 在庫のステータスを更新する
     * Update Stock Status
     */
    async updateStockStatusRaw(requestParameters: UpdateStockStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStock200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStockStatus.');
        }

        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling updateStockStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stocks/{id}/{status}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"status"}}`, encodeURIComponent(String(requestParameters.status))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStock200ResponseFromJSON(jsonValue));
    }

    /**
     * 在庫のステータスを更新する
     * Update Stock Status
     */
    async updateStockStatus(id: string, status: UpdateStockStatusStatusEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStock200Response> {
        const response = await this.updateStockStatusRaw({ id: id, status: status }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れ情報を更新する
     * 
     */
    async updateStockupRaw(requestParameters: UpdateStockupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStockup201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStockup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStockupRequestToJSON(requestParameters.updateStockupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStockup201ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れ情報を更新する
     * 
     */
    async updateStockup(id: string, updateStockupRequest?: UpdateStockupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStockup201Response> {
        const response = await this.updateStockupRaw({ id: id, updateStockupRequest: updateStockupRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れた商品を更新する
     * 
     */
    async updateStockupItemRaw(requestParameters: UpdateStockupItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateStockupItem201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateStockupItem.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling updateStockupItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups/{id}/items/{item_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"item_id"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStockupItemRequestToJSON(requestParameters.updateStockupItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateStockupItem201ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れた商品を更新する
     * 
     */
    async updateStockupItem(id: string, itemId: string, updateStockupItemRequest?: UpdateStockupItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateStockupItem201Response> {
        const response = await this.updateStockupItemRaw({ id: id, itemId: itemId, updateStockupItemRequest: updateStockupItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 仕入れの一括更新
     * 
     */
    async updateStockupsRaw(requestParameters: UpdateStockupsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCheckoutFullfillmentStocking200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stockups`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStockupsRequestToJSON(requestParameters.updateStockupsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCheckoutFullfillmentStocking200ResponseFromJSON(jsonValue));
    }

    /**
     * 仕入れの一括更新
     * 
     */
    async updateStockups(updateStockupsRequest?: UpdateStockupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCheckoutFullfillmentStocking200Response> {
        const response = await this.updateStockupsRaw({ updateStockupsRequest: updateStockupsRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetStocksStatusesEnum = {
    Stocked: 'stocked',
    Consumed: 'consumed',
    Discarded: 'discarded'
} as const;
export type GetStocksStatusesEnum = typeof GetStocksStatusesEnum[keyof typeof GetStocksStatusesEnum];
/**
 * @export
 */
export const UpdateStockStatusStatusEnum = {
    Stocked: 'stocked',
    Discarded: 'discarded'
} as const;
export type UpdateStockStatusStatusEnum = typeof UpdateStockStatusStatusEnum[keyof typeof UpdateStockStatusStatusEnum];
