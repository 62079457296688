import { useCallback, useState } from "react";

type PageMeta = {
  total: number;
  prev?: number;
  next?: number;
};

export const usePaging = (page = 1) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [totalPage, setTotalPage] = useState(0);
  const [prevPage, setPrevPage] = useState<number>();
  const [nextPage, setNextPage] = useState<number>();

  const gotoPage = useCallback((p: number) => {
    setCurrentPage(p);
  }, []);

  const setMeta = useCallback(({ total, prev, next }: PageMeta) => {
    setTotalPage(total);
    setPrevPage(prev);
    setNextPage(next);
  }, []);

  const resetPage = useCallback(() => {
    setCurrentPage(1);
    setTotalPage(0);
    setPrevPage(undefined);
    setNextPage(undefined);
  }, []);

  return {
    currentPage,
    totalPage,
    prevPage,
    nextPage,
    gotoPage,
    setMeta,
    resetPage,
  };
};
