// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { ProductFragmentWithVariantsFragmentDoc } from './ProductFragmentWithVariants';
import { useQuery, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type GetProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetProductQuery = (
  { __typename?: 'QueryRoot' }
  & { product?: Types.Maybe<(
    { __typename?: 'Product' }
    & Pick<Types.Product, 'id' | 'title' | 'description' | 'descriptionHtml'>
    & { featuredImage?: Types.Maybe<(
      { __typename?: 'Image' }
      & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
    )>, priceRange: (
      { __typename?: 'ProductPriceRange' }
      & { minVariantPrice: (
        { __typename?: 'MoneyV2' }
        & Pick<Types.MoneyV2, 'amount'>
      ), maxVariantPrice: (
        { __typename?: 'MoneyV2' }
        & Pick<Types.MoneyV2, 'amount'>
      ) }
    ), variants: (
      { __typename?: 'ProductVariantConnection' }
      & { edges: Array<(
        { __typename?: 'ProductVariantEdge' }
        & { node: (
          { __typename?: 'ProductVariant' }
          & Pick<Types.ProductVariant, 'id' | 'title'>
          & { price: (
            { __typename?: 'MoneyV2' }
            & Pick<Types.MoneyV2, 'amount'>
          ), image?: Types.Maybe<(
            { __typename?: 'Image' }
            & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
          )> }
        ) }
      )> }
    ), images: (
      { __typename?: 'ImageConnection' }
      & { edges: Array<(
        { __typename?: 'ImageEdge' }
        & { node: (
          { __typename?: 'Image' }
          & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
        ) }
      )> }
    ) }
  )> }
);


export const GetProductDocument = `
    query GetProduct($id: ID!) {
  product(id: $id) {
    ...ProductFragmentWithVariants
  }
}
    ${ProductFragmentWithVariantsFragmentDoc}`;
export const useGetProductQuery = <
      TData = GetProductQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetProductQueryVariables,
      options?: UseQueryOptions<GetProductQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProductQuery, TError, TData>(
      ['GetProduct', variables],
      fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, variables, headers),
      options
    );

useGetProductQuery.getKey = (variables: GetProductQueryVariables) => ['GetProduct', variables];
;

export const useInfiniteGetProductQuery = <
      TData = GetProductQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetProductQueryVariables,
      client: GraphQLClient,
      variables: GetProductQueryVariables,
      options?: UseInfiniteQueryOptions<GetProductQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetProductQuery, TError, TData>(
      ['GetProduct.infinite', variables],
      (metaData) => fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, {...variables, ...(metaData.pageParam ? {[pageParamKey]: metaData.pageParam} : {})}, headers)(),
      options
    );


useInfiniteGetProductQuery.getKey = (variables: GetProductQueryVariables) => ['GetProduct.infinite', variables];
;

useGetProductQuery.fetcher = (client: GraphQLClient, variables: GetProductQueryVariables, headers?: RequestInit['headers']) => fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, variables, headers);