/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDeliverableAddresses200ResponseAllOfData } from './GetDeliverableAddresses200ResponseAllOfData';
import {
    GetDeliverableAddresses200ResponseAllOfDataFromJSON,
    GetDeliverableAddresses200ResponseAllOfDataFromJSONTyped,
    GetDeliverableAddresses200ResponseAllOfDataToJSON,
} from './GetDeliverableAddresses200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetDeliverableAddresses200Response
 */
export interface GetDeliverableAddresses200Response {
    /**
     * 
     * @type {GetDeliverableAddresses200ResponseAllOfData}
     * @memberof GetDeliverableAddresses200Response
     */
    data: GetDeliverableAddresses200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetDeliverableAddresses200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetDeliverableAddresses200Response interface.
 */
export function instanceOfGetDeliverableAddresses200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetDeliverableAddresses200ResponseFromJSON(json: any): GetDeliverableAddresses200Response {
    return GetDeliverableAddresses200ResponseFromJSONTyped(json, false);
}

export function GetDeliverableAddresses200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliverableAddresses200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetDeliverableAddresses200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetDeliverableAddresses200ResponseToJSON(value?: GetDeliverableAddresses200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetDeliverableAddresses200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

