import { immerable } from "immer";

import * as api from "../services/api/store/models";

export type RankingType = api.Ranking;

export class Ranking implements RankingType {
  [immerable] = true;

  id = "";
  slug = "";
  name = "";
  latestDate = new Date();

  constructor(data: Partial<RankingType> = {}) {
    Object.assign(this, data);
  }
}
