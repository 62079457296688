/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCheckoutRedeliveries200ResponseAllOfData } from './GetCheckoutRedeliveries200ResponseAllOfData';
import {
    GetCheckoutRedeliveries200ResponseAllOfDataFromJSON,
    GetCheckoutRedeliveries200ResponseAllOfDataFromJSONTyped,
    GetCheckoutRedeliveries200ResponseAllOfDataToJSON,
} from './GetCheckoutRedeliveries200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCheckoutRedeliveries200ResponseAllOf
 */
export interface GetCheckoutRedeliveries200ResponseAllOf {
    /**
     * 
     * @type {GetCheckoutRedeliveries200ResponseAllOfData}
     * @memberof GetCheckoutRedeliveries200ResponseAllOf
     */
    data: GetCheckoutRedeliveries200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCheckoutRedeliveries200ResponseAllOf interface.
 */
export function instanceOfGetCheckoutRedeliveries200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCheckoutRedeliveries200ResponseAllOfFromJSON(json: any): GetCheckoutRedeliveries200ResponseAllOf {
    return GetCheckoutRedeliveries200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCheckoutRedeliveries200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutRedeliveries200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCheckoutRedeliveries200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCheckoutRedeliveries200ResponseAllOfToJSON(value?: GetCheckoutRedeliveries200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCheckoutRedeliveries200ResponseAllOfDataToJSON(value.data),
    };
}

