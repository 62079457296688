import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { MdOutlineDescription } from "react-icons/md";

import { BFC } from "shared/types";

import { routes } from "~/constants";

import { CancelMembershipForm, CancelMembershipData } from "../../forms";
import { useCancelMembership, useSignOut } from "../../hooks";

export const CancelMembershipPage: BFC = () => {
  const { signOut } = useSignOut();
  const router = useRouter();
  const { cancelMembership } = useCancelMembership();
  const [isCancelMembership, setIsCancelMembership] = useState(false);

  const onSubmit = useCallback((data: CancelMembershipData) => {
    return cancelMembership(data, {
      onSuccess: () => {
        setIsCancelMembership(true);
        setTimeout(async () => {
          await router.push(routes.TOP);
          signOut();
        }, 4000);
      }
    });
  }, [cancelMembership, signOut]);

  return isCancelMembership ? (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">退会手続きが完了しました</h1>
        </div>
        <p>SocTocをご利用いただきありがとうございました。<br /> またのご利用を心よりお待ちしております。</p>
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <MdOutlineDescription size={24} className="text-black-500" />
          退会手続き
        </h1>
      </div>
      <div className="bg-white p-3 pb-10 shadow">
        <div className="p-6 my-6 shadow rounded-md bg-red-100">
          <h3 className="font-bold text-gray-700 font-md mb-4">
            必ずご確認ください
          </h3>
          <p className="text-gray-700 font-md mb-2">
            退会した場合、ご登録していだいたアカウント情報は1日の猶予の後全て削除されます。
          </p>
        </div>
        <CancelMembershipForm onSubmit={onSubmit} />
      </div>
    </div>
  );
};
