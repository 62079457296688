import { useMutation } from "@tanstack/react-query";

import { useStoreAPI } from "shared/services/api";
import { DeleteUserRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

export const useCancelMembership = () => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });

  const { mutateAsync: cancelMembership, isLoading } = useMutation(
    ["users/cancelMembership"],
    (data: DeleteUserRequest) => api.deleteUser(data),
  );

  return {
    cancelMembership,
    isLoading,
  };
};
