import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  html: string;
  className?: string;
};

export const ArticleRawHTMLBlock: BFC<Props> = ({
  html,
  className,
}) => {
  return (
    <div
      className={classNames(className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
