/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProduct } from './CostcoProduct';
import {
    CostcoProductFromJSON,
    CostcoProductFromJSONTyped,
    CostcoProductToJSON,
} from './CostcoProduct';

/**
 * 
 * @export
 * @interface ProductRecipe
 */
export interface ProductRecipe {
    /**
     * 
     * @type {string}
     * @memberof ProductRecipe
     */
    id: string;
    /**
     * 
     * @type {CostcoProduct}
     * @memberof ProductRecipe
     */
    costcoProduct: CostcoProduct;
    /**
     * 
     * @type {number}
     * @memberof ProductRecipe
     */
    contentsQuantity: number;
}

/**
 * Check if a given object implements the ProductRecipe interface.
 */
export function instanceOfProductRecipe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "costcoProduct" in value;
    isInstance = isInstance && "contentsQuantity" in value;

    return isInstance;
}

export function ProductRecipeFromJSON(json: any): ProductRecipe {
    return ProductRecipeFromJSONTyped(json, false);
}

export function ProductRecipeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRecipe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'costcoProduct': CostcoProductFromJSON(json['costco_product']),
        'contentsQuantity': json['contents_quantity'],
    };
}

export function ProductRecipeToJSON(value?: ProductRecipe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'costco_product': CostcoProductToJSON(value.costcoProduct),
        'contents_quantity': value.contentsQuantity,
    };
}

