import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { Product } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useFavoriteProducts = () => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });

  const { data, ...queryResult } = useInfiniteQuery(
    ["favorites", "products"],
    ({ pageParam }) => api.getFavorites("products", pageParam || 1),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
    }
  );

  const favoriteProducts: Product[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap(({ data: { favorites } }) =>
      favorites.map((favorite) => new Product(favorite.product))
    );
  }, [data]);

  return {
    favoriteProducts,
    ...queryResult,
  };
};
