import classNames from "classnames";
import { BsCircle, BsCheckCircle } from "react-icons/bs";

import { BFC } from "shared/types";

type Props = {
  items: Array<{ text: string; checked: boolean }>;
};

export const ArticleCheckListBlock: BFC<Props> = ({
  items,
  className,
}) => {
  const classes = classNames("list-none flex flex-col gap-1", className);

  return (
    <ul className={classes}>
      {items.map((item, index) => (
        <li key={index} className="flex items-center gap-3">
          {item.checked ? (
            <BsCheckCircle className="text-green-500" size={18} />
          ) : (
            <BsCircle className="text-gray-500" size={18} />
          )}
          {item.text}
        </li>
      ))}
    </ul>
  );
};
