import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { BFC } from "shared/types";

import { useAuthContext } from "~/features/auth";

import { AddressForm, AddressData } from "../../forms";
import { useAddresses } from "../../hooks";

type Props = {
  returnTo: string;
};

export const AddressNewPage: BFC<Props> = ({
  returnTo,
}) => {
  const { create } = useAddresses();
  const { user: { firstName, lastName } } = useAuthContext();
  const { defaultAddress } = useAddresses();
  const showDefault = !!defaultAddress;
  const router = useRouter();

  const defaultValues = useMemo(() => ({
    userAddress: { firstName, lastName },
  }), [firstName, lastName]);

  const onSubmit = useCallback(async (data: AddressData) => {
    await create(data);
    router.push(returnTo);
  }, [returnTo]);

  return (
    <div className="bg-white">
      <div className="p-3">
        <AddressForm onSubmit={onSubmit} defaultValues={defaultValues} showDefault={showDefault} />
      </div>
    </div>
  );
};
