import Link from "next/link";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { ProductType, ReviewType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useProduct } from "~/features/products";

import { ReviewItem } from "../../components";
import { useReview } from "../../hooks";

type Props = {
  product: ProductType;
  review: ReviewType;
};

export const ReviewShowPage: BFC<Props> = ({
  product: defaultProduct,
  review: defaultReview,
}) => {
  const productId = defaultProduct.id;
  const { product } = useProduct(productId, defaultProduct);

  const reviewId = defaultReview.id;
  const { review } = useReview(productId, reviewId, defaultReview);

  return (
    <div className="divide-y divide-gray-200">
      <Link href={routes.REVIEWS_INDEX(productId)}>
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg bg-white">
          <HiOutlineChevronLeft size={24} />
          {product.title}のレビュー
        </h1>
      </Link>

      <div className="bg-white px-3 sm:px-10">
        <ReviewItem review={review} />
      </div>
    </div>
  );
};
