/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeleteUserAddress200ResponseAllOfData } from './DeleteUserAddress200ResponseAllOfData';
import {
    DeleteUserAddress200ResponseAllOfDataFromJSON,
    DeleteUserAddress200ResponseAllOfDataFromJSONTyped,
    DeleteUserAddress200ResponseAllOfDataToJSON,
} from './DeleteUserAddress200ResponseAllOfData';

/**
 * 
 * @export
 * @interface DeleteUserAddress200Response
 */
export interface DeleteUserAddress200Response {
    /**
     * 
     * @type {DeleteUserAddress200ResponseAllOfData}
     * @memberof DeleteUserAddress200Response
     */
    data: DeleteUserAddress200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof DeleteUserAddress200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the DeleteUserAddress200Response interface.
 */
export function instanceOfDeleteUserAddress200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function DeleteUserAddress200ResponseFromJSON(json: any): DeleteUserAddress200Response {
    return DeleteUserAddress200ResponseFromJSONTyped(json, false);
}

export function DeleteUserAddress200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteUserAddress200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': DeleteUserAddress200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function DeleteUserAddress200ResponseToJSON(value?: DeleteUserAddress200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DeleteUserAddress200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

