import classNames from "classnames";

import { Product } from "shared/models";
import { BFC } from "shared/types";

import { ProductCardWide } from "../ProductCardWide";

type Props = {
  product: Product;
};

export const AlternativeProductCardWide: BFC<Props> = ({
  product,
  className,
}) => {
  return (
    <div className={classNames(className)}>
      <div className="flex items-center justify-between gap-3 text-left w-full">
        <div className="flex flex-col gap-1">
          <div className="text-black-400 text-2xs">指定した代替品</div>
          <ProductCardWide product={product} imageClassName="w-10" />
        </div>
      </div>
    </div>
  );
};
