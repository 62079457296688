/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { StockConsumptionWithStock } from './StockConsumptionWithStock';
import {
    StockConsumptionWithStockFromJSON,
    StockConsumptionWithStockFromJSONTyped,
    StockConsumptionWithStockToJSON,
} from './StockConsumptionWithStock';

/**
 * 
 * @export
 * @interface FullfillmentStocking
 */
export interface FullfillmentStocking {
    /**
     * 
     * @type {string}
     * @memberof FullfillmentStocking
     */
    id: string;
    /**
     * 
     * @type {Product}
     * @memberof FullfillmentStocking
     */
    product: Product;
    /**
     * 
     * @type {Product}
     * @memberof FullfillmentStocking
     */
    alternativeProduct?: Product;
    /**
     * 
     * @type {number}
     * @memberof FullfillmentStocking
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof FullfillmentStocking
     */
    cancelQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof FullfillmentStocking
     */
    cancelReason: FullfillmentStockingCancelReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof FullfillmentStocking
     */
    stockedAt: Date;
    /**
     * 
     * @type {Array<StockConsumptionWithStock>}
     * @memberof FullfillmentStocking
     */
    stockConsumptions: Array<StockConsumptionWithStock>;
}


/**
 * @export
 */
export const FullfillmentStockingCancelReasonEnum = {
    No: 'no',
    OutOfStock: 'out_of_stock'
} as const;
export type FullfillmentStockingCancelReasonEnum = typeof FullfillmentStockingCancelReasonEnum[keyof typeof FullfillmentStockingCancelReasonEnum];


/**
 * Check if a given object implements the FullfillmentStocking interface.
 */
export function instanceOfFullfillmentStocking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "cancelQuantity" in value;
    isInstance = isInstance && "cancelReason" in value;
    isInstance = isInstance && "stockedAt" in value;
    isInstance = isInstance && "stockConsumptions" in value;

    return isInstance;
}

export function FullfillmentStockingFromJSON(json: any): FullfillmentStocking {
    return FullfillmentStockingFromJSONTyped(json, false);
}

export function FullfillmentStockingFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullfillmentStocking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': ProductFromJSON(json['product']),
        'alternativeProduct': !exists(json, 'alternative_product') ? undefined : ProductFromJSON(json['alternative_product']),
        'quantity': json['quantity'],
        'cancelQuantity': json['cancel_quantity'],
        'cancelReason': json['cancel_reason'],
        'stockedAt': (new Date(json['stocked_at'])),
        'stockConsumptions': ((json['stock_consumptions'] as Array<any>).map(StockConsumptionWithStockFromJSON)),
    };
}

export function FullfillmentStockingToJSON(value?: FullfillmentStocking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product': ProductToJSON(value.product),
        'alternative_product': ProductToJSON(value.alternativeProduct),
        'quantity': value.quantity,
        'cancel_quantity': value.cancelQuantity,
        'cancel_reason': value.cancelReason,
        'stocked_at': (value.stockedAt.toISOString()),
        'stock_consumptions': ((value.stockConsumptions as Array<any>).map(StockConsumptionWithStockToJSON)),
    };
}

