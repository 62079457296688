import { useQuery, useMutation, MutateOptions } from "@tanstack/react-query";
import { useCallback, useState } from "react";

import { Checkout } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

type PromiseOf<T> = T extends Promise<infer U> ? U : T;

export const useCheckout = (id: string) => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });
  const [checkout, setCheckout] = useState<Checkout>(new Checkout());

  const { isLoading, refetch } = useQuery(
    ["checkout", id],
    () => api.getCheckout(id),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
    },
  );

  const { mutateAsync: cancelMutate, isLoading: isCancelFetching } = useMutation(
    () => api.cancelCheckout(id),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const cancel = useCallback(
    (options: MutateOptions<PromiseOf<ReturnType<typeof api.cancelCheckout>>>) => cancelMutate(undefined, options),
    [cancelMutate],
  );

  const { mutateAsync: updatePaymentMethod, isLoading: isUpdatePaymentMethodFetching } = useMutation(
    (params: { paymentMethodId: string }) => api.updateCheckoutPaymentMethod(id, params),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    checkout,
    cancel,
    updatePaymentMethod,
    isLoading,
    isCancelFetching,
    isUpdatePaymentMethodFetching,
    isFetching: isCancelFetching || isUpdatePaymentMethodFetching,
  };
};
