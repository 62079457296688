import { useState } from "react";

import { MagazineArticle, MagazineArticleType } from "shared/models";

import { toStrapiImageURL } from "../helpers";
import { StrapiResponse, StrapiArticle } from "../types";
import { useStrapiQuery } from "./useStrapiQuery";

export const useMagazineArticle = (slug: string, base?: Partial<MagazineArticleType>) => {
  const [article, setArticle] = useState(new MagazineArticle(base));

  const { isLoading } = useStrapiQuery<StrapiResponse<StrapiArticle[]>>(
    ["magazineArticle", slug],
    "/api/articles",
    {
      populate: "*",
      filters: {
        slug: {
          $eq: slug,
        },
      },
      publicationState: "preview",
    },
    {
      enabled: !!slug,
      onSuccess: ({ data }) => {
        if (data.length) {
          const { id, attributes } = data[0];
          setArticle(new MagazineArticle({
            id,
            title: attributes.title,
            slug: attributes.slug,
            body: attributes.body,
            categories: attributes.categories.data.map(({ id, attributes }) => ({
              id,
              name: attributes.name,
              slug: attributes.slug,
            })),
            tags: attributes.tags.data.map(({ id, attributes }) => ({
              id,
              name: attributes.name,
              slug: attributes.slug,
            })),
            cover: attributes.cover ? {
              url: toStrapiImageURL(attributes.cover.data.attributes.url),
              width: attributes.cover.data.attributes.width,
              height: attributes.cover.data.attributes.height,
            } : undefined,
            publishAt: attributes.publishAt ? new Date(attributes.publishAt) : new Date(attributes.createdAt),
            createdAt: new Date(attributes.createdAt),
            updatedAt: new Date(attributes.updatedAt),
          }));
        }
      },
    },
  );

  return {
    article,
    isLoading,
  };
};
