import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";

import { FavoritableType, FavoritableTypePath, Product } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";
import { productsQueryKeys } from "~/features/products";
import { salesQueryKeys } from "~/features/sales";

type UseFavoriteOptions = {
  favoritable: Product;
};

export const useFavorite = ({ favoritable }: UseFavoriteOptions) => {
  const favoritableType = FavoritableType.Product;

  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });

  // APIレスポンスを待たずに表示を更新するためのローカルステート
  const [isFavorite, setIsFavorite] = useState(favoritable.isFavorite);
  useEffect(() => {
    setIsFavorite(favoritable.isFavorite);
  }, [favoritable.isFavorite]);

  const queryClient = useQueryClient();
  const invalidateRelatedQueries = useCallback(async () => {
    // お気に入りステータスの表示に関わるクエリのキャッシュを破棄する
    await queryClient.invalidateQueries({
      queryKey: productsQueryKeys.all,
    });
    await queryClient.invalidateQueries({
      queryKey: salesQueryKeys.all,
    });
  }, [queryClient]);

  const { mutate: addFavorite, isLoading: isAdding } = useMutation(
    ["favorites", "add"],
    () => {
      return api.addFavorite({
        favorite: {
          favoritableType,
          favoritableId: favoritable.id,
        },
      });
    },
    {
      onMutate: () => {
        setIsFavorite(true);
      },
      onSettled: invalidateRelatedQueries,
    }
  );

  const { mutate: deleteFavorite, isLoading: isRemoving } = useMutation(
    ["favorites", "delete"],
    () => {
      // FavoritableType とパスの指定は若干異なるので変換する
      // e.g. Product -> products
      const deleteFavoritableType = {
        [FavoritableType.Product]: FavoritableTypePath.Products,
      }[favoritableType];

      return api.deleteFavorite(favoritable.id, deleteFavoritableType);
    },
    {
      onMutate: () => {
        setIsFavorite(false);
      },
      onSettled: invalidateRelatedQueries,
    }
  );

  const isMutating = useMemo(() => {
    return isAdding || isRemoving;
  }, [isAdding, isRemoving]);

  const toggleFavorite = useCallback(() => {
    if (isMutating) {
      return;
    }

    if (isFavorite) {
      deleteFavorite();
      return;
    }

    addFavorite();
  }, [isMutating, isFavorite, deleteFavorite, addFavorite]);

  return {
    isFavorite,
    addFavorite,
    deleteFavorite,
    toggleFavorite,
    isMutating,
  };
};
