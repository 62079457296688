import { immerable } from "immer";

export type AttachableImageType = {
  id?: string;
  url: string;
  width?: number;
  height?: number;
  altText?: string;
  caption?: string;
};

export class AttachableImage implements AttachableImageType {
  [immerable] = true;

  id = "";
  url = "";
  height = 0;
  width = 0;
  altText = "";
  caption = "";

  constructor(data: Partial<AttachableImageType> = {}) {
    Object.assign(this, data);
  }
}
