import Link from "next/link";
import { useMemo } from "react";
import { HiOutlineChatAlt2, HiOutlinePlusCircle } from "react-icons/hi";

import { LinkButton } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ReviewList } from "../../components";
import { useReviews } from "../../hooks";

const REVIEW_LIMIT = 3;

type Props = {
  productId: string;
};

/**
 * REVIEW_LIMIT の件数だけレビューを表示する
 */
export const ReviewSummary: BFC<Props> = ({ productId }) => {
  const { reviews, isLoading } = useReviews({ productId });

  const slicedReviews = useMemo(() => {
    return reviews.slice(0, REVIEW_LIMIT);
  }, [reviews]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between gap-4">
        <h3 className="text-xl font-bold flex items-center gap-1 text-black">
          <HiOutlineChatAlt2 className="text-2xl" />
          レビュー{reviews.length ? ` (${reviews.length}件)` : null}
        </h3>

        <LinkButton
          href={routes.REVIEWS_NEW(productId)}
          className="flex items-center gap-1"
        >
          <HiOutlinePlusCircle className="text-xl" />
          投稿する
        </LinkButton>
      </div>

      <ReviewList
        productId={productId}
        reviews={slicedReviews}
        isLoading={isLoading}
      />

      {reviews.length > 0 && (
        <div className="mt-4 flex justify-end">
          <Link href={routes.REVIEWS_INDEX(productId)} className="text-black hover:underline">
            すべてのレビューを見る &raquo;
          </Link>
        </div>
      )}
    </div>
  );
};
