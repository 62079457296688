/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductShippingRateAllOfAvailableDatetimes } from './ProductShippingRateAllOfAvailableDatetimes';
import {
    ProductShippingRateAllOfAvailableDatetimesFromJSON,
    ProductShippingRateAllOfAvailableDatetimesFromJSONTyped,
    ProductShippingRateAllOfAvailableDatetimesToJSON,
} from './ProductShippingRateAllOfAvailableDatetimes';

/**
 * 
 * @export
 * @interface ProductShippingRate
 */
export interface ProductShippingRate {
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRate
     */
    handle: string;
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ProductShippingRate
     */
    price: number;
    /**
     * 
     * @type {Array<ProductShippingRateAllOfAvailableDatetimes>}
     * @memberof ProductShippingRate
     */
    availableDatetimes: Array<ProductShippingRateAllOfAvailableDatetimes>;
}

/**
 * Check if a given object implements the ProductShippingRate interface.
 */
export function instanceOfProductShippingRate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "handle" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "availableDatetimes" in value;

    return isInstance;
}

export function ProductShippingRateFromJSON(json: any): ProductShippingRate {
    return ProductShippingRateFromJSONTyped(json, false);
}

export function ProductShippingRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductShippingRate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'handle': json['handle'],
        'name': json['name'],
        'price': json['price'],
        'availableDatetimes': ((json['available_datetimes'] as Array<any>).map(ProductShippingRateAllOfAvailableDatetimesFromJSON)),
    };
}

export function ProductShippingRateToJSON(value?: ProductShippingRate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'handle': value.handle,
        'name': value.name,
        'price': value.price,
        'available_datetimes': ((value.availableDatetimes as Array<any>).map(ProductShippingRateAllOfAvailableDatetimesToJSON)),
    };
}

