import classNames from "classnames";
import { useCallback, useRef } from "react";

import { formatDate } from "shared/helpers";
import { MagazineArticle } from "shared/models";
import { BFC } from "shared/types";

import { toStrapiImageURL } from "../../helpers";
import { StrapiArticleBody } from "../../types";
import {
  ArticleCheckListBlock,
  ArticleCodeBlock,
  ArticleDelimiterBlock,
  ArticleEmbedBlock,
  ArticleHeadingBlock,
  ArticleImageBlock,
  ArticleListBlock,
  ArticleParagraphBlock,
  ArticleProductLinkBlock,
  ArticleQuoteBlock,
  ArticleRawHTMLBlock,
  ArticleTableBlock,
  ArticleWarningBlock,
} from "../blocks";
import { TableOfContents } from "../TableOfContents";

type Props = {
  article: MagazineArticle;
};

export const Article: BFC<Props> = ({
  article,
  className,
}) => {
  const { cover, title } = article;
  const json = article.getBodyJson() as StrapiArticleBody;
  const { blocks = [] } = json;
  const ref = useRef<HTMLDivElement>(null);

  const onTableOfContentsClick = useCallback((id: string) => {
    ref.current?.querySelector(`#header-${id}`)?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, []);

  return (
    <div ref={ref} className={classNames("bg-white", className)}>
      {cover && (
        <div className="relative w-full h-60 md:h-80 text-white">
          <img src={cover.url} alt={title} className="object-cover absolute w-full h-full inset-0 z-10" />
          <div className="absolute bottom-0 left-0 z-20 p-4 flex gap-2">
            {article.getCategories().map((category) => (
              <span
                key={category.id}
                className="px-2 py-1 text-sm bg-neutral-100 text-gray-500 rounded"
              >
                {category.name}
              </span>
            ))}
          </div>
        </div>
      )}
      <div className="p-4 flex flex-col gap-3">
        <div className="text-gray-500 text-sm">
          {formatDate(article.getPublishDate())}
        </div>
        <h1 className="font-bold text-2xl">{title}</h1>
        {article.tags.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {article.getTags().map((tag) => (
              <span
                key={tag.id}
                className="px-2 py-1 text-sm bg-white text-gray-600 rounded border"
              >
                #{tag.name}
              </span>
            ))}
          </div>
        )}
        <div className="flex flex-col gap-4 md:grid grid-cols-12 gap-x-4 items-start">
          <div className="hidden md:block md:col-span-4 md:order-last md:sticky md:top-16">
            <TableOfContents blocks={blocks} onClick={onTableOfContentsClick} />
          </div>
          <div className="col-span-8 flex flex-col gap-4">
            <div className="border rounded p-4 flex gap-3">
              <div className="bg-primary rounded-full flex items-center justify-center w-12 h-12 shrink-0">
                <img
                  src="/assets/images/logo/logo_secondary.png"
                  className="w-8"
                />
              </div>
              <div className="flex flex-col gap-1">
                <div>
                  <div className="text-sm text-gray-400">監修</div>
                  <div className="font-bold">
                    SocTocマガジン編集部
                  </div>
                </div>
                <div className="text-sm text-black-600">
                  コストコの商品をより楽しく、よりお得に使っていただくために、
                  様々な情報を発信していきます！
                </div>
              </div>
            </div>
            <div className="md:hidden">
              <TableOfContents blocks={blocks} onClick={onTableOfContentsClick} />
            </div>
            <div className={classNames("flex flex-col gap-4", className)}>
              {blocks.map((block) => {
                const { id, type, data } = block;
                switch (type) {
                  case "header":
                    return <ArticleHeadingBlock key={id} id={id} {...data} />;
                  case "paragraph":
                    return <ArticleParagraphBlock key={id} {...data} />;
                  case "image":
                    return <ArticleImageBlock key={id} src={toStrapiImageURL(data.file.url)} />;
                  case "list":
                    return <ArticleListBlock key={id} {...data} className="ml-3" />;
                  case "checklist":
                    return <ArticleCheckListBlock key={id} {...data} className="ml-3" />;
                  case "quote":
                    return <ArticleQuoteBlock key={id} {...data} />;
                  case "code":
                    return <ArticleCodeBlock key={id} {...data} />;
                  case "embed":
                    return <ArticleEmbedBlock key={id} {...data} />;
                  case "warning":
                    return <ArticleWarningBlock key={id} {...data} />;
                  case "delimiter":
                    return <ArticleDelimiterBlock key={id} />;
                  case "table":
                    return <ArticleTableBlock key={id} {...data} className="my-1" />;
                  case "raw":
                    return <ArticleRawHTMLBlock key={id} {...data} />;
                  case "productLink":
                    return <ArticleProductLinkBlock key={id} {...data} />;
                  default:
                    console.log(type, data);
                    return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
