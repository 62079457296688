import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { useStoreAPI } from "shared/services/api";
import { ConfirmUserPhoneRequest, SendConfirmUserPhoneRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

export const usePhoneConfirmation = () => {
  const { accessToken, refetch } = useAuthContext();
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const api = useStoreAPI({ accessToken });

  const { mutateAsync: confirmPhone, isLoading: isConfirmPhoneLoading } = useMutation(
    ["users/confirmations/phone"],
    (data: ConfirmUserPhoneRequest) => api.confirmUserPhone(data),
    {
      onSuccess: () => {
        setTimeout(refetch, 3000);
        setIsSuccess(true);
        setIsError(false);
      },
      onError: () => {
        setIsError(true);
      },
    },
  );

  const { mutateAsync: sendConfirmationSms, isLoading: isSendConfirmationSmsLoading } = useMutation(
    ["users/confirmations/phone/send"],
    (data: SendConfirmUserPhoneRequest) => api.sendConfirmUserPhone(data),
  );

  return {
    confirmPhone,
    sendConfirmationSms,
    isConfirmPhoneLoading,
    isSendConfirmationSmsLoading,
    isFetching: isConfirmPhoneLoading || isSendConfirmationSmsLoading,
    isSuccess,
    isError,
  };
};
