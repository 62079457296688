/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCostcoProductLogsRequestCostcoProductLogsInner } from './PostCostcoProductLogsRequestCostcoProductLogsInner';
import {
    PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSON,
    PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSONTyped,
    PostCostcoProductLogsRequestCostcoProductLogsInnerToJSON,
} from './PostCostcoProductLogsRequestCostcoProductLogsInner';

/**
 * 
 * @export
 * @interface PostCostcoProductLogsRequest
 */
export interface PostCostcoProductLogsRequest {
    /**
     * 
     * @type {Array<PostCostcoProductLogsRequestCostcoProductLogsInner>}
     * @memberof PostCostcoProductLogsRequest
     */
    costcoProductLogs: Array<PostCostcoProductLogsRequestCostcoProductLogsInner>;
    /**
     * リアルタイムのサーベイデータかどうか。true なら販売中フラグの更新に使用される
     * @type {boolean}
     * @memberof PostCostcoProductLogsRequest
     */
    isRealtime?: boolean;
}

/**
 * Check if a given object implements the PostCostcoProductLogsRequest interface.
 */
export function instanceOfPostCostcoProductLogsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProductLogs" in value;

    return isInstance;
}

export function PostCostcoProductLogsRequestFromJSON(json: any): PostCostcoProductLogsRequest {
    return PostCostcoProductLogsRequestFromJSONTyped(json, false);
}

export function PostCostcoProductLogsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCostcoProductLogsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProductLogs': ((json['costco_product_logs'] as Array<any>).map(PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSON)),
        'isRealtime': !exists(json, 'is_realtime') ? undefined : json['is_realtime'],
    };
}

export function PostCostcoProductLogsRequestToJSON(value?: PostCostcoProductLogsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product_logs': ((value.costcoProductLogs as Array<any>).map(PostCostcoProductLogsRequestCostcoProductLogsInnerToJSON)),
        'is_realtime': value.isRealtime,
    };
}

