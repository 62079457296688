/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorBadRequestAllOfError } from './ResponseErrorBadRequestAllOfError';
import {
    ResponseErrorBadRequestAllOfErrorFromJSON,
    ResponseErrorBadRequestAllOfErrorFromJSONTyped,
    ResponseErrorBadRequestAllOfErrorToJSON,
} from './ResponseErrorBadRequestAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorBadRequestAllOf
 */
export interface ResponseErrorBadRequestAllOf {
    /**
     * 
     * @type {ResponseErrorBadRequestAllOfError}
     * @memberof ResponseErrorBadRequestAllOf
     */
    error: ResponseErrorBadRequestAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorBadRequestAllOf interface.
 */
export function instanceOfResponseErrorBadRequestAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorBadRequestAllOfFromJSON(json: any): ResponseErrorBadRequestAllOf {
    return ResponseErrorBadRequestAllOfFromJSONTyped(json, false);
}

export function ResponseErrorBadRequestAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorBadRequestAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorBadRequestAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorBadRequestAllOfToJSON(value?: ResponseErrorBadRequestAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorBadRequestAllOfErrorToJSON(value.error),
    };
}

