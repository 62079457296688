/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProduct } from './CostcoProduct';
import {
    CostcoProductFromJSON,
    CostcoProductFromJSONTyped,
    CostcoProductToJSON,
} from './CostcoProduct';
import type { CostcoStore } from './CostcoStore';
import {
    CostcoStoreFromJSON,
    CostcoStoreFromJSONTyped,
    CostcoStoreToJSON,
} from './CostcoStore';
import type { CostcoStoreArea } from './CostcoStoreArea';
import {
    CostcoStoreAreaFromJSON,
    CostcoStoreAreaFromJSONTyped,
    CostcoStoreAreaToJSON,
} from './CostcoStoreArea';
import type { StockupCheckoutItem } from './StockupCheckoutItem';
import {
    StockupCheckoutItemFromJSON,
    StockupCheckoutItemFromJSONTyped,
    StockupCheckoutItemToJSON,
} from './StockupCheckoutItem';
import type { StockupItem } from './StockupItem';
import {
    StockupItemFromJSON,
    StockupItemFromJSONTyped,
    StockupItemToJSON,
} from './StockupItem';

/**
 * コストコ商品の必要な仕入れ
 * @export
 * @interface Stockup
 */
export interface Stockup {
    /**
     * 
     * @type {string}
     * @memberof Stockup
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Stockup
     */
    date: Date;
    /**
     * 
     * @type {CostcoProduct}
     * @memberof Stockup
     */
    costcoProduct: CostcoProduct;
    /**
     * 
     * @type {CostcoStore}
     * @memberof Stockup
     */
    costcoStore?: CostcoStore;
    /**
     * 
     * @type {CostcoStoreArea}
     * @memberof Stockup
     */
    costcoStoreArea?: CostcoStoreArea;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    requiredQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    reservedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    stockedQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    outOfStockQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    requiredContentsQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    stockedContentsQuantity?: number;
    /**
     * 
     * @type {CostcoProduct}
     * @memberof Stockup
     */
    alternativeFor?: CostcoProduct;
    /**
     * 
     * @type {string}
     * @memberof Stockup
     */
    pickingGroup: string;
    /**
     * 
     * @type {number}
     * @memberof Stockup
     */
    listingOrder: number;
    /**
     * 
     * @type {string}
     * @memberof Stockup
     */
    memo?: string;
    /**
     * 
     * @type {Array<StockupCheckoutItem>}
     * @memberof Stockup
     */
    checkoutItems: Array<StockupCheckoutItem>;
    /**
     * 
     * @type {Array<StockupItem>}
     * @memberof Stockup
     */
    stockupItems: Array<StockupItem>;
    /**
     * 
     * @type {Date}
     * @memberof Stockup
     */
    updatedAt: Date;
}

/**
 * Check if a given object implements the Stockup interface.
 */
export function instanceOfStockup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "costcoProduct" in value;
    isInstance = isInstance && "requiredQuantity" in value;
    isInstance = isInstance && "reservedQuantity" in value;
    isInstance = isInstance && "stockedQuantity" in value;
    isInstance = isInstance && "outOfStockQuantity" in value;
    isInstance = isInstance && "requiredContentsQuantity" in value;
    isInstance = isInstance && "pickingGroup" in value;
    isInstance = isInstance && "listingOrder" in value;
    isInstance = isInstance && "checkoutItems" in value;
    isInstance = isInstance && "stockupItems" in value;
    isInstance = isInstance && "updatedAt" in value;

    return isInstance;
}

export function StockupFromJSON(json: any): Stockup {
    return StockupFromJSONTyped(json, false);
}

export function StockupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stockup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'costcoProduct': CostcoProductFromJSON(json['costco_product']),
        'costcoStore': !exists(json, 'costco_store') ? undefined : CostcoStoreFromJSON(json['costco_store']),
        'costcoStoreArea': !exists(json, 'costco_store_area') ? undefined : CostcoStoreAreaFromJSON(json['costco_store_area']),
        'requiredQuantity': json['required_quantity'],
        'reservedQuantity': json['reserved_quantity'],
        'stockedQuantity': json['stocked_quantity'],
        'outOfStockQuantity': json['out_of_stock_quantity'],
        'requiredContentsQuantity': json['required_contents_quantity'],
        'stockedContentsQuantity': !exists(json, 'stocked_contents_quantity') ? undefined : json['stocked_contents_quantity'],
        'alternativeFor': !exists(json, 'alternative_for') ? undefined : CostcoProductFromJSON(json['alternative_for']),
        'pickingGroup': json['picking_group'],
        'listingOrder': json['listing_order'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'checkoutItems': ((json['checkout_items'] as Array<any>).map(StockupCheckoutItemFromJSON)),
        'stockupItems': ((json['stockup_items'] as Array<any>).map(StockupItemFromJSON)),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function StockupToJSON(value?: Stockup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
        'costco_product': CostcoProductToJSON(value.costcoProduct),
        'costco_store': CostcoStoreToJSON(value.costcoStore),
        'costco_store_area': CostcoStoreAreaToJSON(value.costcoStoreArea),
        'required_quantity': value.requiredQuantity,
        'reserved_quantity': value.reservedQuantity,
        'stocked_quantity': value.stockedQuantity,
        'out_of_stock_quantity': value.outOfStockQuantity,
        'required_contents_quantity': value.requiredContentsQuantity,
        'stocked_contents_quantity': value.stockedContentsQuantity,
        'alternative_for': CostcoProductToJSON(value.alternativeFor),
        'picking_group': value.pickingGroup,
        'listing_order': value.listingOrder,
        'memo': value.memo,
        'checkout_items': ((value.checkoutItems as Array<any>).map(StockupCheckoutItemToJSON)),
        'stockup_items': ((value.stockupItems as Array<any>).map(StockupItemToJSON)),
        'updated_at': (value.updatedAt.toISOString()),
    };
}

