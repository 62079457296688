import classNames from "classnames";
import { CodeInput, getSegmentCssWidth } from "rci";
import { ChangeEventHandler, useCallback, useEffect, useRef, useState } from "react";

import { Button } from "shared/components";
import { CFC } from "shared/types";

import { LoadingOverlay } from "~/components";
import { useAuthContext } from "~/features/auth/hooks";

import { PhoneConfirmationData, PhoneConfirmationForm } from "../../forms";
import { usePhoneConfirmation } from "../../hooks";

export const PhoneConfirmedRoute: CFC = ({
  children,
}) => {
  const { user, isLoading } = useAuthContext();
  const {
    sendConfirmationSms,
    confirmPhone,
    isSendConfirmationSmsLoading,
    isConfirmPhoneLoading,
    isSuccess,
    isError,
  } = usePhoneConfirmation();
  const { confirmedPhone } = user;
  const [sent, setSent] = useState(false);
  const [verified, setVerified] = useState(false);
  const [phone, setPhone] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const padding = "10px";
  const width = getSegmentCssWidth(padding);

  const handleSend = useCallback(async (data: PhoneConfirmationData) => {
    const { phone } = data;
    setPhone(phone);
    return sendConfirmationSms(data).then(() => {
      setSent(true);
    });
  }, [sendConfirmationSms]);

  const handleCodeChange: ChangeEventHandler<HTMLInputElement> = useCallback(async ({ currentTarget: input }) => {
    input.value = input.value.replace(/\D/g, "");
    if (input.value.length === 6) {
      try {
        await confirmPhone({ confirmationCode: input.value });
        setVerified(true);
      } catch (error) {
        console.log(error);
      }
    }
  }, [confirmPhone]);

  useEffect(() => {
    if (confirmedPhone) {
      setSent(false);
    }
  }, [user, confirmedPhone]);

  useEffect(() => {
    if (isError) {
      // 認証に失敗したとき、コード入力欄にフォーカスを戻す
      inputRef.current?.focus();
    }
  }, [isError]);

  if (isLoading) {
    return null;
  }

  if (confirmedPhone) {
    return <>{children}</>;
  }

  if (verified) {
    return (
      <div className="flex flex-col gap-4 bg-white p-3 py-6">
        <h1 className="font-bold text-center">電話番号認証が完了しました</h1>
        <div className="flex items-center justify-center text-5xl">
          🎉
        </div>
        <div>
          引き続き買い物をお楽しみください
        </div>
      </div>
    );
  }

  const segmentClassNames = classNames("border rounded-lg", {
    "ring-4 ring-red-300": isError,
    "ring-4 ring-green-300": isSuccess,
  });

  return (
    <div className="flex flex-col gap-4 bg-white p-3 py-6">
      <h1 className="font-bold text-center">電話番号認証が完了していません</h1>
      <div>
        お手数ですが、電話番号認証を完了させてください
      </div>
      {sent ? (
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-2">
              <label className="font-bold">電話番号</label>
              {phone}
            </div>
            <div>
              <Button small onClick={() => setSent(false)}>変更する</Button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <label className="font-bold">認証コード</label>
            <div className="flex justify-center">
              <LoadingOverlay loading={isConfirmPhoneLoading} className="px-4">
                <CodeInput
                  inputRef={inputRef}
                  length={6}
                  autoComplete="one-time-code"
                  padding={padding}
                  spacing={padding}
                  disabled={isConfirmPhoneLoading}
                  readOnly={isConfirmPhoneLoading || isSuccess}
                  className="my-3"
                  renderSegment={({ state, index }) => (
                    <div
                      key={index}
                      data-state={state}
                      style={{ width, height: "100%" }}
                      className={classNames(segmentClassNames, {
                        "ring-4 ring-gray-200": state === "cursor",
                      })}
                    />
                  )}
                  onChange={handleCodeChange}
                />
              </LoadingOverlay>
            </div>
            {isError && (
              <div className="text-red-500 text-sm">
                認証コードが間違っています
              </div>
            )}
          </div>
        </div>
      ) : (
        <PhoneConfirmationForm
          defaultValues={{ phone }}
          onSubmit={handleSend}
          isSendingCode={isSendConfirmationSmsLoading}
        />
      )}
    </div>
  );
};
