/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * お気に入り。favoritable_type以外のフィールドはその値によって存在したりしなかったりする
 * @export
 * @interface Favorite
 */
export interface Favorite {
    /**
     * 
     * @type {string}
     * @memberof Favorite
     */
    favoritableType: FavoriteFavoritableTypeEnum;
    /**
     * 
     * @type {Product}
     * @memberof Favorite
     */
    product?: Product;
}


/**
 * @export
 */
export const FavoriteFavoritableTypeEnum = {
    Product: 'Product'
} as const;
export type FavoriteFavoritableTypeEnum = typeof FavoriteFavoritableTypeEnum[keyof typeof FavoriteFavoritableTypeEnum];


/**
 * Check if a given object implements the Favorite interface.
 */
export function instanceOfFavorite(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "favoritableType" in value;

    return isInstance;
}

export function FavoriteFromJSON(json: any): Favorite {
    return FavoriteFromJSONTyped(json, false);
}

export function FavoriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Favorite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favoritableType': json['favoritable_type'],
        'product': !exists(json, 'product') ? undefined : ProductFromJSON(json['product']),
    };
}

export function FavoriteToJSON(value?: Favorite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favoritable_type': value.favoritableType,
        'product': ProductToJSON(value.product),
    };
}

