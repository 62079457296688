import { z } from "zod";

import {
  Button,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  email: z.string().min(1).email(),
});

export type PasswordNewData = z.infer<typeof schema>;

export const PasswordNewForm = createHookForm<PasswordNewData>(({
  formState: { isSubmitting },
}) => {
  return (
    <div className="flex flex-col gap-4">
      <TextControlGroup name="email" label="メールアドレス" placeholder="taro@soctoc.jp" required />
      <Button type="submit" block primary large disabled={isSubmitting}>
        送信
      </Button>
    </div>
  );
}, {
  schema,
});
