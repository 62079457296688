/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCheckoutFullfillmentShipping201ResponseAllOfData } from './PostCheckoutFullfillmentShipping201ResponseAllOfData';
import {
    PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSON,
    PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSONTyped,
    PostCheckoutFullfillmentShipping201ResponseAllOfDataToJSON,
} from './PostCheckoutFullfillmentShipping201ResponseAllOfData';

/**
 * 
 * @export
 * @interface PostCheckoutFullfillmentShipping201Response
 */
export interface PostCheckoutFullfillmentShipping201Response {
    /**
     * 
     * @type {PostCheckoutFullfillmentShipping201ResponseAllOfData}
     * @memberof PostCheckoutFullfillmentShipping201Response
     */
    data: PostCheckoutFullfillmentShipping201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof PostCheckoutFullfillmentShipping201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the PostCheckoutFullfillmentShipping201Response interface.
 */
export function instanceOfPostCheckoutFullfillmentShipping201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function PostCheckoutFullfillmentShipping201ResponseFromJSON(json: any): PostCheckoutFullfillmentShipping201Response {
    return PostCheckoutFullfillmentShipping201ResponseFromJSONTyped(json, false);
}

export function PostCheckoutFullfillmentShipping201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCheckoutFullfillmentShipping201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function PostCheckoutFullfillmentShipping201ResponseToJSON(value?: PostCheckoutFullfillmentShipping201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PostCheckoutFullfillmentShipping201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

