import classNames from "classnames";
import { useRouter } from "next/router";
import { createContext, useCallback, useContext, useEffect, useState, UIEvent, useRef } from "react";

import { TagFamilyTag } from "shared/models";
import { CFC } from "shared/types";

import { StandardLayout } from "~/components";
import { routes } from "~/constants";

import { useProductCategories, useProductsQueryParams } from "../../hooks";
import { ProductCategorySelector } from "../ProductCategorySelector";
import { ProductSubCategorySelector } from "../ProductSubCategorySelector";

type ContextType = {
  selectedCategory: TagFamilyTag | null;
};

const ProductExplorerContext = createContext<ContextType>({
  selectedCategory: null,
});

export const useProductExplorer = () => useContext(ProductExplorerContext);

type Props = {
  selectedCategory?: TagFamilyTag | null
}

export const ProductExplorerLayout: CFC<Props> = ({
  selectedCategory = null,
  children,
}) => {
  const router = useRouter();
  const { categories } = useProductCategories();
  const [selectedMainCategory, setSelectedMainCategory] = useState<TagFamilyTag | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<TagFamilyTag | null>(null);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);

  const { parsedQueryParams } = useProductsQueryParams();

  const onMainSelect = useCallback((category: TagFamilyTag) => {
    setSelectedMainCategory(category);
    router.push(routes.CATEGORY_PRODUCTS(category.id, parsedQueryParams));
  }, [router, parsedQueryParams]);

  const onSubSelect = useCallback((category: TagFamilyTag) => {
    setSelectedSubCategory(category);
    router.push(routes.CATEGORY_PRODUCTS(category.id, parsedQueryParams));
  }, [router, parsedQueryParams]);

  useEffect(() => {
    const allCategories = categories.flatMap((c) => [c, ...c.getChildren(true)]);
    const category = allCategories.find((c) => c.id === selectedCategory?.id);
    if (category) {
      if (!category.parent) {
        setSelectedMainCategory(category);
        setSelectedSubCategory(null);
      } else if (category.depth === 1) {
        setSelectedMainCategory(category);
        setSelectedSubCategory(null);
      } else {
        setSelectedMainCategory(category.getParent());
        setSelectedSubCategory(category);
      }
    } else {
      setSelectedMainCategory(null);
      setSelectedSubCategory(null);
    }
  }, [categories, selectedCategory]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback((e: UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollWidth, clientWidth } = e.currentTarget;
    setShowLeftShadow(scrollLeft > 0);
    setShowRightShadow(scrollLeft + clientWidth + 1 < scrollWidth);
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      setShowLeftShadow(scrollRef.current.scrollLeft > 0);
      setShowRightShadow(scrollRef.current.scrollLeft + scrollRef.current.clientWidth + 1 < scrollRef.current.scrollWidth);
    }
  }, [categories]);

  // 折り返さないflex要素を含むDOMのwidth計算がどうもうまくいかないので、
  // widthの幅計算を手書きする
  const fixWidthClasses = "w-screen md:w-full";

  return (
    <StandardLayout>
      <ProductExplorerContext.Provider value={{ selectedCategory }}>
        <div className={classNames("sticky top-12 z-30 shadow-md bg-white", fixWidthClasses)}>
          <div
            ref={scrollRef}
            onScroll={onScroll}
            className="overflow-x-auto hidden-scrollbar"
          >
            <ProductCategorySelector
              categories={categories}
              onSelect={onMainSelect}
              selectedCategory={selectedMainCategory}
            />
          </div>
          {showLeftShadow && (
            <div className="absolute top-0 left-0 w-12 h-full bg-gradient-to-r from-white to-transparent" />
          )}
          {showRightShadow && (
            <div className="absolute top-0 right-0 w-12 h-full bg-gradient-to-l from-white to-transparent" />
          )}
        </div>
        {selectedMainCategory && selectedMainCategory.children.length > 0 && (
          <div className={classNames("overflow-x-auto hidden-scrollbar pt-4 px-3 bg-white", fixWidthClasses)}>
            <ProductSubCategorySelector
              categories={selectedMainCategory.getChildren(true)}
              onSelect={onSubSelect}
              selectedCategory={selectedSubCategory}
            />
          </div>
        )}
        {children}
      </ProductExplorerContext.Provider>
    </StandardLayout>
  );
};
