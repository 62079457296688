/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDeliveryDriverRequestDeliveryDriver
 */
export interface UpdateDeliveryDriverRequestDeliveryDriver {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDriverRequestDeliveryDriver
     */
    handle?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDriverRequestDeliveryDriver
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDriverRequestDeliveryDriver
     */
    phone?: string;
}

/**
 * Check if a given object implements the UpdateDeliveryDriverRequestDeliveryDriver interface.
 */
export function instanceOfUpdateDeliveryDriverRequestDeliveryDriver(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDeliveryDriverRequestDeliveryDriverFromJSON(json: any): UpdateDeliveryDriverRequestDeliveryDriver {
    return UpdateDeliveryDriverRequestDeliveryDriverFromJSONTyped(json, false);
}

export function UpdateDeliveryDriverRequestDeliveryDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeliveryDriverRequestDeliveryDriver {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'handle': !exists(json, 'handle') ? undefined : json['handle'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
    };
}

export function UpdateDeliveryDriverRequestDeliveryDriverToJSON(value?: UpdateDeliveryDriverRequestDeliveryDriver | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'handle': value.handle,
        'name': value.name,
        'phone': value.phone,
    };
}

