import classNames from "classnames";

import { BFC } from "shared/types";

import { CardBrandLogo } from "~/components";

type Props = {
  brand: string;
  last4: string;
};

export const MaskedCardDetail: BFC<Props> = ({ brand, last4, className }) => {
  return (
    <div className={classNames("flex items-center gap-2", className)}>
      <CardBrandLogo brand={brand} /> **** {last4}
    </div>
  );
};
