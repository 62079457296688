/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * トークン
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    refreshToken: string;
    /**
     * 
     * @type {Date}
     * @memberof Token
     */
    expiresAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Token
     */
    refreshExpiresAt: Date;
}

/**
 * Check if a given object implements the Token interface.
 */
export function instanceOfToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "refreshToken" in value;
    isInstance = isInstance && "expiresAt" in value;
    isInstance = isInstance && "refreshExpiresAt" in value;

    return isInstance;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'refreshToken': json['refresh_token'],
        'expiresAt': (new Date(json['expires_at'])),
        'refreshExpiresAt': (new Date(json['refresh_expires_at'])),
    };
}

export function TokenToJSON(value?: Token | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'refresh_token': value.refreshToken,
        'expires_at': (value.expiresAt.toISOString()),
        'refresh_expires_at': (value.refreshExpiresAt.toISOString()),
    };
}

