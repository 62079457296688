/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
 */
export interface PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner {
    /**
     * 
     * @type {string}
     * @memberof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
     */
    alternativeProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
     */
    cancelQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner
     */
    cancelReason?: PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerCancelReasonEnum;
}


/**
 * @export
 */
export const PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerCancelReasonEnum = {
    No: 'no',
    OutOfStock: 'out_of_stock'
} as const;
export type PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerCancelReasonEnum = typeof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerCancelReasonEnum[keyof typeof PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerCancelReasonEnum];


/**
 * Check if a given object implements the PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner interface.
 */
export function instanceOfPostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSON(json: any): PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner {
    return PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSONTyped(json, false);
}

export function PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'alternativeProductId': !exists(json, 'alternative_product_id') ? undefined : json['alternative_product_id'],
        'quantity': json['quantity'],
        'cancelQuantity': !exists(json, 'cancel_quantity') ? undefined : json['cancel_quantity'],
        'cancelReason': !exists(json, 'cancel_reason') ? undefined : json['cancel_reason'],
    };
}

export function PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerToJSON(value?: PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'alternative_product_id': value.alternativeProductId,
        'quantity': value.quantity,
        'cancel_quantity': value.cancelQuantity,
        'cancel_reason': value.cancelReason,
    };
}

