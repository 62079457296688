import { CheckoutItem } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  item: CheckoutItem;
}

export const CheckoutCancelStatusBadge: BFC<Props> = ({ item }) => {
  if (!item.cancelQuantity) {
    return null;
  };

  return (
    <div className="flex items-center rounded-full inline-block px-2 py-1 text-xs whitespace-nowrap w-max bg-gray-200 text-gray-500">
      {item.quantity === 0 && <div>店頭での在庫切れ</div>}
      {item.quantity > 0 && <div>{`${item.cancelQuantity}点店頭での在庫切れ`}</div>}
    </div>
  );
};