import { createContext, useContext } from "react";

import { Cart, Checkout } from "shared/models";
import { CFC } from "shared/types";

import { useCheckoutService } from "./useCheckoutService";

type CheckoutServiceContextType = ReturnType<typeof useCheckoutService>;

const CheckoutServiceContext = createContext<CheckoutServiceContextType>({
  cart: new Cart(),
  checkout: new Checkout(),
  address: null,
  setAddress: () => void(0),
  paymentMethod: null,
  setPaymentMethod: () => void(0),
  parcels: [],
  updateParcel: () => void(0),
  removeParcel: () => void(0),
  changeAlternativeProduct: () => void(0),
  couponCode: null,
  applyCouponCode: () => Promise.resolve(),
  execute: () => Promise.resolve(),
  agreedToRedeliveryCommissionRate: false,
  setAgreedToRedeliveryCommissionRate: () => void(0),
  isFailed: false,
  isDeliverable: false,
  isAllParcelsDeliverable: false,
  isReady: false,
  isInitialized: false,
  isLoading: false,
  isCheckoutProcessing: false,
  isFailedPayment: false,
  errorMessages: new Map,
});

export const useCheckoutServiceContext = () => {
  return useContext(CheckoutServiceContext);
};

export const CheckoutServiceProvider: CFC = ({ children }) => {
  const checkout = useCheckoutService();

  return (
    <CheckoutServiceContext.Provider value={checkout}>
      {children}
    </CheckoutServiceContext.Provider>
  );
};
