import { immerable } from "immer";

import * as api from "../services/api/store/models";
import { Checkout } from "./checkout";
import { Stock } from "./stock";

export type StockConsumptionWithStockType = api.StockConsumptionWithStock;

/**
 * 在庫の消費 (在庫付き)
 */
export class StockConsumptionWithStock
  implements StockConsumptionWithStockType
{
  [immerable] = true;

  stock = new Stock();
  consumedContentsQuantity = 1;
  createdAt = new Date();

  constructor(data: Partial<StockConsumptionWithStockType> = {}) {
    Object.assign(this, data);
  }
}

export type StockConsumptionWithCheckoutType = api.StockConsumptionWithCheckout;

/**
 * 在庫の消費 (チェックアウト付き)
 */
export class StockConsumptionWithCheckout
  implements StockConsumptionWithCheckoutType
{
  [immerable] = true;

  checkout = new Checkout();
  consumedContentsQuantity = 1;
  createdAt = new Date();

  constructor(data: Partial<StockConsumptionWithCheckoutType> = {}) {
    Object.assign(this, data);
  }
}
