import { useRouter } from "next/router";
import { useCallback } from "react";
import { Button, Link } from "~/../../packages/shared/components";

import { BFC } from "shared/types";

import { TitleHeader } from "~/components";

import { PaymentMethodForm, PaymentMethodData } from "../../forms";
import { usePaymentMethods } from "../../hooks";

type Props = {
  returnTo: string;
};

export const PaymentMethodNewPage: BFC<Props> = ({
  returnTo,
}) => {
  const { create, defaultPaymentMethod } = usePaymentMethods();
  const showDefault = !!defaultPaymentMethod;
  const router = useRouter();

  const onSubmit = useCallback(async (data: PaymentMethodData) => {
    await create(data);
    router.push(returnTo);
  }, [returnTo]);

  return (
    <div className="bg-white">
      <TitleHeader title="お支払い方法の追加" className="border-b" backTo={returnTo} />
      <div className="p-3 flex flex-col gap-3">
        <div>
          <PaymentMethodForm
            onSubmit={onSubmit}
            showDefault={showDefault}
          />
        </div>
        <Link href={returnTo}>
          <Button block>キャンセル</Button>
        </Link>
      </div>
    </div>
  );
};
