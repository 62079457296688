import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { OnSubmitType } from "shared/lib/hook-form";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { NameEditForm, NameEditData } from "../../forms";
import { useUpdateName } from "../../hooks";

export const NameEditPage: BFC = () => {
  const router = useRouter();
  const handleUpdate = useCallback(() => {
    // TODO: toast
    router.push(routes.MYPAGE);
  }, [router]);

  const { user } = useAuthContext();
  const { update } = useUpdateName({
    onSuccess: handleUpdate,
  });

  const onSubmitHandler: OnSubmitType<NameEditData> = useCallback(async (data) => {
    await update(data);
  }, [update]);

  const defaultValues = useMemo(() => ({
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
  }), [user]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <Link href={routes.MYPAGE} className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <HiOutlineChevronLeft size={24} className="text-black-500" />
          氏名の設定
        </h1>
      </Link>
      <div className="bg-white p-3">
        <NameEditForm onSubmit={onSubmitHandler} defaultValues={defaultValues} />
      </div>
    </div>
  );
};
