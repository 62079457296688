/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    stripePaymentId: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    stripeClientSecret: string;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    capturedAmount: number;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    paidAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    expiresAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    capturesAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    capturedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    cancelesAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    canceledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status: PaymentStatusEnum;
}


/**
 * @export
 */
export const PaymentStatusEnum = {
    Pending: 'pending',
    Confirmed: 'confirmed',
    Completed: 'completed',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type PaymentStatusEnum = typeof PaymentStatusEnum[keyof typeof PaymentStatusEnum];


/**
 * Check if a given object implements the Payment interface.
 */
export function instanceOfPayment(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "stripePaymentId" in value;
    isInstance = isInstance && "stripeClientSecret" in value;
    isInstance = isInstance && "capturedAmount" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function PaymentFromJSON(json: any): Payment {
    return PaymentFromJSONTyped(json, false);
}

export function PaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Payment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stripePaymentId': json['stripe_payment_id'],
        'stripeClientSecret': json['stripe_client_secret'],
        'capturedAmount': json['captured_amount'],
        'paidAt': !exists(json, 'paid_at') ? undefined : (new Date(json['paid_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'capturesAt': !exists(json, 'captures_at') ? undefined : (new Date(json['captures_at'])),
        'capturedAt': !exists(json, 'captured_at') ? undefined : (new Date(json['captured_at'])),
        'cancelesAt': !exists(json, 'canceles_at') ? undefined : (new Date(json['canceles_at'])),
        'canceledAt': !exists(json, 'canceled_at') ? undefined : (new Date(json['canceled_at'])),
        'status': json['status'],
    };
}

export function PaymentToJSON(value?: Payment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stripe_payment_id': value.stripePaymentId,
        'stripe_client_secret': value.stripeClientSecret,
        'captured_amount': value.capturedAmount,
        'paid_at': value.paidAt === undefined ? undefined : (value.paidAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'captures_at': value.capturesAt === undefined ? undefined : (value.capturesAt.toISOString()),
        'captured_at': value.capturedAt === undefined ? undefined : (value.capturedAt.toISOString()),
        'canceles_at': value.cancelesAt === undefined ? undefined : (value.cancelesAt.toISOString()),
        'canceled_at': value.canceledAt === undefined ? undefined : (value.canceledAt.toISOString()),
        'status': value.status,
    };
}

