import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { useCallback } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";

import { CFC } from "shared/types";

type ItemProps = {
  onClick?: () => void;
  disabled?: boolean;
};

export const PopoverMenuItem: CFC<ItemProps> = ({
  onClick,
  disabled,
  children,
  className,
}) => {
  const onClickHandler = useCallback(() => {
    if (disabled) return;
    onClick?.();
  }, [onClick]);

  return (
    <Popover.Button
      as="li"
      onClick={onClickHandler}
      className={classNames(className, {
        "text-gray-300": disabled,
      })}
  >
    {children}
  </Popover.Button>
  );
};

type Props = {
  panelClassName?: string;
};

export const PopoverMenu: CFC<Props> & { Item: typeof PopoverMenuItem } = ({
  className,
  panelClassName,
  children,
}) => {
  return (
    <Popover
      className={classNames(className, {
        "relative": !className?.includes("absolute"),
      })}
    >
      <Popover.Button>
        <HiOutlineDotsHorizontal className="text-gray-500" size={20} />
      </Popover.Button>
      <Popover.Panel className={classNames("absolute bg-white rounded shadow-md right-0 top-4 border", panelClassName)}>
        <ul className="flex flex-col w-40 [&>li]:p-3 text-sm">
          {children}
        </ul>
      </Popover.Panel>
    </Popover>
  );
};

PopoverMenu.Item = PopoverMenuItem;
