import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { isAPIError } from "~/../../packages/shared/types";

import { Cart } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { AddCartItemRequest, CreateCartRequest, RemoveCartItemRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

import { useShopifyCart } from "./useShopifyCart";

const { persistAtom } = recoilPersist();

const cartIdState = atom<string>({
  key: "carts/useCart/cartId/v2",
  default: "",
  effects: [persistAtom],
});

type WithCartId<T> = T & { cartId?: string };

export type CartItemOptions = {
  quantity?: number;
  alternativeProductId?: string;
  removeAlternativeProduct?: boolean;
  cartId?: string;
};

export const useCart = () => {
  const { cartId: shopifyCartId, waitCartReady: waitShopifyCartReady, setCartId: setShopifyCartId } = useShopifyCart();
  const [cartId, setCartId] = useRecoilState(cartIdState);
  const { token, isAccidentallySignedOut } = useAuthContext();
  const cartRef = useRef<Cart>(new Cart());
  const cart = cartRef.current;
  const [forHydoration, setForHydration] = useState(false); // nextjsのhydoration error 対策

  const api = useStoreAPI({ accessToken: token });

  const { isLoading } = useQuery(
    ["carts", cartId],
    () => api.getCart(cartId),
    {
      enabled: !!cartId,
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
      },
      onError: (e) => {
        // 指定したカートが存在しない場合は新規カートを作成する
        if (isAPIError(e) && e.response?.status === 404) {
          createCart();
        }
      },
    },
  );

  const { mutateAsync: createCartMutate, isLoading: isCreateCartLoading } = useMutation(
    (params?: CreateCartRequest) => api.createCart(params),
    {
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
        setCartId(cart.id);
      },
    },
  );

  const { mutateAsync: addCartItemMutate, isLoading: isCartAddItemLoading } = useMutation(
    ["carts/addCartItem", cartId],
    (params: WithCartId<AddCartItemRequest>) => api.addCartItem(params.cartId || cartId, params),
    {
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
      },
    },
  );

  const { mutateAsync: updateCartItemMutate, isLoading: isCartUpdateItemLoading } = useMutation(
    ["carts/updateCartItem", cartId],
    (params: WithCartId<AddCartItemRequest>) => api.updateCartItem(params.cartId || cartId, params),
    {
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
      },
    },
  );

  const { mutateAsync: removeCartItemMutate, isLoading: isCartRemoveItemLoading } = useMutation(
    ["carts/removeCartItem", cartId],
    (params: WithCartId<RemoveCartItemRequest>) => api.removeCartItem(params.cartId || cartId, params),
    {
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
      },
    },
  );

  const { mutateAsync: associateToUserMutate, isLoading: isCartAssociateToUserLoading } = useMutation(
    ["carts/associateToUser", cartId],
    (id: string) => api.associateCartUser(id),
    {
      onSuccess: ({ data: { cart } }) => {
        cartRef.current = new Cart(cart);
      },
    },
  );

  const createCart = useCallback(async () => {
    return createCartMutate({}).then(({ data: { cart } }) => {
      return new Cart(cart);
    });
  }, [createCartMutate]);

  const createCartIfNotExists = useCallback(async () => {
    if (!cartId) {
      return createCart();
    } else {
      return cartRef.current;
    }
  }, [cartId, createCart]);

  const addCartItem = useCallback(async (productId: string, options?: CartItemOptions) => {
    const {
      cartId: _cartId,
      quantity = 1,
      alternativeProductId,
      removeAlternativeProduct,
    } = options || {};

    let cartId = _cartId;

    if (!cartId) {
      cartId = (await createCartIfNotExists()).id;
    }

    return addCartItemMutate({
      cartId,
      item: {
        productId,
        quantity,
        alternativeProductId,
        removeAlternativeProduct,
      },
    });
  }, [addCartItemMutate, createCartIfNotExists]);

  const updateCartItem = useCallback(async (productId: string, options?: CartItemOptions) => {
    const {
      cartId: _cartId,
      quantity = 1,
      alternativeProductId,
      removeAlternativeProduct,
    } = options || {};

    let cartId = _cartId;
    if (!cartId) {
      cartId = (await createCartIfNotExists()).id;
    }

    return updateCartItemMutate({
      cartId,
      item: {
        productId,
        quantity,
        alternativeProductId,
        removeAlternativeProduct,
      },
    });
  }, [updateCartItemMutate, createCartIfNotExists]);

  const removeCartItem = useCallback(async (productId: string, cartId?: string) => {
    if (!cartId) {
      cartId = (await createCartIfNotExists()).id;
    }

    return removeCartItemMutate({
      cartId,
      item: {
        productId,
      },
    });
  }, [removeCartItemMutate, createCartIfNotExists]);

  const clearCart = useCallback(async () => {
    for (const item of cart.items) {
      await removeCartItem(item.product.id);
    }
  }, [cart]);

  const unsetCart = useCallback(() => {
    cartRef.current = new Cart();
    setCartId("");
  }, []);

  const associateToUser = useCallback(async (id?: string) => {
    if (id) {
      return associateToUserMutate(id);
    }
  }, [associateToUserMutate]);

  const waitCartReady = useCallback(() => {
    return new Promise<Cart>((resolve) => {
      setTimeout(function waiter() {
        if (!cartId || cartRef.current.id) {
          resolve(cartRef.current);
        } else {
          setTimeout(waiter, 100);
        }
      });
    });
  }, [cartRef, cartId]);

  useEffect(() => {
    setForHydration(true);
  }, []);

  useEffect(() => {
    if (isAccidentallySignedOut) {
      createCart();
    }
  }, [isAccidentallySignedOut]);

  // shopyfy側のカートに入っている商品を移行する
  useEffect(() => {
    (async () => {
      if (shopifyCartId) {
        const cart = await waitShopifyCartReady();
        for (const item of cart.items) {
          await addCartItem(item.product.id, { quantity: item.quantity });
        }
        setShopifyCartId("");
      }
    })();
  }, [shopifyCartId, waitShopifyCartReady]);

  return {
    cart,
    createCart,
    addCartItem,
    updateCartItem,
    removeCartItem,
    clearCart,
    unsetCart,
    associateToUser,
    waitCartReady,
    setCartId,
    isLoading: !forHydoration || (!!cartId && (isLoading || isCreateCartLoading)),
    isMutating:
      isCreateCartLoading || isCartAddItemLoading || isCartUpdateItemLoading || isCartRemoveItemLoading || isCartAssociateToUserLoading,
  };
};
