import { PaymentStatus } from "shared/models/payment";

export function formatPaymentStatus(status: PaymentStatus) {
  switch (status) {
    case PaymentStatus.Pending:
      return "未決済";
    case PaymentStatus.Confirmed:
      return "与信済";
    case PaymentStatus.Completed:
      return "決済済";
    case PaymentStatus.Canceled:
      return "キャンセル済";
  }
}
