/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutParcel } from './CheckoutParcel';
import {
    CheckoutParcelFromJSON,
    CheckoutParcelFromJSONTyped,
    CheckoutParcelToJSON,
} from './CheckoutParcel';
import type { DeliveryParcel } from './DeliveryParcel';
import {
    DeliveryParcelFromJSON,
    DeliveryParcelFromJSONTyped,
    DeliveryParcelToJSON,
} from './DeliveryParcel';

/**
 * 
 * @export
 * @interface GetDeliveryParcels200ResponseAllOfData
 */
export interface GetDeliveryParcels200ResponseAllOfData {
    /**
     * 
     * @type {Array<DeliveryParcel>}
     * @memberof GetDeliveryParcels200ResponseAllOfData
     */
    deliveryParcels: Array<DeliveryParcel>;
    /**
     * 
     * @type {Array<CheckoutParcel>}
     * @memberof GetDeliveryParcels200ResponseAllOfData
     */
    unassignedParcels: Array<CheckoutParcel>;
}

/**
 * Check if a given object implements the GetDeliveryParcels200ResponseAllOfData interface.
 */
export function instanceOfGetDeliveryParcels200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryParcels" in value;
    isInstance = isInstance && "unassignedParcels" in value;

    return isInstance;
}

export function GetDeliveryParcels200ResponseAllOfDataFromJSON(json: any): GetDeliveryParcels200ResponseAllOfData {
    return GetDeliveryParcels200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetDeliveryParcels200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliveryParcels200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryParcels': ((json['delivery_parcels'] as Array<any>).map(DeliveryParcelFromJSON)),
        'unassignedParcels': ((json['unassigned_parcels'] as Array<any>).map(CheckoutParcelFromJSON)),
    };
}

export function GetDeliveryParcels200ResponseAllOfDataToJSON(value?: GetDeliveryParcels200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_parcels': ((value.deliveryParcels as Array<any>).map(DeliveryParcelToJSON)),
        'unassigned_parcels': ((value.unassignedParcels as Array<any>).map(CheckoutParcelToJSON)),
    };
}

