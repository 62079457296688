/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface TimelineCheckout
 */
export interface TimelineCheckout {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckout
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckout
     */
    event: TimelineCheckoutEventEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimelineCheckout
     */
    occurredAt: Date;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof TimelineCheckout
     */
    initiator?: TimelineInitiator;
    /**
     * 
     * @type {object}
     * @memberof TimelineCheckout
     */
    data?: object;
}


/**
 * @export
 */
export const TimelineCheckoutEventEnum = {
    Created: 'created',
    FullfilledStocking: 'fullfilled_stocking',
    FullfilledUnstocking: 'fullfilled_unstocking',
    FullfilledShipping: 'fullfilled_shipping',
    FullfilledUnshipping: 'fullfilled_unshipping',
    Canceled: 'canceled',
    Refunded: 'refunded',
    Captured: 'captured',
    Completed: 'completed',
    Failed: 'failed',
    Expired: 'expired',
    Paid: 'paid',
    FullfilledShippingMail: 'fullfilled_shipping_mail',
    RedeliveryCreated: 'redelivery_created',
    RedeliveryCanceled: 'redelivery_canceled'
} as const;
export type TimelineCheckoutEventEnum = typeof TimelineCheckoutEventEnum[keyof typeof TimelineCheckoutEventEnum];


/**
 * Check if a given object implements the TimelineCheckout interface.
 */
export function instanceOfTimelineCheckout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "occurredAt" in value;

    return isInstance;
}

export function TimelineCheckoutFromJSON(json: any): TimelineCheckout {
    return TimelineCheckoutFromJSONTyped(json, false);
}

export function TimelineCheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'],
        'occurredAt': (new Date(json['occurred_at'])),
        'initiator': !exists(json, 'initiator') ? undefined : TimelineInitiatorFromJSON(json['initiator']),
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function TimelineCheckoutToJSON(value?: TimelineCheckout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event': value.event,
        'occurred_at': (value.occurredAt.toISOString()),
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': value.data,
    };
}

