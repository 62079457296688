import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { getParam } from "shared/lib/next";
import { BFC, isAPIError } from "shared/types";

import { routes } from "~/constants";

import { OAuthSignUpData, OAuthSignUpForm } from "../../forms";
import { SignConnectErrorType, useSignConnect } from "../../hooks";

const SignConnectPageState = {
  Loading: "loading", // 状態の読み込み中
  Connected: "connected", // ログインまたはアカウント接続が完了し、リダイレクト待ち
  SignUp: "sign_up", // 一度ログインを試みたが、ユーザーが存在しなかったため、新規登録フォームを表示する
  Error: "error", // API からエラーが返されたため、エラー画面を表示する
} as const;

type SignConnectPageState = typeof SignConnectPageState[keyof typeof SignConnectPageState];

export const SignConnectPage: BFC = () => {
  const router = useRouter();
  const returnTo = getParam(router.query, "return_to");
  const { connect, errorType } = useSignConnect();
  const [signConnectPageState, setSignConnectPageState] = useState<SignConnectPageState>(SignConnectPageState.Loading);

  // ページ読み込み時に一度 connect を試行する
  useEffect(() => {
    connect({}).then(() => {
      setSignConnectPageState(SignConnectPageState.Connected);
    }).catch((e) => {
      if (isAPIError(e) && e.response.status === 400) {
        // Bad Request が返された場合、新規登録とみなす
        setSignConnectPageState(SignConnectPageState.SignUp);
      } else {
        setSignConnectPageState(SignConnectPageState.Error);
      }
    });
  }, []);

  // 新規登録フォーム送信時、フォームのデータを元に connect を再試行する
  const handleSignUpFormSubmit = useCallback((data: OAuthSignUpData) => {
    connect(data).then(() => {
      setSignConnectPageState(SignConnectPageState.Connected);
    }).catch(() => {
      setSignConnectPageState(SignConnectPageState.Error);
    });
  }, [connect]);

  // ログイン成功時、リダイレクトする
  useEffect(() => {
    if (signConnectPageState === SignConnectPageState.Connected) {
      router.push(returnTo || routes.TOP);
    }
  }, [signConnectPageState, returnTo]);

  const onGoogleSignUp = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_GOOGLE({ return_to: returnTo }));
  }, [returnTo]);

  if (signConnectPageState === SignConnectPageState.Error) {
    return (
      <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
        {(errorType === SignConnectErrorType.NotFound || errorType === SignConnectErrorType.Unknown) && (
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-center">アカウントの接続に失敗しました</h1>
            <div className="flex items-center justify-center text-5xl">
              😢
            </div>
            <div>
              お手数ですが、もう一度操作をお願いします
            </div>
            <Button block large className="flex items-center gap-2" onClick={onGoogleSignUp}>
              <FcGoogle size={20} />Googleアカウントで登録
            </Button>
          </div>
        )}
        {errorType === SignConnectErrorType.AlreadyConnected && (
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-center">このアカウントは既に接続済みです</h1>
            <div className="flex items-center justify-center text-5xl">
              😢
            </div>
            <div>
              お手数ですが、もう一度操作をお願いします
            </div>
            <Button block large className="flex items-center gap-2" onClick={onGoogleSignUp}>
              <FcGoogle size={20} />Googleアカウントで登録
            </Button>
          </div>
        )}
      </div>
    );
  }

  if (signConnectPageState === SignConnectPageState.SignUp) {
    return (
      <div className="bg-white p-3 py-6 pb-10 shadow">
        <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
          <h1 className="text-xl font-bold">会員登録</h1>
          <OAuthSignUpForm onSubmit={handleSignUpFormSubmit} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center w-full h-screen">
      <ColorRing />
    </div>
  );
};
