// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

export type ProductFragmentWithVariantsFragment = (
  { __typename?: 'Product' }
  & Pick<Types.Product, 'id' | 'title' | 'description' | 'descriptionHtml'>
  & { featuredImage?: Types.Maybe<(
    { __typename?: 'Image' }
    & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
  )>, priceRange: (
    { __typename?: 'ProductPriceRange' }
    & { minVariantPrice: (
      { __typename?: 'MoneyV2' }
      & Pick<Types.MoneyV2, 'amount'>
    ), maxVariantPrice: (
      { __typename?: 'MoneyV2' }
      & Pick<Types.MoneyV2, 'amount'>
    ) }
  ), variants: (
    { __typename?: 'ProductVariantConnection' }
    & { edges: Array<(
      { __typename?: 'ProductVariantEdge' }
      & { node: (
        { __typename?: 'ProductVariant' }
        & Pick<Types.ProductVariant, 'id' | 'title'>
        & { price: (
          { __typename?: 'MoneyV2' }
          & Pick<Types.MoneyV2, 'amount'>
        ), image?: Types.Maybe<(
          { __typename?: 'Image' }
          & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
        )> }
      ) }
    )> }
  ), images: (
    { __typename?: 'ImageConnection' }
    & { edges: Array<(
      { __typename?: 'ImageEdge' }
      & { node: (
        { __typename?: 'Image' }
        & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
      ) }
    )> }
  ) }
);

export const ProductFragmentWithVariantsFragmentDoc = `
    fragment ProductFragmentWithVariants on Product {
  id
  title
  description
  descriptionHtml
  featuredImage {
    ... on Image {
      id
      url
      height
      width
      altText
    }
  }
  priceRange {
    minVariantPrice {
      amount
    }
    maxVariantPrice {
      amount
    }
  }
  variants(first: 250) {
    edges {
      node {
        id
        title
        price {
          amount
        }
        image {
          ... on Image {
            id
            url
            height
            width
            altText
          }
        }
      }
    }
  }
  images(first: 250) {
    edges {
      node {
        id
        url
        height
        width
        altText
      }
    }
  }
}
    `;