/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckoutRequestChangeDatetimeOperationsInner
 */
export interface UpdateCheckoutRequestChangeDatetimeOperationsInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestChangeDatetimeOperationsInner
     */
    operation: UpdateCheckoutRequestChangeDatetimeOperationsInnerOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestChangeDatetimeOperationsInner
     */
    checkoutParcelId: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateCheckoutRequestChangeDatetimeOperationsInner
     */
    shippingDate: Date;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestChangeDatetimeOperationsInner
     */
    shippingTimeId: string;
}


/**
 * @export
 */
export const UpdateCheckoutRequestChangeDatetimeOperationsInnerOperationEnum = {
    ChangeDatetime: 'change_datetime'
} as const;
export type UpdateCheckoutRequestChangeDatetimeOperationsInnerOperationEnum = typeof UpdateCheckoutRequestChangeDatetimeOperationsInnerOperationEnum[keyof typeof UpdateCheckoutRequestChangeDatetimeOperationsInnerOperationEnum];


/**
 * Check if a given object implements the UpdateCheckoutRequestChangeDatetimeOperationsInner interface.
 */
export function instanceOfUpdateCheckoutRequestChangeDatetimeOperationsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operation" in value;
    isInstance = isInstance && "checkoutParcelId" in value;
    isInstance = isInstance && "shippingDate" in value;
    isInstance = isInstance && "shippingTimeId" in value;

    return isInstance;
}

export function UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSON(json: any): UpdateCheckoutRequestChangeDatetimeOperationsInner {
    return UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSONTyped(json, false);
}

export function UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutRequestChangeDatetimeOperationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'checkoutParcelId': json['checkout_parcel_id'],
        'shippingDate': (new Date(json['shipping_date'])),
        'shippingTimeId': json['shipping_time_id'],
    };
}

export function UpdateCheckoutRequestChangeDatetimeOperationsInnerToJSON(value?: UpdateCheckoutRequestChangeDatetimeOperationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': value.operation,
        'checkout_parcel_id': value.checkoutParcelId,
        'shipping_date': (value.shippingDate.toISOString().substr(0,10)),
        'shipping_time_id': value.shippingTimeId,
    };
}

