import { Blob } from "@rails/activestorage";
import { useCallback } from "react";

import { useAuthContext } from "~/features/auth";

const API_ORIGIN = process.env.API_ORIGIN || process.env.NEXT_PUBLIC_API_ORIGIN;

const DIRECT_UPLOAD_URL = `${API_ORIGIN}/api/store/direct_uploads`;

export const useDirectUpload = () => {
  const { accessToken } = useAuthContext();

  /**
   * Rails の Direct Upload を利用して
   * クラウドストレージににファイルをアップロードする
   *
   * @param file アップロードするファイル
   * @returns アップロードされたファイルの Blob
   */
  const upload = useCallback(async (file: File): Promise<Blob> => {
    // SSR でエラーが出るので動的インポートしています
    const DirectUpload = (await import("@rails/activestorage")).DirectUpload;
    const directUpload = new DirectUpload(file, DIRECT_UPLOAD_URL, {
      directUploadWillCreateBlobWithXHR: (xhr) => {
        xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
      },
    });

    return new Promise((resolve, reject) => {
      directUpload.create((error, blob) => {
        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  }, []);

  return {
    upload,
  };
};
