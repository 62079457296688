import { immerable } from "immer";

import * as api from "../services/api/models";
import { Prefecture } from "./prefecture";

export type DeliverableAddressType = api.DeliverableAddress;

export class DeliverableAddress implements DeliverableAddressType {
  [immerable] = true;

  id = "";
  zipCode = "";
  prefecture = { id: "", name: "" };
  city = "";
  town = "";

  constructor(data: Partial<DeliverableAddressType> = {}) {
    Object.assign(this, data);
  }

  getPrefecture() {
    return new Prefecture(this.prefecture);
  }

  get summary() {
    return `${this.prefecture.name} ${this.city} ${this.town}`;
  }
}
