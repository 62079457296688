import { useState } from "react";

import { MagazineArticle } from "shared/models";

import { toStrapiImageURL } from "../helpers";
import { StrapiPickupArticle, StrapiResponse } from "../types";
import { useStrapiQuery } from "./useStrapiQuery";

export const useMagazinePickupArticles = () => {
  const [articles, setArticles] = useState<MagazineArticle[]>([]);

  const { data, ...rest } = useStrapiQuery<StrapiResponse<StrapiPickupArticle[]>>(
    ["magazinePickupArticles"],
    "/api/pickup-articles",
    {
      populate: ["*", "article.cover", "article.categories", "article.tags"],
      sort: ["displayPriority:desc"],
    },
    {
      onSuccess: ({ data }) => {
        setArticles(data.map(({ attributes: { article: { data: { id, attributes } } } }) => new MagazineArticle({
          id,
          title: attributes.title,
          slug: attributes.slug,
          body: attributes.body,
          cover: attributes.cover ? {
            url: toStrapiImageURL(attributes.cover.data.attributes.url),
            width: attributes.cover.data.attributes.width,
            height: attributes.cover.data.attributes.height,
          } : undefined,
          categories: attributes.categories.data.map(({ id, attributes }) => ({
            id,
            name: attributes.name,
            slug: attributes.slug,
          })),
          tags: attributes.tags.data.map(({ id, attributes }) => ({
            id,
            name: attributes.name,
            slug: attributes.slug,
          })),
          publishAt: attributes.publishAt ? new Date(attributes.publishAt) : new Date(attributes.createdAt),
          createdAt: new Date(attributes.createdAt),
          updatedAt: new Date(attributes.updatedAt),
        })));
      },
    },
  );

  return {
    articles,
    ...rest,
  };
};
