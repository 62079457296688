// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
export type CreateCustomerTokenMutationVariables = Types.Exact<{
  multipassToken: Types.Scalars['String'];
}>;


export type CreateCustomerTokenMutation = (
  { __typename?: 'Mutation' }
  & { customerAccessTokenCreateWithMultipass?: Types.Maybe<(
    { __typename?: 'CustomerAccessTokenCreateWithMultipassPayload' }
    & { customerAccessToken?: Types.Maybe<(
      { __typename?: 'CustomerAccessToken' }
      & Pick<Types.CustomerAccessToken, 'accessToken' | 'expiresAt'>
    )>, customerUserErrors: Array<(
      { __typename?: 'CustomerUserError' }
      & Pick<Types.CustomerUserError, 'code' | 'field' | 'message'>
    )> }
  )> }
);


export const CreateCustomerTokenDocument = `
    mutation CreateCustomerToken($multipassToken: String!) {
  customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
    `;
export const useCreateCustomerTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCustomerTokenMutation, TError, CreateCustomerTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateCustomerTokenMutation, TError, CreateCustomerTokenMutationVariables, TContext>(
      ['CreateCustomerToken'],
      (variables?: CreateCustomerTokenMutationVariables) => fetcher<CreateCustomerTokenMutation, CreateCustomerTokenMutationVariables>(client, CreateCustomerTokenDocument, variables, headers)(),
      options
    );
useCreateCustomerTokenMutation.fetcher = (client: GraphQLClient, variables: CreateCustomerTokenMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateCustomerTokenMutation, CreateCustomerTokenMutationVariables>(client, CreateCustomerTokenDocument, variables, headers);