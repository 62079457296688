import { DocumentTextIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { CreditCardIcon, HomeIcon, TruckIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useCallback, useState, ChangeEvent, useMemo } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { HiOutlineCheck, HiOutlineExclamationCircle, HiOutlineExclamation } from "react-icons/hi";
import { RiCoupon3Line } from "react-icons/ri";
import { ColorRing } from "react-loader-spinner";
import { ClipLoader } from "react-spinners";
import { calcTaxIncludedPrice, formatPrice } from "~/../../packages/shared/helpers";

import { Button, CheckboxInputGroup, Link, TextInputGroup } from "shared/components";
import { ShippingParcel, ShippingRate, ShippingTime } from "shared/models";
import { BFC } from "shared/types";

import { colors, routes } from "~/constants";
import { useCartValidation } from "~/features/carts";
import { CheckoutPaymentDetail, DescriptionPopoverButton } from "~/features/checkouts";
import { PaymentMethodListItem, AddressListItem } from "~/features/users";

import { ShippingParcelInputGroup } from "../../components";
import { useCheckoutServiceContext } from "../../hooks";

export const CheckoutNewPage: BFC = () => {
  const {
    checkout,
    address,
    paymentMethod,
    parcels,
    updateParcel,
    removeParcel,
    changeAlternativeProduct,
    couponCode,
    applyCouponCode,
    agreedToRedeliveryCommissionRate,
    setAgreedToRedeliveryCommissionRate,
    execute,
    isFailed,
    isDeliverable,
    isAllParcelsDeliverable,
    isReady,
    isInitialized,
    isLoading,
    isCheckoutProcessing,
    errorMessages,
  } = useCheckoutServiceContext();
  const router = useRouter();

  const onParcelChange = useCallback((parcel: ShippingParcel, rate: ShippingRate, date: Date, time: ShippingTime) => {
    updateParcel(parcel, rate, date, time);
  }, [updateParcel]);

  const onParcelRemove = useCallback((parcel: ShippingParcel) => {
    removeParcel(parcel);
  }, [removeParcel]);

  const [couponCodeText, setCouponCodeText] = useState(couponCode ?? "");
  const [couponCodeVerified, setCouponCodeVerified] = useState(true);

  const onCouponCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setCouponCodeText(e.target.value);
    setCouponCodeVerified(!e.target.value);
  }, []);

  const onCouponCodeBlur = useCallback(async () => {
    await applyCouponCode(couponCodeText);
    setCouponCodeVerified(true);
  }, [couponCodeText]);

  const onClickApplyCouponCode = useCallback(async () => {
    await applyCouponCode(couponCodeText);
    setCouponCodeVerified(true);
  }, [couponCodeText]);

  const onExecutePaymentClick = useCallback(() => {
    if (!couponCodeVerified) return;

    execute(() => {
      router.push(routes.CHECKOUTS_THANKS);
    });
  }, [execute, couponCodeVerified]);

  const onAgreedToRedeliveryCommissionRateChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAgreedToRedeliveryCommissionRate(e.target.checked);
  }, [setAgreedToRedeliveryCommissionRate]);

  const redeliveryCommissionRate = useMemo(() => {
    return calcTaxIncludedPrice(checkout.redeliveryCommissionRate);
  }, [checkout]);

  const parcelShippingDates = useMemo(() => {
    if (!parcels.every((p) => p.isDeliverable())) return null;
    return parcels.map((p) => p.formatShippingDateTime()).join(", ");
  }, [parcels]);

  const cartValidation = useCartValidation();

  if (!isInitialized) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="bg-white flex flex-col divide-y">
      <div className="p-4 flex flex-col gap-4">
        <h2 className="font-bold text-lg flex items-center">
          <HomeIcon className="w-5 mr-1" />
          お届け先
        </h2>
        <div className="flex flex-col gap-4">
          {!address ? (
            <div className="flex items-center justify-between gap-3">
              <div className="text-gray-500">お届け先が登録されていません</div>
              <Link href={routes.CHECKOUTS_NEW_ADDRESSES_NEW}>
                <Button small>登録</Button>
              </Link>
            </div>
          ) : (
            <div className="flex items-center justify-between gap-3">
              <AddressListItem address={address} clamp className="flex-1" />
              <Link href={routes.CHECKOUTS_NEW_ADDRESSES}>
                <Button small>変更</Button>
              </Link>
            </div>
          )}
          {!isDeliverable && (
            <div className="text-red-500 font-bold flex gap-1 items-center">
              <HiOutlineExclamationCircle size={20} />
              この住所は配送対象範囲外です
            </div>
          )}
          <div className="flex items-center justify-end gap-1">
            <HiOutlineCheck size={20} />
            <Link href={routes.ADDRESSES_DELIVERABLES}>
              配送可能エリアを確認する
            </Link>
          </div>
        </div>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <h2 className="font-bold text-lg flex items-center">
          <CreditCardIcon className="w-5 mr-1" />
          お支払い方法
        </h2>
        <div>
          {!paymentMethod ? (
            <div className="flex items-center justify-between gap-3">
              <div className="text-gray-500">お支払い方法が登録されていません</div>
              <Link href={routes.CHECKOUTS_NEW_PAYMENT_METHODS_NEW}>
                <Button small>登録</Button>
              </Link>
            </div>
          ) : (
            <div className="flex items-center justify-between gap-3">
              <PaymentMethodListItem paymentMethod={paymentMethod} />
              <Link href={routes.CHECKOUTS_NEW_PAYMENT_METHODS}>
                <Button small>変更</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <h2 className="font-bold text-lg flex items-center">
          <TruckIcon className="w-5 mr-1" />
          発送の詳細
        </h2>
        <div className="flex gap-1">
          <HiOutlineExclamation size={20} className="text-yellow-500" />
          {address ? (
            <div className="text-gray-600 font-md">必ずご在宅のお時間をご選択ください</div>
          ) : (
            <div className="text-gray-600 font-md">まずはお届け先を選択してください</div>
          )}
        </div>
        <div className="flex flex-col gap-4">
          {parcels.map((parcel) => (
            <ShippingParcelInputGroup
              key={parcel.id}
              parcel={parcel}
              onChange={onParcelChange}
              onRemove={onParcelRemove}
              onAlternativeProductChange={changeAlternativeProduct}
            />
          ))}
        </div>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <div className="flex gap-1 items-center">
          <h2 className="font-bold text-lg flex items-center">
            <RiCoupon3Line className="w-5 mr-1" />
            クーポンコード
          </h2>
          <DescriptionPopoverButton
            title="クーポンコードの適用方法"
            description="コードを入力後「適用」ボタンを押してください。各種手数料は値引き後の金額で計算されます。"
            button={<AiOutlineQuestionCircle size={16} className="text-black-500" />}
            panelClassName="-left-12"
          />
        </div>
        {checkout.couponCode && (
          <div className="text-md text-green-600 flex justify-items-start items-center">
            <AiOutlineCheckCircle className="mr-1" />{checkout.couponCode.code}（{checkout.couponCode.name}）を適用中
          </div>
        )}
        <div className="flex items-center justify-between gap-3">
          <div>
            <TextInputGroup
              placeholder="CODE1234"
              defaultValue={checkout.couponCode?.code}
              onChange={onCouponCodeChange}
              invalid={!!errorMessages.get("couponCode.code")}
              error={errorMessages.get("couponCode.code")}
              onBlur={onCouponCodeBlur}
            />
          </div>
          <div>
            <Button small onClick={onClickApplyCouponCode}>{checkout.couponCode ? "変更" : "適用"}</Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-4">
        <h2 className="font-bold text-lg flex items-center">
          <DocumentTextIcon className="w-5 mr-1" />
          お支払い詳細
        </h2>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <ClipLoader size={40} color={colors.primary} />
          </div>
        ): (
          <CheckoutPaymentDetail
            checkout={checkout}
            showDiscountPriceIfZero
            totalPriceSectionClassName="text-lg font-bold"
            totalPriceClassName="text-primary"
          />
        )}
      </div>
      <div className="p-4 flex flex-col gap-4">
        {isFailed && (
          <div className="font-bold text-red-500 p-2 mb-4 text-center">購入処理に失敗しました</div>
        )}
        {!isAllParcelsDeliverable && (
          <div className="font-bold text-red-500 p-2 mb-4 text-center">ご購入いただけない商品があります。発送の詳細をご確認ください</div>
        )}
        {redeliveryCommissionRate > 0 && (
          <CheckboxInputGroup
            label={`お客様都合による再配達の場合、${formatPrice(redeliveryCommissionRate)}(税込)の再配達手数料がかかります`}
            inputLabel="再配達時の手数料に同意する"
            onChange={onAgreedToRedeliveryCommissionRateChange}
            checked={agreedToRedeliveryCommissionRate}
          />
        )}
        <Button
          primary
          large
          block
          onClick={onExecutePaymentClick}
          disabled={!isReady || isLoading || isCheckoutProcessing || !isDeliverable || !isAllParcelsDeliverable || !agreedToRedeliveryCommissionRate || cartValidation.hasError}
        >
          {isCheckoutProcessing ? (
            <ClipLoader size={20} color={colors.primary} />
          ) : (
            <div className="flex flex-col gap-1">
              {parcelShippingDates && (
                <div>
                  {parcelShippingDates}にお届けで
                </div>
              )}
              ご注文を確定する
            </div>
          )}
        </Button>
        {cartValidation.hasError && (
          <div className="flex items-center gap-2">
            <ExclamationCircleIcon className="h-5 w-5 text-red-400 shrink-0" />
            <p className="text-red-500 font-bold">
              カートの内容に問題があります。
              <Link href={routes.CARTS_SHOW} className="underline">カートページに戻って</Link>
              エラーを解消してください
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
