/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTagFamilyTag200ResponseAllOfData } from './GetTagFamilyTag200ResponseAllOfData';
import {
    GetTagFamilyTag200ResponseAllOfDataFromJSON,
    GetTagFamilyTag200ResponseAllOfDataFromJSONTyped,
    GetTagFamilyTag200ResponseAllOfDataToJSON,
} from './GetTagFamilyTag200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetTagFamilyTag200Response
 */
export interface GetTagFamilyTag200Response {
    /**
     * 
     * @type {GetTagFamilyTag200ResponseAllOfData}
     * @memberof GetTagFamilyTag200Response
     */
    data: GetTagFamilyTag200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetTagFamilyTag200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetTagFamilyTag200Response interface.
 */
export function instanceOfGetTagFamilyTag200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetTagFamilyTag200ResponseFromJSON(json: any): GetTagFamilyTag200Response {
    return GetTagFamilyTag200ResponseFromJSONTyped(json, false);
}

export function GetTagFamilyTag200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagFamilyTag200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetTagFamilyTag200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetTagFamilyTag200ResponseToJSON(value?: GetTagFamilyTag200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetTagFamilyTag200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

