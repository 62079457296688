/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpUserRequestCart
 */
export interface SignUpUserRequestCart {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestCart
     */
    shopifyCartId: string;
}

/**
 * Check if a given object implements the SignUpUserRequestCart interface.
 */
export function instanceOfSignUpUserRequestCart(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shopifyCartId" in value;

    return isInstance;
}

export function SignUpUserRequestCartFromJSON(json: any): SignUpUserRequestCart {
    return SignUpUserRequestCartFromJSONTyped(json, false);
}

export function SignUpUserRequestCartFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUserRequestCart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopifyCartId': json['shopify_cart_id'],
    };
}

export function SignUpUserRequestCartToJSON(value?: SignUpUserRequestCart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shopify_cart_id': value.shopifyCartId,
    };
}

