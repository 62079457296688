/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseErrorNotAcceptableAllOfError
 */
export interface ResponseErrorNotAcceptableAllOfError {
    /**
     * 
     * @type {string}
     * @memberof ResponseErrorNotAcceptableAllOfError
     */
    code: ResponseErrorNotAcceptableAllOfErrorCodeEnum;
}


/**
 * @export
 */
export const ResponseErrorNotAcceptableAllOfErrorCodeEnum = {
    NotAcceptable: 'not_acceptable',
    StripeCardError: 'stripe_card_error'
} as const;
export type ResponseErrorNotAcceptableAllOfErrorCodeEnum = typeof ResponseErrorNotAcceptableAllOfErrorCodeEnum[keyof typeof ResponseErrorNotAcceptableAllOfErrorCodeEnum];


/**
 * Check if a given object implements the ResponseErrorNotAcceptableAllOfError interface.
 */
export function instanceOfResponseErrorNotAcceptableAllOfError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function ResponseErrorNotAcceptableAllOfErrorFromJSON(json: any): ResponseErrorNotAcceptableAllOfError {
    return ResponseErrorNotAcceptableAllOfErrorFromJSONTyped(json, false);
}

export function ResponseErrorNotAcceptableAllOfErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorNotAcceptableAllOfError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': json['code'],
    };
}

export function ResponseErrorNotAcceptableAllOfErrorToJSON(value?: ResponseErrorNotAcceptableAllOfError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
    };
}

