/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Prefecture } from './Prefecture';
import {
    PrefectureFromJSON,
    PrefectureFromJSONTyped,
    PrefectureToJSON,
} from './Prefecture';

/**
 * 
 * @export
 * @interface PreRegistration
 */
export interface PreRegistration {
    /**
     * 
     * @type {string}
     * @memberof PreRegistration
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegistration
     */
    email: string;
    /**
     * 
     * @type {Prefecture}
     * @memberof PreRegistration
     */
    prefecture: Prefecture;
    /**
     * 
     * @type {string}
     * @memberof PreRegistration
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PreRegistration
     */
    familyStructure?: PreRegistrationFamilyStructureEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreRegistration
     */
    useScenes?: Array<PreRegistrationUseScenesEnum>;
}


/**
 * @export
 */
export const PreRegistrationFamilyStructureEnum = {
    Single: 'single',
    Couple: 'couple',
    Family: 'family',
    Multiple: 'multiple',
    None: 'none'
} as const;
export type PreRegistrationFamilyStructureEnum = typeof PreRegistrationFamilyStructureEnum[keyof typeof PreRegistrationFamilyStructureEnum];

/**
 * @export
 */
export const PreRegistrationUseScenesEnum = {
    Meal: 'meal',
    Anniversary: 'anniversary',
    Party: 'party',
    Bbq: 'bbq',
    Office: 'office',
    Business: 'business',
    Gift: 'gift'
} as const;
export type PreRegistrationUseScenesEnum = typeof PreRegistrationUseScenesEnum[keyof typeof PreRegistrationUseScenesEnum];


/**
 * Check if a given object implements the PreRegistration interface.
 */
export function instanceOfPreRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "prefecture" in value;

    return isInstance;
}

export function PreRegistrationFromJSON(json: any): PreRegistration {
    return PreRegistrationFromJSONTyped(json, false);
}

export function PreRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'prefecture': PrefectureFromJSON(json['prefecture']),
        'city': !exists(json, 'city') ? undefined : json['city'],
        'familyStructure': !exists(json, 'family_structure') ? undefined : json['family_structure'],
        'useScenes': !exists(json, 'use_scenes') ? undefined : json['use_scenes'],
    };
}

export function PreRegistrationToJSON(value?: PreRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'prefecture': PrefectureToJSON(value.prefecture),
        'city': value.city,
        'family_structure': value.familyStructure,
        'use_scenes': value.useScenes,
    };
}

