import { useMemo } from "react";

import { useCartContext } from "~/features/carts";

/**
 * カートの内容に問題がないかをチェックするフック
 *
 * @returns hasError: エラーがあるかどうか
 * @returns errorMessages: エラーメッセージの配列
 */
export const useCartValidation = () => {
  const { cart } = useCartContext();

  const item = useMemo(() => cart.getItems(), [cart]);

  const errorMessages = useMemo(() => {
    const _errorMessages: string[] = [];

    item.forEach((item) => {
      const product = item.getProduct();

      // 注文可能数のチェック
      if (
        typeof product.sellableQuantity === "number" &&
        product.sellableQuantity < item.quantity
      ) {
        _errorMessages.push(
          `${product.title}の注文可能な最大数量を超えています。最大注文可能数量は${product.sellableQuantity}個です`
        );
      }
    });

    return _errorMessages;
  }, [item]);

  return {
    hasError: errorMessages.length > 0,
    errorMessages,
  };
};
