import { Blob } from "@rails/activestorage";
import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import { HiPhoto } from "react-icons/hi2";
import { z } from "zod";

import {
  Button,
  TextAreaControlGroup,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

import { LoadingOverlay } from "~/components";
import { useDirectUpload } from "~/utils/useDirectUpload";

export type UploadedFile = {
  file: File;
  blob: Blob;
};

const schema = z.object({
  review: z.object({
    title: z.string().min(1).max(255),
    body: z.string().min(1).max(5000),
    files: z.array(z.custom<UploadedFile>()).optional(),
  }),
});

export type ReviewData = z.infer<typeof schema>;

export const ReviewForm = createHookForm<ReviewData>(
  ({ formState: { isSubmitting }, getValues, setValue, watch }) => {
    const { upload } = useDirectUpload();

    const [isUploading, setIsUploading] = useState(false);
    const handleDrop = useCallback(
      async (acceptedFiles: File[]) => {
        if (!acceptedFiles.length || isUploading) return;

        setIsUploading(true);
        await Promise.all(
          acceptedFiles.map(async (file) => {
            const blob = await upload(file);
            const uploadedFile = {
              file,
              blob,
            };
            const existingFiles = getValues("review.files");
            setValue(
              "review.files",
              Array.isArray(existingFiles)
                ? [...existingFiles, uploadedFile]
                : [uploadedFile]
            );
          })
        ).finally(() => {
          setIsUploading(false);
        });
      },
      [getValues, setValue, upload]
    );

    const files = watch("review.files") || [];
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: handleDrop,
      accept: {
        "image/*": [],
      },
      maxFiles: 10,
      multiple: true,
      maxSize: 10 * 1024 * 1024, // 10MB
      onDropRejected: (rejectedFiles) => {
        toast.error(
          `${rejectedFiles.length}個のファイルがアップロードできませんでした。10MB以内の画像のみアップロードできます。`
        );
      },
      disabled: isUploading || isSubmitting || files?.length >= 10,
    });

    // ファイルプレビュー
    const fileWithPreviewUrls = useMemo(
      () =>
        Array.isArray(files)
          ? files.map((file) => ({
              ...file,
              previewUrl: URL.createObjectURL(file.file),
            }))
          : [],
      [files]
    );
    const handleRemoveFile = useCallback(
      (file: UploadedFile) => {
        const existingFiles = getValues("review.files");
        const newFiles =
          existingFiles?.filter(
            (existingFile) =>
              existingFile.blob.signed_id !== file.blob.signed_id
          ) ?? [];
        setValue("review.files", newFiles);
      },
      [getValues, setValue]
    );

    return (
      <div className="flex flex-col gap-4">
        <TextControlGroup
          name="review.title"
          label="タイトル"
          placeholder="最も伝えたいことは何ですか?"
          required
        />

        <TextAreaControlGroup
          name="review.body"
          label="本文"
          placeholder="商品の感想や、購入の決め手になったポイントなどをご記入ください"
          required
          rows={4}
        />

        <div className="flex flex-col gap-2">
          <label className="block font-medium">添付ファイル</label>
          <p>
            より詳細な情報を伝えるために、10枚まで画像を添付することができます。
          </p>

          {fileWithPreviewUrls.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {fileWithPreviewUrls.map((file) => (
                <div key={file.blob.signed_id} className="relative w-24 h-24">
                  <img
                    src={file.previewUrl}
                    alt="プレビュー画像"
                    className="object-cover w-full h-full rounded-md"
                  />
                  <button
                    type="button"
                    className="absolute -top-2 -right-2 p-2 border shadow-sm bg-white rounded-full hover:bg-gray-100"
                    onClick={() => {
                      handleRemoveFile(file);
                    }}
                  >
                    <FaTimes className="w-4 h-4 text-gray-600" />
                  </button>
                </div>
              ))}
            </div>
          )}

          <LoadingOverlay loading={isUploading}>
            <div
              className={classNames(
                "flex justify-center rounded-lg border border-gray-900/25 px-6 py-10 cursor-pointer",
                {
                  "bg-white": !isDragActive,
                  "bg-gray-100 border-dashed border-primary": isDragActive,
                  "bg-gray-100": isUploading,
                }
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <div className="text-center flex flex-col gap-4">
                <HiPhoto
                  className="mx-auto h-12 w-12 text-gray-300"
                  aria-hidden="true"
                />
                {isUploading ? (
                  <p className="text-gray-600 text-center">
                    アップロードしています...
                  </p>
                ) : (
                  <p className="text-gray-600 text-center">
                    ここに画像をドロップするか、タップして選択してください
                    <br />
                    10MB以内の画像のみアップロードできます
                  </p>
                )}
              </div>
            </div>
          </LoadingOverlay>
        </div>

        <Button
          type="submit"
          block
          primary
          large
          loading={isSubmitting}
          disabled={isUploading}
        >
          確認する
        </Button>
      </div>
    );
  },
  {
    schema,
  }
);
