/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorForbiddenAllOfError } from './ResponseErrorForbiddenAllOfError';
import {
    ResponseErrorForbiddenAllOfErrorFromJSON,
    ResponseErrorForbiddenAllOfErrorFromJSONTyped,
    ResponseErrorForbiddenAllOfErrorToJSON,
} from './ResponseErrorForbiddenAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorForbidden
 */
export interface ResponseErrorForbidden {
    /**
     * 
     * @type {ResponseErrorForbiddenAllOfError}
     * @memberof ResponseErrorForbidden
     */
    error: ResponseErrorForbiddenAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorForbidden interface.
 */
export function instanceOfResponseErrorForbidden(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorForbiddenFromJSON(json: any): ResponseErrorForbidden {
    return ResponseErrorForbiddenFromJSONTyped(json, false);
}

export function ResponseErrorForbiddenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorForbidden {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorForbiddenAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorForbiddenToJSON(value?: ResponseErrorForbidden | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorForbiddenAllOfErrorToJSON(value.error),
    };
}

