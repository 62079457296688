/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCheckoutFullfillmentShipping201ResponseAllOfData } from './PostCheckoutFullfillmentShipping201ResponseAllOfData';
import {
    PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSON,
    PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSONTyped,
    PostCheckoutFullfillmentShipping201ResponseAllOfDataToJSON,
} from './PostCheckoutFullfillmentShipping201ResponseAllOfData';

/**
 * 
 * @export
 * @interface PostCheckoutFullfillmentShipping201ResponseAllOf
 */
export interface PostCheckoutFullfillmentShipping201ResponseAllOf {
    /**
     * 
     * @type {PostCheckoutFullfillmentShipping201ResponseAllOfData}
     * @memberof PostCheckoutFullfillmentShipping201ResponseAllOf
     */
    data: PostCheckoutFullfillmentShipping201ResponseAllOfData;
}

/**
 * Check if a given object implements the PostCheckoutFullfillmentShipping201ResponseAllOf interface.
 */
export function instanceOfPostCheckoutFullfillmentShipping201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PostCheckoutFullfillmentShipping201ResponseAllOfFromJSON(json: any): PostCheckoutFullfillmentShipping201ResponseAllOf {
    return PostCheckoutFullfillmentShipping201ResponseAllOfFromJSONTyped(json, false);
}

export function PostCheckoutFullfillmentShipping201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCheckoutFullfillmentShipping201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PostCheckoutFullfillmentShipping201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function PostCheckoutFullfillmentShipping201ResponseAllOfToJSON(value?: PostCheckoutFullfillmentShipping201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PostCheckoutFullfillmentShipping201ResponseAllOfDataToJSON(value.data),
    };
}

