import { useInfiniteQuery } from "@tanstack/react-query";

import { Product } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

import { productsQueryKeys } from "./productsQueryKeys";

export const ProductsSortParam = {
  ActivatedAtDesc: "activated_at_desc",
  PriceAsc: "price_asc",
  PriceDesc: "price_desc"
} as const;

export type ProductsSortParam = typeof ProductsSortParam[keyof typeof ProductsSortParam];

type Params = {
  category?: string;
  keyword?: string;
  tag?: string;
  page?: number;
  sort?: ProductsSortParam;
};

export const useProducts = (params: Params = {}) => {
  const { accessToken } = useAuthContext();

  const api = useStoreAPI({ accessToken });
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    data,
  } = useInfiniteQuery(
    productsQueryKeys.allWithParams(params),
    ({ pageParam }) =>
      api.getProducts(pageParam || 1, params.category, params.keyword, params.sort, params.tag),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
    }
  );

  const products: Product[] = data
    ? data.pages.flatMap(({ data: { products } }) =>
        products.map((p) => new Product(p))
      )
    : [];

  return {
    products,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
