import classNames from "classnames";

import { CFC } from "shared/types";

export const Head: CFC = ({
  className,
  children,
}) => {
  return (
    <thead className={classNames("bg-gray-50", className)}>
      {children}
    </thead>
  );
};