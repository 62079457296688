import classNames from "classnames";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { FcGoogle } from "react-icons/fc";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";

import { ConfirmModal } from "~/components";
import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { useSignConnect } from "../../hooks";

export const ProvidersPage = () => {
  const { user, isLoading, refetch } = useAuthContext();
  const { disconnect } = useSignConnect();
  const [modalShown, showModal, hideModal] = useBooleanState(false);
  const router = useRouter();
  const connectedGoogle = user.getProviders().some((provider) => provider.isGoogle());

  const connectToGoogleHandler = useCallback(() => {
    if (connectedGoogle) {
      showModal();
    } else {
      router.push(routes.USERS_AUTH_WITH_GOOGLE({ return_to: routes.MYPAGE_PROVIDERS }));
    }
  }, [connectedGoogle]);

  const disconnectFromGoogleHandler = useCallback(() => {
    disconnect("google");
    refetch();
    hideModal();
  }, [disconnect, refetch]);

  const connectedClassNames = useCallback((connected: boolean) => classNames({
    "bg-primary-100 border-primary": connected,
  }), []);

  const onBackClick = useCallback(() => {
    router.push(routes.MYPAGE);
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <ColorRing />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <HiOutlineChevronLeft size={24} className="text-black-500" onClick={onBackClick} />
          アカウント連携
        </h1>
      </div>

      <div className="bg-white">
        <h2 className="font-bold px-4 py-4 border-b">Googleアカウント連携</h2>
        <div className="p-4">
          <Button large block className={classNames("flex gap-2", connectedClassNames(connectedGoogle))} onClick={connectToGoogleHandler}>
            <FcGoogle size={20} />
            {connectedGoogle ? "Googleアカウント連携済み" : "Googleアカウント連携"}
          </Button>
        </div>
      </div>

      <ConfirmModal
        open={modalShown}
        title="Googleアカウント連携解除"
        description="Googleアカウント連携を解除しますか？"
        onConfirm={disconnectFromGoogleHandler}
        onCancel={hideModal}
      />
    </div>
  );
};
