import { useCallback } from "react";
import { AiOutlineHeart, AiOutlineShop } from "react-icons/ai";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";

import { routes } from "~/constants";
import { useFavoriteProducts } from "~/features/favorites";
import { ProductCard } from "~/features/products";

export const FavoritesPage = () => {
  const {
    favoriteProducts,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useFavoriteProducts();

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const isLoadingOrFetching = isLoading || isFetchingNextPage;

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <AiOutlineHeart size={24} className="text-black-500" />
          お気に入り一覧
        </h1>
      </div>
      <div className="bg-white p-3">
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="grid grid-cols-2 gap-x-3 gap-y-6 sm:grid-cols-3 2xl:grid-cols-6">
            {favoriteProducts.map((product) => (
              <Link
                key={product.id}
                href={routes.PRODUCTS_SHOW(product.id)}
              >
                <ProductCard product={product} />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
        {!isLoadingOrFetching && !favoriteProducts.length && (
          <div className="flex flex-col items-center justify-center gap-4 py-8">
            <AiOutlineShop size={48} />
            <p className="text-gray-500">
              お気に入りに登録された商品はありません
            </p>
            <Link href={routes.TOP}>
              <Button>商品を探す</Button>
            </Link>
          </div>
        )}
        {isLoadingOrFetching && (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  );
};
