/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stockup } from './Stockup';
import {
    StockupFromJSON,
    StockupFromJSONTyped,
    StockupToJSON,
} from './Stockup';

/**
 * 
 * @export
 * @interface DeleteStockupItem200ResponseAllOfData
 */
export interface DeleteStockupItem200ResponseAllOfData {
    /**
     * 
     * @type {Stockup}
     * @memberof DeleteStockupItem200ResponseAllOfData
     */
    stockup: Stockup;
}

/**
 * Check if a given object implements the DeleteStockupItem200ResponseAllOfData interface.
 */
export function instanceOfDeleteStockupItem200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockup" in value;

    return isInstance;
}

export function DeleteStockupItem200ResponseAllOfDataFromJSON(json: any): DeleteStockupItem200ResponseAllOfData {
    return DeleteStockupItem200ResponseAllOfDataFromJSONTyped(json, false);
}

export function DeleteStockupItem200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteStockupItem200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockup': StockupFromJSON(json['stockup']),
    };
}

export function DeleteStockupItem200ResponseAllOfDataToJSON(value?: DeleteStockupItem200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockup': StockupToJSON(value.stockup),
    };
}

