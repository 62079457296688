import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import ja from "date-fns/locale/ja";
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from "react-datepicker";
import { Control, Controller, FieldValues, FieldPath } from "react-hook-form";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("ja", ja);

type Props<TFieldValues extends FieldValues = FieldValues> = {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  invalid?: boolean;
  containerClassName?: string;
} & Omit<ReactDatePickerProps, "onChange" | "selected">;

export const DatePickerInput = <
  TFieldValues extends FieldValues = FieldValues
>({
  control,
  name,
  invalid,
  containerClassName: _containerClassName,
  className,
  showTimeInput,
  ...props
}: Props<TFieldValues>) => {
  const containerClassName = classNames("relative", _containerClassName);

  const inputClassName = classNames(
    "rounded border-gray-300 shadow-sm w-full form-input",
    "placeholder-gray-300",
    className,
    {
      "pr-10 border-red-300 text-red-900": invalid,
    }
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <div className={containerClassName}>
          <DatePicker
            locale="ja"
            selected={value}
            onChange={onChange}
            dateFormat={showTimeInput ? "yyyy/MM/dd HH:mm" : "yyyy/MM/dd"}
            className={inputClassName}
            showTimeInput={showTimeInput}
            {...props}
          />
          {invalid && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          )}
        </div>
      )}
    />
  );
};
