/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface CreateUserAddress201ResponseAllOfData
 */
export interface CreateUserAddress201ResponseAllOfData {
    /**
     * 
     * @type {Address}
     * @memberof CreateUserAddress201ResponseAllOfData
     */
    userAddress: Address;
    /**
     * 
     * @type {Address}
     * @memberof CreateUserAddress201ResponseAllOfData
     */
    defaultAddress: Address;
}

/**
 * Check if a given object implements the CreateUserAddress201ResponseAllOfData interface.
 */
export function instanceOfCreateUserAddress201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userAddress" in value;
    isInstance = isInstance && "defaultAddress" in value;

    return isInstance;
}

export function CreateUserAddress201ResponseAllOfDataFromJSON(json: any): CreateUserAddress201ResponseAllOfData {
    return CreateUserAddress201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateUserAddress201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserAddress201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAddress': AddressFromJSON(json['user_address']),
        'defaultAddress': AddressFromJSON(json['default_address']),
    };
}

export function CreateUserAddress201ResponseAllOfDataToJSON(value?: CreateUserAddress201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_address': AddressToJSON(value.userAddress),
        'default_address': AddressToJSON(value.defaultAddress),
    };
}

