import React from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

import { twClassNames } from "shared/lib/tailwind";
import { BFC } from "shared/types";

type Props = {
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const ProductFavoriteButton: BFC<Props> = ({
  isActive,
  onClick,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={twClassNames(
        "w-12 h-12", // アクセシビリティのため、タップターゲットを 48px x 48px にする
        "flex items-start justify-end p-2", // アイコンを右上に寄せる
        {
          "text-primary hover:text-primary-600": isActive,
          "text-black-400 hover:text-black-700": !isActive,
        },
        className,
      )}
    >
      {isActive ? (
        <AiFillHeart className="h-6 w-auto" />
      ) : (
        <AiOutlineHeart className="h-6 w-auto" />
      )}
    </button>
  );
};
