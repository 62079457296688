import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";

import { useStoreAPI } from "shared/services/api";
import {
  UpdateUserEmailMarketingAgreedRequest,
  UpdateUserNotificationSettingRequest,
} from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";
import { useCurrentUser } from "~/features/users";

export const NotificationType = {
  Marketing: "marketing",
  CartDropReminder: "cartDropReminder",
  CouponNotification: "couponNotification",
  ProductReActivated: "productReActivated",
} as const;

export type NotificationType =
  typeof NotificationType[keyof typeof NotificationType];

export type NotificationSettings = {
  [key in NotificationType]: boolean;
};

export const useNotificationSettings = () => {
  const { accessToken } = useAuthContext();
  const { currentUser, refetch } = useCurrentUser();
  const api = useStoreAPI({ accessToken });

  // サーバーに永続化されている設定
  const currentSettings = useMemo<NotificationSettings>(() => {
    return {
      marketing: currentUser.emailMarketingAgreed,
      cartDropReminder:
        currentUser.notificationSetting?.cartDropReminder ?? true,
      couponNotification:
        currentUser.notificationSetting?.couponNotification ?? true,
      productReActivated:
        currentUser.notificationSetting?.productReActivated ?? true,
    };
  }, [currentUser]);

  /**
   * 更新処理
   */

  // メールマガジン (user.emailMarketingAgreed)
  const updateEmailMarketingAgreement = useMutation(
    ["users/emailMarketingAgreement"],
    (data: UpdateUserEmailMarketingAgreedRequest) =>
      api.updateUserEmailMarketingAgreed(data),
    {
      onSuccess: () => {
        refetch();
        toast.success("お知らせの受信設定を更新しました");
      },
    }
  );

  // メールマガジン以外 (user.notificationSetting)
  const updateNotificationSetting = useMutation(
    ["users/notificationSetting"],
    (data: UpdateUserNotificationSettingRequest) =>
      api.updateUserNotificationSetting(data),
    {
      onSuccess: () => {
        refetch();
        toast.success("お知らせの受信設定を更新しました");
      },
    }
  );

  // お知らせの受信設定を更新
  const update = useCallback(
    (type: NotificationType, isEnabled: boolean) => {
      if (type === NotificationType.Marketing) {
        // メールマガジンの設定を更新
        updateEmailMarketingAgreement.mutate({
          user: {
            emailMarketingAgreed: isEnabled,
          },
        });
        return;
      }

      // メルマガ以外の設定を更新
      updateNotificationSetting.mutate({
        notificationSetting: {
          [type]: isEnabled,
        },
      });
    },
    [updateEmailMarketingAgreement, updateNotificationSetting]
  );

  return {
    currentSettings,
    update,
  };
};
