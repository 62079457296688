import { useCallback, useMemo, useRef, useState } from "react";
import { HiOutlineTruck } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";
import { formatZipCode } from "~/../../packages/shared/helpers";

import { Button, TextInput } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { DeliverableAddress } from "shared/models";
import { BFC } from "shared/types";

import { SimpleModal } from "~/components";

import { useDeliverableAddresses, useIsDeliverableAddress } from "../../hooks";

export const DeliverableAddressesPage: BFC = () => {
  const [zipCode, setZipCode] = useState<string>();
  const [modalShown, showModal, hideModal] = useBooleanState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { addresses } = useDeliverableAddresses();
  const { isDeliverable, deliverableAddress, isLoading } = useIsDeliverableAddress(zipCode);

  const onClickCheck = useCallback(() => {
    const value = inputRef.current?.value.replace(/\D/g, "");
    if (value) {
      setZipCode(value);
      showModal();
    }
  }, [inputRef]);

  const groupedAddresses = useMemo(() => addresses.reduce((acc, address) => {
    const name = `${address.prefecture.name} ${address.city}`;
    if (!acc.has(name)) {
      acc.set(name, []);
    }
    acc.set(name, [...(acc.get(name) || []), address]);
    return acc;
  }, new Map<string, DeliverableAddress[]>()), [addresses]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white flex flex-col divide-y">
        <h1 className="font-bold flex gap-1 items-center text-xl p-4">
          <HiOutlineTruck size={28} />
          配送可能なエリア
        </h1>
        <div className="flex flex-col gap-3 items-center p-4">
          <div className="font-bold text-lg">
            郵便番号で確認する
          </div>
          <TextInput inputMode="tel" ref={inputRef} placeholder="1234567" />
          <Button primary onClick={onClickCheck} disabled={isLoading}>配送可能か確認する</Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {Array.from(groupedAddresses.keys()).map((name) => (
          <div key={name} className="bg-white">
            <div className="font-bold border-b p-4">
              {name}
            </div>
            <div className="flex flex-col divide-y">
              {groupedAddresses.get(name)?.map((address) => (
                <div key={address.id} className="flex gap-3 p-3">
                  <div className="whitespace-nowrap">〒{formatZipCode(address.zipCode)}</div>
                  <div>
                    {address.prefecture.name} {address.city} {address.town}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <SimpleModal open={modalShown} onClose={hideModal}>
        {isLoading ? (
          <div className="flex items-center justify-center h-40">
            <ColorRing />
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-4">
            <div>
              <div className="font-bold text-primary">〒{formatZipCode(zipCode || "")}</div>
              {deliverableAddress?.summary}は
            </div>
            <div className="flex flex-col gap-4 items-center">
              {isDeliverable ? (
                <>
                  <div className="text-4xl">🎉</div>
                  <div className="font-bold text-primary text-xl">配送可能</div>
                  <div>です！</div>
                </>
              ) : (
                <>
                  <div className="text-4xl">😔</div>
                  <div className="font-bold text-black-500 text-xl">配送不可</div>
                  <div>です...</div>
                </>
              )}
            </div>
            {isDeliverable ? (
              <div>引き続き買い物をお楽しみください👍</div>
            ) : (
              <div>配送先は順次拡大予定ですので、今しばらくお待ちください🙇</div>
            )}
            <Button block large onClick={hideModal}>閉じる</Button>
          </div>
        )}
      </SimpleModal>
    </div>
  );
};
