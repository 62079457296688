import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { ReactNode, useCallback, useEffect } from "react";

import { BFC } from "shared/types";

import { Item } from "./Item";

type Props = {
  slides: ReactNode[];
  containerClassName?: string;
  slideClassName?: string;
};

export const TopCarousel: BFC<Props> & {
  Item: typeof Item;
} = ({
  slides,
  className,
  containerClassName,
  slideClassName,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const scrollTo = useCallback((index: number) => () => {
    emblaApi?.scrollTo(index);
  }, [emblaApi]);

  useEffect(() => {
    emblaApi?.reInit();
  }, [slides]);

  return (
    <div ref={emblaRef} className={classNames("relative overflow-hidden", className)}>
      <div className={classNames("flex items-center h-full", containerClassName)}>
        {slides.map((slide, index) => (
          <div key={index} className={classNames("flex-[0_0_100%] md:flex-[0_0_80%] min-w-0 h-full", slideClassName)}>
            {slide}
          </div>
        ))}
      </div>
      <div className="absolute flex justify-center items-center inset-x-0 bottom-3">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            className="w-2 h-2 rounded-full bg-white mx-1 border"
            onClick={scrollTo(index)}
          />
        ))}
      </div>
    </div>
  );
};

TopCarousel.Item = Item;
