import { useRouter } from "next/router";
import { ReactNode, useCallback } from "react";
import { FcGoogle } from "react-icons/fc";
import { HiChevronRight, HiOutlineUserCircle } from "react-icons/hi";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { BFC } from "shared/types";

import { MaskedCardDetail } from "~/components";
import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { useAddresses, usePaymentMethods, useSignOut } from "../../hooks";

export const MyPage = () => {
  const { user, isLoading } = useAuthContext();
  const { defaultAddress } = useAddresses();
  const { defaultPaymentMethod } = usePaymentMethods();
  const { signOut } = useSignOut();
  const router = useRouter();
  const connectedGoogle = user.getProviders().some((provider) => provider.isGoogle());

  const onSignOutHandler = useCallback(async () => {
    await signOut();
    router.push(routes.TOP);
  }, [signOut]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <HiOutlineUserCircle size={24} className="text-black-500" />
          マイページ
        </h1>
      </div>

      <div className="bg-white">
        <h2 className="font-bold px-4 py-4 border-b">会員情報</h2>
        <div className="flex flex-col divide-y">
          <MenuItem
            label="氏名"
            note={user.fullName}
            href={routes.MYPAGE_NAME}
          />
          <MenuItem
            label="生年月日"
            note={user.birthDateFormatted}
            href={routes.MYPAGE_BIRTH_DATE}
          />
          <MenuItem
            label="メールアドレス"
            note={user.email}
            href={routes.MYPAGE_EMAIL_SHOW}
          />
          <MenuItem
            label="電話番号"
            note={user.phone || "未登録"}
          />
          <MenuItem
            label="お届け先"
            note={<div className="line-clamp-1">{defaultAddress ? defaultAddress.summary : "未登録"}</div>}
            href={routes.MYPAGE_ADDRESSES}
          />
          <MenuItem
            label="お支払い方法"
            note={defaultPaymentMethod ? (
              <MaskedCardDetail brand={defaultPaymentMethod.card.brand} last4={defaultPaymentMethod.card.last4} />
            ) : "未登録"}
            href={routes.MYPAGE_PAYMENT_METHODS}
          />
          <MenuItem
            label="お知らせの受信設定"
            href={routes.MYPAGE_NOTIFICATIONS}
          />
        </div>
      </div>

      <div className="bg-white">
        <h2 className="font-bold px-4 py-4 border-b">アカウント連携</h2>
        <div className="flex flex-col divide-y">
          <MenuItem
            label={(<div className="flex gap-2"><FcGoogle size={20} />Googleアカウント連携</div>)}
            note={connectedGoogle ? "連携済み" : "未連携"}
            href={routes.MYPAGE_PROVIDERS}
          />
        </div>
      </div>

      <div className="bg-white p-4">
        <Button large block onClick={onSignOutHandler}>ログアウト</Button>
      </div>

      <div className="p-4">
        <Link href={routes.MYPAGE_CANCEL_MEMBERSHIP}>
          <div className="text-center text-neutral-500">退会する</div>
        </Link>
      </div>
    </div>
  );
};

type MenuItemProps = {
  label: ReactNode;
  note?: ReactNode;
  href?: string;
};

const MenuItem: BFC<MenuItemProps> = ({
  label,
  note,
  href,
}) => {
  return (
    href ? (
      <Link href={href} noDecoration className="flex items-center justify-between p-4 gap-4">
        <div className="flex flex-col gap-2">
          {label}
          {note && (
            <div className="text-black-400">
              {note}
            </div>
          )}
        </div>
        <HiChevronRight size={24} className="text-black-300" />
      </Link>
    ) : (
      <div className="flex flex-col gap-2 p-4">
        {label}
        {note && (
          <div className="text-black-400">
            {note}
          </div>
        )}
      </div>
    )
  );
};
