import { forwardRef } from "react";

import { InputGroup, BaseTextAreaInputGroupProps } from "../InputGroup";
import { TextAreaInput } from "../TextAreaInput";

export type TextAreaInputGroupProps = Omit<BaseTextAreaInputGroupProps, "input">;

export const TextAreaInputGroup = forwardRef<HTMLTextAreaElement, TextAreaInputGroupProps>(function TextAreaInputGroup(props, ref) {
  return (
    <InputGroup {...props} ref={ref} input={TextAreaInput} />
  );
});
