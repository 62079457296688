import { GraphQLClient } from "graphql-request";

type ShopifyGraphqlClientOptions = {
  token?: string;
  version?: string;
};

export const getShopifyGraphqlClient = (options: ShopifyGraphqlClientOptions = {}) => {
  const {
    token = process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
    version = "2022-10",
  } = options;
  const shop = process.env.SHOPIFY_SHOP;

  return new GraphQLClient(
    `https://${shop}.myshopify.com/api/${version}/graphql.json`,
    {
      headers: {
        "X-Shopify-Storefront-Access-Token": token || "",
      },
    },
  );
};
