import classNames from "classnames";
import { MouseEvent } from "react";

import { CFC } from "shared/types";

type Props = {
  head?: boolean;
  colSpan?: number;
  rowSpan?: number;
  onClick?: (e: MouseEvent<HTMLTableCellElement>) => void;
}

export const Col: CFC<Props> = ({
  head = false,
  colSpan,
  rowSpan,
  onClick,
  children,
  className,
}) => {
  return (
    <>
      {head && (
        <th
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={classNames("px-3 py-3.5 text-left text-sm font-semibold bg-gray-50 text-gray-900", className)}
          onClick={onClick}
        >
          {children}
        </th>
      )}
      {!head && (
        <td
          colSpan={colSpan}
          rowSpan={rowSpan}
          className={classNames("px-3 py-3.5 text-sm", className)}
          onClick={onClick}
        >
          {children}
        </td>
      )}
    </>
  );
};
