import { PhotoIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  title?: string;
};

export const ProductCardSkeleton: BFC<Props> = ({
  title,
  className,
}) => {
  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="border rounded flex justify-center items-center aspect-square">
        <PhotoIcon className="text-black-200 w-8" />
      </div>
      <h3 className="text-sm mt-1 line-clamp-2">
        {title ?? <div className="bg-black-200 h-2 my-1 rounded" />}
      </h3>
      <div className="flex justify-end mt-1">
        <div className="bg-black-200 h-2 w-20 rounded" />
      </div>
    </div>
  );
};
