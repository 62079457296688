import classNames from "classnames";

import { CFC } from "shared/types";

export const Body: CFC = ({
  className,
  children,
}) => {
  return (
    <tbody className={classNames("divide-y divide-gray-200 bg-white", className)}>
      {children}
    </tbody>
  );
};