/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCostcoProducts200ResponseAllOfData } from './GetCostcoProducts200ResponseAllOfData';
import {
    GetCostcoProducts200ResponseAllOfDataFromJSON,
    GetCostcoProducts200ResponseAllOfDataFromJSONTyped,
    GetCostcoProducts200ResponseAllOfDataToJSON,
} from './GetCostcoProducts200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCostcoProducts200ResponseAllOf
 */
export interface GetCostcoProducts200ResponseAllOf {
    /**
     * 
     * @type {GetCostcoProducts200ResponseAllOfData}
     * @memberof GetCostcoProducts200ResponseAllOf
     */
    data: GetCostcoProducts200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCostcoProducts200ResponseAllOf interface.
 */
export function instanceOfGetCostcoProducts200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCostcoProducts200ResponseAllOfFromJSON(json: any): GetCostcoProducts200ResponseAllOf {
    return GetCostcoProducts200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCostcoProducts200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProducts200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCostcoProducts200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCostcoProducts200ResponseAllOfToJSON(value?: GetCostcoProducts200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCostcoProducts200ResponseAllOfDataToJSON(value.data),
    };
}

