/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import { ComponentType, forwardRef, Ref, ReactNode, ComponentProps } from "react";

import { DateSelect } from "../DateSelect";
import { ErrorMessage } from "../ErrorMessage";
import { useInputAriaIds } from "../hooks";
import { SelectInput } from "../SelectInput";
import { TextAreaInput } from "../TextAreaInput";
import { TextInput } from "../TextInput";

type BaseInputGroupProps<T extends ComponentType<any>> = {
  label?: ReactNode;
  error?: string;
  note?: string;
  required?: boolean;
  input: T;
  inputClassName?: string;
  inputContainerClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & ComponentProps<T>;

export type BaseTextInputGroupProps = BaseInputGroupProps<typeof TextInput>;
export type BaseTextAreaInputGroupProps = BaseInputGroupProps<typeof TextAreaInput>;
export type BaseSelectInputGroupProps = BaseInputGroupProps<typeof SelectInput>;
export type BaseDateSelectInputGroupProps = BaseInputGroupProps<typeof DateSelect>;
export type BaseAnyInputGroupProps = BaseInputGroupProps<ComponentType>;
export type InputGroupProps = BaseTextInputGroupProps | BaseSelectInputGroupProps | BaseAnyInputGroupProps;

type InputGroupType = {
  (props: BaseTextInputGroupProps): JSX.Element;
  (props: BaseTextAreaInputGroupProps): JSX.Element;
  (props: BaseSelectInputGroupProps): JSX.Element;
  (props: BaseDateSelectInputGroupProps): JSX.Element;
  (props: BaseAnyInputGroupProps): JSX.Element;
};

export const InputGroup = forwardRef(function InputGroup({
  label,
  error,
  required,
  note,
  input: Input,
  inputClassName,
  inputContainerClassName,
  labelContainerClassName,
  className,
  ...props
}: InputGroupProps, ref: Ref<HTMLInputElement>) {
  const classes = classNames(
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none",
    inputClassName,
  );

  const { inputId, inputAriaProps, descriptionId, errorMessageId } =
    useInputAriaIds({ required, note, error });

  return (
    <div className={classNames(className)}>
      {(label || required) && (
        <div className={classNames("flex items-center gap-2 mb-2", labelContainerClassName)}>
          {label && (
            <label className="text-sm font-semibold text-gray-700" htmlFor={inputId}>{label}</label>
          )}
          {required && (
            <span className="text-xs bg-primary text-white rounded py-px px-1">必須</span>
          )}
        </div>
      )}
      <div className={inputContainerClassName}>
        <Input id={inputId} {...inputAriaProps} {...props as any} ref={ref} className={classes} />
      </div>
      {error && <ErrorMessage id={errorMessageId} message={error} hideIcon />}
      {note && (
        <div id={descriptionId} className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
}) as InputGroupType;
/* eslint-enable @typescript-eslint/no-explicit-any */
