/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface Sale
 */
export interface Sale {
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    handle: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    discountTarget: SaleDiscountTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof Sale
     */
    discountType: SaleDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    amountOff: number;
    /**
     * 
     * @type {number}
     * @memberof Sale
     */
    percentOff: number;
    /**
     * 
     * @type {Date}
     * @memberof Sale
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Sale
     */
    endAt: Date;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Sale
     */
    products: Array<Product>;
}


/**
 * @export
 */
export const SaleDiscountTargetEnum = {
    All: 'all',
    Evennly: 'evennly',
    Specific: 'specific'
} as const;
export type SaleDiscountTargetEnum = typeof SaleDiscountTargetEnum[keyof typeof SaleDiscountTargetEnum];

/**
 * @export
 */
export const SaleDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent'
} as const;
export type SaleDiscountTypeEnum = typeof SaleDiscountTypeEnum[keyof typeof SaleDiscountTypeEnum];


/**
 * Check if a given object implements the Sale interface.
 */
export function instanceOfSale(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "handle" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "discountTarget" in value;
    isInstance = isInstance && "discountType" in value;
    isInstance = isInstance && "amountOff" in value;
    isInstance = isInstance && "percentOff" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "endAt" in value;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function SaleFromJSON(json: any): Sale {
    return SaleFromJSONTyped(json, false);
}

export function SaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'handle': json['handle'],
        'name': json['name'],
        'discountTarget': json['discount_target'],
        'discountType': json['discount_type'],
        'amountOff': json['amount_off'],
        'percentOff': json['percent_off'],
        'startAt': (new Date(json['start_at'])),
        'endAt': (new Date(json['end_at'])),
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
    };
}

export function SaleToJSON(value?: Sale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'handle': value.handle,
        'name': value.name,
        'discount_target': value.discountTarget,
        'discount_type': value.discountType,
        'amount_off': value.amountOff,
        'percent_off': value.percentOff,
        'start_at': (value.startAt.toISOString()),
        'end_at': (value.endAt.toISOString()),
        'products': ((value.products as Array<any>).map(ProductToJSON)),
    };
}

