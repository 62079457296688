import { Popover } from "@headlessui/react";
import classNames from "classnames";
import { ReactNode } from "react";

import { BFC } from "shared/types";

type Props = {
  title: string;
  description: ReactNode;
  button: JSX.Element;
  panelClassName?: string;
}

export const DescriptionPopoverButton: BFC<Props> = ({
  title,
  description,
  button: Button,
  className,
  panelClassName = "",
}) => {
  const panelClassNames = classNames({
    "left-0": !panelClassName.includes("left-"),
  }, panelClassName);

  return (
    <Popover className={classNames("inline-block relative", className)}>
      <Popover.Button className="align-text-top">{Button}</Popover.Button>

      <Popover.Panel className={classNames("absolute z-10 w-72 max-w-xs", panelClassNames)}>
        <div className="rounded shadow-md border overflow-hidden">
          <div className="bg-white p-3">
            <h5 className="font-bold text-black-500">
              {title}
            </h5>
            <div className="mt-1">{description}</div>
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
