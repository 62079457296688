/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductShippingRateAllOfAvailableDatetimes
 */
export interface ProductShippingRateAllOfAvailableDatetimes {
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    rangeStart: string;
    /**
     * 
     * @type {string}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    rangeEnd: string;
    /**
     * 
     * @type {number}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    availableQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof ProductShippingRateAllOfAvailableDatetimes
     */
    salesLimitQuantity: number;
}

/**
 * Check if a given object implements the ProductShippingRateAllOfAvailableDatetimes interface.
 */
export function instanceOfProductShippingRateAllOfAvailableDatetimes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "rangeStart" in value;
    isInstance = isInstance && "rangeEnd" in value;
    isInstance = isInstance && "availableQuantity" in value;
    isInstance = isInstance && "salesLimitQuantity" in value;

    return isInstance;
}

export function ProductShippingRateAllOfAvailableDatetimesFromJSON(json: any): ProductShippingRateAllOfAvailableDatetimes {
    return ProductShippingRateAllOfAvailableDatetimesFromJSONTyped(json, false);
}

export function ProductShippingRateAllOfAvailableDatetimesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductShippingRateAllOfAvailableDatetimes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'date': (new Date(json['date'])),
        'name': json['name'],
        'rangeStart': json['range_start'],
        'rangeEnd': json['range_end'],
        'availableQuantity': json['available_quantity'],
        'salesLimitQuantity': json['sales_limit_quantity'],
    };
}

export function ProductShippingRateAllOfAvailableDatetimesToJSON(value?: ProductShippingRateAllOfAvailableDatetimes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'date': (value.date.toISOString().substr(0,10)),
        'name': value.name,
        'range_start': value.rangeStart,
        'range_end': value.rangeEnd,
        'available_quantity': value.availableQuantity,
        'sales_limit_quantity': value.salesLimitQuantity,
    };
}

