import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, ImgHTMLAttributes, useCallback, useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";

import { BFC } from "shared/types";

type Props = ImgHTMLAttributes<HTMLImageElement> & {
  lazy?: boolean;
  src4Zoom?: string;
};

export const ZoomableImage: BFC<Props> = ({
  lazy = true,
  src,
  src4Zoom,
  className,
  ...props
}) => {
  const [zoom, setZoom] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const imageSrc = useMemo(() => {
    if (lazy) {
      return inView ? src : undefined;
    }

    return src;
  }, [inView, lazy, src]);

  const onImageClick = useCallback(() => {
    setZoom(true);
  }, []);

  const onClose = useCallback(() => {
    setZoom(false);
  }, []);

  const imgClassNames = classNames("cursor-pointer", className);

  return (
    <>
      <img
        ref={ref}
        src={imageSrc}
        className={imgClassNames}
        {...props}
        onClick={onImageClick}
      />
      <Transition appear show={zoom} as={Fragment}>
        <Dialog open={zoom} onClose={onClose} className="relative z-50" data-no-dnd>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/50 " onClick={onClose} />
          </Transition.Child>
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg">
                <img
                  src={src4Zoom || src}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

