import { useMutation } from "@tanstack/react-query";

import { useStoreAPI } from "shared/services/api";
import { UpdateUserNameRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

type Options = {
  onSuccess?: () => void;
};

export const useUpdateName = ({ onSuccess }: Options = {}) => {
  const { accessToken, refetch } = useAuthContext();
  const api = useStoreAPI({ accessToken });

  const { mutateAsync, isLoading } = useMutation(
    ["users/name"],
    (data: UpdateUserNameRequest) => api.updateUserName(data),
    {
      onSuccess: () => {
        refetch();
        onSuccess?.();
      },
    }
  );

  return {
    update: mutateAsync,
    isLoading,
  };
};
