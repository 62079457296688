/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutItem } from './CheckoutItem';
import {
    CheckoutItemFromJSON,
    CheckoutItemFromJSONTyped,
    CheckoutItemToJSON,
} from './CheckoutItem';
import type { CheckoutSummary } from './CheckoutSummary';
import {
    CheckoutSummaryFromJSON,
    CheckoutSummaryFromJSONTyped,
    CheckoutSummaryToJSON,
} from './CheckoutSummary';
import type { ShippingRate } from './ShippingRate';
import {
    ShippingRateFromJSON,
    ShippingRateFromJSONTyped,
    ShippingRateToJSON,
} from './ShippingRate';
import type { ShippingTime } from './ShippingTime';
import {
    ShippingTimeFromJSON,
    ShippingTimeFromJSONTyped,
    ShippingTimeToJSON,
} from './ShippingTime';

/**
 * 
 * @export
 * @interface CheckoutParcel
 */
export interface CheckoutParcel {
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    id: string;
    /**
     * 
     * @type {CheckoutSummary}
     * @memberof CheckoutParcel
     */
    checkout?: CheckoutSummary;
    /**
     * 
     * @type {ShippingRate}
     * @memberof CheckoutParcel
     */
    shippingRate: ShippingRate;
    /**
     * 
     * @type {ShippingTime}
     * @memberof CheckoutParcel
     */
    shippingTime: ShippingTime;
    /**
     * 
     * @type {Date}
     * @memberof CheckoutParcel
     */
    shippingDate: Date;
    /**
     * 
     * @type {Array<CheckoutItem>}
     * @memberof CheckoutParcel
     */
    items: Array<CheckoutItem>;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    stockingStatus: CheckoutParcelStockingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    shippingStatus: CheckoutParcelShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    shippingMailStatus: CheckoutParcelShippingMailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    deliveryStatus: CheckoutParcelDeliveryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    deliveryFailedReason: CheckoutParcelDeliveryFailedReasonEnum;
    /**
     * 
     * @type {Date}
     * @memberof CheckoutParcel
     */
    redeliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    redeliveryTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutParcel
     */
    status: CheckoutParcelStatusEnum;
}


/**
 * @export
 */
export const CheckoutParcelStockingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutParcelStockingStatusEnum = typeof CheckoutParcelStockingStatusEnum[keyof typeof CheckoutParcelStockingStatusEnum];

/**
 * @export
 */
export const CheckoutParcelShippingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutParcelShippingStatusEnum = typeof CheckoutParcelShippingStatusEnum[keyof typeof CheckoutParcelShippingStatusEnum];

/**
 * @export
 */
export const CheckoutParcelShippingMailStatusEnum = {
    Waiting: 'waiting',
    Completed: 'completed'
} as const;
export type CheckoutParcelShippingMailStatusEnum = typeof CheckoutParcelShippingMailStatusEnum[keyof typeof CheckoutParcelShippingMailStatusEnum];

/**
 * @export
 */
export const CheckoutParcelDeliveryStatusEnum = {
    Waiting: 'waiting',
    Completed: 'completed',
    Shipped: 'shipped',
    Failed: 'failed'
} as const;
export type CheckoutParcelDeliveryStatusEnum = typeof CheckoutParcelDeliveryStatusEnum[keyof typeof CheckoutParcelDeliveryStatusEnum];

/**
 * @export
 */
export const CheckoutParcelDeliveryFailedReasonEnum = {
    None: 'none',
    Pending: 'pending',
    Absent: 'absent',
    AddressUnknown: 'address_unknown',
    OperationMiss: 'operation_miss',
    Other: 'other'
} as const;
export type CheckoutParcelDeliveryFailedReasonEnum = typeof CheckoutParcelDeliveryFailedReasonEnum[keyof typeof CheckoutParcelDeliveryFailedReasonEnum];

/**
 * @export
 */
export const CheckoutParcelStatusEnum = {
    Waiting: 'waiting',
    Processing: 'processing',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutParcelStatusEnum = typeof CheckoutParcelStatusEnum[keyof typeof CheckoutParcelStatusEnum];


/**
 * Check if a given object implements the CheckoutParcel interface.
 */
export function instanceOfCheckoutParcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shippingRate" in value;
    isInstance = isInstance && "shippingTime" in value;
    isInstance = isInstance && "shippingDate" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "stockingStatus" in value;
    isInstance = isInstance && "shippingStatus" in value;
    isInstance = isInstance && "shippingMailStatus" in value;
    isInstance = isInstance && "deliveryStatus" in value;
    isInstance = isInstance && "deliveryFailedReason" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CheckoutParcelFromJSON(json: any): CheckoutParcel {
    return CheckoutParcelFromJSONTyped(json, false);
}

export function CheckoutParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutParcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'checkout': !exists(json, 'checkout') ? undefined : CheckoutSummaryFromJSON(json['checkout']),
        'shippingRate': ShippingRateFromJSON(json['shipping_rate']),
        'shippingTime': ShippingTimeFromJSON(json['shipping_time']),
        'shippingDate': (new Date(json['shipping_date'])),
        'items': ((json['items'] as Array<any>).map(CheckoutItemFromJSON)),
        'stockingStatus': json['stocking_status'],
        'shippingStatus': json['shipping_status'],
        'shippingMailStatus': json['shipping_mail_status'],
        'deliveryStatus': json['delivery_status'],
        'deliveryFailedReason': json['delivery_failed_reason'],
        'redeliveryDate': !exists(json, 'redelivery_date') ? undefined : (new Date(json['redelivery_date'])),
        'redeliveryTime': !exists(json, 'redelivery_time') ? undefined : json['redelivery_time'],
        'status': json['status'],
    };
}

export function CheckoutParcelToJSON(value?: CheckoutParcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'checkout': CheckoutSummaryToJSON(value.checkout),
        'shipping_rate': ShippingRateToJSON(value.shippingRate),
        'shipping_time': ShippingTimeToJSON(value.shippingTime),
        'shipping_date': (value.shippingDate.toISOString().substr(0,10)),
        'items': ((value.items as Array<any>).map(CheckoutItemToJSON)),
        'stocking_status': value.stockingStatus,
        'shipping_status': value.shippingStatus,
        'shipping_mail_status': value.shippingMailStatus,
        'delivery_status': value.deliveryStatus,
        'delivery_failed_reason': value.deliveryFailedReason,
        'redelivery_date': value.redeliveryDate === undefined ? undefined : (value.redeliveryDate.toISOString().substr(0,10)),
        'redelivery_time': value.redeliveryTime,
        'status': value.status,
    };
}

