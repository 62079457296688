/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCouponCode } from './UserCouponCode';
import {
    UserCouponCodeFromJSON,
    UserCouponCodeFromJSONTyped,
    UserCouponCodeToJSON,
} from './UserCouponCode';

/**
 * 
 * @export
 * @interface GetUserCouponCodes200ResponseAllOfData
 */
export interface GetUserCouponCodes200ResponseAllOfData {
    /**
     * 
     * @type {Array<UserCouponCode>}
     * @memberof GetUserCouponCodes200ResponseAllOfData
     */
    couponCodes: Array<UserCouponCode>;
}

/**
 * Check if a given object implements the GetUserCouponCodes200ResponseAllOfData interface.
 */
export function instanceOfGetUserCouponCodes200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "couponCodes" in value;

    return isInstance;
}

export function GetUserCouponCodes200ResponseAllOfDataFromJSON(json: any): GetUserCouponCodes200ResponseAllOfData {
    return GetUserCouponCodes200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserCouponCodes200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserCouponCodes200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'couponCodes': ((json['coupon_codes'] as Array<any>).map(UserCouponCodeFromJSON)),
    };
}

export function GetUserCouponCodes200ResponseAllOfDataToJSON(value?: GetUserCouponCodes200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'coupon_codes': ((value.couponCodes as Array<any>).map(UserCouponCodeToJSON)),
    };
}

