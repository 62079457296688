/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCostcoProductLog200ResponseAllOfData } from './GetCostcoProductLog200ResponseAllOfData';
import {
    GetCostcoProductLog200ResponseAllOfDataFromJSON,
    GetCostcoProductLog200ResponseAllOfDataFromJSONTyped,
    GetCostcoProductLog200ResponseAllOfDataToJSON,
} from './GetCostcoProductLog200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCostcoProductLog200ResponseAllOf
 */
export interface GetCostcoProductLog200ResponseAllOf {
    /**
     * 
     * @type {GetCostcoProductLog200ResponseAllOfData}
     * @memberof GetCostcoProductLog200ResponseAllOf
     */
    data: GetCostcoProductLog200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCostcoProductLog200ResponseAllOf interface.
 */
export function instanceOfGetCostcoProductLog200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCostcoProductLog200ResponseAllOfFromJSON(json: any): GetCostcoProductLog200ResponseAllOf {
    return GetCostcoProductLog200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCostcoProductLog200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProductLog200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCostcoProductLog200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCostcoProductLog200ResponseAllOfToJSON(value?: GetCostcoProductLog200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCostcoProductLog200ResponseAllOfDataToJSON(value.data),
    };
}

