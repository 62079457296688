import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";

import { Button, ErrorMessage, Link, RadioInput } from "shared/components";
import { getParam } from "shared/lib/next";
import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { TitleHeader } from "~/components/layouts/TitleHeader";
import { routes } from "~/constants";
import { PaymentMethodListItem, usePaymentMethods } from "~/features/users";

import { useCheckout } from "../../hooks";

export const CheckoutPaymentMethodsIndexPage: BFC = () => {
  const router = useRouter();
  const id = getParam(router.query, "id");
  const { checkout, updatePaymentMethod, isLoading } = useCheckout(id);
  const { paymentMethods, isLoading: paymentMethodsLoading } = usePaymentMethods();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(checkout.getPayment().getPaymentMethod());
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (checkout.isLoaded()) {
      setSelectedPaymentMethod(checkout.getPayment().getPaymentMethod());
      if (!checkout.getPayment().isFailed()) {
        router.push(routes.CHECKOUTS_SHOW(checkout.id));
      }
    }
  }, [checkout]);

  const onSelectFactory = useCallback((paymentMethod: PaymentMethod) => () => {
    setSelectedPaymentMethod(paymentMethod);
  }, []);

  const onSubmit = useCallback(async () => {
    setIsFailed(false);
    return updatePaymentMethod({
        paymentMethodId: selectedPaymentMethod.id,
    }).then(() => {
      router.push(routes.CHECKOUTS_SHOW(checkout.id));
    }).catch(() => {
      setIsFailed(true);
    });
  }, [selectedPaymentMethod, checkout]);

  if (isLoading || paymentMethodsLoading) {
    return (
      <div className="bg-white h-full flex items-center justify-center">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="bg-white">
      <TitleHeader
        title="お支払い方法の変更"
        backTo={routes.CHECKOUTS_SHOW(checkout.id)}
        className="border-b"
      />
      <div className="flex flex-col divide-y">
        {paymentMethods.map((paymentMethod) => (
          <div key={paymentMethod.id} className="flex items-center gap-3 px-3" onClick={onSelectFactory(paymentMethod)}>
            <div className="flex flex-col justify-start">
              <RadioInput checked={paymentMethod.id === selectedPaymentMethod?.id} />
            </div>
            <PaymentMethodListItem
              key={paymentMethod.id}
              paymentMethod={paymentMethod}
              className="flex-1 py-3"
            />
          </div>
        ))}
        <Link href={routes.CHECKOUTS_PAYMENT_METHODS_NEW(checkout.id)} className="px-3 py-4 flex items-center justify-between">
          支払い方法を追加する
          <ChevronRightIcon className="w-5" />
        </Link>
        <div className="p-3">
          <Button primary large block onClick={onSubmit}>
            変更する
          </Button>
          {isFailed && (
            <ErrorMessage
              message="決済をする事ができませんでした。他のお支払い方法をお試しください。"
              className="mt-3"
            />
          )}
        </div>
      </div>
    </div>
  );
};
