import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Review, ReviewType } from "shared/models";
import { useStoreAPI } from "shared/services/api";

export const useReview = (
  productId: string,
  reviewId: string,
  defaultData?: ReviewType
) => {
  const [review, setReview] = useState(new Review(defaultData));

  const api = useStoreAPI();
  const { isLoading } = useQuery(
    ["review", reviewId],
    () => api.getReview(productId, reviewId),
    {
      onSuccess: ({ data }) => {
        setReview(new Review(data.review));
      },
    }
  );

  return {
    review,
    isLoading,
  };
};
