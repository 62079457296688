import { CommonApi, Configuration as CommonConfiguration, ConfigurationParameters } from "./common";
import { LogisticsApi, Configuration as LogisticsConfiguration } from "./logistics";
import { StoreApi, Configuration as StoreConfiguration } from "./store";
import { SurveyApi, Configuration as SurveyConfiguration } from "./survey";

type Conf<T> = new(parameters: ConfigurationParameters) => T;
export type API<T, CT, C extends Conf<CT>> = new(conf: C) => T;

type Option = {
  basePath?: string;
  credentials?: boolean;
  accessToken?: string;
};

const API_ORIGIN = process.env.API_ORIGIN || process.env.NEXT_PUBLIC_API_ORIGIN;

function createConfiguration<T>(conf: Conf<T>, options: Option = {}): T {
  const { basePath = "/api", credentials, accessToken = "" } = options;

  return new conf({
    basePath: API_ORIGIN + basePath,
    credentials: credentials ? "include" : undefined,
    accessToken,
  });
}

export function useCommonAPI(options?: Option) {
  return new CommonApi(createConfiguration(CommonConfiguration, { basePath: "/api/common", ...options }));
}

export function useStoreAPI(options?: Option) {
  return createStoreAPI(options);
}

export function createStoreAPI(options?: Option) {
  return new StoreApi(createConfiguration(StoreConfiguration, { basePath: "/api/store", ...options }));
}

export function useSurveyAPI(options?: Option) {
  return new SurveyApi(createConfiguration(SurveyConfiguration, { basePath: "/api/survey", ...options }));
}

export function useLogisticsAPI(options?: Option) {
  return new LogisticsApi(createConfiguration(LogisticsConfiguration, { basePath: "/api/logistics", ...options }));
}
