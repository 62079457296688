/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stock } from './Stock';
import {
    StockFromJSON,
    StockFromJSONTyped,
    StockToJSON,
} from './Stock';

/**
 * 
 * @export
 * @interface CreateStocks201ResponseAllOfData
 */
export interface CreateStocks201ResponseAllOfData {
    /**
     * 
     * @type {Array<Stock>}
     * @memberof CreateStocks201ResponseAllOfData
     */
    stocks?: Array<Stock>;
}

/**
 * Check if a given object implements the CreateStocks201ResponseAllOfData interface.
 */
export function instanceOfCreateStocks201ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateStocks201ResponseAllOfDataFromJSON(json: any): CreateStocks201ResponseAllOfData {
    return CreateStocks201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateStocks201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStocks201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stocks': !exists(json, 'stocks') ? undefined : ((json['stocks'] as Array<any>).map(StockFromJSON)),
    };
}

export function CreateStocks201ResponseAllOfDataToJSON(value?: CreateStocks201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stocks': value.stocks === undefined ? undefined : ((value.stocks as Array<any>).map(StockToJSON)),
    };
}

