import { useQuery } from "@tanstack/react-query";

import { formatDate } from "shared/helpers";
import { Sale } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";
import { salesQueryKeys } from "~/features/sales";

export const useSales = () => {
  const { accessToken } = useAuthContext();

  const api = useStoreAPI({ accessToken });
  const { isLoading, data: { data } = {} } = useQuery(
    salesQueryKeys.byDate(formatDate(new Date())),
    () => api.getSales()
  );

  const sales: Sale[] = data ? data.sales.map((sale) => new Sale(sale)) : [];

  return {
    sales,
    isLoading,
  };
};
