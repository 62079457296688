import { immerable } from "immer";

import * as api from "../services/api/models";

export type TokenType = api.Token;

export class Token implements TokenType {
  [immerable] = true;

  token = "";
  refreshToken = "";
  expiresAt = new Date();
  refreshExpiresAt = new Date();

  constructor(data: Partial<TokenType> = {}) {
    Object.assign(this, data);
  }
}
