import {
  ResponseErrorBase,
  ResponseErrorInvalidRecord,
  ResponseErrorNotFound,
  ResponseErrorConflict,
} from "shared/services/api/models";

export type APIResponse = {
  status: number;
  json: () => Promise<APIErrorResponse>;
  clone: () => APIResponse;
};

export type APIError = {
  response: APIResponse;
};

export type APIErrorResponse = ResponseErrorBase & (
  ResponseErrorInvalidRecord | ResponseErrorNotFound | ResponseErrorConflict
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAPIError = (error: any): error is APIError => {
  return error.response && error.response.json;
};
