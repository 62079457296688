/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckout } from './TimelineCheckout';
import {
    TimelineCheckoutFromJSON,
    TimelineCheckoutFromJSONTyped,
    TimelineCheckoutToJSON,
} from './TimelineCheckout';
import type { TimelineCheckoutFullfilledShipping } from './TimelineCheckoutFullfilledShipping';
import {
    TimelineCheckoutFullfilledShippingFromJSON,
    TimelineCheckoutFullfilledShippingFromJSONTyped,
    TimelineCheckoutFullfilledShippingToJSON,
} from './TimelineCheckoutFullfilledShipping';
import type { TimelineCheckoutFullfilledStocking } from './TimelineCheckoutFullfilledStocking';
import {
    TimelineCheckoutFullfilledStockingFromJSON,
    TimelineCheckoutFullfilledStockingFromJSONTyped,
    TimelineCheckoutFullfilledStockingToJSON,
} from './TimelineCheckoutFullfilledStocking';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
 */
export interface GetCheckoutTimelines200ResponseAllOfDataTimelinesInner {
    /**
     * 
     * @type {string}
     * @memberof GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
     */
    event: GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerEventEnum;
    /**
     * 
     * @type {Date}
     * @memberof GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
     */
    occurredAt: Date;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
     */
    initiator: TimelineInitiator;
    /**
     * 
     * @type {object}
     * @memberof GetCheckoutTimelines200ResponseAllOfDataTimelinesInner
     */
    data: object;
}


/**
 * @export
 */
export const GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerEventEnum = {
    Created: 'created',
    FullfilledStocking: 'fullfilled_stocking',
    FullfilledUnstocking: 'fullfilled_unstocking',
    FullfilledShipping: 'fullfilled_shipping',
    FullfilledUnshipping: 'fullfilled_unshipping',
    Canceled: 'canceled',
    Refunded: 'refunded',
    Captured: 'captured',
    Completed: 'completed',
    Failed: 'failed',
    Expired: 'expired',
    Paid: 'paid',
    FullfilledShippingMail: 'fullfilled_shipping_mail',
    RedeliveryCreated: 'redelivery_created',
    RedeliveryCanceled: 'redelivery_canceled'
} as const;
export type GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerEventEnum = typeof GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerEventEnum[keyof typeof GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerEventEnum];


/**
 * Check if a given object implements the GetCheckoutTimelines200ResponseAllOfDataTimelinesInner interface.
 */
export function instanceOfGetCheckoutTimelines200ResponseAllOfDataTimelinesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "occurredAt" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSON(json: any): GetCheckoutTimelines200ResponseAllOfDataTimelinesInner {
    return GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSONTyped(json, false);
}

export function GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutTimelines200ResponseAllOfDataTimelinesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'],
        'occurredAt': (new Date(json['occurred_at'])),
        'initiator': TimelineInitiatorFromJSON(json['initiator']),
        'data': json['data'],
    };
}

export function GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerToJSON(value?: GetCheckoutTimelines200ResponseAllOfDataTimelinesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event': value.event,
        'occurred_at': (value.occurredAt.toISOString()),
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': value.data,
    };
}

