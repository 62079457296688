/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateDeliveryParcelRequestDeliveryParcel } from './UpdateDeliveryParcelRequestDeliveryParcel';
import {
    UpdateDeliveryParcelRequestDeliveryParcelFromJSON,
    UpdateDeliveryParcelRequestDeliveryParcelFromJSONTyped,
    UpdateDeliveryParcelRequestDeliveryParcelToJSON,
} from './UpdateDeliveryParcelRequestDeliveryParcel';

/**
 * 
 * @export
 * @interface UpdateDeliveryParcelRequest
 */
export interface UpdateDeliveryParcelRequest {
    /**
     * 
     * @type {UpdateDeliveryParcelRequestDeliveryParcel}
     * @memberof UpdateDeliveryParcelRequest
     */
    deliveryParcel: UpdateDeliveryParcelRequestDeliveryParcel;
}

/**
 * Check if a given object implements the UpdateDeliveryParcelRequest interface.
 */
export function instanceOfUpdateDeliveryParcelRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryParcel" in value;

    return isInstance;
}

export function UpdateDeliveryParcelRequestFromJSON(json: any): UpdateDeliveryParcelRequest {
    return UpdateDeliveryParcelRequestFromJSONTyped(json, false);
}

export function UpdateDeliveryParcelRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeliveryParcelRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryParcel': UpdateDeliveryParcelRequestDeliveryParcelFromJSON(json['delivery_parcel']),
    };
}

export function UpdateDeliveryParcelRequestToJSON(value?: UpdateDeliveryParcelRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_parcel': UpdateDeliveryParcelRequestDeliveryParcelToJSON(value.deliveryParcel),
    };
}

