import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { TagFamilyTag, TagFamilyTagType } from "shared/models";
import { useStoreAPI } from "shared/services/api";

const { persistAtom } = recoilPersist();

const categoriesState = atom<TagFamilyTagType[]>({
  key: "products/categories",
  default: [],
  effects: [persistAtom],
});

export const useProductCategories = () => {
  const [categoriesCache, setCategoriesCache] = useRecoilState(categoriesState);
  const [categories, setCategories] = useState<TagFamilyTag[]>([]);

  const api = useStoreAPI();
  const { isLoading } = useQuery(
    ["product/categories"],
    () => api.getTagFamilyTags("category"), {
    onSuccess: ({ data: { tags } }) => {
      setCategoriesCache(tags);
    },
  });

  useEffect(() => {
    setCategories(categoriesCache.map((category) => new TagFamilyTag(category)));
  }, [categoriesCache]);

  return {
    categories,
    isLoading,
  };
};
