import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Fragment, useMemo } from "react";

import { BFC } from "shared/types";

import { ProductsSortParam } from "../../hooks/useProducts";

type Option = {
  value: ProductsSortParam;
  label: string;
};

const options: Option[] = [
  {
    value: ProductsSortParam.ActivatedAtDesc,
    label: "公開順",
  },
  {
    value: ProductsSortParam.PriceAsc,
    label: "価格が安い順",
  },
  {
    value: ProductsSortParam.PriceDesc,
    label: "価格が高い順",
  },
];

type Props = {
  value: ProductsSortParam;
  onChange: (value: ProductsSortParam) => void;
};

export const ProductSortDropdown: BFC<Props> = ({ value, onChange }) => {
  const currentOption = useMemo(() => {
    return options.find((option) => option.value === value) || options[0];
  }, [value]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 font-semibold text-black-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {currentOption.label}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option.value}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left"
                    )}
                    onClick={() => onChange(option.value)}
                  >
                    {option.label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
