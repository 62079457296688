import { BookmarkSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { formatZipCode } from "shared/helpers";
import { Address } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  address: Address;
  clamp?: boolean;
  isDefault?: boolean;
};

export const AddressListItem: BFC<Props> = ({
  address,
  clamp = false,
  isDefault = false,
  className,
}) => {
  return (
    <div className={classNames("bg-white flex flex-col gap-1", className)}>
      <div className="font-bold">
        {address.lastName} {address.firstName}
      </div>
      <div>
        〒{formatZipCode(address.zipCode)}
      </div>
      <div className={classNames({ "line-clamp-1" : clamp })}>
        <span className="mr-1">{address.prefecture.name}</span>
        <span className="mr-1">{address.city}</span>
        <span className="mr-1">{address.town}</span>
        <span className="mr-1">{address.street}</span>
        {address.building}
      </div>
      {address.deliveryInstructions && (
        <div className={classNames({ "line-clamp-1" : clamp })}>
          {address.deliveryInstructions}
        </div>
      )}
      <div>
        {address.phone}
      </div>
      {isDefault && (
        <div className="mt-1">
          <div className="text-black-500 rounded flex items-center">
            <BookmarkSquareIcon className="w-4 mr-1" />
            いつも使用するお届け先
          </div>
        </div>
      )}
    </div>
  );
};
