import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import { Cart, Checkout } from "shared/models";
import { useStoreAPI } from "shared/services/api";

type Parcel = {
  items: Array<{
    productId: string;
    quantity: number;
    alternativeProductId?: string;
  }>;
};

export const useCartCalculate = (cart: Cart) => {
  const api = useStoreAPI();
  const [checkout, setCheckout] = useState<Checkout>(new Checkout());

  const parcel = useMemo(() => {
    return cart.getItems().reduce<Parcel>((acc, item) => {
      const alternativeProduct = item.getAlternativeProduct();
      acc.items.push({
        productId: item.product.id,
        quantity: item.quantity,
        alternativeProductId: alternativeProduct?.id,
      });
      return acc;
    }, { items: [] });
  }, [cart]);

  const { isLoading } = useQuery(
    ["cart/calculate", parcel],
    () => api.calculateCheckout({ parcels: [parcel] }),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
      enabled: parcel.items.length > 0,
    },
  );

  return {
    checkout,
    isLoading,
  };
};
