import { useMutation } from "@tanstack/react-query";

import { useStoreAPI } from "shared/services/api";
import { CreateReviewRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

type Options = {
  productId: string;
};

export const useCreateReview = ({ productId }: Options) => {
  const { accessToken } = useAuthContext();
  const api = useStoreAPI({ accessToken });

  const { mutateAsync, isLoading } = useMutation(
    ["products/reviews"],
    (data: CreateReviewRequest) => api.createReview(productId, data)
  );

  return {
    create: mutateAsync,
    isLoading,
  };
};
