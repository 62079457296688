import { useRouter } from "next/router";
import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link, Button } from "shared/components";
import { getParam } from "shared/lib/next";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import {
  useProducts,
  ProductCard,
  ProductSortDropdown,
  useProductsQueryParams,
} from "~/features/products";

type Props = {
  keyword?: string;
};

export const ProductsSearchPage: BFC<Props> = () => {
  const router = useRouter();
  const keyword = getParam(router.query, "keyword");

  const { sort, setSort } = useProductsQueryParams();
  const {
    fetchNextPage,
    products,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useProducts({ keyword, sort });

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="bg-white p-3">
      <div className="mb-3">
        <ProductSortDropdown value={sort} onChange={setSort} />
      </div>
      <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
        <div className="grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-4">
          {products.map((product) => (
            <Link href={routes.PRODUCTS_SHOW(product.id)} key={product.id}>
              <ProductCard
                product={product}
              />
            </Link>
          ))}
        </div>
      </InfiniteScroll>
      {products.length === 0 && !isFetchingNextPage && !isLoading && (
        <div className="flex flex-col items-center justify-center text-black-500 py-8">
          該当する商品はありません
        </div>
      )}
      {(isLoading || isFetchingNextPage) ? (
        <div className="flex justify-center items-center">
          <ColorRing />
        </div>
      ) : (
        <Link href={routes.PRODUCTS_REQUEST}>
          <Button primary block className="mt-8">商品をリクエストする</Button>
        </Link>
      )}
    </div>
  );
};
