import { useCallback, useEffect, useMemo, useState } from "react";
import { HiCheck, HiLink, HiOutlineDocumentText } from "react-icons/hi2";

import { Button, Link } from "shared/components";
import { formatDateTime, nl2br } from "shared/helpers";
import { Product, ProductType } from "shared/models";
import { BFC } from "shared/types";

import { ReadMore, Table } from "~/components";
import { routes } from "~/constants";
import { AddToCartButtonState, useAddToCartButton, useCartContext } from "~/features/carts";
import { ReviewSummary } from "~/features/reviews";

import {
  ProductCarousel,
  ProductPrice,
  AlternativeProductSelector,
  StorageTemperatureBadge,
  TagBadge,
  ProductsSlider,
} from "../../components";
import { useProduct } from "../../hooks";

type Props = {
  id: string;
  defaultData?: ProductType;
};

export const ProductShowPage: BFC<Props> = ({
  id,
  defaultData,
}) => {
  const { product } = useProduct(id, defaultData);
  const { cart } = useCartContext();
  const [alternativeProduct, setAlternativeProduct] = useState<Product | null>(null);
  const notes = useMemo(() => product.getNotes(), [product]);

  useEffect(() => {
    cart.getItems().forEach((item) => {
      if (item.product.id === product.id && item.hasAlternativeProduct()) {
        setAlternativeProduct(item.getAlternativeProduct());
      }
    });
  }, [cart, product]);

  const addToCartButton = useAddToCartButton({
    product,
  });

  const handleAddToCartButtonClick = useCallback(async () => {
    await addToCartButton.handleClick({
      alternativeProductId: alternativeProduct?.id,
      removeAlternativeProduct: !alternativeProduct,
    });
  }, [addToCartButton.handleClick, alternativeProduct]);

  const addToCartButtonContent = useMemo(() => {
    if (product.sellableQuantity === 0) {
      return "売り切れました";
    }

    if (addToCartButton.buttonState === AddToCartButtonState.Disabled) {
      return "この商品は販売終了しました";
    }

    if (addToCartButton.buttonState === AddToCartButtonState.Success) {
      return (
        <>
          <HiCheck size={20} className="text-white" />
          カートに追加しました
        </>
      );
    }

    return "カートに入れる";
  }, [addToCartButton.buttonState, product.sellableQuantity]);

  const relatedProducts = useMemo(() => {
    return product.getRelatedProducts();
  }, [product]);

  return (
    <div className="bg-white p-4 md:p-10 flex flex-col gap-16">
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-9">
        <div>
          <ProductCarousel product={product} />
        </div>
        <div className="flex flex-col gap-y-4 md:gap-y-9">
          <div className="flex flex-col gap-1 mt-6 md:mt-0">
            <div className="flex gap-1 flex-wrap">
              <StorageTemperatureBadge storageTemperature={product.storageTemperature} />
              {product.getTags().map((tag) => (
                <Link href={routes.PRODUCTS_SEARCH(tag.name)} key={tag.id}>
                  <TagBadge tag={tag.name} />
                </Link>
              ))}
            </div>
            <h1 className="text-2xl font-bold md:text-5xl">
              {product.title}
            </h1>
          </div>
          <div>{product.description}</div>
          <div className="flex flex-col gap-1 items-end">
            {product.saleEndAt && (
              <div className="font-bold text-primary">{formatDateTime(product.saleEndAt)}まで割引中</div>
            )}
            <ProductPrice
              price={product.price}
              discountPrice={product.discountPrice}
              taxPercentage={product.taxPercentage}
              className="text-2xl text-right font-medium md:text-5xl"
            />
          </div>
          <div className="flex flex-col gap-4 mt-2">
            <AlternativeProductSelector
              selected={alternativeProduct}
              products={product.getAlternativeProducts()}
              onSelect={setAlternativeProduct}
            />

            {typeof product.sellableQuantity === "number" ? (
              <div className="text-lg text-primary font-semibold">
                {product.sellableQuantity > 0 ? `残り${product.sellableQuantity}点` : "在庫がありません"}
              </div>
            ) : null}

            <Button
              onClick={handleAddToCartButtonClick}
              primary
              large
              block
              disabled={!addToCartButton.isAvailable}
              className="gap-1"
            >
              {addToCartButtonContent}
            </Button>
          </div>
        </div>
      </div>

      {notes.length > 0 && (
        <ReadMore>
          <div className="mt-3">
            <h3 className="font-bold text-xl flex items-center gap-1">
              <HiOutlineDocumentText size={20} />
              商品詳細
            </h3>
            <div className="border rounded overflow-hidden mt-3">
              <Table className="w-full">
                <Table.Body>
                  {notes.map((note) => (
                    <Table.Row key={note.heading}>
                      <Table.Col head className="w-24 text-sm align-top">{note.heading}</Table.Col>
                      <Table.Col className="text-sm">
                        {nl2br(note.body)}
                      </Table.Col>
                    </Table.Row>
                  ))}
                  {/*
                  <Table.Row>
                    <Table.Col head className="w-40">消費/賞味期限目安</Table.Col>
                    <Table.Col>
                      {formatExpirationDays(product.expirationDays, product.maxExpirationDays)}
                    </Table.Col>
                  </Table.Row>
                  {product.expirationDays === 1 && (
                    <Table.Row>
                      <Table.Col head className="w-40">配送制限</Table.Col>
                      <Table.Col>
                        この商品は16時以降の配送のみ承ります
                      </Table.Col>
                    </Table.Row>
                  )}
                  */}
                </Table.Body>
              </Table>
            </div>
          </div>
        </ReadMore>
      )}

      {relatedProducts.length > 0 && (
        <div>
          <h3 className="font-bold text-xl flex gap-1">
            <HiLink size={20} />
            関連商品
          </h3>
          <div className="-mx-3 mt-3">
            <ProductsSlider products={relatedProducts} />
          </div>
        </div>
      )}

      <ReviewSummary productId={product.id} />
    </div>
  );
};
