/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUserAddresses200ResponseAllOfData } from './GetUserAddresses200ResponseAllOfData';
import {
    GetUserAddresses200ResponseAllOfDataFromJSON,
    GetUserAddresses200ResponseAllOfDataFromJSONTyped,
    GetUserAddresses200ResponseAllOfDataToJSON,
} from './GetUserAddresses200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetUserAddresses200Response
 */
export interface GetUserAddresses200Response {
    /**
     * 
     * @type {GetUserAddresses200ResponseAllOfData}
     * @memberof GetUserAddresses200Response
     */
    data: GetUserAddresses200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetUserAddresses200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetUserAddresses200Response interface.
 */
export function instanceOfGetUserAddresses200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetUserAddresses200ResponseFromJSON(json: any): GetUserAddresses200Response {
    return GetUserAddresses200ResponseFromJSONTyped(json, false);
}

export function GetUserAddresses200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserAddresses200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetUserAddresses200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetUserAddresses200ResponseToJSON(value?: GetUserAddresses200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetUserAddresses200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

