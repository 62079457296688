import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Product, ProductType } from "./product";

export type FullfillmentShippingItemType = api.FullfillmentShippingItem;

export const FullfillmentShippingItemCancelReason = api.FullfillmentShippingItemCancelReasonEnum;
export type FullfillmentShippingItemCancelReason = api.FullfillmentShippingItemCancelReasonEnum;

export class FullfillmentShippingItem implements FullfillmentShippingItemType {
  [immerable] = true;

  id = "";
  product: ProductType = new Product();
  alternativeProduct?: ProductType;
  quantity = 0;
  cancelQuantity = 0;
  cancelReason: FullfillmentShippingItemCancelReason = FullfillmentShippingItemCancelReason.No;

  constructor(data: Partial<FullfillmentShippingItemType> = {}) {
    Object.assign(this, data);
  }

  getProduct() {
    return new Product(this.product);
  }

  getAlternativeProduct() {
    return this.alternativeProduct ? new Product(this.alternativeProduct) : null;
  }

  getTotalQuantity() {
    return this.quantity + this.cancelQuantity;
  }

  hasAlternativeProduct(): this is Omit<FullfillmentShippingItem, "alternativeProduct" | "getAlternativeProduct"> & {
    alternativeProduct: ProductType,
    getAlternativeProduct(): Product,
  } {
    return !!this.alternativeProduct;
  }
}
