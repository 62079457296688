/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponCode } from './CouponCode';
import {
    CouponCodeFromJSON,
    CouponCodeFromJSONTyped,
    CouponCodeToJSON,
} from './CouponCode';

/**
 * 
 * @export
 * @interface UserCouponCode
 */
export interface UserCouponCode {
    /**
     * 
     * @type {string}
     * @memberof UserCouponCode
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof UserCouponCode
     */
    usedAt?: Date;
    /**
     * 
     * @type {CouponCode}
     * @memberof UserCouponCode
     */
    couponCode: CouponCode;
}

/**
 * Check if a given object implements the UserCouponCode interface.
 */
export function instanceOfUserCouponCode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "couponCode" in value;

    return isInstance;
}

export function UserCouponCodeFromJSON(json: any): UserCouponCode {
    return UserCouponCodeFromJSONTyped(json, false);
}

export function UserCouponCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCouponCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'usedAt': !exists(json, 'used_at') ? undefined : (new Date(json['used_at'])),
        'couponCode': CouponCodeFromJSON(json['coupon_code']),
    };
}

export function UserCouponCodeToJSON(value?: UserCouponCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'used_at': value.usedAt === undefined ? undefined : (value.usedAt.toISOString()),
        'coupon_code': CouponCodeToJSON(value.couponCode),
    };
}

