import Link from "next/link";
import { useCallback } from "react";
import { HiOutlineChevronLeft, HiOutlinePlusCircle } from "react-icons/hi";

import { LinkButton } from "shared/components";
import { ProductType } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useProduct } from "~/features/products";
import { ReviewList } from "~/features/reviews/components";

import { useReviews } from "../../hooks";

type Props = {
  product: ProductType;
};

export const ReviewIndexPage: BFC<Props> = ({ product: defaultProduct }) => {
  const productId = defaultProduct.id;
  const { product } = useProduct(productId, defaultProduct);
  const { reviews, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useReviews({
      productId,
    });

  const loadMore = useCallback(async () => {
    await fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="bg-white divide-y divide-gray-200">
      <Link href={routes.PRODUCTS_SHOW(productId)}>
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg bg-white">
          <HiOutlineChevronLeft size={24} />
          {product.title}のレビュー
          {reviews.length ? ` (${reviews.length}件)` : null}
        </h1>
      </Link>

      <div className="px-3 sm:px-10">
        <ReviewList
          productId={productId}
          reviews={reviews}
          isLoading={isLoading}
          infiniteScrollProps={{
            hasMore: !!hasNextPage,
            loadMore,
            isFetchingNextPage,
          }}
        />
      </div>

      <div className="flex flex-col gap-4 items-center py-8">
        <p className="text-gray-600">お客様のご意見を共有してください😊</p>
        <LinkButton
          href={routes.REVIEWS_NEW(productId)}
          primary
          className="flex items-center gap-1"
        >
          <HiOutlinePlusCircle className="text-xl" />
          レビューを投稿する
        </LinkButton>
      </div>
    </div>
  );
};
