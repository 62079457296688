import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";

import { useBooleanState } from "shared/hooks";
import { CFC } from "shared/types";

import { Footer } from "~/components/layouts/Footer";
import { Header } from "~/components/layouts/Header";
import { Sidebar } from "~/components/layouts/Sidebar";
import { FastestDeliverableLine } from "~/features/addresses/components/FastestDeliverableLine";

type Props = {
  headerContent?: React.ReactNode;
  headerClassName?: string;
};

export const StandardLayout: CFC<Props> = ({
  children,
  headerClassName,
}) => {
  const [sidebarOpen, openSidebar, hideSidebar] = useBooleanState();

  return (
    <div>
      <div className="bg-primary text-neutral-50 text-center py-1 text-xs">
        コストコ専門ネットスーパー / 年会費0円 / 最短翌日配送 / 都内一部地域限定
      </div>
      <FastestDeliverableLine />
      <div className="sticky top-0 z-40 md:border-b bg-white">
        <Header
          className={classNames("md:max-w-7xl md:mx-auto", headerClassName)}
          onMenuClick={openSidebar}
        />
      </div>
      <div className="md:max-w-7xl md:mx-auto md:border-x">
        <div className="flex flex-col min-h-screen">
          <div className="flex-1 flex">
            <div className="hidden md:block md:w-64 md:border-r shrink-0 bg-white">
              <Sidebar hideHeader className="sticky top-12" />
            </div>
            <div className="flex-1 max-w-full md:max-w-[calc(100%-256px)] bg-gray-100">
              {children}
            </div>
          </div>
        </div>
        { /* sidebar for mobile */ }
        <Transition show={sidebarOpen}>
          <Dialog onClose={hideSidebar} className="md:hidden">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 z-40" />
            <Dialog.Panel className="fixed inset-y-0 right-0 bg-gray-200 w-full max-w-xs flex flex-col z-40 overflow-y-scroll">
              <Sidebar className="flex-1" onClose={hideSidebar} />
            </Dialog.Panel>
          </Dialog>
        </Transition>
        { /* sidebar for desktop */ }
      </div>
      <Footer />
    </div>
  );
};
