/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUserAddressRequestUserAddress } from './CreateUserAddressRequestUserAddress';
import {
    CreateUserAddressRequestUserAddressFromJSON,
    CreateUserAddressRequestUserAddressFromJSONTyped,
    CreateUserAddressRequestUserAddressToJSON,
} from './CreateUserAddressRequestUserAddress';

/**
 * 
 * @export
 * @interface CreateUserAddressRequest
 */
export interface CreateUserAddressRequest {
    /**
     * 
     * @type {CreateUserAddressRequestUserAddress}
     * @memberof CreateUserAddressRequest
     */
    userAddress?: CreateUserAddressRequestUserAddress;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUserAddressRequest
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the CreateUserAddressRequest interface.
 */
export function instanceOfCreateUserAddressRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateUserAddressRequestFromJSON(json: any): CreateUserAddressRequest {
    return CreateUserAddressRequestFromJSONTyped(json, false);
}

export function CreateUserAddressRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserAddressRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAddress': !exists(json, 'user_address') ? undefined : CreateUserAddressRequestUserAddressFromJSON(json['user_address']),
        'isDefault': !exists(json, 'is_default') ? undefined : json['is_default'],
    };
}

export function CreateUserAddressRequestToJSON(value?: CreateUserAddressRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_address': CreateUserAddressRequestUserAddressToJSON(value.userAddress),
        'is_default': value.isDefault,
    };
}

