/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConnectUserProviderRequestUser } from './ConnectUserProviderRequestUser';
import {
    ConnectUserProviderRequestUserFromJSON,
    ConnectUserProviderRequestUserFromJSONTyped,
    ConnectUserProviderRequestUserToJSON,
} from './ConnectUserProviderRequestUser';
import type { SignUpUserRequestCart } from './SignUpUserRequestCart';
import {
    SignUpUserRequestCartFromJSON,
    SignUpUserRequestCartFromJSONTyped,
    SignUpUserRequestCartToJSON,
} from './SignUpUserRequestCart';

/**
 * 
 * @export
 * @interface ConnectUserProviderRequest
 */
export interface ConnectUserProviderRequest {
    /**
     * 
     * @type {SignUpUserRequestCart}
     * @memberof ConnectUserProviderRequest
     */
    cart?: SignUpUserRequestCart;
    /**
     * 
     * @type {ConnectUserProviderRequestUser}
     * @memberof ConnectUserProviderRequest
     */
    user?: ConnectUserProviderRequestUser;
}

/**
 * Check if a given object implements the ConnectUserProviderRequest interface.
 */
export function instanceOfConnectUserProviderRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConnectUserProviderRequestFromJSON(json: any): ConnectUserProviderRequest {
    return ConnectUserProviderRequestFromJSONTyped(json, false);
}

export function ConnectUserProviderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectUserProviderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cart': !exists(json, 'cart') ? undefined : SignUpUserRequestCartFromJSON(json['cart']),
        'user': !exists(json, 'user') ? undefined : ConnectUserProviderRequestUserFromJSON(json['user']),
    };
}

export function ConnectUserProviderRequestToJSON(value?: ConnectUserProviderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cart': SignUpUserRequestCartToJSON(value.cart),
        'user': ConnectUserProviderRequestUserToJSON(value.user),
    };
}

