import { useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { createAtom } from "shared/lib/recoil";
import { Prefecture } from "shared/models";
import { useCommonAPI } from "shared/services/api";

const PrefecturesState = createAtom<Prefecture[]>([]);

export const usePrefectures = () => {
  const api = useCommonAPI();
  const [prefectures, setPrefectures] = useRecoilState(PrefecturesState);

  const { isLoading } = useQuery(["prefectures"], () => api.getPrefectures(), {
    onSuccess: ({ data: { prefectures } }) => {
      setPrefectures(prefectures.map((d) => new Prefecture(d)));
    },
    staleTime: Infinity,
  });

  return {
    prefectures,
    isLoading,
  };
};
