/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddFavoriteRequestFavorite } from './AddFavoriteRequestFavorite';
import {
    AddFavoriteRequestFavoriteFromJSON,
    AddFavoriteRequestFavoriteFromJSONTyped,
    AddFavoriteRequestFavoriteToJSON,
} from './AddFavoriteRequestFavorite';

/**
 * 
 * @export
 * @interface AddFavoriteRequest
 */
export interface AddFavoriteRequest {
    /**
     * 
     * @type {AddFavoriteRequestFavorite}
     * @memberof AddFavoriteRequest
     */
    favorite: AddFavoriteRequestFavorite;
}

/**
 * Check if a given object implements the AddFavoriteRequest interface.
 */
export function instanceOfAddFavoriteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "favorite" in value;

    return isInstance;
}

export function AddFavoriteRequestFromJSON(json: any): AddFavoriteRequest {
    return AddFavoriteRequestFromJSONTyped(json, false);
}

export function AddFavoriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFavoriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favorite': AddFavoriteRequestFavoriteFromJSON(json['favorite']),
    };
}

export function AddFavoriteRequestToJSON(value?: AddFavoriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favorite': AddFavoriteRequestFavoriteToJSON(value.favorite),
    };
}

