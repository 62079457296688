/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliverableAddress } from './DeliverableAddress';
import {
    DeliverableAddressFromJSON,
    DeliverableAddressFromJSONTyped,
    DeliverableAddressToJSON,
} from './DeliverableAddress';

/**
 * 
 * @export
 * @interface GetDeliverableAddresses200ResponseAllOfData
 */
export interface GetDeliverableAddresses200ResponseAllOfData {
    /**
     * 
     * @type {Array<DeliverableAddress>}
     * @memberof GetDeliverableAddresses200ResponseAllOfData
     */
    addresses: Array<DeliverableAddress>;
    /**
     * 
     * @type {Date}
     * @memberof GetDeliverableAddresses200ResponseAllOfData
     */
    fastestShippingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableAddresses200ResponseAllOfData
     */
    fastestShippingTime?: string;
}

/**
 * Check if a given object implements the GetDeliverableAddresses200ResponseAllOfData interface.
 */
export function instanceOfGetDeliverableAddresses200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addresses" in value;

    return isInstance;
}

export function GetDeliverableAddresses200ResponseAllOfDataFromJSON(json: any): GetDeliverableAddresses200ResponseAllOfData {
    return GetDeliverableAddresses200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetDeliverableAddresses200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliverableAddresses200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addresses': ((json['addresses'] as Array<any>).map(DeliverableAddressFromJSON)),
        'fastestShippingDate': !exists(json, 'fastest_shipping_date') ? undefined : (new Date(json['fastest_shipping_date'])),
        'fastestShippingTime': !exists(json, 'fastest_shipping_time') ? undefined : json['fastest_shipping_time'],
    };
}

export function GetDeliverableAddresses200ResponseAllOfDataToJSON(value?: GetDeliverableAddresses200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addresses': ((value.addresses as Array<any>).map(DeliverableAddressToJSON)),
        'fastest_shipping_date': value.fastestShippingDate === undefined ? undefined : (value.fastestShippingDate.toISOString().substr(0,10)),
        'fastest_shipping_time': value.fastestShippingTime,
    };
}

