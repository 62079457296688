import { immerable } from "immer";

import * as api from "../services/api/models";

export type AddressType = api.Address;

export class Address implements AddressType {
  [immerable] = true;

  id = "";
  zipCode = "";
  prefecture = { id: "", name: "" };
  city = "";
  town = "";
  street = "";
  building = "";
  firstName = "";
  lastName = "";
  phone = "";
  deliveryInstructions = "";

  constructor(data: Partial<AddressType> = {}) {
    Object.assign(this, data);
  }

  formatForGeocode() {
    return `${this.prefecture.name} ${this.city} ${this.town} ${this.street}`;
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  get summary() {
    return `${this.prefecture.name} ${this.city} ${this.town} ${this.street} ${this.building}`;
  }
}
