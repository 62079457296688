/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';

/**
 * 
 * @export
 * @interface GetCheckouts200ResponseAllOfData
 */
export interface GetCheckouts200ResponseAllOfData {
    /**
     * 
     * @type {Array<Checkout>}
     * @memberof GetCheckouts200ResponseAllOfData
     */
    checkouts: Array<Checkout>;
}

/**
 * Check if a given object implements the GetCheckouts200ResponseAllOfData interface.
 */
export function instanceOfGetCheckouts200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkouts" in value;

    return isInstance;
}

export function GetCheckouts200ResponseAllOfDataFromJSON(json: any): GetCheckouts200ResponseAllOfData {
    return GetCheckouts200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckouts200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckouts200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkouts': ((json['checkouts'] as Array<any>).map(CheckoutFromJSON)),
    };
}

export function GetCheckouts200ResponseAllOfDataToJSON(value?: GetCheckouts200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkouts': ((value.checkouts as Array<any>).map(CheckoutToJSON)),
    };
}

