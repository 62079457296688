/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ResponseErrorBadRequest,
    instanceOfResponseErrorBadRequest,
    ResponseErrorBadRequestFromJSON,
    ResponseErrorBadRequestFromJSONTyped,
    ResponseErrorBadRequestToJSON,
} from './ResponseErrorBadRequest';
import {
    ResponseErrorInvalidRecord,
    instanceOfResponseErrorInvalidRecord,
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordFromJSONTyped,
    ResponseErrorInvalidRecordToJSON,
} from './ResponseErrorInvalidRecord';

/**
 * @type AddFavorite400Response
 * 
 * @export
 */
export type AddFavorite400Response = ResponseErrorBadRequest | ResponseErrorInvalidRecord;

export function AddFavorite400ResponseFromJSON(json: any): AddFavorite400Response {
    return AddFavorite400ResponseFromJSONTyped(json, false);
}

export function AddFavorite400ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFavorite400Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...ResponseErrorBadRequestFromJSONTyped(json, true), ...ResponseErrorInvalidRecordFromJSONTyped(json, true) };
}

export function AddFavorite400ResponseToJSON(value?: AddFavorite400Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfResponseErrorBadRequest(value)) {
        return ResponseErrorBadRequestToJSON(value as ResponseErrorBadRequest);
    }
    if (instanceOfResponseErrorInvalidRecord(value)) {
        return ResponseErrorInvalidRecordToJSON(value as ResponseErrorInvalidRecord);
    }

    return {};
}

