/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckoutFullfilledShippingAllOfData } from './TimelineCheckoutFullfilledShippingAllOfData';
import {
    TimelineCheckoutFullfilledShippingAllOfDataFromJSON,
    TimelineCheckoutFullfilledShippingAllOfDataFromJSONTyped,
    TimelineCheckoutFullfilledShippingAllOfDataToJSON,
} from './TimelineCheckoutFullfilledShippingAllOfData';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledShipping
 */
export interface TimelineCheckoutFullfilledShipping {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledShipping
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledShipping
     */
    event: TimelineCheckoutFullfilledShippingEventEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimelineCheckoutFullfilledShipping
     */
    occurredAt: Date;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof TimelineCheckoutFullfilledShipping
     */
    initiator: TimelineInitiator;
    /**
     * 
     * @type {TimelineCheckoutFullfilledShippingAllOfData}
     * @memberof TimelineCheckoutFullfilledShipping
     */
    data: TimelineCheckoutFullfilledShippingAllOfData;
}


/**
 * @export
 */
export const TimelineCheckoutFullfilledShippingEventEnum = {
    FullfilledShipping: 'fullfilled_shipping'
} as const;
export type TimelineCheckoutFullfilledShippingEventEnum = typeof TimelineCheckoutFullfilledShippingEventEnum[keyof typeof TimelineCheckoutFullfilledShippingEventEnum];


/**
 * Check if a given object implements the TimelineCheckoutFullfilledShipping interface.
 */
export function instanceOfTimelineCheckoutFullfilledShipping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "occurredAt" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledShippingFromJSON(json: any): TimelineCheckoutFullfilledShipping {
    return TimelineCheckoutFullfilledShippingFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledShippingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledShipping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'],
        'occurredAt': (new Date(json['occurred_at'])),
        'initiator': TimelineInitiatorFromJSON(json['initiator']),
        'data': TimelineCheckoutFullfilledShippingAllOfDataFromJSON(json['data']),
    };
}

export function TimelineCheckoutFullfilledShippingToJSON(value?: TimelineCheckoutFullfilledShipping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event': value.event,
        'occurred_at': (value.occurredAt.toISOString()),
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': TimelineCheckoutFullfilledShippingAllOfDataToJSON(value.data),
    };
}

