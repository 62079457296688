import { immerable } from "immer";

import * as api from "shared/services/api/logistics/models";

import { CheckoutParcel, CheckoutParcelType } from "./checkout_parcel";
import { DeliveryDriver, DeliveryDriverType } from "./delivery_driver";

export type DeliveryParcelStatus = api.DeliveryParcelStatusEnum;
export const DeliveryParcelStatus = api.DeliveryParcelStatusEnum;

export type DeliveryParcelType = api.DeliveryParcel;

export class DeliveryParcel implements DeliveryParcelType {
  [immerable] = true;

  id = "";
  shippingDate: Date = new Date();
  checkoutParcel: CheckoutParcelType = new CheckoutParcel();
  deliveryDriver: DeliveryDriverType = new DeliveryDriver();
  deliveryOrder = 0;
  status: DeliveryParcelStatus = "waiting";

  constructor(data: Partial<DeliveryParcelType> = {}) {
    Object.assign(this, data);
  }

  getCheckoutParcel() {
    return new CheckoutParcel(this.checkoutParcel);
  }

  getShippingAddress() {
    return this.getCheckoutParcel().getCheckout().getShippingAddress();
  }

  getShippingTime() {
    return this.getCheckoutParcel().getShippingTime();
  }

  getDeliveryDriver() {
    return new DeliveryDriver(this.deliveryDriver);
  }

  isSame(other: DeliveryParcel | string) {
    if (typeof other === "string") {
      return this.key === other;
    } else {
      return this.key === other.key;
    }
  }

  isSameShippingTime(other: DeliveryParcel) {
    return this.checkoutParcel.shippingTime.id === other.checkoutParcel.shippingTime.id;
  }

  get key() {
    return this.id || this.checkoutParcel.id;
  }

  get isAssigned() {
    return this.deliveryDriver.id !== "";
  }
}
