/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * アカウント新規作成時は必須
 * @export
 * @interface ConnectUserProviderRequestUser
 */
export interface ConnectUserProviderRequestUser {
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    firstName: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectUserProviderRequestUser
     */
    emailMarketingAgreed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConnectUserProviderRequestUser
     */
    zipCode?: string;
}

/**
 * Check if a given object implements the ConnectUserProviderRequestUser interface.
 */
export function instanceOfConnectUserProviderRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "emailMarketingAgreed" in value;

    return isInstance;
}

export function ConnectUserProviderRequestUserFromJSON(json: any): ConnectUserProviderRequestUser {
    return ConnectUserProviderRequestUserFromJSONTyped(json, false);
}

export function ConnectUserProviderRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectUserProviderRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastName': json['last_name'],
        'firstName': json['first_name'],
        'emailMarketingAgreed': json['email_marketing_agreed'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
    };
}

export function ConnectUserProviderRequestUserToJSON(value?: ConnectUserProviderRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_name': value.lastName,
        'first_name': value.firstName,
        'email_marketing_agreed': value.emailMarketingAgreed,
        'zip_code': value.zipCode,
    };
}

