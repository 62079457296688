import { useRouter } from "next/router";
import { useCallback } from "react";
import { UseFormSetError } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { getParam } from "shared/lib/next";
import { BFC, isAPIError } from "shared/types";

import { routes } from "~/constants";

import { SignInData, SignInForm } from "../../forms";
import { useSignIn } from "../../hooks";

export const SignInPage: BFC = () => {
  const { signIn, isSignedInLoading } = useSignIn();
  const router = useRouter();
  const origin = getParam(router.query, "origin");

  const onSubmit = useCallback(
    async (data: SignInData) => {
      await signIn(data);
      router.push(origin || routes.TOP);
    },
    [signIn, origin]
  );

  const onError = useCallback(
    (e: unknown, setError: UseFormSetError<SignInData>) => {
      if (isAPIError(e)) {
        e.response
          .clone()
          .json()
          .then((res) => {
            if (res.error.code === "not_found") {
              setError("password", {
                type: "custome",
                message: "メールアドレスまたはパスワードが間違っています",
              });
            }
          });
      }
    },
    []
  );

  const onGoogleSignIn = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_GOOGLE({ return_to: origin }));
  }, [origin]);

  if (isSignedInLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">ログイン</h1>
          <div>
            会員登録は
            <Link href={routes.USERS_SIGN_UP}>こちら</Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <SignInForm onSubmit={onSubmit} onError={onError} />
          <Link href={routes.USERS_PASSWORD_NEW} className="text-base text-gray-500 text-center">
            パスワードを忘れた方はこちら
          </Link>
          <div className="border-t py-3">
            <Button
              block
              large
              className="flex items-center gap-2"
              onClick={onGoogleSignIn}
            >
              <FcGoogle size={20} />
              Googleアカウントでログイン
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
