import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { HiDevicePhoneMobile, HiEnvelope } from "react-icons/hi2";

import { Button } from "shared/components";
import { CFC } from "shared/types";

import { useAuthContext } from "~/features/auth/hooks";

import { useEmailConfirmation } from "../../hooks";

export const EmailConfirmedRoute: CFC = ({
  children,
}) => {
  const { user, isLoading } = useAuthContext();
  const { sendConfirmationMail } = useEmailConfirmation();
  const { confirmedEmail } = user;
  const [sent, setSent] = useState(false);
  const router = useRouter();

  const handleResend = useCallback(() => {
    const returnTo = router.asPath;
    sendConfirmationMail({ returnTo }).then(() => {
      setSent(true);
    });
  }, [sendConfirmationMail, router]);

  useEffect(() => {
    if (confirmedEmail) {
      setSent(false);
    }
  }, [user, confirmedEmail]);

  if (isLoading) {
    return <></>;
  }

  if (sent) {
    return (
      <div className="flex flex-col gap-4 bg-white p-3 py-6">
        <h1 className="font-bold text-center">再送信しました</h1>
        <div className="flex items-center justify-center text-5xl">
          ✉️
        </div>
        <div>
          お手数ですが、もう一度メールアドレスの認証をお願いします
        </div>
      </div>
    );
  }

  if (!confirmedEmail) {
    return (
      <div className="flex flex-col gap-8 bg-white p-6 py-6">
        <h1 className="font-bold text-center">メールアドレス認証が完了していません</h1>
        <div>
          お手数ですが、メールアドレス認証を完了させてください
        </div>

        <div className="bg-white border shadow rounded-lg">
          <div className="p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              認証メールが届かない場合は、以下をご確認のうえ、もう一度お試しください。
            </h3>
            <ul role="list" className="mt-8 space-y-6 text-gray-600">
              <li className="flex gap-x-3">
                <HiEnvelope className="mt-1 h-5 w-5 flex-none text-gray-500" aria-hidden="true" />
                <div className="flex-1 space-y-1">
                  <p className="font-semibold text-gray-900">1. Google、Yahoo!メール等をご利用の方</p>
                  <p>迷惑メールフォルダに振り分けられている可能性がございますため、そちらのご確認をお願いいたします。</p>
                </div>
              </li>
              <li className="flex gap-x-3">
                <HiDevicePhoneMobile className="mt-1 h-5 w-5 flex-none text-gray-500" aria-hidden="true" />
                <div className="flex-1 space-y-1">
                  <p className="font-semibold text-gray-900">2. docomo、au等キャリアメールをご利用の方</p>
                  <p>受信がフィルタリングされている可能性がございますため、受信リストに @soctoc.jp のドメインを設定してください。</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <Button primary block onClick={handleResend}>認証メールを再送信する</Button>
      </div>
    );
  }

  return <>{children}</>;
};
