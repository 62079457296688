/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stockup } from './Stockup';
import {
    StockupFromJSON,
    StockupFromJSONTyped,
    StockupToJSON,
} from './Stockup';
import type { StockupItem } from './StockupItem';
import {
    StockupItemFromJSON,
    StockupItemFromJSONTyped,
    StockupItemToJSON,
} from './StockupItem';

/**
 * 
 * @export
 * @interface CreateStockupItem201ResponseAllOfData
 */
export interface CreateStockupItem201ResponseAllOfData {
    /**
     * 
     * @type {Stockup}
     * @memberof CreateStockupItem201ResponseAllOfData
     */
    stockup: Stockup;
    /**
     * 
     * @type {StockupItem}
     * @memberof CreateStockupItem201ResponseAllOfData
     */
    stockupItem: StockupItem;
}

/**
 * Check if a given object implements the CreateStockupItem201ResponseAllOfData interface.
 */
export function instanceOfCreateStockupItem201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockup" in value;
    isInstance = isInstance && "stockupItem" in value;

    return isInstance;
}

export function CreateStockupItem201ResponseAllOfDataFromJSON(json: any): CreateStockupItem201ResponseAllOfData {
    return CreateStockupItem201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateStockupItem201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockupItem201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockup': StockupFromJSON(json['stockup']),
        'stockupItem': StockupItemFromJSON(json['stockup_item']),
    };
}

export function CreateStockupItem201ResponseAllOfDataToJSON(value?: CreateStockupItem201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockup': StockupToJSON(value.stockup),
        'stockup_item': StockupItemToJSON(value.stockupItem),
    };
}

