import classNames from "classnames";
import Link from "next/link";

import { CheckoutItem } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { CheckoutCancelStatusBadge } from "~/features/checkouts";
import { ProductCardWideRaw, AlternativeProductCardWide } from "~/features/products";

type Props = {
  item: CheckoutItem;
  fixed?: boolean;
};

export const CheckoutItemRow: BFC<Props> = ({
  item,
  fixed,
  className,
}) => {
  const product = fixed && item.hasAlternativeProduct() ? item.getAlternativeProduct() : item.getProduct();
  const mainPrice = fixed && item.hasAlternativeProduct() ? item.alternativePrice : item.price;
  const mainDiscountPrice = fixed && item.hasAlternativeProduct() ? item.alternativeDiscountPrice : item.discountPrice;
  const mainTaxPercentage = fixed && item.hasAlternativeProduct() ? item.alternativeTaxPercentage : item.taxPercentage;

  return (
    <div className={classNames("flex flex-col", className)}>
      <div className="flex flex-col gap-2 p-3">
        <Link href={routes.PRODUCTS_SHOW(product.id)}>
          <ProductCardWideRaw
            key={product.id}
            id={product.id}
            title={product.title}
            titleTop={fixed && item.hasAlternativeProduct() && (
              <div className="text-sm text-black-400 font-bold">{item.product.title}の代替品として</div>
            )}
            image={product.image?.webp?.url ?? product.image?.url}
            price={mainPrice}
            discountPrice={mainDiscountPrice}
            taxPercentage={mainTaxPercentage}
            quantity={item.quantity}
            smallImage
          />
        </Link>
        {item.hasAlternativeProduct() && !fixed && (
          <AlternativeProductCardWide
            product={item.getAlternativeProduct()}
            className="border rounded p-2"
          />
        )}
      </div>
      <CheckoutCancelStatusBadge item={item} />
    </div>
  );
};
