/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DeleteCostcoProductLog200Response,
  GetCostcoProductLog200Response,
  GetCostcoProductLogs200Response,
  GetCostcoProducts200Response,
  PatchCostcoProductLogRequest,
  PostCostcoProductLogsRequest,
  PostCostcoProducts201Response,
  PostCostcoProductsRequest,
  ResponseErrorInvalidRecord,
  ResponseErrorNotFound,
  ResponseErrorUnauthorized,
  SearchCostcoProducts200Response,
  SignInUser201Response,
  SignInUserRequest,
} from '../models';
import {
    DeleteCostcoProductLog200ResponseFromJSON,
    DeleteCostcoProductLog200ResponseToJSON,
    GetCostcoProductLog200ResponseFromJSON,
    GetCostcoProductLog200ResponseToJSON,
    GetCostcoProductLogs200ResponseFromJSON,
    GetCostcoProductLogs200ResponseToJSON,
    GetCostcoProducts200ResponseFromJSON,
    GetCostcoProducts200ResponseToJSON,
    PatchCostcoProductLogRequestFromJSON,
    PatchCostcoProductLogRequestToJSON,
    PostCostcoProductLogsRequestFromJSON,
    PostCostcoProductLogsRequestToJSON,
    PostCostcoProducts201ResponseFromJSON,
    PostCostcoProducts201ResponseToJSON,
    PostCostcoProductsRequestFromJSON,
    PostCostcoProductsRequestToJSON,
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordToJSON,
    ResponseErrorNotFoundFromJSON,
    ResponseErrorNotFoundToJSON,
    ResponseErrorUnauthorizedFromJSON,
    ResponseErrorUnauthorizedToJSON,
    SearchCostcoProducts200ResponseFromJSON,
    SearchCostcoProducts200ResponseToJSON,
    SignInUser201ResponseFromJSON,
    SignInUser201ResponseToJSON,
    SignInUserRequestFromJSON,
    SignInUserRequestToJSON,
} from '../models';

export interface DeleteCostcoProductLogRequest {
    id: string;
    logId: string;
}

export interface GetCostcoProductRequest {
    id: string;
}

export interface GetCostcoProductLogRequest {
    id: string;
    logId: string;
}

export interface GetCostcoProductLogsRequest {
    id: string;
}

export interface PatchCostcoProductRequest {
    id: string;
    postCostcoProductsRequest?: PostCostcoProductsRequest;
}

export interface PatchCostcoProductLogOperationRequest {
    id: string;
    logId: string;
    patchCostcoProductLogRequest?: PatchCostcoProductLogRequest;
}

export interface PostCostcoProductLogsOperationRequest {
    id: string;
    postCostcoProductLogsRequest?: PostCostcoProductLogsRequest;
}

export interface PostCostcoProductsOperationRequest {
    postCostcoProductsRequest?: PostCostcoProductsRequest;
}

export interface SearchCostcoProductsRequest {
    sku?: string;
}

export interface SignInUserOperationRequest {
    signInUserRequest?: SignInUserRequest;
}

/**
 * 
 */
export class SurveyApi extends runtime.BaseAPI {

    /**
     * 
     * delete costco_product_log
     */
    async deleteCostcoProductLogRaw(requestParameters: DeleteCostcoProductLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteCostcoProductLog200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCostcoProductLog.');
        }

        if (requestParameters.logId === null || requestParameters.logId === undefined) {
            throw new runtime.RequiredError('logId','Required parameter requestParameters.logId was null or undefined when calling deleteCostcoProductLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}/logs/{log_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"log_id"}}`, encodeURIComponent(String(requestParameters.logId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteCostcoProductLog200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     * delete costco_product_log
     */
    async deleteCostcoProductLog(id: string, logId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteCostcoProductLog200Response> {
        const response = await this.deleteCostcoProductLogRaw({ id: id, logId: logId }, initOverrides);
        return await response.value();
    }

    /**
     * get costco product
     */
    async getCostcoProductRaw(requestParameters: GetCostcoProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostCostcoProducts201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCostcoProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/costco_products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCostcoProducts201ResponseFromJSON(jsonValue));
    }

    /**
     * get costco product
     */
    async getCostcoProduct(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostCostcoProducts201Response> {
        const response = await this.getCostcoProductRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * get costco_product_log
     */
    async getCostcoProductLogRaw(requestParameters: GetCostcoProductLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProductLog200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCostcoProductLog.');
        }

        if (requestParameters.logId === null || requestParameters.logId === undefined) {
            throw new runtime.RequiredError('logId','Required parameter requestParameters.logId was null or undefined when calling getCostcoProductLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}/logs/{log_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"log_id"}}`, encodeURIComponent(String(requestParameters.logId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProductLog200ResponseFromJSON(jsonValue));
    }

    /**
     * get costco_product_log
     */
    async getCostcoProductLog(id: string, logId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProductLog200Response> {
        const response = await this.getCostcoProductLogRaw({ id: id, logId: logId }, initOverrides);
        return await response.value();
    }

    /**
     * 商品ログの一覧を取得
     * get costco products
     */
    async getCostcoProductLogsRaw(requestParameters: GetCostcoProductLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProductLogs200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCostcoProductLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}/logs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProductLogs200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品ログの一覧を取得
     * get costco products
     */
    async getCostcoProductLogs(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProductLogs200Response> {
        const response = await this.getCostcoProductLogsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * get costco products
     */
    async getCostcoProductsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProducts200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/costco_products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * get costco products
     */
    async getCostcoProducts(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProducts200Response> {
        const response = await this.getCostcoProductsRaw(initOverrides);
        return await response.value();
    }

    /**
     * update costco product
     */
    async patchCostcoProductRaw(requestParameters: PatchCostcoProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostCostcoProducts201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchCostcoProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/costco_products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PostCostcoProductsRequestToJSON(requestParameters.postCostcoProductsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCostcoProducts201ResponseFromJSON(jsonValue));
    }

    /**
     * update costco product
     */
    async patchCostcoProduct(id: string, postCostcoProductsRequest?: PostCostcoProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostCostcoProducts201Response> {
        const response = await this.patchCostcoProductRaw({ id: id, postCostcoProductsRequest: postCostcoProductsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * update costco_product_log
     */
    async patchCostcoProductLogRaw(requestParameters: PatchCostcoProductLogOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProductLog200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchCostcoProductLog.');
        }

        if (requestParameters.logId === null || requestParameters.logId === undefined) {
            throw new runtime.RequiredError('logId','Required parameter requestParameters.logId was null or undefined when calling patchCostcoProductLog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}/logs/{log_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"log_id"}}`, encodeURIComponent(String(requestParameters.logId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchCostcoProductLogRequestToJSON(requestParameters.patchCostcoProductLogRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProductLog200ResponseFromJSON(jsonValue));
    }

    /**
     * update costco_product_log
     */
    async patchCostcoProductLog(id: string, logId: string, patchCostcoProductLogRequest?: PatchCostcoProductLogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProductLog200Response> {
        const response = await this.patchCostcoProductLogRaw({ id: id, logId: logId, patchCostcoProductLogRequest: patchCostcoProductLogRequest }, initOverrides);
        return await response.value();
    }

    /**
     * create costco product log
     */
    async postCostcoProductLogsRaw(requestParameters: PostCostcoProductLogsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoProductLogs200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postCostcoProductLogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/costco_products/{id}/logs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCostcoProductLogsRequestToJSON(requestParameters.postCostcoProductLogsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoProductLogs200ResponseFromJSON(jsonValue));
    }

    /**
     * create costco product log
     */
    async postCostcoProductLogs(id: string, postCostcoProductLogsRequest?: PostCostcoProductLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoProductLogs200Response> {
        const response = await this.postCostcoProductLogsRaw({ id: id, postCostcoProductLogsRequest: postCostcoProductLogsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * create costco product
     */
    async postCostcoProductsRaw(requestParameters: PostCostcoProductsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostCostcoProducts201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/costco_products`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCostcoProductsRequestToJSON(requestParameters.postCostcoProductsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostCostcoProducts201ResponseFromJSON(jsonValue));
    }

    /**
     * create costco product
     */
    async postCostcoProducts(postCostcoProductsRequest?: PostCostcoProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostCostcoProducts201Response> {
        const response = await this.postCostcoProductsRaw({ postCostcoProductsRequest: postCostcoProductsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * search costco_products
     */
    async searchCostcoProductsRaw(requestParameters: SearchCostcoProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchCostcoProducts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.sku !== undefined) {
            queryParameters['sku'] = requestParameters.sku;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/costco_products/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchCostcoProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * search costco_products
     */
    async searchCostcoProducts(sku?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchCostcoProducts200Response> {
        const response = await this.searchCostcoProductsRaw({ sku: sku }, initOverrides);
        return await response.value();
    }

    /**
     * ログインする
     * sign in user
     */
    async signInUserRaw(requestParameters: SignInUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignInUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInUserRequestToJSON(requestParameters.signInUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignInUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ログインする
     * sign in user
     */
    async signInUser(signInUserRequest?: SignInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignInUser201Response> {
        const response = await this.signInUserRaw({ signInUserRequest: signInUserRequest }, initOverrides);
        return await response.value();
    }

}
