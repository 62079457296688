import classNames from "classnames";
import { useCallback, useState } from "react";
import { Button } from "~/../../packages/shared/components";

import { CFC } from "shared/types";

type Props = {
  minHeight?: number;
  label?: string;
};

export const ReadMore: CFC<Props> = ({
  minHeight = 150,
  label = "すべて見る",
  children,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  return (
    <div className="relative">
      <div
        className={classNames({
          "overflow-hidden": !isExpanded,
        })}
        style={{
          maxHeight: isExpanded ? "none" : minHeight,
        }}
      >
        {children}
      </div>
      {!isExpanded && (
        <>
          <div
            className="absolute inset-0"
            style={{
              background: "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
            }}
          />
          <div
            onClick={handleToggle}
            className="absolute bottom-0 text-center w-full"
          >
            <Button>
              {label}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
