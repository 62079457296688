/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckoutRequestRemoveItemOperationsInner
 */
export interface UpdateCheckoutRequestRemoveItemOperationsInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestRemoveItemOperationsInner
     */
    operation: UpdateCheckoutRequestRemoveItemOperationsInnerOperationEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutRequestRemoveItemOperationsInner
     */
    checkoutItemId: string;
}


/**
 * @export
 */
export const UpdateCheckoutRequestRemoveItemOperationsInnerOperationEnum = {
    RemoveItem: 'remove_item'
} as const;
export type UpdateCheckoutRequestRemoveItemOperationsInnerOperationEnum = typeof UpdateCheckoutRequestRemoveItemOperationsInnerOperationEnum[keyof typeof UpdateCheckoutRequestRemoveItemOperationsInnerOperationEnum];


/**
 * Check if a given object implements the UpdateCheckoutRequestRemoveItemOperationsInner interface.
 */
export function instanceOfUpdateCheckoutRequestRemoveItemOperationsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "operation" in value;
    isInstance = isInstance && "checkoutItemId" in value;

    return isInstance;
}

export function UpdateCheckoutRequestRemoveItemOperationsInnerFromJSON(json: any): UpdateCheckoutRequestRemoveItemOperationsInner {
    return UpdateCheckoutRequestRemoveItemOperationsInnerFromJSONTyped(json, false);
}

export function UpdateCheckoutRequestRemoveItemOperationsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutRequestRemoveItemOperationsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'checkoutItemId': json['checkout_item_id'],
    };
}

export function UpdateCheckoutRequestRemoveItemOperationsInnerToJSON(value?: UpdateCheckoutRequestRemoveItemOperationsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'operation': value.operation,
        'checkout_item_id': value.checkoutItemId,
    };
}

