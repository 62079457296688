/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignUpUser201ResponseAllOfData } from './SignUpUser201ResponseAllOfData';
import {
    SignUpUser201ResponseAllOfDataFromJSON,
    SignUpUser201ResponseAllOfDataFromJSONTyped,
    SignUpUser201ResponseAllOfDataToJSON,
} from './SignUpUser201ResponseAllOfData';

/**
 * 
 * @export
 * @interface SignUpUser201Response
 */
export interface SignUpUser201Response {
    /**
     * 
     * @type {SignUpUser201ResponseAllOfData}
     * @memberof SignUpUser201Response
     */
    data: SignUpUser201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof SignUpUser201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the SignUpUser201Response interface.
 */
export function instanceOfSignUpUser201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function SignUpUser201ResponseFromJSON(json: any): SignUpUser201Response {
    return SignUpUser201ResponseFromJSONTyped(json, false);
}

export function SignUpUser201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUser201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SignUpUser201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function SignUpUser201ResponseToJSON(value?: SignUpUser201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SignUpUser201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

