import { differenceInMinutes } from "date-fns";
import { useMemo } from "react";

import { Link } from "shared/components";
import { formatDate } from "shared/helpers";
import { twClassNames } from "shared/lib/tailwind";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useIsDeliverableAddress } from "~/features/addresses/hooks";
import { useAddresses } from "~/features/users/hooks/useAddresses";

export const FastestDeliverableLine: BFC = ({
  className,
}) => {
  const { defaultAddress, isLoading } = useAddresses();
  const {
    isDeliverable,
    fastestShippingDate,
    fastestShippingTime,
    isLoading: isDeliverableLoading,
  } = useIsDeliverableAddress(defaultAddress?.zipCode);

  // 最速配達日の前日15時までに注文した場合、翌日配送可能
  const untilDate = useMemo(() => {
    if (!fastestShippingDate) return null;

    const date = new Date(fastestShippingDate);
    date.setDate(date.getDate() - 1);
    date.setHours(15, 0, 0, 0);
    return date;
  }, [fastestShippingDate]);

  if (isLoading || isDeliverableLoading) {
    return null;
  }

  if (!defaultAddress) {
    return (
      <Link href={routes.MYPAGE_ADDRESSES_NEW} className={twClassNames("block text-center py-1 text-xs bg-yellow-400", className)}>
        お届け先を登録して最短の配送可能日時を確認する✍️
      </Link>
    );
  }

  if (!isDeliverable || !fastestShippingDate || !fastestShippingTime || !untilDate) {
    return null;
  }

  const diff = differenceInMinutes(untilDate, new Date());
  const diffHours = Math.floor(diff / 60);
  const diffMinutes = diff % 60;

  return (
    <div className={twClassNames("text-center py-1 text-xs bg-lime-500 text-white", className)}>
      {diffHours < 24 ? (
        <>
          {diffHours > 0 && `${diffHours}時間`}
          {diffMinutes}分以内のご注文で
        </>
      ) : (
        "今ご注文いただくと"
      )}
      最短{formatDate(fastestShippingDate, "MM月dd日")} {fastestShippingTime} にお届け可能です🚚
    </div>
  );
};
