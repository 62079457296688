/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorConflictAllOfError } from './ResponseErrorConflictAllOfError';
import {
    ResponseErrorConflictAllOfErrorFromJSON,
    ResponseErrorConflictAllOfErrorFromJSONTyped,
    ResponseErrorConflictAllOfErrorToJSON,
} from './ResponseErrorConflictAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorConflictAllOf
 */
export interface ResponseErrorConflictAllOf {
    /**
     * 
     * @type {ResponseErrorConflictAllOfError}
     * @memberof ResponseErrorConflictAllOf
     */
    error: ResponseErrorConflictAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorConflictAllOf interface.
 */
export function instanceOfResponseErrorConflictAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorConflictAllOfFromJSON(json: any): ResponseErrorConflictAllOf {
    return ResponseErrorConflictAllOfFromJSONTyped(json, false);
}

export function ResponseErrorConflictAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorConflictAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorConflictAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorConflictAllOfToJSON(value?: ResponseErrorConflictAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorConflictAllOfErrorToJSON(value.error),
    };
}

