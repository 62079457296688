/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStocks201ResponseAllOfData } from './CreateStocks201ResponseAllOfData';
import {
    CreateStocks201ResponseAllOfDataFromJSON,
    CreateStocks201ResponseAllOfDataFromJSONTyped,
    CreateStocks201ResponseAllOfDataToJSON,
} from './CreateStocks201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateStocks201ResponseAllOf
 */
export interface CreateStocks201ResponseAllOf {
    /**
     * 
     * @type {CreateStocks201ResponseAllOfData}
     * @memberof CreateStocks201ResponseAllOf
     */
    data?: CreateStocks201ResponseAllOfData;
}

/**
 * Check if a given object implements the CreateStocks201ResponseAllOf interface.
 */
export function instanceOfCreateStocks201ResponseAllOf(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateStocks201ResponseAllOfFromJSON(json: any): CreateStocks201ResponseAllOf {
    return CreateStocks201ResponseAllOfFromJSONTyped(json, false);
}

export function CreateStocks201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStocks201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : CreateStocks201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CreateStocks201ResponseAllOfToJSON(value?: CreateStocks201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateStocks201ResponseAllOfDataToJSON(value.data),
    };
}

