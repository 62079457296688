/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetProductShippingRates200ResponseAllOfData } from './GetProductShippingRates200ResponseAllOfData';
import {
    GetProductShippingRates200ResponseAllOfDataFromJSON,
    GetProductShippingRates200ResponseAllOfDataFromJSONTyped,
    GetProductShippingRates200ResponseAllOfDataToJSON,
} from './GetProductShippingRates200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetProductShippingRates200Response
 */
export interface GetProductShippingRates200Response {
    /**
     * 
     * @type {GetProductShippingRates200ResponseAllOfData}
     * @memberof GetProductShippingRates200Response
     */
    data: GetProductShippingRates200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetProductShippingRates200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetProductShippingRates200Response interface.
 */
export function instanceOfGetProductShippingRates200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetProductShippingRates200ResponseFromJSON(json: any): GetProductShippingRates200Response {
    return GetProductShippingRates200ResponseFromJSONTyped(json, false);
}

export function GetProductShippingRates200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductShippingRates200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetProductShippingRates200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetProductShippingRates200ResponseToJSON(value?: GetProductShippingRates200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetProductShippingRates200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

