/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDirectUpload200ResponseDirectUpload } from './CreateDirectUpload200ResponseDirectUpload';
import {
    CreateDirectUpload200ResponseDirectUploadFromJSON,
    CreateDirectUpload200ResponseDirectUploadFromJSONTyped,
    CreateDirectUpload200ResponseDirectUploadToJSON,
} from './CreateDirectUpload200ResponseDirectUpload';

/**
 * 
 * @export
 * @interface CreateDirectUpload200Response
 */
export interface CreateDirectUpload200Response {
    /**
     * 
     * @type {number}
     * @memberof CreateDirectUpload200Response
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    contentType: string;
    /**
     * 
     * @type {object}
     * @memberof CreateDirectUpload200Response
     */
    metadata: object;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    serviceName: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDirectUpload200Response
     */
    byteSize: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    checksum: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUpload200Response
     */
    signedId: string;
    /**
     * 
     * @type {CreateDirectUpload200ResponseDirectUpload}
     * @memberof CreateDirectUpload200Response
     */
    directUpload: CreateDirectUpload200ResponseDirectUpload;
}

/**
 * Check if a given object implements the CreateDirectUpload200Response interface.
 */
export function instanceOfCreateDirectUpload200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "serviceName" in value;
    isInstance = isInstance && "byteSize" in value;
    isInstance = isInstance && "checksum" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "signedId" in value;
    isInstance = isInstance && "directUpload" in value;

    return isInstance;
}

export function CreateDirectUpload200ResponseFromJSON(json: any): CreateDirectUpload200Response {
    return CreateDirectUpload200ResponseFromJSONTyped(json, false);
}

export function CreateDirectUpload200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDirectUpload200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'key': json['key'],
        'filename': json['filename'],
        'contentType': json['content_type'],
        'metadata': json['metadata'],
        'serviceName': json['service_name'],
        'byteSize': json['byte_size'],
        'checksum': json['checksum'],
        'createdAt': json['created_at'],
        'signedId': json['signed_id'],
        'directUpload': CreateDirectUpload200ResponseDirectUploadFromJSON(json['direct_upload']),
    };
}

export function CreateDirectUpload200ResponseToJSON(value?: CreateDirectUpload200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'filename': value.filename,
        'content_type': value.contentType,
        'metadata': value.metadata,
        'service_name': value.serviceName,
        'byte_size': value.byteSize,
        'checksum': value.checksum,
        'created_at': value.createdAt,
        'signed_id': value.signedId,
        'direct_upload': CreateDirectUpload200ResponseDirectUploadToJSON(value.directUpload),
    };
}

