/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RemoveCartItemRequestItem } from './RemoveCartItemRequestItem';
import {
    RemoveCartItemRequestItemFromJSON,
    RemoveCartItemRequestItemFromJSONTyped,
    RemoveCartItemRequestItemToJSON,
} from './RemoveCartItemRequestItem';

/**
 * 
 * @export
 * @interface RemoveCartItemRequest
 */
export interface RemoveCartItemRequest {
    /**
     * 
     * @type {RemoveCartItemRequestItem}
     * @memberof RemoveCartItemRequest
     */
    item: RemoveCartItemRequestItem;
}

/**
 * Check if a given object implements the RemoveCartItemRequest interface.
 */
export function instanceOfRemoveCartItemRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "item" in value;

    return isInstance;
}

export function RemoveCartItemRequestFromJSON(json: any): RemoveCartItemRequest {
    return RemoveCartItemRequestFromJSONTyped(json, false);
}

export function RemoveCartItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveCartItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': RemoveCartItemRequestItemFromJSON(json['item']),
    };
}

export function RemoveCartItemRequestToJSON(value?: RemoveCartItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': RemoveCartItemRequestItemToJSON(value.item),
    };
}

