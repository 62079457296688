/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPreRegistrationsRequestPreRegistration
 */
export interface PostPreRegistrationsRequestPreRegistration {
    /**
     * 
     * @type {string}
     * @memberof PostPreRegistrationsRequestPreRegistration
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PostPreRegistrationsRequestPreRegistration
     */
    prefectureId: string;
    /**
     * 
     * @type {string}
     * @memberof PostPreRegistrationsRequestPreRegistration
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PostPreRegistrationsRequestPreRegistration
     */
    familyStructure?: PostPreRegistrationsRequestPreRegistrationFamilyStructureEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostPreRegistrationsRequestPreRegistration
     */
    useScenes?: Array<PostPreRegistrationsRequestPreRegistrationUseScenesEnum>;
}


/**
 * @export
 */
export const PostPreRegistrationsRequestPreRegistrationFamilyStructureEnum = {
    Single: 'single',
    Couple: 'couple',
    Family: 'family',
    Multiple: 'multiple',
    None: 'none'
} as const;
export type PostPreRegistrationsRequestPreRegistrationFamilyStructureEnum = typeof PostPreRegistrationsRequestPreRegistrationFamilyStructureEnum[keyof typeof PostPreRegistrationsRequestPreRegistrationFamilyStructureEnum];

/**
 * @export
 */
export const PostPreRegistrationsRequestPreRegistrationUseScenesEnum = {
    Meal: 'meal',
    Anniversary: 'anniversary',
    Party: 'party',
    Bbq: 'bbq',
    Office: 'office',
    Business: 'business',
    Gift: 'gift'
} as const;
export type PostPreRegistrationsRequestPreRegistrationUseScenesEnum = typeof PostPreRegistrationsRequestPreRegistrationUseScenesEnum[keyof typeof PostPreRegistrationsRequestPreRegistrationUseScenesEnum];


/**
 * Check if a given object implements the PostPreRegistrationsRequestPreRegistration interface.
 */
export function instanceOfPostPreRegistrationsRequestPreRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "prefectureId" in value;
    isInstance = isInstance && "city" in value;

    return isInstance;
}

export function PostPreRegistrationsRequestPreRegistrationFromJSON(json: any): PostPreRegistrationsRequestPreRegistration {
    return PostPreRegistrationsRequestPreRegistrationFromJSONTyped(json, false);
}

export function PostPreRegistrationsRequestPreRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPreRegistrationsRequestPreRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'prefectureId': json['prefecture_id'],
        'city': json['city'],
        'familyStructure': !exists(json, 'family_structure') ? undefined : json['family_structure'],
        'useScenes': !exists(json, 'use_scenes') ? undefined : json['use_scenes'],
    };
}

export function PostPreRegistrationsRequestPreRegistrationToJSON(value?: PostPreRegistrationsRequestPreRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'prefecture_id': value.prefectureId,
        'city': value.city,
        'family_structure': value.familyStructure,
        'use_scenes': value.useScenes,
    };
}

