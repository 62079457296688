/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendConfirmUserEmailRequest
 */
export interface SendConfirmUserEmailRequest {
    /**
     * 
     * @type {string}
     * @memberof SendConfirmUserEmailRequest
     */
    returnTo?: string;
}

/**
 * Check if a given object implements the SendConfirmUserEmailRequest interface.
 */
export function instanceOfSendConfirmUserEmailRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SendConfirmUserEmailRequestFromJSON(json: any): SendConfirmUserEmailRequest {
    return SendConfirmUserEmailRequestFromJSONTyped(json, false);
}

export function SendConfirmUserEmailRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendConfirmUserEmailRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'returnTo': !exists(json, 'return_to') ? undefined : json['return_to'],
    };
}

export function SendConfirmUserEmailRequestToJSON(value?: SendConfirmUserEmailRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'return_to': value.returnTo,
    };
}

