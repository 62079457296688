/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddFavorite201ResponseAllOfData } from './AddFavorite201ResponseAllOfData';
import {
    AddFavorite201ResponseAllOfDataFromJSON,
    AddFavorite201ResponseAllOfDataFromJSONTyped,
    AddFavorite201ResponseAllOfDataToJSON,
} from './AddFavorite201ResponseAllOfData';

/**
 * 
 * @export
 * @interface AddFavorite201Response
 */
export interface AddFavorite201Response {
    /**
     * 
     * @type {AddFavorite201ResponseAllOfData}
     * @memberof AddFavorite201Response
     */
    data: AddFavorite201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof AddFavorite201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the AddFavorite201Response interface.
 */
export function instanceOfAddFavorite201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function AddFavorite201ResponseFromJSON(json: any): AddFavorite201Response {
    return AddFavorite201ResponseFromJSONTyped(json, false);
}

export function AddFavorite201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFavorite201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AddFavorite201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function AddFavorite201ResponseToJSON(value?: AddFavorite201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AddFavorite201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

