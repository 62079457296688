/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PreRegistration } from './PreRegistration';
import {
    PreRegistrationFromJSON,
    PreRegistrationFromJSONTyped,
    PreRegistrationToJSON,
} from './PreRegistration';

/**
 * 
 * @export
 * @interface PostPreRegistrations201ResponseAllOfData
 */
export interface PostPreRegistrations201ResponseAllOfData {
    /**
     * 
     * @type {PreRegistration}
     * @memberof PostPreRegistrations201ResponseAllOfData
     */
    preRegistration?: PreRegistration;
}

/**
 * Check if a given object implements the PostPreRegistrations201ResponseAllOfData interface.
 */
export function instanceOfPostPreRegistrations201ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostPreRegistrations201ResponseAllOfDataFromJSON(json: any): PostPreRegistrations201ResponseAllOfData {
    return PostPreRegistrations201ResponseAllOfDataFromJSONTyped(json, false);
}

export function PostPreRegistrations201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPreRegistrations201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'preRegistration': !exists(json, 'pre_registration') ? undefined : PreRegistrationFromJSON(json['pre_registration']),
    };
}

export function PostPreRegistrations201ResponseAllOfDataToJSON(value?: PostPreRegistrations201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pre_registration': PreRegistrationToJSON(value.preRegistration),
    };
}

