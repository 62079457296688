import { useMutation, useQuery } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import { createAtom } from "shared/lib/recoil";
import { PaymentMethod } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { CreateUserPaymentMethodRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";

const paymentMethodsState = createAtom<PaymentMethod[]>([]);
const defaultPaymentMethodState = createAtom<PaymentMethod | null>(null);

export const usePaymentMethods = () => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });
  const [paymentMethods, setPaymentMethods] = useRecoilState(paymentMethodsState);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useRecoilState(defaultPaymentMethodState);

  const { refetch, isLoading } = useQuery(["users/usePaymentMethods"], () => api.getUserPaymentMethods(), {
    onSuccess: ({ data }) => {
      setPaymentMethods(data.paymentMethods.map((pm) => new PaymentMethod(pm)));
      setDefaultPaymentMethod(data.defaultPaymentMethod ? new PaymentMethod(data.defaultPaymentMethod) : null);
    },
  });

  const { mutateAsync: create, isLoading: isCreateLoading } = useMutation(
    ["users/usePaymentMethods/create"],
    (params: CreateUserPaymentMethodRequest) => api.createUserPaymentMethod(params),
    {
      onSuccess: () => {
        refetch();
      }
    },
  );

  const { mutate: destroy } = useMutation(
    (id: string) => api.deleteUserPaymentMethod(id),
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  return {
    paymentMethods,
    defaultPaymentMethod,
    create,
    destroy,
    isLoading: isLoading || isCreateLoading,
    isMutating: isCreateLoading,
  };
};
