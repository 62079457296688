import { immerable } from "immer";

import * as api from "../services/api/store/models";
import { CartItem, CartItemType } from "./cart_item";

export type CartType = api.Cart;

export class Cart implements CartType {
  [immerable] = true;

  id = "";
  items: CartItemType[] = [];
  shopifyCartId?: string | undefined;
  associated = false;

  constructor(data: Partial<CartType> = {}) {
    Object.assign(this, data);
  }

  getItems() {
    return this.items.map((item) => new CartItem(item));
  }

  get totalQuantity() {
    return this.items.reduce((acc, item) => acc + item.quantity, 0);
  }
}

export const convertShopifyCartGID = (gid: string) => {
  return gid.replace("gid://shopify/Cart/", "");
};

export const convertToShopifyCartGID = (id: string) => {
  return `gid://shopify/Cart/${id}`;
};
