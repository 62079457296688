import { QueryClientProvider } from "@tanstack/react-query";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";

import { Ogp } from "shared/features/utils";
import { NextPageWithLayout } from "shared/types";

import { App } from "~/components/layouts/App";
import { AuthProvider } from "~/features/auth";
import { CartProvider } from "~/features/carts";
import { GoogleTagManagerScript } from "~/lib/google_tag_manager";
import { queryClient } from "~/lib/react-query";

import "react-medium-image-zoom/dist/styles.css";
import "shared/features/i18n/config";
import "./styles.css";

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const NextApp = ({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => <>{page}</>);

  return (
    <SessionProvider session={session}>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <App>
            <Ogp />
            <AuthProvider>
              <CartProvider>
                <Toaster position="top-center" />
                <GoogleTagManagerScript />
                {getLayout(<Component {...pageProps} />, pageProps)}
              </CartProvider>
            </AuthProvider>
          </App>
        </QueryClientProvider>
      </RecoilRoot>
    </SessionProvider>
  );
};

export default NextApp;
