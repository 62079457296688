import { MagazineArticle } from "shared/models";

import { StrapiChildModel, StrapiChildrenModel } from "./request";

export type StrapiArticle = {
  id: number;
  attributes: {
    body: string;
    title: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
    publishAt?: string;
    publishedAt: string;
    categories: StrapiChildrenModel<StrapiCategory>;
    tags: StrapiChildrenModel<StrapiTag>;
    cover?: {
      data: StrapiArticleImage;
    };
  };
};

export type StrapiImageFileAttributes = {
  url: string;
  mime: string;
  size: string;
  height: number;
  width: number;
  alt: string;
};

export type StrapiArticleImage = {
  id: number;
  attributes: StrapiImageFileAttributes;
};

export type StrapiCategory = {
  id: number;
  attributes: {
    name: string;
    slug: string;
  };
};

export type StrapiTag = {
  id: number;
  attributes: {
    name: string;
    slug: string;
  };
};

export type StrapiArticleBody = {
  time: number;
  blocks: StrapiArticleBlock[];
  version: string;
};

export type StrapiArticleBlock =
  | StrapiArticleBlockParagraph
  | StrapiArticleBlockHeader
  | StrapiArticleBlockImage
  | StrapiArticleBlockInlineLink
  | StrapiArticleBlockInlineLinkButton
  | StrapiArticleBlockList
  | StrapiArticleBlockCheckList
  | StrapiArticleBlockTable
  | StrapiArticleBlockProductLink
  | StrapiArticleBlockQuote
  | StrapiArticleBlockCode
  | StrapiArticleBlockWarning
  | StrapiArticleBlockDelimiter
  | StrapiArticleBlockRawHTML
  | StrapiArticleBlockEmbed;

export type StrapiArticleBlockParagraph = {
  id: string;
  type: "paragraph";
  data: {
    text: string;
  };
};

export type StrapiArticleBlockHeader = {
  id: string;
  type: "header";
  data: {
    text: string;
    level: number;
  };
};

export type StrapiArticleImageData = {
  file: StrapiImageFileAttributes;
  caption: string;
  withBackground: boolean;
  withBorder: boolean;
  stretched: boolean;
};

export type StrapiArticleBlockImage = {
  id: string;
  type: "image";
  data: StrapiArticleImageData;
};

export type StrapiArticleBlockInlineLink = {
  id: string;
  type: "InlineLink";
  data: {
    linkComment: string;
    linkContentText: string;
    linkContentImage: StrapiArticleImageData | undefined;
    linkTitle: string;
    linkURL: string;
  };
};

export type StrapiArticleBlockInlineLinkButton = {
  id: string;
  type: "InlineLinkButton";
  data: {
    linkURL: string;
    text: string;
  };
};

export type StrapiArticleBlockList = {
  id: string;
  type: "list";
  data: {
    items: string[];
    style: "ordered" | "unordered";
  };
};

export type StrapiArticleBlockCheckList = {
  id: string;
  type: "checklist";
  data: {
    items: Array<{ text: string; checked: boolean }>;
  };
};

export type StrapiArticleBlockTable = {
  id: string;
  type: "table";
  data: {
    content: string[][];
    withHeadings: boolean;
  };
};

export type StrapiArticleBlockProductLink = {
  id: string;
  type: "productLink";
  data: {
    id: string;
  };
};

export type StrapiArticleBlockQuote = {
  id: string;
  type: "quote";
  data: {
    text: string;
    alignment: "left" | "center" | "right";
    caption: string;
  };
};

export type StrapiArticleBlockCode = {
  id: string;
  type: "code";
  data: {
    code: string;
  };
};

export type StrapiArticleBlockWarning = {
  id: string;
  type: "warning";
  data: {
    title: string;
    message: string;
  };
};

export type StrapiArticleBlockDelimiter = {
  id: string;
  type: "delimiter";
  data: {[key: string]: never};
};

export type StrapiArticleBlockRawHTML = {
  id: string;
  type: "raw";
  data: {
    html: string;
  };
};

export type StrapiArticleBlockEmbed = {
  id: string;
  type: "embed";
  data: {
    service: string;
    source: string;
    embed: string;
    width: number;
    height: number;
    caption: string;
  };
};

export type StrapiPickupArticle = {
  id: number;
  attributes: {
    article: StrapiChildModel<StrapiArticle>;
  };
};

export function convertStrapiArticleToMagazineArticle(data: StrapiArticle): MagazineArticle {
  const { id, attributes } = data;
  const { title, slug, body } = attributes;
  // const { id: imageId, attributes: imageAttrs } = article_image?.data || {};

  return new MagazineArticle({
    id,
    title,
    slug,
    body,
  });
}
