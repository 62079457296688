/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDeliveryParcelRequestDeliveryParcel
 */
export interface UpdateDeliveryParcelRequestDeliveryParcel {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryParcelRequestDeliveryParcel
     */
    deliveryDriverId?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryParcelRequestDeliveryParcel
     */
    deliveryOrder?: number;
}

/**
 * Check if a given object implements the UpdateDeliveryParcelRequestDeliveryParcel interface.
 */
export function instanceOfUpdateDeliveryParcelRequestDeliveryParcel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDeliveryParcelRequestDeliveryParcelFromJSON(json: any): UpdateDeliveryParcelRequestDeliveryParcel {
    return UpdateDeliveryParcelRequestDeliveryParcelFromJSONTyped(json, false);
}

export function UpdateDeliveryParcelRequestDeliveryParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeliveryParcelRequestDeliveryParcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryDriverId': !exists(json, 'delivery_driver_id') ? undefined : json['delivery_driver_id'],
        'deliveryOrder': !exists(json, 'delivery_order') ? undefined : json['delivery_order'],
    };
}

export function UpdateDeliveryParcelRequestDeliveryParcelToJSON(value?: UpdateDeliveryParcelRequestDeliveryParcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_driver_id': value.deliveryDriverId,
        'delivery_order': value.deliveryOrder,
    };
}

