/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CheckoutSummary
 */
export interface CheckoutSummary {
    /**
     * 
     * @type {string}
     * @memberof CheckoutSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSummary
     */
    serialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    subtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    serviceCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    smallCheckoutCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    totalShippingRate: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    discountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutSummary
     */
    totalPrice: number;
    /**
     * 
     * @type {User}
     * @memberof CheckoutSummary
     */
    user: User;
    /**
     * 
     * @type {Address}
     * @memberof CheckoutSummary
     */
    shippingAddress: Address;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSummary
     */
    stockingStatus: CheckoutSummaryStockingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSummary
     */
    shippingStatus: CheckoutSummaryShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckoutSummary
     */
    status: CheckoutSummaryStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof CheckoutSummary
     */
    checkedOutAt: Date;
}


/**
 * @export
 */
export const CheckoutSummaryStockingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutSummaryStockingStatusEnum = typeof CheckoutSummaryStockingStatusEnum[keyof typeof CheckoutSummaryStockingStatusEnum];

/**
 * @export
 */
export const CheckoutSummaryShippingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutSummaryShippingStatusEnum = typeof CheckoutSummaryShippingStatusEnum[keyof typeof CheckoutSummaryShippingStatusEnum];

/**
 * @export
 */
export const CheckoutSummaryStatusEnum = {
    Waiting: 'waiting',
    Paid: 'paid',
    Completed: 'completed',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type CheckoutSummaryStatusEnum = typeof CheckoutSummaryStatusEnum[keyof typeof CheckoutSummaryStatusEnum];


/**
 * Check if a given object implements the CheckoutSummary interface.
 */
export function instanceOfCheckoutSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "subtotalPrice" in value;
    isInstance = isInstance && "serviceCommission" in value;
    isInstance = isInstance && "smallCheckoutCommission" in value;
    isInstance = isInstance && "totalShippingRate" in value;
    isInstance = isInstance && "totalTax" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "stockingStatus" in value;
    isInstance = isInstance && "shippingStatus" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "checkedOutAt" in value;

    return isInstance;
}

export function CheckoutSummaryFromJSON(json: any): CheckoutSummary {
    return CheckoutSummaryFromJSONTyped(json, false);
}

export function CheckoutSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serialNumber': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'subtotalPrice': json['subtotal_price'],
        'serviceCommission': json['service_commission'],
        'smallCheckoutCommission': json['small_checkout_commission'],
        'totalShippingRate': json['total_shipping_rate'],
        'totalTax': json['total_tax'],
        'discountPrice': json['discount_price'],
        'totalPrice': json['total_price'],
        'user': UserFromJSON(json['user']),
        'shippingAddress': AddressFromJSON(json['shipping_address']),
        'stockingStatus': json['stocking_status'],
        'shippingStatus': json['shipping_status'],
        'status': json['status'],
        'checkedOutAt': (new Date(json['checked_out_at'])),
    };
}

export function CheckoutSummaryToJSON(value?: CheckoutSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serial_number': value.serialNumber,
        'subtotal_price': value.subtotalPrice,
        'service_commission': value.serviceCommission,
        'small_checkout_commission': value.smallCheckoutCommission,
        'total_shipping_rate': value.totalShippingRate,
        'total_tax': value.totalTax,
        'discount_price': value.discountPrice,
        'total_price': value.totalPrice,
        'user': UserToJSON(value.user),
        'shipping_address': AddressToJSON(value.shippingAddress),
        'stocking_status': value.stockingStatus,
        'shipping_status': value.shippingStatus,
        'status': value.status,
        'checked_out_at': (value.checkedOutAt.toISOString()),
    };
}

