import classNames from "classnames";
import { useCallback } from "react";

import { TagFamilyTag } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  categories: TagFamilyTag[];
  selectedCategory?: TagFamilyTag | null;
  onSelect?: (category: TagFamilyTag) => void;
};

export const ProductSubCategorySelector: BFC<Props> = ({
  categories,
  selectedCategory,
  onSelect,
  className,
}) => {
  const onSelectFactory = useCallback((category: TagFamilyTag) => () => {
    if (onSelect) onSelect(category);
  }, [onSelect]);

  return (
    <div className={classNames("bg-white", className)}>
      <div className="flex w-max gap-3">
        {categories.map((category) => (
          <CategoryTag
            key={category.id}
            tag={category}
            onClick={onSelectFactory(category)}
            selected={category.id === selectedCategory?.id}
            className="cursor-pointer"
          />
        ))}
      </div>
    </div>
  );
};

type TagProp = {
  tag: TagFamilyTag;
  selected?: boolean;
  onClick?: () => void;
};

const CategoryTag: BFC<TagProp> = ({
  tag,
  selected,
  onClick,
  className,
}) => {
  const classes = classNames("px-3 py-2 rounded-full", {
    "bg-gray-100 text-black-500": !selected,
    "bg-primary-100 text-black-500 border border-primary": selected,
  }, className);

  return (
    <div className={classes} onClick={onClick}>
      {tag.tag.name}
    </div>
  );
};
