/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseSuccessWithPagingAllOfMetaPaging
 */
export interface ResponseSuccessWithPagingAllOfMetaPaging {
    /**
     * 
     * @type {number}
     * @memberof ResponseSuccessWithPagingAllOfMetaPaging
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseSuccessWithPagingAllOfMetaPaging
     */
    current: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseSuccessWithPagingAllOfMetaPaging
     */
    prev?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseSuccessWithPagingAllOfMetaPaging
     */
    next?: number;
}

/**
 * Check if a given object implements the ResponseSuccessWithPagingAllOfMetaPaging interface.
 */
export function instanceOfResponseSuccessWithPagingAllOfMetaPaging(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "current" in value;

    return isInstance;
}

export function ResponseSuccessWithPagingAllOfMetaPagingFromJSON(json: any): ResponseSuccessWithPagingAllOfMetaPaging {
    return ResponseSuccessWithPagingAllOfMetaPagingFromJSONTyped(json, false);
}

export function ResponseSuccessWithPagingAllOfMetaPagingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseSuccessWithPagingAllOfMetaPaging {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'current': json['current'],
        'prev': !exists(json, 'prev') ? undefined : json['prev'],
        'next': !exists(json, 'next') ? undefined : json['next'],
    };
}

export function ResponseSuccessWithPagingAllOfMetaPagingToJSON(value?: ResponseSuccessWithPagingAllOfMetaPaging | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'current': value.current,
        'prev': value.prev,
        'next': value.next,
    };
}

