/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CartItem } from './CartItem';
import {
    CartItemFromJSON,
    CartItemFromJSONTyped,
    CartItemToJSON,
} from './CartItem';

/**
 * 
 * @export
 * @interface Cart
 */
export interface Cart {
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    id: string;
    /**
     * 
     * @type {Array<CartItem>}
     * @memberof Cart
     */
    items: Array<CartItem>;
    /**
     * 
     * @type {string}
     * @memberof Cart
     */
    shopifyCartId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Cart
     */
    associated: boolean;
}

/**
 * Check if a given object implements the Cart interface.
 */
export function instanceOfCart(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "associated" in value;

    return isInstance;
}

export function CartFromJSON(json: any): Cart {
    return CartFromJSONTyped(json, false);
}

export function CartFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cart {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'items': ((json['items'] as Array<any>).map(CartItemFromJSON)),
        'shopifyCartId': !exists(json, 'shopify_cart_id') ? undefined : json['shopify_cart_id'],
        'associated': json['associated'],
    };
}

export function CartToJSON(value?: Cart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'items': ((value.items as Array<any>).map(CartItemToJSON)),
        'shopify_cart_id': value.shopifyCartId,
        'associated': value.associated,
    };
}

