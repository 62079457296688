import { useRouter } from "next/router";
import { useCallback } from "react";
import { FcGoogle } from "react-icons/fc";
import { ColorRing } from "react-loader-spinner";

import { Button, Link } from "shared/components";
import { getParam } from "shared/lib/next";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { SignUpForm, SignUpData } from "~/features/users/forms";
import { useSignUp } from "~/features/users/hooks";

export const SignUpPage: BFC = () => {
  const { signUp, isSignUpLoading } = useSignUp();
  const router = useRouter();
  const origin = getParam(router.query, "origin");

  const onSubmit = useCallback(async (data: SignUpData) => {
    await signUp(data);
    const path = origin || routes.TOP;
    router.push(`${path}?signedUp=true`);
  }, [signUp, origin]);

  const onGoogleSignUp = useCallback(() => {
    router.push(routes.USERS_AUTH_WITH_GOOGLE({ return_to: origin }));
  }, [origin]);

  if (isSignUpLoading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <ColorRing />
      </div>
    );
  }

  return (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">会員登録</h1>
          <div>
            ログインは
            <Link href={routes.USERS_SIGN_IN}>こちら</Link>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <SignUpForm
            onSubmit={onSubmit}
          />
          <div className="border-t py-3">
            <Button block large className="flex items-center gap-2" onClick={onGoogleSignUp}>
              <FcGoogle size={20} />Googleアカウントで登録
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
