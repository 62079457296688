import {
  FieldValues,
  FieldPath,
  useFormContext,
  Controller,
} from "react-hook-form";

import {
  DateSelectInputGroupProps,
  DateSelectInputGroup,
} from "../DateSelectInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
  defaultValue?: Date;
} & DateSelectInputGroupProps;

export const DateSelectControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  inputClassName,
  defaultValue,
  ...props
}: Props<Data>) => {
  const { control } = useFormContext<Data>();

  return (
    <Controller
      render={({ field, fieldState }) => (
        <DateSelectInputGroup
          {...props}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
        />
      )}
      control={control}
      name={name}
    />
  );
};
