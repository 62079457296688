import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Checkout } from "./checkout";
import { CostcoProduct, CostcoProductType } from "./costco_product";
import { CostcoStore, CostcoStoreType } from "./costco_store";
import { CostcoStoreArea, CostcoStoreAreaType } from "./costco_store_area";
import { StockupCheckoutItem, StockupCheckoutItemType } from "./stockup_checkout_item";
import { StockupItem, StockupItemType } from "./stockup_item";

export type StockupType = api.Stockup;

export class Stockup implements StockupType {
  [immerable] = true;

  id = "";
  date: Date = new Date();
  costcoProduct: CostcoProductType = new CostcoProduct();
  costcoStore: CostcoStoreType = new CostcoStore();
  costcoStoreArea: CostcoStoreAreaType = new CostcoStoreArea();
  requiredQuantity = 0;
  reservedQuantity = 0;
  stockedQuantity = 0;
  outOfStockQuantity = 0;
  requiredContentsQuantity = 0;
  maxContentsQuantity = 0;
  stockedContentsQuantity = 0;
  alternativeFor?: CostcoProductType;
  pickingGroup = "";
  listingOrder = 0;
  memo = "";
  updatedAt = new Date();
  checkoutItems: StockupCheckoutItemType[] = [];
  stockupItems: StockupItemType[] = [];

  constructor(data: Partial<StockupType> = {}) {
    Object.assign(this, data);
  }

  getCostcoProduct() {
    return new CostcoProduct(this.costcoProduct);
  }

  getStockupItems() {
    return this.stockupItems.map((item) => new StockupItem({
      ...item,
      stockup: this,
    }));
  }

  getCheckoutItems() {
    return this.checkoutItems.map((item) => new StockupCheckoutItem(item));
  }

  getDirectCheckoutItems() {
    return this.getCheckoutItems().map((item) => item.getCheckoutItem());
  }

  getCheckouts() {
    return this.checkoutItems
      .map((checkoutItem) => new Checkout(checkoutItem.checkout))
      .reduce((acc, checkout) => {
        if (acc.find((c) => c.id === checkout.id)) {
          return acc;
        }
        return [...acc, checkout];
      }, [] as Checkout[]);
  }

  forAlternative(): this is Stockup & { alternativeFor: CostcoProductType } {
    return !!this.alternativeFor;
  }

  getAlternativeFor() {
    return new CostcoProduct(this.alternativeFor);
  }

  get restQuantity() {
    return this.requiredQuantity - this.committedQuantity;
  }

  get committedQuantity() {
    return this.reservedQuantity + this.stockedQuantity + this.outOfStockQuantity;
  }
}
