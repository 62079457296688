/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserNotificationSetting } from './UserNotificationSetting';
import {
    UserNotificationSettingFromJSON,
    UserNotificationSettingFromJSONTyped,
    UserNotificationSettingToJSON,
} from './UserNotificationSetting';

/**
 * 
 * @export
 * @interface UpdateUserNotificationSetting200ResponseAllOfData
 */
export interface UpdateUserNotificationSetting200ResponseAllOfData {
    /**
     * 
     * @type {UserNotificationSetting}
     * @memberof UpdateUserNotificationSetting200ResponseAllOfData
     */
    notificationSetting?: UserNotificationSetting;
}

/**
 * Check if a given object implements the UpdateUserNotificationSetting200ResponseAllOfData interface.
 */
export function instanceOfUpdateUserNotificationSetting200ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserNotificationSetting200ResponseAllOfDataFromJSON(json: any): UpdateUserNotificationSetting200ResponseAllOfData {
    return UpdateUserNotificationSetting200ResponseAllOfDataFromJSONTyped(json, false);
}

export function UpdateUserNotificationSetting200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserNotificationSetting200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationSetting': !exists(json, 'notification_setting') ? undefined : UserNotificationSettingFromJSON(json['notification_setting']),
    };
}

export function UpdateUserNotificationSetting200ResponseAllOfDataToJSON(value?: UpdateUserNotificationSetting200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notification_setting': UserNotificationSettingToJSON(value.notificationSetting),
    };
}

