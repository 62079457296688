import classNames from "classnames";
import { isSameDay } from "date-fns";
import { useCallback, useState } from "react";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { Product, ShippingParcel, ShippingRate, ShippingTime } from "shared/models";
import { BFC } from "shared/types";

import { AlternativeProductSelector, ProductCardWideRaw, SoctocProductProvider } from "~/features/products";

import { DatePickerItem } from "../DatePickerItem";
import { TimePickerItem } from "../TimePickerItem";

type Props = {
  parcel: ShippingParcel;
  onChange?: (parcel: ShippingParcel, rate: ShippingRate, date: Date, time: ShippingTime) => void;
  onRemove?: (parcel: ShippingParcel) => void;
  onAlternativeProductChange?: (product: Product, alternativeProduct: Product | null) => void;
};

export const ShippingParcelInputGroup: BFC<Props> = ({
  parcel,
  onChange,
  onRemove,
  onAlternativeProductChange,
  className,
}) => {
  const { shippingRate, shippingTime, shippingDate, items } = parcel;
  const [shownSelector, showSelector, hideSelector] = useBooleanState(false);
  const [selectedDate, selectDate] = useState(shippingDate);
  const [selectedTime, selectTime] = useState(shippingTime);
  const dates = parcel.getDates(shippingRate);
  const times = dates.find((d) => selectedDate && isSameDay(d.date, selectedDate))?.times || [];
  const valid = !!shippingRate && !!shippingTime && !!shippingDate;

  const getTimes = useCallback((date: Date) => {
    return dates.find((d) => isSameDay(d.date, date))?.times || [];
  }, [dates]);

  const onDateClick = useCallback((date: Date) => {
    selectDate(date);
    const times = getTimes(date);
    if (!times.length) {
      selectTime(null);
    } else if (!times.find((t) => t.time.id === selectedTime?.id && t.available)) {
      selectTime(null);
    }
  }, [getTimes, selectedTime]);

  const onTimeClick = useCallback((time: ShippingTime) => {
    selectTime(time);
  }, [times]);

  const onChangeClick = useCallback(() => {
    if (onChange && shippingRate && selectedDate && selectedTime) {
      onChange(parcel, shippingRate, selectedDate, selectedTime);
    }
    hideSelector();
  }, [onChange, parcel, shippingRate, selectedDate, selectedTime]);

  const onRemoveClick = useCallback(() => {
    if (onRemove) {
      onRemove(parcel);
    }
  }, [onRemove, parcel]);

  const onAlternativeProductChangeFactory = useCallback((product: Product) => (alternativeProduct: Product | null) => {
    if (onAlternativeProductChange) {
      onAlternativeProductChange(product, alternativeProduct);
    }
  }, [onAlternativeProductChange]);

  return (
    <div className={classNames("border rounded w-full", className)}>
      <div className="p-3 border-b bg-neutral-100 flex items-center justify-between gap-3">
        <div>
          <div>
            {valid ? (
              <div>
                <span className="text-green-600 font-bold">{parcel.formatShippingDateTime()}</span> にお届け予定
              </div>
            ) : (
              <div className="text-black-400">
                {parcel.isAgeRestrictionApplied
                  ? "次の商品は酒類のため、20歳未満の方はご購入いただけません。"
                  : "選択できる配送日時がありません"}
              </div>
            )}
          </div>
        </div>
        <div className="shrink-0">
          {valid ? (
            <Button small onClick={showSelector}>変更</Button>
          ) : (
            <Button small onClick={onRemoveClick}>除外する</Button>
          )}
        </div>
      </div>
      {shownSelector && (
        <div>
          <div className="p-3 flex flex-col gap-3 w-full overflow-x-auto">
            <div className="flex gap-3 min-w-max">
              {dates.map(({ key, date }) => (
                <DatePickerItem
                  key={key}
                  date={date}
                  selected={!!selectedDate && isSameDay(date, selectedDate)}
                  available={!!getTimes(date).some((t) => t.available)}
                  onClick={onDateClick}
                />
              ))}
            </div>
          </div>
          <div className="p-3 pt-0 flex flex-col gap-3">
            {times.map(({ time, available }) => (
              <TimePickerItem
                key={time.id}
                time={time}
                available={available}
                selected={available && !!selectedTime && selectedTime.id === time.id}
                onClick={onTimeClick}
              />
            ))}
          </div>
          <div className="p-3 pt-0 border-b">
            <Button primary block disabled={!valid} onClick={onChangeClick}>配送日時を変更する</Button>
          </div>
        </div>
      )}
      <div className="flex flex-col divide-y divide-dashed">
        {items.map((item) => (
          <div key={item.product.id} className="p-3 flex flex-col gap-2">
            <SoctocProductProvider key={item.product.id} product={item.product}>
              {(product) => (
                <ProductCardWideRaw
                  key={product.id}
                  id={product.id}
                  title={product.title}
                  image={product.image?.webp?.url ?? product.image?.url}
                  price={product.price}
                  discountPrice={product.discountPrice}
                  taxPercentage={product.taxPercentage}
                  quantity={item.quantity}
                  inactive={product.isInactive()}
                  smallImage
                />
              )}
            </SoctocProductProvider>
            <AlternativeProductSelector
              selected={item.alternativeProduct}
              products={item.product.getAlternativeProducts()}
              onSelect={onAlternativeProductChangeFactory(item.product)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
