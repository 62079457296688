import classNames from "classnames";

import { CFC } from "shared/types";

type Props = {
  loading: boolean;
};

/**
 * ローディング中に子要素をオーバーレイしてスピナーを表示するコンポーネント
 */
export const LoadingOverlay: CFC<Props> = ({
  children,
  loading,
  className,
}) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <div className={classNames("relative", className)}>
      {children}
      <div
        role="status"
        className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center"
      >
        <div className="flex flex-col items-center gap-4">
          <div className="animate-spin rounded-full border-4 border-t-4 border-gray-200 border-t-primary h-8 w-8" />
          <div className="sr-only">読み込み中...</div>
        </div>
      </div>
    </div>
  );
};
