/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { FullfillmentShippingItem } from './FullfillmentShippingItem';
import {
    FullfillmentShippingItemFromJSON,
    FullfillmentShippingItemFromJSONTyped,
    FullfillmentShippingItemToJSON,
} from './FullfillmentShippingItem';
import type { ShippingLine } from './ShippingLine';
import {
    ShippingLineFromJSON,
    ShippingLineFromJSONTyped,
    ShippingLineToJSON,
} from './ShippingLine';
import type { ShippingRate } from './ShippingRate';
import {
    ShippingRateFromJSON,
    ShippingRateFromJSONTyped,
    ShippingRateToJSON,
} from './ShippingRate';
import type { ShippingTime } from './ShippingTime';
import {
    ShippingTimeFromJSON,
    ShippingTimeFromJSONTyped,
    ShippingTimeToJSON,
} from './ShippingTime';

/**
 * 
 * @export
 * @interface FullfillmentShipping
 */
export interface FullfillmentShipping {
    /**
     * 
     * @type {string}
     * @memberof FullfillmentShipping
     */
    id: string;
    /**
     * 
     * @type {ShippingRate}
     * @memberof FullfillmentShipping
     */
    shippingRate: ShippingRate;
    /**
     * 
     * @type {ShippingTime}
     * @memberof FullfillmentShipping
     */
    shippingTime: ShippingTime;
    /**
     * 
     * @type {ShippingLine}
     * @memberof FullfillmentShipping
     */
    shippingLine: ShippingLine;
    /**
     * 
     * @type {Address}
     * @memberof FullfillmentShipping
     */
    shippingAddress: Address;
    /**
     * 
     * @type {Array<FullfillmentShippingItem>}
     * @memberof FullfillmentShipping
     */
    items: Array<FullfillmentShippingItem>;
    /**
     * 
     * @type {string}
     * @memberof FullfillmentShipping
     */
    trackingNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof FullfillmentShipping
     */
    shippedAt: Date;
}

/**
 * Check if a given object implements the FullfillmentShipping interface.
 */
export function instanceOfFullfillmentShipping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shippingRate" in value;
    isInstance = isInstance && "shippingTime" in value;
    isInstance = isInstance && "shippingLine" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "shippedAt" in value;

    return isInstance;
}

export function FullfillmentShippingFromJSON(json: any): FullfillmentShipping {
    return FullfillmentShippingFromJSONTyped(json, false);
}

export function FullfillmentShippingFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullfillmentShipping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shippingRate': ShippingRateFromJSON(json['shipping_rate']),
        'shippingTime': ShippingTimeFromJSON(json['shipping_time']),
        'shippingLine': ShippingLineFromJSON(json['shipping_line']),
        'shippingAddress': AddressFromJSON(json['shipping_address']),
        'items': ((json['items'] as Array<any>).map(FullfillmentShippingItemFromJSON)),
        'trackingNumber': !exists(json, 'tracking_number') ? undefined : json['tracking_number'],
        'shippedAt': (new Date(json['shipped_at'])),
    };
}

export function FullfillmentShippingToJSON(value?: FullfillmentShipping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shipping_rate': ShippingRateToJSON(value.shippingRate),
        'shipping_time': ShippingTimeToJSON(value.shippingTime),
        'shipping_line': ShippingLineToJSON(value.shippingLine),
        'shipping_address': AddressToJSON(value.shippingAddress),
        'items': ((value.items as Array<any>).map(FullfillmentShippingItemToJSON)),
        'tracking_number': value.trackingNumber,
        'shipped_at': (value.shippedAt.toISOString()),
    };
}

