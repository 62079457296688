import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { Button } from "shared/components";
import { CheckoutStatus } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ProductCardWideRaw } from "~/features/products";

import { useCheckoutServiceContext } from "../../hooks";

export const CheckoutThanksPage: BFC = () => {
  const {
    checkout,
    parcels,
  } = useCheckoutServiceContext();
  const router = useRouter();

  const onGotoHomeClick = useCallback(() => {
    router.push(routes.TOP);
  }, []);

  const onGotoOrdersClick = useCallback(() => {
    router.push(routes.CHECKOUTS_SHOW(checkout.id));
  }, [checkout]);

  return (
    <div className="bg-white">
      <div className="flex flex-col gap-6">
        <div className="flex justify-center mt-6">
          <img
            src="/assets/images/logo/logo_primary.png"
            alt="logo"
            className="w-40"
          />
        </div>
        <div className="flex justify-center font-bold">
          お買い上げありがとうございました☺️
        </div>
        <div className="border rounded mx-3">
          {parcels.map((parcel) => (
            <div key={parcel.id} className="p-3">
              <h2 className="font-bold text-lg flex items-center">
                <ShoppingCartIcon className="w-5 mr-1" />
                ご購入商品
              </h2>
              <div className="mt-3 flex flex-col gap-3">
                {parcel.items.map((item) => (
                  <ProductCardWideRaw
                    key={item.product.id}
                    id={item.product.id}
                    title={item.product.title}
                    image={item.product.image?.webp?.url ?? item.product.image?.url}
                    quantity={item.quantity}
                    smallImage
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center text-gray-500">
          {(checkout.status === CheckoutStatus.Paid) && (
            checkout.isCancelable ? `${checkout.formatCancelableAt()}までキャンセルできます` : "配送が進行中のため、キャンセルできません"
          )}
        </div>
      </div>
      <div className="p-3 flex flex-col gap-3">
        <Button large block onClick={onGotoOrdersClick}>
          確認する
        </Button>
        <Button large block onClick={onGotoHomeClick}>
          お買い物を続ける
        </Button>
      </div>
    </div>
  );
};
