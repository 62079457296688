import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { ConnectUserProviderRequest, DisconnectUserProviderRequest } from "shared/services/api/store";
import { isAPIError } from "shared/types";

import { useAuthContext } from "~/features/auth";
import { useCartContext, useMergeCart } from "~/features/carts";

export const SignConnectErrorType = {
  Unknown: "unknown",
  NotFound: "not_found",
  AlreadyConnected: "already_connected",
} as const;
export type SignConnectErrorType = typeof SignConnectErrorType[keyof typeof SignConnectErrorType];

export const useSignConnect = () => {
  const { accessToken, waitToken, signIn } = useAuthContext();
  const api = useStoreAPI({ accessToken });
  const { associateToUser, setCartId, waitCartReady } = useCartContext();
  const { merge } = useMergeCart();
  const [isSignConnectLoading, setIsSignConnectLoading] = useState(false);
  const [errorType, setErrorType] = useState<SignConnectErrorType | null>(null);

  const { mutateAsync: connect, isLoading, isError, error } = useMutation(
    ["users/signConnect"],
    (data?: ConnectUserProviderRequest) => api.connectUserProvider(data, { credentials: "include" }),
    {
      onSuccess: async ({ data: { user, token: t, cart: c } }) => {
        setIsSignConnectLoading(true);
        signIn(new UserMe(user), new Token(t));
        if (!c) {
          await waitToken();
          const cart = await waitCartReady();
          await associateToUser(cart.id);
        } else {
          const cart = await waitCartReady();
          await merge(c.id, cart);
          setCartId(c.id);
        }
        setIsSignConnectLoading(false);
      },
      onError: (e) => {
        if (isAPIError(e)) {
          if (e.response.status === 404) {
            setErrorType(SignConnectErrorType.NotFound);
          } else if (e.response.status === 409) {
            setErrorType(SignConnectErrorType.AlreadyConnected);
          } else if (e.response.status === 400) {
            setErrorType(SignConnectErrorType.AlreadyConnected);
          } else {
            setErrorType(SignConnectErrorType.Unknown);
          }
        }
      },
    },
  );

  const { mutateAsync: disconnect } = useMutation(
    ["users/signDisconnect"],
    (provider: DisconnectUserProviderRequest["provider"]) => api.disconnectUserProvider(provider),
  );

  return {
    connect,
    disconnect,
    error,
    errorType,
    isError,
    isFetching: isLoading || isSignConnectLoading,
  };
};
