import classNames from "classnames";
import { RiInstagramLine, RiTwitterLine } from "react-icons/ri";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

export const Footer: BFC = ({
  className,
}) => {
  return (
    <footer className={classNames("bg-black-900 text-white flex flex-col pt-14 pb-6 px-4 gap-8", className)}>
      <section className="flex flex-col gap-4">
        <div className="flex place-content-center">＼ SNSでもお得な情報を発信してます ／</div>
        <div className="flex place-content-center gap-5 text-black-200">
          <div className="flex place-center border border-black-100 rounded-full p-1">
            <a href={routes.SNS_INSTAGRAM} target="_blank" rel="noreferrer">
              <RiInstagramLine size={20} />
            </a>
          </div>
          <div className="flex place-center border rounded-full p-1">
            <a href={routes.SNS_TWITTER} target="_blank" rel="noreferrer">
              <RiTwitterLine size={20} />
            </a>
          </div>
        </div>
      </section>

      <section className="flex flex-col gap-4">
        <div className="flex justify-center gap-3 text-base text-gray-300">
          <a href="https://wcdi.co.jp/company" target="_blank" rel="noreferrer" className="hover:text-gray-200">運営会社</a> |
          <a href={routes.FAQ} target="_blank" rel="noreferrer" className="hover:text-gray-200">よくあるご質問</a> |
          <a href="https://soctoc.channel.io" target="_blank" rel="noreferrer" className="hover:text-gray-200">お問い合わせ</a> |
          <Link href={routes.DOCUMENTS_TERMS_OF_SERVICE} className="hover:text-gray-200">利用規約</Link>
        </div>
        <div className="flex flex-wrap justify-center gap-3 text-base text-gray-300">
          <Link href={routes.DOCUMENTS_PRIVACY_POLICY} className="hover:text-gray-200">プライバシーポリシー</Link> |
          <Link href={routes.DOCUMENTS_TOKUSHOHO} className="hover:text-gray-200">特定商取引法に基づく表示</Link>
        </div>
      </section>

      <hr className="border-black-800" />

      <section className="flex flex-col gap-8">
        {/* 注: 酒類に関する注意は商品の価格表示よりも大きなフォントサイズでなければならない */}
        <div className="text-center text-gray-300 text-2xl sm:text-5xl">
          <span className="inline-block">20歳未満の者の飲酒は法律で禁止されています。</span>
          <span className="inline-block">20歳未満の者に対しては酒類を販売しません。</span>
        </div>
        <div className="flex justify-center">
          <img
            src="/assets/images/logo/logo_secondary.png"
            className="h-7 w-auto"
          />
        </div>
        <div className="text-center text-sm">
          © WeCanDoIt, Inc. All rights reserved.
        </div>
      </section>
    </footer>
  );
};
