/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutRedelivery } from './CheckoutRedelivery';
import {
    CheckoutRedeliveryFromJSON,
    CheckoutRedeliveryFromJSONTyped,
    CheckoutRedeliveryToJSON,
} from './CheckoutRedelivery';

/**
 * 
 * @export
 * @interface GetCheckoutRedeliveries200ResponseAllOfData
 */
export interface GetCheckoutRedeliveries200ResponseAllOfData {
    /**
     * 
     * @type {Array<CheckoutRedelivery>}
     * @memberof GetCheckoutRedeliveries200ResponseAllOfData
     */
    checkoutRedeliveries: Array<CheckoutRedelivery>;
}

/**
 * Check if a given object implements the GetCheckoutRedeliveries200ResponseAllOfData interface.
 */
export function instanceOfGetCheckoutRedeliveries200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkoutRedeliveries" in value;

    return isInstance;
}

export function GetCheckoutRedeliveries200ResponseAllOfDataFromJSON(json: any): GetCheckoutRedeliveries200ResponseAllOfData {
    return GetCheckoutRedeliveries200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckoutRedeliveries200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutRedeliveries200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkoutRedeliveries': ((json['checkout_redeliveries'] as Array<any>).map(CheckoutRedeliveryFromJSON)),
    };
}

export function GetCheckoutRedeliveries200ResponseAllOfDataToJSON(value?: GetCheckoutRedeliveries200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkout_redeliveries': ((value.checkoutRedeliveries as Array<any>).map(CheckoutRedeliveryToJSON)),
    };
}

