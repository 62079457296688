import { ReactNode } from "react";

import { CheckoutParcel, CheckoutParcelDeliveryFailedReason, CheckoutParcelDeliveryStatus, CheckoutParcelShippingStatus } from "shared/models/checkout_parcel";

import { formatDate } from "../date";

type DateTimeDecorator = (str: string) => ReactNode;

export function formatCheckoutParcelShippingStatus(
  parcel: CheckoutParcel,
  options?: {
    decorator?: DateTimeDecorator,
    billed?: boolean,
  },
) {
  const { decorator } = options ?? {};
  const datetime = decorator ? decorator(parcel.formatShippingDateTime()) : parcel.formatShippingDateTime();
  const deliveryStatus = formatCheckoutParcelDeliveryStatus(parcel.deliveryStatus, parcel);
  const deliveried = parcel.deliveryStatus === CheckoutParcelDeliveryStatus.Completed && !parcel.redeliveryDate;

  const text = deliveried ? "配達済みです" : deliveryStatus ? `配達予定です(${deliveryStatus})` : "配達予定です";

  switch (parcel.shippingStatus) {
    case CheckoutParcelShippingStatus.CompletedWithPartially:
      return (
        <>
          {datetime}に一部{text}
        </>
      );
    case CheckoutParcelShippingStatus.Completed:
      return (
        <>
          {datetime}に{text}
        </>
      );
    case CheckoutParcelShippingStatus.Canceled:
      return "キャンセル済みです";
    case CheckoutParcelShippingStatus.Pending:
      return "保留中です";
    default:
      return (
        <>
          {datetime}に{text}
        </>
      );
  }
}

export function formatCheckoutParcelRedeliveryStatus(
  parcel: CheckoutParcel,
  options?: {
    decorator?: DateTimeDecorator,
  },
) {
  const { decorator } = options ?? {};
  const redeliveryDate = parcel.redeliveryDate ? formatDate(parcel.redeliveryDate) : "未定";
  const redeliveryTime = parcel.redeliveryTime;
  const dateTime = redeliveryTime ? `${redeliveryDate} ${redeliveryTime}` : redeliveryDate;
  const dateTimeString = decorator ? decorator(dateTime) : dateTime;
  const deliveried = parcel.deliveryStatus === CheckoutParcelDeliveryStatus.Completed;

  if (deliveried) {
    return <>{dateTimeString}に再配達済みです</>;
  } else {
    return <>{dateTimeString}に再配達予定です</>;
  }
}

export function formatCheckoutParcelDeliveryStatus(
  status: CheckoutParcelDeliveryStatus,
  parcel?: CheckoutParcel,
) {
  const reason = parcel?.deliveryFailedReason;
  const redeliveryDate = parcel?.redeliveryDate;

  switch (status) {
    case CheckoutParcelDeliveryStatus.Completed:
      if (redeliveryDate) return "配達できませんでした";
      return "配達済み";
    case CheckoutParcelDeliveryStatus.Shipped:
      return "発送済み";
    case CheckoutParcelDeliveryStatus.Failed:
      if (reason === CheckoutParcelDeliveryFailedReason.Pending) return null;
      return "配達できませんでした";
    default:
      return null;
  }
}
