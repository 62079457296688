/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendCouponCodeAllOfReward
 */
export interface FriendCouponCodeAllOfReward {
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCodeAllOfReward
     */
    discountType: FriendCouponCodeAllOfRewardDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FriendCouponCodeAllOfReward
     */
    amountOff: number;
    /**
     * 
     * @type {number}
     * @memberof FriendCouponCodeAllOfReward
     */
    percentOff: number;
}


/**
 * @export
 */
export const FriendCouponCodeAllOfRewardDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent'
} as const;
export type FriendCouponCodeAllOfRewardDiscountTypeEnum = typeof FriendCouponCodeAllOfRewardDiscountTypeEnum[keyof typeof FriendCouponCodeAllOfRewardDiscountTypeEnum];


/**
 * Check if a given object implements the FriendCouponCodeAllOfReward interface.
 */
export function instanceOfFriendCouponCodeAllOfReward(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "discountType" in value;
    isInstance = isInstance && "amountOff" in value;
    isInstance = isInstance && "percentOff" in value;

    return isInstance;
}

export function FriendCouponCodeAllOfRewardFromJSON(json: any): FriendCouponCodeAllOfReward {
    return FriendCouponCodeAllOfRewardFromJSONTyped(json, false);
}

export function FriendCouponCodeAllOfRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendCouponCodeAllOfReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discountType': json['discount_type'],
        'amountOff': json['amount_off'],
        'percentOff': json['percent_off'],
    };
}

export function FriendCouponCodeAllOfRewardToJSON(value?: FriendCouponCodeAllOfReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discount_type': value.discountType,
        'amount_off': value.amountOff,
        'percent_off': value.percentOff,
    };
}

