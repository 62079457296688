import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { Button } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  quantity: number;
  maxQuantity?: number;
  onPlus: () => void;
  onMinus: () => void;
  onRemove: () => void;
  disabled?: boolean;
};

export const QuantityAdjuster: BFC<Props> = ({
  quantity,
  maxQuantity,
  onPlus,
  onMinus,
  onRemove,
  disabled,
  className,
}) => {
  const isPlusButtonDisabled = disabled || (typeof maxQuantity === "number" && quantity >= maxQuantity);

  const isMaxQuantityExceeded = typeof maxQuantity === "number" && quantity > maxQuantity;

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      <div className="flex gap-3">
        <div className="flex border rounded">
          <button className="border-r flex items-center justify-center w-8" onClick={onMinus} disabled={disabled}>
            {quantity > 1 ? (
              <MinusSmallIcon className={classNames("w-6", { "text-black-300": disabled })} />
            ) : (
              <TrashIcon className={classNames("w-4", { "text-black-300": disabled })} />
            )}
          </button>
          <div className="flex items-center justify-center px-3">{quantity}</div>
          <button className="border-l flex items-center justify-center w-8" onClick={onPlus} disabled={isPlusButtonDisabled}>
            <PlusSmallIcon className={classNames("w-6", { "text-black-300": isPlusButtonDisabled })} />
          </button>
        </div>
        <Button onClick={onRemove} disabled={disabled}>削除</Button>
      </div>
      {isMaxQuantityExceeded && (
        <div className="text-sm text-primary">注文可能な最大数量を超えています。注文数を減らしてください</div>
      )}
    </div>
  );
};
