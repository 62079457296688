import classNames from "classnames";
import NextLink from "next/link";

import { CFC } from "shared/types";

type Props = {
  href: string;
  target?: "_blank";
  decorated?: boolean;
  noDecoration?: boolean;
  disabled?: boolean;
  "aria-label"?: string;
  "aria-describedby"?: string;
};

export const Link: CFC<Props> = ({
  href,
  target,
  decorated,
  disabled,
  className,
  children,
  "aria-label": ariaLabel,
  "aria-describedby": ariaDescribedBy,
}) => {
  const classes = classNames(
    className,
    {
      "border-b border-dashed border-primary": decorated,
    },
  );

  if (disabled) {
    return <div className={classes}>{children}</div>;
  }

  return (
    <NextLink
      href={href}
      className={classes}
      target={target}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedBy}
    >
      {children}
    </NextLink>
  );
};
