import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  tag: string;
};

export const TagBadge: BFC<Props> = ({
  tag,
  className,
}) => {
  const classes = classNames(
    "inline-block text-white font-bold rounded text-sm px-2 py-0.5 bg-gray-300",
    className,
  );

  return (
    <span className={classes}>#{tag}</span>
  );
};
