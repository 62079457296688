/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateStockRequestStock } from './UpdateStockRequestStock';
import {
    UpdateStockRequestStockFromJSON,
    UpdateStockRequestStockFromJSONTyped,
    UpdateStockRequestStockToJSON,
} from './UpdateStockRequestStock';

/**
 * 
 * @export
 * @interface UpdateStockRequest
 */
export interface UpdateStockRequest {
    /**
     * 
     * @type {UpdateStockRequestStock}
     * @memberof UpdateStockRequest
     */
    stock: UpdateStockRequestStock;
}

/**
 * Check if a given object implements the UpdateStockRequest interface.
 */
export function instanceOfUpdateStockRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stock" in value;

    return isInstance;
}

export function UpdateStockRequestFromJSON(json: any): UpdateStockRequest {
    return UpdateStockRequestFromJSONTyped(json, false);
}

export function UpdateStockRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStockRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stock': UpdateStockRequestStockFromJSON(json['stock']),
    };
}

export function UpdateStockRequestToJSON(value?: UpdateStockRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stock': UpdateStockRequestStockToJSON(value.stock),
    };
}

