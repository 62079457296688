import { format, isSameDay } from "date-fns";
import produce, { immerable } from "immer";

import { formatDate } from "shared/helpers";

import { Product } from "./product";
import { ShippingParcelItem } from "./shipping_parcel_item";
import { ShippingRate } from "./shipping_rate";
import { ShippingTime } from "./shipping_time";

export type ShippingParcelType = {
  id: string;
  shippingRate: ShippingRate | null;
  shippingTime: ShippingTime | null;
  shippingDate: Date | null;
  items: ShippingParcelItem[];
  isAgeRestrictionApplied: boolean; // 酒類の年齢制限に引っかかっているかどうか
};

type UpdateItemOptions = {
  alternativeProduct: Product | null;
};

export class ShippingParcel implements ShippingParcelType {
  [immerable] = true;

  id = "";
  shippingRate: ShippingRate | null = null;
  shippingTime: ShippingTime | null = null;
  shippingDate: Date | null = null;
  items: ShippingParcelItem[] = [];
  isAgeRestrictionApplied = false;

  constructor(data: Partial<ShippingParcelType> = {}) {
    Object.assign(this, data);
  }

  getDates(shippingRate: ShippingRate | null): Array<{ key: string; date: Date; times: Array<{ time: ShippingTime; available: boolean }>; }> {
    const dates = new Map<string, Map<string, { date: Date; time: ShippingTime; available: boolean }>>();
    this.items.forEach((item) => {
      item.shippingRates.filter((rate) => rate.id === shippingRate?.id).forEach((rate) => {
        rate.availableDatetimes.forEach((datetime) => {
          const date = format(datetime.date, "yyyy-MM-dd");
          const times = dates.get(date) || new Map<string, { date: Date; time: ShippingTime; available: boolean }>();
          let available = datetime.availableQuantity >= item.quantity;
          available = times.has(datetime.id) ? !!times.get(datetime.id)?.available && available : available;
          times.set(datetime.id, { date: datetime.date, time: new ShippingTime(datetime), available });
          dates.set(date, times);
        });
      });
    });
    return [...dates.entries()].map(([date, times]) => ({
      key: date,
      date: new Date(date),
      times: [...times.values()].map(({ time, available }) => ({ time, available })),
    }));
  }

  updateAvailableShippingDateTime() {
    return produce(this, (draft) => {
      const availableDate = this.getDates(this.shippingRate).find((date) => date.times.some((time) => time.available));
      if (availableDate) {
        const availableTime = availableDate.times.find((time) => time.available);
        if (availableTime) {
          draft.shippingDate = availableDate.date;
          draft.shippingTime = availableTime.time;
        }
      }
    });
  }

  addItem(item: ShippingParcelItem) {
    return produce(this, (draft) => {
      draft.items.push(item);
    });
  }

  addItems(items: ShippingParcelItem[]) {
    return produce(this, (draft) => {
      draft.items.push(...items);
    });
  }

  updateItem(product: Product, quantity: number, options?: UpdateItemOptions) {
    return produce(this, (draft) => {
      const { alternativeProduct = null } = options || {};

      const item = draft.items.find((i) => i.product.id === product.id);
      if (!item) {
        return;
      }

      item.quantity = quantity;
      item.alternativeProduct = alternativeProduct;
    });
  }

  removeItem(item: ShippingParcelItem) {
    return produce(this, (draft) => {
      draft.items = draft.items.filter((i) => i.product.id !== item.product.id);
    });
  }

  hasItem(item: ShippingParcelItem) {
    return this.items.some((i) => i.product.id === item.product.id);
  }

  isItemAddable(item: ShippingParcelItem) {
    if (this.isAgeRestrictionApplied && !item.product.isAlcoholic) {
      // 年齢制限による酒類隔離用の Parcel に酒類以外の商品は追加しないようにする
      return false;
    }

    const currentRates = this.items.map((i) => i.shippingRates).flat();
    const currentRateAndDatetimes =
      currentRates.map((r) => r.availableDatetimes.map(
        (d) => ({ rateId: r.id, date: d.date, timeId: d.id, quantity: d.availableQuantity }),
      )).flat();
    const itemRateAndDatetimes =
      item.shippingRates.map((r) => r.availableDatetimes.map(
        (d) => ({ rateId: r.id, date: d.date, timeId: d.id, quantity: d.availableQuantity }),
      )).flat();

    return itemRateAndDatetimes.some((i) => {
      return currentRateAndDatetimes.some((c) => {
        return i.rateId === c.rateId && isSameDay(i.date, c.date) && i.timeId === c.timeId && i.quantity >= item.quantity && c.quantity > 0;
      });
    });
  }

  isDeliverable(): this is ShippingParcel & { shippingRate: ShippingRate; shippingTime: ShippingTime; shippingDate: Date }{
    return !!this.shippingRate && !!this.shippingTime && !!this.shippingDate &&!this.isAgeRestrictionApplied;
  }

  formatShippingDateTime() {
    if (!this.shippingDate || !this.shippingTime) {
      return "";
    }

    return `${formatDate(this.shippingDate, "yyyy年MM月dd日(E)")} ${this.shippingTime.name}`;
  }
}
