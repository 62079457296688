/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCheckoutFullfillmentShippingRequestFullfillmentShipping } from './PostCheckoutFullfillmentShippingRequestFullfillmentShipping';
import {
    PostCheckoutFullfillmentShippingRequestFullfillmentShippingFromJSON,
    PostCheckoutFullfillmentShippingRequestFullfillmentShippingFromJSONTyped,
    PostCheckoutFullfillmentShippingRequestFullfillmentShippingToJSON,
} from './PostCheckoutFullfillmentShippingRequestFullfillmentShipping';

/**
 * 
 * @export
 * @interface PostCheckoutFullfillmentShippingRequest
 */
export interface PostCheckoutFullfillmentShippingRequest {
    /**
     * 
     * @type {PostCheckoutFullfillmentShippingRequestFullfillmentShipping}
     * @memberof PostCheckoutFullfillmentShippingRequest
     */
    fullfillmentShipping: PostCheckoutFullfillmentShippingRequestFullfillmentShipping;
}

/**
 * Check if a given object implements the PostCheckoutFullfillmentShippingRequest interface.
 */
export function instanceOfPostCheckoutFullfillmentShippingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullfillmentShipping" in value;

    return isInstance;
}

export function PostCheckoutFullfillmentShippingRequestFromJSON(json: any): PostCheckoutFullfillmentShippingRequest {
    return PostCheckoutFullfillmentShippingRequestFromJSONTyped(json, false);
}

export function PostCheckoutFullfillmentShippingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCheckoutFullfillmentShippingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullfillmentShipping': PostCheckoutFullfillmentShippingRequestFullfillmentShippingFromJSON(json['fullfillment_shipping']),
    };
}

export function PostCheckoutFullfillmentShippingRequestToJSON(value?: PostCheckoutFullfillmentShippingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullfillment_shipping': PostCheckoutFullfillmentShippingRequestFullfillmentShippingToJSON(value.fullfillmentShipping),
    };
}

