/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface GetUserPaymentMethods200ResponseAllOfData
 */
export interface GetUserPaymentMethods200ResponseAllOfData {
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof GetUserPaymentMethods200ResponseAllOfData
     */
    paymentMethods: Array<PaymentMethod>;
    /**
     * 
     * @type {PaymentMethod}
     * @memberof GetUserPaymentMethods200ResponseAllOfData
     */
    defaultPaymentMethod?: PaymentMethod;
}

/**
 * Check if a given object implements the GetUserPaymentMethods200ResponseAllOfData interface.
 */
export function instanceOfGetUserPaymentMethods200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentMethods" in value;

    return isInstance;
}

export function GetUserPaymentMethods200ResponseAllOfDataFromJSON(json: any): GetUserPaymentMethods200ResponseAllOfData {
    return GetUserPaymentMethods200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserPaymentMethods200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserPaymentMethods200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethods': ((json['payment_methods'] as Array<any>).map(PaymentMethodFromJSON)),
        'defaultPaymentMethod': !exists(json, 'default_payment_method') ? undefined : PaymentMethodFromJSON(json['default_payment_method']),
    };
}

export function GetUserPaymentMethods200ResponseAllOfDataToJSON(value?: GetUserPaymentMethods200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_methods': ((value.paymentMethods as Array<any>).map(PaymentMethodToJSON)),
        'default_payment_method': PaymentMethodToJSON(value.defaultPaymentMethod),
    };
}

