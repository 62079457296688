/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckoutPaymentMethodRequest
 */
export interface UpdateCheckoutPaymentMethodRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckoutPaymentMethodRequest
     */
    paymentMethodId: string;
}

/**
 * Check if a given object implements the UpdateCheckoutPaymentMethodRequest interface.
 */
export function instanceOfUpdateCheckoutPaymentMethodRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "paymentMethodId" in value;

    return isInstance;
}

export function UpdateCheckoutPaymentMethodRequestFromJSON(json: any): UpdateCheckoutPaymentMethodRequest {
    return UpdateCheckoutPaymentMethodRequestFromJSONTyped(json, false);
}

export function UpdateCheckoutPaymentMethodRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutPaymentMethodRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentMethodId': json['payment_method_id'],
    };
}

export function UpdateCheckoutPaymentMethodRequestToJSON(value?: UpdateCheckoutPaymentMethodRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_method_id': value.paymentMethodId,
    };
}

