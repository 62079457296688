/* tslint:disable */
/* eslint-disable */
/**
 * Common
 * 共通API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetCostcoStores200Response,
  GetPrefectures200Response,
  GetShippingTimes200Response,
} from '../models';
import {
    GetCostcoStores200ResponseFromJSON,
    GetCostcoStores200ResponseToJSON,
    GetPrefectures200ResponseFromJSON,
    GetPrefectures200ResponseToJSON,
    GetShippingTimes200ResponseFromJSON,
    GetShippingTimes200ResponseToJSON,
} from '../models';

/**
 * 
 */
export class CommonApi extends runtime.BaseAPI {

    /**
     * コストコ店舗一覧を取得
     * Get Costco Stores
     */
    async getCostcoStoresRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCostcoStores200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/costco_stores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCostcoStores200ResponseFromJSON(jsonValue));
    }

    /**
     * コストコ店舗一覧を取得
     * Get Costco Stores
     */
    async getCostcoStores(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCostcoStores200Response> {
        const response = await this.getCostcoStoresRaw(initOverrides);
        return await response.value();
    }

    /**
     * get prefectures
     */
    async getPrefecturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPrefectures200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prefectures`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPrefectures200ResponseFromJSON(jsonValue));
    }

    /**
     * get prefectures
     */
    async getPrefectures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPrefectures200Response> {
        const response = await this.getPrefecturesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 配送日時を取得する
     * get shipping times
     */
    async getShippingTimesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetShippingTimes200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/shipping_times`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetShippingTimes200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送日時を取得する
     * get shipping times
     */
    async getShippingTimes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetShippingTimes200Response> {
        const response = await this.getShippingTimesRaw(initOverrides);
        return await response.value();
    }

}
