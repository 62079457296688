/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCheckoutTimelines200ResponseAllOfDataTimelinesInner } from './GetCheckoutTimelines200ResponseAllOfDataTimelinesInner';
import {
    GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSON,
    GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSONTyped,
    GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerToJSON,
} from './GetCheckoutTimelines200ResponseAllOfDataTimelinesInner';

/**
 * 
 * @export
 * @interface GetCheckoutTimelines200ResponseAllOfData
 */
export interface GetCheckoutTimelines200ResponseAllOfData {
    /**
     * 
     * @type {Array<GetCheckoutTimelines200ResponseAllOfDataTimelinesInner>}
     * @memberof GetCheckoutTimelines200ResponseAllOfData
     */
    timelines: Array<GetCheckoutTimelines200ResponseAllOfDataTimelinesInner>;
}

/**
 * Check if a given object implements the GetCheckoutTimelines200ResponseAllOfData interface.
 */
export function instanceOfGetCheckoutTimelines200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timelines" in value;

    return isInstance;
}

export function GetCheckoutTimelines200ResponseAllOfDataFromJSON(json: any): GetCheckoutTimelines200ResponseAllOfData {
    return GetCheckoutTimelines200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckoutTimelines200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutTimelines200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timelines': ((json['timelines'] as Array<any>).map(GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerFromJSON)),
    };
}

export function GetCheckoutTimelines200ResponseAllOfDataToJSON(value?: GetCheckoutTimelines200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timelines': ((value.timelines as Array<any>).map(GetCheckoutTimelines200ResponseAllOfDataTimelinesInnerToJSON)),
    };
}

