import { immerable } from "immer";

import * as api from "../services/api/models";
import { ShippingRate } from "./shipping_rate";

export type ProductShippingRateType = api.ProductShippingRate;

export type ProductShippingRateAvailableDatetimeType = api.ProductShippingRateAllOfAvailableDatetimes;

export class ProductShippingRate implements ProductShippingRateType {
  [immerable] = true;

  id = "";
  handle = "";
  name = "";
  price = 0;
  availableDatetimes: ProductShippingRateAvailableDatetimeType[] = [];

  constructor(data: Partial<ProductShippingRateType> = {}) {
    Object.assign(this, data);
  }

  toShippingRate() {
    return new ShippingRate({
      id: this.id,
      handle: this.handle,
      name: this.name,
      price: this.price,
    });
  }
}
