import classNames from "classnames";
import Link from "next/link";
import { AnchorHTMLAttributes } from "react";

import { CFC } from "shared/types";

type Props = {
  href: string;
  large?: boolean;
  small?: boolean;
  block?: boolean;
  primary?: boolean;
  disabled?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export const LinkButton: CFC<Props> = ({
  href,
  large,
  small,
  block,
  primary,
  className,
  children,
  ...props
}) => {
  const classes = classNames(
    "inline-flex items-center rounded border bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm",
    "hover:bg-gray-50",
    "disabled:text-black-300 disabled:cursor-not-allowed",
    className,
    {
      "px-6 py-3": large,
      "px-3 py-1 text-sm": small,
      "!flex w-full justify-center": block,
      "!bg-primary text-white border-transparent hover:bg-primary-600 font-bold":
        primary,
      "disabled:bg-gray-300 disabled:text-white disabled:border-transparent":
        primary,
    }
  );

  return (
    <Link {...props} href={href} className={classes}>
      {children}
    </Link>
  );
};
