/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductImage } from './ProductImage';
import {
    ProductImageFromJSON,
    ProductImageFromJSONTyped,
    ProductImageToJSON,
} from './ProductImage';

/**
 * コストコ商品
 * @export
 * @interface CostcoProduct
 */
export interface CostcoProduct {
    /**
     * 
     * @type {string}
     * @memberof CostcoProduct
     */
    id: string;
    /**
     * 商品名
     * @type {string}
     * @memberof CostcoProduct
     */
    name: string;
    /**
     * 商品名（英語）
     * @type {string}
     * @memberof CostcoProduct
     */
    nameEn: string;
    /**
     * 商品の種類まで含めたユニークな管理コード
     * @type {string}
     * @memberof CostcoProduct
     */
    sku: string;
    /**
     * 保管温度
     * @type {string}
     * @memberof CostcoProduct
     */
    storageTemperature: CostcoProductStorageTemperatureEnum;
    /**
     * 
     * @type {number}
     * @memberof CostcoProduct
     */
    contentsQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof CostcoProduct
     */
    kind: CostcoProductKindEnum;
    /**
     * 重量販売かどうか
     * @type {boolean}
     * @memberof CostcoProduct
     */
    isPerWeight: boolean;
    /**
     * 重量販売かどうか
     * @type {number}
     * @memberof CostcoProduct
     */
    avgExpiryDays: number;
    /**
     * 重量販売かどうか
     * @type {number}
     * @memberof CostcoProduct
     */
    minExpiryDays: number;
    /**
     * 重量販売かどうか
     * @type {number}
     * @memberof CostcoProduct
     */
    maxExpiryDays: number;
    /**
     * 
     * @type {string}
     * @memberof CostcoProduct
     */
    pickingMemo?: string;
    /**
     * 重量販売かどうか
     * @type {number}
     * @memberof CostcoProduct
     */
    stockedContentsQuantity?: number;
    /**
     * 販売価格とマークアップ率に基づいて計算した標準仕入価格
     * @type {number}
     * @memberof CostcoProduct
     */
    standardPurchasePrice?: number;
    /**
     * 子要素のcostco_product_logに最新のレコードが追加されたタイムスタンプ
     * @type {Date}
     * @memberof CostcoProduct
     */
    surveyedAt?: Date;
    /**
     * 
     * @type {ProductImage}
     * @memberof CostcoProduct
     */
    image?: ProductImage;
}


/**
 * @export
 */
export const CostcoProductStorageTemperatureEnum = {
    Normal: 'normal',
    Refrigerated: 'refrigerated',
    SemiRefrigerated: 'semi_refrigerated',
    Frozen: 'frozen',
    Hotted: 'hotted'
} as const;
export type CostcoProductStorageTemperatureEnum = typeof CostcoProductStorageTemperatureEnum[keyof typeof CostcoProductStorageTemperatureEnum];

/**
 * @export
 */
export const CostcoProductKindEnum = {
    Food: 'food',
    DailyNecessities: 'daily_necessities',
    Alcoholic: 'alcoholic'
} as const;
export type CostcoProductKindEnum = typeof CostcoProductKindEnum[keyof typeof CostcoProductKindEnum];


/**
 * Check if a given object implements the CostcoProduct interface.
 */
export function instanceOfCostcoProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "nameEn" in value;
    isInstance = isInstance && "sku" in value;
    isInstance = isInstance && "storageTemperature" in value;
    isInstance = isInstance && "contentsQuantity" in value;
    isInstance = isInstance && "kind" in value;
    isInstance = isInstance && "isPerWeight" in value;
    isInstance = isInstance && "avgExpiryDays" in value;
    isInstance = isInstance && "minExpiryDays" in value;
    isInstance = isInstance && "maxExpiryDays" in value;

    return isInstance;
}

export function CostcoProductFromJSON(json: any): CostcoProduct {
    return CostcoProductFromJSONTyped(json, false);
}

export function CostcoProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostcoProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'nameEn': json['name_en'],
        'sku': json['sku'],
        'storageTemperature': json['storage_temperature'],
        'contentsQuantity': json['contents_quantity'],
        'kind': json['kind'],
        'isPerWeight': json['is_per_weight'],
        'avgExpiryDays': json['avg_expiry_days'],
        'minExpiryDays': json['min_expiry_days'],
        'maxExpiryDays': json['max_expiry_days'],
        'pickingMemo': !exists(json, 'picking_memo') ? undefined : json['picking_memo'],
        'stockedContentsQuantity': !exists(json, 'stocked_contents_quantity') ? undefined : json['stocked_contents_quantity'],
        'standardPurchasePrice': !exists(json, 'standard_purchase_price') ? undefined : json['standard_purchase_price'],
        'surveyedAt': !exists(json, 'surveyed_at') ? undefined : (new Date(json['surveyed_at'])),
        'image': !exists(json, 'image') ? undefined : ProductImageFromJSON(json['image']),
    };
}

export function CostcoProductToJSON(value?: CostcoProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'name_en': value.nameEn,
        'sku': value.sku,
        'storage_temperature': value.storageTemperature,
        'contents_quantity': value.contentsQuantity,
        'kind': value.kind,
        'is_per_weight': value.isPerWeight,
        'avg_expiry_days': value.avgExpiryDays,
        'min_expiry_days': value.minExpiryDays,
        'max_expiry_days': value.maxExpiryDays,
        'picking_memo': value.pickingMemo,
        'stocked_contents_quantity': value.stockedContentsQuantity,
        'standard_purchase_price': value.standardPurchasePrice,
        'surveyed_at': value.surveyedAt === undefined ? undefined : (value.surveyedAt.toISOString()),
        'image': ProductImageToJSON(value.image),
    };
}

