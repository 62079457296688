/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner } from './PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner';
import {
    PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSON,
    PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSONTyped,
    PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerToJSON,
} from './PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner';

/**
 * 
 * @export
 * @interface PostCheckoutFullfillmentStockingRequest
 */
export interface PostCheckoutFullfillmentStockingRequest {
    /**
     * 
     * @type {Array<PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner>}
     * @memberof PostCheckoutFullfillmentStockingRequest
     */
    fullfillmentStockings: Array<PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInner>;
}

/**
 * Check if a given object implements the PostCheckoutFullfillmentStockingRequest interface.
 */
export function instanceOfPostCheckoutFullfillmentStockingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullfillmentStockings" in value;

    return isInstance;
}

export function PostCheckoutFullfillmentStockingRequestFromJSON(json: any): PostCheckoutFullfillmentStockingRequest {
    return PostCheckoutFullfillmentStockingRequestFromJSONTyped(json, false);
}

export function PostCheckoutFullfillmentStockingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCheckoutFullfillmentStockingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullfillmentStockings': ((json['fullfillment_stockings'] as Array<any>).map(PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerFromJSON)),
    };
}

export function PostCheckoutFullfillmentStockingRequestToJSON(value?: PostCheckoutFullfillmentStockingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullfillment_stockings': ((value.fullfillmentStockings as Array<any>).map(PostCheckoutFullfillmentStockingRequestFullfillmentStockingsInnerToJSON)),
    };
}

