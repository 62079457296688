/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FriendCouponCode } from './FriendCouponCode';
import {
    FriendCouponCodeFromJSON,
    FriendCouponCodeFromJSONTyped,
    FriendCouponCodeToJSON,
} from './FriendCouponCode';
import type { UserNotificationSetting } from './UserNotificationSetting';
import {
    UserNotificationSettingFromJSON,
    UserNotificationSettingFromJSONTyped,
    UserNotificationSettingToJSON,
} from './UserNotificationSetting';
import type { UserProvider } from './UserProvider';
import {
    UserProviderFromJSON,
    UserProviderFromJSONTyped,
    UserProviderToJSON,
} from './UserProvider';

/**
 * 
 * @export
 * @interface UserMe
 */
export interface UserMe {
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    phone?: string;
    /**
     * 生年月日。未設定の場合は値なし
     * @type {Date}
     * @memberof UserMe
     */
    birthDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    zipCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    emailMarketingAgreed: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserMe
     */
    stripeCustomerId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    confirmedEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    confirmedPhone: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserMe
     */
    isInternal: boolean;
    /**
     * 
     * @type {Array<UserProvider>}
     * @memberof UserMe
     */
    providers?: Array<UserProvider>;
    /**
     * 
     * @type {FriendCouponCode}
     * @memberof UserMe
     */
    friendCouponCode?: FriendCouponCode;
    /**
     * 
     * @type {UserNotificationSetting}
     * @memberof UserMe
     */
    notificationSetting?: UserNotificationSetting;
    /**
     * チャネルトークのライブラリ初期化時のユーザー認証に必要なハッシュ値
     * @type {string}
     * @memberof UserMe
     */
    channelIoMemberHash: string;
    /**
     * 
     * @type {number}
     * @memberof UserMe
     */
    checkoutCount: number;
}

/**
 * Check if a given object implements the UserMe interface.
 */
export function instanceOfUserMe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "emailMarketingAgreed" in value;
    isInstance = isInstance && "stripeCustomerId" in value;
    isInstance = isInstance && "confirmedEmail" in value;
    isInstance = isInstance && "confirmedPhone" in value;
    isInstance = isInstance && "isInternal" in value;
    isInstance = isInstance && "channelIoMemberHash" in value;
    isInstance = isInstance && "checkoutCount" in value;

    return isInstance;
}

export function UserMeFromJSON(json: any): UserMe {
    return UserMeFromJSONTyped(json, false);
}

export function UserMeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'birthDate': !exists(json, 'birth_date') ? undefined : (new Date(json['birth_date'])),
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'emailMarketingAgreed': json['email_marketing_agreed'],
        'stripeCustomerId': json['stripe_customer_id'],
        'confirmedEmail': json['confirmed_email'],
        'confirmedPhone': json['confirmed_phone'],
        'isInternal': json['is_internal'],
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(UserProviderFromJSON)),
        'friendCouponCode': !exists(json, 'friend_coupon_code') ? undefined : FriendCouponCodeFromJSON(json['friend_coupon_code']),
        'notificationSetting': !exists(json, 'notification_setting') ? undefined : UserNotificationSettingFromJSON(json['notification_setting']),
        'channelIoMemberHash': json['channel_io_member_hash'],
        'checkoutCount': json['checkout_count'],
    };
}

export function UserMeToJSON(value?: UserMe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'phone': value.phone,
        'birth_date': value.birthDate === undefined ? undefined : (value.birthDate.toISOString().substr(0,10)),
        'zip_code': value.zipCode,
        'email_marketing_agreed': value.emailMarketingAgreed,
        'stripe_customer_id': value.stripeCustomerId,
        'confirmed_email': value.confirmedEmail,
        'confirmed_phone': value.confirmedPhone,
        'is_internal': value.isInternal,
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(UserProviderToJSON)),
        'friend_coupon_code': FriendCouponCodeToJSON(value.friendCouponCode),
        'notification_setting': UserNotificationSettingToJSON(value.notificationSetting),
        'channel_io_member_hash': value.channelIoMemberHash,
        'checkout_count': value.checkoutCount,
    };
}

