/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface TagFamilyTag
 */
export interface TagFamilyTag {
    /**
     * 
     * @type {string}
     * @memberof TagFamilyTag
     */
    id: string;
    /**
     * 
     * @type {Array<TagFamilyTag>}
     * @memberof TagFamilyTag
     */
    children: Array<TagFamilyTag>;
    /**
     * 
     * @type {Tag}
     * @memberof TagFamilyTag
     */
    tag: Tag;
}

/**
 * Check if a given object implements the TagFamilyTag interface.
 */
export function instanceOfTagFamilyTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "children" in value;
    isInstance = isInstance && "tag" in value;

    return isInstance;
}

export function TagFamilyTagFromJSON(json: any): TagFamilyTag {
    return TagFamilyTagFromJSONTyped(json, false);
}

export function TagFamilyTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagFamilyTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'children': ((json['children'] as Array<any>).map(TagFamilyTagFromJSON)),
        'tag': TagFromJSON(json['tag']),
    };
}

export function TagFamilyTagToJSON(value?: TagFamilyTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'children': ((value.children as Array<any>).map(TagFamilyTagToJSON)),
        'tag': TagToJSON(value.tag),
    };
}

