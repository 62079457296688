import { useId } from "react";

/**
 * 入力要素の ARIA 属性用の ID を生成するフック
 */
export const useInputAriaIds = ({
  note,
  error,
  required,
}: {
  note?: string;
  error?: string;
  required?: boolean;
}) => {
  const baseId = useId();

  const inputId = `${baseId}-input`;
  const descriptionId = note ? `${baseId}-description` : undefined;
  const errorMessageId = error ? `${baseId}-error` : undefined;

  return {
    // ランダム生成された ID
    baseId,
    // input タグに指定する ID
    inputId,
    // input タグに指定する ARIA 属性
    inputAriaProps: {
      "aria-describedby": descriptionId,
      "aria-errormessage": errorMessageId,
      "aria-invalid": errorMessageId ? "true" : "false",
      "aria-required": required ? "true" : "false",
    },
    // 説明文に指定する ID
    descriptionId,
    // エラーメッセージに指定する ID
    errorMessageId,
  };
};
