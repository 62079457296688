/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { Ranking } from './Ranking';
import {
    RankingFromJSON,
    RankingFromJSONTyped,
    RankingToJSON,
} from './Ranking';

/**
 * 
 * @export
 * @interface GetRankingProducts200ResponseAllOfData
 */
export interface GetRankingProducts200ResponseAllOfData {
    /**
     * 
     * @type {Ranking}
     * @memberof GetRankingProducts200ResponseAllOfData
     */
    ranking: Ranking;
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetRankingProducts200ResponseAllOfData
     */
    products: Array<Product>;
}

/**
 * Check if a given object implements the GetRankingProducts200ResponseAllOfData interface.
 */
export function instanceOfGetRankingProducts200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ranking" in value;
    isInstance = isInstance && "products" in value;

    return isInstance;
}

export function GetRankingProducts200ResponseAllOfDataFromJSON(json: any): GetRankingProducts200ResponseAllOfData {
    return GetRankingProducts200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetRankingProducts200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRankingProducts200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ranking': RankingFromJSON(json['ranking']),
        'products': ((json['products'] as Array<any>).map(ProductFromJSON)),
    };
}

export function GetRankingProducts200ResponseAllOfDataToJSON(value?: GetRankingProducts200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ranking': RankingToJSON(value.ranking),
        'products': ((value.products as Array<any>).map(ProductToJSON)),
    };
}

