import classNames from "classnames";
import { useMemo } from "react";

import { Link } from "shared/components";
import { CFC } from "shared/types";

type Props = {
  image?: string;
  link?: string;
};

export const Item: CFC<Props> = ({
  image,
  link,
  className,
  children,
}) => {
  const content = useMemo(() => {
    return (
      <>
        {image && (
          <img
            className="object-cover w-full h-full"
            src={image}
          />
        )}
        {children}
      </>
    );
  }, [image]);

  return (
    <div className={classNames("h-full", className)}>
      {link ? (
        <Link href={link} className="w-full h-full">{content}</Link>
      ) : (
        content
      )}
    </div>
  );
};
