import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import classNames, { ArgumentArray } from "classnames";
import { useCallback, useEffect, useState } from "react";

import { BFC } from "shared/types";

type Props = {
  currentPage: number;
  totalPage: number;
  prevPage?: number;
  nextPage?: number;
  range?: number;
  gotoPage?: (page: number) => void;
  onChange?: (page: number) => void;
};

export const Paginate: BFC<Props> = ({
  currentPage,
  totalPage,
  prevPage,
  nextPage,
  range = 3,
  gotoPage,
  onChange,
  className,
}) => {
  const [current, setCurrent] = useState(currentPage);

  useEffect(() => {
    setCurrent(currentPage);
  }, [currentPage]);

  let i = 1;
  const pages: number[] = [current];
  const totalRange = range * 2 + 1;
  while (pages.length < totalRange && i < totalRange - 1) {
    const prev = current - i;
    if (prev > 0) pages.push(prev);
    const next = current + i;
    if (next <= totalPage) pages.push(next);
    i++;
  }

  pages.sort((a, b) => a - b);
  let first = pages[0];
  let last = pages[pages.length - 1];
  if (first === 1 && last + 1 < totalPage) {
    last = last + 1;
    pages.push(last);
  }
  if (last === totalPage && first - 1 > 1) {
    first = first - 1;
    pages.unshift(first);
  }

  const classFactory = useCallback((...args: ArgumentArray) => classNames(
    "w-9 h-8 flex justify-center items-center text-sm bg-white",
    ...args,
  ), []);

  const onClickFactory = useCallback((page: number) => () => {
    setCurrent(page);
    gotoPage?.(page);
    onChange?.(page);
  }, [gotoPage, onChange]);

  return (
    <div className={classNames("flex flex-wrap w-max border rounded divide-x overflow-hidden", className)}>
      {prevPage ? (
        <button className={classFactory()} onClick={onClickFactory(prevPage)}>
          <ChevronLeftIcon className="w-6" />
        </button>
      ) : (
        <div className={classFactory("text-gray-300")}>
          <ChevronLeftIcon className="w-6" />
        </div>
      )}
      {first > 1 && (
        <button className={classFactory()} onClick={onClickFactory(1)}>{1}</button>
      )}
      {first > 2 && (
        <div className={classFactory()}>
          <EllipsisHorizontalIcon className="w-6" />
        </div>
      )}
      {pages.map((page) => page === current ? (
        <div key={page} className={classFactory("bg-primary text-white font-bold")}>{page}</div>
      ) : (
        <button key={page} className={classFactory()} onClick={onClickFactory(page)}>{page}</button>
      ))}
      {last < totalPage - 1 && (
        <div className={classFactory()}>
          <EllipsisHorizontalIcon className="w-6 text-gray-400" />
        </div>
      )}
      {last < totalPage && (
        <button className={classFactory()} onClick={onClickFactory(totalPage)}>{totalPage}</button>
      )}
      {nextPage ? (
        <button className={classFactory()} onClick={onClickFactory(nextPage)}>
          <ChevronRightIcon className="w-6" />
        </button>
      ) : (
        <div className={classFactory("text-gray-300")}>
          <ChevronRightIcon className="w-6" />
        </div>
      )}
    </div>
  );
};
