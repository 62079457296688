import { DeepPartial } from "react-hook-form";
import { z } from "zod";

import {
  Button,
  CheckboxControlGroup,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

import { routes } from "~/constants";

const schema = z.object({
  user: z.object({
    lastName: z.string().min(1),
    firstName: z.string().min(1),
    zipCode: z.string().min(1).regex(/^\d{7}$/, "形式が正しくありません"),
    emailMarketingAgreed: z.boolean(),
  })
});

export type OAuthSignUpData = z.infer<typeof schema>;

const defaultValues: DeepPartial<OAuthSignUpData> = {
  user: {
    emailMarketingAgreed: true,
  },
};

export const OAuthSignUpForm = createHookForm<OAuthSignUpData>(({
  formState: { isSubmitting },
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <TextControlGroup name="user.lastName" label="姓" placeholder="ソックトック" required className="flex-1" />
        <TextControlGroup name="user.firstName" label="名" placeholder="太郎" required className="flex-1" />
      </div>
      <TextControlGroup name="user.zipCode" label="郵便番号" note="例: 1234567" required />
      <CheckboxControlGroup name="user.emailMarketingAgreed" inputLabel="メールマガジンの受信に同意する" />
      <div className="text-sm">
        <a href={routes.DOCUMENTS_PRIVACY_POLICY} target="_blank" rel="noreferrer">プライバシーポリシー</a>と
        <a href={routes.DOCUMENTS_TERMS_OF_SERVICE} target="_blank" rel="noreferrer">利用規約</a>に同意の上ご登録ください
      </div>
      <Button type="submit" block primary large disabled={isSubmitting}>
        同意して会員登録する
      </Button>
    </div>
  );
}, {
  schema,
  defaultValues,
});
