/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCheckoutRequestParcelsInnerItemsInner
 */
export interface CreateCheckoutRequestParcelsInnerItemsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestParcelsInnerItemsInner
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCheckoutRequestParcelsInnerItemsInner
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestParcelsInnerItemsInner
     */
    alternativeProductId?: string;
}

/**
 * Check if a given object implements the CreateCheckoutRequestParcelsInnerItemsInner interface.
 */
export function instanceOfCreateCheckoutRequestParcelsInnerItemsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;

    return isInstance;
}

export function CreateCheckoutRequestParcelsInnerItemsInnerFromJSON(json: any): CreateCheckoutRequestParcelsInnerItemsInner {
    return CreateCheckoutRequestParcelsInnerItemsInnerFromJSONTyped(json, false);
}

export function CreateCheckoutRequestParcelsInnerItemsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutRequestParcelsInnerItemsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'quantity': json['quantity'],
        'alternativeProductId': !exists(json, 'alternative_product_id') ? undefined : json['alternative_product_id'],
    };
}

export function CreateCheckoutRequestParcelsInnerItemsInnerToJSON(value?: CreateCheckoutRequestParcelsInnerItemsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'quantity': value.quantity,
        'alternative_product_id': value.alternativeProductId,
    };
}

