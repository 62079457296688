import classNames from "classnames";
import { forwardRef, Ref, ReactNode } from "react";

export type CheckboxInputProps = {
  label?: string | ReactNode;
  labelClassName?: string;
  containerClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInput = forwardRef(function CheckboxInput({
  type,
  label,
  className,
  labelClassName,
  containerClassName,
  ...props
}: CheckboxInputProps, ref: Ref<HTMLInputElement>) {
  const classes = classNames(
    "rounded border-gray-300 shadow-sm mr-1 -mt-1 form-checkbox text-primary",
    "disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none",
    className,
  );

  return (
    <div className={classNames(containerClassName)}>
      <label className={labelClassName}>
        <input
          {...props}
          ref={ref}
          type="checkbox"
          className={classes}
        />
        {label}
      </label>
    </div>
  );
});
