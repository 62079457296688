/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDeliveryParcelRequestDeliveryParcel
 */
export interface CreateDeliveryParcelRequestDeliveryParcel {
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryParcelRequestDeliveryParcel
     */
    checkoutParcelId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeliveryParcelRequestDeliveryParcel
     */
    deliveryOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeliveryParcelRequestDeliveryParcel
     */
    deliveryDriverId?: string;
}

/**
 * Check if a given object implements the CreateDeliveryParcelRequestDeliveryParcel interface.
 */
export function instanceOfCreateDeliveryParcelRequestDeliveryParcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkoutParcelId" in value;
    isInstance = isInstance && "deliveryOrder" in value;

    return isInstance;
}

export function CreateDeliveryParcelRequestDeliveryParcelFromJSON(json: any): CreateDeliveryParcelRequestDeliveryParcel {
    return CreateDeliveryParcelRequestDeliveryParcelFromJSONTyped(json, false);
}

export function CreateDeliveryParcelRequestDeliveryParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryParcelRequestDeliveryParcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkoutParcelId': json['checkout_parcel_id'],
        'deliveryOrder': json['delivery_order'],
        'deliveryDriverId': !exists(json, 'delivery_driver_id') ? undefined : json['delivery_driver_id'],
    };
}

export function CreateDeliveryParcelRequestDeliveryParcelToJSON(value?: CreateDeliveryParcelRequestDeliveryParcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkout_parcel_id': value.checkoutParcelId,
        'delivery_order': value.deliveryOrder,
        'delivery_driver_id': value.deliveryDriverId,
    };
}

