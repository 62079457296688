/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorForbiddenAllOfError } from './ResponseErrorForbiddenAllOfError';
import {
    ResponseErrorForbiddenAllOfErrorFromJSON,
    ResponseErrorForbiddenAllOfErrorFromJSONTyped,
    ResponseErrorForbiddenAllOfErrorToJSON,
} from './ResponseErrorForbiddenAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorForbiddenAllOf
 */
export interface ResponseErrorForbiddenAllOf {
    /**
     * 
     * @type {ResponseErrorForbiddenAllOfError}
     * @memberof ResponseErrorForbiddenAllOf
     */
    error: ResponseErrorForbiddenAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorForbiddenAllOf interface.
 */
export function instanceOfResponseErrorForbiddenAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorForbiddenAllOfFromJSON(json: any): ResponseErrorForbiddenAllOf {
    return ResponseErrorForbiddenAllOfFromJSONTyped(json, false);
}

export function ResponseErrorForbiddenAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorForbiddenAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorForbiddenAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorForbiddenAllOfToJSON(value?: ResponseErrorForbiddenAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorForbiddenAllOfErrorToJSON(value.error),
    };
}

