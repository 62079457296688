/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductShippingRate } from './ProductShippingRate';
import {
    ProductShippingRateFromJSON,
    ProductShippingRateFromJSONTyped,
    ProductShippingRateToJSON,
} from './ProductShippingRate';

/**
 * 
 * @export
 * @interface GetProductShippingRates200ResponseAllOfData
 */
export interface GetProductShippingRates200ResponseAllOfData {
    /**
     * 
     * @type {Array<ProductShippingRate>}
     * @memberof GetProductShippingRates200ResponseAllOfData
     */
    shippingRates: Array<ProductShippingRate>;
}

/**
 * Check if a given object implements the GetProductShippingRates200ResponseAllOfData interface.
 */
export function instanceOfGetProductShippingRates200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shippingRates" in value;

    return isInstance;
}

export function GetProductShippingRates200ResponseAllOfDataFromJSON(json: any): GetProductShippingRates200ResponseAllOfData {
    return GetProductShippingRates200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetProductShippingRates200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductShippingRates200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingRates': ((json['shipping_rates'] as Array<any>).map(ProductShippingRateFromJSON)),
    };
}

export function GetProductShippingRates200ResponseAllOfDataToJSON(value?: GetProductShippingRates200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_rates': ((value.shippingRates as Array<any>).map(ProductShippingRateToJSON)),
    };
}

