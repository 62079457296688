/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import { ComponentType, ReactNode, ComponentProps } from "react";

import { BFC } from "../../../types";
import { DatePickerInput } from "../DatePickerInput";
import { ErrorMessage } from "../ErrorMessage";
import { useInputAriaIds } from "../hooks";

type BaseInputGroupProps<T extends ComponentType<any>> = {
  label?: ReactNode;
  error?: string;
  note?: string;
  required?: boolean;
  input: T;
  inputClassName?: string;
  inputContainerClassName?: string;
  labelContainerClassName?: string;
  className?: string;
} & ComponentProps<T>;

export type BaseDatePickerInputGroupProps = BaseInputGroupProps<typeof DatePickerInput>;
export type BaseAnyInputGroupProps = BaseInputGroupProps<ComponentType>;

export type Props = BaseDatePickerInputGroupProps | BaseAnyInputGroupProps;

export const ControlledInputGroup: BFC<Props> = (
  {
    label,
    error,
    required,
    note,
    input: Input,
    inputClassName: _inputClassName,
    inputContainerClassName,
    labelContainerClassName,
    className,
    ...props
  },
) => {
  const inputClassName = classNames(
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none",
    _inputClassName,
  );

  const { inputId, inputAriaProps, descriptionId, errorMessageId } =
    useInputAriaIds({ required, note, error });

  return (
    <div className={classNames(className)}>
      {(label || required) && (
        <div className={classNames("flex items-center gap-2 mb-2", labelContainerClassName)}>
          {label && (
            <label className="text-sm font-semibold text-gray-700" htmlFor={inputId}>{label}</label>
          )}
          {required && (
            <span className="text-xs bg-primary text-white rounded py-px px-1">必須</span>
          )}
        </div>
      )}
      <div className={inputContainerClassName}>
        <Input id={inputId} {...inputAriaProps} {...props as any} className={inputClassName} />
      </div>
      {error && <ErrorMessage id={errorMessageId} message={error} hideIcon />}
      {note && (
        <div id={descriptionId} className="mt-2 text-sm text-gray-500">{note}</div>
      )}
    </div>
  );
};
/* eslint-enable @typescript-eslint/no-explicit-any */
