import { immerable } from "immer";

import * as api from "../services/api/models";
import { PaymentMethod, PaymentMethodType } from "./payment_method";

export type PaymentType = api.Payment;

export const PaymentStatus = api.PaymentStatusEnum;
export type PaymentStatus = api.PaymentStatusEnum;

export class Payment implements PaymentType {
  [immerable] = true;

  id = "";
  stripePaymentId = "";
  stripeClientSecret = "";
  capturedAmount = 0;
  paymentMethod: PaymentMethodType = new PaymentMethod();
  paidAt?: Date;
  expiresAt?: Date;
  capturesAt?: Date;
  capturedAt?: Date;
  cancelesAt?: Date;
  canceledAt?: Date;
  status: PaymentStatus = PaymentStatus.Pending;

  constructor(data: Partial<PaymentType> = {}) {
    Object.assign(this, data);
  }

  getPaymentMethod() {
    return new PaymentMethod(this.paymentMethod);
  }

  isFailed() {
    return this.status === PaymentStatus.Failed;
  }
}
