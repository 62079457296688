/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordRequestUser
 */
export interface ResetPasswordRequestUser {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestUser
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestUser
     */
    passwordConfirmation: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordRequestUser
     */
    resetPasswordToken: string;
}

/**
 * Check if a given object implements the ResetPasswordRequestUser interface.
 */
export function instanceOfResetPasswordRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "passwordConfirmation" in value;
    isInstance = isInstance && "resetPasswordToken" in value;

    return isInstance;
}

export function ResetPasswordRequestUserFromJSON(json: any): ResetPasswordRequestUser {
    return ResetPasswordRequestUserFromJSONTyped(json, false);
}

export function ResetPasswordRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': json['password'],
        'passwordConfirmation': json['password_confirmation'],
        'resetPasswordToken': json['reset_password_token'],
    };
}

export function ResetPasswordRequestUserToJSON(value?: ResetPasswordRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'password_confirmation': value.passwordConfirmation,
        'reset_password_token': value.resetPasswordToken,
    };
}

