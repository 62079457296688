/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullfillmentShipping } from './FullfillmentShipping';
import {
    FullfillmentShippingFromJSON,
    FullfillmentShippingFromJSONTyped,
    FullfillmentShippingToJSON,
} from './FullfillmentShipping';

/**
 * 
 * @export
 * @interface GetCheckoutFullfillmentShippings200ResponseAllOfData
 */
export interface GetCheckoutFullfillmentShippings200ResponseAllOfData {
    /**
     * 
     * @type {Array<FullfillmentShipping>}
     * @memberof GetCheckoutFullfillmentShippings200ResponseAllOfData
     */
    fullfillmentShippings: Array<FullfillmentShipping>;
}

/**
 * Check if a given object implements the GetCheckoutFullfillmentShippings200ResponseAllOfData interface.
 */
export function instanceOfGetCheckoutFullfillmentShippings200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullfillmentShippings" in value;

    return isInstance;
}

export function GetCheckoutFullfillmentShippings200ResponseAllOfDataFromJSON(json: any): GetCheckoutFullfillmentShippings200ResponseAllOfData {
    return GetCheckoutFullfillmentShippings200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckoutFullfillmentShippings200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutFullfillmentShippings200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullfillmentShippings': ((json['fullfillment_shippings'] as Array<any>).map(FullfillmentShippingFromJSON)),
    };
}

export function GetCheckoutFullfillmentShippings200ResponseAllOfDataToJSON(value?: GetCheckoutFullfillmentShippings200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullfillment_shippings': ((value.fullfillmentShippings as Array<any>).map(FullfillmentShippingToJSON)),
    };
}

