var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6b239bd040a30c72a5425a671dd4e43cb77da7bd"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://1fd54abd099a4135ab3f3b947225968c@o4504729758859264.ingest.sentry.io/4504733663428608",
  enabled: ["production", "staging"].includes(process.env.NEXT_PUBLIC_SENTRY_ENV || "development"),
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || "development",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
