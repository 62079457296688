import { BookmarkSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { PaymentMethod } from "shared/models";
import { BFC } from "shared/types";

import { MaskedCardDetail } from "~/components/elements";

type Props = {
  paymentMethod: PaymentMethod;
  isDefault?: boolean;
};

export const PaymentMethodListItem: BFC<Props> = ({
  paymentMethod,
  isDefault = false,
  className,
}) => {
  return (
    <div className={classNames("bg-white flex flex-col gap-1", className)}>
      <div>
        <MaskedCardDetail brand={paymentMethod.card.brand} last4={paymentMethod.card.last4} />
      </div>
      <div>
        {paymentMethod.card.expMonth}/{paymentMethod.card.expYear}
      </div>
      {isDefault && (
        <div className="mt-1">
          <div className="text-black-500 rounded flex items-center">
            <BookmarkSquareIcon className="w-4 mr-1" />
            いつも使用するお支払い方法
          </div>
        </div>
      )}
    </div>
  );
};
