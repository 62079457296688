import { useMemo } from "react";

import { CFC } from "shared/types";

import { useCartContext } from "~/features/carts";
import { BirthDateRegisteredRoute } from "~/features/users";

/**
 * カート内の商品に酒類が含まれる場合のみ、生年月日の登録を強制する
 */
export const NonAlcoholicOrBirthDateRegisteredRoute: CFC = ({ children }) => {
  const { cart } = useCartContext();

  const containsAlcoholicProduct = useMemo(() => {
    return cart.items.some((item) => item.product.isAlcoholic);
  }, [cart.items]);

  if (containsAlcoholicProduct) {
    return <BirthDateRegisteredRoute>{children}</BirthDateRegisteredRoute>;
  }

  return <>{children}</>;
};
