export * from "./Body";
export * from "./Col";
export * from "./Head";
export * from "./Row";

import classNames from "classnames";

import { CFC } from "shared/types";

import { Body } from "./Body";
import { Col } from "./Col";
import { Head } from "./Head";
import { Row } from "./Row";

type CFCWithTable = CFC & {
  Body: typeof Body;
  Col: typeof Col;
  Head: typeof Head;
  Row: typeof Row;
}

export const Table: CFCWithTable = ({
  className,
  children,
}) => {
  return (
    <table className={classNames(className)}>
      {children}
    </table>
  );
};

Table.Body = Body;
Table.Col = Col;
Table.Head = Head;
Table.Row = Row;
