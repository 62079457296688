/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethodCard
 */
export interface PaymentMethodCard {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCard
     */
    brand: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCard
     */
    expMonth: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodCard
     */
    expYear: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodCard
     */
    last4: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodCard
     */
    threeDSecureSupported?: boolean;
}

/**
 * Check if a given object implements the PaymentMethodCard interface.
 */
export function instanceOfPaymentMethodCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "expMonth" in value;
    isInstance = isInstance && "expYear" in value;
    isInstance = isInstance && "last4" in value;

    return isInstance;
}

export function PaymentMethodCardFromJSON(json: any): PaymentMethodCard {
    return PaymentMethodCardFromJSONTyped(json, false);
}

export function PaymentMethodCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethodCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': json['brand'],
        'expMonth': json['exp_month'],
        'expYear': json['exp_year'],
        'last4': json['last4'],
        'threeDSecureSupported': !exists(json, 'three_d_secure_supported') ? undefined : json['three_d_secure_supported'],
    };
}

export function PaymentMethodCardToJSON(value?: PaymentMethodCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': value.brand,
        'exp_month': value.expMonth,
        'exp_year': value.expYear,
        'last4': value.last4,
        'three_d_secure_supported': value.threeDSecureSupported,
    };
}

