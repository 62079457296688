import { useCallback, useEffect, useMemo, useState } from "react";

import { Product } from "shared/models";

import { CartItemOptions, useCartContext } from "~/features/carts";

export const AddToCartButtonState = {
  Disabled: "Disabled",
  Active: "Active",
  Loading: "Loading",
  Success: "Success",
} as const;

export type AddToCartButtonState =
  typeof AddToCartButtonState[keyof typeof AddToCartButtonState];

// カートに商品を追加した後に表示するメッセージの表示時間
const SUCCESS_MESSAGE_DURATION = 1200;

type UseAddToCartButtonOptions = {
  product: Product;
};

export const useAddToCartButton = ({ product }: UseAddToCartButtonOptions) => {
  const {
    addCartItem,
    isLoading: isCartLoading,
    isMutating,
  } = useCartContext();

  // 商品をカートに追加後にメッセージを表示するためのフラグ
  const [isRightAfterCartItemAdded, setIsRightAfterCartItemAdded] =
    useState(false);

  // フラグを SUCCESS_MESSAGE_DURATION 秒後にリセットする
  useEffect(() => {
    if (!isRightAfterCartItemAdded) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsRightAfterCartItemAdded(false);
    }, SUCCESS_MESSAGE_DURATION);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRightAfterCartItemAdded, setIsRightAfterCartItemAdded]);

  const handleClick = useCallback(async (options?: CartItemOptions) => {
    await addCartItem(product.id, options);
    setIsRightAfterCartItemAdded(true);
  }, [addCartItem, product.id]);

  const buttonState = useMemo(() => {
    if (product.isInactive() || product.sellableQuantity === 0) {
      return AddToCartButtonState.Disabled;
    }

    if (isCartLoading || isMutating) {
      return AddToCartButtonState.Loading;
    }

    if (isRightAfterCartItemAdded) {
      return AddToCartButtonState.Success;
    }

    return AddToCartButtonState.Active;
  }, [product, isCartLoading, isMutating, isRightAfterCartItemAdded]);

  const isAvailable = useMemo(() => {
    return buttonState !== AddToCartButtonState.Disabled && buttonState !== AddToCartButtonState.Loading;
  }, [buttonState]);

  return {
    handleClick,
    buttonState,
    isAvailable,
  };
};
