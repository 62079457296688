/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorInvalidRecordAllOfErrorValidations } from './ResponseErrorInvalidRecordAllOfErrorValidations';
import {
    ResponseErrorInvalidRecordAllOfErrorValidationsFromJSON,
    ResponseErrorInvalidRecordAllOfErrorValidationsFromJSONTyped,
    ResponseErrorInvalidRecordAllOfErrorValidationsToJSON,
} from './ResponseErrorInvalidRecordAllOfErrorValidations';

/**
 * 
 * @export
 * @interface ResponseErrorInvalidRecordAllOfError
 */
export interface ResponseErrorInvalidRecordAllOfError {
    /**
     * 
     * @type {Array<ResponseErrorInvalidRecordAllOfErrorValidations>}
     * @memberof ResponseErrorInvalidRecordAllOfError
     */
    validations: Array<ResponseErrorInvalidRecordAllOfErrorValidations>;
    /**
     * 
     * @type {string}
     * @memberof ResponseErrorInvalidRecordAllOfError
     */
    code: ResponseErrorInvalidRecordAllOfErrorCodeEnum;
}


/**
 * @export
 */
export const ResponseErrorInvalidRecordAllOfErrorCodeEnum = {
    InvalidRecord: 'invalid_record'
} as const;
export type ResponseErrorInvalidRecordAllOfErrorCodeEnum = typeof ResponseErrorInvalidRecordAllOfErrorCodeEnum[keyof typeof ResponseErrorInvalidRecordAllOfErrorCodeEnum];


/**
 * Check if a given object implements the ResponseErrorInvalidRecordAllOfError interface.
 */
export function instanceOfResponseErrorInvalidRecordAllOfError(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "validations" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function ResponseErrorInvalidRecordAllOfErrorFromJSON(json: any): ResponseErrorInvalidRecordAllOfError {
    return ResponseErrorInvalidRecordAllOfErrorFromJSONTyped(json, false);
}

export function ResponseErrorInvalidRecordAllOfErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorInvalidRecordAllOfError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'validations': ((json['validations'] as Array<any>).map(ResponseErrorInvalidRecordAllOfErrorValidationsFromJSON)),
        'code': json['code'],
    };
}

export function ResponseErrorInvalidRecordAllOfErrorToJSON(value?: ResponseErrorInvalidRecordAllOfError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'validations': ((value.validations as Array<any>).map(ResponseErrorInvalidRecordAllOfErrorValidationsToJSON)),
        'code': value.code,
    };
}

