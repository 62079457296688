/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsumeStockRequestStock
 */
export interface ConsumeStockRequestStock {
    /**
     * 
     * @type {number}
     * @memberof ConsumeStockRequestStock
     */
    consumeContentsQuantity?: number;
}

/**
 * Check if a given object implements the ConsumeStockRequestStock interface.
 */
export function instanceOfConsumeStockRequestStock(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsumeStockRequestStockFromJSON(json: any): ConsumeStockRequestStock {
    return ConsumeStockRequestStockFromJSONTyped(json, false);
}

export function ConsumeStockRequestStockFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumeStockRequestStock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'consumeContentsQuantity': !exists(json, 'consume_contents_quantity') ? undefined : json['consume_contents_quantity'],
    };
}

export function ConsumeStockRequestStockToJSON(value?: ConsumeStockRequestStock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'consume_contents_quantity': value.consumeContentsQuantity,
    };
}

