/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCheckoutTimelines200ResponseAllOfData } from './GetCheckoutTimelines200ResponseAllOfData';
import {
    GetCheckoutTimelines200ResponseAllOfDataFromJSON,
    GetCheckoutTimelines200ResponseAllOfDataFromJSONTyped,
    GetCheckoutTimelines200ResponseAllOfDataToJSON,
} from './GetCheckoutTimelines200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCheckoutTimelines200ResponseAllOf
 */
export interface GetCheckoutTimelines200ResponseAllOf {
    /**
     * 
     * @type {GetCheckoutTimelines200ResponseAllOfData}
     * @memberof GetCheckoutTimelines200ResponseAllOf
     */
    data: GetCheckoutTimelines200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCheckoutTimelines200ResponseAllOf interface.
 */
export function instanceOfGetCheckoutTimelines200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCheckoutTimelines200ResponseAllOfFromJSON(json: any): GetCheckoutTimelines200ResponseAllOf {
    return GetCheckoutTimelines200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCheckoutTimelines200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutTimelines200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCheckoutTimelines200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCheckoutTimelines200ResponseAllOfToJSON(value?: GetCheckoutTimelines200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCheckoutTimelines200ResponseAllOfDataToJSON(value.data),
    };
}

