export function formatNumber(
  number: number,
  options?: Intl.NumberFormatOptions
): string {
  return new Intl.NumberFormat("ja-JP", options).format(number);
}

export function formatPrice(
  number: number,
): string {
  return `${formatNumber(number)}円`;
}

export function formatZipCode(zipCode: string): string {
  return zipCode.replace(/(\d{3})(\d{4})/, "$1-$2");
}

export function formatCardBrand(brand: string): string {
  switch (brand) {
    case "visa":
      return "Visa";
    case "mastercard":
      return "MasterCard";
    case "jcb":
      return "JCB";
    case "amex":
      return "American Express";
    case "diners":
      return "Diners Club";
    case "discover":
      return "Discover";
    case "unionpay":
      return "UnionPay";
    default:
      return brand;
  }
}
