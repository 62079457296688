import { useMutation } from "@tanstack/react-query";

import { useStoreAPI } from "shared/services/api";
import { ResetPasswordRequest, SendResetMailRequest } from "shared/services/api/store";

export const usePasswordReset = () => {
  const api = useStoreAPI();

  const { mutate: sendResetMail, isLoading: isSendLoading } = useMutation(
    ["users/password/new"],
    (data: SendResetMailRequest) => api.sendResetMail(data),
  );

  const { mutate: resetPassword, isLoading: isResetLoading } = useMutation(
    ["users/password/edit"],
    (data: ResetPasswordRequest) => api.resetPassword(data),
  );

  return {
    sendResetMail,
    resetPassword,
    isSendLoading,
    isResetLoading,
  };
};
