/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProduct } from './CostcoProduct';
import {
    CostcoProductFromJSON,
    CostcoProductFromJSONTyped,
    CostcoProductToJSON,
} from './CostcoProduct';
import type { StockConsumptionWithCheckout } from './StockConsumptionWithCheckout';
import {
    StockConsumptionWithCheckoutFromJSON,
    StockConsumptionWithCheckoutFromJSONTyped,
    StockConsumptionWithCheckoutToJSON,
} from './StockConsumptionWithCheckout';

/**
 * コストコ商品の在庫
 * @export
 * @interface Stock
 */
export interface Stock {
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    id: string;
    /**
     * 
     * @type {CostcoProduct}
     * @memberof Stock
     */
    costcoProduct: CostcoProduct;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    stockupItemId?: string;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockedContentsQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    status: StockStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    stockedAt?: Date;
    /**
     * 実際に記録された消費期限
     * @type {Date}
     * @memberof Stock
     */
    expiresAt?: Date;
    /**
     * 過去のサーベイデータから推測した消費期限。入庫日不明の場合は推測不可なので未定義
     * @type {Date}
     * @memberof Stock
     */
    estimatedExpiresAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    discardedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<StockConsumptionWithCheckout>}
     * @memberof Stock
     */
    stockConsumptions: Array<StockConsumptionWithCheckout>;
}


/**
 * @export
 */
export const StockStatusEnum = {
    Stocked: 'stocked',
    Consumed: 'consumed',
    Discarded: 'discarded'
} as const;
export type StockStatusEnum = typeof StockStatusEnum[keyof typeof StockStatusEnum];


/**
 * Check if a given object implements the Stock interface.
 */
export function instanceOfStock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "costcoProduct" in value;
    isInstance = isInstance && "stockedContentsQuantity" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "stockConsumptions" in value;

    return isInstance;
}

export function StockFromJSON(json: any): Stock {
    return StockFromJSONTyped(json, false);
}

export function StockFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'costcoProduct': CostcoProductFromJSON(json['costco_product']),
        'stockupItemId': !exists(json, 'stockup_item_id') ? undefined : json['stockup_item_id'],
        'stockedContentsQuantity': json['stocked_contents_quantity'],
        'status': json['status'],
        'stockedAt': !exists(json, 'stocked_at') ? undefined : (new Date(json['stocked_at'])),
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'estimatedExpiresAt': !exists(json, 'estimated_expires_at') ? undefined : (new Date(json['estimated_expires_at'])),
        'discardedAt': !exists(json, 'discarded_at') ? undefined : (new Date(json['discarded_at'])),
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'stockConsumptions': ((json['stock_consumptions'] as Array<any>).map(StockConsumptionWithCheckoutFromJSON)),
    };
}

export function StockToJSON(value?: Stock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'costco_product': CostcoProductToJSON(value.costcoProduct),
        'stockup_item_id': value.stockupItemId,
        'stocked_contents_quantity': value.stockedContentsQuantity,
        'status': value.status,
        'stocked_at': value.stockedAt === undefined ? undefined : (value.stockedAt.toISOString()),
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'estimated_expires_at': value.estimatedExpiresAt === undefined ? undefined : (value.estimatedExpiresAt.toISOString()),
        'discarded_at': value.discardedAt === undefined ? undefined : (value.discardedAt.toISOString()),
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'stock_consumptions': ((value.stockConsumptions as Array<any>).map(StockConsumptionWithCheckoutToJSON)),
    };
}

