/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorInvalidRecordAllOfError } from './ResponseErrorInvalidRecordAllOfError';
import {
    ResponseErrorInvalidRecordAllOfErrorFromJSON,
    ResponseErrorInvalidRecordAllOfErrorFromJSONTyped,
    ResponseErrorInvalidRecordAllOfErrorToJSON,
} from './ResponseErrorInvalidRecordAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorInvalidRecord
 */
export interface ResponseErrorInvalidRecord {
    /**
     * 
     * @type {ResponseErrorInvalidRecordAllOfError}
     * @memberof ResponseErrorInvalidRecord
     */
    error: ResponseErrorInvalidRecordAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorInvalidRecord interface.
 */
export function instanceOfResponseErrorInvalidRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorInvalidRecordFromJSON(json: any): ResponseErrorInvalidRecord {
    return ResponseErrorInvalidRecordFromJSONTyped(json, false);
}

export function ResponseErrorInvalidRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorInvalidRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorInvalidRecordAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorInvalidRecordToJSON(value?: ResponseErrorInvalidRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorInvalidRecordAllOfErrorToJSON(value.error),
    };
}

