/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckoutFullfilledShippingAllOfData } from './TimelineCheckoutFullfilledShippingAllOfData';
import {
    TimelineCheckoutFullfilledShippingAllOfDataFromJSON,
    TimelineCheckoutFullfilledShippingAllOfDataFromJSONTyped,
    TimelineCheckoutFullfilledShippingAllOfDataToJSON,
} from './TimelineCheckoutFullfilledShippingAllOfData';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledShippingAllOf
 */
export interface TimelineCheckoutFullfilledShippingAllOf {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledShippingAllOf
     */
    event: TimelineCheckoutFullfilledShippingAllOfEventEnum;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof TimelineCheckoutFullfilledShippingAllOf
     */
    initiator: TimelineInitiator;
    /**
     * 
     * @type {TimelineCheckoutFullfilledShippingAllOfData}
     * @memberof TimelineCheckoutFullfilledShippingAllOf
     */
    data: TimelineCheckoutFullfilledShippingAllOfData;
}


/**
 * @export
 */
export const TimelineCheckoutFullfilledShippingAllOfEventEnum = {
    FullfilledShipping: 'fullfilled_shipping'
} as const;
export type TimelineCheckoutFullfilledShippingAllOfEventEnum = typeof TimelineCheckoutFullfilledShippingAllOfEventEnum[keyof typeof TimelineCheckoutFullfilledShippingAllOfEventEnum];


/**
 * Check if a given object implements the TimelineCheckoutFullfilledShippingAllOf interface.
 */
export function instanceOfTimelineCheckoutFullfilledShippingAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledShippingAllOfFromJSON(json: any): TimelineCheckoutFullfilledShippingAllOf {
    return TimelineCheckoutFullfilledShippingAllOfFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledShippingAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledShippingAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': json['event'],
        'initiator': TimelineInitiatorFromJSON(json['initiator']),
        'data': TimelineCheckoutFullfilledShippingAllOfDataFromJSON(json['data']),
    };
}

export function TimelineCheckoutFullfilledShippingAllOfToJSON(value?: TimelineCheckoutFullfilledShippingAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': value.event,
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': TimelineCheckoutFullfilledShippingAllOfDataToJSON(value.data),
    };
}

