import { useState, useCallback } from "react";

export const useBooleanState = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const setTrue = useCallback(() => {
    setValue(true);
  }, []);

  const setFalse = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value]);

  return [value, setTrue, setFalse, toggle] as const;
};

export const useBooleanToggleState = (initialState = false) => {
  const [value, setValue] = useState(initialState);

  const toggle = useCallback(() => {
    setValue(!value);
  }, [value]);

  return [value, toggle] as const;
};
