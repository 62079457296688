import { isSameDay as isSameDayFns } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import ja from "date-fns/locale/ja";

type Options = {
  locale?: Locale;
};

export function newCurrentDate() {
  const current = new Date();
  return new Date(current.getFullYear(), current.getMonth(), current.getDate(), 0, current.getTimezoneOffset() * -1);
}

export function formatInTokyo(date: Date, format: string, options?: Options): string {
  return formatInTimeZone(date, "Asia/Tokyo", format, { locale: ja, ...options });
}

export function formatDate(date: Date, format = "yyyy年MM月dd日") {
  return formatInTokyo(date, format, { locale: ja });
}

export function formatDateTime(date: Date, format = "yyyy年MM月dd日 HH:mm") {
  return formatInTokyo(date, format);
}

export function formatWeekday(date: Date) {
  return formatInTokyo(date, "E");
}

export function isSameDay(a: Date | null | undefined, b: Date | null | undefined) {
  if (!a || !b) return false;

  return isSameDayFns(a, b);
}

/**
 * 日付文字列から Date オブジェクトを生成する
 * タイムゾーン変換は行わない
 *
 * @param dateString yyyy-MM-dd 形式の文字列
 * @returns Date オブジェクト
 */
export function getDateByDateString(dateString: string): Date {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    throw new Error(
      `Invalid date string: ${dateString}. Expected format: yyyy-MM-dd`
    );
  }

  // UTC タイムゾーン指定を付与することで、ローカルタイムゾーンでの解釈を防ぐ
  return new Date(`${dateString}T00:00:00Z`);
}

/**
 * year, month, day から Date オブジェクトを生成する
 * タイムゾーン変換は行わない
 */
export function getDateByYearMonthDay(
  year: number | string,
  month: number | string,
  day: number | string
): Date {
  return getDateByDateString(getDateStringByYearMonthDay(year, month, day));
}

/**
 * year, month, day から yyyy-MM-dd 形式の文字列を生成する
 */
export function getDateStringByYearMonthDay(
  year: number | string,
  month: number | string,
  day: number | string
): string {
  const monthWithZeroPadding = month.toString().padStart(2, "0");
  const dayWithZeroPadding = day.toString().padStart(2, "0");
  return `${year}-${monthWithZeroPadding}-${dayWithZeroPadding}`;
}
