import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { UserMe } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useCurrentUser = () => {
  const { token, user } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });

  const { data, isLoading, refetch } = useQuery(["users/me", user.id], () => api.getCurrentUser(), {
    enabled: !!user.id,
  });

  const currentUser = useMemo(() => new UserMe(data?.data.user), [data]);

  return {
    currentUser,
    isLoading,
    refetch,
  };
};
