/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDirectUploadRequestBlob } from './CreateDirectUploadRequestBlob';
import {
    CreateDirectUploadRequestBlobFromJSON,
    CreateDirectUploadRequestBlobFromJSONTyped,
    CreateDirectUploadRequestBlobToJSON,
} from './CreateDirectUploadRequestBlob';

/**
 * 
 * @export
 * @interface CreateDirectUploadRequest
 */
export interface CreateDirectUploadRequest {
    /**
     * 
     * @type {CreateDirectUploadRequestBlob}
     * @memberof CreateDirectUploadRequest
     */
    blob: CreateDirectUploadRequestBlob;
}

/**
 * Check if a given object implements the CreateDirectUploadRequest interface.
 */
export function instanceOfCreateDirectUploadRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blob" in value;

    return isInstance;
}

export function CreateDirectUploadRequestFromJSON(json: any): CreateDirectUploadRequest {
    return CreateDirectUploadRequestFromJSONTyped(json, false);
}

export function CreateDirectUploadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDirectUploadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blob': CreateDirectUploadRequestBlobFromJSON(json['blob']),
    };
}

export function CreateDirectUploadRequestToJSON(value?: CreateDirectUploadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blob': CreateDirectUploadRequestBlobToJSON(value.blob),
    };
}

