import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { CostcoProduct, CostcoProductType } from "./costco_product";
import { StockConsumptionWithCheckout } from "./stock_consumption";

export type StockType = api.Stock;

export type StockStatus = api.StockStatusEnum;
export const StockStatus = api.StockStatusEnum;

// 在庫レコードの作成種別
export const StockCreationSource = {
  Manual: "manual", // 手動で作成
  Stockup: "stockup", // 仕入から自動作成
} as const;

export type StockCreationSource = typeof StockCreationSource[keyof typeof StockCreationSource];

export class Stock implements StockType {
  [immerable] = true;

  id = "";
  costcoProduct: CostcoProductType = new CostcoProduct();
  stockedContentsQuantity = 0;
  status: StockStatus = StockStatus.Stocked;
  stockedAt?: Date;
  expiresAt?: Date;
  estimatedExpiresAt?: Date;
  discardedAt?: Date;
  stockupItemId?: string;
  createdAt: Date = new Date();
  updatedAt: Date = new Date();
  stockConsumptions: api.StockConsumptionWithCheckout[] = [];

  constructor(data: Partial<StockType> = {}) {
    Object.assign(this, data);
  }

  getCostcoProduct() {
    return new CostcoProduct(this.costcoProduct);
  }

  getStockConsumptions() {
    return this.stockConsumptions.map((stockConsumption) => new StockConsumptionWithCheckout(stockConsumption));
  }

  get isStocked() {
    return this.status === StockStatus.Stocked;
  }

  get isConsumed() {
    return this.status === StockStatus.Consumed;
  }

  get isDiscarded() {
    return this.status === StockStatus.Discarded;
  }

  get isUpdatable() {
    return this.isStocked;
  }

  get creationSource(): StockCreationSource {
    return this.stockupItemId ? StockCreationSource.Stockup : StockCreationSource.Manual;
  }
}
