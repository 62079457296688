/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorNotFoundAllOfError } from './ResponseErrorNotFoundAllOfError';
import {
    ResponseErrorNotFoundAllOfErrorFromJSON,
    ResponseErrorNotFoundAllOfErrorFromJSONTyped,
    ResponseErrorNotFoundAllOfErrorToJSON,
} from './ResponseErrorNotFoundAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorNotFoundAllOf
 */
export interface ResponseErrorNotFoundAllOf {
    /**
     * 
     * @type {ResponseErrorNotFoundAllOfError}
     * @memberof ResponseErrorNotFoundAllOf
     */
    error: ResponseErrorNotFoundAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorNotFoundAllOf interface.
 */
export function instanceOfResponseErrorNotFoundAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorNotFoundAllOfFromJSON(json: any): ResponseErrorNotFoundAllOf {
    return ResponseErrorNotFoundAllOfFromJSONTyped(json, false);
}

export function ResponseErrorNotFoundAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorNotFoundAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorNotFoundAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorNotFoundAllOfToJSON(value?: ResponseErrorNotFoundAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorNotFoundAllOfErrorToJSON(value.error),
    };
}

