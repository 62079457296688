/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDeliveryDriver201ResponseAllOfData } from './CreateDeliveryDriver201ResponseAllOfData';
import {
    CreateDeliveryDriver201ResponseAllOfDataFromJSON,
    CreateDeliveryDriver201ResponseAllOfDataFromJSONTyped,
    CreateDeliveryDriver201ResponseAllOfDataToJSON,
} from './CreateDeliveryDriver201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateDeliveryDriver201ResponseAllOf
 */
export interface CreateDeliveryDriver201ResponseAllOf {
    /**
     * 
     * @type {CreateDeliveryDriver201ResponseAllOfData}
     * @memberof CreateDeliveryDriver201ResponseAllOf
     */
    data: CreateDeliveryDriver201ResponseAllOfData;
}

/**
 * Check if a given object implements the CreateDeliveryDriver201ResponseAllOf interface.
 */
export function instanceOfCreateDeliveryDriver201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CreateDeliveryDriver201ResponseAllOfFromJSON(json: any): CreateDeliveryDriver201ResponseAllOf {
    return CreateDeliveryDriver201ResponseAllOfFromJSONTyped(json, false);
}

export function CreateDeliveryDriver201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryDriver201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateDeliveryDriver201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CreateDeliveryDriver201ResponseAllOfToJSON(value?: CreateDeliveryDriver201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateDeliveryDriver201ResponseAllOfDataToJSON(value.data),
    };
}

