/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalculateCheckoutRequestParcelsInner } from './CalculateCheckoutRequestParcelsInner';
import {
    CalculateCheckoutRequestParcelsInnerFromJSON,
    CalculateCheckoutRequestParcelsInnerFromJSONTyped,
    CalculateCheckoutRequestParcelsInnerToJSON,
} from './CalculateCheckoutRequestParcelsInner';

/**
 * 
 * @export
 * @interface CalculateCheckoutRequest
 */
export interface CalculateCheckoutRequest {
    /**
     * 
     * @type {Array<CalculateCheckoutRequestParcelsInner>}
     * @memberof CalculateCheckoutRequest
     */
    parcels: Array<CalculateCheckoutRequestParcelsInner>;
    /**
     * 
     * @type {string}
     * @memberof CalculateCheckoutRequest
     */
    couponCode?: string;
}

/**
 * Check if a given object implements the CalculateCheckoutRequest interface.
 */
export function instanceOfCalculateCheckoutRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parcels" in value;

    return isInstance;
}

export function CalculateCheckoutRequestFromJSON(json: any): CalculateCheckoutRequest {
    return CalculateCheckoutRequestFromJSONTyped(json, false);
}

export function CalculateCheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateCheckoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parcels': ((json['parcels'] as Array<any>).map(CalculateCheckoutRequestParcelsInnerFromJSON)),
        'couponCode': !exists(json, 'coupon_code') ? undefined : json['coupon_code'],
    };
}

export function CalculateCheckoutRequestToJSON(value?: CalculateCheckoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parcels': ((value.parcels as Array<any>).map(CalculateCheckoutRequestParcelsInnerToJSON)),
        'coupon_code': value.couponCode,
    };
}

