import classNames from "classnames";

import { CFC } from "shared/types";

export const Body: CFC = ({
  className,
  children,
}) => {
  return (
    <tbody className={classNames("divide-y bg-white", className)}>
      {children}
    </tbody>
  );
};
