import { immerable } from "immer";

import * as api from "../services/api/survey/models";
import { ProductImage, ProductImageType } from "./product_image";

export const CostcoProductStorageTemperature = api.CostcoProductStorageTemperatureEnum;
export type CostcoProductStorageTemperature = typeof CostcoProductStorageTemperature[keyof typeof CostcoProductStorageTemperature];

export const CostcoProductKind = api.CostcoProductKindEnum;
export type CostcoProductKind = typeof CostcoProductKind[keyof typeof CostcoProductKind];

/**
 * コストコ商品の種類によって税率を返す
 */
export function getTaxPercentageByKind(kind: CostcoProductKind) {
  if (kind === CostcoProductKind.Food) {
    return 8;
  }
  return 10;
}

export type CostcoProductType = api.CostcoProduct;

export class CostcoProduct implements Omit<CostcoProductType, "surveyedAt"> {
  [immerable] = true;

  id = "";
  name = "";
  nameEn = "";
  sku = "";
  storageTemperature: CostcoProductStorageTemperature = CostcoProductStorageTemperature.Normal;
  contentsQuantity = 1;
  kind: CostcoProductKind = CostcoProductKind.Food;
  isPerWeight = false;
  avgExpiryDays = 0;
  minExpiryDays = 0;
  maxExpiryDays = 0;
  pickingMemo = "";
  stockedContentsQuantity = 0;
  standardPurchasePrice?: number;
  image?: ProductImageType;
  surveyedAt?: Date;

  constructor(data: Partial<CostcoProductType> = {}) {
    Object.assign(this, data);
  }

  getImage() {
    return new ProductImage(this.image);
  }

  isFood() {
    return this.kind === CostcoProductKind.Food;
  }

  isDailyNecessities() {
    return this.kind === CostcoProductKind.DailyNecessities;
  }

  isAlcoholic() {
    return this.kind === CostcoProductKind.Alcoholic;
  }

  taxPercentage() {
   return getTaxPercentageByKind(this.kind);
  }
}
