import Head from "next/head";
import { useRouter } from "next/router";
import { createContext, useContext } from "react";

import { BFC, CFC } from "shared/types";

type Props = {
  title?: string;
  description?: string;
  url?: string;
  path?: string;
  image?: string;
};

const baseTitle = "SocToc (ソックトック) | コストコ専門ネットスーパーサービス";
const baseDescription = [
  "SocToc（ソックトック）はコストコ専門のネットスーパーサービスです。",
  "コストコで人気の精肉、鮮魚、デリ、パン、ケーキなどの生鮮食品はもちろん、",
  "常温・冷凍食品、日用品などを、スマホで注文いただき時間帯指定でご自宅までお届けします。",
  "また、大容量すぎて購入が難しいベーカリーやデリ、調味料、日用品なども小分けで購入することができます。",
].join("");
const baseOrigin = process.env.NEXT_PUBLIC_STORE_ORIGIN;

export const OgpContext = createContext({
  title: baseTitle,
  description: baseDescription,
  origin: baseOrigin,
});

type ProviderProps = {
  title?: string;
  description?: string;
  origin?: string;
};

export const OgpProvider: CFC<ProviderProps> = ({
  title = baseTitle,
  description = baseDescription,
  origin = baseOrigin,
  children,
}) => {
  return (
    <OgpContext.Provider value={{ title, description, origin }}>
      {children}
    </OgpContext.Provider>
  );
};

export const Ogp: BFC<Props> = ({
  title,
  url,
  path,
  image = "/ogp/default.png",
  description,
}) => {
  const { title: baseTitle, description: baseDescription, origin } = useContext(OgpContext);
  const router = useRouter();
  const fullTitle = [title, baseTitle].filter(Boolean).join(" | ");
  const fullDescription = description || baseDescription;
  const fullUrl = url ?? origin + (path ?? router.asPath);
  const imageUrl = image.startsWith("http") ? image : origin + image;

  return (
    <Head>
      <title key="title">{fullTitle}</title>
      <meta key="description" name="description" content={description} />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:url" property="og:url" content={fullUrl} />
      <meta key="og:title" property="og:title" content={fullTitle} />
      <meta key="og:description" property="og:description" content={fullDescription} />
      <meta key="og:image" property="og:image" content={imageUrl} />
      <meta key="twitter:url" property="twitter:url" content={fullUrl} />
      <meta key="twitter:title" property="twitter:title" content={fullTitle} />
      <meta key="twitter:description" property="twitter:description" content={fullDescription} />
      <meta key="twitter:image" property="twitter:image" content={imageUrl} />
      <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
    </Head>
  );
};
