/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateDeliveryDriverRequestDeliveryDriver } from './UpdateDeliveryDriverRequestDeliveryDriver';
import {
    UpdateDeliveryDriverRequestDeliveryDriverFromJSON,
    UpdateDeliveryDriverRequestDeliveryDriverFromJSONTyped,
    UpdateDeliveryDriverRequestDeliveryDriverToJSON,
} from './UpdateDeliveryDriverRequestDeliveryDriver';

/**
 * 
 * @export
 * @interface UpdateDeliveryDriverRequest
 */
export interface UpdateDeliveryDriverRequest {
    /**
     * 
     * @type {UpdateDeliveryDriverRequestDeliveryDriver}
     * @memberof UpdateDeliveryDriverRequest
     */
    deliveryDriver: UpdateDeliveryDriverRequestDeliveryDriver;
}

/**
 * Check if a given object implements the UpdateDeliveryDriverRequest interface.
 */
export function instanceOfUpdateDeliveryDriverRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryDriver" in value;

    return isInstance;
}

export function UpdateDeliveryDriverRequestFromJSON(json: any): UpdateDeliveryDriverRequest {
    return UpdateDeliveryDriverRequestFromJSONTyped(json, false);
}

export function UpdateDeliveryDriverRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeliveryDriverRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryDriver': UpdateDeliveryDriverRequestDeliveryDriverFromJSON(json['delivery_driver']),
    };
}

export function UpdateDeliveryDriverRequestToJSON(value?: UpdateDeliveryDriverRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_driver': UpdateDeliveryDriverRequestDeliveryDriverToJSON(value.deliveryDriver),
    };
}

