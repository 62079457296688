import { useMutation } from "@tanstack/react-query";
import produce from "immer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Address, Cart, Checkout, PaymentMethod, Product, ProductShippingRate, UserMe } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { CalculateCheckoutRequest, CreateCheckoutRequest } from "shared/services/api/store";
import { isAPIError } from "shared/types";

import { useIsDeliverableAddress } from "~/features/addresses";
import { useAuthContext } from "~/features/auth";
import { useCartContext } from "~/features/carts";
import { useAddresses, usePaymentMethods } from "~/features/users";

import { useShippingParcels } from "./useShippingParcels";
import { useStripeContext } from "./useStripeContext";

enum ReadyState {
  NOT_INITIALIZED,
  INITIALIZING,
  READY,
};

export const useCheckoutService = () => {
  const { token, user } = useAuthContext();
  const { cart, clearCart, updateCartItem, isLoading: isCartLoading } = useCartContext();
  const { defaultAddress, isLoading: isAddressesLoading } = useAddresses();
  const { defaultPaymentMethod, isLoading: isPaymentMethodsLoading } = usePaymentMethods();
  const { parcels, makeParcel, updateParcel, removeParcel, clearParcels, updateParcelsItem } = useShippingParcels();
  const { stripe } = useStripeContext();
  const [address, setAddress] = useState<Address | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null);
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [checkout, setCheckout] = useState<Checkout>(new Checkout());
  const [agreedToRedeliveryCommissionRate, setAgreedToRedeliveryCommissionRate] = useState(false);
  const [isAddressInitialized, setIsAddressInitialized] = useState(false);
  const [isExecuteProccessing, setExecuteProccessing] = useState(false);
  const [isFailedPayment, setIsFailedPayment] = useState(false);
  const { isDeliverable } = useIsDeliverableAddress(address?.zipCode);
  const api = useStoreAPI({ accessToken: token });
  const isReady = useRef(ReadyState.NOT_INITIALIZED);
  const oldAddress = useRef(address);
  const oldIsUserCanBuyAlcohol = useRef(user.canBuyAlcohol);
  const [errorMessages, setErrorMessages] = useState<Map<string, string>>(new Map);
  const [isFailed, setIsFailed] = useState(false);

  const { mutateAsync: create, isLoading: isCreateCheckoutLoading } = useMutation(
    ["createCheckout", cart],
    (data: CreateCheckoutRequest) => api.createCheckout(data),
  );

  const { mutateAsync: calculateMutate, isLoading: isCalculateCheckoutLoading } = useMutation(
    ["calculateCheckout", cart],
    (data: CalculateCheckoutRequest) => api.calculateCheckout(data),
  );

  const { mutateAsync: completeMutate, isLoading: isCompleteCheckoutLoading } = useMutation(
    ["completeCheckout", cart],
    (id: string) => api.completeCheckout(id),
    {
      onSuccess: ({ data: { checkout } }) => {
        setCheckout(new Checkout(checkout));
      },
    },
  );

  const { mutateAsync: failMutate, isLoading: isFailCheckoutLoading } = useMutation(
    ["failCheckout", cart],
    (id: string) => api.failCheckout(id),
  );

  const execute = useCallback(async (onSucceeded?: () => void) => {
    if (address && paymentMethod && stripe) {
      setExecuteProccessing(true);
      try {
        // TODO: クーポンコード適用後に有効期限が切れるなどして決済に失敗するケースに対応する必要がある
        const { data: { payment, checkout: c } } = await create({
          shippingAddress: {
            zipCode: address.zipCode,
            prefectureId: address.prefecture.id,
            city: address.city,
            town: address.town,
            street: address.street,
            building: address.building,
            firstName: address.firstName,
            lastName: address.lastName,
            phone: address.phone,
            deliveryInstructions: address.deliveryInstructions,
          },
          paymentMethodId: paymentMethod.id,
          parcels: parcels.map((parcel) => ({
            shippingRateId: parcel.shippingRate?.id || "",
            shippingTimeId: parcel.shippingTime?.id || "",
            shippingDate: parcel.shippingDate || new Date(),
            items: parcel.items.map((item) => ({
              productId: item.product.id,
              quantity: item.quantity,
              alternativeProductId: item.alternativeProduct?.id,
            })),
          })),
          couponCode: checkout.couponCode?.code || "",
        });

        try {
          await stripe.confirmCardPayment(payment.stripeClientSecret, {
            payment_method: paymentMethod.stripePaymentMethodId,
          });
        } catch (error) {
          await failMutate(c.id);
          setIsFailedPayment(true);
          throw error;
        }

        await completeMutate(c.id);
        await clearCart();

        if (onSucceeded) {
          onSucceeded();
        }
      } catch (error) {
        setIsFailed(true);
        setExecuteProccessing(false);
      }
    }
  }, [create, completeMutate, checkout, address, paymentMethod, parcels, stripe]);

  const calculate = useCallback(async (couponCode?: string) => {
    const { data: { checkout: c } } = await calculateMutate({
      parcels: parcels.map((parcel) => ({
        shippingRateId: parcel.isDeliverable() ? parcel.shippingRate?.id : "",
        items: parcel.items.map((item) => ({
          productId: item.product.id,
          quantity: item.quantity,
          alternativeProductId: item.alternativeProduct?.id,
        })),
      })),
      couponCode,
    }, {
      onSuccess: () => {
        setCouponCode(couponCode || null);
        setErrorMessages(new Map);
      },
      onError: (e) => {
        if (isAPIError(e)) {
          e.response.clone().json().then((data) => {
            if (data.error.code === "invalid_record") {
              const tmpErrors = new Map;
              data.error.validations.forEach((v) => {
                const model = v.model.split("::").shift(); // MEMO: STIを考慮してモデル名だけを取り出す
                tmpErrors.set(`${model}.${v.key}`, v.message);
              });
              setErrorMessages(tmpErrors);
            }
            if (data.error.code === "not_found") {
              setErrorMessages(new Map([["couponCode.code", "クーポンコードが正しくありません"]]));
            }
          });
        }
      },
    });
    setCheckout((current) => produce(current, (draft) => {
      draft.subtotalPrice = c.subtotalPrice;
      draft.minSubtotalPrice = c.minSubtotalPrice;
      draft.maxSubtotalPrice = c.maxSubtotalPrice;
      draft.totalShippingRate = c.totalShippingRate;
      draft.discountPrice = c.discountPrice;
      draft.serviceCommission = c.serviceCommission;
      draft.smallCheckoutCommission = c.smallCheckoutCommission;
      draft.totalTax = c.totalTax;
      draft.minTotalTax = c.minTotalTax;
      draft.maxTotalTax = c.maxTotalTax;
      draft.totalPrice = c.totalPrice;
      draft.minTotalPrice = c.minTotalPrice;
      draft.maxTotalPrice = c.maxTotalPrice;
      draft.couponCode = c.couponCode;
      draft.redeliveryCommissionRate = c.redeliveryCommissionRate;
    }));
  }, [parcels]);

  const changeAlternativeProduct = useCallback((product: Product, alternativeProduct: Product | null) => {
    updateCartItem(product.id, {
      alternativeProductId: alternativeProduct?.id,
      removeAlternativeProduct: !alternativeProduct,
    });
  }, [updateCartItem]);

  const resetParcels = useCallback(async (cart: Cart, address: Address | null, user: UserMe) => {
    clearParcels();
    for (const item of cart.getItems()) {
      const { data: { shippingRates } } = await api.getProductShippingRates(item.product.id, address?.zipCode);
      const productShippingRates = shippingRates.map((shippingRate) => new ProductShippingRate(shippingRate));
      makeParcel(item.getProduct(), item.quantity, {
        alternativeProduct: item.getAlternativeProduct(),
        shippingRates: productShippingRates,
        user,
      });
    };
  }, []);

  const isAllParcelsDeliverable = useMemo(() => {
    return parcels.every((parcel) => parcel.isDeliverable());
  }, [parcels]);

  useEffect(() => {
    if (!isAddressesLoading) {
      if (defaultAddress && !isAddressInitialized) {
        setAddress(defaultAddress);
      }
      setIsAddressInitialized(true);
    }
  }, [defaultAddress, isAddressesLoading, isAddressInitialized]);

  useEffect(() => {
    if (defaultPaymentMethod && !paymentMethod) {
      setPaymentMethod(defaultPaymentMethod);
    }
  }, [defaultPaymentMethod, paymentMethod]);

  useEffect(() => {
    (async () => {
      if (!isCartLoading && isAddressInitialized && isReady.current === ReadyState.NOT_INITIALIZED) {
        isReady.current = ReadyState.INITIALIZING;
        oldAddress.current = address;
        oldIsUserCanBuyAlcohol.current = user.canBuyAlcohol;
        await resetParcels(cart, address, user);
        isReady.current = ReadyState.READY;
      }
    })();
  }, [cart, isCartLoading, isAddressInitialized, address, user]);

  useEffect(() => {
    (async () => {
      const hasAnyChange = oldAddress.current?.zipCode !== address?.zipCode || oldIsUserCanBuyAlcohol.current !== user.canBuyAlcohol;
      if (!isCartLoading && hasAnyChange && isReady.current === ReadyState.READY) {
        isReady.current = ReadyState.INITIALIZING;
        oldAddress.current = address;
        oldIsUserCanBuyAlcohol.current = user.canBuyAlcohol;
        await resetParcels(cart, address, user);
        isReady.current = ReadyState.READY;
      }
    })();
  }, [cart, isCartLoading, address, user]);

  useEffect(() => {
    if (isReady.current === ReadyState.READY) {
      for (const item of cart.getItems()) {
        updateParcelsItem(item.getProduct(), item.quantity, {
          alternativeProduct: item.getAlternativeProduct(),
        });
      }
    }
  }, [cart]);

  useEffect(() => {
    if (parcels.length > 0 && !isExecuteProccessing) {
      calculate(couponCode || undefined);
    }
  }, [parcels]);

  useEffect(() => {
    setAgreedToRedeliveryCommissionRate(false);
  }, [checkout.redeliveryCommissionRate]);

  const applyCouponCode = useCallback(async (couponCode: string) => {
    try {
      await calculate(couponCode);
    } catch (error) {
      console.log(error);
    }
  }, [calculate]);

  return {
    cart,
    checkout,
    address,
    setAddress,
    paymentMethod,
    setPaymentMethod,
    parcels,
    updateParcel,
    removeParcel,
    changeAlternativeProduct,
    couponCode,
    applyCouponCode,
    execute,
    agreedToRedeliveryCommissionRate,
    setAgreedToRedeliveryCommissionRate,
    isFailed,
    isDeliverable,
    isAllParcelsDeliverable,
    isReady: address && paymentMethod && isReady.current === ReadyState.READY,
    isLoading: isCartLoading || isAddressesLoading || isPaymentMethodsLoading || isCalculateCheckoutLoading,
    isInitialized: isReady.current === ReadyState.READY,
    isCheckoutProcessing: isCreateCheckoutLoading || isCompleteCheckoutLoading || isFailCheckoutLoading || isExecuteProccessing,
    isFailedPayment,
    errorMessages,
  };
};
