/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCheckoutRequestParcelsInnerItemsInner } from './CreateCheckoutRequestParcelsInnerItemsInner';
import {
    CreateCheckoutRequestParcelsInnerItemsInnerFromJSON,
    CreateCheckoutRequestParcelsInnerItemsInnerFromJSONTyped,
    CreateCheckoutRequestParcelsInnerItemsInnerToJSON,
} from './CreateCheckoutRequestParcelsInnerItemsInner';

/**
 * 
 * @export
 * @interface CreateCheckoutRequestParcelsInner
 */
export interface CreateCheckoutRequestParcelsInner {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestParcelsInner
     */
    shippingRateId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestParcelsInner
     */
    shippingTimeId: string;
    /**
     * 
     * @type {Date}
     * @memberof CreateCheckoutRequestParcelsInner
     */
    shippingDate: Date;
    /**
     * 
     * @type {Array<CreateCheckoutRequestParcelsInnerItemsInner>}
     * @memberof CreateCheckoutRequestParcelsInner
     */
    items: Array<CreateCheckoutRequestParcelsInnerItemsInner>;
}

/**
 * Check if a given object implements the CreateCheckoutRequestParcelsInner interface.
 */
export function instanceOfCreateCheckoutRequestParcelsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shippingRateId" in value;
    isInstance = isInstance && "shippingTimeId" in value;
    isInstance = isInstance && "shippingDate" in value;
    isInstance = isInstance && "items" in value;

    return isInstance;
}

export function CreateCheckoutRequestParcelsInnerFromJSON(json: any): CreateCheckoutRequestParcelsInner {
    return CreateCheckoutRequestParcelsInnerFromJSONTyped(json, false);
}

export function CreateCheckoutRequestParcelsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutRequestParcelsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingRateId': json['shipping_rate_id'],
        'shippingTimeId': json['shipping_time_id'],
        'shippingDate': (new Date(json['shipping_date'])),
        'items': ((json['items'] as Array<any>).map(CreateCheckoutRequestParcelsInnerItemsInnerFromJSON)),
    };
}

export function CreateCheckoutRequestParcelsInnerToJSON(value?: CreateCheckoutRequestParcelsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_rate_id': value.shippingRateId,
        'shipping_time_id': value.shippingTimeId,
        'shipping_date': (value.shippingDate.toISOString().substr(0,10)),
        'items': ((value.items as Array<any>).map(CreateCheckoutRequestParcelsInnerItemsInnerToJSON)),
    };
}

