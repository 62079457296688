/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LogisticsUser } from './LogisticsUser';
import {
    LogisticsUserFromJSON,
    LogisticsUserFromJSONTyped,
    LogisticsUserToJSON,
} from './LogisticsUser';

/**
 * 
 * @export
 * @interface GetUserSession200ResponseAllOfData
 */
export interface GetUserSession200ResponseAllOfData {
    /**
     * 
     * @type {LogisticsUser}
     * @memberof GetUserSession200ResponseAllOfData
     */
    user: LogisticsUser;
}

/**
 * Check if a given object implements the GetUserSession200ResponseAllOfData interface.
 */
export function instanceOfGetUserSession200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function GetUserSession200ResponseAllOfDataFromJSON(json: any): GetUserSession200ResponseAllOfData {
    return GetUserSession200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserSession200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSession200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': LogisticsUserFromJSON(json['user']),
    };
}

export function GetUserSession200ResponseAllOfDataToJSON(value?: GetUserSession200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': LogisticsUserToJSON(value.user),
    };
}

