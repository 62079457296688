import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { MdLocalShipping } from "react-icons/md";

import { Button, Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { AddressListItem, useAddresses } from "~/features/users";

export const AddressesIndexPage: BFC = () => {
  const { addresses, defaultAddress } = useAddresses();

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-5 py-6 text-lg">
          <MdLocalShipping  size={24} className="text-black-500" />
          お届け先一覧
        </h1>
      </div>

      <div className="bg-white">
        <div className="flex flex-col divide-y">
          {addresses.map((address) => (
            <div key={address.id} className="flex items-center gap-3 px-3">
              <AddressListItem
                key={address.id}
                address={address}
                isDefault={defaultAddress?.id === address.id}
                className="flex-1 p-2"
              />
              <div>
                <Link href={routes.MYPAGE_ADDRESSES_EDIT(address.id)}>
                  <Button small onClick={(e) => e.stopPropagation()}>修正</Button>
                </Link>
              </div>
            </div>
          ))}
          <Link href={routes.MYPAGE_ADDRESSES_NEW} noDecoration className="px-3 py-4 flex items-center justify-between">
            お届け先を追加する
            <ChevronRightIcon className="w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};