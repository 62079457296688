/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 画像
 * @export
 * @interface AttachableImage
 */
export interface AttachableImage {
    /**
     * 
     * @type {number}
     * @memberof AttachableImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof AttachableImage
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof AttachableImage
     */
    url: string;
}

/**
 * Check if a given object implements the AttachableImage interface.
 */
export function instanceOfAttachableImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;

    return isInstance;
}

export function AttachableImageFromJSON(json: any): AttachableImage {
    return AttachableImageFromJSONTyped(json, false);
}

export function AttachableImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttachableImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'url': json['url'],
    };
}

export function AttachableImageToJSON(value?: AttachableImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'url': value.url,
    };
}

