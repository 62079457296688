import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { Link } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  backTo?: string;
  title: string;
};

export const TitleHeader: BFC<Props> = ({
  title,
  backTo,
  className,
}) => {
  return (
    <div className={classNames("bg-white flex items-center relative", className)}>
      {backTo ? (
        <Link href={backTo} className="text-black-600 p-3">
          <ChevronLeftIcon className="h-6 w-6" />
        </Link>
      ) : (
        <div className="h-10 w-10" />
      )}
      <div className="absolute flex flex-1 items-center justify-center inset-x-10 inset-y-0">
        <h1 className="font-bold text-xl">
          {title}
        </h1>
      </div>
    </div>
  );
};
