import { immerable } from "immer";

import { DeleteFavoriteTypeEnum, FavoriteFavoritableTypeEnum } from "../services/api/store";
import * as api from "../services/api/store/models";
import { Product } from "./product";

export type FavoriteType = api.Favorite;

export type FavoritableType = api.FavoriteFavoritableTypeEnum;
export const FavoritableType = api.FavoriteFavoritableTypeEnum;

export type FavoritableTypePath = DeleteFavoriteTypeEnum;
export const FavoritableTypePath = DeleteFavoriteTypeEnum;

export class Favorite implements FavoriteType {
  [immerable] = true;

  favoritableType: FavoriteFavoritableTypeEnum =
    FavoriteFavoritableTypeEnum.Product;
  product?: Product = undefined;

  constructor(data: Partial<FavoriteType> = {}) {
    Object.assign(this, data);
  }
}
