/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stockup } from './Stockup';
import {
    StockupFromJSON,
    StockupFromJSONTyped,
    StockupToJSON,
} from './Stockup';

/**
 * 
 * @export
 * @interface RecreateStockups201ResponseAllOfData
 */
export interface RecreateStockups201ResponseAllOfData {
    /**
     * 
     * @type {Array<Stockup>}
     * @memberof RecreateStockups201ResponseAllOfData
     */
    stockups: Array<Stockup>;
}

/**
 * Check if a given object implements the RecreateStockups201ResponseAllOfData interface.
 */
export function instanceOfRecreateStockups201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockups" in value;

    return isInstance;
}

export function RecreateStockups201ResponseAllOfDataFromJSON(json: any): RecreateStockups201ResponseAllOfData {
    return RecreateStockups201ResponseAllOfDataFromJSONTyped(json, false);
}

export function RecreateStockups201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecreateStockups201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockups': ((json['stockups'] as Array<any>).map(StockupFromJSON)),
    };
}

export function RecreateStockups201ResponseAllOfDataToJSON(value?: RecreateStockups201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockups': ((value.stockups as Array<any>).map(StockupToJSON)),
    };
}

