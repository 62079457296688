import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import { Button } from "shared/components";
import { BFC } from "shared/types";

type Props = {
  title: string;
  description?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  open: boolean;
};

export const ConfirmModal: BFC<Props> = ({
  title,
  description,
  message,
  open,
  onConfirm,
  onCancel,
  confirmText = "OK",
  cancelText = "キャンセル",
}) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog open={open} onClose={onCancel} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-black/40 fixed inset-0" />
        </Transition.Child>
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="h-max bg-white rounded-lg p-4 w-full max-w-md">
              <Dialog.Title className="font-bold text-lg">{title}</Dialog.Title>
              {description && <Dialog.Description className="mt-4">{description}</Dialog.Description>}
              {message && <p>{message}</p>}

              <div className="flex gap-3 mt-6">
                <Button onClick={onCancel} block className="flex-1">{cancelText}</Button>
                <Button primary onClick={onConfirm} block className="flex-1">{confirmText}</Button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
