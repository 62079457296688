/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDeliverableAddresses200ResponseAllOfData } from './GetDeliverableAddresses200ResponseAllOfData';
import {
    GetDeliverableAddresses200ResponseAllOfDataFromJSON,
    GetDeliverableAddresses200ResponseAllOfDataFromJSONTyped,
    GetDeliverableAddresses200ResponseAllOfDataToJSON,
} from './GetDeliverableAddresses200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetDeliverableAddresses200ResponseAllOf
 */
export interface GetDeliverableAddresses200ResponseAllOf {
    /**
     * 
     * @type {GetDeliverableAddresses200ResponseAllOfData}
     * @memberof GetDeliverableAddresses200ResponseAllOf
     */
    data: GetDeliverableAddresses200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetDeliverableAddresses200ResponseAllOf interface.
 */
export function instanceOfGetDeliverableAddresses200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetDeliverableAddresses200ResponseAllOfFromJSON(json: any): GetDeliverableAddresses200ResponseAllOf {
    return GetDeliverableAddresses200ResponseAllOfFromJSONTyped(json, false);
}

export function GetDeliverableAddresses200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliverableAddresses200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetDeliverableAddresses200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetDeliverableAddresses200ResponseAllOfToJSON(value?: GetDeliverableAddresses200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetDeliverableAddresses200ResponseAllOfDataToJSON(value.data),
    };
}

