import { useMemo } from "react";
import { ColorRing } from "react-loader-spinner";
import { TwitterShareButton, TwitterIcon, FacebookShareButton, FacebookIcon, LineShareButton, LineIcon, HatenaShareButton, HatenaIcon } from "react-share";

import { MagazineArticleType } from "shared/models";
import { BFC } from "shared/types";

import { Article } from "../../components";
import { useMagazineArticle } from "../../hooks";

type Props = {
  slug: string;
  article: Partial<MagazineArticleType>;
};

export const MagazineArticleShowPage: BFC<Props> = ({
  slug,
  article: initialArticle,
}) => {
  const { article, isLoading } = useMagazineArticle(slug, initialArticle);

  const url = useMemo(() => {
    return process.env.NEXT_PUBLIC_STORE_ORIGIN + "/magazine/articles/" + slug;
  }, [slug]);

  if (isLoading) {
    return <div className="flex items-center justify-center h-full"><ColorRing /></div>;
  }

  return (
    <div>
      <div>
        <Article article={article} />
      </div>
      <div className="mt-4 p-4 bg-white">
        <div className="font-bold text-center">
          シェアする
        </div>
        <div className="mt-4 flex gap-8 justify-center">
          <FacebookShareButton
            url={url}
            className="flex flex-col gap-1 items-center justify-center w-16"
          >
            <FacebookIcon size={32} round />
            <div className="text-sm text-gray-500">
              Facebook
            </div>
          </FacebookShareButton>
          <TwitterShareButton
            url={url}
            title={article.title}
            hashtags={["SocToc", "コストコ専門ネットスーパー"]}
            className="flex flex-col gap-1 items-center justify-center w-16"
          >
            <TwitterIcon size={32} round />
            <div className="text-sm text-gray-500">
              X(Twitter)
            </div>
          </TwitterShareButton>
          <LineShareButton
            url={url}
            title={article.title}
            className="flex flex-col gap-1 items-center justify-center w-16"
          >
            <LineIcon size={32} round />
            <div className="text-sm text-gray-500">
              LINE
            </div>
          </LineShareButton>
          <HatenaShareButton
            url={url}
            title={article.title}
            className="flex flex-col gap-1 items-center justify-center w-16"
          >
            <HatenaIcon size={32} round />
            <div className="text-sm text-gray-500">
              はてな
            </div>
          </HatenaShareButton>
        </div>
      </div>
    </div>
  );
};
