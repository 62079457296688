import Link from "next/link";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { BFC } from "shared/types";

import { routes } from "~/constants";

import {
  NotificationSettings,
  NotificationType,
  useNotificationSettings,
} from "../../hooks";

export const NotificationSettingsPage: BFC = () => {
  const { currentSettings, update } = useNotificationSettings();

  const [formState, setFormState] =
    useState<NotificationSettings>(currentSettings);

  useEffect(() => {
    setFormState(currentSettings);
  }, [setFormState, currentSettings]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const type = e.target.name as NotificationType;
      const isEnabled = e.target.checked;

      // フォームの状態を更新
      setFormState((prev) => ({ ...prev, [type]: isEnabled }));

      // サーバーに永続化
      update(type, isEnabled);
    },
    [setFormState, update]
  );

  return (
    <div className="flex flex-col gap-4 pb-4">
      <Link
        href={routes.MYPAGE}
        className="flex items-center gap-2 font-bold px-4 py-6 text-lg bg-white"
      >
        <HiOutlineChevronLeft size={24} className="text-black-500" />
        お知らせの受信設定
      </Link>

      <div className="bg-white">
        <div className="p-4 space-y-4">
          <p>
            受け取りたい項目にチェックを入れてください。重要なお知らせは、設定に関わらずお送りする場合があります。
          </p>

          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="marketing"
                name={NotificationType.Marketing}
                aria-describedby="marketing-description"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                onChange={handleChange}
                checked={formState.marketing}
              />
            </div>
            <div className="ml-3 leading-6">
              <label htmlFor="marketing" className="font-medium text-gray-900">
                メールマガジン
              </label>
              <p id="marketing-description" className="text-gray-500">
                限定セールやメルマガ限定クーポン、新商品の追加などをお知らせします
              </p>
            </div>
          </div>

          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="cartDropReminder"
                name={NotificationType.CartDropReminder}
                aria-describedby="cart-drop-reminder-description"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                onChange={handleChange}
                checked={formState.cartDropReminder}
              />
            </div>
            <div className="ml-3 leading-6">
              <label
                htmlFor="cartDropReminder"
                className="font-medium text-gray-900"
              >
                お買い忘れお知らせメール
              </label>
              <p id="cart-drop-reminder-description" className="text-gray-500">
                カートに入れた商品を注文していない場合にお知らせします
              </p>
            </div>
          </div>

          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="couponNotification"
                name={NotificationType.CouponNotification}
                aria-describedby="coupon-notification-description"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                onChange={handleChange}
                checked={formState.couponNotification}
              />
            </div>
            <div className="ml-3 leading-6">
              <label
                htmlFor="couponNotification"
                className="font-medium text-gray-900"
              >
                クーポン獲得通知
              </label>
              <p id="coupon-notification-description" className="text-gray-500">
                クーポンを獲得した際にお知らせします
              </p>
            </div>
          </div>

          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="productReActivated"
                name={NotificationType.ProductReActivated}
                aria-describedby="coupon-notification-description"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                onChange={handleChange}
                checked={formState.productReActivated}
              />
            </div>
            <div className="ml-3 leading-6">
              <label
                htmlFor="productReActivated"
                className="font-medium text-gray-900"
              >
                商品の販売再開通知
              </label>
              <p id="coupon-notification-description" className="text-gray-500">
                お気に入りの商品の販売が再開された際にお知らせします
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
