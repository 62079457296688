import { useState } from "react";
import { z } from "zod";

import {
  Button,
  CheckboxControlGroup,
  SelectControlGroup,
  TextControlGroup,
} from "shared/components";
import { usePrefectures } from "shared/features/master";
import { useZipCodeAddressCompletion } from "shared/features/utils";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  userAddress: z.object({
    firstName: z.string().min(1),
    lastName: z.string().min(1),
    phone: z.string().min(1).regex(/^\d{10,11}$/, "形式が正しくありません"),
    zipCode: z.string().min(1).regex(/^\d{7}$/, "形式が正しくありません"),
    prefectureId: z.string().min(1),
    city: z.string().min(1),
    town: z.string().min(1),
    street: z.string().min(1),
    building: z.string().optional(),
    deliveryInstructions: z.string().optional(),
  }),
  isDefault: z.boolean().optional(),
});

export type AddressData = z.infer<typeof schema>;

type Props = {
  submitText?: string;
  showDefault?: boolean;
};

export const AddressForm = createHookForm<AddressData, Props>(({
  watch,
  setValue,
  formState: { isSubmitting },
  submitText = "登録する",
  showDefault,
}) => {
  const zipCode = watch("userAddress.zipCode");
  const { prefectures } = usePrefectures();
  const prefectureOptions = prefectures.map((prefecture) => ({ value: prefecture.id, label: prefecture.name }));
  const [currentZipCode, setCurrentZipCode] = useState(zipCode);

  useZipCodeAddressCompletion(zipCode, (address) => {
    if (currentZipCode === zipCode) return;

    setCurrentZipCode(zipCode);

    const prefectureId = prefectures.find((prefecture) => prefecture.name === address.prefecture)?.id;
    if (prefectureId) {
      setValue("userAddress.prefectureId", prefectureId);
    }
    setValue("userAddress.city", address.city);
    setValue("userAddress.town", address.town);
  });

  if (!prefectureOptions.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <TextControlGroup name="userAddress.lastName" label="姓" note="例: ソックトック" required className="flex-1" />
        <TextControlGroup name="userAddress.firstName" label="名" note="例: 太郎" required className="flex-1" />
      </div>
      <TextControlGroup name="userAddress.phone" label="電話番号" note="例: 08011112222" required />
      <div className="flex gap-3">
        <TextControlGroup
          name="userAddress.zipCode"
          label="郵便番号"
          note="例: 1234567"
          required
          className="flex-1"
        />
        <SelectControlGroup
          name="userAddress.prefectureId"
          label="都道府県"
          items={prefectureOptions}
          placeholder="都道府県"
          required
          className="flex-1"
        />
      </div>
      <TextControlGroup name="userAddress.city" label="市区町村" note="例: 渋谷区" required />
      <TextControlGroup name="userAddress.town" label="町名" note="例: 渋谷" required />
      <TextControlGroup name="userAddress.street" label="番地" note="例: 1-1-1" required />
      <TextControlGroup name="userAddress.building" label="建物名・部屋番号" note="例: 〇〇ビル 201号室" />
      <TextControlGroup name="userAddress.deliveryInstructions" label="配達員への伝達事項" note="例: 大きなサボテンが目印です" />
      {showDefault && <CheckboxControlGroup name="isDefault" inputLabel="いつも使用するお届け先にする" />}
      <Button type="submit" block primary large disabled={isSubmitting} className="mt-6">
        {submitText}
      </Button>
    </div>
  );
}, {
  schema,
});
