/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaymentMethodCard } from './PaymentMethodCard';
import {
    PaymentMethodCardFromJSON,
    PaymentMethodCardFromJSONTyped,
    PaymentMethodCardToJSON,
} from './PaymentMethodCard';

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    stripePaymentMethodId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    type: PaymentMethodTypeEnum;
    /**
     * 
     * @type {PaymentMethodCard}
     * @memberof PaymentMethod
     */
    card: PaymentMethodCard;
}


/**
 * @export
 */
export const PaymentMethodTypeEnum = {
    Card: 'card'
} as const;
export type PaymentMethodTypeEnum = typeof PaymentMethodTypeEnum[keyof typeof PaymentMethodTypeEnum];


/**
 * Check if a given object implements the PaymentMethod interface.
 */
export function instanceOfPaymentMethod(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "stripePaymentMethodId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "card" in value;

    return isInstance;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stripePaymentMethodId': json['stripe_payment_method_id'],
        'type': json['type'],
        'card': PaymentMethodCardFromJSON(json['card']),
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stripe_payment_method_id': value.stripePaymentMethodId,
        'type': value.type,
        'card': PaymentMethodCardToJSON(value.card),
    };
}

