/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateCheckoutRequestChangeDatetimeOperationsInner } from './UpdateCheckoutRequestChangeDatetimeOperationsInner';
import {
    UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSON,
    UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSONTyped,
    UpdateCheckoutRequestChangeDatetimeOperationsInnerToJSON,
} from './UpdateCheckoutRequestChangeDatetimeOperationsInner';
import type { UpdateCheckoutRequestRemoveItemOperationsInner } from './UpdateCheckoutRequestRemoveItemOperationsInner';
import {
    UpdateCheckoutRequestRemoveItemOperationsInnerFromJSON,
    UpdateCheckoutRequestRemoveItemOperationsInnerFromJSONTyped,
    UpdateCheckoutRequestRemoveItemOperationsInnerToJSON,
} from './UpdateCheckoutRequestRemoveItemOperationsInner';
import type { UpdateCheckoutRequestSetAlternativeProductOperationsInner } from './UpdateCheckoutRequestSetAlternativeProductOperationsInner';
import {
    UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSON,
    UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSONTyped,
    UpdateCheckoutRequestSetAlternativeProductOperationsInnerToJSON,
} from './UpdateCheckoutRequestSetAlternativeProductOperationsInner';
import type { UpdateCheckoutRequestSetManualDiscountPriceOperation } from './UpdateCheckoutRequestSetManualDiscountPriceOperation';
import {
    UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSON,
    UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSONTyped,
    UpdateCheckoutRequestSetManualDiscountPriceOperationToJSON,
} from './UpdateCheckoutRequestSetManualDiscountPriceOperation';

/**
 * 
 * @export
 * @interface UpdateCheckoutRequest
 */
export interface UpdateCheckoutRequest {
    /**
     * 
     * @type {Array<UpdateCheckoutRequestRemoveItemOperationsInner>}
     * @memberof UpdateCheckoutRequest
     */
    removeItemOperations?: Array<UpdateCheckoutRequestRemoveItemOperationsInner>;
    /**
     * 
     * @type {Array<UpdateCheckoutRequestSetAlternativeProductOperationsInner>}
     * @memberof UpdateCheckoutRequest
     */
    setAlternativeProductOperations?: Array<UpdateCheckoutRequestSetAlternativeProductOperationsInner>;
    /**
     * 
     * @type {Array<UpdateCheckoutRequestChangeDatetimeOperationsInner>}
     * @memberof UpdateCheckoutRequest
     */
    changeDatetimeOperations?: Array<UpdateCheckoutRequestChangeDatetimeOperationsInner>;
    /**
     * 
     * @type {UpdateCheckoutRequestSetManualDiscountPriceOperation}
     * @memberof UpdateCheckoutRequest
     */
    setManualDiscountPriceOperation?: UpdateCheckoutRequestSetManualDiscountPriceOperation;
}

/**
 * Check if a given object implements the UpdateCheckoutRequest interface.
 */
export function instanceOfUpdateCheckoutRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateCheckoutRequestFromJSON(json: any): UpdateCheckoutRequest {
    return UpdateCheckoutRequestFromJSONTyped(json, false);
}

export function UpdateCheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCheckoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'removeItemOperations': !exists(json, 'remove_item_operations') ? undefined : ((json['remove_item_operations'] as Array<any>).map(UpdateCheckoutRequestRemoveItemOperationsInnerFromJSON)),
        'setAlternativeProductOperations': !exists(json, 'set_alternative_product_operations') ? undefined : ((json['set_alternative_product_operations'] as Array<any>).map(UpdateCheckoutRequestSetAlternativeProductOperationsInnerFromJSON)),
        'changeDatetimeOperations': !exists(json, 'change_datetime_operations') ? undefined : ((json['change_datetime_operations'] as Array<any>).map(UpdateCheckoutRequestChangeDatetimeOperationsInnerFromJSON)),
        'setManualDiscountPriceOperation': !exists(json, 'set_manual_discount_price_operation') ? undefined : UpdateCheckoutRequestSetManualDiscountPriceOperationFromJSON(json['set_manual_discount_price_operation']),
    };
}

export function UpdateCheckoutRequestToJSON(value?: UpdateCheckoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'remove_item_operations': value.removeItemOperations === undefined ? undefined : ((value.removeItemOperations as Array<any>).map(UpdateCheckoutRequestRemoveItemOperationsInnerToJSON)),
        'set_alternative_product_operations': value.setAlternativeProductOperations === undefined ? undefined : ((value.setAlternativeProductOperations as Array<any>).map(UpdateCheckoutRequestSetAlternativeProductOperationsInnerToJSON)),
        'change_datetime_operations': value.changeDatetimeOperations === undefined ? undefined : ((value.changeDatetimeOperations as Array<any>).map(UpdateCheckoutRequestChangeDatetimeOperationsInnerToJSON)),
        'set_manual_discount_price_operation': UpdateCheckoutRequestSetManualDiscountPriceOperationToJSON(value.setManualDiscountPriceOperation),
    };
}

