import { z } from "zod";

import {
  Button,
  TextControlGroup,
  SelectControlGroup,
  ErrorMessage,
} from "shared/components";
import { formatCancelMembershipLogReason } from "shared/helpers";
import { createHookForm } from "shared/lib/hook-form";
import { CancelMembershipLogReason } from "shared/models";

export const schema = z.object({
  reason: z.enum([
    CancelMembershipLogReason.HighCommissions,
    CancelMembershipLogReason.NotFoundWanted,
    CancelMembershipLogReason.NotCoveredArea,
    CancelMembershipLogReason.No,
    CancelMembershipLogReason.Other,
  ], {
    errorMap: () => ({ message: "退会理由を選択してください" })
  }),
  description: z.string(),
}).refine(({ reason, description }) => !(reason === CancelMembershipLogReason.Other && !description), {
  message: "詳細を入力してください",
  path: ["description"],
});

export type CancelMembershipData = z.infer<typeof schema>;

export const CancelMembershipForm = createHookForm<CancelMembershipData>(({
  watch,
  submissionError,
  formState: { isSubmitting },
}) => {
  const reasons = [
    CancelMembershipLogReason.HighCommissions,
    CancelMembershipLogReason.NotFoundWanted,
    CancelMembershipLogReason.NotCoveredArea,
    CancelMembershipLogReason.No,
    CancelMembershipLogReason.Other,
  ].map((value) => ({ value, label: formatCancelMembershipLogReason(value) }));

  const cancelReason = watch("reason");
  const hasOtherReason = cancelReason === CancelMembershipLogReason.Other;

  return (
    <div className="flex flex-col gap-4">
      <SelectControlGroup
        name="reason"
        label="退会理由"
        placeholder="退会理由を選択"
        items={reasons}
        required
      />
      {hasOtherReason && <p>その他を選んだ方は、詳細をお書きください</p>}
      <TextControlGroup name="description" type="text" label="詳細" required={hasOtherReason} />
      {submissionError && <ErrorMessage message={submissionError} />}
      <Button type="submit" block primary large disabled={isSubmitting}>
        退会する
      </Button>
    </div>
  );
}, {
  schema,
});
