/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetReviews200ResponseAllOfData } from './GetReviews200ResponseAllOfData';
import {
    GetReviews200ResponseAllOfDataFromJSON,
    GetReviews200ResponseAllOfDataFromJSONTyped,
    GetReviews200ResponseAllOfDataToJSON,
} from './GetReviews200ResponseAllOfData';
import type { ResponseSuccessWithPagingAllOfMeta } from './ResponseSuccessWithPagingAllOfMeta';
import {
    ResponseSuccessWithPagingAllOfMetaFromJSON,
    ResponseSuccessWithPagingAllOfMetaFromJSONTyped,
    ResponseSuccessWithPagingAllOfMetaToJSON,
} from './ResponseSuccessWithPagingAllOfMeta';

/**
 * 
 * @export
 * @interface GetReviews200Response
 */
export interface GetReviews200Response {
    /**
     * 
     * @type {GetReviews200ResponseAllOfData}
     * @memberof GetReviews200Response
     */
    data: GetReviews200ResponseAllOfData;
    /**
     * 
     * @type {ResponseSuccessWithPagingAllOfMeta}
     * @memberof GetReviews200Response
     */
    meta: ResponseSuccessWithPagingAllOfMeta;
}

/**
 * Check if a given object implements the GetReviews200Response interface.
 */
export function instanceOfGetReviews200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetReviews200ResponseFromJSON(json: any): GetReviews200Response {
    return GetReviews200ResponseFromJSONTyped(json, false);
}

export function GetReviews200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReviews200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetReviews200ResponseAllOfDataFromJSON(json['data']),
        'meta': ResponseSuccessWithPagingAllOfMetaFromJSON(json['meta']),
    };
}

export function GetReviews200ResponseToJSON(value?: GetReviews200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetReviews200ResponseAllOfDataToJSON(value.data),
        'meta': ResponseSuccessWithPagingAllOfMetaToJSON(value.meta),
    };
}

