import { immerable } from "immer";

import { formatDate } from "../helpers";
import * as api from "../services/api/models";

export type ReviewType = api.Review;

export class Review implements ReviewType {
  [immerable] = true;

  id = "";
  productId = "";
  title = "";
  body = "";
  files: Array<api.AttachableImage> = [];
  createdAt = new Date();
  publishedAt = new Date();

  constructor(data: Partial<ReviewType> = {}) {
    Object.assign(this, data);
  }

  get createdAtFormatted() {
    return formatDate(this.createdAt, "yyyy年MM月dd日");
  }
}
