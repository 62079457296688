import { StockCreationSource, StockStatus } from "../../models";

export function formatStockStatus(status: StockStatus) {
  switch (status) {
    case StockStatus.Stocked:
      return "在庫";
    case StockStatus.Consumed:
      return "消費済";
    case StockStatus.Discarded:
      return "廃棄済";
  }
}

export function formatStockCreationSource(creationSource: StockCreationSource) {
  switch (creationSource) {
    case StockCreationSource.Manual:
      return "手動";
    case StockCreationSource.Stockup:
      return "仕入";
  }
}
