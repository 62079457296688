import { PhotoIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import Link from "next/link";
import { ReactNode } from "react";

import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ProductPrice } from "../ProductPrice";

type Props = {
  id: string;
  title: string;
  titleTop?: ReactNode;
  image?: string;
  smallImage?: boolean;
  price?: number;
  discountPrice?: number;
  taxPercentage?: number;
  quantity?: number;
  sellableQuantity?: number;
  action?: ReactNode;
  inactive?: boolean;
  clampTitle?: boolean;
};

export const ProductCardWideRaw: BFC<Props> = ({
  id,
  title,
  titleTop,
  image,
  smallImage,
  price,
  discountPrice,
  taxPercentage,
  quantity,
  sellableQuantity,
  action,
  className,
  inactive,
  clampTitle,
}) => {
  const imageClasses = classNames("border rounded aspect-square object-cover", {
    "w-28": !smallImage,
    "w-20": smallImage,
  });

  return (
    <div className={classNames("flex gap-3 items-start", className)}>
      <Link href={routes.PRODUCTS_SHOW(id)}>
        {image ? (
          <img
            src={image}
            className={imageClasses}
          />
        ) : (
          <div className="border rounded flex justify-center items-center aspect-square">
            <PhotoIcon className="text-black-200 w-8" />
          </div>
        )}
      </Link>
      <div className="flex flex-col gap-1 flex-1">
        {titleTop}
        <Link href={routes.PRODUCTS_SHOW(id)}>
          <h3 className={classNames({ "line-clamp-2": clampTitle })}>{title}</h3>
        </Link>
        {price !== undefined && (
          <ProductPrice
            price={price}
            discountPrice={discountPrice}
            taxPercentage={taxPercentage}
            className="font-bold"
          />
        )}
        {quantity ? <div>{quantity}個</div> : null }
        {typeof sellableQuantity === "number" && (
          <div className="text-md text-primary font-semibold">
            {sellableQuantity > 0 ? `残り${sellableQuantity}点` : "在庫がありません"}
          </div>
        )}
        {action}
        {inactive && <div className="text-red-700">この商品は販売終了しました</div>}
      </div>
    </div>
  );
};
