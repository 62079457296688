/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledStockingAllOfData
 */
export interface TimelineCheckoutFullfilledStockingAllOfData {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledStockingAllOfData
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledStockingAllOfData
     */
    alternativeProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledStockingAllOfData
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledStockingAllOfData
     */
    cancelQuantity: number;
}

/**
 * Check if a given object implements the TimelineCheckoutFullfilledStockingAllOfData interface.
 */
export function instanceOfTimelineCheckoutFullfilledStockingAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "cancelQuantity" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledStockingAllOfDataFromJSON(json: any): TimelineCheckoutFullfilledStockingAllOfData {
    return TimelineCheckoutFullfilledStockingAllOfDataFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledStockingAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledStockingAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'alternativeProductId': !exists(json, 'alternative_product_id') ? undefined : json['alternative_product_id'],
        'quantity': json['quantity'],
        'cancelQuantity': json['cancel_quantity'],
    };
}

export function TimelineCheckoutFullfilledStockingAllOfDataToJSON(value?: TimelineCheckoutFullfilledStockingAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'alternative_product_id': value.alternativeProductId,
        'quantity': value.quantity,
        'cancel_quantity': value.cancelQuantity,
    };
}

