import { ChangeEvent, useCallback, useMemo } from "react";
import { FieldValues, FieldPath, useFormContext, Controller } from "react-hook-form";

import { formatDate } from "shared/helpers";

import { TextInputGroup, TextInputGroupProps } from "../TextInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & TextInputGroupProps;

export const DateControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  ...props
}: Props<Data>) => {
  const { control, formState, getFieldState, watch, setValue } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

  const value = watch(name);

  const inputValue = useMemo(() => {
    if (typeof value === "string") {
      return value;
    } else if (value) {
      return formatDate(value, "yyyy-MM-dd");
    }
  }, [value]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(name, new Date(e.target.value) as FieldValues[typeof name]);
  }, [setValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextInputGroup
          {...props}
          {...field}
          onChange={onChange}
          type="date"
          value={inputValue}
          invalid={!!fieldState.error}
          error={fieldState.error?.message}
        />
      )}
    />
  );
};
