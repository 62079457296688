import classNames from "classnames";
import { useRouter } from "next/router";
import { useCallback } from "react";

import { Link, Button } from "shared/components";
import { formatCheckoutParcelRedeliveryStatus, formatCheckoutParcelShippingStatus, formatPrice } from "shared/helpers";
import { Checkout, CheckoutParcelDeliveryFailedReason, CheckoutStatus, Product } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { CheckoutCancelStatusBadge } from "~/features/checkouts";
import { ProductCardWideRaw } from "~/features/products";

type Props = {
  checkout: Checkout;
};

export const CheckoutHistoryCard: BFC<Props> = ({
  checkout,
  className,
}) => {
  const router = useRouter();

  const onClickFactory = useCallback((id: string) => () => {
    router.push(routes.CHECKOUTS_SHOW(id));
  }, []);

  const shippingTimeDecorator = useCallback((shippingTime: string) => {
    return (
      <span className="text-green-600 font-bold">{shippingTime}</span>
    );
  }, []);

  const redeliveryDateDecorator = useCallback((date: string) => {
    return (
      <span className="text-red-600 font-bold">{date}</span>
    );
  }, []);

  return (
    <div className={classNames(className)}>
      <div className="grid gap-2 bg-white p-4">
        <div className="grid grid-cols-1 gap-4 text-base font-medium leading-5 sm:grid-cols-4">
          <div>
            <h3 className="font-bold">注文日</h3>
            <div className="text-gray-500">{checkout.formatCheckedOutAt(true)}</div>
          </div>
          <div>
            <h3 className="font-bold">合計</h3>
            <div className="text-gray-500">
              {!checkout.isFixed() && checkout.minTotalPrice !== checkout.maxTotalPrice ?
                `${formatPrice(checkout.minTotalPrice)} ~ ${formatPrice(checkout.maxTotalPrice)}` :
                formatPrice(checkout.getFixedTotalPrice())}
            </div>
          </div>
          <div>
            <h3 className="font-bold">お届け先</h3>
            <div className="text-gray-500">
              <div className="grid">
                {checkout.shippingAddress.lastName} {checkout.shippingAddress.firstName}
                <div>
                  <span className="mr-1">{checkout.shippingAddress.city}</span>
                  <span className="mr-1">{checkout.shippingAddress.town}</span>
                  <span className="mr-1">{checkout.shippingAddress.street}</span>
                  {checkout.shippingAddress.building}
                </div>
              </div>
            </div>
          </div>
        </div>
        {checkout.getParcels().map((parcel) => (
          <div className="border-t border-gray-200 flex flex-col gap-5 mt-3 pt-3">
            <div key={parcel.id} className="grid gap-3">
              <h1 className="text-lg font-bold mt-1">
                {checkout.status === CheckoutStatus.Canceled ?
                  "キャンセル済みです" :
                  formatCheckoutParcelShippingStatus(parcel, {
                    decorator: shippingTimeDecorator,
                    billed: checkout.isBilled,
                  })
                }
                {parcel.redeliveryDate && parcel.deliveryFailedReason != CheckoutParcelDeliveryFailedReason.Pending && (
                  <div>
                    {formatCheckoutParcelRedeliveryStatus(parcel, { decorator: redeliveryDateDecorator })}
                  </div>
                )}
              </h1>
              {parcel.getFixedItems(checkout).map((item) => (
                <div className="flex flex-col gap-2" key={item.id}>
                  {((product: Product) => (
                    <Link href={routes.PRODUCTS_SHOW(product.id)}>
                      <ProductCardWideRaw
                        key={product.id}
                        id={product.id}
                        title={product.title}
                        image={product.image?.webp?.url ?? product.image?.url}
                        price={item.price}
                        discountPrice={item.discountPrice}
                        taxPercentage={item.taxPercentage}
                        quantity={item.quantity}
                        smallImage
                      />
                    </Link>
                  ))(item.getProduct())}
                  <CheckoutCancelStatusBadge item={item} />
                </div>
              ))}
            </div>
            <Button block onClick={onClickFactory(checkout.id)}>注文内容を表示</Button>
          </div>
        ))}
      </div>
    </div>
  );
};
