/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MultipassToken } from './MultipassToken';
import {
    MultipassTokenFromJSON,
    MultipassTokenFromJSONTyped,
    MultipassTokenToJSON,
} from './MultipassToken';

/**
 * 
 * @export
 * @interface CreateUserMultipassToken201ResponseAllOfData
 */
export interface CreateUserMultipassToken201ResponseAllOfData {
    /**
     * 
     * @type {MultipassToken}
     * @memberof CreateUserMultipassToken201ResponseAllOfData
     */
    multipassToken: MultipassToken;
}

/**
 * Check if a given object implements the CreateUserMultipassToken201ResponseAllOfData interface.
 */
export function instanceOfCreateUserMultipassToken201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "multipassToken" in value;

    return isInstance;
}

export function CreateUserMultipassToken201ResponseAllOfDataFromJSON(json: any): CreateUserMultipassToken201ResponseAllOfData {
    return CreateUserMultipassToken201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateUserMultipassToken201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserMultipassToken201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'multipassToken': MultipassTokenFromJSON(json['multipass_token']),
    };
}

export function CreateUserMultipassToken201ResponseAllOfDataToJSON(value?: CreateUserMultipassToken201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'multipass_token': MultipassTokenToJSON(value.multipassToken),
    };
}

