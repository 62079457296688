/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryDriver } from './DeliveryDriver';
import {
    DeliveryDriverFromJSON,
    DeliveryDriverFromJSONTyped,
    DeliveryDriverToJSON,
} from './DeliveryDriver';

/**
 * 
 * @export
 * @interface CreateDeliveryDriver201ResponseAllOfData
 */
export interface CreateDeliveryDriver201ResponseAllOfData {
    /**
     * 
     * @type {DeliveryDriver}
     * @memberof CreateDeliveryDriver201ResponseAllOfData
     */
    deliveryDriver: DeliveryDriver;
}

/**
 * Check if a given object implements the CreateDeliveryDriver201ResponseAllOfData interface.
 */
export function instanceOfCreateDeliveryDriver201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryDriver" in value;

    return isInstance;
}

export function CreateDeliveryDriver201ResponseAllOfDataFromJSON(json: any): CreateDeliveryDriver201ResponseAllOfData {
    return CreateDeliveryDriver201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateDeliveryDriver201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryDriver201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryDriver': DeliveryDriverFromJSON(json['delivery_driver']),
    };
}

export function CreateDeliveryDriver201ResponseAllOfDataToJSON(value?: CreateDeliveryDriver201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_driver': DeliveryDriverToJSON(value.deliveryDriver),
    };
}

