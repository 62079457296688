/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckoutFullfilledShippingAllOfDataItems } from './TimelineCheckoutFullfilledShippingAllOfDataItems';
import {
    TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSON,
    TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSONTyped,
    TimelineCheckoutFullfilledShippingAllOfDataItemsToJSON,
} from './TimelineCheckoutFullfilledShippingAllOfDataItems';

/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledShippingAllOfData
 */
export interface TimelineCheckoutFullfilledShippingAllOfData {
    /**
     * 
     * @type {number}
     * @memberof TimelineCheckoutFullfilledShippingAllOfData
     */
    shippingId: number;
    /**
     * 
     * @type {Array<TimelineCheckoutFullfilledShippingAllOfDataItems>}
     * @memberof TimelineCheckoutFullfilledShippingAllOfData
     */
    items?: Array<TimelineCheckoutFullfilledShippingAllOfDataItems>;
}

/**
 * Check if a given object implements the TimelineCheckoutFullfilledShippingAllOfData interface.
 */
export function instanceOfTimelineCheckoutFullfilledShippingAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shippingId" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledShippingAllOfDataFromJSON(json: any): TimelineCheckoutFullfilledShippingAllOfData {
    return TimelineCheckoutFullfilledShippingAllOfDataFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledShippingAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledShippingAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingId': json['shipping_id'],
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(TimelineCheckoutFullfilledShippingAllOfDataItemsFromJSON)),
    };
}

export function TimelineCheckoutFullfilledShippingAllOfDataToJSON(value?: TimelineCheckoutFullfilledShippingAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_id': value.shippingId,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(TimelineCheckoutFullfilledShippingAllOfDataItemsToJSON)),
    };
}

