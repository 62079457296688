import { CheckoutStatus, CheckoutStockingStatus, CheckoutShippingStatus } from "shared/models/checkout";

export function formatCheckoutStatus(status: CheckoutStatus) {
  switch (status) {
    case CheckoutStatus.Waiting:
      return "未払い";
    case CheckoutStatus.Paid:
      return "支払い済";
    case CheckoutStatus.Completed:
      return "完了";
    case CheckoutStatus.Canceled:
      return "キャンセル";
    case CheckoutStatus.Failed:
      return "失敗";
  }
}

export function formatCheckoutStockingStatus(status: CheckoutStockingStatus) {
  switch (status) {
    case CheckoutStockingStatus.Waiting:
      return "未仕入";
    case CheckoutStockingStatus.Partially:
      return "一部仕入済";
    case CheckoutStockingStatus.CompletedWithPartially:
      return "仕入済(キャンセル含む)";
    case CheckoutStockingStatus.Completed:
      return "仕入済";
    case CheckoutStockingStatus.Canceled:
      return "仕入キャンセル済み";
    case CheckoutStockingStatus.Pending:
      return "仕入保留中";
  }
}

export function formatCheckoutShippingStatus(status: CheckoutShippingStatus) {
  switch (status) {
    case CheckoutShippingStatus.Waiting:
      return "未発送";
    case CheckoutShippingStatus.Partially:
      return "一部発送済";
    case CheckoutShippingStatus.CompletedWithPartially:
      return "発送済(キャンセル含む)";
    case CheckoutShippingStatus.Completed:
      return "発送済";
    case CheckoutShippingStatus.Canceled:
      return "発送キャンセル済";
    case CheckoutShippingStatus.Pending:
      return "発送保留中";
  }
}
