import { immerable } from "immer";

import * as api from "../services/api/store/models";
import { ProductType, Product } from "./product";

export type SaleType = api.Sale;

export type SaleDiscountTarget = api.SaleDiscountTargetEnum;
export const SaleDiscountTarget = api.SaleDiscountTargetEnum;

export type SaleDiscountType = api.SaleDiscountTypeEnum;
export const SaleDiscountType = api.SaleDiscountTypeEnum;

export class Sale implements SaleType {
  [immerable] = true;

  id = "";
  handle = "";
  name = "";
  discountTarget: SaleDiscountTarget = SaleDiscountTarget.Evennly;
  discountType: SaleDiscountType = SaleDiscountType.Amount;
  amountOff = 0;
  percentOff = 0;
  startAt = new Date();
  endAt = new Date();
  products: ProductType[] = [];

  constructor(data: Partial<SaleType> = {}) {
    Object.assign(this, data);
  }

  getProducts() {
    return this.products.map((p) => new Product(p));
  }
}
