import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Address, AddressType } from "./address";
import { FullfillmentShippingItem, FullfillmentShippingItemType } from "./fullfillment_shipping_item";
import { ShippingLine, ShippingLineType } from "./shipping_line";
import { ShippingRate, ShippingRateType } from "./shipping_rate";
import { ShippingTime, ShippingTimeType } from "./shipping_time";

export type FullfillmentShippingType = api.FullfillmentShipping;

export class FullfillmentShipping implements FullfillmentShippingType {
  [immerable] = true;

  id = "";
  shippingRate: ShippingRateType = new ShippingRate();
  shippingTime: ShippingTimeType = new ShippingTime();
  shippingDate: Date = new Date();
  shippingLine: ShippingLineType = new ShippingLine();
  shippingAddress: AddressType = new Address();
  items: FullfillmentShippingItemType[] = [];
  shippedAt = new Date();

  constructor(data: Partial<FullfillmentShippingType> = {}) {
    Object.assign(this, data);
  }

  getItems() {
    return this.items.map((item) => new FullfillmentShippingItem(item));
  }
}
