/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateStockupRequestStockup
 */
export interface UpdateStockupRequestStockup {
    /**
     * 
     * @type {number}
     * @memberof UpdateStockupRequestStockup
     */
    requiredQuantity?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateStockupRequestStockup
     */
    pickingGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateStockupRequestStockup
     */
    memo?: string;
}

/**
 * Check if a given object implements the UpdateStockupRequestStockup interface.
 */
export function instanceOfUpdateStockupRequestStockup(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateStockupRequestStockupFromJSON(json: any): UpdateStockupRequestStockup {
    return UpdateStockupRequestStockupFromJSONTyped(json, false);
}

export function UpdateStockupRequestStockupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStockupRequestStockup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requiredQuantity': !exists(json, 'required_quantity') ? undefined : json['required_quantity'],
        'pickingGroup': !exists(json, 'picking_group') ? undefined : json['picking_group'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
    };
}

export function UpdateStockupRequestStockupToJSON(value?: UpdateStockupRequestStockup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'required_quantity': value.requiredQuantity,
        'picking_group': value.pickingGroup,
        'memo': value.memo,
    };
}

