/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddFavoriteRequestFavorite
 */
export interface AddFavoriteRequestFavorite {
    /**
     * お気に入りアイテムの種類
     * @type {string}
     * @memberof AddFavoriteRequestFavorite
     */
    favoritableType: AddFavoriteRequestFavoriteFavoritableTypeEnum;
    /**
     * お気に入りアイテムのID
     * @type {string}
     * @memberof AddFavoriteRequestFavorite
     */
    favoritableId: string;
}


/**
 * @export
 */
export const AddFavoriteRequestFavoriteFavoritableTypeEnum = {
    Product: 'Product'
} as const;
export type AddFavoriteRequestFavoriteFavoritableTypeEnum = typeof AddFavoriteRequestFavoriteFavoritableTypeEnum[keyof typeof AddFavoriteRequestFavoriteFavoritableTypeEnum];


/**
 * Check if a given object implements the AddFavoriteRequestFavorite interface.
 */
export function instanceOfAddFavoriteRequestFavorite(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "favoritableType" in value;
    isInstance = isInstance && "favoritableId" in value;

    return isInstance;
}

export function AddFavoriteRequestFavoriteFromJSON(json: any): AddFavoriteRequestFavorite {
    return AddFavoriteRequestFavoriteFromJSONTyped(json, false);
}

export function AddFavoriteRequestFavoriteFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddFavoriteRequestFavorite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favoritableType': json['favoritable_type'],
        'favoritableId': json['favoritable_id'],
    };
}

export function AddFavoriteRequestFavoriteToJSON(value?: AddFavoriteRequestFavorite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favoritable_type': value.favoritableType,
        'favoritable_id': value.favoritableId,
    };
}

