/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateUserAddress201ResponseAllOfData } from './CreateUserAddress201ResponseAllOfData';
import {
    CreateUserAddress201ResponseAllOfDataFromJSON,
    CreateUserAddress201ResponseAllOfDataFromJSONTyped,
    CreateUserAddress201ResponseAllOfDataToJSON,
} from './CreateUserAddress201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateUserAddress201ResponseAllOf
 */
export interface CreateUserAddress201ResponseAllOf {
    /**
     * 
     * @type {CreateUserAddress201ResponseAllOfData}
     * @memberof CreateUserAddress201ResponseAllOf
     */
    data: CreateUserAddress201ResponseAllOfData;
}

/**
 * Check if a given object implements the CreateUserAddress201ResponseAllOf interface.
 */
export function instanceOfCreateUserAddress201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CreateUserAddress201ResponseAllOfFromJSON(json: any): CreateUserAddress201ResponseAllOf {
    return CreateUserAddress201ResponseAllOfFromJSONTyped(json, false);
}

export function CreateUserAddress201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserAddress201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateUserAddress201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CreateUserAddress201ResponseAllOfToJSON(value?: CreateUserAddress201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateUserAddress201ResponseAllOfDataToJSON(value.data),
    };
}

