import { useCallback } from "react";

import { CustomerToken } from "shared/models";

import { getShopifyGraphqlClient } from "~/services/shopify";

import { useCreateCustomerTokenMutation } from "../graphql/CreateCustomerTokenMutation";
import { useMultipassToken } from "./useMultipassToken";

export const useCustomerToken = () => {
  const client = getShopifyGraphqlClient();
  const { mutateAsync, isLoading } = useCreateCustomerTokenMutation(client);
  const { create: createMultipassToken, isLoading: isMultipassLoading } = useMultipassToken();

  const create = useCallback(async () => {
    const multipassToken = await createMultipassToken();
    const { customerAccessTokenCreateWithMultipass: data } = await mutateAsync({ multipassToken: multipassToken.token });
    if (!data?.customerAccessToken) throw new Error("Failed to create customer access token");

    return new CustomerToken({
      token: data.customerAccessToken.accessToken,
      expiresAt: new Date(data.customerAccessToken.expiresAt),
    });
  }, []);

  return {
    create,
    isLoading: isLoading || isMultipassLoading,
  };
};
