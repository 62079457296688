import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import { TextInputGroup, TextInputGroupProps } from "../TextInputGroup";

type Props<Data extends FieldValues = FieldValues> = {
  name: FieldPath<Data>;
} & TextInputGroupProps;

export const NumberControlGroup = <Data extends FieldValues = FieldValues>({
  name,
  ...props
}: Props<Data>) => {
  const { register, formState, getFieldState } = useFormContext<Data>();
  const fieldState = getFieldState(name, formState);

  return (
    <TextInputGroup
      {...props}
      {...register(name, { valueAsNumber: true })}
      type="number"
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};