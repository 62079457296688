import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { isSameDay } from "shared/helpers";
import {
  Product,
  ProductShippingRate,
  ShippingParcel,
  ShippingParcelItem,
  ShippingRate,
  ShippingTime,
  UserMe,
} from "shared/models";

type MakeParcelOptions = {
  shippingRates: ProductShippingRate[];
  alternativeProduct: Product | null;
  user: UserMe;
};

type UpdateParcelsItemOptions = {
  alternativeProduct: Product | null;
};

export const useShippingParcels = () => {
  const [parcels, setParcels] = useState<ShippingParcel[]>([]);

  const clearParcels = useCallback(() => {
    setParcels([]);
  }, []);

  const makeParcel = useCallback((product: Product, quantity: number, options: MakeParcelOptions) => {
    const { alternativeProduct, shippingRates, user } = options;

    const item = new ShippingParcelItem({
      product,
      quantity,
      alternativeProduct,
      shippingRates,
    });

    setParcels((current) => {
      // 注文不可の酒類商品の場合は、発送不可の Parcel に分ける
      if (item.product.isAlcoholic && !user.canBuyAlcohol) {
        const existingAgeRestrictedParcel = current.find((p) => p.isAgeRestrictionApplied);
        if (existingAgeRestrictedParcel) {
          return current.map((p) => {
            if (p.id === existingAgeRestrictedParcel.id) {
              return p.addItem(item);
            } else {
              return p;
            }
          });
        } else {
          const newAgeRestrictedParcel = new ShippingParcel({
            id: uuidv4(),
            items: [item],
            isAgeRestrictionApplied: true,
          });
          return current.concat(newAgeRestrictedParcel);
        }
      }

      const addableParcel = current.find((p) => p.isItemAddable(item));
      if (addableParcel) {
        return current.map((p) => {
          if (p.id === addableParcel.id) {
            return p.addItem(item).updateAvailableShippingDateTime();
          } else {
            return p;
          }
        });
      } else {
        const parcel = new ShippingParcel({
          id: uuidv4(),
          shippingRate: item.getShippingRate(),
          shippingTime: item.getShippingTime(),
          shippingDate: item.getShippingDate(),
          items: [item],
        });
        return current.concat(parcel);
      }
    });
  }, []);

  const updateParcel = useCallback((parcel: ShippingParcel, rate: ShippingRate, date: Date, time: ShippingTime) => {
    setParcels((current) => {
      const same = current.find((p) => {
        return p.shippingRate?.id === rate.id && isSameDay(p.shippingDate, date) && p.shippingTime?.id === time.id;
      });
      if (same && same.id === parcel.id) {
        return current;
      } else if (same) {
        return current.map((p) => {
          if (p.id === same.id) {
            return p.addItems(parcel.items);
          } else {
            return p;
          }
        }).filter((p) => p.id !== parcel.id);
      } else {
        return current.map((p) => {
          if (p.id === parcel.id) {
            return new ShippingParcel({
              id: parcel.id,
              shippingRate: rate,
              shippingDate: date,
              shippingTime: time,
              items: parcel.items,
            });
          } else {
            return p;
          }
        });
      }
    });
  }, []);

  const changeParcel = useCallback((item: ShippingParcelItem, rate: ShippingRate, date: Date, time: ShippingTime) => {
    setParcels((current) => {
      const removed = current.map((p) => {
        if (p.hasItem(item)) {
          return p.removeItem(item);
        } else {
          return p;
        }
      });
      let isAdded = false;
      const added = removed.map((p) => {
        if (!p.shippingRate || !p.shippingTime || !p.shippingDate) {
          return p;
        } else if (p.shippingRate.id === rate.id && p.shippingTime.id === time.id && isSameDay(p.shippingDate, date)) {
          isAdded = true;
          return p.addItem(item);
        } else {
          return p;
        }
      });
      if (!isAdded) {
        const parcel = new ShippingParcel({
          id: uuidv4(),
          shippingRate: rate,
          shippingTime: time,
          shippingDate: date,
          items: [item],
        });
        return added.concat(parcel);
      }
      return added.filter((p) => p.items.length > 0);
    });
  }, []);

  const removeParcel = useCallback((parcel: ShippingParcel) => {
    setParcels((current) => {
      return current.filter((p) => p.id !== parcel.id);
    });
  }, []);

  const updateParcelsItem = useCallback((product: Product, quantity: number, options?: UpdateParcelsItemOptions) => {
    setParcels((current) => {
      const { alternativeProduct = null } = options || {};
      return current.map((p) => {
        return p.updateItem(product, quantity, { alternativeProduct });
      });
    });
  }, []);

  return {
    parcels,
    clearParcels,
    makeParcel,
    updateParcel,
    changeParcel,
    removeParcel,
    updateParcelsItem,
  };
};
