// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

import { ProductFragmentFragmentDoc } from '../../products/graphql/ProductFragment';
export type CartFragmentFragment = (
  { __typename?: 'Cart' }
  & Pick<Types.Cart, 'id' | 'checkoutUrl' | 'totalQuantity'>
  & { cost: (
    { __typename?: 'CartCost' }
    & { totalAmount: (
      { __typename?: 'MoneyV2' }
      & Pick<Types.MoneyV2, 'amount'>
    ) }
  ), buyerIdentity: (
    { __typename?: 'CartBuyerIdentity' }
    & Pick<Types.CartBuyerIdentity, 'email' | 'phone'>
    & { customer?: Types.Maybe<(
      { __typename?: 'Customer' }
      & Pick<Types.Customer, 'id' | 'email' | 'firstName' | 'lastName' | 'displayName'>
    )> }
  ), lines: (
    { __typename?: 'CartLineConnection' }
    & { edges: Array<(
      { __typename?: 'CartLineEdge' }
      & { node: (
        { __typename?: 'CartLine' }
        & Pick<Types.CartLine, 'id' | 'quantity'>
        & { merchandise: (
          { __typename?: 'ProductVariant' }
          & Pick<Types.ProductVariant, 'id' | 'title'>
          & { image?: Types.Maybe<(
            { __typename?: 'Image' }
            & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
          )>, price: (
            { __typename?: 'MoneyV2' }
            & Pick<Types.MoneyV2, 'amount'>
          ), product: (
            { __typename?: 'Product' }
            & Pick<Types.Product, 'id' | 'title' | 'description' | 'descriptionHtml'>
            & { featuredImage?: Types.Maybe<(
              { __typename?: 'Image' }
              & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
            )>, priceRange: (
              { __typename?: 'ProductPriceRange' }
              & { minVariantPrice: (
                { __typename?: 'MoneyV2' }
                & Pick<Types.MoneyV2, 'amount'>
              ), maxVariantPrice: (
                { __typename?: 'MoneyV2' }
                & Pick<Types.MoneyV2, 'amount'>
              ) }
            ) }
          ) }
        ), attributes: Array<(
          { __typename?: 'Attribute' }
          & Pick<Types.Attribute, 'key' | 'value'>
        )>, cost: (
          { __typename?: 'CartLineCost' }
          & { totalAmount: (
            { __typename?: 'MoneyV2' }
            & Pick<Types.MoneyV2, 'amount'>
          ), compareAtAmountPerQuantity?: Types.Maybe<(
            { __typename?: 'MoneyV2' }
            & Pick<Types.MoneyV2, 'amount'>
          )> }
        ) }
      ) }
    )> }
  ) }
);

export const CartFragmentFragmentDoc = `
    fragment CartFragment on Cart {
  id
  checkoutUrl
  totalQuantity
  cost {
    totalAmount {
      amount
    }
  }
  buyerIdentity {
    customer {
      id
      email
      firstName
      lastName
      displayName
    }
    email
    phone
  }
  lines(first: 250) {
    edges {
      node {
        id
        quantity
        merchandise {
          ... on ProductVariant {
            id
            title
            image {
              ... on Image {
                id
                url
                height
                width
                altText
              }
            }
            price {
              amount
            }
            product {
              ...ProductFragment
            }
          }
        }
        attributes {
          key
          value
        }
        cost {
          totalAmount {
            amount
          }
          compareAtAmountPerQuantity {
            amount
          }
        }
      }
    }
  }
}
    ${ProductFragmentFragmentDoc}`;