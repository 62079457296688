import { immerable } from "immer";

import * as api from "../services/api/logistics/models";

export type ProductNoteType = api.ProductNote;

export class ProductNote implements ProductNoteType {
  [immerable] = true;

  heading = "";
  body = "";

  constructor(data: Partial<ProductNoteType> = {}) {
    Object.assign(this, data);
  }
}
