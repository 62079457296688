/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Stockup } from './Stockup';
import {
    StockupFromJSON,
    StockupFromJSONTyped,
    StockupToJSON,
} from './Stockup';

/**
 * 
 * @export
 * @interface CreateStockup201ResponseAllOfData
 */
export interface CreateStockup201ResponseAllOfData {
    /**
     * 
     * @type {Stockup}
     * @memberof CreateStockup201ResponseAllOfData
     */
    stockup: Stockup;
}

/**
 * Check if a given object implements the CreateStockup201ResponseAllOfData interface.
 */
export function instanceOfCreateStockup201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockup" in value;

    return isInstance;
}

export function CreateStockup201ResponseAllOfDataFromJSON(json: any): CreateStockup201ResponseAllOfData {
    return CreateStockup201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateStockup201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockup201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockup': StockupFromJSON(json['stockup']),
    };
}

export function CreateStockup201ResponseAllOfDataToJSON(value?: CreateStockup201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockup': StockupToJSON(value.stockup),
    };
}

