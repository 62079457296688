import { immerable } from "immer";

import { Product } from "./product";
import { ProductShippingRate } from "./product_shipping_rate";
import { ShippingTime } from "./shipping_time";

export type ShippingParcelItemType = {
  product: Product;
  quantity: number;
  alternativeProduct: Product | null;
  shippingRates: ProductShippingRate[];
};

export class ShippingParcelItem implements ShippingParcelItemType {
  [immerable] = true;

  product = new Product();
  quantity = 0;
  alternativeProduct: Product | null = null;
  shippingRates: ProductShippingRate[] = [];

  constructor(data: Partial<ShippingParcelItemType> = {}) {
    Object.assign(this, data);
  }

  getShippingRate(): ProductShippingRate | null {
    return this.shippingRates[0] || null;
  }

  getShippingDate(): Date | null {
    const shippingRate = this.getShippingRate();
    if (!shippingRate) {
      return null;
    }
    return shippingRate.availableDatetimes.find(({ availableQuantity }) => availableQuantity >= this.quantity)?.date || null;
  }

  getShippingTime(): ShippingTime | null {
    const shippingRate = this.getShippingRate();
    if (!shippingRate) {
      return null;
    }
    const datetime = shippingRate.availableDatetimes.find(({ availableQuantity }) => availableQuantity >= this.quantity);
    if (!datetime) {
      return null;
    }
    return new ShippingTime({
      id: datetime.id,
      name: datetime.name,
      rangeStart: datetime.rangeStart,
      rangeEnd: datetime.rangeEnd,
    });
  }
}
