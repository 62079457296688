import { z } from "zod";

import { Button, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  phone: z
    .string()
    .regex(/^[0-9]+$/, "ハイフンは不要です")
    .regex(/^0[56789]0[0-9]{8}$/, "形式が正しくありません"),
});

export type PhoneConfirmationData = z.infer<typeof schema>;

type Props = {
  isSendingCode: boolean;
};

export const PhoneConfirmationForm = createHookForm<
  PhoneConfirmationData,
  Props
>(
  ({ formState: { isSubmitting }, isSendingCode }) => {
    return (
      <div className="flex flex-col gap-4">
        <TextControlGroup
          name="phone"
          label="電話番号"
          placeholder="09012345678"
          note="ハイフンなしで入力してください"
          inputMode="tel"
          required
        />
        <Button
          type="submit"
          primary
          block
          loading={isSubmitting || isSendingCode}
        >
          認証コードを送信する
        </Button>
      </div>
    );
  },
  {
    schema,
  }
);
