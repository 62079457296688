/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateUserBirthDateRequestUser } from './UpdateUserBirthDateRequestUser';
import {
    UpdateUserBirthDateRequestUserFromJSON,
    UpdateUserBirthDateRequestUserFromJSONTyped,
    UpdateUserBirthDateRequestUserToJSON,
} from './UpdateUserBirthDateRequestUser';

/**
 * 
 * @export
 * @interface UpdateUserBirthDateRequest
 */
export interface UpdateUserBirthDateRequest {
    /**
     * 
     * @type {UpdateUserBirthDateRequestUser}
     * @memberof UpdateUserBirthDateRequest
     */
    user?: UpdateUserBirthDateRequestUser;
}

/**
 * Check if a given object implements the UpdateUserBirthDateRequest interface.
 */
export function instanceOfUpdateUserBirthDateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateUserBirthDateRequestFromJSON(json: any): UpdateUserBirthDateRequest {
    return UpdateUserBirthDateRequestFromJSONTyped(json, false);
}

export function UpdateUserBirthDateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserBirthDateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UpdateUserBirthDateRequestUserFromJSON(json['user']),
    };
}

export function UpdateUserBirthDateRequestToJSON(value?: UpdateUserBirthDateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UpdateUserBirthDateRequestUserToJSON(value.user),
    };
}

