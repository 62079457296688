import { immerable } from "immer";

import * as api from "../services/api/models";

export class Prefecture implements api.Prefecture {
  [immerable] = true;

  id = "";
  name = "";

  constructor(data: Partial<Prefecture> = {}) {
    Object.assign(this, data);
  }
}
