import { useState } from "react";

import { MagazineCategory } from "shared/models";

import { StrapiCategory, StrapiResponse } from "../types";
import { useStrapiQuery } from "./useStrapiQuery";

export const useMagazineCategiories = () => {
  const [categories, setCategories] = useState<MagazineCategory[]>([]);

  const { isLoading } = useStrapiQuery<StrapiResponse<StrapiCategory[]>>(
    ["magazineCategories"],
    "/api/categories",
    {
      populate: "*",
    },
    {
      onSuccess: ({ data }) => {
        setCategories(data.map(({ id, attributes }) => new MagazineCategory({
          id,
          name: attributes.name,
          slug: attributes.slug,
        })));
      },
    },
  );

  return {
    categories,
    isLoading,
  };
};
