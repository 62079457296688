import classNames from "classnames";
import { useCallback } from "react";

import { Product } from "shared/models";
import { BFC } from "shared/types";

import { ProductCardWide } from "../ProductCardWide";

type Props = {
  product: Product;
  selected?: boolean;
  onSelect?: (product: Product | null) => void;
};

export const ProductSelectableCard: BFC<Props> = ({
  product,
  selected,
  onSelect,
  className,
}) => {
  const onSelectHandler = useCallback(() => {
    if (onSelect) {
      onSelect(product);
    }
  }, [product, onSelect]);

  const classes = classNames(
    className,
    {
      "border-primary": selected,
    },
  );

  return (
    <div className={classNames("border rounded p-3", classes)}>
      <button onClick={onSelectHandler}>
        <ProductCardWide
          product={product}
          clampTitle={false}
        />
      </button>
    </div>
  );
};
