/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FriendCouponCodeAllOfReward } from './FriendCouponCodeAllOfReward';
import {
    FriendCouponCodeAllOfRewardFromJSON,
    FriendCouponCodeAllOfRewardFromJSONTyped,
    FriendCouponCodeAllOfRewardToJSON,
} from './FriendCouponCodeAllOfReward';

/**
 * 友達紹介クーポン。CouponCodeを継承しているが、紹介特典クーポンの割引率情報を含んでいる
 * @export
 * @interface FriendCouponCode
 */
export interface FriendCouponCode {
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCode
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCode
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCode
     */
    code: string;
    /**
     * 
     * @type {boolean}
     * @memberof FriendCouponCode
     */
    isAvailable: boolean;
    /**
     * 
     * @type {Date}
     * @memberof FriendCouponCode
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCode
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof FriendCouponCode
     */
    discountType: FriendCouponCodeDiscountTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FriendCouponCode
     */
    amountOff: number;
    /**
     * 
     * @type {number}
     * @memberof FriendCouponCode
     */
    percentOff: number;
    /**
     * 
     * @type {boolean}
     * @memberof FriendCouponCode
     */
    isPublic: boolean;
    /**
     * 
     * @type {FriendCouponCodeAllOfReward}
     * @memberof FriendCouponCode
     */
    reward: FriendCouponCodeAllOfReward;
}


/**
 * @export
 */
export const FriendCouponCodeDiscountTypeEnum = {
    Amount: 'amount',
    Percent: 'percent'
} as const;
export type FriendCouponCodeDiscountTypeEnum = typeof FriendCouponCodeDiscountTypeEnum[keyof typeof FriendCouponCodeDiscountTypeEnum];


/**
 * Check if a given object implements the FriendCouponCode interface.
 */
export function instanceOfFriendCouponCode(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "isAvailable" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "discountType" in value;
    isInstance = isInstance && "amountOff" in value;
    isInstance = isInstance && "percentOff" in value;
    isInstance = isInstance && "isPublic" in value;
    isInstance = isInstance && "reward" in value;

    return isInstance;
}

export function FriendCouponCodeFromJSON(json: any): FriendCouponCode {
    return FriendCouponCodeFromJSONTyped(json, false);
}

export function FriendCouponCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendCouponCode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
        'isAvailable': json['is_available'],
        'expiresAt': !exists(json, 'expires_at') ? undefined : (new Date(json['expires_at'])),
        'type': json['type'],
        'discountType': json['discount_type'],
        'amountOff': json['amount_off'],
        'percentOff': json['percent_off'],
        'isPublic': json['is_public'],
        'reward': FriendCouponCodeAllOfRewardFromJSON(json['reward']),
    };
}

export function FriendCouponCodeToJSON(value?: FriendCouponCode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'is_available': value.isAvailable,
        'expires_at': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'type': value.type,
        'discount_type': value.discountType,
        'amount_off': value.amountOff,
        'percent_off': value.percentOff,
        'is_public': value.isPublic,
        'reward': FriendCouponCodeAllOfRewardToJSON(value.reward),
    };
}

