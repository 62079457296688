import { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ArticleCardWide } from "../../components";
import { useMagazineCategoryArticles } from "../../hooks";

type Props = {
  slug: string;
};

export const MagazineCategoryShowPage: BFC<Props> = ({
  slug,
}) => {
  const {
    category,
    articles,
    totalCount,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
  } = useMagazineCategoryArticles(slug);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  if (isLoading) {
    return <div className="flex items-center justify-center h-full"><ColorRing /></div>;
  }

  return (
    <div>
      <div className="bg-white">
        <div className="p-4 flex flex-col gap-3">
          <h2 className="font-bold text-xl">
            {category.name}の記事一覧
          </h2>
          <div className="text-gray-500">{totalCount}件</div>
        </div>

        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage} className="p-4 flex flex-col gap-3">
          {articles.map((article) => (
            <Link key={article.id} href={routes.MAGAZINE_ARTICLES_SHOW(article.slug)}>
              <ArticleCardWide article={article} />
            </Link>
          ))}
        </InfiniteScroll>
        {(isLoading || isFetchingNextPage) && (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  );
};
