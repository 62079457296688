/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCostcoProduct200ResponseAllOfData } from './GetCostcoProduct200ResponseAllOfData';
import {
    GetCostcoProduct200ResponseAllOfDataFromJSON,
    GetCostcoProduct200ResponseAllOfDataFromJSONTyped,
    GetCostcoProduct200ResponseAllOfDataToJSON,
} from './GetCostcoProduct200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCostcoProduct200ResponseAllOf
 */
export interface GetCostcoProduct200ResponseAllOf {
    /**
     * 
     * @type {GetCostcoProduct200ResponseAllOfData}
     * @memberof GetCostcoProduct200ResponseAllOf
     */
    data: GetCostcoProduct200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCostcoProduct200ResponseAllOf interface.
 */
export function instanceOfGetCostcoProduct200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCostcoProduct200ResponseAllOfFromJSON(json: any): GetCostcoProduct200ResponseAllOf {
    return GetCostcoProduct200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCostcoProduct200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProduct200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCostcoProduct200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCostcoProduct200ResponseAllOfToJSON(value?: GetCostcoProduct200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCostcoProduct200ResponseAllOfDataToJSON(value.data),
    };
}

