import classNames from "classnames";

import { formatDate } from "shared/helpers";
import { MagazineArticle } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  article: MagazineArticle;
};

export const ArticleCardWide: BFC<Props> = ({
  article,
  className,
}) => {
  const { cover, title, publishAt } = article;
  const categories = article.getCategories();

  return (
    <div className={classNames("bg-white flex gap-3", className)}>
      {cover && (
        <div className="w-1/5 shrink-0">
          <img
            src={cover.url}
            alt={title}
            className="w-full max-w-[160px] aspect-square object-cover border rounded"
          />
        </div>
      )}
      <div className="flex flex-col gap-1 flex-1">
        {categories.length > 0 && (
          <div className="flex gap-1">
            {categories.map((category) => (
              <span
                key={category.id}
                className="text-xs rounded bg-neutral-100 text-gray-500 py-0.5 px-2"
              >
                {category.name}
              </span>
            ))}
          </div>
        )}
        <h3 className="font-bold line-clamp-2">{title}</h3>
        <div className="text-sm text-gray-400 flex-1 flex items-end justify-end">
          {formatDate(publishAt)}
        </div>
      </div>
    </div>
  );
};
