/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutItem } from './CheckoutItem';
import {
    CheckoutItemFromJSON,
    CheckoutItemFromJSONTyped,
    CheckoutItemToJSON,
} from './CheckoutItem';
import type { CheckoutSummary } from './CheckoutSummary';
import {
    CheckoutSummaryFromJSON,
    CheckoutSummaryFromJSONTyped,
    CheckoutSummaryToJSON,
} from './CheckoutSummary';
import type { ProductRecipe } from './ProductRecipe';
import {
    ProductRecipeFromJSON,
    ProductRecipeFromJSONTyped,
    ProductRecipeToJSON,
} from './ProductRecipe';
import type { ShippingTime } from './ShippingTime';
import {
    ShippingTimeFromJSON,
    ShippingTimeFromJSONTyped,
    ShippingTimeToJSON,
} from './ShippingTime';

/**
 * コストコ商品の必要な仕入れに対する注文商品
 * @export
 * @interface StockupCheckoutItem
 */
export interface StockupCheckoutItem {
    /**
     * 
     * @type {string}
     * @memberof StockupCheckoutItem
     */
    id: string;
    /**
     * 
     * @type {CheckoutSummary}
     * @memberof StockupCheckoutItem
     */
    checkout: CheckoutSummary;
    /**
     * 
     * @type {CheckoutItem}
     * @memberof StockupCheckoutItem
     */
    checkoutItem: CheckoutItem;
    /**
     * 
     * @type {Array<ProductRecipe>}
     * @memberof StockupCheckoutItem
     */
    productRecipes: Array<ProductRecipe>;
    /**
     * 
     * @type {Array<ProductRecipe>}
     * @memberof StockupCheckoutItem
     */
    alternativeProductRecipes?: Array<ProductRecipe>;
    /**
     * 
     * @type {Date}
     * @memberof StockupCheckoutItem
     */
    shippingDate: Date;
    /**
     * 
     * @type {ShippingTime}
     * @memberof StockupCheckoutItem
     */
    shippingTime: ShippingTime;
}

/**
 * Check if a given object implements the StockupCheckoutItem interface.
 */
export function instanceOfStockupCheckoutItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "checkout" in value;
    isInstance = isInstance && "checkoutItem" in value;
    isInstance = isInstance && "productRecipes" in value;
    isInstance = isInstance && "shippingDate" in value;
    isInstance = isInstance && "shippingTime" in value;

    return isInstance;
}

export function StockupCheckoutItemFromJSON(json: any): StockupCheckoutItem {
    return StockupCheckoutItemFromJSONTyped(json, false);
}

export function StockupCheckoutItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockupCheckoutItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'checkout': CheckoutSummaryFromJSON(json['checkout']),
        'checkoutItem': CheckoutItemFromJSON(json['checkout_item']),
        'productRecipes': ((json['product_recipes'] as Array<any>).map(ProductRecipeFromJSON)),
        'alternativeProductRecipes': !exists(json, 'alternative_product_recipes') ? undefined : ((json['alternative_product_recipes'] as Array<any>).map(ProductRecipeFromJSON)),
        'shippingDate': (new Date(json['shipping_date'])),
        'shippingTime': ShippingTimeFromJSON(json['shipping_time']),
    };
}

export function StockupCheckoutItemToJSON(value?: StockupCheckoutItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'checkout': CheckoutSummaryToJSON(value.checkout),
        'checkout_item': CheckoutItemToJSON(value.checkoutItem),
        'product_recipes': ((value.productRecipes as Array<any>).map(ProductRecipeToJSON)),
        'alternative_product_recipes': value.alternativeProductRecipes === undefined ? undefined : ((value.alternativeProductRecipes as Array<any>).map(ProductRecipeToJSON)),
        'shipping_date': (value.shippingDate.toISOString().substr(0,10)),
        'shipping_time': ShippingTimeToJSON(value.shippingTime),
    };
}

