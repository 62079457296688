import { immerable } from "immer";

import * as api from "../services/api/store/models";

export type TagType = api.Tag;

export class Tag implements TagType {
  [immerable] = true;

  id = "";
  name = "";
  taggingsCount = 0;

  constructor(data: Partial<TagType> = {}) {
    Object.assign(this, data);
  }
}
