/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachableImage } from './AttachableImage';
import {
    AttachableImageFromJSON,
    AttachableImageFromJSONTyped,
    AttachableImageToJSON,
} from './AttachableImage';

/**
 * 商品のレビュー
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    body: string;
    /**
     * 
     * @type {Array<AttachableImage>}
     * @memberof Review
     */
    files: Array<AttachableImage>;
    /**
     * レビューが投稿された日時
     * @type {Date}
     * @memberof Review
     */
    createdAt: Date;
    /**
     * レビューが掲載された日時。未掲載の場合はフィールドがない
     * @type {Date}
     * @memberof Review
     */
    publishedAt?: Date;
}

/**
 * Check if a given object implements the Review interface.
 */
export function instanceOfReview(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "files" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function ReviewFromJSON(json: any): Review {
    return ReviewFromJSONTyped(json, false);
}

export function ReviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): Review {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'productId': json['product_id'],
        'title': json['title'],
        'body': json['body'],
        'files': ((json['files'] as Array<any>).map(AttachableImageFromJSON)),
        'createdAt': (new Date(json['created_at'])),
        'publishedAt': !exists(json, 'published_at') ? undefined : (new Date(json['published_at'])),
    };
}

export function ReviewToJSON(value?: Review | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product_id': value.productId,
        'title': value.title,
        'body': value.body,
        'files': ((value.files as Array<any>).map(AttachableImageToJSON)),
        'created_at': (value.createdAt.toISOString()),
        'published_at': value.publishedAt === undefined ? undefined : (value.publishedAt.toISOString()),
    };
}

