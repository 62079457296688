import { immerable } from "immer";

import * as api from "shared/services/api/logistics/models";

import { DeliveryParcel } from "./delivery_parcel";
import { ShippingLineType } from "./shipping_line";

export type DeliveryDriverType = api.DeliveryDriver;

export const UNASSIGNED_DRIVER_ID = "unassigned";

export class DeliveryDriver implements DeliveryDriverType {
  [immerable] = true;

  id = "";
  handle = "";
  name?: string;
  phone?: string;
  shippingLine?: ShippingLineType;

  constructor(data: Partial<DeliveryDriverType> = {}) {
    Object.assign(this, data);
  }

  isAssigned(parcel: DeliveryParcel) {
    return parcel.deliveryDriver.id === this.id;
  }

  get key() {
    return this.id || UNASSIGNED_DRIVER_ID;
  }

  get colorCode() {
    if (!this.id) return "#FFFFFF";

    const hash = this.id.split("").reduce((acc, c) => {
      acc = (acc << 5) - acc + c.charCodeAt(0);
      return acc & acc;
    }, 0);
    const r = ((hash & 0xff0000) >> 16).toString(16).padStart(2, "0");
    const g = ((hash & 0x00ff00) >> 8).toString(16).padStart(2, "0");
    const b = (hash & 0x0000ff).toString(16).padStart(2, "0");
    return `#${r}${g}${b}`;
  }
}
