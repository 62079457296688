/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateCheckoutRequestParcelsInner } from './CreateCheckoutRequestParcelsInner';
import {
    CreateCheckoutRequestParcelsInnerFromJSON,
    CreateCheckoutRequestParcelsInnerFromJSONTyped,
    CreateCheckoutRequestParcelsInnerToJSON,
} from './CreateCheckoutRequestParcelsInner';
import type { CreateCheckoutRequestShippingAddress } from './CreateCheckoutRequestShippingAddress';
import {
    CreateCheckoutRequestShippingAddressFromJSON,
    CreateCheckoutRequestShippingAddressFromJSONTyped,
    CreateCheckoutRequestShippingAddressToJSON,
} from './CreateCheckoutRequestShippingAddress';

/**
 * 
 * @export
 * @interface CreateCheckoutRequest
 */
export interface CreateCheckoutRequest {
    /**
     * 
     * @type {CreateCheckoutRequestShippingAddress}
     * @memberof CreateCheckoutRequest
     */
    shippingAddress: CreateCheckoutRequestShippingAddress;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequest
     */
    paymentMethodId: string;
    /**
     * 
     * @type {Array<CreateCheckoutRequestParcelsInner>}
     * @memberof CreateCheckoutRequest
     */
    parcels: Array<CreateCheckoutRequestParcelsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequest
     */
    couponCode?: string;
}

/**
 * Check if a given object implements the CreateCheckoutRequest interface.
 */
export function instanceOfCreateCheckoutRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "paymentMethodId" in value;
    isInstance = isInstance && "parcels" in value;

    return isInstance;
}

export function CreateCheckoutRequestFromJSON(json: any): CreateCheckoutRequest {
    return CreateCheckoutRequestFromJSONTyped(json, false);
}

export function CreateCheckoutRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingAddress': CreateCheckoutRequestShippingAddressFromJSON(json['shipping_address']),
        'paymentMethodId': json['payment_method_id'],
        'parcels': ((json['parcels'] as Array<any>).map(CreateCheckoutRequestParcelsInnerFromJSON)),
        'couponCode': !exists(json, 'coupon_code') ? undefined : json['coupon_code'],
    };
}

export function CreateCheckoutRequestToJSON(value?: CreateCheckoutRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_address': CreateCheckoutRequestShippingAddressToJSON(value.shippingAddress),
        'payment_method_id': value.paymentMethodId,
        'parcels': ((value.parcels as Array<any>).map(CreateCheckoutRequestParcelsInnerToJSON)),
        'coupon_code': value.couponCode,
    };
}

