/* tslint:disable */
/* eslint-disable */
/**
 * Common
 * 共通API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Prefecture } from './Prefecture';
import {
    PrefectureFromJSON,
    PrefectureFromJSONTyped,
    PrefectureToJSON,
} from './Prefecture';

/**
 * 
 * @export
 * @interface GetPrefectures200ResponseAllOfData
 */
export interface GetPrefectures200ResponseAllOfData {
    /**
     * 
     * @type {Array<Prefecture>}
     * @memberof GetPrefectures200ResponseAllOfData
     */
    prefectures: Array<Prefecture>;
}

/**
 * Check if a given object implements the GetPrefectures200ResponseAllOfData interface.
 */
export function instanceOfGetPrefectures200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "prefectures" in value;

    return isInstance;
}

export function GetPrefectures200ResponseAllOfDataFromJSON(json: any): GetPrefectures200ResponseAllOfData {
    return GetPrefectures200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetPrefectures200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPrefectures200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prefectures': ((json['prefectures'] as Array<any>).map(PrefectureFromJSON)),
    };
}

export function GetPrefectures200ResponseAllOfDataToJSON(value?: GetPrefectures200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prefectures': ((value.prefectures as Array<any>).map(PrefectureToJSON)),
    };
}

