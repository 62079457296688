/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsumeStockRequestStock } from './ConsumeStockRequestStock';
import {
    ConsumeStockRequestStockFromJSON,
    ConsumeStockRequestStockFromJSONTyped,
    ConsumeStockRequestStockToJSON,
} from './ConsumeStockRequestStock';

/**
 * 
 * @export
 * @interface ConsumeStockRequest
 */
export interface ConsumeStockRequest {
    /**
     * 
     * @type {ConsumeStockRequestStock}
     * @memberof ConsumeStockRequest
     */
    stock: ConsumeStockRequestStock;
}

/**
 * Check if a given object implements the ConsumeStockRequest interface.
 */
export function instanceOfConsumeStockRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stock" in value;

    return isInstance;
}

export function ConsumeStockRequestFromJSON(json: any): ConsumeStockRequest {
    return ConsumeStockRequestFromJSONTyped(json, false);
}

export function ConsumeStockRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsumeStockRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stock': ConsumeStockRequestStockFromJSON(json['stock']),
    };
}

export function ConsumeStockRequestToJSON(value?: ConsumeStockRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stock': ConsumeStockRequestStockToJSON(value.stock),
    };
}

