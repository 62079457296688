/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecreateStockups201ResponseAllOfData } from './RecreateStockups201ResponseAllOfData';
import {
    RecreateStockups201ResponseAllOfDataFromJSON,
    RecreateStockups201ResponseAllOfDataFromJSONTyped,
    RecreateStockups201ResponseAllOfDataToJSON,
} from './RecreateStockups201ResponseAllOfData';

/**
 * 
 * @export
 * @interface RecreateStockups201ResponseAllOf
 */
export interface RecreateStockups201ResponseAllOf {
    /**
     * 
     * @type {RecreateStockups201ResponseAllOfData}
     * @memberof RecreateStockups201ResponseAllOf
     */
    data: RecreateStockups201ResponseAllOfData;
}

/**
 * Check if a given object implements the RecreateStockups201ResponseAllOf interface.
 */
export function instanceOfRecreateStockups201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function RecreateStockups201ResponseAllOfFromJSON(json: any): RecreateStockups201ResponseAllOf {
    return RecreateStockups201ResponseAllOfFromJSONTyped(json, false);
}

export function RecreateStockups201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecreateStockups201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': RecreateStockups201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function RecreateStockups201ResponseAllOfToJSON(value?: RecreateStockups201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': RecreateStockups201ResponseAllOfDataToJSON(value.data),
    };
}

