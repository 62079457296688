/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalculateCheckout201ResponseAllOfData } from './CalculateCheckout201ResponseAllOfData';
import {
    CalculateCheckout201ResponseAllOfDataFromJSON,
    CalculateCheckout201ResponseAllOfDataFromJSONTyped,
    CalculateCheckout201ResponseAllOfDataToJSON,
} from './CalculateCheckout201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CalculateCheckout201ResponseAllOf
 */
export interface CalculateCheckout201ResponseAllOf {
    /**
     * 
     * @type {CalculateCheckout201ResponseAllOfData}
     * @memberof CalculateCheckout201ResponseAllOf
     */
    data: CalculateCheckout201ResponseAllOfData;
}

/**
 * Check if a given object implements the CalculateCheckout201ResponseAllOf interface.
 */
export function instanceOfCalculateCheckout201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CalculateCheckout201ResponseAllOfFromJSON(json: any): CalculateCheckout201ResponseAllOf {
    return CalculateCheckout201ResponseAllOfFromJSONTyped(json, false);
}

export function CalculateCheckout201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalculateCheckout201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CalculateCheckout201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CalculateCheckout201ResponseAllOfToJSON(value?: CalculateCheckout201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CalculateCheckout201ResponseAllOfDataToJSON(value.data),
    };
}

