/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagFamilyTag } from './TagFamilyTag';
import {
    TagFamilyTagFromJSON,
    TagFamilyTagFromJSONTyped,
    TagFamilyTagToJSON,
} from './TagFamilyTag';

/**
 * 
 * @export
 * @interface GetTagFamilyTags200ResponseAllOfData
 */
export interface GetTagFamilyTags200ResponseAllOfData {
    /**
     * 
     * @type {Array<TagFamilyTag>}
     * @memberof GetTagFamilyTags200ResponseAllOfData
     */
    tags: Array<TagFamilyTag>;
}

/**
 * Check if a given object implements the GetTagFamilyTags200ResponseAllOfData interface.
 */
export function instanceOfGetTagFamilyTags200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function GetTagFamilyTags200ResponseAllOfDataFromJSON(json: any): GetTagFamilyTags200ResponseAllOfData {
    return GetTagFamilyTags200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetTagFamilyTags200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagFamilyTags200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': ((json['tags'] as Array<any>).map(TagFamilyTagFromJSON)),
    };
}

export function GetTagFamilyTags200ResponseAllOfDataToJSON(value?: GetTagFamilyTags200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': ((value.tags as Array<any>).map(TagFamilyTagToJSON)),
    };
}

