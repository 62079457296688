/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetUserSession200ResponseAllOfData } from './GetUserSession200ResponseAllOfData';
import {
    GetUserSession200ResponseAllOfDataFromJSON,
    GetUserSession200ResponseAllOfDataFromJSONTyped,
    GetUserSession200ResponseAllOfDataToJSON,
} from './GetUserSession200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetUserSession200ResponseAllOf
 */
export interface GetUserSession200ResponseAllOf {
    /**
     * 
     * @type {GetUserSession200ResponseAllOfData}
     * @memberof GetUserSession200ResponseAllOf
     */
    data: GetUserSession200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetUserSession200ResponseAllOf interface.
 */
export function instanceOfGetUserSession200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetUserSession200ResponseAllOfFromJSON(json: any): GetUserSession200ResponseAllOf {
    return GetUserSession200ResponseAllOfFromJSONTyped(json, false);
}

export function GetUserSession200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserSession200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetUserSession200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetUserSession200ResponseAllOfToJSON(value?: GetUserSession200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetUserSession200ResponseAllOfDataToJSON(value.data),
    };
}

