import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";

import { UserMe, Token } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { isAPIError } from "shared/types";

const { persistAtom } = recoilPersist();

const userState = atom<UserMe>({
  key: "auth/user",
  default: new UserMe(),
  effects: [persistAtom],
});

const tokenState = atom<Token>({
  key: "auth/token",
  default: new Token(),
  effects: [persistAtom],
});

export const useAuth = () => {
  const [userObject, setUser] = useRecoilState(userState);
  const [tokenObject, setToken] = useRecoilState(tokenState);
  const tokenRef = useRef("");
  const api = useStoreAPI({ accessToken: tokenObject.token });
  const [isIdle, setIsIdle] = useState(true);
  const [isAccidentallySignedOut, setIsAccidentallySignedOut] = useState(false);

  const { isLoading, refetch: _refetch } = useQuery(
    ["users/session"],
    () => api.getUserSession(),
    {
      retry: false,
      refetchOnWindowFocus: "always",
      onSuccess: ({ data: { user } }) => {
        setIsIdle(false);
        setUser(new UserMe(user));
      },
      onError: (e) => {
        setIsIdle(false);
        if (isAPIError(e)) {
          if (e.response.status === 401 && userObject.id) {
            signOut();
            setIsAccidentallySignedOut(true);
          }
        }
      },
    },
  );

  useEffect(() => {
    tokenRef.current = tokenObject.token;
  }, [tokenObject]);

  const signIn = useCallback((user: UserMe, token: Token) => {
    setToken(token);
    setUser(user);
  }, []);

  const signOut = useCallback(() => {
    setToken(new Token());
    setUser(new UserMe());
  }, []);

  const refetch = useCallback(() => {
    _refetch();
  }, [_refetch]);

  const setOnlyToken = useCallback((token: Token) => {
    setToken(token);
  }, []);

  const waitToken = useCallback(() => {
    return new Promise<string>((resolve) => {
      setTimeout(function waiter() {
        if (tokenRef.current) {
          resolve(tokenRef.current);
        } else {
          setTimeout(waiter, 100);
        }
      });
    });
  }, [tokenRef]);

  const user = new UserMe(userObject);
  const token = new Token(tokenObject);

  return {
    user,
    userToken: token,
    token: token.token,
    accessToken: token.token,
    signIn,
    signOut,
    refetch,
    setOnlyToken,
    waitToken,
    isIdle,
    isSignedIn: user.isSignedIn() && !isLoading,
    isLoading,
    isAccidentallySignedOut,
  };
};
