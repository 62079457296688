import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { LinkButton } from "shared/components";
import { Review } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ReviewItem } from "../ReviewItem";

type Props = {
  productId: string;
  reviews: Review[];
  isLoading: boolean;
  infiniteScrollProps?: {
    isFetchingNextPage: boolean;
    hasMore: boolean;
    loadMore: () => void;
  };
};

export const ReviewList: BFC<Props> = ({
  productId,
  reviews,
  isLoading,
  infiniteScrollProps,
}) => {
  if (isLoading) {
    return (
      <div className="bg-white flex justify-center items-center">
        <ColorRing />
      </div>
    );
  }

  if (!reviews.length) {
    return (
      <div className="bg-white flex flex-col py-8 gap-4 items-center">
        <p className="text-black font-bold">レビューはまだありません</p>
        <p className="text-gray-600 text-center leading-7">
          この商品を購入したことがありますか?
          <br />
          ぜひレビューの投稿をお願いいたします🥺
          <br />
          <LinkButton href={routes.REVIEWS_NEW(productId)} className="mt-2">
            レビューを投稿する
          </LinkButton>
        </p>
      </div>
    );
  }

  if (infiniteScrollProps) {
    const { isFetchingNextPage, hasMore, loadMore } = infiniteScrollProps;

    return (
      <div className="bg-white">
        <InfiniteScroll
          hasMore={hasMore}
          loadMore={loadMore}
          className="divide-y divide-gray-200"
        >
          {reviews.map((review) => (
            <ReviewItem key={review.id} review={review} />
          ))}
        </InfiniteScroll>
        {isFetchingNextPage && (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="bg-white">
      <div className="divide-y divide-gray-200">
        {reviews.map((review) => (
          <ReviewItem key={review.id} review={review} />
        ))}
      </div>
    </div>
  );
};
