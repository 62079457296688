/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddCartItemRequest,
  AddFavorite201Response,
  AddFavorite400Response,
  AddFavoriteRequest,
  CalculateCheckout201Response,
  CalculateCheckoutRequest,
  ConfirmUserEmailRequest,
  ConfirmUserPhoneRequest,
  ConnectUserProviderRequest,
  CreateCart201Response,
  CreateCartRequest,
  CreateCheckout201Response,
  CreateCheckoutRequest,
  CreateDirectUpload200Response,
  CreateDirectUploadRequest,
  CreateReview200Response,
  CreateReviewRequest,
  CreateUserAddress201Response,
  CreateUserAddressRequest,
  CreateUserMultipassToken201Response,
  CreateUserPaymentMethod201Response,
  CreateUserPaymentMethodRequest,
  DeleteUserAddress200Response,
  DeleteUserPaymentMethod200Response,
  DeleteUserRequest,
  DisconnectUserProvider200Response,
  GetCarouselItems200Response,
  GetCheckout200Response,
  GetCheckouts200Response,
  GetCurrentUser200Response,
  GetDeliverableAddresses200Response,
  GetFavorites200Response,
  GetProduct200Response,
  GetProductShippingRates200Response,
  GetProducts200Response,
  GetRankingProducts200Response,
  GetReviews200Response,
  GetSale200Response,
  GetSaleProducts200Response,
  GetSales200Response,
  GetTagFamilyTag200Response,
  GetTagFamilyTags200Response,
  GetUserAddress200Response,
  GetUserAddresses200Response,
  GetUserCouponCodes200Response,
  GetUserPaymentMethods200Response,
  GetUserSession200Response,
  PostPreRegistrations201Response,
  PostPreRegistrationsRequest,
  RemoveCartItemRequest,
  ResetPasswordRequest,
  ResponseErrorBadRequest,
  ResponseErrorConflict,
  ResponseErrorForbidden,
  ResponseErrorInvalidRecord,
  ResponseErrorNotAcceptable,
  ResponseErrorNotFound,
  ResponseErrorUnauthorized,
  ResponseSuccess,
  SendConfirmUserEmailRequest,
  SendConfirmUserPhoneRequest,
  SendResetMailRequest,
  SignInUserRequest,
  SignUpUser201Response,
  SignUpUserRequest,
  UpdateCheckoutPaymentMethodRequest,
  UpdateUserBirthDateRequest,
  UpdateUserEmailMarketingAgreedRequest,
  UpdateUserEmailRequest,
  UpdateUserNameRequest,
  UpdateUserNotificationSetting200Response,
  UpdateUserNotificationSettingRequest,
  UpdateUserPaymentMethodRequest,
} from '../models';
import {
    AddCartItemRequestFromJSON,
    AddCartItemRequestToJSON,
    AddFavorite201ResponseFromJSON,
    AddFavorite201ResponseToJSON,
    AddFavorite400ResponseFromJSON,
    AddFavorite400ResponseToJSON,
    AddFavoriteRequestFromJSON,
    AddFavoriteRequestToJSON,
    CalculateCheckout201ResponseFromJSON,
    CalculateCheckout201ResponseToJSON,
    CalculateCheckoutRequestFromJSON,
    CalculateCheckoutRequestToJSON,
    ConfirmUserEmailRequestFromJSON,
    ConfirmUserEmailRequestToJSON,
    ConfirmUserPhoneRequestFromJSON,
    ConfirmUserPhoneRequestToJSON,
    ConnectUserProviderRequestFromJSON,
    ConnectUserProviderRequestToJSON,
    CreateCart201ResponseFromJSON,
    CreateCart201ResponseToJSON,
    CreateCartRequestFromJSON,
    CreateCartRequestToJSON,
    CreateCheckout201ResponseFromJSON,
    CreateCheckout201ResponseToJSON,
    CreateCheckoutRequestFromJSON,
    CreateCheckoutRequestToJSON,
    CreateDirectUpload200ResponseFromJSON,
    CreateDirectUpload200ResponseToJSON,
    CreateDirectUploadRequestFromJSON,
    CreateDirectUploadRequestToJSON,
    CreateReview200ResponseFromJSON,
    CreateReview200ResponseToJSON,
    CreateReviewRequestFromJSON,
    CreateReviewRequestToJSON,
    CreateUserAddress201ResponseFromJSON,
    CreateUserAddress201ResponseToJSON,
    CreateUserAddressRequestFromJSON,
    CreateUserAddressRequestToJSON,
    CreateUserMultipassToken201ResponseFromJSON,
    CreateUserMultipassToken201ResponseToJSON,
    CreateUserPaymentMethod201ResponseFromJSON,
    CreateUserPaymentMethod201ResponseToJSON,
    CreateUserPaymentMethodRequestFromJSON,
    CreateUserPaymentMethodRequestToJSON,
    DeleteUserAddress200ResponseFromJSON,
    DeleteUserAddress200ResponseToJSON,
    DeleteUserPaymentMethod200ResponseFromJSON,
    DeleteUserPaymentMethod200ResponseToJSON,
    DeleteUserRequestFromJSON,
    DeleteUserRequestToJSON,
    DisconnectUserProvider200ResponseFromJSON,
    DisconnectUserProvider200ResponseToJSON,
    GetCarouselItems200ResponseFromJSON,
    GetCarouselItems200ResponseToJSON,
    GetCheckout200ResponseFromJSON,
    GetCheckout200ResponseToJSON,
    GetCheckouts200ResponseFromJSON,
    GetCheckouts200ResponseToJSON,
    GetCurrentUser200ResponseFromJSON,
    GetCurrentUser200ResponseToJSON,
    GetDeliverableAddresses200ResponseFromJSON,
    GetDeliverableAddresses200ResponseToJSON,
    GetFavorites200ResponseFromJSON,
    GetFavorites200ResponseToJSON,
    GetProduct200ResponseFromJSON,
    GetProduct200ResponseToJSON,
    GetProductShippingRates200ResponseFromJSON,
    GetProductShippingRates200ResponseToJSON,
    GetProducts200ResponseFromJSON,
    GetProducts200ResponseToJSON,
    GetRankingProducts200ResponseFromJSON,
    GetRankingProducts200ResponseToJSON,
    GetReviews200ResponseFromJSON,
    GetReviews200ResponseToJSON,
    GetSale200ResponseFromJSON,
    GetSale200ResponseToJSON,
    GetSaleProducts200ResponseFromJSON,
    GetSaleProducts200ResponseToJSON,
    GetSales200ResponseFromJSON,
    GetSales200ResponseToJSON,
    GetTagFamilyTag200ResponseFromJSON,
    GetTagFamilyTag200ResponseToJSON,
    GetTagFamilyTags200ResponseFromJSON,
    GetTagFamilyTags200ResponseToJSON,
    GetUserAddress200ResponseFromJSON,
    GetUserAddress200ResponseToJSON,
    GetUserAddresses200ResponseFromJSON,
    GetUserAddresses200ResponseToJSON,
    GetUserCouponCodes200ResponseFromJSON,
    GetUserCouponCodes200ResponseToJSON,
    GetUserPaymentMethods200ResponseFromJSON,
    GetUserPaymentMethods200ResponseToJSON,
    GetUserSession200ResponseFromJSON,
    GetUserSession200ResponseToJSON,
    PostPreRegistrations201ResponseFromJSON,
    PostPreRegistrations201ResponseToJSON,
    PostPreRegistrationsRequestFromJSON,
    PostPreRegistrationsRequestToJSON,
    RemoveCartItemRequestFromJSON,
    RemoveCartItemRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    ResponseErrorBadRequestFromJSON,
    ResponseErrorBadRequestToJSON,
    ResponseErrorConflictFromJSON,
    ResponseErrorConflictToJSON,
    ResponseErrorForbiddenFromJSON,
    ResponseErrorForbiddenToJSON,
    ResponseErrorInvalidRecordFromJSON,
    ResponseErrorInvalidRecordToJSON,
    ResponseErrorNotAcceptableFromJSON,
    ResponseErrorNotAcceptableToJSON,
    ResponseErrorNotFoundFromJSON,
    ResponseErrorNotFoundToJSON,
    ResponseErrorUnauthorizedFromJSON,
    ResponseErrorUnauthorizedToJSON,
    ResponseSuccessFromJSON,
    ResponseSuccessToJSON,
    SendConfirmUserEmailRequestFromJSON,
    SendConfirmUserEmailRequestToJSON,
    SendConfirmUserPhoneRequestFromJSON,
    SendConfirmUserPhoneRequestToJSON,
    SendResetMailRequestFromJSON,
    SendResetMailRequestToJSON,
    SignInUserRequestFromJSON,
    SignInUserRequestToJSON,
    SignUpUser201ResponseFromJSON,
    SignUpUser201ResponseToJSON,
    SignUpUserRequestFromJSON,
    SignUpUserRequestToJSON,
    UpdateCheckoutPaymentMethodRequestFromJSON,
    UpdateCheckoutPaymentMethodRequestToJSON,
    UpdateUserBirthDateRequestFromJSON,
    UpdateUserBirthDateRequestToJSON,
    UpdateUserEmailMarketingAgreedRequestFromJSON,
    UpdateUserEmailMarketingAgreedRequestToJSON,
    UpdateUserEmailRequestFromJSON,
    UpdateUserEmailRequestToJSON,
    UpdateUserNameRequestFromJSON,
    UpdateUserNameRequestToJSON,
    UpdateUserNotificationSetting200ResponseFromJSON,
    UpdateUserNotificationSetting200ResponseToJSON,
    UpdateUserNotificationSettingRequestFromJSON,
    UpdateUserNotificationSettingRequestToJSON,
    UpdateUserPaymentMethodRequestFromJSON,
    UpdateUserPaymentMethodRequestToJSON,
} from '../models';

export interface AddCartItemOperationRequest {
    id: string;
    addCartItemRequest?: AddCartItemRequest;
}

export interface AddFavoriteOperationRequest {
    addFavoriteRequest?: AddFavoriteRequest;
}

export interface AssociateCartUserRequest {
    id: string;
}

export interface CalculateCheckoutOperationRequest {
    calculateCheckoutRequest?: CalculateCheckoutRequest;
}

export interface CancelCheckoutRequest {
    id: string;
}

export interface CompleteCheckoutRequest {
    id: string;
}

export interface ConfirmUserEmailOperationRequest {
    confirmUserEmailRequest?: ConfirmUserEmailRequest;
}

export interface ConfirmUserPhoneOperationRequest {
    confirmUserPhoneRequest?: ConfirmUserPhoneRequest;
}

export interface ConnectUserProviderOperationRequest {
    connectUserProviderRequest?: ConnectUserProviderRequest;
}

export interface CreateCartOperationRequest {
    createCartRequest?: CreateCartRequest;
}

export interface CreateCheckoutOperationRequest {
    createCheckoutRequest?: CreateCheckoutRequest;
}

export interface CreateDirectUploadOperationRequest {
    createDirectUploadRequest?: CreateDirectUploadRequest;
}

export interface CreateReviewOperationRequest {
    id: string;
    createReviewRequest?: CreateReviewRequest;
}

export interface CreateUserAddressOperationRequest {
    createUserAddressRequest?: CreateUserAddressRequest;
}

export interface CreateUserPaymentMethodOperationRequest {
    createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest;
}

export interface DeleteFavoriteRequest {
    id: string;
    type: DeleteFavoriteTypeEnum;
}

export interface DeleteUserOperationRequest {
    deleteUserRequest?: DeleteUserRequest;
}

export interface DeleteUserAddressRequest {
    id: string;
}

export interface DeleteUserPaymentMethodRequest {
    id: string;
}

export interface DisconnectUserProviderRequest {
    provider: DisconnectUserProviderProviderEnum;
}

export interface FailCheckoutRequest {
    id: string;
}

export interface GetCarouselItemsRequest {
    id: string;
}

export interface GetCartRequest {
    id: string;
}

export interface GetCheckoutRequest {
    id: string;
}

export interface GetCheckoutsRequest {
    page?: number;
}

export interface GetDeliverableAddressesRequest {
    zipCode?: string;
}

export interface GetFavoritesRequest {
    type: GetFavoritesTypeEnum;
    page?: number;
}

export interface GetProductRequest {
    id: string;
}

export interface GetProductShippingRatesRequest {
    id: string;
    zipCode?: string;
}

export interface GetProductsRequest {
    page?: number;
    category?: string;
    keyword?: string;
    sort?: GetProductsSortEnum;
    tag?: string;
}

export interface GetRankingProductsRequest {
    id: string;
    page?: number;
}

export interface GetReviewRequest {
    productId: string;
    reviewId: string;
}

export interface GetReviewsRequest {
    id: string;
    page?: number;
}

export interface GetSaleRequest {
    id: string;
}

export interface GetSaleProductsRequest {
    id: string;
    page?: number;
}

export interface GetTagFamilyTagRequest {
    handle: string;
    id: string;
}

export interface GetTagFamilyTagsRequest {
    handle: string;
}

export interface GetUserAddressRequest {
    id: string;
}

export interface PostPreRegistrationsOperationRequest {
    postPreRegistrationsRequest?: PostPreRegistrationsRequest;
}

export interface RemoveCartItemOperationRequest {
    id: string;
    removeCartItemRequest?: RemoveCartItemRequest;
}

export interface ResetPasswordOperationRequest {
    resetPasswordRequest?: ResetPasswordRequest;
}

export interface SendConfirmUserEmailOperationRequest {
    sendConfirmUserEmailRequest?: SendConfirmUserEmailRequest;
}

export interface SendConfirmUserPhoneOperationRequest {
    sendConfirmUserPhoneRequest?: SendConfirmUserPhoneRequest;
}

export interface SendResetMailOperationRequest {
    sendResetMailRequest?: SendResetMailRequest;
}

export interface SignInUserOperationRequest {
    signInUserRequest?: SignInUserRequest;
}

export interface SignUpUserOperationRequest {
    signUpUserRequest?: SignUpUserRequest;
}

export interface UpdateCartItemRequest {
    id: string;
    addCartItemRequest?: AddCartItemRequest;
}

export interface UpdateCheckoutPaymentMethodOperationRequest {
    id: string;
    updateCheckoutPaymentMethodRequest?: UpdateCheckoutPaymentMethodRequest;
}

export interface UpdateUserAddressRequest {
    id: string;
    createUserAddressRequest?: CreateUserAddressRequest;
}

export interface UpdateUserBirthDateOperationRequest {
    updateUserBirthDateRequest?: UpdateUserBirthDateRequest;
}

export interface UpdateUserEmailOperationRequest {
    updateUserEmailRequest?: UpdateUserEmailRequest;
}

export interface UpdateUserEmailMarketingAgreedOperationRequest {
    updateUserEmailMarketingAgreedRequest?: UpdateUserEmailMarketingAgreedRequest;
}

export interface UpdateUserNameOperationRequest {
    updateUserNameRequest?: UpdateUserNameRequest;
}

export interface UpdateUserNotificationSettingOperationRequest {
    updateUserNotificationSettingRequest?: UpdateUserNotificationSettingRequest;
}

export interface UpdateUserPaymentMethodOperationRequest {
    id: string;
    updateUserPaymentMethodRequest?: UpdateUserPaymentMethodRequest;
}

/**
 * 
 */
export class StoreApi extends runtime.BaseAPI {

    /**
     * カートにアイテムを追加する
     * add cart item
     */
    async addCartItemRaw(requestParameters: AddCartItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addCartItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddCartItemRequestToJSON(requestParameters.addCartItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カートにアイテムを追加する
     * add cart item
     */
    async addCartItem(id: string, addCartItemRequest?: AddCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.addCartItemRaw({ id: id, addCartItemRequest: addCartItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * お気に入りのアイテムを追加する
     * Add Favorite
     */
    async addFavoriteRaw(requestParameters: AddFavoriteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddFavorite201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/favorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddFavoriteRequestToJSON(requestParameters.addFavoriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddFavorite201ResponseFromJSON(jsonValue));
    }

    /**
     * お気に入りのアイテムを追加する
     * Add Favorite
     */
    async addFavorite(addFavoriteRequest?: AddFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddFavorite201Response> {
        const response = await this.addFavoriteRaw({ addFavoriteRequest: addFavoriteRequest }, initOverrides);
        return await response.value();
    }

    /**
     * カートとユーザーを紐付ける
     * associate cart user
     */
    async associateCartUserRaw(requestParameters: AssociateCartUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling associateCartUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts/{id}/user`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カートとユーザーを紐付ける
     * associate cart user
     */
    async associateCartUser(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.associateCartUserRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを計算する
     * create checkouts
     */
    async calculateCheckoutRaw(requestParameters: CalculateCheckoutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalculateCheckout201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/calculate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalculateCheckoutRequestToJSON(requestParameters.calculateCheckoutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalculateCheckout201ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを計算する
     * create checkouts
     */
    async calculateCheckout(calculateCheckoutRequest?: CalculateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalculateCheckout201Response> {
        const response = await this.calculateCheckoutRaw({ calculateCheckoutRequest: calculateCheckoutRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 注文をキャンセルする
     * cancel checkout
     */
    async cancelCheckoutRaw(requestParameters: CancelCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 注文をキャンセルする
     * cancel checkout
     */
    async cancelCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.cancelCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを完了させる
     * complete checkout
     */
    async completeCheckoutRaw(requestParameters: CompleteCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを完了させる
     * complete checkout
     */
    async completeCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.completeCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * メールアドレスを認証する
     * 
     */
    async confirmUserEmailRaw(requestParameters: ConfirmUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/confirmations/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmUserEmailRequestToJSON(requestParameters.confirmUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * メールアドレスを認証する
     * 
     */
    async confirmUserEmail(confirmUserEmailRequest?: ConfirmUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.confirmUserEmailRaw({ confirmUserEmailRequest: confirmUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 電話番号を認証する
     * 
     */
    async confirmUserPhoneRaw(requestParameters: ConfirmUserPhoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/confirmations/phone`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmUserPhoneRequestToJSON(requestParameters.confirmUserPhoneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * 電話番号を認証する
     * 
     */
    async confirmUserPhone(confirmUserPhoneRequest?: ConfirmUserPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.confirmUserPhoneRaw({ confirmUserPhoneRequest: confirmUserPhoneRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーOauth連携
     * connect oauth user
     */
    async connectUserProviderRaw(requestParameters: ConnectUserProviderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/providers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConnectUserProviderRequestToJSON(requestParameters.connectUserProviderRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーOauth連携
     * connect oauth user
     */
    async connectUserProvider(connectUserProviderRequest?: ConnectUserProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpUser201Response> {
        const response = await this.connectUserProviderRaw({ connectUserProviderRequest: connectUserProviderRequest }, initOverrides);
        return await response.value();
    }

    /**
     * カートを作成する
     * create cart
     */
    async createCartRaw(requestParameters: CreateCartOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCartRequestToJSON(requestParameters.createCartRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カートを作成する
     * create cart
     */
    async createCart(createCartRequest?: CreateCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.createCartRaw({ createCartRequest: createCartRequest }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを作成する
     * create checkouts
     */
    async createCheckoutRaw(requestParameters: CreateCheckoutOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCheckout201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCheckoutRequestToJSON(requestParameters.createCheckoutRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCheckout201ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを作成する
     * create checkouts
     */
    async createCheckout(createCheckoutRequest?: CreateCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCheckout201Response> {
        const response = await this.createCheckoutRaw({ createCheckoutRequest: createCheckoutRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ダイレクトアップロードのBlobレコードを作成する  
     * Create Direct Upload
     */
    async createDirectUploadRaw(requestParameters: CreateDirectUploadOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateDirectUpload200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/direct_uploads`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDirectUploadRequestToJSON(requestParameters.createDirectUploadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateDirectUpload200ResponseFromJSON(jsonValue));
    }

    /**
     * ダイレクトアップロードのBlobレコードを作成する  
     * Create Direct Upload
     */
    async createDirectUpload(createDirectUploadRequest?: CreateDirectUploadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateDirectUpload200Response> {
        const response = await this.createDirectUploadRaw({ createDirectUploadRequest: createDirectUploadRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 商品のレビューを投稿する
     * Create Review
     */
    async createReviewRaw(requestParameters: CreateReviewOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateReview200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}/reviews`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateReviewRequestToJSON(requestParameters.createReviewRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReview200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品のレビューを投稿する
     * Create Review
     */
    async createReview(id: string, createReviewRequest?: CreateReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateReview200Response> {
        const response = await this.createReviewRaw({ id: id, createReviewRequest: createReviewRequest }, initOverrides);
        return await response.value();
    }

    /**
     * お届け先を登録する
     * 
     */
    async createUserAddressRaw(requestParameters: CreateUserAddressOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserAddress201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/addresses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserAddressRequestToJSON(requestParameters.createUserAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserAddress201ResponseFromJSON(jsonValue));
    }

    /**
     * お届け先を登録する
     * 
     */
    async createUserAddress(createUserAddressRequest?: CreateUserAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserAddress201Response> {
        const response = await this.createUserAddressRaw({ createUserAddressRequest: createUserAddressRequest }, initOverrides);
        return await response.value();
    }

    /**
     * multipass tokenを生成する
     * 
     */
    async createUserMultipassTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserMultipassToken201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/multipass_tokens`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserMultipassToken201ResponseFromJSON(jsonValue));
    }

    /**
     * multipass tokenを生成する
     * 
     */
    async createUserMultipassToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserMultipassToken201Response> {
        const response = await this.createUserMultipassTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethodRaw(requestParameters: CreateUserPaymentMethodOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserPaymentMethod201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserPaymentMethodRequestToJSON(requestParameters.createUserPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserPaymentMethod201ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を登録する
     * 
     */
    async createUserPaymentMethod(createUserPaymentMethodRequest?: CreateUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserPaymentMethod201Response> {
        const response = await this.createUserPaymentMethodRaw({ createUserPaymentMethodRequest: createUserPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

    /**
     * お気に入りを解除する
     * Remove Favorite Item
     */
    async deleteFavoriteRaw(requestParameters: DeleteFavoriteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFavorite.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling deleteFavorite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/favorites/{type}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * お気に入りを解除する
     * Remove Favorite Item
     */
    async deleteFavorite(id: string, type: DeleteFavoriteTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteFavoriteRaw({ id: id, type: type }, initOverrides);
    }

    /**
     * 退会
     * delete user
     */
    async deleteUserRaw(requestParameters: DeleteUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteUserRequestToJSON(requestParameters.deleteUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 退会
     * delete user
     */
    async deleteUser(deleteUserRequest?: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.deleteUserRaw({ deleteUserRequest: deleteUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * お届け先を削除する
     * 
     */
    async deleteUserAddressRaw(requestParameters: DeleteUserAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteUserAddress200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteUserAddress200ResponseFromJSON(jsonValue));
    }

    /**
     * お届け先を削除する
     * 
     */
    async deleteUserAddress(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteUserAddress200Response> {
        const response = await this.deleteUserAddressRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を削除する
     * 
     */
    async deleteUserPaymentMethodRaw(requestParameters: DeleteUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteUserPaymentMethod200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteUserPaymentMethod200ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を削除する
     * 
     */
    async deleteUserPaymentMethod(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteUserPaymentMethod200Response> {
        const response = await this.deleteUserPaymentMethodRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーOAuthの接続解除
     * 
     */
    async disconnectUserProviderRaw(requestParameters: DisconnectUserProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        if (requestParameters.provider === null || requestParameters.provider === undefined) {
            throw new runtime.RequiredError('provider','Required parameter requestParameters.provider was null or undefined when calling disconnectUserProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/providers/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters.provider))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーOAuthの接続解除
     * 
     */
    async disconnectUserProvider(provider: DisconnectUserProviderProviderEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.disconnectUserProviderRaw({ provider: provider }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを失敗させる
     * complete checkout
     */
    async failCheckoutRaw(requestParameters: FailCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling failCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/fail`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを失敗させる
     * complete checkout
     */
    async failCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.failCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * カルーセルアイテムを取得する
     * get carousel items
     */
    async getCarouselItemsRaw(requestParameters: GetCarouselItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCarouselItems200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCarouselItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/carousels/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCarouselItems200ResponseFromJSON(jsonValue));
    }

    /**
     * カルーセルアイテムを取得する
     * get carousel items
     */
    async getCarouselItems(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCarouselItems200Response> {
        const response = await this.getCarouselItemsRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * カート情報を取得
     * get cart
     */
    async getCartRaw(requestParameters: GetCartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カート情報を取得
     * get cart
     */
    async getCart(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.getCartRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * チェックアウトを取得する
     * get checkout
     */
    async getCheckoutRaw(requestParameters: GetCheckoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckout.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * チェックアウトを取得する
     * get checkout
     */
    async getCheckout(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.getCheckoutRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのチェックアウト情報をページ単位で取得
     * get checkouts
     */
    async getCheckoutsRaw(requestParameters: GetCheckoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckouts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckouts200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのチェックアウト情報をページ単位で取得
     * get checkouts
     */
    async getCheckouts(page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckouts200Response> {
        const response = await this.getCheckoutsRaw({ page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 現在ログインしているユーザー
     * Get Current User
     */
    async getCurrentUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCurrentUser200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentUser200ResponseFromJSON(jsonValue));
    }

    /**
     * 現在ログインしているユーザー
     * Get Current User
     */
    async getCurrentUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCurrentUser200Response> {
        const response = await this.getCurrentUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * 配送可能なエリアを取得する
     * get is deliverable address by zip code
     */
    async getDeliverableAddressesRaw(requestParameters: GetDeliverableAddressesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDeliverableAddresses200Response>> {
        const queryParameters: any = {};

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zip_code'] = requestParameters.zipCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/addresses/deliverables`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDeliverableAddresses200ResponseFromJSON(jsonValue));
    }

    /**
     * 配送可能なエリアを取得する
     * get is deliverable address by zip code
     */
    async getDeliverableAddresses(zipCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDeliverableAddresses200Response> {
        const response = await this.getDeliverableAddressesRaw({ zipCode: zipCode }, initOverrides);
        return await response.value();
    }

    /**
     * お気に入りに登録済のアイテム一覧を取得する
     * Get Favorites
     */
    async getFavoritesRaw(requestParameters: GetFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFavorites200Response>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getFavorites.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/favorites/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFavorites200ResponseFromJSON(jsonValue));
    }

    /**
     * お気に入りに登録済のアイテム一覧を取得する
     * Get Favorites
     */
    async getFavorites(type: GetFavoritesTypeEnum, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFavorites200Response> {
        const response = await this.getFavoritesRaw({ type: type, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 商品情報を取得する
     * get product
     */
    async getProductRaw(requestParameters: GetProductRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProduct200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProduct.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProduct200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品情報を取得する
     * get product
     */
    async getProduct(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProduct200Response> {
        const response = await this.getProductRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * 商品に対する配送料の一覧を取得する
     * get shipping rates
     */
    async getProductShippingRatesRaw(requestParameters: GetProductShippingRatesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductShippingRates200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProductShippingRates.');
        }

        const queryParameters: any = {};

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zip_code'] = requestParameters.zipCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{id}/shipping_rates`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductShippingRates200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品に対する配送料の一覧を取得する
     * get shipping rates
     */
    async getProductShippingRates(id: string, zipCode?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductShippingRates200Response> {
        const response = await this.getProductShippingRatesRaw({ id: id, zipCode: zipCode }, initOverrides);
        return await response.value();
    }

    /**
     * 商品一覧を取得する
     * get products
     */
    async getProductsRaw(requestParameters: GetProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200Response>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.sort !== undefined) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品一覧を取得する
     * get products
     */
    async getProducts(page?: number, category?: string, keyword?: string, sort?: GetProductsSortEnum, tag?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200Response> {
        const response = await this.getProductsRaw({ page: page, category: category, keyword: keyword, sort: sort, tag: tag }, initOverrides);
        return await response.value();
    }

    /**
     * ランキングの商品一覧を取得する
     * get ranking products
     */
    async getRankingProductsRaw(requestParameters: GetRankingProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRankingProducts200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRankingProducts.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/rankings/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRankingProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * ランキングの商品一覧を取得する
     * get ranking products
     */
    async getRankingProducts(id: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRankingProducts200Response> {
        const response = await this.getRankingProductsRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 商品のレビュー詳細を取得する
     * Get Review
     */
    async getReviewRaw(requestParameters: GetReviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateReview200Response>> {
        if (requestParameters.productId === null || requestParameters.productId === undefined) {
            throw new runtime.RequiredError('productId','Required parameter requestParameters.productId was null or undefined when calling getReview.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling getReview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{product_id}/reviews/{review_id}`.replace(`{${"product_id"}}`, encodeURIComponent(String(requestParameters.productId))).replace(`{${"review_id"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateReview200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品のレビュー詳細を取得する
     * Get Review
     */
    async getReview(productId: string, reviewId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateReview200Response> {
        const response = await this.getReviewRaw({ productId: productId, reviewId: reviewId }, initOverrides);
        return await response.value();
    }

    /**
     * 商品のレビュー一覧を取得する
     * Get Reviews
     */
    async getReviewsRaw(requestParameters: GetReviewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReviews200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReviews.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/products/{id}/reviews`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReviews200ResponseFromJSON(jsonValue));
    }

    /**
     * 商品のレビュー一覧を取得する
     * Get Reviews
     */
    async getReviews(id: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReviews200Response> {
        const response = await this.getReviewsRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * セールを取得する
     * get sales
     */
    async getSaleRaw(requestParameters: GetSaleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSale200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSale.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sales/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSale200ResponseFromJSON(jsonValue));
    }

    /**
     * セールを取得する
     * get sales
     */
    async getSale(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSale200Response> {
        const response = await this.getSaleRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * セールの商品一覧を取得する
     * get sale products
     */
    async getSaleProductsRaw(requestParameters: GetSaleProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSaleProducts200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSaleProducts.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sales/{id}/products`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSaleProducts200ResponseFromJSON(jsonValue));
    }

    /**
     * セールの商品一覧を取得する
     * get sale products
     */
    async getSaleProducts(id: string, page?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSaleProducts200Response> {
        const response = await this.getSaleProductsRaw({ id: id, page: page }, initOverrides);
        return await response.value();
    }

    /**
     * 開催中のセールを取得する
     * get sales
     */
    async getSalesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSales200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSales200ResponseFromJSON(jsonValue));
    }

    /**
     * 開催中のセールを取得する
     * get sales
     */
    async getSales(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSales200Response> {
        const response = await this.getSalesRaw(initOverrides);
        return await response.value();
    }

    /**
     * タグファミリーに所属するタグを取得する
     * get tag family tags
     */
    async getTagFamilyTagRaw(requestParameters: GetTagFamilyTagRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTagFamilyTag200Response>> {
        if (requestParameters.handle === null || requestParameters.handle === undefined) {
            throw new runtime.RequiredError('handle','Required parameter requestParameters.handle was null or undefined when calling getTagFamilyTag.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getTagFamilyTag.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tag_families/{handle}/tags/{id}`.replace(`{${"handle"}}`, encodeURIComponent(String(requestParameters.handle))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTagFamilyTag200ResponseFromJSON(jsonValue));
    }

    /**
     * タグファミリーに所属するタグを取得する
     * get tag family tags
     */
    async getTagFamilyTag(handle: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTagFamilyTag200Response> {
        const response = await this.getTagFamilyTagRaw({ handle: handle, id: id }, initOverrides);
        return await response.value();
    }

    /**
     * タグファミリーに所属するタグを取得する
     * get tag family tags
     */
    async getTagFamilyTagsRaw(requestParameters: GetTagFamilyTagsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTagFamilyTags200Response>> {
        if (requestParameters.handle === null || requestParameters.handle === undefined) {
            throw new runtime.RequiredError('handle','Required parameter requestParameters.handle was null or undefined when calling getTagFamilyTags.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tag_families/{handle}/tags`.replace(`{${"handle"}}`, encodeURIComponent(String(requestParameters.handle))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTagFamilyTags200ResponseFromJSON(jsonValue));
    }

    /**
     * タグファミリーに所属するタグを取得する
     * get tag family tags
     */
    async getTagFamilyTags(handle: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTagFamilyTags200Response> {
        const response = await this.getTagFamilyTagsRaw({ handle: handle }, initOverrides);
        return await response.value();
    }

    /**
     * お届け先を取得
     * get user address
     */
    async getUserAddressRaw(requestParameters: GetUserAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserAddress200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserAddress200ResponseFromJSON(jsonValue));
    }

    /**
     * お届け先を取得
     * get user address
     */
    async getUserAddress(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserAddress200Response> {
        const response = await this.getUserAddressRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * お届け先一覧を取得
     * get user addresses
     */
    async getUserAddressesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserAddresses200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/addresses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserAddresses200ResponseFromJSON(jsonValue));
    }

    /**
     * お届け先一覧を取得
     * get user addresses
     */
    async getUserAddresses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserAddresses200Response> {
        const response = await this.getUserAddressesRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザーに紐づくクーポン一覧を取得する
     * 
     */
    async getUserCouponCodesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserCouponCodes200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/coupon_codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserCouponCodes200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーに紐づくクーポン一覧を取得する
     * 
     */
    async getUserCouponCodes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserCouponCodes200Response> {
        const response = await this.getUserCouponCodesRaw(initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法の一覧を取得する
     * 
     */
    async getUserPaymentMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserPaymentMethods200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserPaymentMethods200ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法の一覧を取得する
     * 
     */
    async getUserPaymentMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserPaymentMethods200Response> {
        const response = await this.getUserPaymentMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 認証状態を取得する
     * 
     */
    async getUserSessionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserSession200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserSession200ResponseFromJSON(jsonValue));
    }

    /**
     * 認証状態を取得する
     * 
     */
    async getUserSession(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserSession200Response> {
        const response = await this.getUserSessionRaw(initOverrides);
        return await response.value();
    }

    /**
     * 事前登録を受け付けるエンドポイント
     * create pre registration
     */
    async postPreRegistrationsRaw(requestParameters: PostPreRegistrationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPreRegistrations201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pre_registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPreRegistrationsRequestToJSON(requestParameters.postPreRegistrationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPreRegistrations201ResponseFromJSON(jsonValue));
    }

    /**
     * 事前登録を受け付けるエンドポイント
     * create pre registration
     */
    async postPreRegistrations(postPreRegistrationsRequest?: PostPreRegistrationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPreRegistrations201Response> {
        const response = await this.postPreRegistrationsRaw({ postPreRegistrationsRequest: postPreRegistrationsRequest }, initOverrides);
        return await response.value();
    }

    /**
     * カート内アイテムを削除する
     * remove cart item
     */
    async removeCartItemRaw(requestParameters: RemoveCartItemOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling removeCartItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveCartItemRequestToJSON(requestParameters.removeCartItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カート内アイテムを削除する
     * remove cart item
     */
    async removeCartItem(id: string, removeCartItemRequest?: RemoveCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.removeCartItemRaw({ id: id, removeCartItemRequest: removeCartItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.resetPasswordRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     */
    async resetPassword(resetPasswordRequest?: ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.resetPasswordRaw({ resetPasswordRequest: resetPasswordRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 認証用のメールを送信する
     * 
     */
    async sendConfirmUserEmailRaw(requestParameters: SendConfirmUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/confirmations/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendConfirmUserEmailRequestToJSON(requestParameters.sendConfirmUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * 認証用のメールを送信する
     * 
     */
    async sendConfirmUserEmail(sendConfirmUserEmailRequest?: SendConfirmUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.sendConfirmUserEmailRaw({ sendConfirmUserEmailRequest: sendConfirmUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 認証用のSMSを送信する
     * 
     */
    async sendConfirmUserPhoneRaw(requestParameters: SendConfirmUserPhoneOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/confirmations/phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendConfirmUserPhoneRequestToJSON(requestParameters.sendConfirmUserPhoneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * 認証用のSMSを送信する
     * 
     */
    async sendConfirmUserPhone(sendConfirmUserPhoneRequest?: SendConfirmUserPhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.sendConfirmUserPhoneRaw({ sendConfirmUserPhoneRequest: sendConfirmUserPhoneRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 
     * reset password
     */
    async sendResetMailRaw(requestParameters: SendResetMailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendResetMailRequestToJSON(requestParameters.sendResetMailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * 
     * reset password
     */
    async sendResetMail(sendResetMailRequest?: SendResetMailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.sendResetMailRaw({ sendResetMailRequest: sendResetMailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーログイン
     * sign in user
     */
    async signInUserRaw(requestParameters: SignInUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/sessions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInUserRequestToJSON(requestParameters.signInUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーログイン
     * sign in user
     */
    async signInUser(signInUserRequest?: SignInUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpUser201Response> {
        const response = await this.signInUserRaw({ signInUserRequest: signInUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ログアウトさせる
     * 
     */
    async signOutUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * ログアウトさせる
     * 
     */
    async signOutUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.signOutUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * ユーザー登録
     * sign up user
     */
    async signUpUserRaw(requestParameters: SignUpUserOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpUser201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpUserRequestToJSON(requestParameters.signUpUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpUser201ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー登録
     * sign up user
     */
    async signUpUser(signUpUserRequest?: SignUpUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpUser201Response> {
        const response = await this.signUpUserRaw({ signUpUserRequest: signUpUserRequest }, initOverrides);
        return await response.value();
    }

    /**
     * カート内アイテムを更新する
     * update cart item
     */
    async updateCartItemRaw(requestParameters: UpdateCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCart201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCartItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/carts/{id}/items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AddCartItemRequestToJSON(requestParameters.addCartItemRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCart201ResponseFromJSON(jsonValue));
    }

    /**
     * カート内アイテムを更新する
     * update cart item
     */
    async updateCartItem(id: string, addCartItemRequest?: AddCartItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCart201Response> {
        const response = await this.updateCartItemRaw({ id: id, addCartItemRequest: addCartItemRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 注文の支払い方法を更新する
     * update checkout payment method
     */
    async updateCheckoutPaymentMethodRaw(requestParameters: UpdateCheckoutPaymentMethodOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCheckout200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCheckoutPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/checkouts/{id}/payment_method`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCheckoutPaymentMethodRequestToJSON(requestParameters.updateCheckoutPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCheckout200ResponseFromJSON(jsonValue));
    }

    /**
     * 注文の支払い方法を更新する
     * update checkout payment method
     */
    async updateCheckoutPaymentMethod(id: string, updateCheckoutPaymentMethodRequest?: UpdateCheckoutPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCheckout200Response> {
        const response = await this.updateCheckoutPaymentMethodRaw({ id: id, updateCheckoutPaymentMethodRequest: updateCheckoutPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

    /**
     * お届け先を更新する
     * 
     */
    async updateUserAddressRaw(requestParameters: UpdateUserAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserAddress201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/addresses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserAddressRequestToJSON(requestParameters.createUserAddressRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserAddress201ResponseFromJSON(jsonValue));
    }

    /**
     * お届け先を更新する
     * 
     */
    async updateUserAddress(id: string, createUserAddressRequest?: CreateUserAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserAddress201Response> {
        const response = await this.updateUserAddressRaw({ id: id, createUserAddressRequest: createUserAddressRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 自分の生年月日を設定する。すでに設定済の場合は400エラーが発生する
     * update user birth date
     */
    async updateUserBirthDateRaw(requestParameters: UpdateUserBirthDateOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCurrentUser200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/birth_date`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserBirthDateRequestToJSON(requestParameters.updateUserBirthDateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentUser200ResponseFromJSON(jsonValue));
    }

    /**
     * 自分の生年月日を設定する。すでに設定済の場合は400エラーが発生する
     * update user birth date
     */
    async updateUserBirthDate(updateUserBirthDateRequest?: UpdateUserBirthDateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCurrentUser200Response> {
        const response = await this.updateUserBirthDateRaw({ updateUserBirthDateRequest: updateUserBirthDateRequest }, initOverrides);
        return await response.value();
    }

    /**
     * update user email
     */
    async updateUserEmailRaw(requestParameters: UpdateUserEmailOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseSuccess>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserEmailRequestToJSON(requestParameters.updateUserEmailRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseSuccessFromJSON(jsonValue));
    }

    /**
     * update user email
     */
    async updateUserEmail(updateUserEmailRequest?: UpdateUserEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseSuccess> {
        const response = await this.updateUserEmailRaw({ updateUserEmailRequest: updateUserEmailRequest }, initOverrides);
        return await response.value();
    }

    /**
     * メールマーケティングの受け取り設定を更新する
     * update email_marketing_agreed
     */
    async updateUserEmailMarketingAgreedRaw(requestParameters: UpdateUserEmailMarketingAgreedOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisconnectUserProvider200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/email_marketing_agreed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserEmailMarketingAgreedRequestToJSON(requestParameters.updateUserEmailMarketingAgreedRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisconnectUserProvider200ResponseFromJSON(jsonValue));
    }

    /**
     * メールマーケティングの受け取り設定を更新する
     * update email_marketing_agreed
     */
    async updateUserEmailMarketingAgreed(updateUserEmailMarketingAgreedRequest?: UpdateUserEmailMarketingAgreedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisconnectUserProvider200Response> {
        const response = await this.updateUserEmailMarketingAgreedRaw({ updateUserEmailMarketingAgreedRequest: updateUserEmailMarketingAgreedRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの氏名（姓・名）を更新する
     * Update user name
     */
    async updateUserNameRaw(requestParameters: UpdateUserNameOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetCurrentUser200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/name`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserNameRequestToJSON(requestParameters.updateUserNameRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetCurrentUser200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの氏名（姓・名）を更新する
     * Update user name
     */
    async updateUserName(updateUserNameRequest?: UpdateUserNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetCurrentUser200Response> {
        const response = await this.updateUserNameRaw({ updateUserNameRequest: updateUserNameRequest }, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの通知受信設定を（部分的に）更新する
     * Update user notification setting
     */
    async updateUserNotificationSettingRaw(requestParameters: UpdateUserNotificationSettingOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateUserNotificationSetting200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/notification_setting`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserNotificationSettingRequestToJSON(requestParameters.updateUserNotificationSettingRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateUserNotificationSetting200ResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの通知受信設定を（部分的に）更新する
     * Update user notification setting
     */
    async updateUserNotificationSetting(updateUserNotificationSettingRequest?: UpdateUserNotificationSettingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateUserNotificationSetting200Response> {
        const response = await this.updateUserNotificationSettingRaw({ updateUserNotificationSettingRequest: updateUserNotificationSettingRequest }, initOverrides);
        return await response.value();
    }

    /**
     * 支払い方法を更新する
     * 
     */
    async updateUserPaymentMethodRaw(requestParameters: UpdateUserPaymentMethodOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateUserPaymentMethod201Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUserPaymentMethod.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("HttpBearerTokenAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/payment_methods/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPaymentMethodRequestToJSON(requestParameters.updateUserPaymentMethodRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateUserPaymentMethod201ResponseFromJSON(jsonValue));
    }

    /**
     * 支払い方法を更新する
     * 
     */
    async updateUserPaymentMethod(id: string, updateUserPaymentMethodRequest?: UpdateUserPaymentMethodRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateUserPaymentMethod201Response> {
        const response = await this.updateUserPaymentMethodRaw({ id: id, updateUserPaymentMethodRequest: updateUserPaymentMethodRequest }, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const DeleteFavoriteTypeEnum = {
    Products: 'products'
} as const;
export type DeleteFavoriteTypeEnum = typeof DeleteFavoriteTypeEnum[keyof typeof DeleteFavoriteTypeEnum];
/**
 * @export
 */
export const DisconnectUserProviderProviderEnum = {
    Google: 'google'
} as const;
export type DisconnectUserProviderProviderEnum = typeof DisconnectUserProviderProviderEnum[keyof typeof DisconnectUserProviderProviderEnum];
/**
 * @export
 */
export const GetFavoritesTypeEnum = {
    Products: 'products'
} as const;
export type GetFavoritesTypeEnum = typeof GetFavoritesTypeEnum[keyof typeof GetFavoritesTypeEnum];
/**
 * @export
 */
export const GetProductsSortEnum = {
    ActivatedAtDesc: 'activated_at_desc',
    PriceAsc: 'price_asc',
    PriceDesc: 'price_desc'
} as const;
export type GetProductsSortEnum = typeof GetProductsSortEnum[keyof typeof GetProductsSortEnum];
