import { PhotoIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";

import { Product } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  product: Product;
};

export const ProductCarousel: BFC<Props> = ({
  product,
}) => {
  const [emblaMainRef, emblaMainApi] = useEmblaCarousel();
  const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
    containScroll: "keepSnaps",
    dragFree: true,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (emblaMainApi && emblaThumbsApi) {
      emblaMainApi.on("select", () => {
        setSelectedIndex(emblaMainApi.selectedScrollSnap());
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
      });
    }
  }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

  const onThumbClickFactory = useCallback((index: number) => () => {
    if (!emblaMainApi || !emblaThumbsApi) return;
    if (emblaThumbsApi.clickAllowed()) emblaMainApi.scrollTo(index);
  }, [emblaMainApi, emblaThumbsApi]);

  if (!product.images.length) {
    return (
      <div className="border rounded flex justify-center items-center aspect-square">
        <PhotoIcon className="text-black-200 w-8" />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-y-2">
        <div className="overflow-hidden" ref={emblaMainRef}>
          <div className="flex">
            {product.images.map((image) => (
              <div key={image.url} className="min-w-0 flex-[0_0_100%]">
                <Zoom>
                  <img
                    src={image.webp?.url ?? image.url}
                    className="rounded border aspect-square object-cover"
                    width="1000"
                    height="1000"
                  />
                </Zoom>
              </div>
            ))}
          </div>
        </div>

        <div className="overflow-hidden" ref={emblaThumbsRef}>
          <div className="flex gap-x-2">
            {product.images.map((image, i) => (
              <div
                key={image.webp?.url ?? image.url}
                className={classNames(
                  "min-w-0 flex-[0_0_24%] sm:flex-[0_0_10%]",
                  { "opacity-30": selectedIndex !== i },
                  { "opacity-100": selectedIndex === i }
                )}
                onClick={onThumbClickFactory(i)}
              >
                <img
                  src={image.webp?.url ?? image.url}
                  className="rounded border aspect-square object-cover"
                  width="300"
                  height="300"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
