/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveCartItemRequestItem
 */
export interface RemoveCartItemRequestItem {
    /**
     * 
     * @type {string}
     * @memberof RemoveCartItemRequestItem
     */
    productId: string;
}

/**
 * Check if a given object implements the RemoveCartItemRequestItem interface.
 */
export function instanceOfRemoveCartItemRequestItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;

    return isInstance;
}

export function RemoveCartItemRequestItemFromJSON(json: any): RemoveCartItemRequestItem {
    return RemoveCartItemRequestItemFromJSONTyped(json, false);
}

export function RemoveCartItemRequestItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveCartItemRequestItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
    };
}

export function RemoveCartItemRequestItemToJSON(value?: RemoveCartItemRequestItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
    };
}

