import { Router, useRouter } from "next/router";

export const getParam = (query: Router["query"] | undefined, key: string, defaultValue = "") => {
  const param = (query || {})[key];
  if (Array.isArray(param)) {
    return param[0] || defaultValue;
  }
  return param ?? defaultValue;
};

export const getParamAsNumber = (query: Router["query"] | undefined, key: string, defaultValue = 0) => {
  const param = getParam(query, key);
  return param ? parseInt(param, 10) : defaultValue;
};

export const useParam = (key: string, defaultValue = "") => {
  const router = useRouter();
  return getParam(router.query, key, defaultValue);
};
