import { useMutation } from "@tanstack/react-query";

import { MultipassToken } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useMultipassToken = () => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });

  const { mutateAsync, ...others } = useMutation(
    ["users/multipassToken"],
    () => api.createUserMultipassToken().then(({ data: { multipassToken } }) => new MultipassToken(multipassToken)),
  );

  return {
    create: mutateAsync,
    ...others,
  };
};
