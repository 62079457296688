/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CouponCode } from './CouponCode';
import {
    CouponCodeFromJSON,
    CouponCodeFromJSONTyped,
    CouponCodeToJSON,
} from './CouponCode';

/**
 * 
 * @export
 * @interface CheckoutDraft
 */
export interface CheckoutDraft {
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    subtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    minSubtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    maxSubtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    serviceCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    smallCheckoutCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    redeliveryCommission: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    redeliveryCommissionRate: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    totalShippingRate: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    minTotalTax: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    maxTotalTax: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    discountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    totalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    minTotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutDraft
     */
    maxTotalPrice: number;
    /**
     * 
     * @type {CouponCode}
     * @memberof CheckoutDraft
     */
    couponCode?: CouponCode;
}

/**
 * Check if a given object implements the CheckoutDraft interface.
 */
export function instanceOfCheckoutDraft(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "subtotalPrice" in value;
    isInstance = isInstance && "minSubtotalPrice" in value;
    isInstance = isInstance && "maxSubtotalPrice" in value;
    isInstance = isInstance && "serviceCommission" in value;
    isInstance = isInstance && "smallCheckoutCommission" in value;
    isInstance = isInstance && "redeliveryCommission" in value;
    isInstance = isInstance && "redeliveryCommissionRate" in value;
    isInstance = isInstance && "totalShippingRate" in value;
    isInstance = isInstance && "totalTax" in value;
    isInstance = isInstance && "minTotalTax" in value;
    isInstance = isInstance && "maxTotalTax" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "minTotalPrice" in value;
    isInstance = isInstance && "maxTotalPrice" in value;

    return isInstance;
}

export function CheckoutDraftFromJSON(json: any): CheckoutDraft {
    return CheckoutDraftFromJSONTyped(json, false);
}

export function CheckoutDraftFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutDraft {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subtotalPrice': json['subtotal_price'],
        'minSubtotalPrice': json['min_subtotal_price'],
        'maxSubtotalPrice': json['max_subtotal_price'],
        'serviceCommission': json['service_commission'],
        'smallCheckoutCommission': json['small_checkout_commission'],
        'redeliveryCommission': json['redelivery_commission'],
        'redeliveryCommissionRate': json['redelivery_commission_rate'],
        'totalShippingRate': json['total_shipping_rate'],
        'totalTax': json['total_tax'],
        'minTotalTax': json['min_total_tax'],
        'maxTotalTax': json['max_total_tax'],
        'discountPrice': json['discount_price'],
        'totalPrice': json['total_price'],
        'minTotalPrice': json['min_total_price'],
        'maxTotalPrice': json['max_total_price'],
        'couponCode': !exists(json, 'coupon_code') ? undefined : CouponCodeFromJSON(json['coupon_code']),
    };
}

export function CheckoutDraftToJSON(value?: CheckoutDraft | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subtotal_price': value.subtotalPrice,
        'min_subtotal_price': value.minSubtotalPrice,
        'max_subtotal_price': value.maxSubtotalPrice,
        'service_commission': value.serviceCommission,
        'small_checkout_commission': value.smallCheckoutCommission,
        'redelivery_commission': value.redeliveryCommission,
        'redelivery_commission_rate': value.redeliveryCommissionRate,
        'total_shipping_rate': value.totalShippingRate,
        'total_tax': value.totalTax,
        'min_total_tax': value.minTotalTax,
        'max_total_tax': value.maxTotalTax,
        'discount_price': value.discountPrice,
        'total_price': value.totalPrice,
        'min_total_price': value.minTotalPrice,
        'max_total_price': value.maxTotalPrice,
        'coupon_code': CouponCodeToJSON(value.couponCode),
    };
}

