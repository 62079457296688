import { useMutation } from "@tanstack/react-query";
import { useState } from "react";

import { UserMe, Token } from "shared/models";
import { useStoreAPI } from "shared/services/api";
import { SignUpUserRequest } from "shared/services/api/store";

import { useAuthContext } from "~/features/auth";
import { useCartContext } from "~/features/carts";

export const useSignUp = () => {
  const api = useStoreAPI();
  const { signIn, waitToken, setOnlyToken } = useAuthContext();
  const { cart, associateToUser } = useCartContext();
  const [isSignUpLoading, setIsSignUpLoading] = useState(false);

  const { mutateAsync, isLoading } = useMutation(
    ["users/signUp"],
    (data: SignUpUserRequest) => api.signUpUser(data),
    {
      onSuccess: async ({ data: { user, token: t } }) => {
        setIsSignUpLoading(true);
        setOnlyToken(new Token(t));
        await waitToken();
        await associateToUser(cart.id);
        signIn(new UserMe(user), new Token(t));
        setIsSignUpLoading(false);
      },
    },
  );

  return {
    signUp: mutateAsync,
    isSignUpLoading,
    isLoading: isLoading || isSignUpLoading,
  };
};
