/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttachableImage } from './AttachableImage';
import {
    AttachableImageFromJSON,
    AttachableImageFromJSONTyped,
    AttachableImageToJSON,
} from './AttachableImage';

/**
 * 
 * @export
 * @interface ProductImage
 */
export interface ProductImage {
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    url: string;
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImage
     */
    webp?: AttachableImage;
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImage
     */
    thumb?: AttachableImage;
    /**
     * 
     * @type {AttachableImage}
     * @memberof ProductImage
     */
    thumbWebp?: AttachableImage;
    /**
     * 
     * @type {number}
     * @memberof ProductImage
     */
    displayPriority: number;
    /**
     * 
     * @type {string}
     * @memberof ProductImage
     */
    caption?: string;
}

/**
 * Check if a given object implements the ProductImage interface.
 */
export function instanceOfProductImage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "displayPriority" in value;

    return isInstance;
}

export function ProductImageFromJSON(json: any): ProductImage {
    return ProductImageFromJSONTyped(json, false);
}

export function ProductImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductImage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'url': json['url'],
        'webp': !exists(json, 'webp') ? undefined : AttachableImageFromJSON(json['webp']),
        'thumb': !exists(json, 'thumb') ? undefined : AttachableImageFromJSON(json['thumb']),
        'thumbWebp': !exists(json, 'thumb_webp') ? undefined : AttachableImageFromJSON(json['thumb_webp']),
        'displayPriority': json['display_priority'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
    };
}

export function ProductImageToJSON(value?: ProductImage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'width': value.width,
        'height': value.height,
        'url': value.url,
        'webp': AttachableImageToJSON(value.webp),
        'thumb': AttachableImageToJSON(value.thumb),
        'thumb_webp': AttachableImageToJSON(value.thumbWebp),
        'display_priority': value.displayPriority,
        'caption': value.caption,
    };
}

