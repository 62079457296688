import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Product, ProductType } from "./product";
import { StockConsumptionWithStock } from "./stock_consumption";

export type FullfillmentStockingType = api.FullfillmentStocking;

export const FullfillmentStockingCancelReason = api.FullfillmentStockingCancelReasonEnum;
export type FullfillmentStockingCancelReason = typeof FullfillmentStockingCancelReason[keyof typeof FullfillmentStockingCancelReason];

export class FullfillmentStocking implements FullfillmentStockingType {
  [immerable] = true;

  id = "";
  product: ProductType = new Product();
  alternativeProduct?: ProductType;
  quantity = 0;
  cancelQuantity = 0;
  cancelReason: FullfillmentStockingCancelReason = FullfillmentStockingCancelReason.No;
  stockedAt = new Date();
  stockConsumptions: StockConsumptionWithStock[] = [];

  constructor(data: Partial<FullfillmentStockingType> = {}) {
    Object.assign(this, data);
  }

  getProduct() {
    return new Product(this.product);
  }

  getAlternativeProduct() {
    return this.alternativeProduct ? new Product(this.alternativeProduct) : null;
  }

  getTotalQuantity() {
    return this.quantity + this.cancelQuantity;
  }

  hasAlternativeProduct(): this is Omit<FullfillmentStocking, "alternativeProduct" | "getAlternativeProduct"> & {
    alternativeProduct: ProductType,
    getAlternativeProduct(): Product,
  } {
    return !!this.alternativeProduct;
  }
}
