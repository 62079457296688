import classNames from "classnames";
import { useMemo } from "react";

import { ProductStorageTemperature } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  small?: boolean;
  storageTemperature: ProductStorageTemperature;
};

export const StorageTemperatureBadge: BFC<Props> = ({
  small = false,
  storageTemperature,
  className,
}) => {
  const label = useMemo(() => {
    switch (storageTemperature) {
      case ProductStorageTemperature.Frozen:
        return "冷凍";
      case ProductStorageTemperature.Refrigerated:
      case ProductStorageTemperature.SemiRefrigerated:
        return "冷蔵";
      case ProductStorageTemperature.Normal:
        return "常温";
      case ProductStorageTemperature.Hotted:
        return "ホット";
    }
  }, [storageTemperature]);

  const classes = classNames(
    "inline-block text-white font-bold rounded",
    className,
    {
      "bg-green-500": storageTemperature === ProductStorageTemperature.Normal,
      "bg-sky-400": storageTemperature === ProductStorageTemperature.Refrigerated
        || storageTemperature === ProductStorageTemperature.SemiRefrigerated,
      "bg-blue-600": storageTemperature === ProductStorageTemperature.Frozen,
      "bg-red-500": storageTemperature === ProductStorageTemperature.Hotted,
      "text-xs px-1 py-px": small,
      "text-sm px-2 py-0.5": !small,
    },
  );

  return (
    <span className={classes}>{label}</span>
  );
};
