/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { CheckoutBilling } from './CheckoutBilling';
import {
    CheckoutBillingFromJSON,
    CheckoutBillingFromJSONTyped,
    CheckoutBillingToJSON,
} from './CheckoutBilling';
import type { CheckoutParcel } from './CheckoutParcel';
import {
    CheckoutParcelFromJSON,
    CheckoutParcelFromJSONTyped,
    CheckoutParcelToJSON,
} from './CheckoutParcel';
import type { CouponCode } from './CouponCode';
import {
    CouponCodeFromJSON,
    CouponCodeFromJSONTyped,
    CouponCodeToJSON,
} from './CouponCode';
import type { FullfillmentShipping } from './FullfillmentShipping';
import {
    FullfillmentShippingFromJSON,
    FullfillmentShippingFromJSONTyped,
    FullfillmentShippingToJSON,
} from './FullfillmentShipping';
import type { PaymentMinimum } from './PaymentMinimum';
import {
    PaymentMinimumFromJSON,
    PaymentMinimumFromJSONTyped,
    PaymentMinimumToJSON,
} from './PaymentMinimum';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface Checkout
 */
export interface Checkout {
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    serialNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    subtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    minSubtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    maxSubtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    serviceCommission: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    smallCheckoutCommission: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    redeliveryCommission: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    redeliveryCommissionRate: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    totalShippingRate: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    totalTax: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    maxTotalTax: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    minTotalTax: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    discountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    manualDiscountPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    totalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    minTotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof Checkout
     */
    maxTotalPrice: number;
    /**
     * 
     * @type {CouponCode}
     * @memberof Checkout
     */
    couponCode?: CouponCode;
    /**
     * 
     * @type {Array<CheckoutParcel>}
     * @memberof Checkout
     */
    parcels: Array<CheckoutParcel>;
    /**
     * 
     * @type {User}
     * @memberof Checkout
     */
    user: User;
    /**
     * 
     * @type {Address}
     * @memberof Checkout
     */
    shippingAddress: Address;
    /**
     * 
     * @type {boolean}
     * @memberof Checkout
     */
    isCancelable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Checkout
     */
    isBilled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Checkout
     */
    checkedOutAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Checkout
     */
    cancelableAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    stockingStatus: CheckoutStockingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    shippingStatus: CheckoutShippingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Checkout
     */
    status: CheckoutStatusEnum;
    /**
     * 
     * @type {CheckoutBilling}
     * @memberof Checkout
     */
    billing?: CheckoutBilling;
    /**
     * 
     * @type {Array<FullfillmentShipping>}
     * @memberof Checkout
     */
    fullfillmentShippings?: Array<FullfillmentShipping>;
    /**
     * 
     * @type {PaymentMinimum}
     * @memberof Checkout
     */
    payment?: PaymentMinimum;
}


/**
 * @export
 */
export const CheckoutStockingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutStockingStatusEnum = typeof CheckoutStockingStatusEnum[keyof typeof CheckoutStockingStatusEnum];

/**
 * @export
 */
export const CheckoutShippingStatusEnum = {
    Waiting: 'waiting',
    Partially: 'partially',
    CompletedWithPartially: 'completed_with_partially',
    Completed: 'completed',
    Canceled: 'canceled',
    Pending: 'pending'
} as const;
export type CheckoutShippingStatusEnum = typeof CheckoutShippingStatusEnum[keyof typeof CheckoutShippingStatusEnum];

/**
 * @export
 */
export const CheckoutStatusEnum = {
    Waiting: 'waiting',
    Paid: 'paid',
    Completed: 'completed',
    Canceled: 'canceled',
    Failed: 'failed'
} as const;
export type CheckoutStatusEnum = typeof CheckoutStatusEnum[keyof typeof CheckoutStatusEnum];


/**
 * Check if a given object implements the Checkout interface.
 */
export function instanceOfCheckout(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "subtotalPrice" in value;
    isInstance = isInstance && "minSubtotalPrice" in value;
    isInstance = isInstance && "maxSubtotalPrice" in value;
    isInstance = isInstance && "serviceCommission" in value;
    isInstance = isInstance && "smallCheckoutCommission" in value;
    isInstance = isInstance && "redeliveryCommission" in value;
    isInstance = isInstance && "redeliveryCommissionRate" in value;
    isInstance = isInstance && "totalShippingRate" in value;
    isInstance = isInstance && "totalTax" in value;
    isInstance = isInstance && "maxTotalTax" in value;
    isInstance = isInstance && "minTotalTax" in value;
    isInstance = isInstance && "discountPrice" in value;
    isInstance = isInstance && "manualDiscountPrice" in value;
    isInstance = isInstance && "totalPrice" in value;
    isInstance = isInstance && "minTotalPrice" in value;
    isInstance = isInstance && "maxTotalPrice" in value;
    isInstance = isInstance && "parcels" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "shippingAddress" in value;
    isInstance = isInstance && "isCancelable" in value;
    isInstance = isInstance && "isBilled" in value;
    isInstance = isInstance && "checkedOutAt" in value;
    isInstance = isInstance && "stockingStatus" in value;
    isInstance = isInstance && "shippingStatus" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function CheckoutFromJSON(json: any): Checkout {
    return CheckoutFromJSONTyped(json, false);
}

export function CheckoutFromJSONTyped(json: any, ignoreDiscriminator: boolean): Checkout {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serialNumber': !exists(json, 'serial_number') ? undefined : json['serial_number'],
        'subtotalPrice': json['subtotal_price'],
        'minSubtotalPrice': json['min_subtotal_price'],
        'maxSubtotalPrice': json['max_subtotal_price'],
        'serviceCommission': json['service_commission'],
        'smallCheckoutCommission': json['small_checkout_commission'],
        'redeliveryCommission': json['redelivery_commission'],
        'redeliveryCommissionRate': json['redelivery_commission_rate'],
        'totalShippingRate': json['total_shipping_rate'],
        'totalTax': json['total_tax'],
        'maxTotalTax': json['max_total_tax'],
        'minTotalTax': json['min_total_tax'],
        'discountPrice': json['discount_price'],
        'manualDiscountPrice': json['manual_discount_price'],
        'totalPrice': json['total_price'],
        'minTotalPrice': json['min_total_price'],
        'maxTotalPrice': json['max_total_price'],
        'couponCode': !exists(json, 'coupon_code') ? undefined : CouponCodeFromJSON(json['coupon_code']),
        'parcels': ((json['parcels'] as Array<any>).map(CheckoutParcelFromJSON)),
        'user': UserFromJSON(json['user']),
        'shippingAddress': AddressFromJSON(json['shipping_address']),
        'isCancelable': json['is_cancelable'],
        'isBilled': json['is_billed'],
        'checkedOutAt': (new Date(json['checked_out_at'])),
        'cancelableAt': !exists(json, 'cancelable_at') ? undefined : (new Date(json['cancelable_at'])),
        'stockingStatus': json['stocking_status'],
        'shippingStatus': json['shipping_status'],
        'status': json['status'],
        'billing': !exists(json, 'billing') ? undefined : CheckoutBillingFromJSON(json['billing']),
        'fullfillmentShippings': !exists(json, 'fullfillment_shippings') ? undefined : ((json['fullfillment_shippings'] as Array<any>).map(FullfillmentShippingFromJSON)),
        'payment': !exists(json, 'payment') ? undefined : PaymentMinimumFromJSON(json['payment']),
    };
}

export function CheckoutToJSON(value?: Checkout | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serial_number': value.serialNumber,
        'subtotal_price': value.subtotalPrice,
        'min_subtotal_price': value.minSubtotalPrice,
        'max_subtotal_price': value.maxSubtotalPrice,
        'service_commission': value.serviceCommission,
        'small_checkout_commission': value.smallCheckoutCommission,
        'redelivery_commission': value.redeliveryCommission,
        'redelivery_commission_rate': value.redeliveryCommissionRate,
        'total_shipping_rate': value.totalShippingRate,
        'total_tax': value.totalTax,
        'max_total_tax': value.maxTotalTax,
        'min_total_tax': value.minTotalTax,
        'discount_price': value.discountPrice,
        'manual_discount_price': value.manualDiscountPrice,
        'total_price': value.totalPrice,
        'min_total_price': value.minTotalPrice,
        'max_total_price': value.maxTotalPrice,
        'coupon_code': CouponCodeToJSON(value.couponCode),
        'parcels': ((value.parcels as Array<any>).map(CheckoutParcelToJSON)),
        'user': UserToJSON(value.user),
        'shipping_address': AddressToJSON(value.shippingAddress),
        'is_cancelable': value.isCancelable,
        'is_billed': value.isBilled,
        'checked_out_at': (value.checkedOutAt.toISOString()),
        'cancelable_at': value.cancelableAt === undefined ? undefined : (value.cancelableAt.toISOString()),
        'stocking_status': value.stockingStatus,
        'shipping_status': value.shippingStatus,
        'status': value.status,
        'billing': CheckoutBillingToJSON(value.billing),
        'fullfillment_shippings': value.fullfillmentShippings === undefined ? undefined : ((value.fullfillmentShippings as Array<any>).map(FullfillmentShippingToJSON)),
        'payment': PaymentMinimumToJSON(value.payment),
    };
}

