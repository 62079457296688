import { FieldValues, FieldPath, useFormContext } from "react-hook-form";

import {
  DatePickerInputGroup,
  DatePickerInputGroupProps,
} from "../DatePickerInputGroup";

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>;
} & Omit<DatePickerInputGroupProps, "control" | "name">;

export const DatePickerControlGroup = <
  TFieldValues extends FieldValues = FieldValues
>({
  name,
  ...props
}: Props<TFieldValues>) => {
  const { control, formState, getFieldState } = useFormContext<TFieldValues>();
  const fieldState = getFieldState(name, formState);

  return (
    <DatePickerInputGroup
      control={control as any} // eslint-disable-line @typescript-eslint/no-explicit-any
      name={name}
      {...props}
      invalid={!!fieldState.error}
      error={fieldState.error?.message}
    />
  );
};
