/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateCheckoutRequestShippingAddress
 */
export interface CreateCheckoutRequestShippingAddress {
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    prefectureId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    town: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    building: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCheckoutRequestShippingAddress
     */
    deliveryInstructions?: string;
}

/**
 * Check if a given object implements the CreateCheckoutRequestShippingAddress interface.
 */
export function instanceOfCreateCheckoutRequestShippingAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "prefectureId" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "town" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "building" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function CreateCheckoutRequestShippingAddressFromJSON(json: any): CreateCheckoutRequestShippingAddress {
    return CreateCheckoutRequestShippingAddressFromJSONTyped(json, false);
}

export function CreateCheckoutRequestShippingAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutRequestShippingAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'zipCode': json['zip_code'],
        'prefectureId': json['prefecture_id'],
        'city': json['city'],
        'town': json['town'],
        'street': json['street'],
        'building': json['building'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'phone': json['phone'],
        'deliveryInstructions': !exists(json, 'delivery_instructions') ? undefined : json['delivery_instructions'],
    };
}

export function CreateCheckoutRequestShippingAddressToJSON(value?: CreateCheckoutRequestShippingAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'zip_code': value.zipCode,
        'prefecture_id': value.prefectureId,
        'city': value.city,
        'town': value.town,
        'street': value.street,
        'building': value.building,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'phone': value.phone,
        'delivery_instructions': value.deliveryInstructions,
    };
}

