import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import { Product, Sale } from "shared/models";
import { useStoreAPI } from "shared/services/api";

export const useSaleProducts = (id: string) => {
  const [sale, setSale] = useState<Sale>();
  const [products, setProducts] = useState<Product[]>([]);

  const api = useStoreAPI();
  const { fetchNextPage, hasNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery(
    ["sales/products", id],
    ({ pageParam }) => api.getSaleProducts(id, pageParam),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
      enabled: !!id,
      onSuccess: ({ pages }) => {
        setSale(new Sale(pages[pages.length - 1].data.sale));
        setProducts(pages.flatMap(({ data: { products } }) => products.map((p) => new Product(p))));
      },
    },
  );

  return {
    sale,
    products,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
