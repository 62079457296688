import { XCircleIcon } from "@heroicons/react/20/solid";
import { useMemo } from "react";

import { Button } from "shared/components";
import { BFC } from "shared/types";

import { ReviewData } from "../../forms";

type Props = {
  reviewData: ReviewData;
  onConfirm: () => void;
  onCancel: () => void;
  isSubmitting: boolean;
  failed: boolean;
};

export const ReviewConfirm: BFC<Props> = ({
  reviewData: { review },
  onConfirm,
  onCancel,
  isSubmitting,
  failed,
}) => {
  const fileWithPreviewUrls = useMemo(
    () =>
      Array.isArray(review.files)
        ? review.files.map((file) => ({
            ...file,
            previewUrl: URL.createObjectURL(file.file),
          }))
        : [],
    [review.files]
  );

  return (
    <div className="flex flex-col gap-6">
      <p className="text-center text-black font-bold">
        以下の内容でレビューを投稿します。よろしいですか?
      </p>

      <dl className="divide-y divide-gray-100">
        <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="font-medium leading-6 text-gray-900">タイトル</dt>
          <dd className="mt-1 leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
            {review.title}
          </dd>
        </div>

        <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="font-medium leading-6 text-gray-900">本文</dt>
          <dd className="mt-1 leading-6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">
            {review.body}
          </dd>
        </div>

        <div className="py-6 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt className="font-medium leading-6 text-gray-900">添付ファイル</dt>
          <dd className="mt-2 text-gray-900 sm:col-span-2 sm:mt-0">
            {fileWithPreviewUrls.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {fileWithPreviewUrls.map((file) => (
                  <div key={file.blob.signed_id} className="relative w-24 h-24">
                    <img
                      src={file.previewUrl}
                      alt="プレビュー画像"
                      className="object-cover w-full h-full rounded-md"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <span className="text-gray-400">添付ファイルはありません</span>
              </div>
            )}
          </dd>
        </div>
      </dl>

      {failed && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="font-medium text-red-800">
                レビューの投稿に失敗しました
              </h3>
              <div className="mt-2 text-red-700">
                恐れ入りますが、時間をおいて再度お試しください。
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col sm:flex-row gap-4">
        <Button
          className="flex-1 justify-center"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          戻って修正する
        </Button>
        <Button
          primary
          className="flex-1 justify-center"
          onClick={onConfirm}
          loading={isSubmitting}
        >
          投稿する
        </Button>
      </div>
    </div>
  );
};
