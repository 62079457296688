/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductImage } from './ProductImage';
import {
    ProductImageFromJSON,
    ProductImageFromJSONTyped,
    ProductImageToJSON,
} from './ProductImage';
import type { ProductNote } from './ProductNote';
import {
    ProductNoteFromJSON,
    ProductNoteFromJSONTyped,
    ProductNoteToJSON,
} from './ProductNote';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 商品
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    shopifyProductId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    descriptionHtml: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    discountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    taxPercentage: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    storageTemperature: ProductStorageTemperatureEnum;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    expirationDays: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    maxExpirationDays?: number;
    /**
     * 
     * @type {ProductImage}
     * @memberof Product
     */
    image?: ProductImage;
    /**
     * 
     * @type {Array<ProductImage>}
     * @memberof Product
     */
    images?: Array<ProductImage>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    alternativeProducts?: Array<Product>;
    /**
     * 
     * @type {Array<Product>}
     * @memberof Product
     */
    relatedProducts?: Array<Product>;
    /**
     * 
     * @type {Array<ProductNote>}
     * @memberof Product
     */
    notes?: Array<ProductNote>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Product
     */
    tags?: Array<Tag>;
    /**
     * 酒類であればtrue
     * @type {boolean}
     * @memberof Product
     */
    isAlcoholic: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    saleEndAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status: ProductStatusEnum;
    /**
     * 商品をお気に入りに登録しているかどうか。未認証の場合は常にfalse
     * @type {boolean}
     * @memberof Product
     */
    isFavorite: boolean;
    /**
     * 在庫に基づき購入可能な数量。一部エンドポイントでのみ利用可能。購入数無制限の場合はフィールドなし
     * @type {number}
     * @memberof Product
     */
    sellableQuantity?: number;
}


/**
 * @export
 */
export const ProductStorageTemperatureEnum = {
    Normal: 'normal',
    SemiRefrigerated: 'semi_refrigerated',
    Refrigerated: 'refrigerated',
    Frozen: 'frozen',
    Hotted: 'hotted'
} as const;
export type ProductStorageTemperatureEnum = typeof ProductStorageTemperatureEnum[keyof typeof ProductStorageTemperatureEnum];

/**
 * @export
 */
export const ProductStatusEnum = {
    Draft: 'draft',
    Active: 'active',
    Archived: 'archived',
    Closed: 'closed'
} as const;
export type ProductStatusEnum = typeof ProductStatusEnum[keyof typeof ProductStatusEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shopifyProductId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "descriptionHtml" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "taxPercentage" in value;
    isInstance = isInstance && "storageTemperature" in value;
    isInstance = isInstance && "expirationDays" in value;
    isInstance = isInstance && "isAlcoholic" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "isFavorite" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shopifyProductId': json['shopify_product_id'],
        'title': json['title'],
        'description': json['description'],
        'descriptionHtml': json['description_html'],
        'price': json['price'],
        'discountPrice': !exists(json, 'discount_price') ? undefined : json['discount_price'],
        'taxPercentage': json['tax_percentage'],
        'storageTemperature': json['storage_temperature'],
        'expirationDays': json['expiration_days'],
        'maxExpirationDays': !exists(json, 'max_expiration_days') ? undefined : json['max_expiration_days'],
        'image': !exists(json, 'image') ? undefined : ProductImageFromJSON(json['image']),
        'images': !exists(json, 'images') ? undefined : ((json['images'] as Array<any>).map(ProductImageFromJSON)),
        'alternativeProducts': !exists(json, 'alternative_products') ? undefined : ((json['alternative_products'] as Array<any>).map(ProductFromJSON)),
        'relatedProducts': !exists(json, 'related_products') ? undefined : ((json['related_products'] as Array<any>).map(ProductFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : ((json['notes'] as Array<any>).map(ProductNoteFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'isAlcoholic': json['is_alcoholic'],
        'saleEndAt': !exists(json, 'sale_end_at') ? undefined : (new Date(json['sale_end_at'])),
        'status': json['status'],
        'isFavorite': json['is_favorite'],
        'sellableQuantity': !exists(json, 'sellable_quantity') ? undefined : json['sellable_quantity'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shopify_product_id': value.shopifyProductId,
        'title': value.title,
        'description': value.description,
        'description_html': value.descriptionHtml,
        'price': value.price,
        'discount_price': value.discountPrice,
        'tax_percentage': value.taxPercentage,
        'storage_temperature': value.storageTemperature,
        'expiration_days': value.expirationDays,
        'max_expiration_days': value.maxExpirationDays,
        'image': ProductImageToJSON(value.image),
        'images': value.images === undefined ? undefined : ((value.images as Array<any>).map(ProductImageToJSON)),
        'alternative_products': value.alternativeProducts === undefined ? undefined : ((value.alternativeProducts as Array<any>).map(ProductToJSON)),
        'related_products': value.relatedProducts === undefined ? undefined : ((value.relatedProducts as Array<any>).map(ProductToJSON)),
        'notes': value.notes === undefined ? undefined : ((value.notes as Array<any>).map(ProductNoteToJSON)),
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'is_alcoholic': value.isAlcoholic,
        'sale_end_at': value.saleEndAt === undefined ? undefined : (value.saleEndAt.toISOString()),
        'status': value.status,
        'is_favorite': value.isFavorite,
        'sellable_quantity': value.sellableQuantity,
    };
}

