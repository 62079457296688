import classNames from "classnames";

import { CFC } from "shared/types";

type Props = {
  large?: boolean;
  small?: boolean;
  block?: boolean;
  primary?: boolean;
  disabled?: boolean;
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: CFC<Props> = ({
  type = "button",
  large,
  small,
  block,
  primary,
  disabled,
  loading,
  className,
  children,
  ...props
}) => {
  const classes = classNames(
    "inline-flex items-center rounded border shadow-sm disabled:cursor-not-allowed",
    className,
    {
      "px-6 py-3": large,
      "px-3 py-1 text-sm": small,
      "px-4 py-2": !large && !small,
      "text-base": !small,
      "!flex w-full justify-center": block,
      "bg-primary text-white border-transparent hover:bg-primary-600 font-bold": primary,
      "disabled:bg-gray-300 disabled:text-white disabled:border-transparent": primary,
      "bg-white font-medium text-gray-700 hover:bg-gray-50 disabled:text-black-300": !primary,
    },
  );

  return (
    <button {...props} type={type} className={classes} disabled={disabled || loading}>
      {loading && (
        <div role="status" className="animate-spin rounded-full border-2 border-t-2 border-gray-200 border-t-primary h-4 w-4 mr-2" />
      )}
      {children}
    </button>
  );
};
