import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { forwardRef, Ref, TextareaHTMLAttributes } from "react";

export type TextareaInputProps = {
  invalid?: boolean;
  containerClassName?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const TextAreaInput = forwardRef(function TextAreaInput(
  { invalid, className, containerClassName, ...props }: TextareaInputProps,
  ref: Ref<HTMLTextAreaElement>
) {
  const classes = classNames(
    "rounded border-gray-300 shadow-sm w-full pr-10 form-input",
    "placeholder-gray-300",
    className,
    {
      "border-red-300 text-red-900": invalid,
    }
  );

  return (
    <div className={classNames("relative", containerClassName)}>
      <textarea {...props} ref={ref} className={classes} />
      {invalid && (
        <div className="pointer-events-none absolute inset-y-0 right-0 p-3">
          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
        </div>
      )}
    </div>
  );
});
