/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutRedelivery } from './CheckoutRedelivery';
import {
    CheckoutRedeliveryFromJSON,
    CheckoutRedeliveryFromJSONTyped,
    CheckoutRedeliveryToJSON,
} from './CheckoutRedelivery';

/**
 * 
 * @export
 * @interface CreateCheckoutRedelivery201ResponseAllOfData
 */
export interface CreateCheckoutRedelivery201ResponseAllOfData {
    /**
     * 
     * @type {CheckoutRedelivery}
     * @memberof CreateCheckoutRedelivery201ResponseAllOfData
     */
    checkoutRedelivery?: CheckoutRedelivery;
}

/**
 * Check if a given object implements the CreateCheckoutRedelivery201ResponseAllOfData interface.
 */
export function instanceOfCreateCheckoutRedelivery201ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateCheckoutRedelivery201ResponseAllOfDataFromJSON(json: any): CreateCheckoutRedelivery201ResponseAllOfData {
    return CreateCheckoutRedelivery201ResponseAllOfDataFromJSONTyped(json, false);
}

export function CreateCheckoutRedelivery201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCheckoutRedelivery201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkoutRedelivery': !exists(json, 'checkout_redelivery') ? undefined : CheckoutRedeliveryFromJSON(json['checkout_redelivery']),
    };
}

export function CreateCheckoutRedelivery201ResponseAllOfDataToJSON(value?: CreateCheckoutRedelivery201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkout_redelivery': CheckoutRedeliveryToJSON(value.checkoutRedelivery),
    };
}

