import { immerable } from "immer";

import { formatDate } from "../helpers";
import * as api from "../services/api/models";
import { FriendCouponCode } from "./friend_coupon_code";
import { UserNotificationSetting } from "./user_notification_setting";
import { UserProvider, UserProviderType } from "./user_provider";

export type UserMeType = api.UserMe;

export class UserMe implements UserMeType {
  [immerable] = true;

  id = "";
  firstName = "";
  lastName = "";
  email = "";
  phone = "";
  emailMarketingAgreed = false;
  stripeCustomerId = "";
  confirmedEmail = false;
  confirmedPhone = false;
  isInternal = false;
  friendCouponCode?: FriendCouponCode;
  birthDate?: Date;
  channelIoMemberHash = "";
  checkoutCount = 0;

  providers: UserProviderType[] = [];

  notificationSetting?: UserNotificationSetting;

  constructor(data: Partial<UserMeType> = {}) {
    Object.assign(this, data);
  }

  getProviders() {
    return this.providers.map((p) => new UserProvider(p));
  }

  isSignedIn() {
    return !!this.id;
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }

  get birthDateFormatted() {
    if (!(this.birthDate instanceof Date)) {
      return "未登録";
    }

    return formatDate(this.birthDate);
  }

  // 生年月日から計算した年齢
  // 生年月日が未登録の場合は null を返す
  get age() {
    if (!(this.birthDate instanceof Date)) {
      return null;
    }

    // @see https://stackoverflow.com/a/7091965
    const today = new Date();
    let age = today.getFullYear() - this.birthDate.getFullYear();
    const m = today.getMonth() - this.birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.birthDate.getDate())) {
      age--;
    }

    return age;
  }

  // 酒類を購入可能な年齢に達しているかどうか
  get canBuyAlcohol(): boolean {
    return !!this.age && this.age >= 20;
  }
}
