/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Sale } from './Sale';
import {
    SaleFromJSON,
    SaleFromJSONTyped,
    SaleToJSON,
} from './Sale';

/**
 * 
 * @export
 * @interface GetSales200ResponseAllOfData
 */
export interface GetSales200ResponseAllOfData {
    /**
     * 
     * @type {Array<Sale>}
     * @memberof GetSales200ResponseAllOfData
     */
    sales: Array<Sale>;
}

/**
 * Check if a given object implements the GetSales200ResponseAllOfData interface.
 */
export function instanceOfGetSales200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sales" in value;

    return isInstance;
}

export function GetSales200ResponseAllOfDataFromJSON(json: any): GetSales200ResponseAllOfData {
    return GetSales200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetSales200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSales200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sales': ((json['sales'] as Array<any>).map(SaleFromJSON)),
    };
}

export function GetSales200ResponseAllOfDataToJSON(value?: GetSales200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sales': ((value.sales as Array<any>).map(SaleToJSON)),
    };
}

