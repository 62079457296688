import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  items: string[];
  style: "ordered" | "unordered";
};

export const ArticleListBlock: BFC<Props> = ({
  items,
  style,
  className,
}) => {
  const classes = classNames("list-inside my-1 ml-1", className, {
    "list-disc": style === "unordered",
    "list-decimal": style === "ordered",
  });

  return (
    <ul className={classes}>
      {items.map((item) => (
        <li
          key={item}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ))}
    </ul>
  );
};
