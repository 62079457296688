/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStockupItem201ResponseAllOfData } from './CreateStockupItem201ResponseAllOfData';
import {
    CreateStockupItem201ResponseAllOfDataFromJSON,
    CreateStockupItem201ResponseAllOfDataFromJSONTyped,
    CreateStockupItem201ResponseAllOfDataToJSON,
} from './CreateStockupItem201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateStockupItem201ResponseAllOf
 */
export interface CreateStockupItem201ResponseAllOf {
    /**
     * 
     * @type {CreateStockupItem201ResponseAllOfData}
     * @memberof CreateStockupItem201ResponseAllOf
     */
    data: CreateStockupItem201ResponseAllOfData;
}

/**
 * Check if a given object implements the CreateStockupItem201ResponseAllOf interface.
 */
export function instanceOfCreateStockupItem201ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CreateStockupItem201ResponseAllOfFromJSON(json: any): CreateStockupItem201ResponseAllOf {
    return CreateStockupItem201ResponseAllOfFromJSONTyped(json, false);
}

export function CreateStockupItem201ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockupItem201ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateStockupItem201ResponseAllOfDataFromJSON(json['data']),
    };
}

export function CreateStockupItem201ResponseAllOfToJSON(value?: CreateStockupItem201ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateStockupItem201ResponseAllOfDataToJSON(value.data),
    };
}

