/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostCostcoProductLogsRequestCostcoProductLogsInner
 */
export interface PostCostcoProductLogsRequestCostcoProductLogsInner {
    /**
     * 
     * @type {Date}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    taxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    discountPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    weight?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    expiryDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof PostCostcoProductLogsRequestCostcoProductLogsInner
     */
    costcoStoreId: string;
}

/**
 * Check if a given object implements the PostCostcoProductLogsRequestCostcoProductLogsInner interface.
 */
export function instanceOfPostCostcoProductLogsRequestCostcoProductLogsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "costcoStoreId" in value;

    return isInstance;
}

export function PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSON(json: any): PostCostcoProductLogsRequestCostcoProductLogsInner {
    return PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSONTyped(json, false);
}

export function PostCostcoProductLogsRequestCostcoProductLogsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCostcoProductLogsRequestCostcoProductLogsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'price': json['price'],
        'taxPercentage': !exists(json, 'tax_percentage') ? undefined : json['tax_percentage'],
        'discountPrice': !exists(json, 'discount_price') ? undefined : json['discount_price'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : (json['expiry_date'] === null ? null : new Date(json['expiry_date'])),
        'costcoStoreId': json['costco_store_id'],
    };
}

export function PostCostcoProductLogsRequestCostcoProductLogsInnerToJSON(value?: PostCostcoProductLogsRequestCostcoProductLogsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString().substr(0,10)),
        'price': value.price,
        'tax_percentage': value.taxPercentage,
        'discount_price': value.discountPrice,
        'weight': value.weight,
        'expiry_date': value.expiryDate === undefined ? undefined : (value.expiryDate === null ? null : value.expiryDate.toISOString().substr(0,10)),
        'costco_store_id': value.costcoStoreId,
    };
}

