/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Cart } from './Cart';
import {
    CartFromJSON,
    CartFromJSONTyped,
    CartToJSON,
} from './Cart';
import type { Token } from './Token';
import {
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
} from './Token';
import type { UserMe } from './UserMe';
import {
    UserMeFromJSON,
    UserMeFromJSONTyped,
    UserMeToJSON,
} from './UserMe';

/**
 * 
 * @export
 * @interface SignUpUser201ResponseAllOfData
 */
export interface SignUpUser201ResponseAllOfData {
    /**
     * 
     * @type {UserMe}
     * @memberof SignUpUser201ResponseAllOfData
     */
    user: UserMe;
    /**
     * 
     * @type {Token}
     * @memberof SignUpUser201ResponseAllOfData
     */
    token: Token;
    /**
     * 
     * @type {Cart}
     * @memberof SignUpUser201ResponseAllOfData
     */
    cart?: Cart;
}

/**
 * Check if a given object implements the SignUpUser201ResponseAllOfData interface.
 */
export function instanceOfSignUpUser201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function SignUpUser201ResponseAllOfDataFromJSON(json: any): SignUpUser201ResponseAllOfData {
    return SignUpUser201ResponseAllOfDataFromJSONTyped(json, false);
}

export function SignUpUser201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpUser201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserMeFromJSON(json['user']),
        'token': TokenFromJSON(json['token']),
        'cart': !exists(json, 'cart') ? undefined : CartFromJSON(json['cart']),
    };
}

export function SignUpUser201ResponseAllOfDataToJSON(value?: SignUpUser201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserMeToJSON(value.user),
        'token': TokenToJSON(value.token),
        'cart': CartToJSON(value.cart),
    };
}

