/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateDeliveryDriverRequestDeliveryDriver } from './CreateDeliveryDriverRequestDeliveryDriver';
import {
    CreateDeliveryDriverRequestDeliveryDriverFromJSON,
    CreateDeliveryDriverRequestDeliveryDriverFromJSONTyped,
    CreateDeliveryDriverRequestDeliveryDriverToJSON,
} from './CreateDeliveryDriverRequestDeliveryDriver';

/**
 * 
 * @export
 * @interface CreateDeliveryDriverRequest
 */
export interface CreateDeliveryDriverRequest {
    /**
     * 
     * @type {CreateDeliveryDriverRequestDeliveryDriver}
     * @memberof CreateDeliveryDriverRequest
     */
    deliveryDriver?: CreateDeliveryDriverRequestDeliveryDriver;
}

/**
 * Check if a given object implements the CreateDeliveryDriverRequest interface.
 */
export function instanceOfCreateDeliveryDriverRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateDeliveryDriverRequestFromJSON(json: any): CreateDeliveryDriverRequest {
    return CreateDeliveryDriverRequestFromJSONTyped(json, false);
}

export function CreateDeliveryDriverRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDeliveryDriverRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryDriver': !exists(json, 'delivery_driver') ? undefined : CreateDeliveryDriverRequestDeliveryDriverFromJSON(json['delivery_driver']),
    };
}

export function CreateDeliveryDriverRequestToJSON(value?: CreateDeliveryDriverRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_driver': CreateDeliveryDriverRequestDeliveryDriverToJSON(value.deliveryDriver),
    };
}

