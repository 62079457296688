/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TimelineCheckoutFullfilledStockingAllOfData } from './TimelineCheckoutFullfilledStockingAllOfData';
import {
    TimelineCheckoutFullfilledStockingAllOfDataFromJSON,
    TimelineCheckoutFullfilledStockingAllOfDataFromJSONTyped,
    TimelineCheckoutFullfilledStockingAllOfDataToJSON,
} from './TimelineCheckoutFullfilledStockingAllOfData';
import type { TimelineInitiator } from './TimelineInitiator';
import {
    TimelineInitiatorFromJSON,
    TimelineInitiatorFromJSONTyped,
    TimelineInitiatorToJSON,
} from './TimelineInitiator';

/**
 * 
 * @export
 * @interface TimelineCheckoutFullfilledStockingAllOf
 */
export interface TimelineCheckoutFullfilledStockingAllOf {
    /**
     * 
     * @type {string}
     * @memberof TimelineCheckoutFullfilledStockingAllOf
     */
    event: TimelineCheckoutFullfilledStockingAllOfEventEnum;
    /**
     * 
     * @type {TimelineInitiator}
     * @memberof TimelineCheckoutFullfilledStockingAllOf
     */
    initiator: TimelineInitiator;
    /**
     * 
     * @type {TimelineCheckoutFullfilledStockingAllOfData}
     * @memberof TimelineCheckoutFullfilledStockingAllOf
     */
    data: TimelineCheckoutFullfilledStockingAllOfData;
}


/**
 * @export
 */
export const TimelineCheckoutFullfilledStockingAllOfEventEnum = {
    FullfilledStocking: 'fullfilled_stocking'
} as const;
export type TimelineCheckoutFullfilledStockingAllOfEventEnum = typeof TimelineCheckoutFullfilledStockingAllOfEventEnum[keyof typeof TimelineCheckoutFullfilledStockingAllOfEventEnum];


/**
 * Check if a given object implements the TimelineCheckoutFullfilledStockingAllOf interface.
 */
export function instanceOfTimelineCheckoutFullfilledStockingAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "event" in value;
    isInstance = isInstance && "initiator" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function TimelineCheckoutFullfilledStockingAllOfFromJSON(json: any): TimelineCheckoutFullfilledStockingAllOf {
    return TimelineCheckoutFullfilledStockingAllOfFromJSONTyped(json, false);
}

export function TimelineCheckoutFullfilledStockingAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineCheckoutFullfilledStockingAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'event': json['event'],
        'initiator': TimelineInitiatorFromJSON(json['initiator']),
        'data': TimelineCheckoutFullfilledStockingAllOfDataFromJSON(json['data']),
    };
}

export function TimelineCheckoutFullfilledStockingAllOfToJSON(value?: TimelineCheckoutFullfilledStockingAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event': value.event,
        'initiator': TimelineInitiatorToJSON(value.initiator),
        'data': TimelineCheckoutFullfilledStockingAllOfDataToJSON(value.data),
    };
}

