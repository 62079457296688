import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { HiOutlineXMark } from "react-icons/hi2";

import { Button } from "shared/components";
import { Product } from "shared/models";
import { BFC } from "shared/types";

import { ProductSelectableCard } from "../ProductSelectableCard";

type Props = {
  show?: boolean;
  selected: Product | null;
  products: Product[];
  onSelect?: (product: Product | null) => void | Promise<void>;
  onClose: (flag: boolean) => void;
};

export const AlternativeProductSelectorModal: BFC<Props> = ({
  show,
  selected,
  products,
  onSelect,
  onClose,
  className,
}) => {
  const [currentSelected, setCurrentSelected] = useState<Product | null>(null);

  useEffect(() => {
    setCurrentSelected(selected);
  }, [show, selected]);

  const onCloseHandler = useCallback(() => {
    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  const onSelectHandler = useCallback((product: Product | null) => {
    if (onSelect) {
      const res = onSelect(product);
      if (res instanceof Promise) {
        res.then(() => onCloseHandler());
      } else {
        onCloseHandler();
      }
    } else {
      onCloseHandler();
    }
  }, [onSelect, onCloseHandler]);

  const onNoSelectHandler = useCallback(() => {
    if (onSelect) {
      const res = onSelect(null);
      if (res instanceof Promise) {
        res.then(() => onCloseHandler());
      } else {
        onCloseHandler();
      }
    } else {
      onCloseHandler();
    }
  }, []);

  const buttonClassNames = classNames(
    {
      "border border-primary": !currentSelected,
    },
  );

  return (
    <Dialog open={show} onClose={onClose} className="relative z-50">
      <Dialog.Backdrop className="fixed inset-0 bg-black/40" />
      <Dialog.Panel className={classNames("fixed inset-0 overflow-y-auto bg-white", className)}>
        <Dialog.Title className="relative h-12 border-b">
          <div className="absolute inset-0 flex items-center justify-center text-lg font-bold">
            代替品を選択してください
          </div>
          <div className="absolute right-0 inset-y-0 flex justify-center items-center p-3">
            <HiOutlineXMark size={24} onClick={onCloseHandler} />
          </div>
        </Dialog.Title>
        <div className="h-min-full flex flex-col gap-4">
          <div className="border mx-3 mt-3 p-3 text-sm text-black-500 bg-gray-100">
            代替品は、仕入れ日に店舗にて在庫がなかった場合に、代わりに購入してお届けする商品です。
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-3 px-4">
            {products.map((product) => (
              <ProductSelectableCard
                key={product.id}
                product={product}
                selected={currentSelected?.id === product.id}
                onSelect={onSelectHandler}
              />
            ))}
          </div>
          <div className="px-4">
            <Button block large onClick={onNoSelectHandler} className={buttonClassNames}>
              代替品を希望しない
            </Button>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};
