/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FriendCouponCodeAllOfReward } from './FriendCouponCodeAllOfReward';
import {
    FriendCouponCodeAllOfRewardFromJSON,
    FriendCouponCodeAllOfRewardFromJSONTyped,
    FriendCouponCodeAllOfRewardToJSON,
} from './FriendCouponCodeAllOfReward';

/**
 * 
 * @export
 * @interface FriendCouponCodeAllOf
 */
export interface FriendCouponCodeAllOf {
    /**
     * 
     * @type {FriendCouponCodeAllOfReward}
     * @memberof FriendCouponCodeAllOf
     */
    reward: FriendCouponCodeAllOfReward;
}

/**
 * Check if a given object implements the FriendCouponCodeAllOf interface.
 */
export function instanceOfFriendCouponCodeAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reward" in value;

    return isInstance;
}

export function FriendCouponCodeAllOfFromJSON(json: any): FriendCouponCodeAllOf {
    return FriendCouponCodeAllOfFromJSONTyped(json, false);
}

export function FriendCouponCodeAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendCouponCodeAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reward': FriendCouponCodeAllOfRewardFromJSON(json['reward']),
    };
}

export function FriendCouponCodeAllOfToJSON(value?: FriendCouponCodeAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reward': FriendCouponCodeAllOfRewardToJSON(value.reward),
    };
}

