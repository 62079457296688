import { useEffect, useState } from "react";

import {
  Product,
  convertToShopifyProductGID,
} from "shared/models";

import { getShopifyGraphqlClient } from "~/services/shopify";

import { GetProductQuery, useGetProductQuery } from "../graphql/GetProductQuery";

type Options = {
  base: Product;
};

export const useShopifyProduct = (id: string, options: Options) => {
  const { base } = options;
  const client = getShopifyGraphqlClient();
  const [product, setProduct] = useState<Product>(base);
  const [shopifyProduct, setShopifyProduct] = useState<GetProductQuery["product"]>();

  const { isLoading } = useGetProductQuery(
    client,
    { id: convertToShopifyProductGID(id) },
    {
      onSuccess: ({ product: p }) => {
        setShopifyProduct(p);
      },
    },
  );

  useEffect(() => {
    setProduct(new Product({
      ...base,
    }));
  }, [base, shopifyProduct]);

  return {
    product,
    isLoading,
  };
};
