import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  id: string;
  text: string;
  level: number;
};

export const ArticleHeadingBlock: BFC<Props> = ({
  id,
  text,
  level,
  className,
}) => {
  const classes = classNames("font-bold", className, {
    "text-3xl my-4": level === 1,
    "text-2xl my-3": level === 2,
    "text-xl my-2": level === 3,
    "text-lg my-1": level === 4,
    "text-base my-0.5": level === 5,
    "text-sm": level === 6,
  });

  return (
    <div
      id={`header-${id}`}
      className={classes}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
