import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { Checkout, CheckoutType } from "./checkout";
import { CheckoutItem, CheckoutItemType } from "./checkout_item";
import { CostcoProductType } from "./costco_product";
import { ProductRecipe, ProductRecipeType } from "./product_recipe";
import { ShippingTime, ShippingTimeType } from "./shipping_time";

export type StockupCheckoutItemType = api.StockupCheckoutItem;

export class StockupCheckoutItem implements StockupCheckoutItemType {
  [immerable] = true;

  id = "";
  checkout: CheckoutType = new Checkout();
  checkoutItem: CheckoutItemType = new CheckoutItem();
  productRecipes: ProductRecipeType[] = [];
  alternativeProductRecipes: ProductRecipeType[] = [];
  shippingDate = new Date();
  shippingTime: ShippingTimeType = new ShippingTime();

  constructor(data: Partial<StockupCheckoutItemType> = {}) {
    Object.assign(this, data);
  }

  getCheckout() {
    return new Checkout(this.checkout);
  }

  getCheckoutItem() {
    return new CheckoutItem(this.checkoutItem);
  }

  getProductRecipes() {
    return this.productRecipes.map((recipe) => new ProductRecipe(recipe));
  }

  getAlternativeProductRecipes() {
    return this.alternativeProductRecipes.map((recipe) => new ProductRecipe(recipe));
  }

  hasProductRecipeByCostcoProduct(costcoProduct: CostcoProductType) {
    return this.productRecipes.some((recipe) => recipe.costcoProduct.id === costcoProduct.id);
  }

  hasAlternativeProductRecipeByCostcoProduct(costcoProduct: CostcoProductType) {
    return this.alternativeProductRecipes.some((recipe) => recipe.costcoProduct.id === costcoProduct.id);
  }
}
