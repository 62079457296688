/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TagFamilyTag } from './TagFamilyTag';
import {
    TagFamilyTagFromJSON,
    TagFamilyTagFromJSONTyped,
    TagFamilyTagToJSON,
} from './TagFamilyTag';

/**
 * 
 * @export
 * @interface GetTagFamilyTag200ResponseAllOfData
 */
export interface GetTagFamilyTag200ResponseAllOfData {
    /**
     * 
     * @type {TagFamilyTag}
     * @memberof GetTagFamilyTag200ResponseAllOfData
     */
    tag: TagFamilyTag;
}

/**
 * Check if a given object implements the GetTagFamilyTag200ResponseAllOfData interface.
 */
export function instanceOfGetTagFamilyTag200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tag" in value;

    return isInstance;
}

export function GetTagFamilyTag200ResponseAllOfDataFromJSON(json: any): GetTagFamilyTag200ResponseAllOfData {
    return GetTagFamilyTag200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetTagFamilyTag200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTagFamilyTag200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tag': TagFamilyTagFromJSON(json['tag']),
    };
}

export function GetTagFamilyTag200ResponseAllOfDataToJSON(value?: GetTagFamilyTag200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tag': TagFamilyTagToJSON(value.tag),
    };
}

