import { createContext, useContext } from "react";

import { Cart } from "shared/models";
import { CFC } from "shared/types";

import { useCart } from "./useCart";

type CartContextType = ReturnType<typeof useCart>;

const CartContext = createContext<CartContextType>({
  cart: new Cart(),
  addCartItem: () => new Promise(() => void(0)),
  updateCartItem: () => new Promise(() => void(0)),
  removeCartItem: () => new Promise(() => void(0)),
  associateToUser: () => new Promise(() => void(0)),
  createCart: () => new Promise(() => void(0)),
  clearCart: () => new Promise(() => void(0)),
  unsetCart: () => void(0),
  waitCartReady: () => new Promise(() => void(0)),
  setCartId: () => void(0),
  isLoading: false,
  isMutating: false,
});

export const useCartContext = () => useContext(CartContext);

export const CartProvider: CFC = ({ children }) => {
  const value = useCart();

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
};
