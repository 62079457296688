/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StockedStockupItemRequestStockupItem } from './StockedStockupItemRequestStockupItem';
import {
    StockedStockupItemRequestStockupItemFromJSON,
    StockedStockupItemRequestStockupItemFromJSONTyped,
    StockedStockupItemRequestStockupItemToJSON,
} from './StockedStockupItemRequestStockupItem';

/**
 * 
 * @export
 * @interface StockedStockupItemRequest
 */
export interface StockedStockupItemRequest {
    /**
     * 
     * @type {StockedStockupItemRequestStockupItem}
     * @memberof StockedStockupItemRequest
     */
    stockupItem: StockedStockupItemRequestStockupItem;
}

/**
 * Check if a given object implements the StockedStockupItemRequest interface.
 */
export function instanceOfStockedStockupItemRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stockupItem" in value;

    return isInstance;
}

export function StockedStockupItemRequestFromJSON(json: any): StockedStockupItemRequest {
    return StockedStockupItemRequestFromJSONTyped(json, false);
}

export function StockedStockupItemRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockedStockupItemRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stockupItem': StockedStockupItemRequestStockupItemFromJSON(json['stockup_item']),
    };
}

export function StockedStockupItemRequestToJSON(value?: StockedStockupItemRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stockup_item': StockedStockupItemRequestStockupItemToJSON(value.stockupItem),
    };
}

