import * as api from "shared/services/api/models";

export type UserProviderType = api.UserProvider;

export const UserProviderProvider = api.UserProviderProviderEnum;
export type UserProviderProvider = api.UserProviderProviderEnum;

export class UserProvider implements UserProviderType {
  provider: UserProviderProvider = UserProviderProvider.Google;

  constructor(data: Partial<UserProviderType> = {}) {
    Object.assign(this, data);
  }

  isGoogle() {
    return this.provider === UserProviderProvider.Google;
  }
}
