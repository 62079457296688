/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCheckout200ResponseAllOfData } from './GetCheckout200ResponseAllOfData';
import {
    GetCheckout200ResponseAllOfDataFromJSON,
    GetCheckout200ResponseAllOfDataFromJSONTyped,
    GetCheckout200ResponseAllOfDataToJSON,
} from './GetCheckout200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCheckout200ResponseAllOf
 */
export interface GetCheckout200ResponseAllOf {
    /**
     * 
     * @type {GetCheckout200ResponseAllOfData}
     * @memberof GetCheckout200ResponseAllOf
     */
    data: GetCheckout200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCheckout200ResponseAllOf interface.
 */
export function instanceOfGetCheckout200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCheckout200ResponseAllOfFromJSON(json: any): GetCheckout200ResponseAllOf {
    return GetCheckout200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCheckout200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckout200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCheckout200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCheckout200ResponseAllOfToJSON(value?: GetCheckout200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCheckout200ResponseAllOfDataToJSON(value.data),
    };
}

