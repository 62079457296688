import { immerable } from "immer";

import * as api from "../services/api/models";

export type ShippingRateType = api.ShippingRate;

export class ShippingRate implements ShippingRateType {
  [immerable] = true;

  id = "";
  handle = "";
  name = "";
  price = 0;

  constructor(data: Partial<ShippingRateType> = {}) {
    Object.assign(this, data);
  }
}
