import { FC } from "react";

import { Product } from "shared/models";
import { BFC } from "shared/types";

import { useShopifyProduct } from "../../hooks";

type Props = {
  product: Product;
  skeleton?: FC;
  children?: (product: Product) => React.ReactNode;
};

export const ShopifyProductProvider: BFC<Props> = ({
  product,
  skeleton: Skeleton,
  children,
}) => {
  const { product: shopifyProduct, isLoading } = useShopifyProduct(product.shopifyProductId, { base: product });

  if (isLoading || !children) {
    return Skeleton ? <Skeleton /> : <div />;
  }

  return <>{children(shopifyProduct)}</>;
};
