/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProduct } from './CostcoProduct';
import {
    CostcoProductFromJSON,
    CostcoProductFromJSONTyped,
    CostcoProductToJSON,
} from './CostcoProduct';

/**
 * 
 * @export
 * @interface PostCostcoProducts201ResponseAllOfData
 */
export interface PostCostcoProducts201ResponseAllOfData {
    /**
     * 
     * @type {CostcoProduct}
     * @memberof PostCostcoProducts201ResponseAllOfData
     */
    costcoProduct: CostcoProduct;
}

/**
 * Check if a given object implements the PostCostcoProducts201ResponseAllOfData interface.
 */
export function instanceOfPostCostcoProducts201ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProduct" in value;

    return isInstance;
}

export function PostCostcoProducts201ResponseAllOfDataFromJSON(json: any): PostCostcoProducts201ResponseAllOfData {
    return PostCostcoProducts201ResponseAllOfDataFromJSONTyped(json, false);
}

export function PostCostcoProducts201ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostCostcoProducts201ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProduct': CostcoProductFromJSON(json['costco_product']),
    };
}

export function PostCostcoProducts201ResponseAllOfDataToJSON(value?: PostCostcoProducts201ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product': CostcoProductToJSON(value.costcoProduct),
    };
}

