/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SurveyUser } from './SurveyUser';
import {
    SurveyUserFromJSON,
    SurveyUserFromJSONTyped,
    SurveyUserToJSON,
} from './SurveyUser';

/**
 * コストコ商品ログ
 * @export
 * @interface CostcoProductLog
 */
export interface CostcoProductLog {
    /**
     * 
     * @type {string}
     * @memberof CostcoProductLog
     */
    id: string;
    /**
     * コストコ店舗ID
     * @type {string}
     * @memberof CostcoProductLog
     */
    costcoStoreId: string;
    /**
     * コストコ商品ID
     * @type {string}
     * @memberof CostcoProductLog
     */
    costcoProductId: string;
    /**
     * 記録日
     * @type {Date}
     * @memberof CostcoProductLog
     */
    date: Date;
    /**
     * 価格
     * @type {number}
     * @memberof CostcoProductLog
     */
    price: number;
    /**
     * 税率(%)
     * @type {number}
     * @memberof CostcoProductLog
     */
    taxPercentage: number;
    /**
     * 重量
     * @type {any}
     * @memberof CostcoProductLog
     */
    weight: any | null;
    /**
     * 賞味期限
     * @type {any}
     * @memberof CostcoProductLog
     */
    expiryDate: any | null;
    /**
     * 
     * @type {SurveyUser}
     * @memberof CostcoProductLog
     */
    surveyedBy?: SurveyUser;
}

/**
 * Check if a given object implements the CostcoProductLog interface.
 */
export function instanceOfCostcoProductLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "costcoStoreId" in value;
    isInstance = isInstance && "costcoProductId" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "taxPercentage" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "expiryDate" in value;

    return isInstance;
}

export function CostcoProductLogFromJSON(json: any): CostcoProductLog {
    return CostcoProductLogFromJSONTyped(json, false);
}

export function CostcoProductLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): CostcoProductLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'costcoStoreId': json['costco_store_id'],
        'costcoProductId': json['costco_product_id'],
        'date': (new Date(json['date'])),
        'price': json['price'],
        'taxPercentage': json['tax_percentage'],
        'weight': json['weight'],
        'expiryDate': json['expiry_date'],
        'surveyedBy': !exists(json, 'surveyed_by') ? undefined : SurveyUserFromJSON(json['surveyed_by']),
    };
}

export function CostcoProductLogToJSON(value?: CostcoProductLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'costco_store_id': value.costcoStoreId,
        'costco_product_id': value.costcoProductId,
        'date': (value.date.toISOString().substr(0,10)),
        'price': value.price,
        'tax_percentage': value.taxPercentage,
        'weight': value.weight,
        'expiry_date': value.expiryDate,
        'surveyed_by': SurveyUserToJSON(value.surveyedBy),
    };
}

