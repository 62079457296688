/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStocksRequestStocksInner } from './CreateStocksRequestStocksInner';
import {
    CreateStocksRequestStocksInnerFromJSON,
    CreateStocksRequestStocksInnerFromJSONTyped,
    CreateStocksRequestStocksInnerToJSON,
} from './CreateStocksRequestStocksInner';

/**
 * 
 * @export
 * @interface CreateStocksRequest
 */
export interface CreateStocksRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateStocksRequest
     */
    costcoProductId: string;
    /**
     * 
     * @type {Array<CreateStocksRequestStocksInner>}
     * @memberof CreateStocksRequest
     */
    stocks: Array<CreateStocksRequestStocksInner>;
}

/**
 * Check if a given object implements the CreateStocksRequest interface.
 */
export function instanceOfCreateStocksRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProductId" in value;
    isInstance = isInstance && "stocks" in value;

    return isInstance;
}

export function CreateStocksRequestFromJSON(json: any): CreateStocksRequest {
    return CreateStocksRequestFromJSONTyped(json, false);
}

export function CreateStocksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStocksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProductId': json['costco_product_id'],
        'stocks': ((json['stocks'] as Array<any>).map(CreateStocksRequestStocksInnerFromJSON)),
    };
}

export function CreateStocksRequestToJSON(value?: CreateStocksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product_id': value.costcoProductId,
        'stocks': ((value.stocks as Array<any>).map(CreateStocksRequestStocksInnerToJSON)),
    };
}

