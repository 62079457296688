import Link from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { HiOutlineChevronLeft } from "react-icons/hi";

import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";

import { BirthDateEditData, BirthDateEditForm } from "../../forms";
import { useUpdateBirthDate } from "../../hooks";

export const BirthDateEditPage: BFC = () => {
  const router = useRouter();
  const handleUpdate = useCallback(async () => {
    toast.success("生年月日を設定しました");
    await router.push(routes.MYPAGE);
  }, [router]);

  const { user } = useAuthContext();
  const { update } = useUpdateBirthDate({
    onSuccess: handleUpdate,
  });

  const handleSubmit = useCallback(
    async (data: BirthDateEditData) => {
      await update(data);
    },
    [update]
  );

  const isBirthday = useMemo(() => {
    if (!user.birthDate) {
      return false;
    }

    const today = new Date();
    return (
      user.birthDate.getMonth() === today.getMonth() &&
      user.birthDate.getDate() === today.getDate()
    );
  }, [user.birthDate]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <Link href={routes.MYPAGE} className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <HiOutlineChevronLeft size={24} className="text-black-500" />
          生年月日の設定
        </h1>
      </Link>
      <div className="bg-white p-3">
        {user.birthDate ? (
          <div className="flex flex-col gap-4">
            <dl className="flex flex-col gap-4">
              <div>
                <dt className="font-bold">生年月日</dt>
                <dd className="text-gray-600">{user.birthDateFormatted}</dd>
              </div>

              <div>
                <dt className="font-bold">ご年齢</dt>
                <dd className="text-gray-600">{user.age}歳</dd>
              </div>
            </dl>

            {isBirthday ? (
              <div className="bg-yellow-100 border-l-4 border-yellow-500 p-4">
                <div className="flex flex-col gap-2">
                  <div className="font-bold">
                    {user.fullName}さん、お誕生日おめでとうございます 🎉
                  </div>
                  <div>素敵な一日をお過ごしください！</div>
                </div>
              </div>
            ) : null}

            <ul className="text-gray-600">
              <li>生年月日は一度設定すると変更できません。</li>
              <li>
                誤って設定した場合は、お手数ですが
                <a
                  href="https://soctoc.channel.io"
                  target="_blank"
                  rel="noreferrer"
                  className="underline hover:no-underline"
                >
                  お問い合わせ
                </a>
                よりご連絡ください。
              </li>
            </ul>
          </div>
        ) : (
          <BirthDateEditForm onSubmit={handleSubmit} />
        )}
      </div>
    </div>
  );
};
