import { immerable } from "immer";

import * as api from "../services/api/models";
import { PaymentStatus } from "./payment";
import { PaymentMethod } from "./payment_method";

export type PaymentMinimumType = api.PaymentMinimum;

export class PaymentMinimum implements PaymentMinimumType {
  [immerable] = true;

  id = "";
  paymentMethod: PaymentMethod = new PaymentMethod();
  status: PaymentStatus = PaymentStatus.Pending;
  receiptUrl: string | undefined = undefined;

  constructor(data: Partial<PaymentMinimumType> = {}) {
    Object.assign(this, data);
  }
}
