import { immerable } from "immer";

import * as api from "../services/api/models";
import { FullfillmentShippingItemCancelReason } from "./fullfillment_shipping_item";
import { Product, ProductType } from "./product";
import { StockConsumptionWithStock, StockConsumptionWithStockType } from "./stock_consumption";

export type CheckoutItemType = api.CheckoutItem & {
  cancelQuantity: number;
  cancelReason: FullfillmentShippingItemCancelReason;
};

export class CheckoutItem implements CheckoutItemType {
  [immerable] = true;

  id = "";
  checkoutId = "";
  product: ProductType = new Product();
  productSuggestedStockConsumptions: StockConsumptionWithStockType[] = [];
  price = 0;
  discountPrice?: number;
  quantity = 0;
  subtotalPrice = 0;
  totalShippingRate = 0;
  taxPercentage = 10;
  alternativeProduct?: ProductType;
  alternativeProductSuggestedStockConsumptions?: StockConsumptionWithStockType[];
  alternativePrice?: number;
  alternativeDiscountPrice?: number;
  alternativeSubtotalPrice?: number;
  alternativeTaxPercentage?: number;
  cancelQuantity = 0;
  cancelReason: FullfillmentShippingItemCancelReason = FullfillmentShippingItemCancelReason.No;

  constructor(data: Partial<CheckoutItemType> = {}) {
    Object.assign(this, data);
  }

  getProduct() {
    return new Product(this.product);
  }

  getProductSuggestedStockConsumptions() {
    return this.productSuggestedStockConsumptions.map(
      (stockConsumption) => new StockConsumptionWithStock(stockConsumption)
    );
  }

  getAlternativeProduct() {
    return this.alternativeProduct ? new Product(this.alternativeProduct) : null;
  }

  getAlternativeProductSuggestedStockConsumptions() {
    return this.alternativeProductSuggestedStockConsumptions?.map(
      (stockConsumption) => new StockConsumptionWithStock(stockConsumption)
    );
  }

  hasAlternativeProduct(): this is Omit<CheckoutItem, "alternativeProduct" | "getAlternativeProduct"> & {
    alternativeProduct: ProductType,
    getAlternativeProduct(): Product,
  } {
    return !!this.alternativeProduct;
  }
}
