import { immerable } from "immer";

import * as api from "../services/api/store/models";
import { Product, ProductType } from "./product";

export type CartItemType = api.CartItem;

export class CartItem implements CartItemType {
  [immerable] = true;

  product: ProductType = new Product();
  quantity = 0;
  alternativeProduct?: ProductType;

  constructor(data: Partial<CartItemType> = {}) {
    Object.assign(this, data);
  }

  getProduct() {
    return new Product(this.product);
  }

  getAlternativeProduct() {
    const product = this.alternativeProduct ? new Product(this.alternativeProduct) : null;
    return !product || product.isInactive() ? null : product;
  }

  hasAlternativeProduct(): this is Required<CartItem> {
    return !!this.alternativeProduct;
  }
}
