import { AiOutlineHistory } from "react-icons/ai";
import { ColorRing } from "react-loader-spinner";

import { Paginate } from "shared/features/utils";

import { useCheckouts, CheckoutHistoryCard } from "~/features/checkouts";

export const CheckoutsPage = () => {
  const { checkouts, isLoading, paging } = useCheckouts();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <ColorRing />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white">
        <h1 className="flex items-center gap-2 font-bold px-4 py-6 text-lg">
          <AiOutlineHistory size={24} className="text-black-500" />
          注文履歴
        </h1>
      </div>
      <div className="flex flex-col gap-4 px-0 sm:px-3">
        {checkouts.map((checkout) => (
          <CheckoutHistoryCard
            key={checkout.id}
            checkout={checkout}
            className="sm:rounded-lg sm:border-x border-y overflow-hidden"
          />
        ))}
        {checkouts.length === 0 && (
          <div className="mt-5">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg rounded border-gray-200 p-4">
              まだ商品を購入されていません
            </div>
          </div>
        )}
        <Paginate {...paging} />
      </div>
    </div>
  );
};
