import { immerable } from "immer";

import * as api from "../services/api/models";

export const PaymentMethodTypeType = api.PaymentMethodTypeEnum;
export type PaymentMethodTypeType = api.PaymentMethodTypeEnum;

export type PaymentMethodType = api.PaymentMethod;

export class PaymentMethod implements PaymentMethodType {
  [immerable] = true;

  id = "";
  stripePaymentMethodId = "";
  type: PaymentMethodTypeType = "card";
  card: PaymentMethodType["card"] = {
    brand: "",
    expMonth: 0,
    expYear: 0,
    last4: "",
    threeDSecureSupported: false,
  };

  constructor(data: Partial<PaymentMethodType> = {}) {
    Object.assign(this, data);
  }

  get summary() {
    return `${this.card.brand} **** **** **** ${this.card.last4}`;
  }
}
