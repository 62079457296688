import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { User } from "./user";

export type TimelineCheckoutType = api.TimelineCheckout;

export type TimelineCheckoutEvent = api.TimelineCheckoutEventEnum;
export const TimelineCheckoutEvent = api.TimelineCheckoutEventEnum;

export type TimelineCheckoutFullfilledStockingEventData = api.TimelineCheckoutFullfilledStockingAllOfData;
export type TimelineCheckoutFullfilledShippingEventData = api.TimelineCheckoutFullfilledShippingAllOfData;

export type TimelineCheckoutEventData = TimelineCheckoutType["data"]
  | TimelineCheckoutFullfilledStockingEventData
  | TimelineCheckoutFullfilledShippingEventData;

export class TimelineCheckout implements TimelineCheckoutType {
  [immerable] = true;

  id = "";
  event: TimelineCheckoutEvent = "created";
  initiator?: TimelineCheckoutType["initiator"];
  data: TimelineCheckoutEventData = {};
  occurredAt = new Date();

  constructor(data: Partial<TimelineCheckoutType> = {}) {
    Object.assign(this, data);
  }

  getInitiator() {
    if (!this.initiator) return null;

    if (this.initiator.type === "User") {
      return new User({ id: this.initiator.id });
    }
  }

  isFullfilledStocking(): this is Omit<TimelineCheckout, "getData"> & {
    getData(): TimelineCheckoutFullfilledStockingEventData,
  } {
    return this.event === TimelineCheckoutEvent.FullfilledStocking;
  }

  isFullfilledShipping(): this is Omit<TimelineCheckout, "getData"> & {
    getData(): TimelineCheckoutFullfilledShippingEventData,
  } {
    return this.event === TimelineCheckoutEvent.FullfilledShipping;
  }

  getData(): TimelineCheckoutEventData {
    if (this.isFullfilledStocking()) {
      return api.TimelineCheckoutFullfilledStockingAllOfDataFromJSON(this.data);
    } else if (this.isFullfilledShipping()) {
      return api.TimelineCheckoutFullfilledShippingAllOfDataFromJSON(this.data);
    } else {
      return this.data;
    }
  }
}
