/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeliveryDriver } from './DeliveryDriver';
import {
    DeliveryDriverFromJSON,
    DeliveryDriverFromJSONTyped,
    DeliveryDriverToJSON,
} from './DeliveryDriver';

/**
 * 
 * @export
 * @interface GetDeliveryDrivers200ResponseAllOfData
 */
export interface GetDeliveryDrivers200ResponseAllOfData {
    /**
     * 
     * @type {Array<DeliveryDriver>}
     * @memberof GetDeliveryDrivers200ResponseAllOfData
     */
    deliveryDrivers: Array<DeliveryDriver>;
}

/**
 * Check if a given object implements the GetDeliveryDrivers200ResponseAllOfData interface.
 */
export function instanceOfGetDeliveryDrivers200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deliveryDrivers" in value;

    return isInstance;
}

export function GetDeliveryDrivers200ResponseAllOfDataFromJSON(json: any): GetDeliveryDrivers200ResponseAllOfData {
    return GetDeliveryDrivers200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetDeliveryDrivers200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliveryDrivers200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryDrivers': ((json['delivery_drivers'] as Array<any>).map(DeliveryDriverFromJSON)),
    };
}

export function GetDeliveryDrivers200ResponseAllOfDataToJSON(value?: GetDeliveryDrivers200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'delivery_drivers': ((value.deliveryDrivers as Array<any>).map(DeliveryDriverToJSON)),
    };
}

