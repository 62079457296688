import { immerable } from "immer";

import { calcTaxIncludedPrice } from "shared/helpers";

import * as api from "../services/api/models";
import { ImageType } from "./image";
import { ProductImage, ProductImageType } from "./product_image";
import { ProductNote, ProductNoteType } from "./product_note";
import { ProductVariant, ProductVariantType } from "./product_variant";
import { Tag, TagType } from "./tag";

export type ProductType = api.Product & {
  variants?: Array<Partial<ProductVariantType>>;
  images?: Array<ImageType>;
};

export type ProductStorageTemperature = api.ProductStorageTemperatureEnum;
export const ProductStorageTemperature = api.ProductStorageTemperatureEnum;

export type ProductStatus = api.ProductStatusEnum;
export const ProductStatus = api.ProductStatusEnum;

export class Product implements ProductType {
  [immerable] = true;

  id = "";
  shopifyProductId = "";
  title = "";
  description = "";
  descriptionHtml = "";
  price = 0;
  discountPrice?: number;
  taxPercentage = -1;
  storageTemperature: ProductStorageTemperature = ProductStorageTemperature.Normal;
  isAlcoholic = false;
  expirationDays = 0;
  maxExpirationDays?: number;
  image?: ProductImageType;
  images: ProductImageType[] = [];
  notes: ProductNoteType[] = [];
  tags: TagType[] = [];
  alternativeProducts: ProductType[] = [];
  relatedProducts: ProductType[] = [];
  saleEndAt?: Date;
  variants: Array<Partial<ProductVariantType>> = [];
  status: ProductStatus = ProductStatus.Active;
  isFavorite = false;
  sellableQuantity?: number;

  constructor(data: Partial<ProductType> = {}) {
    Object.assign(this, data);
  }

  getVariants() {
    return this.variants.map((variant) => new ProductVariant(variant));
  }

  getAlternativeProducts() {
    return this.alternativeProducts.map((product) => new Product(product));
  }

  getRelatedProducts() {
    if (!this.relatedProducts) return [];
    return this.relatedProducts.map((product) => new Product(product));
  }

  getNotes() {
    return this.notes.map((note) => new ProductNote(note));
  }

  getTags() {
    return this.tags.map((tag) => new Tag(tag));
  }

  getImage() {
    return new ProductImage(this.image);
  }

  compareByStorageTemperature(other: ProductType) {
    const priority: ProductStorageTemperature[] = [
      ProductStorageTemperature.Normal,
      ProductStorageTemperature.SemiRefrigerated,
      ProductStorageTemperature.Refrigerated,
      ProductStorageTemperature.Frozen,
    ];
    return priority.findIndex((p) => p === this.storageTemperature) - priority.findIndex((p) => p === other.storageTemperature);
  }

  get gid() {
    return convertToShopifyProductGID(this.shopifyProductId);
  }

  get calcTaxIncludedPrice() {
    if (this.taxPercentage > 0) {
      return calcTaxIncludedPrice(this.price, this.taxPercentage);
    }
  }

  isInactive() {
    return this.status !== ProductStatus.Active;
  }
}

export const convertShopifyProductGID = (gid: string) => {
  return gid.replace("gid://shopify/Product/", "");
};

export const convertToShopifyProductGID = (id: string) => {
  return `gid://shopify/Product/${id}`;
};
