import classNames from "classnames";

import { CFC } from "shared/types";

type Props = {
  onClick?: () => void;
}

export const Row: CFC<Props> = ({
  className,
  onClick,
  children,
}) => {
  return (
    <tr className={classNames(className)} onClick={onClick}>
      {children}
    </tr>
  );
};