/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchCostcoProductLogRequestCostcoProductLog
 */
export interface PatchCostcoProductLogRequestCostcoProductLog {
    /**
     * 
     * @type {number}
     * @memberof PatchCostcoProductLogRequestCostcoProductLog
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof PatchCostcoProductLogRequestCostcoProductLog
     */
    taxPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchCostcoProductLogRequestCostcoProductLog
     */
    weight?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchCostcoProductLogRequestCostcoProductLog
     */
    expiryDate?: Date | null;
}

/**
 * Check if a given object implements the PatchCostcoProductLogRequestCostcoProductLog interface.
 */
export function instanceOfPatchCostcoProductLogRequestCostcoProductLog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function PatchCostcoProductLogRequestCostcoProductLogFromJSON(json: any): PatchCostcoProductLogRequestCostcoProductLog {
    return PatchCostcoProductLogRequestCostcoProductLogFromJSONTyped(json, false);
}

export function PatchCostcoProductLogRequestCostcoProductLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchCostcoProductLogRequestCostcoProductLog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'price': json['price'],
        'taxPercentage': !exists(json, 'tax_percentage') ? undefined : json['tax_percentage'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : (json['expiry_date'] === null ? null : new Date(json['expiry_date'])),
    };
}

export function PatchCostcoProductLogRequestCostcoProductLogToJSON(value?: PatchCostcoProductLogRequestCostcoProductLog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'price': value.price,
        'tax_percentage': value.taxPercentage,
        'weight': value.weight,
        'expiry_date': value.expiryDate === undefined ? undefined : (value.expiryDate === null ? null : value.expiryDate.toISOString().substr(0,10)),
    };
}

