import { CheckoutRedeliveryReason } from "shared/models";

export function formatCheckoutRedeliveryReason(reason: CheckoutRedeliveryReason) {
  switch (reason) {
    case CheckoutRedeliveryReason.Pending:
      return "保留中";
    case CheckoutRedeliveryReason.Absent:
      return "不在";
    case CheckoutRedeliveryReason.AddressUnknown:
      return "お届け先不明";
    case CheckoutRedeliveryReason.OperationMiss:
      return "配達業務ミス";
    case CheckoutRedeliveryReason.Other:
      return "その他";
  }
}
