import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  text: string;
};

export const ArticleParagraphBlock: BFC<Props> = ({
  text,
  className,
}) => {
  return (
    <div
      className={classNames(className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
