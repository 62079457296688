/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateStockup201ResponseAllOfData } from './CreateStockup201ResponseAllOfData';
import {
    CreateStockup201ResponseAllOfDataFromJSON,
    CreateStockup201ResponseAllOfDataFromJSONTyped,
    CreateStockup201ResponseAllOfDataToJSON,
} from './CreateStockup201ResponseAllOfData';

/**
 * 
 * @export
 * @interface CreateStockup201Response
 */
export interface CreateStockup201Response {
    /**
     * 
     * @type {CreateStockup201ResponseAllOfData}
     * @memberof CreateStockup201Response
     */
    data: CreateStockup201ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof CreateStockup201Response
     */
    meta: object;
}

/**
 * Check if a given object implements the CreateStockup201Response interface.
 */
export function instanceOfCreateStockup201Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function CreateStockup201ResponseFromJSON(json: any): CreateStockup201Response {
    return CreateStockup201ResponseFromJSONTyped(json, false);
}

export function CreateStockup201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockup201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': CreateStockup201ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function CreateStockup201ResponseToJSON(value?: CreateStockup201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': CreateStockup201ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

