import classNames from "classnames";
import { useCallback, useMemo } from "react";

import { BFC } from "shared/types";

import { StrapiArticleBlock, StrapiArticleBlockHeader } from "~/features/magazines/types";

type Props = {
  blocks: StrapiArticleBlock[];
  onClick?: (id: string) => void;
};

export const TableOfContents: BFC<Props> = ({
  blocks,
  onClick,
  className,
}) => {
  const headerBlocks = useMemo(() => {
    return blocks.filter((block): block is StrapiArticleBlockHeader => block.type === "header");
  }, [blocks]);

  const onClickFactory = useCallback((id: string) => () => {
    onClick?.(id);
  }, [onClick]);

  if (headerBlocks.length === 0) {
    return null;
  }

  return (
    <div className={classNames("bg-gray-100 border-y", className)}>
      <div className="border-b p-4 flex items-center">
        <div className="grow">目次</div>
      </div>
      <ul className="p-3">
        {headerBlocks.map((block) => {
          const classes = classNames("cursor-pointer", {
            "not-first:border-t p-3": block.data.level === 2,
            "p-3 pl-4": block.data.level === 3,
            "p-3 pl-6": block.data.level === 4,
            "p-3 pl-8": block.data.level === 5,
            "p-3 pl-10": block.data.level === 6,
          });

          return (
            <li className={classes} key={block.id} onClick={onClickFactory(block.id)}>
              {block.data.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
