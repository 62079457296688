import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { DeliverableAddress } from "shared/models";
import { useStoreAPI } from "shared/services/api";

export const useDeliverableAddresses = () => {
  const api = useStoreAPI();
  const [addresses, setAddresses] = useState<DeliverableAddress[]>([]);

  const { isLoading } = useQuery(
    ["addresses/getDeliverableAddresses"],
    () => api.getDeliverableAddresses(),
    {
      onSuccess: ({ data: { addresses } }) => {
        setAddresses(addresses.map((address) => new DeliverableAddress(address)));
      },
    },
  );

  return {
    addresses,
    isLoading,
  };
};
