import Link from "next/link";
import Zoom from "react-medium-image-zoom";

import { Review } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

type Props = {
  review: Review;
};

export const ReviewItem: BFC<Props> = ({ review }) => {
  const { title, body, files, createdAt, createdAtFormatted } = review;

  return (
    <article className="py-12 bg-white flex flex-col gap-4">
      <h4 className="text-lg font-bold text-gray-900">
        <Link
          href={routes.REVIEWS_SHOW(review.productId, review.id)}
          className="hover:underline"
        >
          {title}
        </Link>
      </h4>

      <p className="text-gray-600 whitespace-pre-line">
        {body}
      </p>

      {files.length > 0 && (
        <ul
          role="list"
          className="grid grid-cols-2 gap-6 sm:grid-cols-3 lg:grid-cols-4"
        >
          {files.map((file, index) => (
            <li key={file.url}>
              <Zoom>
                <img
                  src={file.url}
                  alt={`レビュー「${title}」に添付された${index + 1}枚目の画像`}
                  className="aspect-square w-full object-cover rounded-lg bg-gray-100 hover:opacity-75"
                />
              </Zoom>
            </li>
          ))}
        </ul>
      )}

      <time className="text-gray-500" dateTime={createdAt.toISOString()}>
        {createdAtFormatted}
      </time>
    </article>
  );
};
