import { BFC } from "../../../types";
import {
  BaseDatePickerInputGroupProps,
  ControlledInputGroup,
} from "../ControlledInputGroup";
import { DatePickerInput } from "../DatePickerInput";

export type DatePickerInputGroupProps = Omit<
  BaseDatePickerInputGroupProps,
  "input"
>;

export const DatePickerInputGroup: BFC<DatePickerInputGroupProps> = (props) => {
  return <ControlledInputGroup {...props} input={DatePickerInput} />;
};
