/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FullfillmentStocking } from './FullfillmentStocking';
import {
    FullfillmentStockingFromJSON,
    FullfillmentStockingFromJSONTyped,
    FullfillmentStockingToJSON,
} from './FullfillmentStocking';

/**
 * 
 * @export
 * @interface GetCheckoutFullfillmentStockings200ResponseAllOfData
 */
export interface GetCheckoutFullfillmentStockings200ResponseAllOfData {
    /**
     * 
     * @type {Array<FullfillmentStocking>}
     * @memberof GetCheckoutFullfillmentStockings200ResponseAllOfData
     */
    fullfillmentStockings: Array<FullfillmentStocking>;
}

/**
 * Check if a given object implements the GetCheckoutFullfillmentStockings200ResponseAllOfData interface.
 */
export function instanceOfGetCheckoutFullfillmentStockings200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fullfillmentStockings" in value;

    return isInstance;
}

export function GetCheckoutFullfillmentStockings200ResponseAllOfDataFromJSON(json: any): GetCheckoutFullfillmentStockings200ResponseAllOfData {
    return GetCheckoutFullfillmentStockings200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckoutFullfillmentStockings200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckoutFullfillmentStockings200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullfillmentStockings': ((json['fullfillment_stockings'] as Array<any>).map(FullfillmentStockingFromJSON)),
    };
}

export function GetCheckoutFullfillmentStockings200ResponseAllOfDataToJSON(value?: GetCheckoutFullfillmentStockings200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullfillment_stockings': ((value.fullfillmentStockings as Array<any>).map(FullfillmentStockingToJSON)),
    };
}

