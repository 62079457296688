/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner
 */
export interface UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner
     */
    deliveryOrder?: number;
}

/**
 * Check if a given object implements the UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner interface.
 */
export function instanceOfUpdateDeliveryDriverParcelsRequestDeliveryParcelsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function UpdateDeliveryDriverParcelsRequestDeliveryParcelsInnerFromJSON(json: any): UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner {
    return UpdateDeliveryDriverParcelsRequestDeliveryParcelsInnerFromJSONTyped(json, false);
}

export function UpdateDeliveryDriverParcelsRequestDeliveryParcelsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'deliveryOrder': !exists(json, 'delivery_order') ? undefined : json['delivery_order'],
    };
}

export function UpdateDeliveryDriverParcelsRequestDeliveryParcelsInnerToJSON(value?: UpdateDeliveryDriverParcelsRequestDeliveryParcelsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'delivery_order': value.deliveryOrder,
    };
}

