// @ts-nocheck
import * as Types from '../../../services/shopify/graphql/types';

export type ProductFragmentFragment = (
  { __typename?: 'Product' }
  & Pick<Types.Product, 'id' | 'title' | 'description' | 'descriptionHtml'>
  & { featuredImage?: Types.Maybe<(
    { __typename?: 'Image' }
    & Pick<Types.Image, 'id' | 'url' | 'height' | 'width' | 'altText'>
  )>, priceRange: (
    { __typename?: 'ProductPriceRange' }
    & { minVariantPrice: (
      { __typename?: 'MoneyV2' }
      & Pick<Types.MoneyV2, 'amount'>
    ), maxVariantPrice: (
      { __typename?: 'MoneyV2' }
      & Pick<Types.MoneyV2, 'amount'>
    ) }
  ) }
);

export const ProductFragmentFragmentDoc = `
    fragment ProductFragment on Product {
  id
  title
  description
  descriptionHtml
  featuredImage {
    ... on Image {
      id
      url
      height
      width
      altText
    }
  }
  priceRange {
    minVariantPrice {
      amount
    }
    maxVariantPrice {
      amount
    }
  }
}
    `;