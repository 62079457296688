/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetStocks200ResponseAllOfData } from './GetStocks200ResponseAllOfData';
import {
    GetStocks200ResponseAllOfDataFromJSON,
    GetStocks200ResponseAllOfDataFromJSONTyped,
    GetStocks200ResponseAllOfDataToJSON,
} from './GetStocks200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetStocks200ResponseAllOf
 */
export interface GetStocks200ResponseAllOf {
    /**
     * 
     * @type {GetStocks200ResponseAllOfData}
     * @memberof GetStocks200ResponseAllOf
     */
    data: GetStocks200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetStocks200ResponseAllOf interface.
 */
export function instanceOfGetStocks200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetStocks200ResponseAllOfFromJSON(json: any): GetStocks200ResponseAllOf {
    return GetStocks200ResponseAllOfFromJSONTyped(json, false);
}

export function GetStocks200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStocks200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetStocks200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetStocks200ResponseAllOfToJSON(value?: GetStocks200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetStocks200ResponseAllOfDataToJSON(value.data),
    };
}

