import { immerable } from "immer";

import * as api from "../services/api/store/models";
import { Tag, TagType } from "./tag";

export type TagFamilyTagType = api.TagFamilyTag & {
  depth?: number;
  parent?: TagFamilyTagType;
};

export class TagFamilyTag implements TagFamilyTagType {
  [immerable] = true;

  id = "";
  tag: TagType = new Tag();
  children: TagFamilyTagType[] = [];
  depth = 0;
  parent?: TagFamilyTagType = undefined;

  constructor(data: Partial<TagFamilyTagType> = {}) {
    Object.assign(this, data);
  }

  getParent() {
    if (!this.parent) return null;
    return new TagFamilyTag(this.parent);
  }

  getChildren(recursive = false): TagFamilyTag[] {
    return this.children.map((child) => {
      const childTag = new TagFamilyTag({
        ...child,
        depth: this.depth + 1,
        parent: this,
      });
      return [childTag, recursive ? childTag.getChildren(recursive) : []].flat();
    }).flat();
  }
}
