/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Prefecture } from './Prefecture';
import {
    PrefectureFromJSON,
    PrefectureFromJSONTyped,
    PrefectureToJSON,
} from './Prefecture';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    zipCode: string;
    /**
     * 
     * @type {Prefecture}
     * @memberof Address
     */
    prefecture: Prefecture;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    town: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    deliveryInstructions?: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "zipCode" in value;
    isInstance = isInstance && "prefecture" in value;
    isInstance = isInstance && "city" in value;
    isInstance = isInstance && "town" in value;
    isInstance = isInstance && "street" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "phone" in value;

    return isInstance;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'zipCode': json['zip_code'],
        'prefecture': PrefectureFromJSON(json['prefecture']),
        'city': json['city'],
        'town': json['town'],
        'street': json['street'],
        'building': !exists(json, 'building') ? undefined : json['building'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'phone': json['phone'],
        'deliveryInstructions': !exists(json, 'delivery_instructions') ? undefined : json['delivery_instructions'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'zip_code': value.zipCode,
        'prefecture': PrefectureToJSON(value.prefecture),
        'city': value.city,
        'town': value.town,
        'street': value.street,
        'building': value.building,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'phone': value.phone,
        'delivery_instructions': value.deliveryInstructions,
    };
}

