import { useCallback, useState } from "react";

import { BFC } from "shared/types";

import { PasswordNewForm, PasswordNewData } from "../../forms";
import { usePasswordReset } from "../../hooks";

export const PasswordNewPage: BFC = () => {
  const { sendResetMail } = usePasswordReset();
  const [isSentResetMail, setIsSentResetMail] = useState(false);

  const onSubmit = useCallback((data: PasswordNewData) => sendResetMail(data, {
    onSuccess: () => setIsSentResetMail(true),
  }), []);

  return isSentResetMail ? (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">メール送信完了</h1>
        </div>
        <p>パスワード再設定用のURLをご入力のメールアドレスに送信しました。<br /> 記載された内容に従って、パスワードの再設定を行なってください。</p>
      </div>
    </div>
  ) : (
    <div className="bg-white p-3 py-6 pb-10 shadow flex flex-col gap-3">
      <div className="w-full max-w-2xl mx-auto flex flex-col gap-6">
        <div className="flex justify-between items-center">
          <h1 className="text-xl font-bold">パスワードを忘れた場合</h1>
        </div>
        <p>ご登録いただいたメールアドレスを入力してください。<br /> メールアドレス宛に、パスワード再設定ページのURLが記載されたメールを送信します。</p>
        <div className="flex flex-col gap-4">
          <PasswordNewForm onSubmit={onSubmit} />
        </div>
      </div>
    </div>
  );
};