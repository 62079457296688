import classNames from "classnames";

import { BFC } from "shared/types";

type Props = {
  src: string;
  withBorder?: boolean;
  className?: string;
};

export const ArticleImageBlock: BFC<Props> = ({
  src,
  withBorder,
  className,
}) => {
  const classes = classNames("w-full rounded", className, {
    "border": withBorder,
  });

  return (
    <div className="flex justify-center">
      <img src={src} className={classes} alt="image" />
    </div>
  );
};
