import { immerable } from "immer";

import * as api from "../services/api/models";

export type UserType = api.User;

export const userDefaultData: UserType = {
  id: "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  channelIoUserId: undefined,
};

export class User implements UserType {
  [immerable] = true;

  id = "";
  firstName = "";
  lastName = "";
  email = "";
  phone = "";
  channelIoUserId: string | undefined = undefined;

  constructor(data: Partial<UserType> = userDefaultData) {
    Object.assign(this, data);
  }

  get fullName() {
    return `${this.lastName} ${this.firstName}`;
  }
}
