/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateStockupRequestStockup
 */
export interface CreateStockupRequestStockup {
    /**
     * 
     * @type {Date}
     * @memberof CreateStockupRequestStockup
     */
    date: Date;
    /**
     * 
     * @type {string}
     * @memberof CreateStockupRequestStockup
     */
    costcoProductId: string;
    /**
     * 
     * @type {number}
     * @memberof CreateStockupRequestStockup
     */
    requiredQuantity: number;
    /**
     * 
     * @type {number}
     * @memberof CreateStockupRequestStockup
     */
    listingOrder: number;
    /**
     * 
     * @type {string}
     * @memberof CreateStockupRequestStockup
     */
    pickingGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateStockupRequestStockup
     */
    memo?: string;
}

/**
 * Check if a given object implements the CreateStockupRequestStockup interface.
 */
export function instanceOfCreateStockupRequestStockup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "costcoProductId" in value;
    isInstance = isInstance && "requiredQuantity" in value;
    isInstance = isInstance && "listingOrder" in value;

    return isInstance;
}

export function CreateStockupRequestStockupFromJSON(json: any): CreateStockupRequestStockup {
    return CreateStockupRequestStockupFromJSONTyped(json, false);
}

export function CreateStockupRequestStockupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStockupRequestStockup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': (new Date(json['date'])),
        'costcoProductId': json['costco_product_id'],
        'requiredQuantity': json['required_quantity'],
        'listingOrder': json['listing_order'],
        'pickingGroup': !exists(json, 'picking_group') ? undefined : json['picking_group'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
    };
}

export function CreateStockupRequestStockupToJSON(value?: CreateStockupRequestStockup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': (value.date.toISOString().substr(0,10)),
        'costco_product_id': value.costcoProductId,
        'required_quantity': value.requiredQuantity,
        'listing_order': value.listingOrder,
        'picking_group': value.pickingGroup,
        'memo': value.memo,
    };
}

