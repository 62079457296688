/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkout } from './Checkout';
import {
    CheckoutFromJSON,
    CheckoutFromJSONTyped,
    CheckoutToJSON,
} from './Checkout';
import type { Payment } from './Payment';
import {
    PaymentFromJSON,
    PaymentFromJSONTyped,
    PaymentToJSON,
} from './Payment';

/**
 * 
 * @export
 * @interface GetCheckout200ResponseAllOfData
 */
export interface GetCheckout200ResponseAllOfData {
    /**
     * 
     * @type {Checkout}
     * @memberof GetCheckout200ResponseAllOfData
     */
    checkout: Checkout;
    /**
     * 
     * @type {Payment}
     * @memberof GetCheckout200ResponseAllOfData
     */
    payment: Payment;
}

/**
 * Check if a given object implements the GetCheckout200ResponseAllOfData interface.
 */
export function instanceOfGetCheckout200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "checkout" in value;
    isInstance = isInstance && "payment" in value;

    return isInstance;
}

export function GetCheckout200ResponseAllOfDataFromJSON(json: any): GetCheckout200ResponseAllOfData {
    return GetCheckout200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCheckout200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCheckout200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkout': CheckoutFromJSON(json['checkout']),
        'payment': PaymentFromJSON(json['payment']),
    };
}

export function GetCheckout200ResponseAllOfDataToJSON(value?: GetCheckout200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkout': CheckoutToJSON(value.checkout),
        'payment': PaymentToJSON(value.payment),
    };
}

