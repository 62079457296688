import classNames from "classnames";
import { useCallback } from "react";

import { ShippingTime } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  time: ShippingTime;
  selected?: boolean;
  available?: boolean;
  onClick?: (time: ShippingTime) => void;
};

export const TimePickerItem: BFC<Props> = ({
  time,
  selected,
  available,
  onClick,
  className,
}) => {
  const classes = classNames("border rounded p-2 text-center", className, {
    "border-primary bg-primary-50": selected,
    "text-black-200 bg-gray-50": !available,
  });

  const onClickHandler = useCallback(() => {
    if (onClick && available) {
      onClick(time);
    }
  }, [time, onClick, available]);

  return (
    <div className={classes} onClick={onClickHandler}>
      {time.name}
    </div>
  );
};
