import { useCallback } from "react";

import { CFC } from "shared/types";

import { useAuthContext } from "~/features/auth/hooks";

import { BirthDateEditData, BirthDateEditForm } from "../../forms";
import { useUpdateBirthDate } from "../../hooks";

export const BirthDateRegisteredRoute: CFC = ({ children }) => {
  const { user, isLoading } = useAuthContext();
  const { update } = useUpdateBirthDate();

  const handleSubmit = useCallback(
    async (data: BirthDateEditData) => {
      return update(data);
    },
    [update]
  );

  if (isLoading) {
    return null;
  }

  if (user.birthDate) {
    return <>{children}</>;
  }

  return (
    <div className="flex flex-col gap-4 bg-white p-3 py-6">
      <h1 className="font-bold text-center">生年月日が登録されていません</h1>
      <div>
        お手数ですが、酒類がカートに入っておりますため、生年月日のご登録をお願いいたします。
      </div>
      <BirthDateEditForm onSubmit={handleSubmit} />
    </div>
  );
};
