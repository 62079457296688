/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface FullfillmentShippingItem
 */
export interface FullfillmentShippingItem {
    /**
     * 
     * @type {string}
     * @memberof FullfillmentShippingItem
     */
    id: string;
    /**
     * 
     * @type {Product}
     * @memberof FullfillmentShippingItem
     */
    product: Product;
    /**
     * 
     * @type {Product}
     * @memberof FullfillmentShippingItem
     */
    alternativeProduct?: Product;
    /**
     * 
     * @type {number}
     * @memberof FullfillmentShippingItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof FullfillmentShippingItem
     */
    cancelQuantity: number;
    /**
     * 
     * @type {string}
     * @memberof FullfillmentShippingItem
     */
    cancelReason: FullfillmentShippingItemCancelReasonEnum;
}


/**
 * @export
 */
export const FullfillmentShippingItemCancelReasonEnum = {
    No: 'no',
    OutOfStock: 'out_of_stock'
} as const;
export type FullfillmentShippingItemCancelReasonEnum = typeof FullfillmentShippingItemCancelReasonEnum[keyof typeof FullfillmentShippingItemCancelReasonEnum];


/**
 * Check if a given object implements the FullfillmentShippingItem interface.
 */
export function instanceOfFullfillmentShippingItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "cancelQuantity" in value;
    isInstance = isInstance && "cancelReason" in value;

    return isInstance;
}

export function FullfillmentShippingItemFromJSON(json: any): FullfillmentShippingItem {
    return FullfillmentShippingItemFromJSONTyped(json, false);
}

export function FullfillmentShippingItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): FullfillmentShippingItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'product': ProductFromJSON(json['product']),
        'alternativeProduct': !exists(json, 'alternative_product') ? undefined : ProductFromJSON(json['alternative_product']),
        'quantity': json['quantity'],
        'cancelQuantity': json['cancel_quantity'],
        'cancelReason': json['cancel_reason'],
    };
}

export function FullfillmentShippingItemToJSON(value?: FullfillmentShippingItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'product': ProductToJSON(value.product),
        'alternative_product': ProductToJSON(value.alternativeProduct),
        'quantity': value.quantity,
        'cancel_quantity': value.cancelQuantity,
        'cancel_reason': value.cancelReason,
    };
}

