import classNames from "classnames";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { MdLocalShipping } from "react-icons/md";
import { ColorRing } from "react-loader-spinner";

import { Button } from "shared/components";
import { useBooleanState } from "shared/hooks";
import { getParam } from "shared/lib/next";
import { BFC, isAPIError } from "shared/types";

import { ConfirmModal } from "~/components";

import { AddressForm, AddressData } from "../../forms";
import { useAddress } from "../../hooks";

type Props = {
  returnTo: string;
  showTitle?: boolean;
  showDelete?: boolean;
};

export const AddressEditPage: BFC<Props> = ({
  returnTo,
  showTitle = false,
  showDelete = true,
}) => {
  const router = useRouter();
  const id = getParam(router.query, "id");
  const { address, defaultAddress, update, isLoading, destroy, error } = useAddress(id);
  const [modalShown, showModal, hideModal] = useBooleanState(false);

  const onSubmit = useCallback(async (data: AddressData) => {
    await update(data);
    router.push(returnTo);
  }, [update, returnTo]);

  const onClick = useCallback(() => {
    showModal();
  }, []);

  const onConfirmFactory = useCallback((id: string) => () => {
    destroy(id, {
      onSuccess: () => {
        router.push(returnTo);
      }
    });
  }, []);

  const defaultValues: Partial<AddressData> = useMemo(() => {
    return {
      userAddress: {
        firstName: address.firstName,
        lastName: address.lastName,
        zipCode: address.zipCode,
        prefectureId: address.prefecture.id,
        city: address.city,
        town: address.town,
        street: address.street,
        building: address.building,
        phone: address.phone,
        deliveryInstructions: address.deliveryInstructions,
      },
      isDefault: defaultAddress?.id === address.id,
    };
  }, [address, defaultAddress]);

  useEffect(() => {
    if (error && isAPIError(error)) {
      if (error.response.status === 404) {
        router.push(returnTo);
      }
    }
  }, [error, returnTo]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ColorRing />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className={classNames({ "flex flex-col gap-4 pb-4": showTitle })}>
          {showTitle && (
            <div className="bg-white">
              <h1 className="flex items-center gap-2 font-bold px-5 py-6 text-lg">
                <MdLocalShipping  size={24} className="text-black-500" />
                お届け先の編集
              </h1>
            </div>
          )}
          <div className="bg-white p-3">
            <AddressForm
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              submitText="更新する"
              showDefault={true}
            />
          </div>
        </div>
        {showDelete && (
          <div className="flex flex-col gap-4 pb-4">
            <div className="bg-white">
              <h1 className="flex items-center gap-2 font-bold px-5 py-6 text-lg">
                <MdLocalShipping  size={24} className="text-black-500" />
                お届け先の削除
              </h1>
            </div>
            <div className="bg-white p-3">
              <Button block large onClick={onClick}>削除する</Button>
            </div>
          </div>
        )}
      </div>

      <ConfirmModal
        open={modalShown}
        title="お届け先の削除"
        description="本当にこのお届け先を削除しますか?"
        onConfirm={onConfirmFactory(id)}
        onCancel={hideModal}
      />
    </>
  );
};
