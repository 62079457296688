import classNames from "classnames";
import { Fragment, useCallback } from "react";

import { TagFamilyTag } from "shared/models";
import { BFC } from "shared/types";

type Props = {
  categories: TagFamilyTag[];
  selectedCategory?: TagFamilyTag | null;
  onSelect?: (category: TagFamilyTag) => void;
};

export const ProductCategorySelector: BFC<Props> = ({
  categories,
  selectedCategory,
  onSelect,
  className,
}) => {
  const onSelectFactory = useCallback((category: TagFamilyTag) => () => {
    if (onSelect) onSelect(category);
  }, [onSelect]);

  return (
    <div className={classNames("bg-white", className)}>
      <div className="flex w-max">
        {categories.map((category) => (
          <Fragment key={category.id}>
            <RootTag
              key={category.id}
              tag={category}
              onClick={onSelectFactory(category)}
              selected={category.id === selectedCategory?.id}
              className="cursor-pointer"
            />
            {category.getChildren().map((child) => (
              <ChildTag
                key={child.id}
                tag={child}
                onClick={onSelectFactory(child)}
                selected={child.id === selectedCategory?.id}
                className="cursor-pointer"
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

type TagProp = {
  tag: TagFamilyTag;
  selected?: boolean;
  onClick?: () => void;
};

const RootTag: BFC<TagProp> = ({
  tag,
  selected,
  onClick,
  className,
}) => {
  const classes = classNames("px-4 py-3 font-bold", {
    "text-black-400": !selected,
    "border-b-2 border-primary text-primary": selected,
  }, className);

  return (
    <div className={classes} onClick={onClick}>
      {tag.tag.name}
    </div>
  );
};

const ChildTag: BFC<TagProp> = ({
  tag,
  selected,
  onClick,
  className,
}) => {
  const classes = classNames("px-4 py-3", {
    "border-b-2 border-primary font-bold text-primary": selected,
  }, className);

  return (
    <div className={classes} onClick={onClick}>
      {tag.tag.name}
    </div>
  );
};
