/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserEmailMarketingAgreedRequestUser
 */
export interface UpdateUserEmailMarketingAgreedRequestUser {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserEmailMarketingAgreedRequestUser
     */
    emailMarketingAgreed: boolean;
}

/**
 * Check if a given object implements the UpdateUserEmailMarketingAgreedRequestUser interface.
 */
export function instanceOfUpdateUserEmailMarketingAgreedRequestUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "emailMarketingAgreed" in value;

    return isInstance;
}

export function UpdateUserEmailMarketingAgreedRequestUserFromJSON(json: any): UpdateUserEmailMarketingAgreedRequestUser {
    return UpdateUserEmailMarketingAgreedRequestUserFromJSONTyped(json, false);
}

export function UpdateUserEmailMarketingAgreedRequestUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserEmailMarketingAgreedRequestUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailMarketingAgreed': json['email_marketing_agreed'],
    };
}

export function UpdateUserEmailMarketingAgreedRequestUserToJSON(value?: UpdateUserEmailMarketingAgreedRequestUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email_marketing_agreed': value.emailMarketingAgreed,
    };
}

