/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Sale } from './Sale';
import {
    SaleFromJSON,
    SaleFromJSONTyped,
    SaleToJSON,
} from './Sale';

/**
 * 
 * @export
 * @interface GetSale200ResponseAllOfData
 */
export interface GetSale200ResponseAllOfData {
    /**
     * 
     * @type {Sale}
     * @memberof GetSale200ResponseAllOfData
     */
    sale: Sale;
}

/**
 * Check if a given object implements the GetSale200ResponseAllOfData interface.
 */
export function instanceOfGetSale200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sale" in value;

    return isInstance;
}

export function GetSale200ResponseAllOfDataFromJSON(json: any): GetSale200ResponseAllOfData {
    return GetSale200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetSale200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSale200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sale': SaleFromJSON(json['sale']),
    };
}

export function GetSale200ResponseAllOfDataToJSON(value?: GetSale200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sale': SaleToJSON(value.sale),
    };
}

