import qs from "qs";

export const routes = {
  TOP: "/",
  TOP_WITH_PARAMS: (params?: { sort?: string; }) => params ? `/?${qs.stringify(params)}` : "/",
  LANDING_PAGE: "/lp",
  MYPAGE: "/mypage",
  MYPAGE_ADDRESSES: "/mypage/addresses",
  MYPAGE_ADDRESSES_NEW: "/mypage/addresses/new",
  MYPAGE_ADDRESSES_EDIT: (id: string) => `/mypage/addresses/${id}/edit`,
  MYPAGE_PAYMENT_METHODS: "/mypage/payment_methods",
  MYPAGE_PAYMENT_METHODS_NEW: "/mypage/payment_methods/new",
  MYPAGE_COUPON_CODES: "/mypage/coupon_codes",
  MYPAGE_EMAIL_SHOW: "/mypage/email",
  MYPAGE_EMAIL_EDIT: "/mypage/email/edit",
  MYPAGE_NAME: "/mypage/name",
  MYPAGE_NOTIFICATIONS: "/mypage/notifications",
  MYPAGE_PROVIDERS: "/mypage/providers",
  MYPAGE_CANCEL_MEMBERSHIP: "/mypage/cancel_membership",
  MYPAGE_FRIEND_COUPON: "/mypage/friend_coupon",
  MYPAGE_BIRTH_DATE: "/mypage/birth_date",
  USERS_PASSWORD_NEW: "/users/password/new",
  USERS_SIGN_IN: "/users/sign_in",
  USERS_SIGN_UP: "/users/sign_up",
  USERS_AUTH_WITH_GOOGLE: (params: { return_to?: string } = {}) =>
    `${
      process.env.NEXT_PUBLIC_API_ORIGIN
    }/users/auth/google_oauth2?${qs.stringify(params)}`,
  PRODUCTS_REQUEST: "https://forms.gle/nfNVx3wF8ahFNwAS6",
  PRODUCTS_SHOW: (id: string) => `/products/${id}`,
  PRODUCTS_POPULAR: "/products/popular",
  REVIEWS_INDEX: (productId: string) => `/products/${productId}/reviews`,
  REVIEWS_NEW: (productId: string) => `/products/${productId}/reviews/new`,
  REVIEWS_SHOW: (productId: string, reviewId: string) => `/products/${productId}/reviews/${reviewId}`,
  PRODUCTS_SEARCH: (keyword: string, params?: { sort?: string; }) => `/products/search?keyword=${keyword}${params ? `&${qs.stringify(params)}` : ""}`,
  CATEGORY_PRODUCTS: (category: string, params?: { sort?: string }) => `/categories/${category}/products${params ? `?${qs.stringify(params)}` : ""}`,
  FAVORITES: "/favorites",
  CARTS_SHOW: "/carts",
  CHECKOUTS: "/checkouts",
  CHECKOUTS_SHOW: (id: string) => `/checkouts/${id}`,
  CHECKOUTS_NEW: "/checkouts/new",
  CHECKOUTS_NEW_ADDRESSES: "/checkouts/addresses",
  CHECKOUTS_NEW_ADDRESSES_NEW: "/checkouts/addresses/new",
  CHECKOUTS_NEW_ADDRESSES_EDIT: (id: string) => `/checkouts/addresses/${id}/edit`,
  CHECKOUTS_NEW_PAYMENT_METHODS: "/checkouts/payment_methods",
  CHECKOUTS_NEW_PAYMENT_METHODS_NEW: "/checkouts/payment_methods/new",
  CHECKOUTS_PAYMENT_METHODS: (id: string) => `/checkouts/${id}/payment_methods`,
  CHECKOUTS_PAYMENT_METHODS_NEW: (id: string) => `/checkouts/${id}/payment_methods/new`,
  CHECKOUTS_THANKS: "/checkouts/thanks",
  SALES_SHOW: (id: string) => `/sales/${id}`,
  PRODUCT_RANKINGS_SHOW: (id: string) => `/rankings/${id}/products`,
  ADDRESSES_DELIVERABLES: "/addresses/deliverables",
  MAGAZINE_TOP: "/magazine",
  MAGAZINE_CATEGORIES_SHOW: (id: string) => `/magazine/categories/${id}`,
  MAGAZINE_ARTICLES_SHOW: (id: string) => `/magazine/articles/${id}`,
  DOCUMENTS_PRIVACY_POLICY: "/documents/privacy_policy",
  DOCUMENTS_TERMS_OF_SERVICE: "/documents/terms_of_service",
  DOCUMENTS_TOKUSHOHO: "/documents/tokushoho",
  FAQ: "https://western-day-f05.notion.site/2468305f909c47e985ff8f9291f5a14d",
  RELEASE_NOTES:
    "https://western-day-f05.notion.site/SocToc-78d1ac83b45e456f81e72816c8764993",
  SNS_INSTAGRAM: "https://www.instagram.com/soctoc_jp",
  SNS_TWITTER: "https://twitter.com/soctoc_jp",
};
