import { isPast } from "date-fns";
import { z } from "zod";

import { Button, DateSelectControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    birthDate: z
      .date()
      .refine((value) => isPast(value), "過去の日付を選択してください"),
  }),
});

export type BirthDateEditData = z.infer<typeof schema>;

export const BirthDateEditForm = createHookForm<BirthDateEditData>(
  ({ formState: { isSubmitting } }) => {
    return (
      <div className="flex flex-col gap-4">
        <DateSelectControlGroup name="user.birthDate" label="生年月日" />

        <ul className="text-gray-600">
          <li>生年月日は酒類をご購入の際に年齢確認のために使用します。</li>
          <li>20歳未満の者に対しては酒類を販売しません。</li>
          <li>生年月日は設定後に変更することはできません。</li>
        </ul>

        <Button type="submit" block primary large loading={isSubmitting}>
          生年月日を設定する
        </Button>
      </div>
    );
  },
  {
    schema,
  }
);
