/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PatchCostcoProductLogRequestCostcoProductLog } from './PatchCostcoProductLogRequestCostcoProductLog';
import {
    PatchCostcoProductLogRequestCostcoProductLogFromJSON,
    PatchCostcoProductLogRequestCostcoProductLogFromJSONTyped,
    PatchCostcoProductLogRequestCostcoProductLogToJSON,
} from './PatchCostcoProductLogRequestCostcoProductLog';

/**
 * 
 * @export
 * @interface PatchCostcoProductLogRequest
 */
export interface PatchCostcoProductLogRequest {
    /**
     * 
     * @type {PatchCostcoProductLogRequestCostcoProductLog}
     * @memberof PatchCostcoProductLogRequest
     */
    costcoProductLog: PatchCostcoProductLogRequestCostcoProductLog;
}

/**
 * Check if a given object implements the PatchCostcoProductLogRequest interface.
 */
export function instanceOfPatchCostcoProductLogRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProductLog" in value;

    return isInstance;
}

export function PatchCostcoProductLogRequestFromJSON(json: any): PatchCostcoProductLogRequest {
    return PatchCostcoProductLogRequestFromJSONTyped(json, false);
}

export function PatchCostcoProductLogRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchCostcoProductLogRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProductLog': PatchCostcoProductLogRequestCostcoProductLogFromJSON(json['costco_product_log']),
    };
}

export function PatchCostcoProductLogRequestToJSON(value?: PatchCostcoProductLogRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product_log': PatchCostcoProductLogRequestCostcoProductLogToJSON(value.costcoProductLog),
    };
}

