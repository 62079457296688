import { useCallback, useMemo } from "react";
import { FaChevronRight } from "react-icons/fa";
import { FcClock, FcShop, FcIdea, FcBullish } from "react-icons/fc";
import { HiOutlineTruck } from "react-icons/hi";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link, Button } from "shared/components";
import { formatDateTime } from "shared/helpers";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { useCarouselItems, TOP_CAROUSEL_HANDLE, TopCarousel } from "~/features/carousels";
import { useRankingProducts, HOT_PRODUCTS_RANKING_SLUG } from "~/features/rankings";
import { useSales } from "~/features/sales";

import { ProductCard, ProductSortDropdown, ProductsSlider } from "../../components";
import { useProducts, usePopularProducts, useProductsQueryParams } from "../../hooks";

export const TopPage: BFC = () => {
  const { user } = useAuthContext();
  const { carouselItems } = useCarouselItems(TOP_CAROUSEL_HANDLE);
  const { sort, setSort } = useProductsQueryParams();
  const {
    fetchNextPage,
    products,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useProducts({
    sort,
  });
  const { products: popularProducts } = usePopularProducts();
  const { ranking, products: hotProducts } = useRankingProducts(HOT_PRODUCTS_RANKING_SLUG);

  const { sales } = useSales();

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const slides = useMemo(() => {
    return carouselItems.map((item) => (
      <TopCarousel.Item key={item.id} image={item.image.url} link={item.url} />
    ));
  }, [carouselItems]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      {slides.length > 0 && (
        <TopCarousel
          slides={slides}
          className="bg-white aspect-video md:aspect-auto md:h-80"
          containerClassName="gap-4"
          slideClassName=""
        />
      )}

      {sales.map((sale) => (
        <div className="bg-white" key={sale.id}>
          <div className="flex flex-wrap items-center p-3">
            <div className="flex gap-1 mr-auto">
              <FcClock size={20} />
              <h2 className="font-bold text-lg">{sale.name}開催中</h2>
            </div>
            <div className="text-xs bg-primary rounded-full text-white py-1 px-2 shrink-0 ml-auto">
              {formatDateTime(sale.endAt)}まで
            </div>
          </div>
          <ProductsSlider products={sale.getProducts()} />
          <div className="flex justify-end items-center gap-1 p-3">
            <FaChevronRight size={16} />
            <Link href={routes.SALES_SHOW(sale.id)}>もっとみる</Link>
          </div>
        </div>
      ))}

      {user.checkoutCount == 0 && popularProducts.length > 0 && (
        <div className="bg-white">
          <div className="flex flex-wrap items-center p-3">
            <div className="flex gap-1 mr-auto">
              <FcIdea size={20} />
              <h2 className="font-bold text-lg">人気・おすすめ</h2>
            </div>
          </div>
          <ProductsSlider products={popularProducts} />
          <div className="flex justify-end items-center gap-1 p-3">
            <FaChevronRight size={16} />
            <Link href={routes.PRODUCTS_POPULAR}>もっとみる</Link>
          </div>
        </div>
      )}

      {hotProducts.length > 0 && (
        <div className="bg-white">
          <div className="flex flex-wrap items-center p-3">
            <div className="flex gap-1 mr-auto">
              <FcBullish size={20} />
              <h2 className="font-bold text-lg">{ranking.name}</h2>
            </div>
          </div>
          <ProductsSlider products={hotProducts} />
          <div className="flex justify-end items-center gap-1 p-3">
            <FaChevronRight size={16} />
            <Link href={routes.PRODUCT_RANKINGS_SHOW(ranking.slug)}>もっとみる</Link>
          </div>
        </div>
      )}

      <div className="bg-white p-3">
        <div className="flex justify-between items-center">
          <div className="flex gap-1">
            <FcShop size={20} />
            <h2 className="font-bold text-lg">商品一覧</h2>
          </div>
          <div className="text-xs">商品は随時拡充中です😊</div>
        </div>
        <div className="mt-2">
          <ProductSortDropdown value={sort} onChange={setSort} />
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="mt-3 grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-4">
            {products.map((product) => (
              <Link
                key={product.id}
                href={routes.PRODUCTS_SHOW(product.id)}
              >
                <ProductCard product={product} />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
        {isLoading || isFetchingNextPage ? (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        ) : (
          <Link href={routes.PRODUCTS_REQUEST}>
            <Button primary block className="mt-8">
              商品をリクエストする
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

const June23 = () => (
  <div className="h-full flex items-center justify-center">
    <div className="bg-gradient-to-r from-blue-700 via-sky-500 to-cyan-300 text-white flex flex-col gap-4 items-center justify-center aspect-video h-full w-full">
      <div className="font-bold text-lg">6月16日より対象エリア拡大 🎉</div>
      <div className="text-center">
        豊島区・文京区・板橋区・北区
        <br />
        吉祥寺周辺エリアが新たに追加されました！
      </div>
      <Link
        href={routes.ADDRESSES_DELIVERABLES}
        className="flex gap-1 items-center"
      >
        <HiOutlineTruck size={20} />
        対象エリアの詳細はコチラ
      </Link>
    </div>
  </div>
);

const ShippingRateFree = () => (
  <div className="h-full flex items-center justify-center">
    <div className="bg-gradient-to-r from-emerald-500 via-green-500 to-lime-400 text-white flex flex-col gap-4 items-center justify-center aspect-video h-full w-full">
      <div className="font-bold text-lg">好評のため12月末まで期間延長💡</div>
      <div className="text-center">
        商品代金15,000円(税抜)以上で<br />
        配送手数料が無料になるキャンペーンを実施中！
      </div>
      <div className="text-center">
        🚚🚚🚚💨💨💨
      </div>
      <div className="text-center">
        お得なこの機会にぜひご利用ください！
      </div>
    </div>
  </div>
);

const FriendCoupon = () => (
  <div className="h-full flex items-center justify-center">
    <div className="bg-gradient-to-r from-orange-500 via-amber-500 to-yellow-400 text-white flex flex-col gap-4 items-center justify-center aspect-video h-full w-full">
      <div className="font-bold text-lg">お友達紹介クーポン機能❤️</div>
      <div className="text-center">
        SocTocを知らないお友達にクーポンを送ると<br />
        紹介したあなたも特典クーポンが貰えるように！
      </div>
      <div className="text-center">
        今なら期間限定でクーポン金額増額中 🎁
      </div>
      <Link
        href={routes.MYPAGE_FRIEND_COUPON}
        className="flex gap-1 items-center"
      >
        詳細は👉こちら
      </Link>
    </div>
  </div>
);
