/* tslint:disable */
/* eslint-disable */
/**
 * Common
 * 共通API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetShippingTimes200ResponseAllOfData } from './GetShippingTimes200ResponseAllOfData';
import {
    GetShippingTimes200ResponseAllOfDataFromJSON,
    GetShippingTimes200ResponseAllOfDataFromJSONTyped,
    GetShippingTimes200ResponseAllOfDataToJSON,
} from './GetShippingTimes200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetShippingTimes200Response
 */
export interface GetShippingTimes200Response {
    /**
     * 
     * @type {GetShippingTimes200ResponseAllOfData}
     * @memberof GetShippingTimes200Response
     */
    data: GetShippingTimes200ResponseAllOfData;
    /**
     * 
     * @type {object}
     * @memberof GetShippingTimes200Response
     */
    meta: object;
}

/**
 * Check if a given object implements the GetShippingTimes200Response interface.
 */
export function instanceOfGetShippingTimes200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function GetShippingTimes200ResponseFromJSON(json: any): GetShippingTimes200Response {
    return GetShippingTimes200ResponseFromJSONTyped(json, false);
}

export function GetShippingTimes200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetShippingTimes200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetShippingTimes200ResponseAllOfDataFromJSON(json['data']),
        'meta': json['meta'],
    };
}

export function GetShippingTimes200ResponseToJSON(value?: GetShippingTimes200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetShippingTimes200ResponseAllOfDataToJSON(value.data),
        'meta': value.meta,
    };
}

