/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductNote
 */
export interface ProductNote {
    /**
     * 
     * @type {string}
     * @memberof ProductNote
     */
    heading: string;
    /**
     * 
     * @type {string}
     * @memberof ProductNote
     */
    body: string;
}

/**
 * Check if a given object implements the ProductNote interface.
 */
export function instanceOfProductNote(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "heading" in value;
    isInstance = isInstance && "body" in value;

    return isInstance;
}

export function ProductNoteFromJSON(json: any): ProductNote {
    return ProductNoteFromJSONTyped(json, false);
}

export function ProductNoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductNote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'heading': json['heading'],
        'body': json['body'],
    };
}

export function ProductNoteToJSON(value?: ProductNote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'heading': value.heading,
        'body': value.body,
    };
}

