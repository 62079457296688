/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProductLog } from './CostcoProductLog';
import {
    CostcoProductLogFromJSON,
    CostcoProductLogFromJSONTyped,
    CostcoProductLogToJSON,
} from './CostcoProductLog';

/**
 * 
 * @export
 * @interface DeleteCostcoProductLog200ResponseAllOfData
 */
export interface DeleteCostcoProductLog200ResponseAllOfData {
    /**
     * 
     * @type {CostcoProductLog}
     * @memberof DeleteCostcoProductLog200ResponseAllOfData
     */
    costcoProductLog?: CostcoProductLog;
}

/**
 * Check if a given object implements the DeleteCostcoProductLog200ResponseAllOfData interface.
 */
export function instanceOfDeleteCostcoProductLog200ResponseAllOfData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteCostcoProductLog200ResponseAllOfDataFromJSON(json: any): DeleteCostcoProductLog200ResponseAllOfData {
    return DeleteCostcoProductLog200ResponseAllOfDataFromJSONTyped(json, false);
}

export function DeleteCostcoProductLog200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCostcoProductLog200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProductLog': !exists(json, 'costco_product_log') ? undefined : CostcoProductLogFromJSON(json['costco_product_log']),
    };
}

export function DeleteCostcoProductLog200ResponseAllOfDataToJSON(value?: DeleteCostcoProductLog200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product_log': CostcoProductLogToJSON(value.costcoProductLog),
    };
}

