/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetDeliveryDrivers200ResponseAllOfData } from './GetDeliveryDrivers200ResponseAllOfData';
import {
    GetDeliveryDrivers200ResponseAllOfDataFromJSON,
    GetDeliveryDrivers200ResponseAllOfDataFromJSONTyped,
    GetDeliveryDrivers200ResponseAllOfDataToJSON,
} from './GetDeliveryDrivers200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetDeliveryDrivers200ResponseAllOf
 */
export interface GetDeliveryDrivers200ResponseAllOf {
    /**
     * 
     * @type {GetDeliveryDrivers200ResponseAllOfData}
     * @memberof GetDeliveryDrivers200ResponseAllOf
     */
    data: GetDeliveryDrivers200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetDeliveryDrivers200ResponseAllOf interface.
 */
export function instanceOfGetDeliveryDrivers200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetDeliveryDrivers200ResponseAllOfFromJSON(json: any): GetDeliveryDrivers200ResponseAllOf {
    return GetDeliveryDrivers200ResponseAllOfFromJSONTyped(json, false);
}

export function GetDeliveryDrivers200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetDeliveryDrivers200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetDeliveryDrivers200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetDeliveryDrivers200ResponseAllOfToJSON(value?: GetDeliveryDrivers200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetDeliveryDrivers200ResponseAllOfDataToJSON(value.data),
    };
}

