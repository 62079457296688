import { z } from "zod";

import { Button, TextControlGroup } from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

const schema = z.object({
  user: z.object({
    lastName: z.string().min(1),
    firstName: z.string().min(1),
  }),
});

export type NameEditData = z.infer<typeof schema>;

export const NameEditForm = createHookForm<NameEditData>(
  ({ formState: { isSubmitting } }) => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <TextControlGroup
            name="user.lastName"
            label="姓"
            placeholder="ソックトック"
            required
            className="flex-1"
          />
          <TextControlGroup
            name="user.firstName"
            label="名"
            placeholder="太郎"
            required
            className="flex-1"
          />
        </div>

        <Button type="submit" block primary large loading={isSubmitting}>
          氏名を更新する
        </Button>
      </div>
    );
  },
  {
    schema,
  }
);
