import { useQuery } from "@tanstack/react-query";
import { produce } from "immer";
import { useState } from "react";

import { Product, ProductType } from "shared/models";
import { useStoreAPI } from "shared/services/api";

export const useProduct = (id: string, defaultData?: ProductType) => {
  const [product, setProduct] = useState(new Product(defaultData));

  const api = useStoreAPI();
  const { isLoading } = useQuery(["product", id], () => api.getProduct(id), {
    onSuccess: ({ data: { product: p } }) => {
      setProduct((current) => produce(current, (draft) => {
        draft.id = p.id;
        draft.title = p.title;
        draft.description = p.description;
        draft.descriptionHtml = p.descriptionHtml;
        draft.price = p.price;
        draft.discountPrice = p.discountPrice;
        draft.shopifyProductId = p.shopifyProductId;
        draft.taxPercentage = p.taxPercentage;
        draft.image = p.image;
        draft.images = p.images || [];
        draft.status = p.status;
        draft.isFavorite = p.isFavorite;
        draft.tags = p.tags || [];
        draft.relatedProducts = p.relatedProducts || [];
        draft.sellableQuantity = p.sellableQuantity;
      }));
    },
  });

  return {
    product,
    isLoading,
  };
};
