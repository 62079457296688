/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseErrorNotAcceptableAllOfError } from './ResponseErrorNotAcceptableAllOfError';
import {
    ResponseErrorNotAcceptableAllOfErrorFromJSON,
    ResponseErrorNotAcceptableAllOfErrorFromJSONTyped,
    ResponseErrorNotAcceptableAllOfErrorToJSON,
} from './ResponseErrorNotAcceptableAllOfError';

/**
 * 
 * @export
 * @interface ResponseErrorNotAcceptable
 */
export interface ResponseErrorNotAcceptable {
    /**
     * 
     * @type {ResponseErrorNotAcceptableAllOfError}
     * @memberof ResponseErrorNotAcceptable
     */
    error: ResponseErrorNotAcceptableAllOfError;
}

/**
 * Check if a given object implements the ResponseErrorNotAcceptable interface.
 */
export function instanceOfResponseErrorNotAcceptable(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "error" in value;

    return isInstance;
}

export function ResponseErrorNotAcceptableFromJSON(json: any): ResponseErrorNotAcceptable {
    return ResponseErrorNotAcceptableFromJSONTyped(json, false);
}

export function ResponseErrorNotAcceptableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseErrorNotAcceptable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'error': ResponseErrorNotAcceptableAllOfErrorFromJSON(json['error']),
    };
}

export function ResponseErrorNotAcceptableToJSON(value?: ResponseErrorNotAcceptable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'error': ResponseErrorNotAcceptableAllOfErrorToJSON(value.error),
    };
}

