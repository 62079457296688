/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * 
 * @export
 * @interface GetUserAddresses200ResponseAllOfData
 */
export interface GetUserAddresses200ResponseAllOfData {
    /**
     * 
     * @type {Array<Address>}
     * @memberof GetUserAddresses200ResponseAllOfData
     */
    userAddresses: Array<Address>;
    /**
     * 
     * @type {Address}
     * @memberof GetUserAddresses200ResponseAllOfData
     */
    defaultAddress?: Address;
}

/**
 * Check if a given object implements the GetUserAddresses200ResponseAllOfData interface.
 */
export function instanceOfGetUserAddresses200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userAddresses" in value;

    return isInstance;
}

export function GetUserAddresses200ResponseAllOfDataFromJSON(json: any): GetUserAddresses200ResponseAllOfData {
    return GetUserAddresses200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetUserAddresses200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserAddresses200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAddresses': ((json['user_addresses'] as Array<any>).map(AddressFromJSON)),
        'defaultAddress': !exists(json, 'default_address') ? undefined : AddressFromJSON(json['default_address']),
    };
}

export function GetUserAddresses200ResponseAllOfDataToJSON(value?: GetUserAddresses200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_addresses': ((value.userAddresses as Array<any>).map(AddressToJSON)),
        'default_address': AddressToJSON(value.defaultAddress),
    };
}

