import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";
import { HiChevronRight, HiOutlineDocument, HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { HiXMark } from "react-icons/hi2";

import { BFC } from "shared/types";

import { routes } from "~/constants";
import { useAuthContext } from "~/features/auth";
import { useMagazineCategiories } from "~/features/magazines";
import { useProductCategories, useProductsQueryParams } from "~/features/products";

type MenuItem = {
  label: string;
  href: string;
};

type MenuItemGroup = {
  label: string;
  items: MenuItem[];
};

type Props = {
  hideHeader?: boolean;
  onClose?: () => void;
};

export const Sidebar: BFC<Props> = ({
  onClose,
  hideHeader = false,
  className,
}) => {
  const { isSignedIn } = useAuthContext();
  const { categories } = useProductCategories();
  const { categories: magazineCategories } = useMagazineCategiories();

  const { parsedQueryParams } = useProductsQueryParams();

  const menuItemGroups = useMemo(() => {
    const groups: MenuItemGroup[] = [];

    if (isSignedIn) {
      groups.push({
        label: "マイメニュー",
        items: [
          { label: "マイページ", href: routes.MYPAGE },
          { label: "購入履歴", href: routes.CHECKOUTS },
          { label: "クーポン", href: routes.MYPAGE_COUPON_CODES },
          { label: "お気に入り商品", href: routes.FAVORITES },
          { label: "お友達紹介", href: routes.MYPAGE_FRIEND_COUPON },
        ],
      });
    } else {
      groups.push({
        label: "マイメニュー",
        items: [
          { label: "新規会員登録", href: routes.USERS_SIGN_UP },
          { label: "ログイン", href: routes.USERS_SIGN_IN },
        ],
      });
    }

    groups.push({
      label: "買い物を楽しむ",
      items: categories.map((c) => ({
        label: c.tag.name,
        href: routes.CATEGORY_PRODUCTS(c.id, parsedQueryParams),
      })).concat([
        { label: "配送可能エリアを確認", href: routes.ADDRESSES_DELIVERABLES },
      ]),
    });

    if (process.env.NEXT_PUBLIC_SOCTOC_MAGAZINE_OPEN === "1") {
      groups.push({
        label: "SocTocマガジン",
        items: [
          { label: "マガジンTOP", href: routes.MAGAZINE_TOP },
        ].concat(magazineCategories.map((category) => ({
          label: category.name,
          href: routes.MAGAZINE_CATEGORIES_SHOW(category.slug),
        }))),
      });
    }

    return groups;
  }, [isSignedIn, magazineCategories, parsedQueryParams]);

  return (
    <div className={classNames("flex flex-col bg-white justify-between", className)}>
      <div>
        {!hideHeader && (
          <div className="flex items-center justify-between px-3 py-3 gap-3">
            <div className="flex-1">
              <img
                src="/assets/images/logo/logo_primary.png"
                className="h-6 w-auto"
              />
            </div>
            <button className="text-gray-600" onClick={onClose}>
              <HiXMark size={24} />
            </button>
          </div>
        )}
        <div className="flex flex-col overflow-y-auto border-b">
          {menuItemGroups.map((group) => (
            <div key={group.label}>
              <div className="bg-black-50 p-2 text-sm text-black-400">
                {group.label}
              </div>
              <div className="flex flex-col divide-y text-black-600">
                {group.items.map((item) => (
                  <Link key={item.href} href={item.href} className="flex p-3 items-center justify-between" onClick={onClose}>
                    {item.label}
                    <HiChevronRight size={20} className="text-black-400" />
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col text-gray-500 p-4 gap-3 mt-10">
        <div>
          <a href={routes.LANDING_PAGE} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <Image src="/favicons/favicon.ico" alt="soctoc" className="rounded-full" width={20} height={20} />
            SocTocとは
          </a>
        </div>
        <div>
          <a href={routes.FAQ} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <HiOutlineQuestionMarkCircle size={20} className="text-gray-500" />
            よくあるご質問
          </a>
        </div>
        <div>
          <a href={routes.RELEASE_NOTES} target="_blank" rel="noreferrer" className="flex items-center gap-2">
            <HiOutlineDocument size={20} className="text-gray-500" />
            リリースノート
          </a>
        </div>
      </div>
    </div>
  );
};
