import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import { usePaging } from "shared/features/utils";
import { Checkout } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

export const useCheckouts = (page?: string) => {
  const { token } = useAuthContext();
  const api = useStoreAPI({ accessToken: token });
  const [checkouts, setCheckouts] = useState<Checkout[]>([]);
  const paging = usePaging();
  const { currentPage, setMeta } = paging;

  const { isLoading } = useQuery(
    ["checkouts"],
    () => api.getCheckouts(currentPage),
    {
      onSuccess: ({ data: { checkouts }, meta: { paging } }) => {
        setCheckouts(checkouts.map((checkout) => new Checkout(checkout)));
        setMeta(paging);
      },
    },
  );

  return {
    checkouts,
    paging,
    isLoading,
  };
};