import { useCallback } from "react";

import { Cart } from "shared/models";

import { useCartContext } from "./useCartContext";

export const useMergeCart = () => {
  const { addCartItem, isMutating } = useCartContext();

  const merge = useCallback(async (destId: string, sourceCart: Cart) => {
    if (destId === sourceCart.id) {
      return;
    }

    for (const item of sourceCart.items) {
      await addCartItem(item.product.id, {
        quantity: item.quantity,
        cartId: destId,
      });
    }
  }, []);

  return {
    merge,
    isMutating,
  };
};
