import { isPast } from "date-fns";
import { validate as validateEmail } from "email-validator";
import { DeepPartial } from "react-hook-form";
import { z } from "zod";

import {
  Button,
  CheckboxControlGroup,
  DateSelectControlGroup,
  TextControlGroup,
} from "shared/components";
import { createHookForm } from "shared/lib/hook-form";

import { routes } from "~/constants";

const schema = z.object({
  user: z.object({
    email: z.string().min(1).refine((value) => validateEmail(value), {
      message: "Invalid email",
    }),
    password: z.string().min(1).min(8, "最低8文字で入力してください"),
    passwordConfirmation: z.string().min(1),
    lastName: z.string().min(1),
    firstName: z.string().min(1),
    birthDate: z.date().optional().refine((value) => !value || isPast(value), "未来の日付は指定できません"),
    zipCode: z.string().min(1).regex(/^\d{7}$/, "形式が正しくありません"),
    emailMarketingAgreed: z.boolean(),
  }).refine(
    (data) => data.password === data.passwordConfirmation,
    {
      message: "パスワードが一致しません",
      path: ["passwordConfirmation"],
    },
  ),
});

export type SignUpData = z.infer<typeof schema>;

const defaultValues: DeepPartial<SignUpData> = {
  user: {
    emailMarketingAgreed: true,
  },
};

export const SignUpForm = createHookForm<SignUpData>(({
  formState: { isSubmitting },
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4">
        <TextControlGroup name="user.lastName" label="姓" placeholder="ソックトック" required className="flex-1" />
        <TextControlGroup name="user.firstName" label="名" placeholder="太郎" required className="flex-1" />
      </div>
      <TextControlGroup name="user.email" label="メールアドレス" placeholder="taro@soctoc.jp" required />
      <TextControlGroup name="user.password" type="password" label="パスワード" required />
      <TextControlGroup name="user.passwordConfirmation" type="password" label="パスワード(確認)" required />
      <TextControlGroup name="user.zipCode" label="郵便番号" note="例: 1234567" required />
      <DateSelectControlGroup
        name="user.birthDate"
        label="生年月日"
        note="生年月日は登録後変更できません。酒類ご購入時の年齢確認のために使用します。20歳未満の者に対しては酒類を販売しません。生年月日を登録された方限定でバースデークーポンを配布しております。"
      />
      <CheckboxControlGroup
        name="user.emailMarketingAgreed"
        inputLabel="メールマガジンの受信に同意する"
        note="セールや限定クーポン、新入荷情報などをお届けします"
      />
      <div className="text-sm">
        <a href={routes.DOCUMENTS_PRIVACY_POLICY} target="_blank" rel="noreferrer">プライバシーポリシー</a>と
        <a href={routes.DOCUMENTS_TERMS_OF_SERVICE} target="_blank" rel="noreferrer">利用規約</a>に同意の上ご登録ください
      </div>
      <Button type="submit" block primary large disabled={isSubmitting}>
        同意して会員登録する
      </Button>
    </div>
  );
}, {
  schema,
  defaultValues,
});
