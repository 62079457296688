import { immerable } from "immer";

import * as api from "../services/api/logistics/models";
import { CostcoProduct, CostcoProductType } from "./costco_product";

export type ProductRecipeType = api.ProductRecipe;

export class ProductRecipe implements ProductRecipeType {
  [immerable] = true;

  id = "";
  costcoProduct: CostcoProductType = new CostcoProduct();
  contentsQuantity = 0;

  constructor(data: Partial<ProductRecipeType> = {}) {
    Object.assign(this, data);
  }

  getCostcoProduct() {
    return new CostcoProduct(this.costcoProduct);
  }

  get productQuantity() {
    return this.contentsQuantity / this.costcoProduct.contentsQuantity;
  }

  get ceiledProductQuantity() {
    return Math.ceil(this.productQuantity);
  }
}
