import classNames from "classnames";

import { Link } from "shared/components";
import { Product } from "shared/models";
import { BFC } from "shared/types";

import { routes } from "~/constants";

import { ProductCard } from "../ProductCard";

type Props = {
  products: Product[];
};

export const ProductsSlider: BFC<Props> = ({
  products,
  className,
}) => {
  return (
    <div className={classNames("w-full", className)}>
      <div className="overflow-x-scroll hidden-scrollbar px-3">
        <div className="flex gap-3 w-max">
          {products.map((product) => (
            <Link
              key={product.id}
              href={routes.PRODUCTS_SHOW(product.id)}
              noDecoration
              className="w-36 md:w-44"
            >
              <ProductCard product={product} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
