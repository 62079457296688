import { useInfiniteQuery } from "@tanstack/react-query";

import { Review } from "shared/models";
import { useStoreAPI } from "shared/services/api";

import { useAuthContext } from "~/features/auth";

type Params = {
  productId: string;
};

export const useReviews = ({ productId }: Params) => {
  const { accessToken } = useAuthContext();

  const api = useStoreAPI({ accessToken });
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    data,
  } = useInfiniteQuery(
    ["reviews", productId],
    ({ pageParam }) => api.getReviews(productId, pageParam ?? 1),
    {
      getNextPageParam: (last) => last?.meta.paging.next,
    }
  );

  const reviews: Review[] = data
    ? data.pages.flatMap(({ data: { reviews } }) =>
        reviews.map((r) => new Review(r))
      )
    : [];

  return {
    reviews,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  };
};
