/* tslint:disable */
/* eslint-disable */
/**
 * Survey
 * 現地調査用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CostcoProductLog } from './CostcoProductLog';
import {
    CostcoProductLogFromJSON,
    CostcoProductLogFromJSONTyped,
    CostcoProductLogToJSON,
} from './CostcoProductLog';

/**
 * 
 * @export
 * @interface GetCostcoProductLogs200ResponseAllOfData
 */
export interface GetCostcoProductLogs200ResponseAllOfData {
    /**
     * 
     * @type {Array<CostcoProductLog>}
     * @memberof GetCostcoProductLogs200ResponseAllOfData
     */
    costcoProductLogs: Array<CostcoProductLog>;
}

/**
 * Check if a given object implements the GetCostcoProductLogs200ResponseAllOfData interface.
 */
export function instanceOfGetCostcoProductLogs200ResponseAllOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "costcoProductLogs" in value;

    return isInstance;
}

export function GetCostcoProductLogs200ResponseAllOfDataFromJSON(json: any): GetCostcoProductLogs200ResponseAllOfData {
    return GetCostcoProductLogs200ResponseAllOfDataFromJSONTyped(json, false);
}

export function GetCostcoProductLogs200ResponseAllOfDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCostcoProductLogs200ResponseAllOfData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'costcoProductLogs': ((json['costco_product_logs'] as Array<any>).map(CostcoProductLogFromJSON)),
    };
}

export function GetCostcoProductLogs200ResponseAllOfDataToJSON(value?: GetCostcoProductLogs200ResponseAllOfData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'costco_product_logs': ((value.costcoProductLogs as Array<any>).map(CostcoProductLogToJSON)),
    };
}

