/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDirectUploadRequestBlob
 */
export interface CreateDirectUploadRequestBlob {
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUploadRequestBlob
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUploadRequestBlob
     */
    contentType: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDirectUploadRequestBlob
     */
    byteSize: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDirectUploadRequestBlob
     */
    checksum: string;
    /**
     * 
     * @type {object}
     * @memberof CreateDirectUploadRequestBlob
     */
    metadata?: object;
}

/**
 * Check if a given object implements the CreateDirectUploadRequestBlob interface.
 */
export function instanceOfCreateDirectUploadRequestBlob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filename" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "byteSize" in value;
    isInstance = isInstance && "checksum" in value;

    return isInstance;
}

export function CreateDirectUploadRequestBlobFromJSON(json: any): CreateDirectUploadRequestBlob {
    return CreateDirectUploadRequestBlobFromJSONTyped(json, false);
}

export function CreateDirectUploadRequestBlobFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDirectUploadRequestBlob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'contentType': json['content_type'],
        'byteSize': json['byte_size'],
        'checksum': json['checksum'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
    };
}

export function CreateDirectUploadRequestBlobToJSON(value?: CreateDirectUploadRequestBlob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'content_type': value.contentType,
        'byte_size': value.byteSize,
        'checksum': value.checksum,
        'metadata': value.metadata,
    };
}

