import { immerable } from "immer";

import { formatDate } from "../helpers";
import * as api from "../services/api/store/models";
import { AddressType, Address } from "./address";
import { CheckoutBilling, CheckoutBillingType } from "./checkout_billing";
import { CheckoutParcel, CheckoutParcelType, CheckoutParcelShippingMailStatus } from "./checkout_parcel";
import { CouponCodeType } from "./coupon_code";
import { FullfillmentShipping } from "./fullfillment_shipping";
import { Payment } from "./payment";
import { PaymentMinimumType } from "./payment_minimum";
import { User, userDefaultData, UserType } from "./user";

export const CheckoutStatus = api.CheckoutStatusEnum;
export type CheckoutStatus = typeof CheckoutStatus[keyof typeof CheckoutStatus];

export const CheckoutStockingStatus = api.CheckoutStockingStatusEnum;
export type CheckoutStockingStatus = typeof CheckoutStockingStatus[keyof typeof CheckoutStockingStatus];

export const CheckoutShippingStatus = api.CheckoutShippingStatusEnum;
export type CheckoutShippingStatus = typeof CheckoutShippingStatus[keyof typeof CheckoutShippingStatus];

export type CheckoutType = api.Checkout;

export class Checkout implements CheckoutType {
  [immerable] = true;

  id = "";
  serialNumber: string | undefined = undefined;
  subtotalPrice = 0;
  minSubtotalPrice = 0;
  maxSubtotalPrice = 0;
  discountPrice = 0;
  manualDiscountPrice = 0;
  totalShippingRate = 0;
  serviceCommission = 0;
  smallCheckoutCommission = 0;
  redeliveryCommission = 0;
  redeliveryCommissionRate = 0;
  totalTax = 0;
  minTotalTax = 0;
  maxTotalTax = 0;
  totalPrice = 0;
  maxTotalPrice = 0;
  minTotalPrice = 0;
  couponCode: CouponCodeType | undefined = undefined;
  parcels: CheckoutParcelType[] = [];
  user: UserType = userDefaultData;
  status: CheckoutStatus = CheckoutStatus.Waiting;
  stockingStatus: CheckoutStockingStatus = CheckoutStockingStatus.Waiting;
  shippingStatus: CheckoutShippingStatus = CheckoutShippingStatus.Waiting;
  shippingAddress: AddressType = new Address();
  billing: CheckoutBillingType | undefined = undefined;
  checkedOutAt = new Date();
  isCancelable = false;
  isBilled = false;
  cancelableAt = new Date();
  fullfillmentShippings?: FullfillmentShipping[];
  payment: PaymentMinimumType | undefined = undefined;

  constructor(data: Partial<CheckoutType> = {}) {
    Object.assign(this, data);
  }

  // @deperecated
  get quantity() {
    return this.totalQuantity;
  }

  get totalQuantity() {
    return this.parcels.reduce(
      (acc, parcel) => acc + parcel.items.reduce((acc2, item) => acc2 + item.quantity, 0),
      0,
    );
  }

  get taxPercentage() {
    this.parcels.forEach((parcel) => {
      parcel.items.forEach((item) => {
        if (item.taxPercentage && item.taxPercentage > 0) {
          return item.taxPercentage;
        }
      });
    });
    return 10;
  }

  formatCheckedOutAt(full = false) {
    return formatDate(this.checkedOutAt, full ? "yyyy年MM月dd日 HH:mm" : "MM月dd日 HH:mm");
  }

  getUser() {
    return new User(this.user);
  }

  getParcels() {
    return this.parcels.map((parcel) => new CheckoutParcel(parcel));
  }

  getParcel(id: string) {
    return this.getParcels().find((parcel) => parcel.id === id);
  }

  getSentShippingMailParcels() {
    return this.getParcels().filter((parcel) => parcel.shippingMailStatus === CheckoutParcelShippingMailStatus.Completed);
  }

  getShippingAddress() {
    return new Address(this.shippingAddress);
  }

  getPayment() {
    return new Payment(this.payment);
  }

  getBilling() {
    return this.billing ? new CheckoutBilling(this.billing) : null;
  }

  getFixedCheckout() {
    const billing = this.getBilling();
    return billing ? billing.toCheckout() : this;
  }

  getFixedTotalPrice() {
    return this.getFixedCheckout().totalPrice;
  }

  isLoaded() {
    return !!this.id;
  }

  isFixed() {
    return !!this.billing;
  }

  isEditable() {
    return this.status === CheckoutStatus.Paid;
  }

  hasCancelItems() {
    const items = this.getParcels().flatMap((parcel) => parcel.getFixedItems(this));
    return items.some((item) => item.cancelQuantity > 0);
  }

  formatCancelableAt() {
    return formatDate(this.cancelableAt, "yyyy年MM月dd日 HH:mm");
  }
}
