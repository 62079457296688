import { useCallback } from "react";
import { FcBullish } from "react-icons/fc";
import InfiniteScroll from "react-infinite-scroller";
import { ColorRing } from "react-loader-spinner";

import { Link } from "shared/components";
import { BFC } from "shared/types";

import { routes } from "~/constants";
import { ProductCard } from "~/features/products";
import { useRankingProducts } from "~/features/rankings";

type Props = {
  id: string;
};

export const ProductRankingShowPage: BFC<Props> = ({
  id,
}) => {
  const { ranking, products, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useRankingProducts(id);

  const loadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <div className="flex flex-col gap-4 pb-4">
      <div className="bg-white p-3">
        <div className="flex flex-wrap items-center">
          <div className="flex gap-1 mr-auto">
            <FcBullish size={20} />
            <h2 className="font-bold text-lg">{ranking.name}</h2>
          </div>
        </div>
        <InfiniteScroll loadMore={loadMore} hasMore={hasNextPage}>
          <div className="mt-3 grid grid-cols-2 gap-x-3 gap-y-6 md:grid-cols-4">
            {products.map((product) => (
              <Link key={product.id} href={routes.PRODUCTS_SHOW(product.id)} noDecoration>
                <ProductCard
                  product={product}
                />
              </Link>
            ))}
          </div>
        </InfiniteScroll>
        {(isLoading || isFetchingNextPage) && (
          <div className="flex justify-center items-center">
            <ColorRing />
          </div>
        )}
      </div>
    </div>
  );
};
