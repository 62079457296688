/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CheckoutParcel } from './CheckoutParcel';
import {
    CheckoutParcelFromJSON,
    CheckoutParcelFromJSONTyped,
    CheckoutParcelToJSON,
} from './CheckoutParcel';
import type { DeliveryDriver } from './DeliveryDriver';
import {
    DeliveryDriverFromJSON,
    DeliveryDriverFromJSONTyped,
    DeliveryDriverToJSON,
} from './DeliveryDriver';

/**
 * 
 * @export
 * @interface DeliveryParcel
 */
export interface DeliveryParcel {
    /**
     * 
     * @type {string}
     * @memberof DeliveryParcel
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryParcel
     */
    shippingDate: Date;
    /**
     * 
     * @type {CheckoutParcel}
     * @memberof DeliveryParcel
     */
    checkoutParcel: CheckoutParcel;
    /**
     * 
     * @type {DeliveryDriver}
     * @memberof DeliveryParcel
     */
    deliveryDriver: DeliveryDriver;
    /**
     * 
     * @type {number}
     * @memberof DeliveryParcel
     */
    deliveryOrder: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveryParcel
     */
    status: DeliveryParcelStatusEnum;
}


/**
 * @export
 */
export const DeliveryParcelStatusEnum = {
    Waiting: 'waiting',
    InProgress: 'in_progress',
    Completed: 'completed',
    Absented: 'absented',
    Cancelled: 'cancelled'
} as const;
export type DeliveryParcelStatusEnum = typeof DeliveryParcelStatusEnum[keyof typeof DeliveryParcelStatusEnum];


/**
 * Check if a given object implements the DeliveryParcel interface.
 */
export function instanceOfDeliveryParcel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "shippingDate" in value;
    isInstance = isInstance && "checkoutParcel" in value;
    isInstance = isInstance && "deliveryDriver" in value;
    isInstance = isInstance && "deliveryOrder" in value;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function DeliveryParcelFromJSON(json: any): DeliveryParcel {
    return DeliveryParcelFromJSONTyped(json, false);
}

export function DeliveryParcelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryParcel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shippingDate': (new Date(json['shipping_date'])),
        'checkoutParcel': CheckoutParcelFromJSON(json['checkout_parcel']),
        'deliveryDriver': DeliveryDriverFromJSON(json['delivery_driver']),
        'deliveryOrder': json['delivery_order'],
        'status': json['status'],
    };
}

export function DeliveryParcelToJSON(value?: DeliveryParcel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'shipping_date': (value.shippingDate.toISOString().substr(0,10)),
        'checkout_parcel': CheckoutParcelToJSON(value.checkoutParcel),
        'delivery_driver': DeliveryDriverToJSON(value.deliveryDriver),
        'delivery_order': value.deliveryOrder,
        'status': value.status,
    };
}

