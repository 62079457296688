import { FC, useMemo } from "react";

import { Product } from "shared/models";
import { BFC, RequireOne } from "shared/types";

import { useProduct } from "../../hooks";

type BaseProps = {
  skeleton?: FC;
  children: (product: Product) => React.ReactNode;
};

type ProductProps = RequireOne<{
  id?: string;
  product?: Product;
}>;

export const SoctocProductProvider: BFC<BaseProps & ProductProps> = ({
  id,
  product,
  skeleton: Skeleton,
  children,
}) => {
  const baseProduct = useMemo(() => {
    return new Product(product);
  }, [product]);

  const { product: soctocProduct, isLoading } = useProduct(id || baseProduct.id, baseProduct);

  if (isLoading && Skeleton) {
    return <Skeleton />;
  }

  return <>{children(soctocProduct)}</>;
};
