/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { StockConsumptionWithStock } from './StockConsumptionWithStock';
import {
    StockConsumptionWithStockFromJSON,
    StockConsumptionWithStockFromJSONTyped,
    StockConsumptionWithStockToJSON,
} from './StockConsumptionWithStock';

/**
 * 
 * @export
 * @interface CheckoutItem
 */
export interface CheckoutItem {
    /**
     * 
     * @type {string}
     * @memberof CheckoutItem
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CheckoutItem
     */
    checkoutId: string;
    /**
     * 
     * @type {Product}
     * @memberof CheckoutItem
     */
    product: Product;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    discountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    subtotalPrice: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    taxPercentage: number;
    /**
     * 
     * @type {Product}
     * @memberof CheckoutItem
     */
    alternativeProduct?: Product;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    alternativePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    alternativeDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    alternativeSubtotalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckoutItem
     */
    alternativeTaxPercentage?: number;
    /**
     * 
     * @type {Array<StockConsumptionWithStock>}
     * @memberof CheckoutItem
     */
    productSuggestedStockConsumptions?: Array<StockConsumptionWithStock>;
    /**
     * 
     * @type {Array<StockConsumptionWithStock>}
     * @memberof CheckoutItem
     */
    alternativeProductSuggestedStockConsumptions?: Array<StockConsumptionWithStock>;
}

/**
 * Check if a given object implements the CheckoutItem interface.
 */
export function instanceOfCheckoutItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "checkoutId" in value;
    isInstance = isInstance && "product" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "subtotalPrice" in value;
    isInstance = isInstance && "taxPercentage" in value;

    return isInstance;
}

export function CheckoutItemFromJSON(json: any): CheckoutItem {
    return CheckoutItemFromJSONTyped(json, false);
}

export function CheckoutItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'checkoutId': json['checkout_id'],
        'product': ProductFromJSON(json['product']),
        'quantity': json['quantity'],
        'price': json['price'],
        'discountPrice': !exists(json, 'discount_price') ? undefined : json['discount_price'],
        'subtotalPrice': json['subtotal_price'],
        'taxPercentage': json['tax_percentage'],
        'alternativeProduct': !exists(json, 'alternative_product') ? undefined : ProductFromJSON(json['alternative_product']),
        'alternativePrice': !exists(json, 'alternative_price') ? undefined : json['alternative_price'],
        'alternativeDiscountPrice': !exists(json, 'alternative_discount_price') ? undefined : json['alternative_discount_price'],
        'alternativeSubtotalPrice': !exists(json, 'alternative_subtotal_price') ? undefined : json['alternative_subtotal_price'],
        'alternativeTaxPercentage': !exists(json, 'alternative_tax_percentage') ? undefined : json['alternative_tax_percentage'],
        'productSuggestedStockConsumptions': !exists(json, 'product_suggested_stock_consumptions') ? undefined : ((json['product_suggested_stock_consumptions'] as Array<any>).map(StockConsumptionWithStockFromJSON)),
        'alternativeProductSuggestedStockConsumptions': !exists(json, 'alternative_product_suggested_stock_consumptions') ? undefined : ((json['alternative_product_suggested_stock_consumptions'] as Array<any>).map(StockConsumptionWithStockFromJSON)),
    };
}

export function CheckoutItemToJSON(value?: CheckoutItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'checkout_id': value.checkoutId,
        'product': ProductToJSON(value.product),
        'quantity': value.quantity,
        'price': value.price,
        'discount_price': value.discountPrice,
        'subtotal_price': value.subtotalPrice,
        'tax_percentage': value.taxPercentage,
        'alternative_product': ProductToJSON(value.alternativeProduct),
        'alternative_price': value.alternativePrice,
        'alternative_discount_price': value.alternativeDiscountPrice,
        'alternative_subtotal_price': value.alternativeSubtotalPrice,
        'alternative_tax_percentage': value.alternativeTaxPercentage,
        'product_suggested_stock_consumptions': value.productSuggestedStockConsumptions === undefined ? undefined : ((value.productSuggestedStockConsumptions as Array<any>).map(StockConsumptionWithStockToJSON)),
        'alternative_product_suggested_stock_consumptions': value.alternativeProductSuggestedStockConsumptions === undefined ? undefined : ((value.alternativeProductSuggestedStockConsumptions as Array<any>).map(StockConsumptionWithStockToJSON)),
    };
}

