import React, { useCallback } from "react";

import { Product } from "shared/models";
import { BFC } from "shared/types";

import { useAuthContext } from "~/features/auth";
import { useAddToCartButton } from "~/features/carts";
import { useFavorite } from "~/features/favorites";
import {
  ProductFavoriteButton,
} from "~/features/products";

import { ProductCardRaw } from "../ProductCardRaw";

type Props = {
  product: Product;
};

export const ProductCard: BFC<Props> = ({ product, className }) => {
  const { isSignedIn } = useAuthContext();

  const { isFavorite, toggleFavorite } = useFavorite({
    favoritable: product,
  });

  const handleFavoriteButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      // 親要素のリンクが発動しないようにする
      e.preventDefault();
      e.stopPropagation();

      toggleFavorite();
    },
    [toggleFavorite]
  );

  const { handleClick: addToCart, buttonState: addToCartButtonState } =
    useAddToCartButton({
      product,
    });

  const handleAddToCartButtonClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      // 親要素のリンクが発動しないようにする
      e.preventDefault();
      e.stopPropagation();

      await addToCart();
    },
    [addToCart]
  );

  return (
    <div className="relative h-full">
      {isSignedIn && (
        <ProductFavoriteButton
          isActive={isFavorite}
          onClick={handleFavoriteButtonClick}
          className="absolute top-0 right-0 z-10"
        />
      )}
      <ProductCardRaw
        title={product.title}
        image={product.image?.webp?.url ?? product.image?.url}
        price={product.price}
        discountPrice={product.discountPrice}
        taxPercentage={product.taxPercentage}
        storageTemperature={product.storageTemperature}
        onAddToCartButtonClick={handleAddToCartButtonClick}
        addToCartButtonState={addToCartButtonState}
        className={className}
      />
    </div>
  );
};
