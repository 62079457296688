/* tslint:disable */
/* eslint-disable */
/**
 * Store
 * 店舗向けAPI
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetCurrentUser200ResponseAllOfData } from './GetCurrentUser200ResponseAllOfData';
import {
    GetCurrentUser200ResponseAllOfDataFromJSON,
    GetCurrentUser200ResponseAllOfDataFromJSONTyped,
    GetCurrentUser200ResponseAllOfDataToJSON,
} from './GetCurrentUser200ResponseAllOfData';

/**
 * 
 * @export
 * @interface GetCurrentUser200ResponseAllOf
 */
export interface GetCurrentUser200ResponseAllOf {
    /**
     * 
     * @type {GetCurrentUser200ResponseAllOfData}
     * @memberof GetCurrentUser200ResponseAllOf
     */
    data: GetCurrentUser200ResponseAllOfData;
}

/**
 * Check if a given object implements the GetCurrentUser200ResponseAllOf interface.
 */
export function instanceOfGetCurrentUser200ResponseAllOf(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function GetCurrentUser200ResponseAllOfFromJSON(json: any): GetCurrentUser200ResponseAllOf {
    return GetCurrentUser200ResponseAllOfFromJSONTyped(json, false);
}

export function GetCurrentUser200ResponseAllOfFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetCurrentUser200ResponseAllOf {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': GetCurrentUser200ResponseAllOfDataFromJSON(json['data']),
    };
}

export function GetCurrentUser200ResponseAllOfToJSON(value?: GetCurrentUser200ResponseAllOf | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GetCurrentUser200ResponseAllOfDataToJSON(value.data),
    };
}

