import { atom, atomFamily } from "recoil";
import { v4 as uuidv4 } from "uuid";

export const createAtom = <T>(defaultValue: T) => {
  return atom({
    key: uuidv4(),
    default: defaultValue,
  });
};

export const createAtomFamily = <T>(defaultValue: T) => {
  return atomFamily({
    key: uuidv4(),
    default: defaultValue,
  });
};
