/* tslint:disable */
/* eslint-disable */
/**
 * Logistics
 * 物流管理用API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiuchi@wcdi.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateStockupItemRequestStockupItem
 */
export interface UpdateStockupItemRequestStockupItem {
    /**
     * 
     * @type {string}
     * @memberof UpdateStockupItemRequestStockupItem
     */
    status: UpdateStockupItemRequestStockupItemStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateStockupItemRequestStockupItem
     */
    quantity?: number;
}


/**
 * @export
 */
export const UpdateStockupItemRequestStockupItemStatusEnum = {
    Reserved: 'reserved',
    OutOfStock: 'out_of_stock'
} as const;
export type UpdateStockupItemRequestStockupItemStatusEnum = typeof UpdateStockupItemRequestStockupItemStatusEnum[keyof typeof UpdateStockupItemRequestStockupItemStatusEnum];


/**
 * Check if a given object implements the UpdateStockupItemRequestStockupItem interface.
 */
export function instanceOfUpdateStockupItemRequestStockupItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function UpdateStockupItemRequestStockupItemFromJSON(json: any): UpdateStockupItemRequestStockupItem {
    return UpdateStockupItemRequestStockupItemFromJSONTyped(json, false);
}

export function UpdateStockupItemRequestStockupItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStockupItemRequestStockupItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
    };
}

export function UpdateStockupItemRequestStockupItemToJSON(value?: UpdateStockupItemRequestStockupItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'quantity': value.quantity,
    };
}

