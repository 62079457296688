import classNames from "classnames";
import { FaCcVisa, FaCcMastercard, FaCcJcb, FaCcAmex, FaCcDinersClub, FaCcDiscover, FaCreditCard } from "react-icons/fa";

import { BFC } from "shared/types";

type Props = {
  brand: string;
}

export const CardBrandLogo: BFC<Props> = ({ brand, className }) => {
  const classes = classNames("w-5 h-auto", className);

  switch (brand) {
    case "visa":
      return <FaCcVisa className={classes} />;
    case "mastercard":
      return <FaCcMastercard className={classes} />;
    case "jcb":
      return <FaCcJcb className={classes} />;
    case "amex":
      return <FaCcAmex className={classes} />;
    case "diners":
      return <FaCcDinersClub className={classes} />;
    case "discover":
      return <FaCcDiscover className={classes} />;
    default:
      return <FaCreditCard className={classes} />;
  }
};
